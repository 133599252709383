import { SelectCloseAccountReasonKeys } from '@afterpaytouch/portal-api/consumer/account/types'
import React, { createContext, Dispatch, FunctionComponent, SetStateAction, useState, ReactNode } from 'react'

export const noop = (): void => {}

export const CloseAccountFlowSteps = {
  SelectCloseAccountReason: 'SelectCloseAccountReason',
  ConfirmCloseAccount: 'ConfirmCloseAccount',
  IneligibleCloseAccount: 'IneligibleCloseAccount',
} as const

type CloseAccountFlowStepsType = typeof CloseAccountFlowSteps
type CloseAccountStepsKeys = keyof CloseAccountFlowStepsType
export type CloseAccountStepsProps = CloseAccountFlowStepsType[CloseAccountStepsKeys]

export interface CloseAccountFlowResult {
  success: boolean
  error: boolean
}
export interface CloseAccountFlowContextProps {
  show: boolean
  step: CloseAccountStepsProps
  setStep: Dispatch<SetStateAction<CloseAccountStepsProps>>
  resetState: () => void
  closeModal: () => void
  result: CloseAccountFlowResult
  setResult: Dispatch<SetStateAction<CloseAccountFlowResult>>
  closeAccountReason: SelectCloseAccountReasonKeys
  setCloseAccountReason: Dispatch<SetStateAction<SelectCloseAccountReasonKeys>>
}

const defaultStep = CloseAccountFlowSteps.SelectCloseAccountReason
export const defaultResult = { success: false, error: false }
export const closeAccountFlowContextDefaults: CloseAccountFlowContextProps = {
  show: false,
  step: defaultStep,
  setStep: noop,
  resetState: noop,
  closeModal: noop,
  result: defaultResult,
  setResult: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  closeAccountReason: null,
  setCloseAccountReason: noop,
}

export const CloseAccountFlowContext = createContext<CloseAccountFlowContextProps>(closeAccountFlowContextDefaults)

interface CloseAccountFlowProviderProps {
  show?: boolean
  closeModal: () => void
  initialStep?: CloseAccountStepsProps
  children: ReactNode
}

export const CloseAccountFlowModalProvider: FunctionComponent<CloseAccountFlowProviderProps> = ({ show = false, closeModal, children }) => {
  const [step, setStep] = useState<CloseAccountStepsProps>(defaultStep)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [closeAccountReason, setCloseAccountReason] = useState<SelectCloseAccountReasonKeys>(null)
  const [result, setResult] = useState<CloseAccountFlowResult>(defaultResult)

  const resetState = (): void => {
    setStep(defaultStep)
    // @ts-ignore: OPERATION BLEED STOPPER
    setCloseAccountReason(null)
  }
  const value = {
    show,
    step,
    setStep,
    resetState,
    closeModal,
    result,
    setResult,
    closeAccountReason,
    setCloseAccountReason,
  }

  return <CloseAccountFlowContext.Provider value={value}>{children}</CloseAccountFlowContext.Provider>
}
