import React, { FunctionComponent, ReactNode } from 'react'
import clsx from 'clsx'
import { PaymentIcon, PaymentIconNames, Tag, Text } from '@afterpaytouch/core'
import { CardBrand } from '@afterpay/types'
import { PaymentMethodType } from '@afterpaytouch/portal-api/types'
import { useCardIconName } from '../../utils/cardIconName'
import { useConsumerSizes } from '../../utils/responsive'
import styles from './styles.module.scss'
import { useTranslation } from 'next-i18next'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { useFlag } from '../../hooks'

export enum IconPosition {
  Left = 'Left',
  Right = 'Right',
}

export interface PaymentRowProps {
  number?: string
  bankName?: string
  expiryDate?: string
  cardType?: CardBrand | string
  isPreferred?: boolean
  bodyComponent?: ReactNode
  rightAdornment?: ReactNode
  testNameSpace?: string
  iconPosition?: IconPosition
  disabled?: boolean
  type?: PaymentMethodType
  textColorFire?: boolean
}

export interface PaymentRowIconProps {
  cardIconName: PaymentIconNames
  isBank?: boolean
  isApplePay?: boolean
  type?: PaymentMethodType
}

export interface PreferredPaymentDisclaimerRowProps {
  testNameSpace?: string
  rightAdornment?: ReactNode
  merchantName?: string
}

// @TODO: Remove this component in favour of a reusable one based on checkouts PaymentMethodIcon component
export const PaymentMethodRowIcon: FunctionComponent<PaymentRowIconProps> = ({ cardIconName, type = PaymentMethodType.CREDIT_CARD }) => {
  if (type === PaymentMethodType.APPLE_PAY) {
    return <PaymentIcon.Applepay />
  }
  if (type === PaymentMethodType.ACH_BANK_ACCOUNT) {
    return <PaymentIcon.GenericBank />
  }
  if (type === PaymentMethodType.CASH_APP_PAY) {
    return (
      <div className={styles.cashIcon}>
        <PaymentIcon.CashPay size='Medium' />
      </div>
    )
  }
  return <PaymentIcon name={cardIconName} />
}

const I18N_NAMESPACE = ['account']

export const PreferredPaymentDisclaimerRow: FunctionComponent<PreferredPaymentDisclaimerRowProps> = ({ testNameSpace = '', rightAdornment, merchantName }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  return (
    <>
      <div
        {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-payment-method-row` })}
        className='flex flex-row items-center justify-between disabled:opacity-50'
      >
        <div className='flex flex-col'>
          <div className='mt-2'>
            <Text renderAs='span' size='M'>
              {t('recurringPayments:billingAgreementDetail:preferredMethodRowHeader')}
            </Text>
          </div>
          <div className='mt-1'>
            <Text renderAs='span' size='XS' color='Gray50' testNameSpace='preferred-ba-note'>
              {t('recurringPayments:billingAgreementDetail:preferredMethodRowNote', { merchantName })}
            </Text>
          </div>
        </div>
        {rightAdornment !== undefined && rightAdornment}
      </div>
    </>
  )
}

export const PaymentMethodRow: FunctionComponent<PaymentRowProps> = ({
  number,
  bankName,
  expiryDate,
  type = PaymentMethodType.CREDIT_CARD,
  cardType,
  isPreferred,
  bodyComponent,
  rightAdornment,
  testNameSpace = '',
  iconPosition = IconPosition.Left,
  disabled = false,
  textColorFire = false,
}) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const cardIconName = useCardIconName(cardType as CardBrand)
  const { textSize, smallTextSize } = useConsumerSizes()
  const iconLeft = iconPosition === IconPosition.Left
  const iconRight = iconPosition === IconPosition.Right
  const disabledClassnames = disabled ? 'opacity-50' : ''
  const paymentMethodTextColor = textColorFire ? 'Fire' : 'Gray40'
  const preferredLabel = `${t('account:tab:payment-methods:body:preferredLabel')}`
  const isAmexSoftCloseEnabled = useFlag('amex-soft-close-enabled', false)

  return (
    <>
      <div
        {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-payment-method-row` })}
        className='flex flex-row items-center justify-between disabled:opacity-50'
      >
        <div className='flex flex-row'>
          <div className='flex flex-row items-center'>
            {iconLeft && (
              <div className={clsx(disabledClassnames, 'md:mr-2')}>
                <PaymentMethodRowIcon type={type} cardIconName={cardIconName} />
              </div>
            )}
            <div className='flex flex-grow flex-row flex-wrap items-center'>
              {type === PaymentMethodType.APPLE_PAY && bodyComponent}
              {bankName && (
                <div className={clsx(disabledClassnames, 'ml-2 md:ml-4')}>
                  <Text size={textSize} color={paymentMethodTextColor} testNameSpace={`${testNameSpace}-payment-method-row-bankName`}>
                    {bankName}
                  </Text>
                </div>
              )}
              {type === PaymentMethodType.APPLE_PAY && cardType && (
                <div className={clsx(disabledClassnames, 'ml-2 md:ml-4')}>
                  <Text size={textSize} testNameSpace={`${testNameSpace}-payment-method-row-type`}>
                    {cardType}
                  </Text>
                </div>
              )}
              {number !== null && (
                <div className={clsx(disabledClassnames, 'ml-2 md:ml-4')}>
                  <Text size={textSize} color={paymentMethodTextColor} testNameSpace={`${testNameSpace}-payment-method-row-number`}>
                    <MaskedElement>{number}</MaskedElement>
                  </Text>
                </div>
              )}

              {expiryDate !== undefined && expiryDate && (
                <div className={clsx(disabledClassnames, 'ml-2 md:ml-4')}>
                  <Text size={textSize} color={paymentMethodTextColor} testNameSpace={`${testNameSpace}-payment-method-row-expiryDate`}>
                    <MaskedElement>{expiryDate}</MaskedElement>
                  </Text>
                </div>
              )}
              {isPreferred && (
                <div className={clsx(disabledClassnames, 'ml-2 py-2 md:ml-4')}>
                  <Tag.Black testNameSpace='preferred-label'>{preferredLabel}</Tag.Black>
                </div>
              )}
            </div>
          </div>
        </div>
        {iconRight && <PaymentMethodRowIcon type={type} cardIconName={cardIconName} />}
        {rightAdornment !== undefined && rightAdornment}
      </div>
      {cardType === 'AMEX' && isAmexSoftCloseEnabled && (
        <div className='pt-2'>
          <Text size={smallTextSize} color={paymentMethodTextColor} testNameSpace={`${testNameSpace}-payment-method-amex-error`}>
            {t('account:tab:payment-methods:modal:form:error:cardNumber:brandNotAcceptedAmex')}
          </Text>
        </div>
      )}
    </>
  )
}
