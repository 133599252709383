import { DisputeFlowType, OrderResponse } from '@afterpaytouch/portal-api'
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DisputeReason, DisputeSubReason } from '../../model'
import { LaunchDarklyFlags, useFlag } from '../../hooks'
import { useIsDisputePermitted } from '../../state'

// @ts-ignore: OPERATION BLEED STOPPER
const noop = (): void => null

export enum DisputeCreationModalSteps {
  SelectReason,
  ContactMerchant,
  OrderMismatchConfirmation, // deprecated
  TimeLimitTip,
  ContactMerchantInfo,
  SubmitEvidence,
  Disclaimer,
  Alibaba,
  Decline,
  DisagreeWithDeliveryWarn,
  NoPermission,
  Prezzee,
}

export interface DisputeCreationModalContextResult {
  success: boolean
  error: boolean
  message?: string
  title?: string
}

export interface DisputeCreationModalContextProps {
  step: DisputeCreationModalSteps
  setStep: (step: DisputeCreationModalSteps) => void
  resetState: () => void
  order: OrderResponse
  setOrder: (order: OrderResponse) => void
  closeModal: () => void
  result: DisputeCreationModalContextResult
  setResult: (result: DisputeCreationModalContextResult) => void
  isLoading: boolean
  setIsLoading: (loading: boolean) => void
  contactMerchantDate: string | null
  setContactMerchantDate: (date: string | null) => void
  contactMerchantName: string
  setContactMerchantName: (name: string | null) => void
  contactMerchantReason: string
  setContactMerchantReason: (reason: string) => void
  expectedDeliveryDate: string | null
  setExpectedDeliveryDate: (date: string | null) => void
  reason: DisputeReason
  setReason: (reason: DisputeReason) => void
  subReason: DisputeSubReason
  setSubReason: (subReason: DisputeSubReason) => void
  allowPartialRefund: boolean
  usePartialRefund: boolean
  setUsePartialRefund: (usePartialRefund: boolean) => void
  partialAmount: number
  setPartialAmount: (partialAmount: number) => void
  files: File[]
  setFiles: (files: File[]) => void
  refreshDisputeInfo: () => void
  note: string
  setNote: (note: string) => void
  useNote: boolean
  setUseNote: (useNote: boolean) => void
}

export const disputeCreateModalContextDefaultValue: DisputeCreationModalContextProps = {
  // @ts-ignore: OPERATION BLEED STOPPER
  step: null,
  setStep: noop,
  resetState: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  order: null,
  setOrder: noop,
  closeModal: noop,
  result: {
    success: false,
    error: false,
  },
  setResult: noop,
  isLoading: false,
  setIsLoading: noop,
  contactMerchantDate: null,
  setContactMerchantDate: noop,
  contactMerchantName: '',
  setContactMerchantName: noop,
  contactMerchantReason: '',
  setContactMerchantReason: noop,
  expectedDeliveryDate: null,
  setExpectedDeliveryDate: noop,
  reason: DisputeReason.ProductNotReceived,
  setReason: noop,
  subReason: DisputeSubReason.NotReceivedGoods,
  setSubReason: noop,
  allowPartialRefund: true,
  usePartialRefund: false,
  setUsePartialRefund: noop,
  partialAmount: 0,
  setPartialAmount: noop,
  files: [],
  setFiles: noop,
  refreshDisputeInfo: noop,
  note: '',
  setNote: noop,
  useNote: false,
  setUseNote: noop,
}

export const DisputeCreationModalContext = createContext<DisputeCreationModalContextProps>(disputeCreateModalContextDefaultValue)

export const useDisputeCreationModalContext = (): DisputeCreationModalContextProps => {
  return { ...useContext(DisputeCreationModalContext) }
}

export interface DisputeCreationModalProviderProps {
  initialStep?: DisputeCreationModalSteps
  order?: OrderResponse
  refreshDisputeInfo: () => void
  closeModal: () => void
  children?: ReactNode
}

export const DisputeCreationModalProvider: FC<DisputeCreationModalProviderProps> = ({ children, order: defaultOrder, closeModal, refreshDisputeInfo }) => {
  const permissionControlEnabled = useFlag(LaunchDarklyFlags.IrDisputePermissionControlEnabled)
  const orderMismatchEnabled = useFlag(LaunchDarklyFlags.IrOrderMismatchFlowEnabled)
  const initialResult = useMemo(() => ({ success: false, error: false }), [])
  const [step, setStep] = useState<DisputeCreationModalSteps>(DisputeCreationModalSteps.ContactMerchant)
  const [order, setOrder] = useState(defaultOrder ?? null)
  const [result, setResult] = useState<DisputeCreationModalContextResult>(initialResult)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [contactMerchantDate, setContactMerchantDate] = useState<string | null>(null)
  const [contactMerchantName, setContactMerchantName] = useState<string>('')
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState<string | null>(null)
  const [contactMerchantReason, setContactMerchantReason] = useState<string>('')
  const [reason, setReason] = useState<DisputeReason>(DisputeReason.ProductNotReceived)
  const [subReason, setSubReason] = useState<DisputeSubReason>(DisputeSubReason.NotReceivedGoods)
  const [usePartialRefund, setUsePartialRefund] = useState<boolean>(false)
  const [partialAmount, setPartialAmount] = useState<number>(0)
  const [files, setFiles] = useState<File[]>([])
  const [note, setNote] = useState<string>('')
  const [useNote, setUseNote] = useState<boolean>(false)

  const resetState = useCallback(() => {
    setStep(DisputeCreationModalSteps.ContactMerchant)
    setResult(initialResult)
    setContactMerchantDate(null)
    setContactMerchantName('')
    setExpectedDeliveryDate(null)
    setContactMerchantReason('')
    setUsePartialRefund(false)
    setIsLoading(false)
    setFiles([])
    setNote('')
    setUseNote(false)
    setReason(DisputeReason.ProductNotReceived)
  }, [initialResult])

  const allowPartialRefund: boolean = useMemo(() => {
    return !['SQUARE', 'SQUAREDEV'].some((agency) => agency === order?.merchant?.agencyRef)
  }, [order])

  const isPermitted = useIsDisputePermitted()

  useEffect(() => {
    if (order?.disputeFlowType === DisputeFlowType.ALIBABA) {
      setStep(DisputeCreationModalSteps.Alibaba)
      return
    }

    if (order?.disputeFlowType === DisputeFlowType.DECLINE) {
      setStep(DisputeCreationModalSteps.Decline)
      return
    }

    if (order?.disputeFlowType === DisputeFlowType.PREZZEE) {
      setStep(DisputeCreationModalSteps.Prezzee)
      return
    }

    if (permissionControlEnabled && !isPermitted) {
      setStep(DisputeCreationModalSteps.NoPermission)
      return
    }

    if (!orderMismatchEnabled) {
      switch (order?.disputeFlowType) {
        case DisputeFlowType.SUP:
          setStep(DisputeCreationModalSteps.SelectReason)
          break
        default:
          break
      }
    }
  }, [order, permissionControlEnabled, isPermitted, orderMismatchEnabled])

  const value = {
    step,
    setStep,
    order,
    setOrder,
    closeModal,
    result,
    setResult,
    resetState,
    isLoading,
    setIsLoading,
    contactMerchantDate,
    setContactMerchantDate,
    contactMerchantName,
    setContactMerchantName,
    expectedDeliveryDate,
    setExpectedDeliveryDate,
    contactMerchantReason,
    setContactMerchantReason,
    reason,
    setReason,
    subReason,
    setSubReason,
    allowPartialRefund,
    usePartialRefund,
    setUsePartialRefund,
    partialAmount,
    setPartialAmount,
    files,
    setFiles,
    refreshDisputeInfo,
    note,
    setNote,
    useNote,
    setUseNote,
  }
  // @ts-ignore: OPERATION BLEED STOPPER
  return <DisputeCreationModalContext.Provider value={value}>{children}</DisputeCreationModalContext.Provider>
}
