import React, { useEffect, FunctionComponent, useCallback, useContext } from 'react'
import { Modal } from '@afterpaytouch/core'
import { SelectCloseAccountReason } from './SelectCloseAccountReason/SelectCloseAccountReason'
import { CloseAccountFlowModalProvider, CloseAccountFlowContext, CloseAccountFlowSteps } from './CloseAccountFlowContext'
import { SelectCloseAccountOption } from './SelectCloseAccountOption/SelectCloseAccountOption'
import { DeactivateAccount } from './DeactivateAccount'
import { Error } from './Error'
import { ConfirmCloseAccount } from './ConfirmCloseAccount'
export interface CloseAccountFlowProps {
  show: boolean
  closeModal: () => void
  onSuccess?: () => void
  isConsumerEligibleToCloseAccount?: boolean
}

export const CloseAccountSteps: FunctionComponent<{ onSuccess: () => void; isConsumerEligibleToCloseAccount?: boolean }> = ({
  onSuccess,
  isConsumerEligibleToCloseAccount = false,
}) => {
  const { step, resetState, show, closeModal } = useContext(CloseAccountFlowContext)
  const renderStep = useCallback((): React.ReactNode => {
    switch (step) {
      case CloseAccountFlowSteps.SelectCloseAccountReason:
        return <SelectCloseAccountReason />
      case CloseAccountFlowSteps.SelectCloseAccountOption:
        return <SelectCloseAccountOption />
      case CloseAccountFlowSteps.DeactivateAccount:
        return <DeactivateAccount />
      case CloseAccountFlowSteps.ConfirmCloseAccount:
        return <ConfirmCloseAccount onSuccess={onSuccess} />
    }
  }, [step, onSuccess])

  useEffect(() => {
    if (!show) {
      resetState()
    }
  }, [show, resetState])

  return (
    <Modal show={show} onClose={closeModal} onHide={closeModal} onEscapeKeyDown={closeModal} testNameSpace='close-account-flow'>
      {isConsumerEligibleToCloseAccount ? renderStep() : <Error />}
    </Modal>
  )
}

export const CloseAccountFlowModal: FunctionComponent<CloseAccountFlowProps> = ({
  show = false,
  closeModal,
  onSuccess = () => {},
  isConsumerEligibleToCloseAccount = false,
}) => {
  return (
    <CloseAccountFlowModalProvider show={show} closeModal={closeModal}>
      <CloseAccountSteps onSuccess={onSuccess} isConsumerEligibleToCloseAccount={isConsumerEligibleToCloseAccount} />
    </CloseAccountFlowModalProvider>
  )
}
