import { Modal, Button, Heading, Text, useToast, Link } from '@afterpaytouch/core'
import React, { FunctionComponent, useCallback } from 'react'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { Trans, useTranslation } from 'next-i18next'
import { useGetOrder, useGetOrderPaymentType, useOrderHasAutopayEnabled, useToggleAutopayMutation } from '../../state'
import { TrackingEvent } from '../../model/amplitude'

const I18N_NAMESPACE = ['common', 'orders']

interface OrderAutopayPBIModalProps {
  orderId: string
  isModalOpen: boolean
  closeModal: () => void
}

export const OrderAutopayPBIModal: FunctionComponent<OrderAutopayPBIModalProps> = ({ orderId, isModalOpen, closeModal }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const testNameSpace = 'order-autopay-pbi'
  const amplitude = useAmplitudeWithEnduringEventProperties()
  const setToast = useToast()
  const orderDetails = useGetOrder(orderId)

  const onShow = (): void =>
    amplitude.logEvent(TrackingEvent.CLICKED_AUTOPAY_TOGGLE, {
      // note: log the future state of the toggle
      autopay: isAutopayEnabled ? 'off' : 'on',
      orderId,
    })

  const onClose = (): void => {
    amplitude.logEvent(TrackingEvent.CLOSED_AUTOPAY_MODAL, { orderId })
    closeModal()
  }

  const [toggleAutopay, { isLoading: isToggleAutopayLoading }] = useToggleAutopayMutation()

  const isAutopayEnabled = useOrderHasAutopayEnabled(orderId)

  const paymentType = useGetOrderPaymentType(orderId)

  const handleSubmit = useCallback(async (): Promise<void> => {
    const eventName = isAutopayEnabled ? TrackingEvent.CLICKED_CONFIRM_TURN_AUTOPAY_OFF : TrackingEvent.CLICKED_CONFIRM_TURN_AUTOPAY_ON
    amplitude.logEvent(eventName, { orderId })
    if (isToggleAutopayLoading) {
      return
    }

    try {
      await toggleAutopay({ orderId: Number(orderId), enabled: !isAutopayEnabled }).unwrap()
      // We want to log the future value of autopayDisabled
      amplitude.logEvent(TrackingEvent.TOGGLE_AUTOPAY_SUCCESS, {
        autopay: orderDetails.autopayDisabled ? 'on' : 'off',
        orderId,
        orderTotal: orderDetails?.orderSummary?.orderTotal?.amount,
        source: orderDetails?.source,
        paymentMethodType: orderDetails?.paymentMethod?.type,
        channel: orderDetails?.channel,
      })
      setToast({
        message: isAutopayEnabled ? t('orders:autopay:modal:toast:success:off') : t('orders:autopay:modal:toast:success:on'),
        kind: 'Default',
        manualDismiss: true,
      })
      closeModal()
    } catch (e) {
      amplitude.logEvent(TrackingEvent.TOGGLE_AUTOPAY_FAILURE, { autopay: orderDetails.autopayDisabled ? 'on' : 'off', orderId })
      setToast({
        message: t('common:error:defaultErrorMsg'),
        kind: 'Failure',
        manualDismiss: true,
      })
    }
  }, [amplitude, closeModal, isAutopayEnabled, isToggleAutopayLoading, orderId, setToast, t, toggleAutopay])

  if (!isModalOpen) {
    return null
  }

  return (
    <Modal
      show={isModalOpen}
      onShow={onShow}
      onClose={onClose}
      onHide={onClose}
      onBackdropClick={onClose}
      onEscapeKeyDown={onClose}
      testNameSpace={testNameSpace}
    >
      <Modal.Header divider>
        <Heading testNameSpace={testNameSpace} size='M'>
          {isAutopayEnabled ? t('orders:autopay:modal:headingOff') : t('orders:autopay:modal:headingOn')}
        </Heading>
      </Modal.Header>
      <Modal.Content>
        {isAutopayEnabled ? (
          <Text size='S'>{t('orders:autopay:modal:bodyOff')}</Text>
        ) : (
          <>
            <div className='mb-4'>
              <Text size='S'>{t('orders:autopay:modal:bodyOn')}</Text>
            </div>
            <Text size='S' color='Gray40'>
              <Trans
                i18nKey={'orders:autopay:modal:disclaimer'}
                components={{
                  paymentAuthLink: (
                    <Link
                      href={t(`orders:orderAutopay:paymentAuthorisationUrl:${paymentType}`)}
                      target='_blank'
                      kind='Underline'
                      onClick={() =>
                        amplitude.logEvent(TrackingEvent.CLICKED_PAYMENT_AUTHORISATION_LINK, {
                          orderId,
                          outboundLink: t(`orders:orderAutopay:paymentAuthorisationUrl:${paymentType}`),
                        })
                      }
                    />
                  ),
                }}
              />
            </Text>
          </>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button.Secondary
          padding='Fluid'
          disabled={isToggleAutopayLoading}
          loading={isToggleAutopayLoading}
          onClick={handleSubmit}
          testNameSpace={testNameSpace}
        >
          <Heading size='M'>{isAutopayEnabled ? t('orders:autopay:modal:cta:off') : t('orders:autopay:modal:cta:on')}</Heading>
        </Button.Secondary>
      </Modal.Footer>
    </Modal>
  )
}
