import { Button, Heading, Modal, Text } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { FunctionComponent } from 'react'
import { Route } from '../../config/router'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { useDisplayIllustrations } from '../../state/convergence/hooks'

export interface PulseIntroModalProps {
  isModalOpen: boolean
  closeModal: () => void
}

const I18N_NAMESPACE = ['common', 'loyalty']

export const PulseLogo = (): JSX.Element => {
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <div className='bg-dark flex h-[124px] w-[124px] items-center justify-center rounded-sm'>
      <Image width={92.48} height={30.2} src={`/images/pulse.svg`} alt={t('loyalty:logo:alt')} />
    </div>
  )
}

export const PulseIntroModal: FunctionComponent<PulseIntroModalProps> = ({ isModalOpen, closeModal }) => {
  const router = useRouter()
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const isDisplayIllustrations = useDisplayIllustrations()

  const onShow = (): void => logEvent('Viewed Pulse Intro Modal')

  const onButtonClick = (): void => {
    logEvent(TrackingEvent.CLICKED_PULSE_INTRO_MODAL_BUTTON)
    closeModal()
    router.push(Route.ACCOUNT__PULSE)
  }

  const testNameSpace = 'pulse-intro'

  return isModalOpen ? (
    <Modal
      show={isModalOpen}
      onShow={onShow}
      onClose={closeModal}
      onHide={closeModal}
      onBackdropClick={closeModal}
      onEscapeKeyDown={closeModal}
      testNameSpace={testNameSpace}
      backdropKind='Darker'
    >
      <Modal.Content>
        {isDisplayIllustrations && (
          <div className='mt-6 flex items-center justify-center'>
            <PulseLogo />
          </div>
        )}
        <div className='mt-6'>
          <Heading size='XXL' testNameSpace={testNameSpace}>
            {t('loyalty:introModal:heading')}
          </Heading>
        </div>
        <div className='mt-4'>
          <Text size='M' testNameSpace={testNameSpace}>
            {t('loyalty:introModal:content')}
          </Text>
        </div>
        <div className='mt-6'>
          <Button padding='Fluid' onClick={onButtonClick} testNameSpace={testNameSpace}>
            <Text bold>{t('loyalty:introModal:button')}</Text>
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  ) : null
}
