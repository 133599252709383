import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Heading, Icon, Input, Link, Modal, Text } from '@afterpaytouch/core'
import { useDisputeTranslation } from '../hooks'
import { useConsumerSizes } from '../../../utils/responsive'
import { ACCEPT_FILE_MIME, MAX_FILE_LENGTH, MAX_FILE_SIZE, MAX_NOTE_LENGTH } from '../../../model'
import { TrackOnMount } from '../../../integrations/amplitude'

import { checkAmount } from '../utils'
import { useTranslation } from 'next-i18next'
import { TrackingEvent } from '../../../model/amplitude'
import { DisputeCreationModalSteps, useDisputeCreationModalContext } from '../DisputeCreationModalContext'

const I18N_NAMESPACE = ['common']

export const SubmitEvidence: FC = () => {
  const { textSize } = useConsumerSizes()
  const { t: tCommon } = useTranslation(I18N_NAMESPACE)
  const t = useDisputeTranslation()
  const {
    setStep,
    allowPartialRefund,
    usePartialRefund,
    setUsePartialRefund,
    partialAmount,
    setPartialAmount,
    order,
    files,
    setFiles,
    note,
    setNote,
    useNote,
    setUseNote,
  } = useDisputeCreationModalContext()
  const uploadRef = useRef<HTMLInputElement>(null)
  const [error, setError] = useState<string>('')

  /* memorized text */
  const disputeAmount: string = useMemo(() => {
    return `${order?.orderSummary?.orderTotal?.symbol}${order?.orderSummary?.totalAfterRefund?.amount}`
  }, [order])
  const addFileButtonText = useMemo(() => {
    if (files.length > 0) {
      return t('submitEvidence:addAnotherFile')
    }
    return t('submitEvidence:addFile')
  }, [t, files])
  const accept = useMemo<string>(() => {
    return ACCEPT_FILE_MIME.join(',')
  }, [])

  /* event handler */
  const noteChangedHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNote(event.target.value)
    },
    [setNote]
  )
  const useNoteChangedHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setUseNote(e.target.checked)
    },
    [setUseNote]
  )
  const backClickHandler = useCallback(() => {
    setStep(DisputeCreationModalSteps.ContactMerchantInfo)
  }, [setStep])
  const usePartialRefundChangedHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setUsePartialRefund(e.target.checked)
      setError('')
    },
    [setUsePartialRefund]
  )
  const partialAmountChangedHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPartialAmount(Number(event.target.value.trim()))
      setError('')
    },
    [setPartialAmount]
  )
  const addFileButtonClickHandler = useCallback(() => {
    uploadRef.current?.click()
  }, [])
  const fileChangedHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore: OPERATION BLEED STOPPER
      const fileList = Array.from(event.target.files)
      if (fileList.length + files.length > MAX_FILE_LENGTH) {
        setError(t('submitEvidence:error:exceedCount'))
        return
      }

      const fileOutOfSizeList = fileList.filter((file: File) => file.size > MAX_FILE_SIZE)
      if (fileOutOfSizeList.length > 0) {
        setError(`${t('submitEvidence:error:exceedSize')}`)
        return
      }

      setFiles([...files, ...fileList])
      setError('')
      event.target.value = ''
    },
    [files, setFiles, t]
  )
  const removeFileHandler = useCallback(
    (index: number) => () => {
      const dummyFiles = [...files]
      dummyFiles.splice(index, 1)
      setFiles([...dummyFiles])
    },
    [files, setFiles]
  )
  const submitHandler = async (): Promise<void> => {
    if (usePartialRefund) {
      if (partialAmount <= 0) {
        setError(t('submitEvidence:error:amountNegative'))
        return
      }

      if (!checkAmount(partialAmount)) {
        setError(t('submitEvidence:error:amount'))
        return
      }

      if (partialAmount > Number(order?.orderSummary?.totalAfterRefund?.amount)) {
        setError(t('submitEvidence:error:exceedOrderAmount', { amount: Number(order?.orderSummary?.totalAfterRefund?.amount) }))
        return
      }
    }

    setStep(DisputeCreationModalSteps.Disclaimer)
  }

  useEffect(() => {
    return () => {
      setError('')
    }
  }, [])

  const evidenceHeading = t('submitEvidence:head')
  const evidenceDisputeAmount = t('submitEvidence:disputeAmount')
  const evidenceAmountDescription = t('submitEvidence:amountDescription')
  const evidenceSubTitle = t('submitEvidence:subtitle')
  const evidenceUploaderTips = t('submitEvidence:uploaderTips')
  const evidenceTips = t('submitEvidence:tips')
  const privacy = t('submitEvidence:privacy')
  const privacyTitle = t('submitEvidence:privacyTitle')
  const submit = t('contactMerchantInfo:continue')

  return (
    <>
      <TrackOnMount eventName={TrackingEvent.VIEWED_CREATE_DISPUTE_SUBMIT_EVIDENCE} />
      <Modal.Header divider={true}>
        <div className='absolute z-10 pt-1'>
          <Button.Link size='Small' onClick={backClickHandler}>
            <Icon.CaretLeft size={'Small'} />
          </Button.Link>
        </div>
        <div className='text-center'>
          <Heading size='L'>{evidenceHeading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Heading size='M'>{evidenceDisputeAmount}</Heading>
        <div className='mt-4'>
          <Heading size='XL'>{disputeAmount}</Heading>
        </div>
        {allowPartialRefund && (
          <div className='mt-4'>
            <Checkbox label={t('submitEvidence:usePartialRefund')} defaultChecked={usePartialRefund} onChange={usePartialRefundChangedHandler} />
          </div>
        )}
        {usePartialRefund && (
          <div className='mt-4'>
            <Text size={textSize}>{evidenceAmountDescription}</Text>
            <div className='mt-4 flex items-stretch'>
              <Input.Number
                label={t('submitEvidence:partialRefundLabel')}
                icon='Coins'
                iconPosition='Left'
                fullWidth={true}
                value={partialAmount.toString()}
                onChange={partialAmountChangedHandler}
              />
              <div className='p-md flex items-center justify-center'>
                <Text>{order?.orderSummary?.orderTotal?.currency}</Text>
              </div>
            </div>
          </div>
        )}
        <div className='mt-6'>
          <Text size={textSize}>{evidenceSubTitle}</Text>
        </div>
        <div className='mt-4'>
          {files.map((file: File, index) => {
            return (
              <div className='mt-2 flex items-center justify-between' key={`${file.name}${index}`}>
                <div className='flex-auto overflow-hidden text-ellipsis whitespace-nowrap'>
                  <Text size={textSize} bold renderAs='span'>
                    {file.name}
                  </Text>
                </div>
                <div className='shrink-0 grow-0 cursor-pointer' onClick={removeFileHandler(index)}>
                  <Icon.Close size='Small' />
                </div>
              </div>
            )
          })}
          <div className='mt-2 flex items-end'>
            <Button.Ghost size='Regular' disabled={files.length >= MAX_FILE_LENGTH} onClick={addFileButtonClickHandler}>
              {addFileButtonText}
            </Button.Ghost>
            <Text size='S' color='Gray40'>
              {evidenceUploaderTips}
            </Text>
          </div>
          {/* @ts-ignore: OPERATION BLEED STOPPER */}
          <input ref={(ref) => (uploadRef.current = ref)} className='hidden' type='file' multiple={true} accept={accept} onChange={fileChangedHandler} />
        </div>
        <div className='mt-6'>
          <Text size='XS' color='Gray40'>
            {evidenceTips}
          </Text>
        </div>
        <div className='mt-4'>
          <Checkbox label={t('submitEvidence:additionalInfo')} defaultChecked={useNote} onChange={useNoteChangedHandler} />
        </div>
        {useNote && (
          <Input.TextArea
            rows={6}
            fullWidth={true}
            placeholder={t('submitEvidence:notePlaceholder')}
            onChange={noteChangedHandler}
            value={note}
            characterLimit={MAX_NOTE_LENGTH}
          />
        )}
        {error && (
          <div className='mt-6'>
            <Text size={textSize} color='Fire'>
              {error}
            </Text>
          </div>
        )}
        <div className='mt-6'>
          <Text size={textSize} renderAs='span'>
            {privacy}
          </Text>
          <Link kind='Underline' href={tCommon('common:urls:privacyPolicyUrl')} target='_blank'>
            <Text size={textSize} renderAs='span'>
              {privacyTitle}
            </Text>
          </Link>
          <Text size={textSize} renderAs='span'>
            .
          </Text>
        </div>
        <div className='mt-4'>
          <Button padding='Fluid' onClick={submitHandler}>
            {submit}
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}
