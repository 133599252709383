import { Heading, Text } from '@afterpaytouch/core'
import { Trans, useTranslation } from 'next-i18next'
import React, { useContext } from 'react'
import { IdentityDocument } from '../../../../model'
import { useIsPartialVerified } from '../../../../state'
import { Wrapper } from '../../Wrapper'
import { IsRetryPageContext } from '../helper'
import { useHeadingAndDescription } from '../utils'

const I18N_NAMESPACE = ['common', 'verifyIdentity']

interface Props {
  documentType: IdentityDocument
}

export const HardIdHeader: React.FC<Props> = ({ documentType }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { heading, description } = useHeadingAndDescription(t, documentType)
  const isRetryPage = useContext(IsRetryPageContext)
  const isRetryMode = useIsPartialVerified() && isRetryPage

  return (
    <>
      <Heading>{heading}</Heading>
      {isRetryMode && (
        <Wrapper marginType='Small'>
          <Text>
            <Trans i18nKey={description} />
          </Text>
        </Wrapper>
      )}
    </>
  )
}
