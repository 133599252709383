import { Skeleton, Text, useIsBreakpointMediumAndUp, useIsCashTheme } from '@afterpaytouch/core'
import { Money as IMoney, SupportedLocale } from '@afterpaytouch/portal-api'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useLocalStorage } from '@afterpay/utils/storage'
import { HeaderInfoDisplay } from '../../HeaderInfoDisplay'
import { Greeting } from '../../Greeting'
import { FirstPurchaseModal } from './FirstPurchaseModal'
import styles from './style.module.scss'
import { useFlag } from '../../../../hooks'

interface FirstPurchaseProps {
  consumer: {
    givenName: string
    isVerified: boolean
    hasTransactions: boolean
    availableToSpend: IMoney
  }
  isSkeletonShown: boolean
  isParentLoading: boolean
}

const I18N_NAMESPACE = ['common', 'index']

export const FirstPurchase: FunctionComponent<FirstPurchaseProps> = ({ consumer, isSkeletonShown, isParentLoading }) => {
  const {
    i18n: { language: locale },
    t,
  } = useTranslation(I18N_NAMESPACE)
  const isCashTheme = useIsCashTheme()
  const isBpMediumUp = useIsBreakpointMediumAndUp()
  const amountSize = isBpMediumUp ? 'XXXXL' : 'XXXL'
  const [hasSeenFirstPurchaseModal, setHasSeenFirstPurchaseModal] = useLocalStorage('hasSeenFirstPurchaseModal', false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const isAvailableToSpendDisabled = useFlag('consumer-portal-available-to-spend-disabled', false)

  const isFirstPurchase = !isAvailableToSpendDisabled
  const closeModal = (): void => {
    setIsModalOpen(false)
    setHasSeenFirstPurchaseModal(true)
  }

  useEffect(() => {
    if (!isParentLoading && !hasSeenFirstPurchaseModal && consumer.isVerified && !consumer.hasTransactions) {
      setIsModalOpen(true)
    }
  }, [isParentLoading, hasSeenFirstPurchaseModal, consumer.isVerified, consumer.hasTransactions])

  return (
    <>
      <div className={styles.container}>
        <Greeting consumer={consumer} firstPurchase={isFirstPurchase} isLoading={isSkeletonShown} withComma={isAvailableToSpendDisabled} />
        <div className={styles.contentSkeletonContainer}>
          {isSkeletonShown && <Skeleton kind={isCashTheme ? 'Default' : 'Primary'} />}
          {!isAvailableToSpendDisabled ? (
            <div className={isSkeletonShown ? styles.invisible : styles.visible}>
              <HeaderInfoDisplay amount={consumer.availableToSpend} amountSize={amountSize} locale={locale as SupportedLocale} isCreditLimit={true} />
            </div>
          ) : (
            <div className='relative mb-2'>
              <Text size='XL' bold>
                {t('index:welcome:firstPurchase:modal:content:heading').toLowerCase()}
              </Text>
            </div>
          )}
        </div>
      </div>
      {!isAvailableToSpendDisabled && <FirstPurchaseModal isModalOpen={isModalOpen} closeModal={closeModal} />}
    </>
  )
}
