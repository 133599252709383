import { RegisterOptions } from 'react-hook-form'
import { DateFieldSchema, DateOfBirthFieldSchema, DropdownFieldSchema, DropdownItems, FieldType, InputFieldSchema } from '@afterpaytouch/form'
import { IdentityDocument, SupportedLocale } from '../../../model'
import { useIsPartialVerified } from '../../../state'
import { TFunction } from 'next-i18next'
import { useContext } from 'react'
import { IsRetryPageContext } from './helper'

interface InputFieldSchemaOpts {
  label: string
  value?: string
  options?: RegisterOptions
  inline?: boolean | number
  otherProps?: any
  error?: boolean
  show?: boolean
}

export const getInputFieldSchema = ({
  value = '',
  label,
  inline = false,
  options = {},
  otherProps = {},
  error = false,
  show = true,
}: InputFieldSchemaOpts): InputFieldSchema => ({
  type: FieldType.Input,
  show,
  props: {
    label,
    value,
    error,
    ...otherProps,
  },
  inline,
  options,
})

interface InputBirthdaySchemaOpts {
  label: string
  value?: string
  options?: RegisterOptions
  inline?: boolean
  locale: string
  otherValidations: Omit<DateOfBirthFieldSchema, 'type'>
  otherProps?: any
  error?: boolean
  show?: boolean
}

export const getInputBirthdaySchema = ({
  locale,
  label,
  value,
  options = {},
  otherValidations = {},
  otherProps = {},
  error = false,
  show = true,
}: InputBirthdaySchemaOpts): DateOfBirthFieldSchema => ({
  type: FieldType.DateOfBirth,
  show,
  props: {
    label,
    defaultValue: value,
    locale: locale as SupportedLocale,
    error,
    ...otherProps,
  },
  options,
  ...otherValidations,
})

interface DropdownSchemaOpts {
  label: string
  value?: string
  items: DropdownItems[]
  options: RegisterOptions
  id?: string
  error?: boolean
  show?: boolean
}

export const getDropdownFieldSchema = ({ label, value = '', items, options, id, error = false, show = true }: DropdownSchemaOpts): DropdownFieldSchema => ({
  type: FieldType.Dropdown,
  props: {
    label,
    defaultValue: value,
    id,
    error,
  },
  items,
  options,
  show,
})

interface DateSchemaOpts {
  label: string
  options: RegisterOptions
  format?: string
  otherProps?: any
  error?: boolean
  show?: boolean
}

export const getDateSchema = ({ format, label, options, otherProps = {}, error = false, show = true }: DateSchemaOpts): DateFieldSchema => ({
  type: FieldType.Date,
  format,
  props: {
    label,
    error,
    ...otherProps,
  },
  options,
  show,
})

export const useHeadingAndDescription = (t: TFunction, documentType: IdentityDocument): { heading: string; description: string } => {
  const isRetryPage = useContext(IsRetryPageContext)
  const isRetryMode = useIsPartialVerified() && isRetryPage
  const heading = isRetryMode ? t('verifyIdentity:partialVerified:heading') : t(`verifyIdentity:document:${documentType}`)
  const description = t(`verifyIdentity:partialVerified:description:${documentType}`)

  return {
    heading,
    description,
  }
}
