import React, { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Button, Heading, Modal, Text } from '@afterpaytouch/core'
import { Route } from '../../../config/router'
import { useRouter } from 'next/router'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

export const IneligibleCloseCreditFacility: FunctionComponent = () => {
  const I18N_NAMESPACE = ['common', 'payMonthly']
  const { t } = useTranslation(I18N_NAMESPACE)
  const route = useRouter()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const testNameSpace = 'close-credit-facility-error'

  const redirectToOrders = (): void => {
    route.push(Route.ORDERS)
    logEvent(TrackingEvent.CREDIT_FACILITY.PressedPayClOrders)
  }

  return (
    <>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading size='L' testNameSpace={testNameSpace}>
            {t('payMonthly:closeCreditFacility:modal:title')}
          </Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className='mb-4'>
          <Text>
            <Trans i18nKey={'payMonthly:closeCreditFacility:ineligibleCloseCreditFacility:detail'} components={{ br: <br /> }} />
          </Text>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button testNameSpace={testNameSpace} padding='Fluid' onClick={redirectToOrders}>
          {t('payMonthly:closeCreditFacility:ineligibleCloseCreditFacility:cta')}
        </Button>
      </Modal.Footer>
    </>
  )
}
