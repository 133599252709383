import { Button, Grid, GridWall, Modal, Text, useDate } from '@afterpaytouch/core'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect } from 'react'
import { useConfirmPreferredPaydayMutation } from '../../../../../state/preferredPaymentDay/hooks'
import { PreferredDaySteps, useSetPreferredDayModalContext } from '../PreferredDayModalContext'

const I18N_NAMESPACE = ['account']
interface NewPaymentScheduleProps {
  dueDate: string
  isFirst?: boolean
}

const NewPaymentSchedule: FunctionComponent<NewPaymentScheduleProps> = ({ dueDate, isFirst }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const router = useRouter()
  const locale = router?.locale as SupportedLocale
  const { formatDate } = useDate({ locale, month: 'short', weekday: 'short' })

  return (
    <div className='group'>
      <Grid gutter={{ sm: 0.5 }}>
        <Grid.Item sm='auto'>
          <div className='relative flex h-full w-6 justify-center pt-1'>
            <div className='border-dark-subtle absolute -bottom-1 top-1 border-0 border-l-2 border-solid group-last:hidden'></div>
            <div>
              <div className='border-dark-subtle bg-light relative h-4 w-4 rounded-full border-2 border-solid' />
            </div>
          </div>
        </Grid.Item>
        <Grid.Item>
          <div className='flex flex-col pb-6 group-last:pb-0'>
            <Text bold renderAs='span' size={'S'} color={'Gray40'}>
              {isFirst ? t('account:tab:payment-methods:preferred-day:today') : formatDate(dueDate)}
            </Text>
            <div className='group-first:hidden'>
              <Text renderAs='span' size={'S'} color={'Gray40'}>
                {t('account:tab:payment-methods:preferred-day:modal:frequency:fortnight')}
              </Text>
            </div>
          </div>
        </Grid.Item>
      </Grid>
    </div>
  )
}

interface GridTextProps {
  bold?: boolean
  children: string | number
}
const GridText: FunctionComponent<GridTextProps> = ({ bold = false, children }) => {
  return (
    <div className='mb-2'>
      <Text bold={bold}>{children}</Text>
    </div>
  )
}

export const ConfirmNewSchedule: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { selectedPreferredDay, examplePaymentSchedule, setStep, setResult } = useSetPreferredDayModalContext()
  const [confirmPreferredPayday, { isSuccess, isError, isLoading }] = useConfirmPreferredPaydayMutation()
  const numberOfInstalments = examplePaymentSchedule?.length

  const handleConfirm = (): void => {
    confirmPreferredPayday({ preferredPaymentDay: selectedPreferredDay })
  }

  useEffect(() => {
    if (isSuccess) {
      setResult({ success: true, error: false })
      setStep(PreferredDaySteps.PreferredPaydayResult)
    }
    if (isError) {
      setResult({ success: false, error: true })
      setStep(PreferredDaySteps.PreferredPaydayResult)
    }
  }, [isSuccess, isError, setResult, setStep])

  return (
    <>
      <Modal.Content>
        <GridWall columns={2} gutter='none'>
          <GridText bold>{t('account:tab:payment-methods:preferred-day:modal:recurrence:label')}</GridText>
          <GridText>{t('account:tab:payment-methods:preferred-day:modal:frequency:fortnight')}</GridText>
          <GridText bold>{t('account:tab:payment-methods:preferred-day:modal:preferredDay:label')}</GridText>
          <GridText>{selectedPreferredDay}</GridText>
          <GridText bold>{t('account:tab:payment-methods:preferred-day:modal:installments:label')}</GridText>
          <GridText>{numberOfInstalments}</GridText>
        </GridWall>
        <div className='mt-4'>
          <Text>{t('account:tab:payment-methods:preferred-day:modal:confirmation:1')}</Text>
        </div>
        <div className='mt-4'>
          <Text>{t('account:tab:payment-methods:preferred-day:modal:confirmation:2')}</Text>
        </div>
        <div className='mt-6'>
          <div className='border-dark-subtle rounded-xl border-[0.5px] border-solid px-4 py-6'>
            {examplePaymentSchedule?.map((day, i) => (
              <NewPaymentSchedule key={`newPaymentSchedule-${day}`} dueDate={day} isFirst={i === 0} />
            ))}
          </div>
        </div>
        <div className='mt-4'>
          <Button padding='Fluid' onClick={handleConfirm} loading={isLoading}>
            {t('account:tab:payment-methods:preferred-day:modal:confirmation:cta')}
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}
