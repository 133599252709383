import { useTranslation } from 'next-i18next'
import { Heading, Skeleton, Icon, Span, Panel, useIsCashTheme } from '@afterpaytouch/core'
import { Money as IMoney } from '@afterpaytouch/portal-api'
import React, { FunctionComponent } from 'react'
import { Money } from '../../'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'

const I18N_NAMESPACE = ['common', 'index']

interface PayInFourCardProps {
  consumer: {
    totalOwedForPBI?: IMoney
    availableToSpend?: IMoney
  }
  isLoading: boolean
}

export const PayInFourCard: FunctionComponent<PayInFourCardProps> = ({ consumer, isLoading }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isCashTheme = useIsCashTheme()
  if (typeof consumer.availableToSpend === 'undefined' || typeof consumer.totalOwedForPBI === 'undefined') {
    return null
  }
  return (
    <div className='relative grid h-full w-full [&>*:first-child]:grid' data-testid='pay-in-four-card-container'>
      <Panel>
        <Panel.Content inset>
          <div className='min-w-100 py-4 pl-3.5 sm:pr-4 md:pr-3' data-testid='pay-in-four-card'>
            <div className='flex flex-row'>
              <div className='mr-2 flex min-w-fit items-center'>
                <Heading size='S'>{t('index:welcome:existingUser:payInFour:heading')}</Heading>
              </div>
            </div>
            <div className='flex flex-row items-center justify-between'>
              <div>
                <div className='mt-2 flex min-w-fit flex-row items-center'>
                  <Money value={consumer.totalOwedForPBI} size='XL' bold testNameSpace='pay-in-four-card' />
                </div>
                <MaskedElement>
                  <div className='mt-1 flex flex-row gap-1'>
                    <Span color='Gray50' size='XXS'>
                      {t('index:welcome:existingUser:payInFour:availableToSpend')}
                    </Span>
                    <Money color='Gray50' value={consumer.availableToSpend} size='XXS' />
                    <span className='ml-1 flex'>
                      <Icon name='Info' size='ExtraSmall' color='Gray50' title={t('payMonthly:decreaseLimit:button')} />
                    </span>
                  </div>
                </MaskedElement>
              </div>
            </div>
          </div>
        </Panel.Content>
      </Panel>
      {isLoading && <Skeleton kind={isCashTheme ? 'Default' : 'Primary'} />}
    </div>
  )
}
