import { Heading, Modal, Text, useIsBreakpointSmall } from '@afterpaytouch/core'
import { LoyaltyTierBenefit } from '@afterpaytouch/portal-api'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'

export interface PulseBenefitModalProps {
  isModalOpen: boolean
  closeModal: () => void
  pulseBenefit: LoyaltyTierBenefit
}

export const PulseBenefitModal: FunctionComponent<PulseBenefitModalProps> = ({ isModalOpen, closeModal, pulseBenefit }) => {
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const isBreakpointSmall = useIsBreakpointSmall()

  const onShow = (): void => logEvent('Viewed Pulse Benefit Modal', { pulseBenefit })

  const testNameSpace = 'pulse-benefit-modal'

  return isModalOpen ? (
    <Modal
      show={isModalOpen}
      onShow={onShow}
      onClose={closeModal}
      onHide={closeModal}
      onBackdropClick={closeModal}
      onEscapeKeyDown={closeModal}
      testNameSpace={testNameSpace}
    >
      <Modal.Header divider>
        <div className={clsx('text-center', isBreakpointSmall && 'mx-5')}>
          <Heading testNameSpace={testNameSpace} size='L'>
            {pulseBenefit.title}
          </Heading>
        </div>
      </Modal.Header>

      <Modal.Content>
        {/* @ts-ignore: OPERATION BLEED STOPPER */}
        {pulseBenefit.contents.map((content, index) => {
          return (
            <Text key={index} testNameSpace={testNameSpace}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Text>
          )
        })}
      </Modal.Content>
    </Modal>
  ) : null
}
