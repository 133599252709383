import { Button, Heading, Modal, Text, Span } from '@afterpaytouch/core'
import { Trans, useTranslation } from 'next-i18next'
import React, { FunctionComponent, ReactNode } from 'react'
import Image from 'next/image'
import { useConsumerSizes } from '../../utils/responsive'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { NewTabLink } from '../NewTabLink'
import { useDisplayIllustrations } from '../../state/convergence/hooks'

const I18N_NAMESPACE = ['common', 'index']

interface ConsumerLendingIntroModalProps {
  isModalOpen: boolean
  closeModal: () => void
  testNameSpace?: string
}

interface TermsLinkProps {
  children?: ReactNode
}

const TermsLink: FunctionComponent<TermsLinkProps> = ({ children }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  return (
    <Span underline>
      <NewTabLink href={t('common:urls.consumerLendingUrl')}>{children}</NewTabLink>
    </Span>
  )
}

export const ConsumerLendingIntroModal: FunctionComponent<ConsumerLendingIntroModalProps> = ({
  isModalOpen,
  closeModal,
  testNameSpace = 'consumer-lending-intro-modal',
}) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const isDisplayIllustrations = useDisplayIllustrations()

  const { headingJumboSize, textSize } = useConsumerSizes()

  const labels = {
    title: t('index:monthlyPayments:title'),
    body: t('index:monthlyPayments:body'),
    note: (
      <Trans
        i18nKey={'index:monthlyPayments:finePrint'}
        components={{
          termsLink: <TermsLink />,
        }}
      />
    ),
    cta: t('index:monthlyPayments:learnMore'),
  }

  const onHandleLearnMore = (): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    logEvent(TrackingEvent.CLICKED_LEARN_MORE_MONTHLY_PAYMENTS, { outboundLink: t('common:urls:payMonthlyUrl') })
    window.open(t('common:urls:payMonthlyUrl'), '_blank')
    closeModal()
  }

  return (
    <Modal show={isModalOpen} onClose={closeModal} onHide={closeModal} onBackdropClick={closeModal} backdropKind='Darker' testNameSpace='first-purchase'>
      <Modal.Header>
        <div className='my-6 text-center' data-testid={`${testNameSpace}-header`}>
          {isDisplayIllustrations && (
            <Image
              width={138}
              height={139}
              src={`/images/cha-ching.svg`}
              alt={t('index:welcome:existingUser:monthlyPayments:heading')}
              data-testid='monthly-payments-image'
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          )}
          <div className='text-left'>
            <Heading testNameSpace={testNameSpace} size={headingJumboSize}>
              {labels.title}
            </Heading>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <Text size={textSize}>{labels.body}</Text>
        </div>
        <div className='mt-4'>
          <Text color='Gray40' size='XXS'>
            {labels.note}
          </Text>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button kind='Primary' padding='Fluid' onClick={onHandleLearnMore} testNameSpace={testNameSpace}>
          {labels.cta}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
