import { useCallback, useEffect } from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { SerializedError } from '@reduxjs/toolkit'
import {
  ConfirmPayNowResponse,
  InitiateCustomAmountPaymentResponse,
  InitiateInstallmentPaymentResponse,
  Money,
  PaymentMethodType,
} from '@afterpaytouch/portal-api/types'
import { PayNowInput, usePayNowModalContext } from './PayNowModalContext'
import { isConsumerLendingOrder } from '../../state/consumerLending'
import {
  useConfirmLendingPayNowMutation,
  useConfirmPayNowMutation,
  useInitiateInstallmentPaymentMutation,
  useInitiateLendingInstallmentPaymentMutation,
} from '../../state/paymentSchedule/hooks'
import {
  useConfirmCustomAmountPayNowMutation,
  useConfirmLendingCustomAmountPaymentMutation,
  useConfirmLendingPayoutMutation,
  useInitiateCustomAmountPaymentMutation,
  useInitiateLendingCustomAmountPaymentMutation,
  useInitiateLendingPayoutMutation,
} from '../../state/orders/hooks'

interface UseInitInstallmentPaymentProps {
  orderId: string
}

interface UseConfirmInstallmentPaymentProps {
  orderId: string
}

interface UseConfirmCustomAmountPaymentProps {
  orderId: string
}

interface UseInitCustomAmountPaymentProps {
  orderId: string
}

interface InitiateInstallmentPaymentProps {
  schedules: Array<{
    id: number
  }>
}

interface ConfirmInstallmentPaymentProps {
  traceId: string
  storeToken: boolean
  paymentMethodId?: number
  paymentMethodType: PaymentMethodType
}

interface ConfirmCustomAmountPaymentProps {
  traceId: string
  storeToken: boolean
  paymentMethodId?: number
  paymentMethodType: PaymentMethodType
}

interface InitiateCustomAmountPaymentProps {
  amount: Money
}

type UseInitInstallmentPaymentResult = [
  // @TODO: Why is the response below types as installment or amount payment - should be one or the other
  ({ schedules }: InitiateInstallmentPaymentProps) => Promise<InitiateInstallmentPaymentResponse | InitiateCustomAmountPaymentResponse>,
  {
    data: InitiateInstallmentPaymentResponse | InitiateCustomAmountPaymentResponse
    isLoading: boolean
  }
]

type UseInitCustomAmountPaymentResult = [
  // @TODO: Why is the response below types as installment or amount payment - should be one or the other
  ({ amount }: InitiateCustomAmountPaymentProps) => Promise<InitiateInstallmentPaymentResponse | InitiateCustomAmountPaymentResponse>,
  {
    data: InitiateInstallmentPaymentResponse | InitiateCustomAmountPaymentResponse
    isLoading: boolean
    isError: boolean
  }
]

type UseConfirmInstallmentPaymentResult = [
  ({ traceId, storeToken, paymentMethodType }: ConfirmInstallmentPaymentProps) => Promise<ConfirmPayNowResponse>,
  {
    isSuccess: boolean
    isError: boolean
    isLoading: boolean
    error: FetchBaseQueryError | SerializedError
  }
]

type UseConfirmCustomAmountPaymentResult = [
  ({ traceId, storeToken, paymentMethodType }: ConfirmCustomAmountPaymentProps) => Promise<ConfirmPayNowResponse>,
  {
    isSuccess: boolean
    isError: boolean
    isLoading: boolean
    error: FetchBaseQueryError | SerializedError
  }
]

export const useInitInstallmentPayment = ({ orderId }: UseInitInstallmentPaymentProps): UseInitInstallmentPaymentResult => {
  const { setResult, order, payNowStep } = usePayNowModalContext()

  const isConsumerLending = isConsumerLendingOrder(order?.paymentType)
  const isPayout = isConsumerLending && payNowStep === PayNowInput.REMAINING
  const initiateInstallmentPayment = useInitiateInstallmentPaymentMutation()
  const initiateLendingInstallmentPayment = useInitiateLendingInstallmentPaymentMutation()
  const initiateLendingPayout = useInitiateLendingPayoutMutation()

  const payoutInit = initiateLendingPayout
  const scheduleInit = isConsumerLending ? initiateLendingInstallmentPayment : initiateInstallmentPayment
  const [payoutInitFn] = initiateLendingPayout
  const [installmentInitFn] = scheduleInit
  const [_, { data, isLoading, isError }] = isPayout ? payoutInit : scheduleInit

  useEffect(() => {
    if (isError) {
      setResult({ error: true, success: false })
    }
  }, [isError, setResult])

  return [
    useCallback(
      async ({ schedules }: InitiateInstallmentPaymentProps) => {
        if (isPayout) {
          return await payoutInitFn({ orderId: Number(orderId) }).unwrap()
        }
        return await installmentInitFn({
          paymentScheduleIds: schedules.map(({ id }) => id),
        }).unwrap()
      },
      [installmentInitFn, isPayout, orderId, payoutInitFn]
    ),
    {
      // @ts-ignore: OPERATION BLEED STOPPER
      data,
      isLoading,
    },
  ]
}

export const useConfirmInstallmentPayment = ({ orderId }: UseConfirmInstallmentPaymentProps): UseConfirmInstallmentPaymentResult => {
  const { order, payNowStep } = usePayNowModalContext()

  const isConsumerLending = isConsumerLendingOrder(order?.paymentType)
  const isPayout = isConsumerLending && payNowStep === PayNowInput.REMAINING
  const confirmInstallmentPayment = useConfirmPayNowMutation()
  const confirmLendingInstallmentPayment = useConfirmLendingPayNowMutation()
  const confirmLendingPayout = useConfirmLendingPayoutMutation()

  const payoutConfirm = confirmLendingPayout
  const scheduleConfirm = isConsumerLending ? confirmLendingInstallmentPayment : confirmInstallmentPayment
  const [payoutConfirmFn] = confirmLendingPayout
  const [installmentConfirmFn] = scheduleConfirm

  const [_, { isSuccess, isError, error, isLoading }] = isPayout ? payoutConfirm : scheduleConfirm

  return [
    // @ts-ignore: OPERATION BLEED STOPPER
    useCallback(
      async ({ paymentMethodId, traceId, storeToken, paymentMethodType }: ConfirmInstallmentPaymentProps) => {
        try {
          if (isPayout) {
            return await payoutConfirmFn({ orderId: Number(orderId), body: { traceId, storeToken, paymentMethodType } }).unwrap()
          }
          return await installmentConfirmFn({
            paymentMethodId,
            traceId,
            storeToken,
            paymentMethodType,
          }).unwrap()
        } catch (error) {}
      },
      [installmentConfirmFn, isPayout, orderId, payoutConfirmFn]
    ),
    {
      isSuccess,
      isError,
      // @ts-ignore: OPERATION BLEED STOPPER
      error,
      isLoading,
    },
  ]
}

export const useInitCustomAmountPayment = ({ orderId }: UseInitCustomAmountPaymentProps): UseInitCustomAmountPaymentResult => {
  const { setResult, order } = usePayNowModalContext()

  const isConsumerLending = isConsumerLendingOrder(order?.paymentType)
  const initiateCustomAmountPayment = useInitiateCustomAmountPaymentMutation()
  const initiateLendingCustomAmountPayment = useInitiateLendingCustomAmountPaymentMutation()

  const customAmountInit = isConsumerLending ? initiateLendingCustomAmountPayment : initiateCustomAmountPayment
  const [customAmountInitFn] = customAmountInit

  const [_, { data, isLoading, isError }] = customAmountInit

  useEffect(() => {
    if (isError) {
      setResult({ error: true, success: false })
    }
  }, [isError, setResult])

  return [
    // @ts-ignore: OPERATION BLEED STOPPER
    useCallback(
      async ({ amount }: InitiateCustomAmountPaymentProps) => {
        try {
          return await customAmountInitFn({
            orderId: Number(orderId),
            body: {
              amount,
            },
          }).unwrap()
        } catch (error) {}
      },
      [customAmountInitFn, orderId]
    ),
    {
      // @ts-ignore: OPERATION BLEED STOPPER
      data,
      isError,
      isLoading,
    },
  ]
}

export const useConfirmInitCustomAmountPayment = ({ orderId }: UseConfirmCustomAmountPaymentProps): UseConfirmCustomAmountPaymentResult => {
  const { setResult, order } = usePayNowModalContext()

  const isConsumerLending = isConsumerLendingOrder(order?.paymentType)
  const confirmCustomAmountPayment = useConfirmCustomAmountPayNowMutation()
  const confirmLendingCustomAmountPayment = useConfirmLendingCustomAmountPaymentMutation()

  const customAmountConfirm = isConsumerLending ? confirmLendingCustomAmountPayment : confirmCustomAmountPayment
  const [customAmountConfirmFn] = customAmountConfirm

  const [_, { isSuccess, isError, error, isLoading }] = customAmountConfirm

  useEffect(() => {
    if (isError) {
      setResult({ error: true, success: false })
    }
  }, [isError, setResult])

  return [
    // @ts-ignore: OPERATION BLEED STOPPER
    useCallback(
      async ({ paymentMethodId, traceId, storeToken, paymentMethodType }: ConfirmCustomAmountPaymentProps) => {
        try {
          return await customAmountConfirmFn({
            orderId: Number(orderId),
            body: {
              paymentMethodId,
              traceId,
              storeToken,
              paymentMethodType,
            },
          }).unwrap()
        } catch (error) {}
      },
      [customAmountConfirmFn, orderId]
    ),
    {
      isSuccess,
      isError,
      // @ts-ignore: OPERATION BLEED STOPPER
      error,
      isLoading,
    },
  ]
}
