import React, { FunctionComponent, useRef, useState, useEffect } from 'react'
import { Grid, Heading, Text } from '@afterpaytouch/core'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRootClose } from 'react-overlays'
import { Route } from '../../config/router'
import { Breadcrumb } from '../Breadcrumb'
import styles from './style.module.scss'
import { useRouter } from 'next/router'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { Avatar } from '../Avatar'
import { Gravatar } from '../Gravatar'
import { INavigationItemProps, NavigationMenu } from '../Navigation'
import { useConsumerEmail, useConsumerName } from '../../state/account/account.hooks'
import { useLogout } from '../../state/auth/user/hooks'
import { useIsPulseEnabled } from '../../state/loyalty/loyalty.hooks'
import { useIsAlignPaydayEligible } from '../../state/preferredPaymentDay/hooks'

export const AccountBar: FunctionComponent = () => {
  const consumer = {
    name: useConsumerName(),
    email: useConsumerEmail(),
  }

  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const showOverlay = (): void => setIsOverlayVisible(true)
  const hideOverlay = (): void => setIsOverlayVisible(false)

  const profileButtonClassNames = clsx(styles.avatar, !isOverlayVisible && styles['cursor-pointer'])

  return (
    <Grid center>
      <Grid.Item>
        <Breadcrumb />
      </Grid.Item>
      <Grid.Item sm='auto'>
        <button className={profileButtonClassNames} onClick={showOverlay} data-testid='account-bar-profile-button'>
          <MaskedElement>
            <Avatar kind={'Normal'} name={consumer.name} />
          </MaskedElement>
          <ProfileOverlay consumer={consumer} isVisible={isOverlayVisible} onHide={hideOverlay} />
        </button>
      </Grid.Item>
    </Grid>
  )
}

interface ProfileOverlayProps {
  consumer: {
    name: string
    email: string | null
  }
  isVisible: boolean
  onHide: () => void
}

export const ProfileOverlay: FunctionComponent<ProfileOverlayProps> = ({ consumer, isVisible, onHide }) => {
  const [doTransition, setDoTransition] = useState(false)

  const resetTransition = (): void => setDoTransition(false)

  const overlayRef = useRef()

  const hide = (): void => {
    onHide()
    resetTransition()
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  useRootClose(overlayRef, hide, {
    disabled: !isVisible,
  })

  useEffect(() => {
    if (isVisible) {
      setDoTransition(true)
    }
  }, [isVisible])

  const containerClassNames = clsx(
    styles.container,
    styles['opacity-0'],
    styles['translate-y-4'],
    doTransition && clsx(styles['opacity-100'], styles['translate-y-0'], styles['transition-all'])
  )

  const backdropClassNames = clsx(styles.backdrop, doTransition && clsx(styles.showBackdrop))

  const navigationItems = useNavigationItemsByRoute()

  if (!isVisible) {
    return null
  }

  return (
    <div className={styles.overlay}>
      <div className={backdropClassNames} />
      {/* @ts-ignore: OPERATION BLEED STOPPER */}
      <div ref={overlayRef} className={containerClassNames}>
        <div className={styles.profile}>
          <div className={styles.image}>
            <Gravatar size='L' alt={consumer.name} name={consumer.name} title={consumer.name} testNameSpace='account-bar-active' />
          </div>
          <div className={styles.name}>
            <MaskedElement>
              <Heading size='L' testNameSpace='name' lineClamp={2}>
                {consumer.name}
              </Heading>
            </MaskedElement>
          </div>
          <div className={styles.email}>
            <MaskedElement>
              <Text renderAs='span' color='Gray40' testNameSpace='email' lineClamp={2}>
                {consumer.email}
              </Text>
            </MaskedElement>
          </div>
        </div>
        <nav className={styles.navigation}>
          <NavigationMenu items={navigationItems} onSelect={hide} />
        </nav>
      </div>
    </div>
  )
}

const useNavigationItemsByRoute = (): INavigationItemProps[] => {
  const I18N_NAMESPACE = ['common']
  const { t } = useTranslation(I18N_NAMESPACE)
  const { pathname } = useRouter()
  const logout = useLogout()

  const isPulseEnabled = useIsPulseEnabled()
  const isAlignPaydayEligible = useIsAlignPaydayEligible()

  const AccountProfile = {
    text: t('common:navigation:account-profile'),
    testId: 'account-profile',
    route: [Route.ACCOUNT__PROFILE],
  }

  const AccountLoyalty = {
    text: t('common:navigation:account-loyalty'),
    testId: 'account-loyalty',
    route: [Route.ACCOUNT__PULSE],
  }

  const AccountPaymentMethods = isAlignPaydayEligible
    ? {
        text: t('common:navigation:account-paymentSettings'),
        testId: 'account-paymentSettings',
        route: [Route.ACCOUNT__PAYMENT_SETTINGS],
      }
    : {
        text: t('common:navigation:account-paymentMethods'),
        testId: 'account-paymentMethods',
        route: [Route.ACCOUNT__PAYMENT_METHODS],
      }

  const AccountPreferences = {
    text: t('common:navigation:account-preferences'),
    testId: 'account-preferences',
    route: [Route.ACCOUNT__PREFERENCES],
  }

  const AccountSecurity = {
    text: t('common:navigation:account-security'),
    testId: 'account-security',
    route: [Route.ACCOUNT__SECURITY],
  }

  const Logout = {
    text: t('common:navigation:logout'),
    testId: 'account-logout',
    onClick: () => {
      logout()
    },
  }

  switch (pathname) {
    case '/account': {
      return [Logout]
    }
    default: {
      const defaultNavigationItems = isPulseEnabled
        ? [AccountProfile, AccountLoyalty, AccountPaymentMethods, AccountPreferences, AccountSecurity, Logout]
        : [AccountProfile, AccountPaymentMethods, AccountPreferences, AccountSecurity, Logout]

      return defaultNavigationItems
    }
  }
}
