import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react'

export const TYPES = {
  BUTTON: 'button',
  SUBMIT: 'submit',
} as const

type TypesType = typeof TYPES
type TypesKeys = keyof TypesType
export type ButtonTypes = TypesType[TypesKeys]

export interface ShellButtonProps extends Omit<React.AnchorHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  children: ReactNode
  disabled?: boolean
  onClick?: (e: SyntheticEvent) => void
  tabIndex?: number
  testNameSpace?: string
  type?: ButtonTypes
  'aria-disabled'?: boolean | 'true' | 'false'
  'aria-label'?: string
  'aria-labelledby'?: string
}
export type ShellButtonComponent = FunctionComponent<ShellButtonProps>

export const ShellButton: ShellButtonComponent = ({
  children,
  disabled = false,
  onClick = () => {},
  tabIndex = 0,
  type = 'button',
  testNameSpace = '',
  ...otherProps
}) => {
  return (
    <button
      className='cursor-pointer touch-none overflow-hidden border-none bg-transparent outline-none disabled:cursor-default'
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex}
      type={type}
      {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-shellButton` })}
      {...(disabled && { 'aria-disabled': true })}
      {...otherProps}
    >
      {children}
    </button>
  )
}
