import React, { createContext, Dispatch, FunctionComponent, SetStateAction, useCallback, useContext, useMemo, useState, ReactNode } from 'react'
import { Money } from '@afterpaytouch/portal-api/types'
import { PaymentCardDetails, PaymentType } from '@afterpay/types'
import { InitiateInstallmentPaymentResponse } from '@afterpaytouch/portal-api/consumer/paymentSchedule'
import { InitiateCustomAmountPaymentResponse } from '@afterpaytouch/portal-api'
import { PaymentMethods } from '../../state/orders/types'
import { useACHModal } from '../Ach'
import { AchStepsProps } from '../Ach/AchFlowModalContext'

export const noop = (): void => {}

export const PayNowSteps = {
  Installments: 'Installments',
  Payment: 'Payment',
  ClearpayPartiallyMigratedRedirection: 'ClearpayPartiallyMigratedRedirection',
} as const

type PayNowStepsType = typeof PayNowSteps
type PayNowStepsKeys = keyof PayNowStepsType
export type PayNowStepsProps = PayNowStepsType[PayNowStepsKeys]

export const PayNowInput = {
  NEXT: 'NEXT',
  REMAINING: 'REMAINING',
  CUSTOM: 'CUSTOM',
} as const

type PayNowInputType = typeof PayNowInput
type PayNowInputKeys = keyof PayNowInputType
export type PayNowInputProps = PayNowInputType[PayNowInputKeys]

interface CustomPaymentOrder {
  orderId: string | number
  merchant: string
  paymentType: PaymentType
}

// The initiateData prop is optional, if it is not passed the payment setup api call will be made when opening the payment modal screen
export interface PaymentCustomAmount {
  amount: Money
  initiateData?: InitiateCustomAmountPaymentResponse
  total?: Money
  scheduleId?: string
}

export interface PaymentInstallment {
  initiateData?: InitiateInstallmentPaymentResponse // Installment payment
  ids?: Array<{
    id: number
    sequence?: number
  }>
  total: Money
  isOverdue?: boolean
  scheduleId?: string
}

export type PaymentSetupProps = PaymentCustomAmount | PaymentInstallment

// Payment and merchant props are required when an initialStep of Payment is passed. Otherwise, they are considered optional.
export type PayNowModalInitialStepProp =
  | {
      initalStep: typeof PayNowSteps.Payment
      payment: PaymentSetupProps
      order: CustomPaymentOrder
    }
  | {
      initalStep?: typeof PayNowSteps.Installments | typeof PayNowSteps.ClearpayPartiallyMigratedRedirection
      order: Partial<CustomPaymentOrder> & Required<Pick<CustomPaymentOrder, 'orderId'>>
    }

interface PayNowModalContextResult {
  success: boolean
  error: boolean
  message?: string
}

export interface PayNowModalContextProps {
  initialStep: PayNowStepsProps
  step: PayNowStepsProps
  setStep: Dispatch<SetStateAction<PayNowStepsProps>>
  payment: PaymentSetupProps
  setPayment: Dispatch<SetStateAction<PaymentSetupProps>>
  payNowStep: PayNowInputProps
  setPayNowStep: Dispatch<SetStateAction<PayNowInputProps>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  isFetching: boolean
  setIsFetching: Dispatch<SetStateAction<boolean>>
  order: CustomPaymentOrder
  setOrder: Dispatch<SetStateAction<CustomPaymentOrder>>
  paymentMethod: PaymentMethods
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethods>>
  result: PayNowModalContextResult
  setResult: Dispatch<SetStateAction<PayNowModalContextResult>>
  resetState: () => void
  closeModal: () => void
  setIsBankPayment: Dispatch<SetStateAction<boolean>>
  isBankPayment: boolean
  hasAccountLevelPayments: boolean
  setHasAccountLevelPayments: Dispatch<SetStateAction<boolean>>
  isEligibleToUseBankAccount: boolean
  setIsEligibleToUseBankAccount: Dispatch<SetStateAction<boolean>>
  isAchFlowModalOpen: boolean
  closeAchFlowModal: () => void
  openAchFlowModal: () => void
  setAchFlowInitiateStep: Dispatch<SetStateAction<AchStepsProps>>
  isAddCheckingAccountSuccess: boolean
  setIsAddCheckingAccountSuccess: Dispatch<SetStateAction<boolean>>
  isThreedsModalOpen: boolean
  setIsThreedsModalOpen: Dispatch<SetStateAction<boolean>>
  achFlowInitialStep: AchStepsProps
  setLinkedAccountId: Dispatch<SetStateAction<Number>>
  linkedAccountId: Number
}

export const PayNowModalContextDefaults: PayNowModalContextProps = {
  // @ts-ignore: OPERATION BLEED STOPPER
  initialStep: null,
  // @ts-ignore: OPERATION BLEED STOPPER
  step: null,
  setStep: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  payNowStep: null,
  setPayNowStep: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  payment: null,
  setPayment: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isLoading: null, // Is data loading for first time, used to show modal loader
  setIsLoading: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isFetching: null, // Is data fetching (are any endpoints in flight), used in business logic and button states
  setIsFetching: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  order: null,
  setOrder: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  paymentMethod: null,
  setPaymentMethod: noop,
  result: {
    success: false,
    error: false,
  },
  setResult: noop,
  resetState: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  closeModal: null,
  setIsBankPayment: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isBankPayment: null,
  // @ts-ignore: OPERATION BLEED STOPPER
  hasAccountLevelPayments: null,
  setHasAccountLevelPayments: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isEligibleToUseBankAccount: null,
  setIsEligibleToUseBankAccount: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isAchFlowModalOpen: null,
  closeAchFlowModal: noop,
  openAchFlowModal: noop,
  setAchFlowInitiateStep: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isAddCheckingAccountSuccess: null,
  setIsAddCheckingAccountSuccess: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isThreedsModalOpen: null,
  setIsThreedsModalOpen: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  achFlowInitialStep: null,
  setLinkedAccountId: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  linkedAccountId: null,
}

export const PayNowModalContext = createContext<PayNowModalContextProps>(PayNowModalContextDefaults)

export const usePayNowModalContext = (): PayNowModalContextProps => {
  return { ...useContext(PayNowModalContext) }
}

interface PayNowModalProviderProps {
  initialStep?: PayNowStepsProps
  payment?: PaymentSetupProps
  order?: CustomPaymentOrder
  closeModal: () => void
  children: ReactNode
}

export const PayNowModalProvider: FunctionComponent<PayNowModalProviderProps> = ({
  initialStep: defaultStep,
  payment: initialPayment,
  order: initialOrder,
  closeModal,
  children,
}) => {
  const initialStep = defaultStep ?? PayNowSteps.Installments
  const initalResult = useMemo(() => ({ success: false, error: false }), [])
  const [step, setStep] = useState<PayNowStepsProps>(initialStep)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [payment, setPayment] = useState<PaymentSetupProps>(initialPayment ?? null)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [payNowStep, setPayNowStep] = useState<PayNowInputProps>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [order, setOrder] = useState<CustomPaymentOrder>(initialOrder ?? null)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [paymentMethod, setPaymentMethod] = useState<PaymentCardDetails>(null)
  const [result, setResult] = useState<PayNowModalContextResult>(initalResult)
  const [isBankPayment, setIsBankPayment] = useState<boolean>(false)
  const [hasAccountLevelPayments, setHasAccountLevelPayments] = useState<boolean>(false)
  const [isEligibleToUseBankAccount, setIsEligibleToUseBankAccount] = useState<boolean>(false)
  const [isThreedsModalOpen, setIsThreedsModalOpen] = useState<boolean>(false)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [linkedAccountId, setLinkedAccountId] = useState<Number>(null)
  const {
    achFlowInitialStep,
    setAchFlowInitiateStep,
    isAchFlowModalOpen,
    openAchFlowModal,
    closeAchFlowModal,
    isAchSuccess: isAddCheckingAccountSuccess,
    setIsAchSuccess: setIsAddCheckingAccountSuccess,
  } = useACHModal()

  const resetState = useCallback((): void => {
    setStep(initialStep)
    setResult(initalResult)
    setIsLoading(false)
    // @ts-ignore: OPERATION BLEED STOPPER
    setPayment(null)
    setIsAddCheckingAccountSuccess(false)
    // @ts-ignore: OPERATION BLEED STOPPER
    setLinkedAccountId(null)
  }, [initalResult, initialStep])

  const value = {
    initialStep,
    step,
    setStep,
    payment,
    setPayment,
    payNowStep,
    setPayNowStep,
    isLoading,
    setIsLoading,
    isFetching,
    setIsFetching,
    order,
    setOrder,
    paymentMethod,
    setPaymentMethod,
    result,
    setResult,
    resetState,
    closeModal,
    setIsBankPayment,
    isBankPayment,
    hasAccountLevelPayments,
    setHasAccountLevelPayments,
    isEligibleToUseBankAccount,
    setIsEligibleToUseBankAccount,
    isAchFlowModalOpen,
    openAchFlowModal,
    setAchFlowInitiateStep,
    closeAchFlowModal,
    isAddCheckingAccountSuccess,
    setIsAddCheckingAccountSuccess,
    isThreedsModalOpen,
    setIsThreedsModalOpen,
    achFlowInitialStep,
    setLinkedAccountId,
    linkedAccountId,
  }

  return <PayNowModalContext.Provider value={value}>{children}</PayNowModalContext.Provider>
}
