import { useRef, useState } from 'react'
import { useFocusRef } from '../useFocusRef'

interface UseCodeEntry {
  codeEntryRef: React.MutableRefObject<HTMLInputElement>
  codeEntryKey: number
  resetCodeEntry: () => void
}

export const useCodeEntry = (): UseCodeEntry => {
  const codeEntryRef = useRef<HTMLInputElement | undefined>()
  const [codeEntryKey, setCodeEntryKey] = useState(0)
  const resetCodeEntry = (): void => {
    setCodeEntryKey(codeEntryKey + 1)
    // @ts-ignore: OPERATION BLEED STOPPER
    codeEntryRef.current.focus()
  }
  // @ts-ignore: OPERATION BLEED STOPPER
  useFocusRef(codeEntryRef)
  return {
    // @ts-ignore: OPERATION BLEED STOPPER
    codeEntryRef,
    codeEntryKey,
    resetCodeEntry,
  }
}
