import { PaymentMethods } from '../../state/orders/types'
import { CardDefaultValue } from '@afterpaytouch/form'
import { PaymentCustomAmount, PaymentInstallment, PaymentSetupProps } from './PayNowModalContext'

// @ts-expect-error TODO: fix type error
export const isCustomAmountPayment = (payment?: PaymentSetupProps): payment is PaymentCustomAmount => Boolean(payment?.amount)

// @ts-expect-error TODO: fix type error
export const isInstallmentPayment = (payment?: PaymentSetupProps): payment is PaymentInstallment => Boolean(payment?.ids)

export const isNewCreditCardPayment = (payment?: PaymentMethods): payment is CardDefaultValue => Boolean((payment as CardDefaultValue)?.cardNumber)

// @ts-ignore: OPERATION BLEED STOPPER
export const isSingleInstallment = (payment?: PaymentSetupProps): payment is PaymentInstallment => isInstallmentPayment(payment) && payment.ids.length === 1

// Returns the sequence of the installment if there is only one installment selected
export const getSingleInstallmentSequence = (payment?: PaymentSetupProps): number | null => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return isSingleInstallment(payment) ? payment.ids[0].sequence : null
}
