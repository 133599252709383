import React, { FunctionComponent, useState } from 'react'
import { HeaderInfoModal } from '../HeaderInfoModal'
import { CreditLimitButton } from '../CreditLimitButton'
import { OwedAmountButton } from '../OwedAmountButton'

interface ButtonDisplayProps {
  isCreditLimit?: boolean
  isOwedAmount?: boolean
  isUpcomingPaymentsLinkRequired?: boolean
}

export const ButtonDisplay: FunctionComponent<ButtonDisplayProps> = ({
  isCreditLimit = false,
  isOwedAmount = false,
  isUpcomingPaymentsLinkRequired = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = (): void => setIsModalOpen(true)
  const closeModal = (): void => setIsModalOpen(false)

  return (
    <>
      {isCreditLimit && <CreditLimitButton openModal={openModal} />}
      {isOwedAmount && <OwedAmountButton openModal={openModal} isUpcomingPaymentsLinkRequired={isUpcomingPaymentsLinkRequired} />}
      <HeaderInfoModal isModalOpen={isModalOpen} closeModal={closeModal} isCreditLimit={isCreditLimit} isOwedAmount={isOwedAmount} />
    </>
  )
}
