import { Text, Link, Divider } from '@afterpaytouch/core'
import { CreditFacilityDocument } from '@afterpaytouch/portal-api'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'

const I18N_NAMESPACE = ['common', 'payMonthly']

export const PayMonthlyStatement: FunctionComponent<{ pclStatement?: CreditFacilityDocument }> = ({ pclStatement }) => {
  const { t } = useTranslation(I18N_NAMESPACE)

  if (typeof pclStatement === 'undefined' || pclStatement?.url == null || typeof pclStatement?.url === 'undefined') {
    return null
  }

  return (
    <>
      <div className='relative' data-testid='pay-monthly-panel-pclStatement-row'>
        <div className='flex justify-between'>
          <Text bold>{t('payMonthly:panel:content:details:pclStatement')}</Text>
          <Link kind='Underline' href={pclStatement.url} target='_blank' rel='noopener noreferrer' size='S'>
            {t('payMonthly:panel:content:details:view')}
          </Link>
        </div>
      </div>
      <Divider kind='Hairline' margin='Regular' />
    </>
  )
}
