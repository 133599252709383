import { namePattern, requiredValidation, dobValidation, nameMaxLengthValidation } from './../VerifyIdentity/validations'
import { TFunction } from 'next-i18next'
import { AddressFieldSchema, DateOfBirthFieldSchema, FieldType, InputFieldSchema, Schema } from '@afterpaytouch/form'
import { ConsumerAccountResponse, Country } from '@afterpaytouch/portal-api/types'
import { InputAddressData } from '@afterpaytouch/core'
import { app } from '../../../env'
import { SupportedLocale, ProfileErrorCode } from '../../../model'
import { requiredAddress } from './validations'
import { getAddressOptionalFields } from '../../../utils/profile-validation'

// TODO combine sign up and verify retry to use one schema with dynamic values

interface GetSchemaAndFormDataResult {
  schema: Schema<VerifyRetryForm>
  initialFormData: VerifyRetryForm
}

export interface VerifyRetryForm {
  givenNames: string
  otherGivenNames: string
  surname: string
  dateOfBirth: string
  address: InputAddressData
  footer?: boolean
  profileError?: boolean
}

const getInputFieldSchema = ({ value, label, error = false, options = {}, inline = false }): InputFieldSchema => ({
  type: FieldType.Input,
  show: true,
  props: {
    label,
    value,
    error,
  },
  options,
  inline,
})

const getInputBirthdaySchema = ({ locale, label, value, options, otherValidations = {}, otherProps = {} }): DateOfBirthFieldSchema => ({
  type: FieldType.DateOfBirth,
  show: true,
  props: {
    label,
    defaultValue: value,
    locale: locale as SupportedLocale,
    ...otherProps,
  },
  options,
  ...otherValidations,
})

const getInputAddressSchema = ({ value, error = false, otherProps = {}, options = {} }): AddressFieldSchema => ({
  type: FieldType.Address,
  apiKey: app.GOOGLEMAPS_API_KEY,
  show: true,
  props: {
    initialAddress: value,
    ...otherProps,
  },
  options,
})

const getFormData = (consumer: ConsumerAccountResponse): VerifyRetryForm => {
  const { id, name, addressType, ...otherContactAddress } = { ...consumer?.contactAddress }
  const address = {
    address1: otherContactAddress?.address1 ?? '',
    address2: otherContactAddress?.address2 ?? '',
    area2: otherContactAddress?.area2 ?? '',
    suburb: otherContactAddress?.suburb ?? '',
    state: otherContactAddress?.state ?? '',
    postcode: otherContactAddress?.postcode ?? '',
    countryCode: otherContactAddress?.countryCode ?? '',
  }

  return {
    givenNames: consumer?.givenNames ?? '',
    otherGivenNames: consumer?.otherGivenNames ?? '',
    surname: consumer?.surname ?? '',
    dateOfBirth: consumer?.dateOfBirth ?? '',
    address,
  }
}

export const getSchemaAndFormData = (
  consumer: ConsumerAccountResponse,
  components: any,
  t: TFunction,
  country: Country,
  hasAddressError: boolean,
  profileError: ProfileErrorCode
): GetSchemaAndFormDataResult => {
  const initialFormData = getFormData(consumer)
  const { givenNames, otherGivenNames, surname, dateOfBirth, address } = initialFormData
  const { preferredLocale, countryCode } = consumer

  const isSameProfileInfoWarning = profileError === ProfileErrorCode.SameProfileInfoWarning

  const required = requiredValidation(t)
  const namePatterns = namePattern(t)
  const dobValidateOptions = dobValidation(t)
  const nameMaxLengthOptions = nameMaxLengthValidation(t)

  const schema: Schema<VerifyRetryForm> = {
    givenNames: getInputFieldSchema({
      label: t('verifyIdentity:retry:givenNames'),
      value: givenNames,
      error: isSameProfileInfoWarning,
      options: {
        ...required('givenNames'),
        ...namePatterns('givenNames'),
        ...nameMaxLengthOptions('givenNames', countryCode),
      },
      inline: true,
    }),
    otherGivenNames: getInputFieldSchema({
      label: t('verifyIdentity:retry:otherGivenNames'),
      value: otherGivenNames,
      error: isSameProfileInfoWarning,
      options: {
        ...namePatterns('otherGivenNames'),
        ...nameMaxLengthOptions('otherGivenNames', countryCode),
      },
      inline: true,
    }),
    surname: getInputFieldSchema({
      label: t('verifyIdentity:retry:surname'),
      value: surname,
      error: isSameProfileInfoWarning,
      options: {
        ...required('surname'),
        ...namePatterns('surname'),
        ...nameMaxLengthOptions('surname', countryCode),
      },
    }),
    dateOfBirth: getInputBirthdaySchema({
      value: dateOfBirth,
      locale: preferredLocale,
      label: t('verifyIdentity:retry:DOB'),
      options: { ...required('dateOfBirth') },
      otherValidations: dobValidateOptions('dateOfBirth'),
    }),
    address: getInputAddressSchema({
      value: address,
      otherProps: {
        label: t('signUp:address'),
        manualAddressLabel: t('signUp:findAddressLabel'),
        address1Label: t('signUp:address1Label'),
        address2Label: t('signUp:address2Label'),
        postcodeLabel: t('signUp:postcodeLabel'),
        searchAddressLabel: t('signUp:searchAddressLabel'),
        componentRestrictions: { country },
        suburbLabel: t('signUp:suburbLabel'),
        stateLabel: t('signUp:stateLabel'),
        optionalManualAddressFields: getAddressOptionalFields(country),
        error: isSameProfileInfoWarning || profileError === ProfileErrorCode.InvalidAddress || hasAddressError,
      },
      options: {
        ...requiredAddress({ name: 'address', country, t }),
      },
    }),
    profileError: {
      type: FieldType.Component,
      Component: components.profileError,
      show: typeof profileError !== 'undefined' && !isSameProfileInfoWarning,
    },
    footer: {
      type: FieldType.Component,
      Component: components.footer,
    },
  }

  return { schema, initialFormData }
}

export const transformToBackend = (data: VerifyRetryForm): VerifyRetryForm => {
  const { address, ...otherData } = data
  const { postcode, ...otherAddress } = address

  // Note: backend trim postcode in PAYL-17587, PAYL-17548
  // In order to have a successful data comparison in VerifyRetry, removing space in postcode
  return {
    ...otherData,
    address: {
      ...otherAddress,
      postcode: postcode.replace(/ /g, ''),
    },
  }
}
