import { BankDetailsFromPlaid, PaymentMethodType, PaymentSourceCaptureCardTypes, PaymentType } from '@afterpay/types'
import {
  LoyaltyTierBenefit,
  SupportedLocale,
  DisputePermissionError,
  SelectClosePayMonthlyReasonProps,
  OrderSource,
  OrderChannel,
} from '@afterpaytouch/portal-api'
import { EntryPointProps } from '../../component/AccountTabs/PaymentMethods/PreferredDayModal/types'
import { PayNowInputProps } from '../../component/PayNowModal/PayNowModalContext'
import { CardScanAmplitudeErrorReason, CardScanCheckpoint } from '../../model'

export enum PageNames {
  INDEX = 'Login',
  LOGIN_PASSWORD = 'Login Password',
  CREATE_PASSWORD_VERIFY = 'Create Password Verify',
  CREATE_PASSWORD = 'Create Password',
  HOME = 'Home',
  UPCOMING_PAYMENTS = 'Upcoming payments',
  ORDERS = 'Orders',
  ORDER = 'Order Details',
  HELP = 'Help',
  ACCOUNT = 'Account',
  SHOP = 'Shop',
  LOGOUT = 'Log Out',
  PPA = 'PPA',
  LEGACY = 'Legacy',
  RECURRING_PAYMENTS = 'Recurring payments',
  UNSUBSCRIBE = 'Unsubscribe',
  VERIFY_IDENTITY = 'HardID Landing',
  VERIFY_RETRY = 'Verify retry',
  VERIFY_LICENCE = 'HardID Drivers Licence',
  VERIFY_PASSPORT = 'HardID Passport',
  VERIFY_MEDICARE = 'HardID Medicare',
  RETRY_VERIFY_HARD_ID = 'HardID Retry',
  SIGNUP_RETRY = 'Signup Retry',
  FORCE_PASSWORD_RESET = 'Force password reset',
  PROTECT_ACCOUNT = 'Protect account',
  CONFIRM_ORDER = 'Confirm order',
  PAY_MONTHLY = 'Pay Monthly',
  PAY_MONTHLY_DECREASE_LIMIT = 'Decrease my limit',
  ERROR = 'Error',
  PROFILE_CONFIRM = 'Profile Confirm',
  VERIFY_2FA = 'Verify 2FA',
  VERIFY_CHALLENGE = 'Verify Challenge',
  UNSUITABILITY_QUESTIONS = 'Unsuitability Questions',
  CONTRACT_AGREEMENT = 'Contract Agreement',
}

export const AmplitudeEventTypes = {
  ALIGN_PAY_DAY: {
    MODAL_VIEWED: 'Align Pay Day - Pop Up Choose a Preferred Day Viewed',
    MODAL_CTA_CLICKED: 'Align Pay Day - Pop Up Choose a Preferred Day Clicked',
    PREFERRED_DAY_CLICKED: 'Align Pay Day - Preferred Day Clicked',
    OK_BUTTON_CLICKED: 'Align Pay Day - OK Button Clicked',
    ERROR_TOAST_VIEWED: 'Align Pay Day - Error Message Viewed (Toast)',
    ERROR_MODAL_VIEWED: 'Align Pay Day - Error Message Viewed (Modal)',
    SET_PREFERRED_DATE_OPEN_MODAL: 'Align Pay Day - Open Choose Preferred Day Modal - No Day Currently Active',
    OPEN_CHANGE_PREFERRED_DAY_MENU: 'Align Pay Day - Setting Preferred Payment Day - Open Menu Clicked',
    CHANGE_PREFERRED_DAY: 'Align Pay Day - Clicked Change Preferred Payment Day',
    TURN_OFF_PREFERRED_DAY: 'Align Pay Day - Clicked Turn Off Preferred Payment Day',
    TURN_OFF_PREFERRED_DAY_CONFIRM: 'Align Pay Day - Confirm Turning Off Preferred Payment Day',
    FEATURE_DISPLAYED: 'Align Pay Day - Choose a Preferred Payment Day Viewed In Settings',
  },
  ACH: {
    ClickAddCardInAddBankAccountError: 'Selected Bank Account Error - Eligibility - Add Card',
    ClickedAddBankAccountInfoContinue: 'Selected Add Bank Account Info - Continue',
    ClickedTryAgainInLinkBankAccountError: 'Selected Bank Account Error - Account Select - Try Again',
    SelectedBankAccount: 'Selected Select Bank Account - Account',
    SelectedPaymentMethodType: 'Selected Payment Method',
    ViewAddBankAccountError: 'Viewed Bank Account Error - Add Bank - Linking',
    ViewAddBankAccountOauthError: 'Viewed Bank Account Oauth Error - Add Bank - Linking',
    ViewAddBankAccountInfo: 'Viewed Add Bank Account Info',
    ViewBankAccountErrorGeneric: 'Viewed Bank Account Error - Account Select - Generic',
    ViewBankAccountErrorNoAccount: 'Viewed Bank Account Error - Account Select - No Accounts',
    ViewSelectNewPaymentMethod: 'Viewed Select New Payment Method',
    ViewedBankAccountErrorEligibility: 'Viewed Bank Account Error - Eligibility',
    ViewedBankAccountList: 'Viewed Select Bank Account', // TODO: Remove - unused
    ViewedLinkedCheckingAccountError: 'Selected Bank Account Error - Account Select - Generic',
    ViewedLinkedCheckingAccountSuccess: 'Viewed Success Link Checking Account',
    SelectedContinueInPlaidSuccess: 'Selected Continue In Plaid Success',
    SelectedContinueInPlaidOauthSuccess: 'Selected Continue In Plaid Oauth Success',
    RelinkBankAccountInPlaidError: 'Relinked Bank Account In Plaid Error',
    RelinkBankAccountInPlaidSuccess: 'Relinked Bank Account In Plaid Success',
    RelinkBankAccountInPlaidOauthError: 'Relinked Bank Account In Plaid Oauth Error',
    RelinkBankAccountInPlaidOauthSuccess: 'Relinked Bank Account In Plaid Oauth Success',
  },
  CLICKED_ACCOUNT: 'Clicked Homepage Navigation - Account',
  CLICKED_ADD_PAYMENT_METHOD: 'Clicked Add Payment Method',
  CLICKED_CONFIRM_TURN_AUTOPAY_ON: 'Clicked Confirm Turn Autopay On',
  CLICKED_CONFIRM_TURN_AUTOPAY_OFF: 'Clicked Confirm Turn Autopay Off',
  CLICKED_CHANGE_BILLING_AGREEMENT_PAYMENT_METHOD: 'Clicked Change Billing Agreement Payment Method',
  CLICKED_CANCEL_BILLING_AGREEMENT: 'Clicked Cancel Recurring Payment',
  CLICKED_CHANGE_ORDER_PAYMENT_METHOD: 'Clicked Change Order Payment Method',
  CLICKED_CHANGE_PAYMENT_DATE: 'Clicked Change Payment Date',
  CLICKED_CHANGE_PAYMENT_DATE_FAQS: 'Clicked Change Payment Date FAQs',
  CLICKED_CONFIRM_CUSTOM_PAYMENT_AMOUNT: 'Clicked Confirm Custom Payment Amount',
  CLICKED_CONFIRM_PAYMENT_AMOUNT: 'Clicked Confirm Payment Amount',
  CLICKED_CONFIRM_PAYMENT_DATE_CHANGE: 'Clicked Confirm Payment Date Change',
  VIEWED_PULSE_BENEFIT_MODAL: 'Viewed Pulse Benefit Modal',
  VIEWED_PULSE_INTRO_MODAL: 'Viewed Pulse Intro Modal',
  CLICKED_PULSE_REWARDS_ACCOUNT_PAGE: 'Clicked Account - Pulse Rewards',
  VIEWED_PULSE_REWARDS_PAGE: 'Viewed Account - Pulse Rewards',
  CLICKED_PULSE_INTRO_MODAL_BUTTON: 'Pulse Rewards Popup - Clicked Pulse Intro Modal Button',
  VIEWED_WELCOME_MODAL: 'Viewed Welcome Modal',
  CLICKED_WELCOME_MODAL_BUTTON: 'Clicked Welcome Modal Button',
  CLICKED_MOBILE_APP_PULSE_DEEPLINK: 'Clicked Mobile App Pulse Deeplink',
  CLICKED_EDIT_ADDRESS: 'Clicked Edit Address',
  CLICKED_EDIT_EMAIL: 'Clicked Edit Email',
  CLICKED_EDIT_LANGUAGE: 'Clicked Edit Language',
  CLICKED_EDIT_MOBILE: 'Clicked Edit Mobile',
  CLICKED_EDIT_PASSWORD: 'Clicked Edit Password',
  PRESSED_AUTOPAY_ON: 'Pressed Autopay On',
  CLICKED_GET_APP: 'Clicked Get App',
  CLICKED_HELP: 'Clicked Homepage Navigation - Help',
  CLICKED_HOME: 'Clicked Homepage Navigation - Home',
  CLICKED_INVITE_FRIEND: 'Clicked Invite a Friend',
  CLICKED_LEARN_MORE_MONTHLY_PAYMENTS: 'Clicked Homepage Navigation - Learn more Monthly Payments',
  CLICKED_MAKE_A_CUSTOM_PAYMENT: 'Clicked Make a Custom Payment',
  CLICKED_MAKE_A_PAYMENT: 'Clicked Make A Payment',
  CLICKED_MAKE_A_PAYMENT_CONFIRM_AMOUNT: 'Make a Payment - Clicked Confirm Amount',
  CLICKED_MAKE_A_PAYMENT_CONFIRM_AMOUNT_FAILURE: 'Clicked Make a Payment - Confirm Amount Failure',
  CLICKED_MAKE_A_PAYMENT_CONFIRM_CUSTOM_PAYMENT_AMOUNT_FAILURE: 'Clicked Make a Payment - Confirm Custom Payment Amount Failure',
  CLICKED_MAKE_A_PAYMENT_SUBMIT_PAYMENT: 'Clicked Submit Payment - Confirm Payment',
  CLICKED_MAKE_A_PAYMENT_SUBMIT_PAYMENT_FAILURE: 'Make a payment - failed payment modal/toast',
  CLICKED_MAKE_A_PAYMENT_SUBMIT_PAYMENT_SUCCESS: 'Make a payment - successsful payment modal/toast',
  CLICKED_MAKE_A_PAYMENT_NEW_PAYMENT_METHOD: 'Make a payment - new payment method',
  CLICKED_NEED_HELP: 'Clicked Need Help',
  CLICKED_ORDER_DETAIL: 'Clicked Order Detail',
  CLICKED_ORDERS: 'Clicked Homepage Navigation - Clicked Orders',
  CLICKED_ORDERS_LIST_PAGINATION: 'Clicked Orders List Pagination',
  CLICKED_PAY_NOW: 'Clicked Pay Now',
  CLICKED_PAY_MONTHLY: 'Clicked Pay Monthly',
  CLICKED_PAYMENT_AUTHORISATION_LINK: 'Clicked Payment Authorisation Link',
  CLICKED_REPORT_A_PROBLEM: 'Clicked Report a Problem',
  CLICKED_IM_RETURNING_MY_ORDER: "Clicked I'm Returning My Order",
  CLICKED_RETURNING_ORDER_BY_MAIL: 'Clicked Returning Order By Mail',
  CLICKED_RETURNING_ORDER_IN_STORE: 'Clicked Returning Order In Store',
  CLICKED_RECURRING_PAYMENTS: 'Clicked Homepage Navigation - Clicked Recurring Payments',
  CLICKED_REMOVE_CARD: 'Clicked Remove Card',
  CLICKED_SHOP: 'Clicked Homepage Navigation - Shop',
  CLICKED_SHOP_NOW: 'Clicked Shop Now',
  CLICKED_SHOP_TILE: 'ShopDirectory - Store - Click',
  CLICKED_SHOP_SEE_MORE: 'ShopDirectory - Clicked See More',
  CLICKED_SUBMIT_PAYMENT: 'Clicked Submit Payment',
  CLICKED_SWITCH_PORTAL: 'Clicked Homepage Navigation - Switch Afterpay Portal',
  CLICKED_AUTOPAY_TOGGLE: 'Clicked Autopay Toggle',
  CLICKED_UPCOMING_PAYMENTS: 'Clicked Homepage Navigation - Upcoming Payments',
  CLICKED_UPDATE_CONTACT_DETAILS: 'Clicked Update Contact Details',
  CLICKED_UPDATE_PREFERRED_CARD: 'Clicked Update Preferred Card',
  CLICKED_UPDATE_SECURITY_DETAILS: 'Clicked Update Security Details',
  CLICKED_VIEW_BILLING_ADDRESS: 'Clicked View Billing Address',
  CLICKED_VERIFY_NOW: 'Clicked Verify Now',
  CLICKED_UNSUBSCRIBE_EMAIL: 'Clicked Unsubscribe Email',
  CLOSED_AUTOPAY_MODAL: 'Closed Autopay Modal',
  CREDIT_FACILITY: {
    ViewedClFacility: 'Viewed CL Facility',
    PressedDecreaseClFacility: 'Pressed Decrease CL Facility',
    ConfirmDecreaseClFacility: 'Confirm Decrease CL Facility',
    CancelDecreaseClFacility: 'Cancel Decrease CL Facility',
    PressedCloseClFacility: 'Pressed Close CL Facility',
    PressedPayClOrders: 'Pressed Pay CL Orders',
    ConfirmCloseClFacility: 'Confirm Close CL Facility',
  },
  UNSUBSCRIBE_EMAIL_FAILED: 'Unsubscribe Email Failed',
  UNSUBSCRIBE_EMAIL_SUCCESS: 'Unsubscribe Email Success',
  DELETE_CARD_FAILED: 'Delete Card Failed',
  EDIT_ADDRESS_ERROR: 'Edit Address Error',
  EDIT_ADDRESS_SUCCESS: 'Edit Address Success',
  EDIT_EMAIL_ERROR: 'Edit Email Error',
  EDIT_EMAIL_SUCCESS: 'Edit Email Success',
  EDIT_MOBILE_ERROR: 'Edit Mobile Error',
  EDIT_MOBILE_SUCCESS: 'Edit Mobile Success',
  EDIT_PASSWORD_ERROR: 'Edit Password Error',
  EDIT_PASSWORD_SUCCESS: 'Edit Password Success',
  EDIT_LANGUAGE_SUCCESS: 'Edit Language Success',
  EDIT_LANGUAGE_ERROR: 'Edit Language Error',
  ENABLE_AUTOPAY_FAILED: 'Enable Autopay Failed',
  INDEX_PAGE_LOADED: `${PageNames.INDEX} page loaded`,
  NEW_CARD_ADDED_3DS_FAILED: 'New Card Added 3DS Failed',
  NEW_CARD_ADDED_3DS_PASSED: 'New Card Added 3DS Passed',
  PORTAL_APPLE_PAY_ERROR: 'Portal Apple Pay Error',
  PRESSED_BACK_IN_CARD_SCAN: 'Pressed Back to add payment method',
  PRESSED_CARD_SCAN_ADD_PAYMENT: 'Pressed Scan Card - Add Payment',
  PRESSED_CLOSE_BROWSER_TAB: 'Pressed Close on Browser Tab',
  PRESSED_DISPUTE_INFO_ACCEPT: 'Dispute Detail Accept This Dispute - New',
  PRESSED_DISPUTE_INFO_CANCEL: 'Dispute Detail Cancel This Dispute - New',
  PRESSED_DISPUTE_INFO_REJECT: 'Dispute Detail Reject This Dispute - New',
  PRESSED_DISPUTE_INFO_REJECT_SUBMIT: 'Dispute Detail Reject This Dispute Submit - New',
  SCAN_PAYMENT_FAILED_ADD_PAYMENT: 'Scan Payment Failed - Add Payment',
  SCAN_PAYMENT_MATCHED_ADD_PAYMENT: 'Scan Payment Matched - Add Payment',
  SET_PREFERRED_APPLE_PAY_ERROR: 'Set Apple Pay as preferred Failed',
  SET_PREFERRED_CARD_FAILED: 'Set Preferred Card Failed',
  SUBMIT_SUCCESS_FOR_RETURNING_ORDER_BY_MAIL: 'Submit Success For Returning Order By Mail',
  SUBMIT_FAILURE_FOR_RETURNING_ORDER_BY_MAIL: 'Submit Failure For Returning Order By Mail',
  TOGGLE_MARKETING_EMAILS: 'Toggled Marketing Emails',
  UPDATE_PAYMENT_METHOD_IN_BILLING_AGREEMENT_FAILED: 'Update payment method in billing agreement failed',
  UPDATE_PAYMENT_METHOD_IN_ORDER_FAILED: 'Update payment method in order failed',
  CANCEL_BILLING_AGREEMENT_FAILED: 'Cancel recurring payment failed',
  VIEWED_3DS_VERIFICATION_INITIATE: 'Viewed 3DS verification Initiate',
  VIEWED_3DS_VERIFICATION_INITIATE_FAILED: 'Viewed 3DS verification Initiate failed',
  VIEWED_CARD_SCAN_ADD_PAYMENT: 'Viewed Card Scan - Add Payment',
  VIEWED_CHANGE_PAYMENT_DATE_ERROR: 'Viewed Change Payment Date Error',
  VIEWED_DISPUTE_INFO_DETAIL: 'Viewed Dispute Detail - New',
  VIEWED_DISPUTE_INFO_PROCESS_FAILURE: 'Viewed Dispute Detail Error Message - New',
  VIEWED_DISPUTE_INFO_PROCESS_SUCCESS: 'Viewed Dispute Detail Success Message - New',
  VIEWED_DISPUTE_INFO_REJECT: 'Viewed Dispute Detail Upload Evidence - New',
  VIEWED_FAILED_PAYMENT_MODAL: 'View Failed Payment modal/toast',
  VIEWED_NEW_AUTOPAY_BANNER: 'Viewed New Autopay Banner',
  VIEWED_AUTOPAY_MODAL: 'Viewed Autopay Modal',
  VIEWED_ORDER_DETAILS_PAGE: 'Viewed Order Details Page',
  VIEWED_ORDERS_PAGE: `Viewed ${PageNames.ORDERS} Page`,
  VIEWED_RECURRINGPAYMENT_DETAILS_PAGE: 'Viewed Recurring Payment Details Page',
  VIEWED_MAKE_A_PAYMENT_INSTALLMENTS: 'Viewed Make A Payment Modal - Installments',
  VIEWED_MAKE_A_PAYMENT_PAYMENT: 'Viewed Make A Payment Modal - Payment',
  CHANGE_SELECTED_PAYMENT_TYPE: 'Make A Payment Modal - Change Selected Payment Type',
  CHANGE_PAYMENT_METHOD: 'Make A Payment Modal - Payment - Switch Payment Method',
  VIEWED_ADD_PAYMENT_METHOD_SUCCESS_TOAST: 'Viewed Add Payment Method Success Toast',
  PRESSED_REPORT_CARD_CANCELLATION: 'Report Lost And Stolen Card Button Click - New',
  PRESSED_REPORT_CARD_CANCELLATION_ALL: 'Remove All Cards Button Click - New',
  PRESSED_REPORT_CARD_CANCELLATION_PARTIAL: 'Remove Partial Cards Button Click - New',
  REPORT_CARD_CANCELLATION_SUCCESS_ALL: 'Remove All Cards Success - New',
  REPORT_CARD_CANCELLATION_SUCCESS_PARTIAL: 'Remove Partial Cards Success - New',
  REPORT_CARD_CANCELLATION_DECLINE: 'Report Lost And Stolen Card Decline - New',
  VIEWED_SUCCESSFUL_PAYMENT_MODAL: 'View Successful Payment modal/toast',
  PRESSED_INITIAL_REPORTING_PROBLEM: 'Pressed Initial Reporting Problem help - New',
  VIEWED_DISPUTE_PERMISSION_CHECKER: 'Viewed Dispute Permission Checker',
  VIEWED_CREATE_DISPUTE_SELECT_PROBLEM: 'Viewed Create Dispute Select Problem Type - New',
  VIEWED_CREATE_DISPUTE_CONTACT_MERCHANT: 'Viewed Create Dispute Contact Merchant - New',
  VIEWED_CREATE_DISPUTE_ORDER_MISMATCH_CONFIRMATION: 'Viewed Create Dispute Order Mismatch Confirmation',
  VIEWED_CREATE_DISPUTE_CONTACT_MERCHANT_INFO: 'Viewed Create Dispute Contact Merchant Info - New',
  VIEWED_CREATE_DISPUTE_SUBMIT_EVIDENCE: 'Viewed Create Dispute Submit Evidence - New',
  VIEWED_CREATE_DISPUTE_ALIBABA_FLOW: 'Viewed Create Dispute Alibaba Flow - New',
  VIEWED_CREATE_DISPUTE_CUSTOM_DECLINE_FLOW: 'Viewed Create Dispute Custom Decline Flow - New',
  VIEWED_CREATE_DISPUTE_SUCCESS: 'Viewed Create Dispute Success - New',
  VIEWED_CREATE_DISPUTE_FAILURE: 'Viewed Create Dispute Failure - New',
  PRESSED_CREATE_DISPUTE_SELECT_PROBLEM_CTA: 'Pressed Create Dispute Select Problem CTA - New',
  PRESSED_CREATE_DISPUTE_CONTACT_MERCHANT: 'Pressed Create Dispute Contact Merchant - New',
  PRESSED_CREATE_DISPUTE_CONTACT_MERCHANT_INFO: 'Pressed Create Dispute Contact Merchant Info - New',
  PRESSED_CREATE_DISPUTE_CANCEL_CONTACT_MERCHANT: 'Pressed Create Dispute Cancel Contact Merchant - New',
  PRESSED_CREATE_DISPUTE_ORDER_MISMATCH_CONFIRM_YES: 'Pressed Create Dispute Order Mismatch Yes',
  PRESSED_CREATE_DISPUTE_ORDER_MISMATCH_CONFIRM_NO: 'Pressed Create Dispute Order Mismatch No',
  PRESSED_OPEN_PAYMENT_RESCHEDULE_MODAL: 'Pressed Open Payment Reschedule Modal',
  PRESSED_BIOMETRIC_LOGIN_TURN_OFF: 'Pressed Biometric Login Turn Off',
  PRESSED_EDIT_ADDRESS_IN_REACTIVATE_ACCOUNT_FLOW: 'Pressed Edit Address In Reactivate Account Flow',
  PRESSED_SOFTID_LANDING_PAGE_ADDRESS_VERIFY_BUTTON: 'Pressed SoftID Landing Page Address Verify Button',
  PRESSED_HARDID_RETRY_PAGE_VERIFY_BUTTON: 'Pressed HardID Retry Page Verify Button',
  PRESSED_HARDID_DRIVERS_LICENCE_PAGE_VERIFY_BUTTON: 'Pressed HardID Drivers Licence Page Verify Button',
  PRESSED_HARDID_PASSPORT_PAGE_VERIFY_BUTTON: 'Pressed HardID Passport Page Verify Button',
  PRESSED_HARDID_MEDICARE_PAGE_VERIFY_BUTTON: 'Pressed HardID Medicare Page Verify Button',
  VIEWED_NON_TERMINAL_ERROR: 'Viewed Non-terminal error',
  PRESSED_HARDID_LANDING_PAGE_HELP_LINK: 'Pressed HardID Landing Page Help Link',
  PRESSED_HARDID_LANDING_PAGE_DRIVERS_LICENCE_BUTTON: 'Pressed HardID Landing Page Licence Button',
  PRESSED_HARDID_LANDING_PAGE_MEDICARE_BUTTON: 'Pressed HardID Landing Page Medicare Button',
  PRESSED_HARDID_LANDING_PAGE_PASSPORT_BUTTON: 'Pressed HardID Landing Page Passport Button',
  VIEWED_VERIFICATION_SUCCESS: 'Viewed Verification Success',
  PRESSED_HARDID_RETRY_PAGE_HELP_LINK: 'Pressed HardID Retry Page Help Link',
  GENERATE_MOBILE_APP_QR_CODE_SUCCESS: 'Generate Mobile APP QR Code Success',
  GENERATE_MOBILE_APP_QR_CODE_ERROR: 'Generate Mobile APP QR Code Error',
  TERMINATION_NOTICE: {
    CLOSE_BUTTON_CLICKED: 'Termination Notice Modal - Close Button Clicked',
    MODAL_VIEWED: 'Termination Notice Modal - Viewed',
  },
  TOGGLE_AUTOPAY_SUCCESS: 'Toggle Autopay Success',
  TOGGLE_AUTOPAY_FAILURE: 'Toggle Autopay Failure',
  PRESSED_CONTRACT_AGREEMENT_PAGE_CREDIT_CONTRACT_LINK: `Pressed ${PageNames.CONTRACT_AGREEMENT} Page Credit Contract Link`,
  // account closure
  PRESSED_CLOSE_MY_ACCOUNT: 'Pressed close my account',
  VIEWED_CLOSE_MY_ACCOUNT: 'Viewed close my account',
  SELECTED_CLOSE_ACCOUNT_REASON: 'Selected close account reason',
  VIEWED_BEFORE_YOU_GO: 'Viewed before you go',
  PRESSED_NO_CLOSE_MY_ACCOUNT: 'Pressed no close my account',
  SELECTED_DEACTIVATE_ACCOUNT: 'Selected deactivate account',
  SELECTED_REDUCE_SPENDING_LIMIT: 'Selected reduce spending limit',
  VIEWED_CLOSE_ACCOUNT_CONFIRM: 'Viewed close account confirm',
  PRESSED_CONFIRM_TO_CLOSE_MY_ACCOUNT: 'Pressed confirm to close my account',
  VIEWED_CLOSE_MY_ACCOUNT_SUCCESS_TOAST: 'Viewed close my account success toast',
  VIEWED_CLOSE_MY_ACCOUNT_ERROR: 'Viewed close my account error',
  VIEWED_CONFIRM_DEACTIVATE_MY_ACCOUNT: 'Viewed confirm deactivate my account',
  PRESSED_CONFIRM_DEACTIVATE_MY_ACCOUNT: 'Pressed confirm deactivate my account',
  VIEWED_DEACTIVATE_ACCOUNT_SUCCESS_TOAST: 'Viewed deactivate account success toast',
  VIEWED_LOGIN_PAGE: 'Viewed Login Page',
  PRESSED_CONTINUE_ON_LOGIN_PAGE: 'Pressed Continue on Login Page',
  USER_LOOK_UP_ON_LOGIN_PAGE: 'User Look Up on Login Page',
  CROSS_REGION_USER_REDIRECT_ON_LOGIN_PAGE: 'Cross Region User Redirect on Login Page',
  CLICKED_RETAILER_LINK: 'Clicked Retailer Link',
  PRESSED_CONTINUE_BUTTON_ON_PASSWORD_PAGE: 'Pressed Continue Button on Password Page',
  CLICKED_NOT_YOU: 'Clicked Not You',
  CLICKED_FORGOT_PASSWORD: 'Clicked Forgot Password',
  SEND_CODE_ON_CREATE_PASSWORD_VERIFY: 'Send Code on Create Password Verify',
  PRESSED_RESEND_CODE: 'Pressed Resend Code',
  PRESSED_CONTINUE_ON_PROFILE_CONFIRM_PAGE: 'Pressed Continue on Profile Confirm Page',
  PRESSED_MANUAL_ADDRESS_TOGGLE: 'Pressed Manual Address toggle',
  CLICKED_TERM_LINK: 'Clicked Term Link',
  CLICKED_CONTINUE_ON_CREATE_PASSWORD: 'Clicked Continue on Create Password',
  CLICK_DOWNLOAD_MOBILE_APP: 'Clicked Download Mobile App',
  LOGIN_ERROR: 'Login Error',
  TYPED_EMAIL_ON_LOGIN_PAGE: 'Typed Email on Login Page',
  CONSUMER_LOST_EMAIL: 'Consumer Lost Email',
} as const
export type AmplitudeEventType = (typeof AmplitudeEventTypes)[keyof typeof AmplitudeEventTypes]

export interface AmplitudeEventTypesProperties {
  [AmplitudeEventTypes.ALIGN_PAY_DAY.MODAL_VIEWED]: {}
  [AmplitudeEventTypes.ALIGN_PAY_DAY.MODAL_CTA_CLICKED]: {}
  [AmplitudeEventTypes.ALIGN_PAY_DAY.PREFERRED_DAY_CLICKED]: { preferredDay: string; route: EntryPointProps; previousChoice: string | undefined }
  [AmplitudeEventTypes.ALIGN_PAY_DAY.OK_BUTTON_CLICKED]: { preferredDay: string; route: EntryPointProps; success: boolean }
  [AmplitudeEventTypes.ALIGN_PAY_DAY.ERROR_TOAST_VIEWED]: { route: EntryPointProps }
  [AmplitudeEventTypes.ALIGN_PAY_DAY.ERROR_MODAL_VIEWED]: { route: EntryPointProps }
  [AmplitudeEventTypes.ALIGN_PAY_DAY.SET_PREFERRED_DATE_OPEN_MODAL]: {}
  [AmplitudeEventTypes.ALIGN_PAY_DAY.OPEN_CHANGE_PREFERRED_DAY_MENU]: { preferredDay: string }
  [AmplitudeEventTypes.ALIGN_PAY_DAY.CHANGE_PREFERRED_DAY]: { preferredDay: string }
  [AmplitudeEventTypes.ALIGN_PAY_DAY.TURN_OFF_PREFERRED_DAY]: { preferredDay: string }
  [AmplitudeEventTypes.ALIGN_PAY_DAY.TURN_OFF_PREFERRED_DAY_CONFIRM]: { success: boolean }
  [AmplitudeEventTypes.ALIGN_PAY_DAY.FEATURE_DISPLAYED]: {}
  [AmplitudeEventTypes.ACH.ClickAddCardInAddBankAccountError]: {}
  [AmplitudeEventTypes.ACH.ClickedAddBankAccountInfoContinue]: {}
  [AmplitudeEventTypes.ACH.ClickedTryAgainInLinkBankAccountError]: {}
  [AmplitudeEventTypes.ACH.SelectedBankAccount]: { account: BankDetailsFromPlaid }
  [AmplitudeEventTypes.ACH.SelectedPaymentMethodType]: { paymentMethod: 'card' | 'bank' }
  [AmplitudeEventTypes.ACH.ViewAddBankAccountError]: {}
  [AmplitudeEventTypes.ACH.ViewAddBankAccountOauthError]: {}
  [AmplitudeEventTypes.ACH.ViewAddBankAccountInfo]: { accounts: BankDetailsFromPlaid[] }
  [AmplitudeEventTypes.ACH.ViewBankAccountErrorGeneric]: {}
  [AmplitudeEventTypes.ACH.ViewBankAccountErrorNoAccount]: {}
  [AmplitudeEventTypes.ACH.ViewSelectNewPaymentMethod]: {}
  [AmplitudeEventTypes.ACH.ViewedBankAccountErrorEligibility]: {}
  [AmplitudeEventTypes.ACH.ViewedLinkedCheckingAccountError]: {}
  [AmplitudeEventTypes.ACH.ViewedLinkedCheckingAccountSuccess]: {}
  [AmplitudeEventTypes.ACH.SelectedContinueInPlaidSuccess]: {}
  [AmplitudeEventTypes.ACH.SelectedContinueInPlaidOauthSuccess]: {}
  [AmplitudeEventTypes.ACH.RelinkBankAccountInPlaidError]: {}
  [AmplitudeEventTypes.ACH.RelinkBankAccountInPlaidOauthError]: {}
  [AmplitudeEventTypes.ACH.RelinkBankAccountInPlaidSuccess]: {}
  [AmplitudeEventTypes.ACH.RelinkBankAccountInPlaidOauthSuccess]: {}
  [AmplitudeEventTypes.CREDIT_FACILITY.ViewedClFacility]: {}
  [AmplitudeEventTypes.CREDIT_FACILITY.PressedDecreaseClFacility]: {}
  [AmplitudeEventTypes.CREDIT_FACILITY.ConfirmDecreaseClFacility]: { amount: string }
  [AmplitudeEventTypes.CREDIT_FACILITY.CancelDecreaseClFacility]: {}
  [AmplitudeEventTypes.CREDIT_FACILITY.PressedCloseClFacility]: {}
  [AmplitudeEventTypes.CREDIT_FACILITY.PressedPayClOrders]: {}
  [AmplitudeEventTypes.CREDIT_FACILITY.ConfirmCloseClFacility]: { closeReason: SelectClosePayMonthlyReasonProps }
  [AmplitudeEventTypes.CLICKED_ACCOUNT]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_ADD_PAYMENT_METHOD]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_CHANGE_BILLING_AGREEMENT_PAYMENT_METHOD]: {}
  [AmplitudeEventTypes.CLICKED_CANCEL_BILLING_AGREEMENT]: {}
  [AmplitudeEventTypes.CLICKED_CHANGE_ORDER_PAYMENT_METHOD]: {}
  [AmplitudeEventTypes.CLICKED_CHANGE_PAYMENT_DATE]: { orderId: string | number }
  [AmplitudeEventTypes.CLICKED_CHANGE_PAYMENT_DATE_FAQS]: { orderId: string | number }
  [AmplitudeEventTypes.CLICKED_CONFIRM_CUSTOM_PAYMENT_AMOUNT]: { orderId: string | number; outboundLink?: string; isHardship: boolean }
  [AmplitudeEventTypes.CLICKED_CONFIRM_PAYMENT_AMOUNT]: { paymentType: PaymentType; isHardship?: boolean }
  [AmplitudeEventTypes.CLICKED_CONFIRM_PAYMENT_DATE_CHANGE]: { orderId: number; originalPaymentDueDate: any; updatedPaymentDueDate: any }
  [AmplitudeEventTypes.VIEWED_PULSE_BENEFIT_MODAL]: { pulseBenefit: LoyaltyTierBenefit }
  [AmplitudeEventTypes.VIEWED_PULSE_INTRO_MODAL]: {}
  [AmplitudeEventTypes.CLICKED_PULSE_REWARDS_ACCOUNT_PAGE]: {}
  [AmplitudeEventTypes.VIEWED_PULSE_REWARDS_PAGE]: {}
  [AmplitudeEventTypes.CLICKED_PULSE_INTRO_MODAL_BUTTON]: {}
  [AmplitudeEventTypes.VIEWED_WELCOME_MODAL]: {}
  [AmplitudeEventTypes.CLICKED_WELCOME_MODAL_BUTTON]: {}
  [AmplitudeEventTypes.CLICKED_MOBILE_APP_PULSE_DEEPLINK]: {}
  [AmplitudeEventTypes.CLICKED_EDIT_ADDRESS]: {}
  [AmplitudeEventTypes.CLICKED_EDIT_EMAIL]: {}
  [AmplitudeEventTypes.CLICKED_EDIT_LANGUAGE]: {}
  [AmplitudeEventTypes.CLICKED_EDIT_MOBILE]: {}
  [AmplitudeEventTypes.CLICKED_EDIT_PASSWORD]: {}
  [AmplitudeEventTypes.PRESSED_AUTOPAY_ON]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_GET_APP]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_HELP]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_HOME]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_INVITE_FRIEND]: {}
  [AmplitudeEventTypes.CLICKED_LEARN_MORE_MONTHLY_PAYMENTS]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_MAKE_A_CUSTOM_PAYMENT]: {
    orderId: string | number
    outboundLink?: string
    customAmount?: string
    isHardship?: boolean
  }
  [AmplitudeEventTypes.CLICKED_MAKE_A_PAYMENT]: { orderId: string | number; outboundLink: string }
  [AmplitudeEventTypes.CLICKED_MAKE_A_PAYMENT_CONFIRM_AMOUNT]: { id: string | number; outboundLink: string; isHardship: boolean }
  [AmplitudeEventTypes.CLICKED_MAKE_A_PAYMENT_CONFIRM_AMOUNT_FAILURE]: { id: string | number; outboundLink: string; error: any }
  [AmplitudeEventTypes.CLICKED_MAKE_A_PAYMENT_CONFIRM_CUSTOM_PAYMENT_AMOUNT_FAILURE]: {
    id: string | number
    outboundLink: string
    errorCode: any
    errorMsg: any
  }
  [AmplitudeEventTypes.CLICKED_MAKE_A_PAYMENT_SUBMIT_PAYMENT]: { orderId: string | number; outboundLink: string }
  [AmplitudeEventTypes.CLICKED_MAKE_A_PAYMENT_SUBMIT_PAYMENT_FAILURE]: {
    orderId: string | number
    outboundLink: string
    paymentType: PaymentType
    amount: string
    isHardship: boolean
  }
  [AmplitudeEventTypes.CLICKED_MAKE_A_PAYMENT_SUBMIT_PAYMENT_SUCCESS]: {
    orderId: string | number
    outboundLink: string
    paymentType: PaymentType
    amount: string
    isHardship: boolean
  }
  [AmplitudeEventTypes.CLICKED_MAKE_A_PAYMENT_NEW_PAYMENT_METHOD]: {
    saveMethod?: boolean
    isHardship: boolean
  }
  [AmplitudeEventTypes.CLICKED_NEED_HELP]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_ORDER_DETAIL]: { orderId: string | number; outboundLink?: string; paymentType: PaymentType; isActiveOrder?: boolean }
  [AmplitudeEventTypes.CLICKED_ORDERS]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_ORDERS_LIST_PAGINATION]: { pageNumber: number }
  [AmplitudeEventTypes.CLICKED_PAY_NOW]: {
    locale?: SupportedLocale
    installmentDueDate: string | number
    installmentStatus: string
    outboundLink: string
  }
  [AmplitudeEventTypes.CLICKED_PAY_MONTHLY]: {}
  [AmplitudeEventTypes.CLICKED_REPORT_A_PROBLEM]: { locale: string; outboundLink: string }
  [AmplitudeEventTypes.CLICKED_IM_RETURNING_MY_ORDER]: { locale: string }
  [AmplitudeEventTypes.CLICKED_RETURNING_ORDER_BY_MAIL]: { locale: string }
  [AmplitudeEventTypes.CLICKED_RETURNING_ORDER_IN_STORE]: { locale: string }
  [AmplitudeEventTypes.CLICKED_RECURRING_PAYMENTS]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_REMOVE_CARD]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_SHOP]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_SHOP_NOW]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_SHOP_TILE]: { outboundLink: string; context: 'new_web_portal'; locale?: string }
  [AmplitudeEventTypes.CLICKED_SHOP_SEE_MORE]: { category: string; locale: string }
  [AmplitudeEventTypes.CLICKED_SUBMIT_PAYMENT]: {
    isNewPaymentMethod: boolean
    orderId: string | number
    outboundLink: string
    isHardship: boolean
  }
  [AmplitudeEventTypes.CLICKED_SWITCH_PORTAL]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_AUTOPAY_TOGGLE]: { autopay: 'on' | 'off'; orderId: string | number }
  [AmplitudeEventTypes.CLICKED_UPCOMING_PAYMENTS]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_UPDATE_CONTACT_DETAILS]: {}
  [AmplitudeEventTypes.CLICKED_UPDATE_PREFERRED_CARD]: { outboundLink: string }
  [AmplitudeEventTypes.CLICKED_UPDATE_SECURITY_DETAILS]: {} // TODO: Remove - unused
  [AmplitudeEventTypes.CLICKED_VERIFY_NOW]: { locale: string; outboundLink: string }
  [AmplitudeEventTypes.CLICKED_VIEW_BILLING_ADDRESS]: { locale: string }
  [AmplitudeEventTypes.CLICKED_UNSUBSCRIBE_EMAIL]: { href: string; email: string }
  [AmplitudeEventTypes.UNSUBSCRIBE_EMAIL_SUCCESS]: { href: string; email: string }
  [AmplitudeEventTypes.UNSUBSCRIBE_EMAIL_FAILED]: { href: string; email: string; errorMessage: string }
  [AmplitudeEventTypes.DELETE_CARD_FAILED]: { error: any }
  [AmplitudeEventTypes.EDIT_ADDRESS_ERROR]: {}
  [AmplitudeEventTypes.EDIT_ADDRESS_SUCCESS]: {}
  [AmplitudeEventTypes.EDIT_EMAIL_ERROR]: {}
  [AmplitudeEventTypes.EDIT_EMAIL_SUCCESS]: {}
  [AmplitudeEventTypes.EDIT_MOBILE_ERROR]: {}
  [AmplitudeEventTypes.EDIT_MOBILE_SUCCESS]: {}
  [AmplitudeEventTypes.EDIT_PASSWORD_ERROR]: {}
  [AmplitudeEventTypes.EDIT_PASSWORD_SUCCESS]: {}
  [AmplitudeEventTypes.EDIT_LANGUAGE_ERROR]: { from: SupportedLocale; to: SupportedLocale }
  [AmplitudeEventTypes.EDIT_LANGUAGE_SUCCESS]: { from: SupportedLocale; to: SupportedLocale }
  [AmplitudeEventTypes.ENABLE_AUTOPAY_FAILED]: { error: any }
  [AmplitudeEventTypes.INDEX_PAGE_LOADED]: {} // TODO: Remove - unused
  [AmplitudeEventTypes.NEW_CARD_ADDED_3DS_FAILED]: { error: any }
  [AmplitudeEventTypes.NEW_CARD_ADDED_3DS_PASSED]: { traceId: string }
  [AmplitudeEventTypes.PORTAL_APPLE_PAY_ERROR]: {
    // TODO: Fix - this is a mess
    type?: PaymentSourceCaptureCardTypes | ApplePayJS.ApplePayPaymentMethodType
    errorMessage?: any
    errorCode?: any
    errorsList?: any
    customMessage:
      | 'Session Initialization Failed'
      | 'Beginning Session Failed'
      | 'Merchant Validation Failed'
      | 'Invalid PaymentSourceCapture card type'
      | 'Failed to exchange Apple Pay token'
      | 'Topaz setup failure'
      | 'Invalid card type'
    request?: {
      refId: string
    }
  }
  [AmplitudeEventTypes.PRESSED_BACK_IN_CARD_SCAN]: { checkpoint: CardScanCheckpoint }
  [AmplitudeEventTypes.PRESSED_BIOMETRIC_LOGIN_TURN_OFF]: {}
  [AmplitudeEventTypes.PRESSED_CARD_SCAN_ADD_PAYMENT]: { checkpoint: CardScanCheckpoint }
  [AmplitudeEventTypes.PRESSED_CLOSE_BROWSER_TAB]: {}
  [AmplitudeEventTypes.PRESSED_DISPUTE_INFO_ACCEPT]: {}
  [AmplitudeEventTypes.PRESSED_DISPUTE_INFO_CANCEL]: {}
  [AmplitudeEventTypes.PRESSED_DISPUTE_INFO_REJECT]: {}
  [AmplitudeEventTypes.PRESSED_DISPUTE_INFO_REJECT_SUBMIT]: {}
  [AmplitudeEventTypes.SCAN_PAYMENT_FAILED_ADD_PAYMENT]: { checkpoint: CardScanCheckpoint; reason: CardScanAmplitudeErrorReason }
  [AmplitudeEventTypes.SCAN_PAYMENT_MATCHED_ADD_PAYMENT]: { checkpoint: CardScanCheckpoint }
  [AmplitudeEventTypes.SET_PREFERRED_APPLE_PAY_ERROR]: { error: any }
  [AmplitudeEventTypes.SET_PREFERRED_CARD_FAILED]: { error: any }
  [AmplitudeEventTypes.SUBMIT_SUCCESS_FOR_RETURNING_ORDER_BY_MAIL]: { locale: string }
  [AmplitudeEventTypes.SUBMIT_FAILURE_FOR_RETURNING_ORDER_BY_MAIL]: { locale: string }
  [AmplitudeEventTypes.TOGGLE_MARKETING_EMAILS]: { toggle: boolean; success: boolean }
  [AmplitudeEventTypes.UPDATE_PAYMENT_METHOD_IN_BILLING_AGREEMENT_FAILED]: { error: any }
  [AmplitudeEventTypes.UPDATE_PAYMENT_METHOD_IN_ORDER_FAILED]: { error: any }
  [AmplitudeEventTypes.CANCEL_BILLING_AGREEMENT_FAILED]: { error: any }
  [AmplitudeEventTypes.VIEWED_ADD_PAYMENT_METHOD_SUCCESS_TOAST]: { locale: string }
  [AmplitudeEventTypes.VIEWED_MAKE_A_PAYMENT_INSTALLMENTS]: { paymentType: PaymentType; outboundLink: string }
  [AmplitudeEventTypes.VIEWED_MAKE_A_PAYMENT_PAYMENT]: { paymentType: PaymentType; outboundLink: string; type: PayNowInputProps }
  [AmplitudeEventTypes.CHANGE_SELECTED_PAYMENT_TYPE]: { type: PayNowInputProps }
  [AmplitudeEventTypes.CHANGE_PAYMENT_METHOD]: {}
  [AmplitudeEventTypes.VIEWED_3DS_VERIFICATION_INITIATE]: { challengeRequired: boolean }
  [AmplitudeEventTypes.VIEWED_3DS_VERIFICATION_INITIATE_FAILED]: { error: any }
  [AmplitudeEventTypes.VIEWED_CARD_SCAN_ADD_PAYMENT]: { checkpoint: CardScanCheckpoint }
  [AmplitudeEventTypes.VIEWED_CHANGE_PAYMENT_DATE_ERROR]: { errorCode: any; errorReason: any }
  [AmplitudeEventTypes.VIEWED_DISPUTE_INFO_DETAIL]: {}
  [AmplitudeEventTypes.VIEWED_DISPUTE_INFO_PROCESS_FAILURE]: {}
  [AmplitudeEventTypes.VIEWED_DISPUTE_INFO_PROCESS_SUCCESS]: {}
  [AmplitudeEventTypes.VIEWED_DISPUTE_INFO_REJECT]: {}
  [AmplitudeEventTypes.VIEWED_FAILED_PAYMENT_MODAL]: { failureReason: any; failureCode: string; isHardship?: boolean } // TODO: failureReason needs better typing
  [AmplitudeEventTypes.VIEWED_ORDERS_PAGE]: {} // TODO: Remove - unused
  [AmplitudeEventTypes.VIEWED_ORDER_DETAILS_PAGE]: { orderId: string | number; outboundLink: string; paymentType: PaymentType }
  [AmplitudeEventTypes.VIEWED_RECURRINGPAYMENT_DETAILS_PAGE]: {} // TODO: Remove - unused
  [AmplitudeEventTypes.PRESSED_REPORT_CARD_CANCELLATION]: {}
  [AmplitudeEventTypes.VIEWED_VERIFICATION_SUCCESS]: { locale: string }
  [AmplitudeEventTypes.PRESSED_REPORT_CARD_CANCELLATION_ALL]: {}
  [AmplitudeEventTypes.PRESSED_REPORT_CARD_CANCELLATION_PARTIAL]: {}
  [AmplitudeEventTypes.REPORT_CARD_CANCELLATION_SUCCESS_ALL]: {}
  [AmplitudeEventTypes.REPORT_CARD_CANCELLATION_SUCCESS_PARTIAL]: {}
  [AmplitudeEventTypes.REPORT_CARD_CANCELLATION_DECLINE]: {}
  [AmplitudeEventTypes.VIEWED_SUCCESSFUL_PAYMENT_MODAL]: {}
  [AmplitudeEventTypes.PRESSED_INITIAL_REPORTING_PROBLEM]: {}
  [AmplitudeEventTypes.VIEWED_DISPUTE_PERMISSION_CHECKER]: {
    reason: DisputePermissionError
  }
  [AmplitudeEventTypes.VIEWED_CREATE_DISPUTE_SELECT_PROBLEM]: {}
  [AmplitudeEventTypes.VIEWED_CREATE_DISPUTE_CONTACT_MERCHANT]: {}
  [AmplitudeEventTypes.VIEWED_CREATE_DISPUTE_ORDER_MISMATCH_CONFIRMATION]: {}
  [AmplitudeEventTypes.VIEWED_CREATE_DISPUTE_SUBMIT_EVIDENCE]: {}
  [AmplitudeEventTypes.VIEWED_CREATE_DISPUTE_ALIBABA_FLOW]: {}
  [AmplitudeEventTypes.VIEWED_CREATE_DISPUTE_SUCCESS]: {}
  [AmplitudeEventTypes.VIEWED_CREATE_DISPUTE_FAILURE]: {}
  [AmplitudeEventTypes.PRESSED_CREATE_DISPUTE_SELECT_PROBLEM_CTA]: {}
  [AmplitudeEventTypes.PRESSED_CREATE_DISPUTE_CONTACT_MERCHANT]: {}
  [AmplitudeEventTypes.PRESSED_CREATE_DISPUTE_CANCEL_CONTACT_MERCHANT]: {}
  [AmplitudeEventTypes.PRESSED_CREATE_DISPUTE_CONTACT_MERCHANT_INFO]: {}
  [AmplitudeEventTypes.PRESSED_CREATE_DISPUTE_ORDER_MISMATCH_CONFIRM_YES]: {}
  [AmplitudeEventTypes.PRESSED_CREATE_DISPUTE_ORDER_MISMATCH_CONFIRM_NO]: {}
  [AmplitudeEventTypes.PRESSED_OPEN_PAYMENT_RESCHEDULE_MODAL]: {}
  [AmplitudeEventTypes.PRESSED_EDIT_ADDRESS_IN_REACTIVATE_ACCOUNT_FLOW]: {}
  [AmplitudeEventTypes.PRESSED_SOFTID_LANDING_PAGE_ADDRESS_VERIFY_BUTTON]: {}
  [AmplitudeEventTypes.PRESSED_HARDID_RETRY_PAGE_VERIFY_BUTTON]: {}
  [AmplitudeEventTypes.PRESSED_HARDID_DRIVERS_LICENCE_PAGE_VERIFY_BUTTON]: {}
  [AmplitudeEventTypes.PRESSED_HARDID_PASSPORT_PAGE_VERIFY_BUTTON]: {}
  [AmplitudeEventTypes.PRESSED_HARDID_MEDICARE_PAGE_VERIFY_BUTTON]: {}
  [AmplitudeEventTypes.VIEWED_NON_TERMINAL_ERROR]: {}
  [AmplitudeEventTypes.PRESSED_HARDID_LANDING_PAGE_HELP_LINK]: {}
  [AmplitudeEventTypes.PRESSED_HARDID_LANDING_PAGE_DRIVERS_LICENCE_BUTTON]: {}
  [AmplitudeEventTypes.PRESSED_HARDID_LANDING_PAGE_MEDICARE_BUTTON]: {}
  [AmplitudeEventTypes.PRESSED_HARDID_LANDING_PAGE_PASSPORT_BUTTON]: {}
  [AmplitudeEventTypes.PRESSED_HARDID_RETRY_PAGE_HELP_LINK]: {}
  [AmplitudeEventTypes.GENERATE_MOBILE_APP_QR_CODE_SUCCESS]: {}
  [AmplitudeEventTypes.GENERATE_MOBILE_APP_QR_CODE_ERROR]: {}
  [AmplitudeEventTypes.TERMINATION_NOTICE.CLOSE_BUTTON_CLICKED]: {}
  [AmplitudeEventTypes.TERMINATION_NOTICE.MODAL_VIEWED]: {}
  [AmplitudeEventTypes.TOGGLE_AUTOPAY_SUCCESS]: {
    autopay: 'on' | 'off'
    orderId: string | number
    orderTotal: string
    source: OrderSource
    paymentMethodType: PaymentMethodType
    channel: OrderChannel
  }
  [AmplitudeEventTypes.TOGGLE_AUTOPAY_FAILURE]: { autopay: 'on' | 'off'; orderId: string | number }
  [AmplitudeEventTypes.VIEWED_NEW_AUTOPAY_BANNER]: { orderId: string | number }
  [AmplitudeEventTypes.CLICKED_CONFIRM_TURN_AUTOPAY_ON]: { orderId: string | number }
  [AmplitudeEventTypes.CLICKED_CONFIRM_TURN_AUTOPAY_OFF]: { orderId: string | number }
  [AmplitudeEventTypes.CLICKED_PAYMENT_AUTHORISATION_LINK]: { outboundLink?: string; orderId: string | number }
  [AmplitudeEventTypes.CLOSED_AUTOPAY_MODAL]: { orderId: string | number }
  [AmplitudeEventTypes.PRESSED_CLOSE_MY_ACCOUNT]: {}
  [AmplitudeEventTypes.VIEWED_CLOSE_MY_ACCOUNT]: {}
  [AmplitudeEventTypes.SELECTED_CLOSE_ACCOUNT_REASON]: {}
  [AmplitudeEventTypes.VIEWED_BEFORE_YOU_GO]: {}
  [AmplitudeEventTypes.PRESSED_NO_CLOSE_MY_ACCOUNT]: {}
  [AmplitudeEventTypes.SELECTED_DEACTIVATE_ACCOUNT]: {}
  [AmplitudeEventTypes.SELECTED_REDUCE_SPENDING_LIMIT]: {}
  [AmplitudeEventTypes.VIEWED_CLOSE_ACCOUNT_CONFIRM]: {}
  [AmplitudeEventTypes.PRESSED_CONFIRM_TO_CLOSE_MY_ACCOUNT]: {}
  [AmplitudeEventTypes.VIEWED_CLOSE_MY_ACCOUNT_SUCCESS_TOAST]: {}
  [AmplitudeEventTypes.VIEWED_CLOSE_MY_ACCOUNT_ERROR]: {}
  [AmplitudeEventTypes.VIEWED_CONFIRM_DEACTIVATE_MY_ACCOUNT]: {}
  [AmplitudeEventTypes.PRESSED_CONFIRM_DEACTIVATE_MY_ACCOUNT]: {}
  [AmplitudeEventTypes.VIEWED_DEACTIVATE_ACCOUNT_SUCCESS_TOAST]: {}
  [AmplitudeEventTypes.VIEWED_LOGIN_PAGE]: {}
  [AmplitudeEventTypes.PRESSED_CONTINUE_ON_LOGIN_PAGE]: {}
  [AmplitudeEventTypes.USER_LOOK_UP_ON_LOGIN_PAGE]: {}
  [AmplitudeEventTypes.CROSS_REGION_USER_REDIRECT_ON_LOGIN_PAGE]: {}
  [AmplitudeEventTypes.CLICKED_RETAILER_LINK]: {}
  [AmplitudeEventTypes.PRESSED_CONTINUE_BUTTON_ON_PASSWORD_PAGE]: {}
  [AmplitudeEventTypes.CLICKED_NOT_YOU]: {}
  [AmplitudeEventTypes.CLICKED_FORGOT_PASSWORD]: {}
  [AmplitudeEventTypes.SEND_CODE_ON_CREATE_PASSWORD_VERIFY]: {}
  [AmplitudeEventTypes.PRESSED_RESEND_CODE]: {}
  [AmplitudeEventTypes.PRESSED_CONTINUE_ON_PROFILE_CONFIRM_PAGE]: {}
  [AmplitudeEventTypes.PRESSED_MANUAL_ADDRESS_TOGGLE]: {}
  [AmplitudeEventTypes.CLICKED_TERM_LINK]: {}
  [AmplitudeEventTypes.CLICKED_CONTINUE_ON_CREATE_PASSWORD]: {}
  [AmplitudeEventTypes.CLICK_DOWNLOAD_MOBILE_APP]: {}
  [AmplitudeEventTypes.LOGIN_ERROR]: {}
  [AmplitudeEventTypes.TYPED_EMAIL_ON_LOGIN_PAGE]: {}
  [AmplitudeEventTypes.CONSUMER_LOST_EMAIL]: {}
  [AmplitudeEventTypes.PRESSED_CONTRACT_AGREEMENT_PAGE_CREDIT_CONTRACT_LINK]: { outboundLink: string | undefined }
}
