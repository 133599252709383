import { Heading, Checkbox, Text } from '@afterpaytouch/core'
import React, { FunctionComponent } from 'react'
import { OrderAutopayPBIModal, useOrderAutopayPBIModal } from '../OrderAutopayPBIModal'
import { useOrderHasAutopayEnabled } from '../../state'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { TrackOnMount } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { useDisplayIllustrations } from '../../state/convergence/hooks'

const I18N_NAMESPACE = ['orders']

interface OrderAutopayPBIProps {
  orderId: string
}

export const OrderAutopayPBI: FunctionComponent<OrderAutopayPBIProps> = ({ orderId }) => {
  const isAutopayEnabled = useOrderHasAutopayEnabled(orderId)
  const { t } = useTranslation(I18N_NAMESPACE)
  const isDisplayIllustrations = useDisplayIllustrations()
  const [isOrderAutopayPBIModalOpen, { openModal: openOrderAutopayPBIModal, closeModal: closeOrderAutopayPBIModal }] = useOrderAutopayPBIModal()
  const testNameSpace = 'autopay-pbi'
  const toggleAutopay = (): void => {
    openOrderAutopayPBIModal()
  }

  const closeAutopayModal = (): void => {
    closeOrderAutopayPBIModal()
  }

  return (
    <>
      <TrackOnMount eventName={TrackingEvent.VIEWED_NEW_AUTOPAY_BANNER} eventProps={{ orderId }} />
      <div data-testid={testNameSpace}>
        <div className='pb-2 pt-2'>
          <div className='flex'>
            {isDisplayIllustrations && (
              <div className={'mr-2 flex min-w-fit items-start justify-center'}>
                <Image
                  width={24}
                  height={24}
                  src={`/images/autopay.svg`}
                  alt={t('orders:orderAutopay:autopay')}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
            )}
            <div className='ml-2'>
              <Heading size='M'>{t('orders:autopay:banner:heading')}</Heading>
              <Text size='S' color='Gray40'>
                {t('orders:autopay:banner:subheading')}
              </Text>
            </div>
            <Checkbox
              testNameSpace={testNameSpace}
              id={testNameSpace}
              kind='Toggle'
              defaultChecked={isAutopayEnabled}
              checked={isAutopayEnabled}
              label={isAutopayEnabled ? t('orders:autopay:banner:toggleLabel:on') : t('orders:autopay:banner:toggleLabel:off')}
              reversed
              onChange={toggleAutopay}
            />
          </div>
        </div>
        <OrderAutopayPBIModal orderId={orderId} isModalOpen={isOrderAutopayPBIModalOpen} closeModal={closeAutopayModal} />
      </div>
    </>
  )
}
