import React, { FunctionComponent, useContext, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Heading, Modal, Text, useToast } from '@afterpaytouch/core'
import { isPortalApiError, SupportedLocale } from '@afterpaytouch/portal-api'
import { SELECT_CLOSE_ACCOUNT_REASONS } from '@afterpaytouch/portal-api/consumer/account/types'
import { useRouter } from 'next/router'
import { Route } from '../../../config/router'
import { useDeactivateAccountMutation } from '../../../state/account'
import { CloseAccountFlowContext } from '../CloseAccountFlow/CloseAccountFlowContext'
import { Error } from './Error'

export const ConfirmCloseAccount: FunctionComponent<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const I18N_NAMESPACE = ['common', 'account']
  const { t } = useTranslation(I18N_NAMESPACE)
  const setToast = useToast()
  const router = useRouter()
  const locale = router?.locale as SupportedLocale

  const { closeModal, closeAccountReason } = useContext(CloseAccountFlowContext)
  const [
    setDeactivateAccountMutation,
    {
      isError: isSetDeactivateAccountError,
      isLoading: isSetDeactivateAccountLoading,
      error: setDeactivateAccountError,
      isSuccess: isSetDeactivateAccountSuccess,
    },
  ] = useDeactivateAccountMutation()

  // handle success
  useEffect(() => {
    if (isSetDeactivateAccountSuccess) {
      setToast({
        message: t('account:closeAccount:confirmCloseAccount:deactivateAccountSuccessToastMessage'),
        kind: 'Failure',
        linkLabel: t('account:closeAccount:confirmCloseAccount:deactivateAccountSuccessToastMessageLink'),
        linkUrl: `/${locale}${Route.ACCOUNT__PROFILE__REACTIVATEACCOUNTMODAL}`,
        linkTarget: '_self',
        testNameSpace: 'select-close-account',
      })
      closeModal()
      onSuccess()
    }
  }, [isSetDeactivateAccountSuccess])

  const submitDeactivateAccount = (): void => {
    setDeactivateAccountMutation({
      body: { deactivateReason: SELECT_CLOSE_ACCOUNT_REASONS[closeAccountReason] },
    })
  }

  if (isSetDeactivateAccountError) {
    return (
      // @ts-ignore: OPERATION BLEED STOPPER
      <Error error={'data' in setDeactivateAccountError && isPortalApiError(setDeactivateAccountError.data) ? setDeactivateAccountError.data : undefined} />
    )
  }

  return (
    <>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading size='L'>{t('account:closeAccount:selectCloseAccountReason:header')}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Text>{t('account:closeAccount:confirmCloseAccount:content')}</Text>
      </Modal.Content>
      <Modal.Footer>
        <div className='mb-6 mt-2'>
          <Button testNameSpace='select-close-account-confirm' padding='Fluid' onClick={submitDeactivateAccount} loading={isSetDeactivateAccountLoading}>
            {t('account:closeAccount:confirmCloseAccount:confirmButton')}
          </Button>
        </div>
        <div>
          <Button.Ghost testNameSpace='select-close-account-cancel' padding='Fluid' onClick={closeModal}>
            {t('account:closeAccount:confirmCloseAccount:cancelButton')}
          </Button.Ghost>
        </div>
      </Modal.Footer>
    </>
  )
}
