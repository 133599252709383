import React, { FunctionComponent, useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Button, Heading, Text, useIsBreakpointMediumAndUp } from '@afterpaytouch/core'
import { getMerchantName } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import { OrderChannel } from '../../model'
import { isConsumerLendingOrder, useConsumerAccountIsInHardship, useGetOrderTransactionQuery } from '../../state'
import { useConsumerSizes } from '../../utils/responsive'
import { GetHelpModal } from './GetHelpModal'
import { useGetHelpModal } from './hooks'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { useRouter } from 'next/router'
import { useFlag } from '../../hooks'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { TrackingEvent } from '../../model/amplitude'

const I18N_NAMESPACE = ['common', 'orders']

interface OrderDeliveryInfoSectionProps {
  id: string
  refreshDisputeInfo?: () => void
}

export const OrderDeliveryInfoSection: FunctionComponent<OrderDeliveryInfoSectionProps> = ({ id, refreshDisputeInfo }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { data } = useGetOrderTransactionQuery(id)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const router = useRouter()

  const [isGetHelpModalOpen, { openModal: openGetHelpModal, closeModal: closeGetHelpModal }] = useGetHelpModal()
  const { sectionHeadingSize } = useConsumerSizes()
  const [animateModal, setAnimateModal] = useState<boolean>(true)

  const { paymentType } = data ?? {}
  const isConsumerLending = isConsumerLendingOrder(paymentType)
  const isConsumerAccountInHardship = useConsumerAccountIsInHardship()
  const isHardshipEnabled = useFlag('consumer-portal-hardship-account-payments-enabled')
  const isConsumerLendingOrHardship = isConsumerLending || (isConsumerAccountInHardship && isHardshipEnabled)

  const isDisputeFlagEnabled = useFlag('consumer-portal-order-enable-dispute')
  const isRedirectFRITESFlagEnabled = useFlag('consumer-portal-get-help-redirect-frites-users')

  const reopenGetHelpModal = (): void => {
    setAnimateModal(false)
    openGetHelpModal()
  }

  const onCloseGetHelpModal = (): void => {
    setAnimateModal(true)
    closeGetHelpModal()
  }

  const renderDeiveryAddress = (): JSX.Element => {
    if (data?.orderDetail?.shippingAddress === null || data?.orderDetail?.shippingAddress === undefined) {
      // @ts-ignore: OPERATION BLEED STOPPER
      return null
    }

    const deliveryAddressHeading = <Heading size={sectionHeadingSize}></Heading>
    const deliveryAddress = (
      <div className='mt-5 break-words'>
        <Text testNameSpace='delivery-name'>{data?.orderDetail.shippingAddress.name}</Text>
        <Text testNameSpace='delivery-address'>{data?.orderDetail.shippingAddress.address1}</Text>
        <Text>{data?.orderDetail.shippingAddress.address2}</Text>
        <Text>{data?.orderDetail.shippingAddress.suburb}</Text>
        <Text>
          {data?.orderDetail.shippingAddress.state} {data?.orderDetail.shippingAddress.postcode}
        </Text>
      </div>
    )
    return (
      <div className='pr-6 sm:w-full sm:py-4 md:w-1/2 md:py-2 lg:w-1/4'>
        <MaskedElement>
          <Trans components={{ deliveryAddress, deliveryAddressHeading }} i18nKey='orders:deliveryInfoSection:deliveryAddress' />
        </MaskedElement>
      </div>
    )
  }

  const renderStoreInfo = (): JSX.Element => {
    if (data?.store === undefined || data?.store === null) {
      // @ts-ignore: OPERATION BLEED STOPPER
      return null
    }
    const storeInfoHeading = <Heading size={sectionHeadingSize}></Heading>
    const storeAddress = (
      <div className='mt-5 break-words'>
        <Text testNameSpace='store-name'>{data?.store.name}</Text>
        <Text testNameSpace='store-address'>{data?.store.address.address1}</Text>
        <Text>{data?.store.address.address2}</Text>
        <Text>
          {data?.store.address.suburb} {data?.store.address.state} {data?.store.address.postcode}
        </Text>
        <Text>{data?.store.address.countryCode}</Text>
      </div>
    )
    return (
      <div className='pr-6 sm:w-full sm:py-4 md:w-1/2 md:py-2 lg:w-1/4'>
        <Trans components={{ storeAddress, storeInfoHeading }} i18nKey='orders:deliveryInfoSection:storeInfo' />
      </div>
    )
  }
  const renderMerchantInfo = (): JSX.Element => {
    const merchantInfoHeading = <Heading size={sectionHeadingSize}></Heading>
    const merchantInfo = (
      <div className='mt-5 break-words'>
        {/* @ts-ignore: OPERATION BLEED STOPPER */}
        <Text testNameSpace='merchant-name'>{getMerchantName(data?.merchant)}</Text>
        <Text testNameSpace='merchant-contact-email'>
          <a href={`mailto:${data?.merchant.contactEmail}`} className='text-inherit underline'>
            {data?.merchant.contactEmail}
          </a>
        </Text>
        <Text>{data?.merchant.contactUrl}</Text>
        <Text>{data?.merchant.contactNumber}</Text>
        <Text>{data?.merchant.countryCode}</Text>
      </div>
    )
    return (
      <div className='pr-6 sm:w-full sm:py-4 md:w-1/2 md:py-2 lg:w-1/4'>
        <Trans components={{ merchantInfo, merchantInfoHeading }} i18nKey='orders:deliveryInfoSection:merchantInfo' />
      </div>
    )
  }

  const renderMerchantHelp = (): JSX.Element => {
    return (
      <div className='pr-6 sm:w-full sm:py-4 md:w-1/2 md:py-2 lg:w-1/4'>
        <Heading size={sectionHeadingSize}>{t('orders:deliveryInfoSection:merchantHelp')}</Heading>
        <div className='mt-5'>
          <Text>{t('orders:deliveryInfoSection:delivery')}</Text>
          <Text>{t('orders:deliveryInfoSection:returns')}</Text>
          <Text>{t('orders:deliveryInfoSection:refunds')}</Text>
          <Text>{t('orders:deliveryInfoSection:cancellations')}</Text>
        </div>
      </div>
    )
  }

  const handleGetHelpButton = (): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    logEvent(TrackingEvent.CLICKED_REPORT_A_PROBLEM, { locale: router?.locale, outboundLink: router?.pathname })

    if (isRedirectFRITESFlagEnabled) {
      window.location.href = t('common:urls:helpUrl:newRequest')
      return
    }
    openGetHelpModal()
  }

  const isBpMediumUp = useIsBreakpointMediumAndUp()
  const renderOrderSupport = (): JSX.Element => {
    return (
      <div className='pr-6 sm:w-full sm:py-4 md:w-1/2 md:py-2 lg:w-1/4'>
        <Heading size={sectionHeadingSize} testNameSpace='order-support'>
          {t('orders:deliveryInfoSection:orderSupport')}
        </Heading>
        <div className='mt-5'>
          <Button.Ghost onClick={() => handleGetHelpButton()} testNameSpace='order-page-get-help' padding={isBpMediumUp ? 'Fixed' : 'Fluid'}>
            {t('orders:deliveryInfoSection:getHelp')}
          </Button.Ghost>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='flex flex-wrap'>
        {[OrderChannel[OrderChannel.IN_STORE], OrderChannel[OrderChannel.IN_STORE_CARD], OrderChannel[OrderChannel.IN_STORE_CARD_ONLINE]].includes(
          // @ts-ignore: OPERATION BLEED STOPPER
          data?.channel
        ) && renderStoreInfo()}
        {/* @ts-ignore: OPERATION BLEED STOPPER */}
        {[OrderChannel[OrderChannel.ONLINE], OrderChannel[OrderChannel.MONEY]].includes(data?.channel) && renderDeiveryAddress()}
        {renderMerchantInfo()}
        {renderMerchantHelp()}
        {(!isConsumerAccountInHardship || isDisputeFlagEnabled) && renderOrderSupport()}
      </div>
      <GetHelpModal
        // @ts-ignore: OPERATION BLEED STOPPER
        order={data}
        show={isGetHelpModalOpen}
        onClose={onCloseGetHelpModal}
        disputeProps={{ refreshDisputeInfo }}
        reopenGetHelpModal={reopenGetHelpModal}
        animate={animateModal}
        isConsumerLendingOrHardship={isConsumerLendingOrHardship}
      />
    </>
  )
}
