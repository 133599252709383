import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'

import { Heading, Skeleton, useIsCashTheme } from '@afterpaytouch/core'

import { useConsumerSizes } from '../../../../utils/responsive'
import styles from './style.module.scss'
import { Greeting } from '../../Greeting'

const I18N_NAMESPACE = ['common', 'index']

interface VerifyIdentityProps {
  consumer: { givenName: string; isVerified: boolean }
  isSkeletonShown: boolean
}

export const VerifyIdentity: FunctionComponent<VerifyIdentityProps> = ({ consumer, isSkeletonShown }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { headingSize } = useConsumerSizes()
  const isCashTheme = useIsCashTheme()
  return (
    <>
      <div className={styles.container}>
        <Greeting consumer={consumer} isLoading={isSkeletonShown} />
        <div className={styles.contentSkeletonContainer}>
          {isSkeletonShown && <Skeleton kind={isCashTheme ? 'Default' : 'Primary'} />}
          <div className={isSkeletonShown ? styles.invisible : styles.visible}>
            <Heading size={headingSize}>
              <span className={styles.heading}>{t('index:welcome:verifyIdentity:heading')}</span>
              {t('index:welcome:verifyIdentity:subheading')}
            </Heading>
          </div>
        </div>
      </div>
    </>
  )
}
