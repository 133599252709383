import React, { FunctionComponent } from 'react'
import { useTranslation, Trans } from 'next-i18next'
import { Text, Span, Heading } from '@afterpaytouch/core'
import { BankDetailsFromPlaid } from '@afterpay/types'

const I18N_NAMESPACE = ['ach']

export const Success: FunctionComponent<{
  selectedAccount: BankDetailsFromPlaid
}> = ({ selectedAccount }) => {
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <>
      <div className='mb-4 mt-4'>
        <Text>
          <Trans
            i18nKey={'ach:linkBankAccount:success:title'}
            components={{
              strong: <Span bold />,
            }}
            values={{
              account: `${selectedAccount.name} **** ${selectedAccount.numberMask}`,
            }}
          />
        </Text>
      </div>
      <Heading>{t('ach:linkBankAccount:success:subheading')}</Heading>
      <Text>
        <Trans
          i18nKey={'ach:linkBankAccount:success:detail'}
          components={{
            strong: <Span bold />,
          }}
        />
      </Text>
    </>
  )
}
