import { createAsyncThunk } from '@reduxjs/toolkit'

import { portalAction, PortalThunk, AsyncAction, resetAuthState } from '../../'
import { ConsumerLogoutResponse } from '../../../model'
import { createFingerPrint } from '../../../utils'
import { navigateToShop } from '../../../utils/redirects'
import { setSessionStorageItem } from '@afterpay/utils/storage'

export enum UserAuthActions {
  USER_FINGERPRINT = 'user/fingerprint',
  AUTH_LOGOUT = 'consumer/logout',
}

export const createFingerprint = createAsyncThunk(UserAuthActions.USER_FINGERPRINT, async () => await createFingerPrint())

export const authLogout: PortalThunk<undefined, ConsumerLogoutResponse | void> = portalAction(UserAuthActions.AUTH_LOGOUT, async (_, thunk) => {
  const logout: ConsumerLogoutResponse = await thunk.extra.portalApi().post('/portal/consumers/auth/logout', {})
  if (logout.status) {
    navigateToShop()
  }
  return logout
})

// TODO review this logout logic after registration/login is implemented
export const resetAuthData = (): AsyncAction<void> => async (dispatch) => {
  await dispatch(resetAuthState())
  setSessionStorageItem('profilingSessionId', null)
  setSessionStorageItem('transactionToken', null)
}
