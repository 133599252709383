import { Text, TextSizes, useMoney, Skeleton, ColorTypes } from '@afterpaytouch/core'
import { Money as MoneyValue } from '@afterpaytouch/portal-api'
import { useRouter } from 'next/router'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import { SupportedLocale } from '../../model'
import styles from './style.module.css'
import { isValidMoney } from './utils'

interface MoneyProps {
  value: MoneyValue
  size?: TextSizes
  strikethrough?: boolean
  bold?: boolean
  showFree?: boolean
  loading?: boolean
  color?: ColorTypes
  testNameSpace?: string
}
const I18N_NAMESPACE = ['common']

export const Money: FunctionComponent<MoneyProps> = ({
  value,
  size = 'XS',
  strikethrough = false,
  bold = false,
  showFree = false,
  loading = false,
  color,
  testNameSpace = '',
}) => {
  const router = useRouter()
  const { t } = useTranslation(I18N_NAMESPACE)

  const { formatMoney } = useMoney({
    locale: router.locale as SupportedLocale,
    currency: value?.currency,
  })

  const renderValue = (): string => {
    if (showFree && !isValidMoney(value)) {
      return t('common:free')
    } else {
      return formatMoney(value?.amount)
    }
  }

  return (
    <Text
      size={size}
      strikethrough={strikethrough}
      bold={bold}
      renderAs='span'
      color={color}
      {...(testNameSpace.length > 0 && {
        'data-testid': `${testNameSpace}-money`,
      })}
    >
      <span className={loading ? styles.visuallyInvisible : ''} data-testid='text-money'>
        {renderValue()}
      </span>
      {loading && <Skeleton testNameSpace='text-skeleton' />}
    </Text>
  )
}
