import { useEffect, useState } from 'react'

interface useModalEvents {
  onOpen?: () => void
  onClose?: () => void
}

type useModal = (isModalOpenByDefault?: boolean, events?: useModalEvents) => [boolean, { openModal: () => void; closeModal: () => void }]

export const createModalHook = (): useModal => {
  return function useModal(isModalOpenByDefault = false, events): [boolean, { openModal: () => void; closeModal: () => void }] {
    const [isModalOpen, setIsModalOpen] = useState(isModalOpenByDefault)

    useEffect(() => {
      if (isModalOpenByDefault) {
        events?.onOpen?.()
      }
    }, [])

    const openModal = (): void => {
      setIsModalOpen(true)
      events?.onOpen?.()
    }

    const closeModal = (): void => {
      setIsModalOpen(false)
      events?.onClose?.()
    }

    return [isModalOpen, { openModal, closeModal }]
  }
}
