import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'

import { Grid, Heading, Button, Modal, Icon, Text } from '@afterpaytouch/core'
import { BankDetailsFromPlaid } from '@afterpay/types'
import { isPortalApiError } from '@afterpaytouch/portal-api/utils'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { useGetPlaidBankAccountsMutation, useLinkBankAccountMutation } from '../../../state'
import { AchFlowModalContext } from '../AchFlowModalContext'
import { AchFlowNavigation } from '../AchFlowNavigation'
import { Error } from './Error'
import { BankAccountsList } from './BankAccountsList'
import { Success } from './Success'
import styles from './style.module.scss'

const I18N_NAMESPACE = ['ach']

export const LinkBankAccount: FunctionComponent<{ onSuccess?: (LinkedAccountFingerprint?: string, id?: number) => void }> = ({ onSuccess }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const [
    getPlaidBankAccounts,
    { isError: isGetAccountsError, data: accountsData, error: getAccountsError, isLoading: isGetAccountsLoading, reset: resetAddPublicToken },
  ] = useGetPlaidBankAccountsMutation()
  const [linkBankAccount, { isError: isLinkError, isSuccess: isLinkSuccess, isLoading: isLinkLoading, reset: resetLinkBankAccount, data: linkAccountData }] =
    useLinkBankAccountMutation()
  const { publicToken, setResult, result, closeModal } = useContext(AchFlowModalContext)
  const [selectedAccount, setSelectedAccount] = useState<BankDetailsFromPlaid>()
  const amplitude = useAmplitudeWithEnduringEventProperties()
  const [bankLogoSrc, setBankLogoSrc] = useState('')

  useEffect(() => {
    const fetchData = publicToken !== '' && typeof publicToken !== 'undefined' && !result.error && !result.success
    if (fetchData) {
      getPlaidBankAccounts({ publicToken })
    }
  }, [publicToken, result, getPlaidBankAccounts])

  useEffect(() => {
    if (isGetAccountsError || isLinkError) {
      let httpStatusCode
      if (typeof getAccountsError !== 'undefined' && 'data' in getAccountsError && isPortalApiError(getAccountsError.data)) {
        httpStatusCode = getAccountsError.data.httpStatusCode
      }
      setResult({
        error: true,
        success: false,
        httpStatusCode,
      })
      if (isGetAccountsError && httpStatusCode === 412) {
        amplitude.logEvent(TrackingEvent.ACH.ViewBankAccountErrorNoAccount)
      } else if (isGetAccountsError) {
        amplitude.logEvent(TrackingEvent.ACH.ViewBankAccountErrorGeneric)
      } else {
        amplitude.logEvent(TrackingEvent.ACH.ViewedLinkedCheckingAccountError)
      }
    }
    if (isLinkSuccess) {
      setResult({
        error: false,
        success: true,
        httpStatusCode: undefined,
      })
      if (typeof onSuccess !== 'undefined') {
        // @ts-ignore: OPERATION BLEED STOPPER
        onSuccess(selectedAccount.fingerprint, linkAccountData?.account?.id)
      }
      amplitude.logEvent(TrackingEvent.ACH.ViewedLinkedCheckingAccountSuccess)
    }
  }, [isGetAccountsError, isLinkError, isLinkSuccess, getAccountsError, setResult])

  useEffect(() => {
    if (typeof accountsData?.bank?.logo !== 'undefined') {
      setBankLogoSrc(`data:image/png;base64,${accountsData.bank.logo}`)
    }
  }, [accountsData])

  const refetch = (): void => {
    // reset all result to trigger a refetch if the consumer clicks the "Try again"
    resetAddPublicToken()
    resetLinkBankAccount()
    setResult({
      error: false,
      success: false,
      httpStatusCode: undefined,
    })
  }

  if (result.error) {
    return <Error refetch={refetch} />
  }

  const handleSelect = (account: BankDetailsFromPlaid): void => {
    setSelectedAccount(account)
  }

  const handleSubmit = (): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    linkBankAccount({ fingerprint: selectedAccount.fingerprint })
  }

  return (
    <>
      <Modal.Header divider>
        <AchFlowNavigation />
      </Modal.Header>
      {!isGetAccountsLoading && typeof accountsData !== 'undefined' ? (
        <>
          <Modal.Content>
            <Grid>
              <Grid.FixedItem width={64}>
                <div className={styles.logo}>
                  {/* Show the fallback bank logo if bank logo data is missing or there is a loading error. */}
                  {bankLogoSrc !== '' ? (
                    <Image
                      onError={() => setBankLogoSrc('')}
                      src={bankLogoSrc}
                      alt={accountsData?.bank?.name}
                      width={48}
                      height={48}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  ) : (
                    <Icon.Bank />
                  )}
                </div>
              </Grid.FixedItem>
              <Grid.Item>
                <Heading>{accountsData?.bank?.name}</Heading>
                <Text color='Gray40'>{accountsData?.bank?.url}</Text>
              </Grid.Item>
            </Grid>
            {/* @ts-ignore: OPERATION BLEED STOPPER */}
            {isLinkSuccess ? <Success selectedAccount={selectedAccount} /> : <BankAccountsList data={accountsData} onSelect={handleSelect} />}
          </Modal.Content>
          <Modal.Footer>
            {isLinkSuccess ? (
              <Button padding='Fluid' onClick={closeModal} testNameSpace='ach-link-bank-account-success-cta'>
                {t('ach:linkBankAccount:success:CTA')}
              </Button>
            ) : (
              <Button
                padding='Fluid'
                onClick={handleSubmit}
                loading={isLinkLoading}
                testNameSpace='ach-link-bank-account-submit'
                disabled={typeof selectedAccount === 'undefined'}
              >
                {t('ach:linkBankAccount:CTA')}
              </Button>
            )}
          </Modal.Footer>
        </>
      ) : (
        <Modal.Content>
          <div className='h-48'>
            <Modal.Loader />
          </div>
        </Modal.Content>
      )}
    </>
  )
}
