import { Country } from '@afterpay/types'
import { Currency } from '@afterpaytouch/portal-api'

export function parseCountry(query: string | string[]): Country {
  const key = typeof query === 'string' ? query?.toUpperCase() : Array.isArray(query) ? query[0]?.toUpperCase() : undefined
  if (key === 'UK') {
    return Country.GB
  }
  return Object.values(Country).find((country) => key === country) ?? Country.AU
}

// @TODO: Build out country mapping logic below

// const getLocales = router.router?.locales.map(locale => ({ [locale.replace('-', '')]: locale }))
// export function localeToCountryCode (locale: string, country: Country): void {}

export interface CountryConfig {
  countryCode: Country
  currencyCode: Currency
  currencySymbol: string
  portalUrl: string
}

export interface PortalCountries {
  countries: CountryConfig[]
}

export const AUS_STATES_LIST = {
  NSW: 'New South Wales',
  VIC: 'Victoria',
  QLD: 'Queensland',
  WA: 'Western Australia',
  SA: 'South Australia',
  TAS: 'Tasmania',
  ACT: 'Australian Capital Territory',
  NT: 'Northern Territory',
} as const

export type AusStateKey = keyof typeof AUS_STATES_LIST
interface AusStatesList {
  key: AusStateKey
  value: (typeof AUS_STATES_LIST)[AusStateKey]
}

export interface CountryPathname {
  country?: string
}

const AustralianStatesList: AusStatesList[] = Object.keys(AUS_STATES_LIST)
  .filter((value): value is AusStateKey => isNaN(Number(value)))
  .map((key) => {
    return {
      key,
      value: AUS_STATES_LIST[key],
    }
  })

const COUNTRY_TO_STATES_LIST_MAP: Record<string, AusStatesList[] | []> = {
  AU: AustralianStatesList,
  NZ: [],
}

export const getStatesListByCountry = (country?: Country): AusStatesList[] | [] => {
  if (typeof country === 'undefined') {
    return []
  }

  return COUNTRY_TO_STATES_LIST_MAP[country]
}
