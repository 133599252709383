import React, { FunctionComponent, ReactNode, useMemo } from 'react'
import { Button, Grid, Text, Money as fMoney, useDate } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { PaymentStatus, SupportedLocale } from '@afterpaytouch/portal-api'
import { useGetTotalPayableHardshipRepayments } from '../../state'
import { Money } from '../Money'
import { PaymentArrangementSchedule } from '@afterpaytouch/portal-api/consumer/hardship/types'

const I18N_NAMESPACE = ['index', 'payments', 'upcomingPayments', 'common']

interface UpcomingHardshipPaymentItemProps {
  installmentSequence: number
  paymentItem: PaymentArrangementSchedule
  onSelect: () => {} | void
  locale: SupportedLocale
  isAllowedToBePaid: boolean
}

export const UpcomingHardshipPaymentItem: FunctionComponent<UpcomingHardshipPaymentItemProps> = ({
  isAllowedToBePaid,
  installmentSequence,
  paymentItem,
  onSelect,
  locale,
}) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const totalNumberOfPayments = useGetTotalPayableHardshipRepayments()
  const { overdueDatetime, outstandingBalance, paymentStatus } = paymentItem
  const { formatUnixDate } = useDate({ locale, year: 'numeric', month: 'short', weekday: 'short' })
  const dueDate = formatUnixDate(overdueDatetime)
  const isOverdue = paymentStatus === PaymentStatus.OVERDUE
  const textColor = isOverdue ? 'Fire' : 'Black'

  const action = useMemo((): ReactNode => {
    return isAllowedToBePaid ? (
      <Button size='Regular' testNameSpace='payNow' onClick={onSelect}>
        {t('index:payments:pay', { money: fMoney({ ...outstandingBalance, locale }) })}
      </Button>
    ) : (
      <>
        <Money bold value={outstandingBalance} size='M' />
        <Text>{t('common:accountStatus:onHold')}</Text>
      </>
    )
  }, [outstandingBalance, t, locale, onSelect, isAllowedToBePaid])

  return (
    <Grid center>
      <Grid.Item>
        <Text bold color={textColor}>
          {dueDate}
        </Text>
        <Text renderAs='span' color={textColor}>
          {t('upcomingPayments:hardshipProgress', { installment: installmentSequence, total: totalNumberOfPayments })}
          {isOverdue && ` • ${t('index:payments:overdue')}`}
        </Text>
      </Grid.Item>
      <Grid.Item sm='auto'>{action}</Grid.Item>
    </Grid>
  )
}
