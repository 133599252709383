import { Link, Text } from '@afterpaytouch/core'
import { Trans, useTranslation } from 'next-i18next'
import React, { useContext } from 'react'
import { useAmplitudeWithEnduringEventProperties } from '../../../../integrations/amplitude'
import { TrackingEvent } from '../../../../model/amplitude'
import { useIsPartialVerified } from '../../../../state'
import { IsRetryPageContext } from '../helper'

const I18N_NAMESPACE = ['common', 'verifyIdentity']

// @ts-ignore: OPERATION BLEED STOPPER
export const HardIdHelpLink: React.FC = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isRetryPage = useContext(IsRetryPageContext)
  const isRetryMode = useIsPartialVerified() && isRetryPage
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  return (
    isRetryMode && (
      <Text>
        <Trans
          i18nKey='verifyIdentity:partialVerified:help'
          components={{
            a: (
              <Link
                key='description-link'
                onClick={() => logEvent(TrackingEvent.PRESSED_HARDID_RETRY_PAGE_HELP_LINK)}
                target='_blank'
                kind='Underline'
                href={t('common:urls:identityHelpUrl')}
                testNameSpace='verifyIdentity'
              />
            ),
          }}
        />
      </Text>
    )
  )
}
