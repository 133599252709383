import { AxiosRequestHeaders } from 'axios'

export interface Authentication {
  authenticated: boolean
  persistent: boolean
  persistentOptIn?: boolean
  verificationRequired: boolean
}

export enum AuthenticationErrors {
  IncorrectPassword,
  AccountLocked,
  AccountDisabled,
  FailureMaxAttempts,
  TooManyOTPRequests,
  PaywatchCheckFailed,
  OtherError,
}

export interface FingerprintHeader extends AxiosRequestHeaders {
  'X-Device-Fingerprint': string
}

export interface FingerPrint {
  fingerprint: string
  language: string
  timezoneOffset: number
  resolution: string
}

export const unauthenticate = (): Authentication => ({
  authenticated: false,
  persistent: false,
  verificationRequired: false,
})

export const authenticate = (verificationRequired: boolean, persistent: boolean, persistentOptIn: boolean = false): Authentication => ({
  authenticated: true,
  verificationRequired,
  persistent,
  persistentOptIn,
})

export const persistentSession = (active: boolean, verify: boolean = false): Authentication => ({
  authenticated: active,
  verificationRequired: verify,
  persistent: active,
  persistentOptIn: false,
})
