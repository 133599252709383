import React, { FunctionComponent } from 'react'
import { Icon, Text } from '@afterpaytouch/core'
import { useConsumerSizes } from '../../../utils/responsive'
import styles from './style.module.scss'

export interface PaymentRowProps {
  numberMask: string
  name: string
  warning?: boolean
  linked?: boolean
  testNameSpace?: string
}

export const BankAccountItem: FunctionComponent<PaymentRowProps> = ({ numberMask, name, warning = false, linked = false, testNameSpace = '' }) => {
  const { textSize, smallTextSize } = useConsumerSizes()

  return (
    <>
      <div {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-bank-account-item` })} className={warning || linked ? 'opacity-50' : undefined}>
        <Text size={textSize}>{name}</Text>
        <Text size={smallTextSize}>{`•••••••••• ${numberMask}`}</Text>
        {warning && (
          <div className={styles.icon} {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-bank-account-item-warning` })}>
            <Icon.WarningAlt color='Fire' />
          </div>
        )}
        {linked && (
          <div className={styles.icon} {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-bank-account-item-linked` })}>
            <Icon.Link />
          </div>
        )}
      </div>
    </>
  )
}
