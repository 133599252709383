import { Divider, Heading, Skeleton, Text } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect } from 'react'
import { useGetCreditFacilityQuery, useGetCreditFacilitySummaryQuery } from '../../state'
import { useConsumerSizes } from '../../utils/responsive'
import { PayMonthlyLimit } from './PayMonthlyLimit'
import { PayMonthlyStatement } from './PayMonthlyStatement'
import { PayMonthlyCreditContract } from './PayMonthlyCreditContract'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { CreditFacilityDocumentTypes } from '@afterpaytouch/portal-api'
import { Money } from '../Money'
import { useFlag } from '../../hooks'
import { ClosePayMonthlyButton, ExtendedPanel } from '../'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'

const I18N_NAMESPACE = ['common', 'payMonthly']

export const PayMonthlyInfo: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { sectionHeadingSize, textSize, smallTextSize } = useConsumerSizes()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const { locale } = useRouter()
  const isClosePayMonthlyFlagEnabled = useFlag('consumer-portal-pay-monthly-close-credit-facility-enabled', false)

  const {
    data: creditFacilityResponse,
    isFetching: isGetCreditFacilityFetching,
    isLoading: isGetCreditFacilityLoading,
    isError: isGetCreditFacilityError,
    refetch: refetchGetCreditFacilityQuery,
  } = useGetCreditFacilityQuery()

  const {
    data: creditFacilitySummaryData,
    isLoading: isGetCreditFacilitySummaryLoading,
    isFetching: isGetCreditFacilitySummaryFetching,
    isError: isGetCreditFacilitySummaryError,
    refetch: refetchGetCreditFacilitySummary,
  } = useGetCreditFacilitySummaryQuery()

  const isSkeletonShown = isGetCreditFacilityFetching || isGetCreditFacilityLoading || isGetCreditFacilitySummaryLoading || isGetCreditFacilitySummaryFetching
  const creditFacility = creditFacilityResponse?.creditFacility

  const pclStatement = creditFacility?.documents.find((d) => d.type === CreditFacilityDocumentTypes.PCLStatement)
  const creditContract = creditFacility?.documents.find((d) => d.type === CreditFacilityDocumentTypes.CreditContract)
  const openDate = new Date(creditFacilityResponse?.creditFacility.openedDate ?? Date.now()).toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  const refetch = (): void => {
    if (isGetCreditFacilityError) {
      refetchGetCreditFacilityQuery()
    }
    if (isGetCreditFacilitySummaryError) {
      refetchGetCreditFacilitySummary()
    }
  }

  useEffect(() => {
    logEvent(TrackingEvent.CREDIT_FACILITY.ViewedClFacility)
  }, [])

  if (isGetCreditFacilityError || isGetCreditFacilitySummaryError) {
    return <ExtendedPanel.Error onClick={refetch} />
  }
  // TODO pay monthly closed status
  return (
    <>
      <Heading size={sectionHeadingSize}>{t('payMonthly:panel:content:details:heading')}</Heading>
      <div className='md:block lg:flex lg:flex-row'>
        <div className='mr-10 mt-7 max-w-md lg:flex lg:flex-grow lg:basis-1/2 lg:flex-col'>
          <PayMonthlyLimit creditFacility={creditFacility} isLoading={isSkeletonShown} />
          <div className='relative'>
            <div className='flex justify-between'>
              <Text bold>{t('payMonthly:panel:content:details:openedDate')}</Text>
              <MaskedElement>
                <Text>{openDate}</Text>
              </MaskedElement>
            </div>
            {isSkeletonShown && <Skeleton testNameSpace='pay-monthly-panel-openedDate-skeleton' />}
          </div>
          <Divider kind='Hairline' margin='Regular' />
          <PayMonthlyStatement pclStatement={pclStatement} />
          <PayMonthlyCreditContract creditContract={creditContract} isLoading={isSkeletonShown} />
          {/* TODO: Cleanup Credit Assessment references if unused after Pay Monthly launch - https://apglobal.atlassian.net/browse/FLE-2197 */}
          {/* <PayMonthlyCreditAssessment creditAssessment={creditAssessment} isLoading={isSkeletonShown} /> */}
          {isClosePayMonthlyFlagEnabled && (
            <div>
              <ClosePayMonthlyButton openDate={openDate} />
            </div>
          )}
        </div>
        <div className='relative ml-10  flex md:mt-3 md:justify-start lg:basis-1/2 lg:justify-center'>
          <MaskedElement>
            {isSkeletonShown ? (
              <Skeleton testNameSpace='pay-monthly-panel-summary-data-skeleton' />
            ) : (
              <div className='relative flex flex-col items-center rounded-xl px-10 shadow-[0_8px_32px_0px_rgba(0,0,0,0.08)]'>
                <div className=' mb-7 mt-6 max-w-xs text-center'>
                  <Heading size={sectionHeadingSize} testNameSpace='pay-monthly-limit-breakdown-modal'>
                    {t('payMonthly:limitBreakdownModal:heading')}
                  </Heading>
                </div>
                <div className='relative mb-2 flex w-full max-w-xs justify-between'>
                  <Text size={textSize} testNameSpace='pay-monthly-limit-breakdown-modal-limit'>
                    {t('payMonthly:limitBreakdownModal:label:creditFacilityLimit')}
                  </Text>
                  <Money
                    value={creditFacilitySummaryData?.facilityLimit ?? { amount: '0.00', currency: 'AUD' }}
                    bold
                    size={textSize}
                    testNameSpace='pay-monthly-limit-breakdown-modal-limit'
                  />
                </div>
                <div className='mb-2 flex w-full max-w-xs justify-between'>
                  <Text size={textSize} testNameSpace='pay-monthly-limit-breakdown-modal-owed-principal'>
                    {t('payMonthly:limitBreakdownModal:label:pclTotalOwedPrincipal')}
                  </Text>
                  <Money
                    value={creditFacilitySummaryData?.totalOwedPrincipal ?? { amount: '0.00', currency: 'AUD' }}
                    bold
                    size={textSize}
                    testNameSpace='pay-monthly-limit-breakdown-modal-owed-principal'
                  />
                </div>
                <div className='flex w-full max-w-xs justify-between'>
                  <Text size={textSize} testNameSpace='pay-monthly-limit-breakdown-modal-available-to-spend'>
                    {t('payMonthly:limitBreakdownModal:label:availableToSpendPCL')}
                  </Text>
                  <Money
                    value={creditFacilitySummaryData?.availableToSpend ?? { amount: '0.00', currency: 'AUD' }}
                    bold
                    size={textSize}
                    testNameSpace='pay-monthly-limit-breakdown-modal-available-to-spend'
                  />
                </div>
                <div className='mb-6 flex w-full max-w-xs justify-start'>
                  <Text size={smallTextSize}>{t('payMonthly:limitBreakdownModal:label:interestDisclaimer')}</Text>
                </div>
              </div>
            )}
          </MaskedElement>
        </div>
      </div>
    </>
  )
}
