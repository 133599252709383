import React, { FunctionComponent } from 'react'
import { Modal, Heading, Text, Button } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { useGetAmountDueSummaryQuery } from '../../state'
import { useConsumerSizes } from '../../utils/responsive'
import { Money } from '../Money'

const I18N_NAMESPACE = ['common', 'orders']

interface TotalOwingModalProps {
  isModalOpen: boolean
  closeModal: () => void
  testNameSpace?: string
}

export const TotalOwingModal: FunctionComponent<TotalOwingModalProps> = ({ isModalOpen, closeModal, testNameSpace = 'total-owing-modal' }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { sectionHeadingSize } = useConsumerSizes()
  const { data } = useGetAmountDueSummaryQuery()

  return (
    <Modal testNameSpace={testNameSpace} show={isModalOpen} onClose={closeModal} onHide={closeModal} onBackdropClick={closeModal} onEscapeKeyDown={closeModal}>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading size={sectionHeadingSize}>{t('orders:orderList:totalOwingModal:heading')}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className='grid grid-cols-[auto,auto] justify-between gap-y-5'>
          {data?.paymentPeriods?.map(({ period, periodUnit, total }) => (
            <React.Fragment key={period}>
              <Text renderAs='span'>{t('orders:orderList:totalOwingModal:periodTotal', { period, periodUnit })}</Text>
              <Money testNameSpace={`${testNameSpace}-${period}`} size='XL' bold value={total} />
            </React.Fragment>
          ))}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button testNameSpace={testNameSpace} padding='Fluid' kind='Secondary' onClick={closeModal}>
          {t('orders:orderList:totalOwingModal:cta:close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
