import React, { ReactNode } from 'react'
import { TFunction, Trans } from 'next-i18next'
import { Link, Money } from '@afterpaytouch/core'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { AmountDueSummaryResponse } from '@afterpaytouch/portal-api/consumer/amountDueSummary/types'
import { Route } from '../../../../config/router'
import { AccountStatus } from '../../../../model'
import { AppLink } from '../../../AppLink'
import { StatusMessage, StatusMessageProps } from './StatusMessage'
import { mapLocaleToCurrency } from '../../../Money/utils'

export interface IStatusMessages {
  [key: string]: StatusMessageProps
}

export const constructStatusMessages = (
  t: TFunction,
  locale: string,
  consumer: {
    amountDueSummary?: AmountDueSummaryResponse
  }
): IStatusMessages => {
  const paymentPeriodsTotal = consumer.amountDueSummary?.paymentPeriods?.find((pp) => pp.period === 30)?.total
  return {
    NoActiveOrders: {
      iconName: 'Calendar',
      textChild: t('index:welcome:existingUser:status:noActiveOrders', {
        amount: Money({
          ...{ amount: '0.00' },
          locale: locale as SupportedLocale,
          currency: mapLocaleToCurrency(locale as SupportedLocale),
        }),
      }),
    },
    ActiveOrders: {
      iconName: 'Calendar',
      textChild: t('index:welcome:existingUser:status:activeOrders', {
        amount: Money({
          ...(typeof paymentPeriodsTotal !== 'undefined' ? paymentPeriodsTotal : { amount: '0.00', currency: 'AUD', symbol: '$' }),
          locale: locale as SupportedLocale,
          currency: mapLocaleToCurrency(locale as SupportedLocale),
        }),
      }),
    },
    OverduePayment: {
      iconName: 'WarningAlt',
      color: 'Fire',
      textChild: t('index:welcome:existingUser:status:overduePayment'),
    },
    Hardship: {
      iconName: 'WarningAlt',
      color: 'Fire',
      textChild: (
        <Trans
          i18nKey={'index:welcome:existingUser:status:hardship'}
          components={{
            supportLink: <Link href={t('index:welcome:existingUser:status:supportLinkHref')} target='_blank' kind='Underline' />,
          }}
        />
      ),
    },
    Deactivated: {
      iconName: 'WarningAlt',
      color: 'Fire',
      textChild: (
        <Trans
          i18nKey={'index:welcome:existingUser:status:deactivated'}
          components={{
            accountLink: <AppLink route={Route.ACCOUNT__PROFILE} kind='Underline' />,
          }}
        />
      ),
    },
    WrittenOff: {
      iconName: 'WarningAlt',
      color: 'Fire',
      textChild: t('index:welcome:existingUser:status:writtenOff'),
    },
    BankAccountUnlink: {
      iconName: 'WarningAlt',
      color: 'Fire',
      textChild: (
        <Trans
          i18nKey={'index:welcome.existingUser.status.bankAccountUnlink'}
          components={{
            paymentMethodLink: <AppLink route={Route.ACCOUNT__PAYMENT_METHODS} kind='Underline' />,
          }}
        />
      ),
    },
  }
}

export const renderStatusMessage = (
  consumer: {
    accountStatus?: AccountStatus
    hasOverduePayments: boolean
    numActiveOrders: number
    isDeactivated: boolean
    isFrozen?: boolean
    hasBankAccountUnlinked?: boolean
  },
  statusMessages: { [key: string]: StatusMessageProps },
  isLoading: boolean
): ReactNode | ReactNode[] => {
  if (typeof consumer.accountStatus === 'undefined' || typeof consumer.hasBankAccountUnlinked === 'undefined') {
    return null
  }
  const isAccountFrozen =
    Boolean(consumer.isFrozen) ||
    /* What other account statuses should match this case? */
    [AccountStatus.HARDSHIP, AccountStatus.SUSPENDED, AccountStatus.PAYMENT_ARRANGEMENT].includes(consumer.accountStatus)

  const hasActiveOrders = consumer.numActiveOrders > 0

  // Show normal message
  if (
    consumer.accountStatus !== AccountStatus.WRITTEN_OFF &&
    !consumer.isDeactivated &&
    !isAccountFrozen &&
    !consumer.hasOverduePayments &&
    !consumer.hasBankAccountUnlinked
  ) {
    return hasActiveOrders ? (
      <StatusMessage {...statusMessages.ActiveOrders} isLoading={isLoading} />
    ) : (
      <StatusMessage {...statusMessages.NoActiveOrders} isLoading={isLoading} />
    )
  }

  // Show error messages
  const statusMessageArr = []

  if (consumer.accountStatus === AccountStatus.WRITTEN_OFF) {
    // @ts-ignore: OPERATION BLEED STOPPER
    statusMessageArr.push(<StatusMessage key='accountStatus-WrittenOff' {...statusMessages.WrittenOff} isLoading={isLoading} />)
  }

  if (consumer.isDeactivated) {
    // @ts-ignore: OPERATION BLEED STOPPER
    statusMessageArr.push(<StatusMessage key='accountStatus-Deactivated' {...statusMessages.Deactivated} isLoading={isLoading} />)
  }

  if (isAccountFrozen) {
    // @ts-ignore: OPERATION BLEED STOPPER
    statusMessageArr.push(<StatusMessage key='accountStatus-Hardship' {...statusMessages.Hardship} isLoading={isLoading} />)
  }

  if (consumer.hasOverduePayments) {
    // @ts-ignore: OPERATION BLEED STOPPER
    statusMessageArr.push(<StatusMessage key='accountStatus-OverduePayment' {...statusMessages.OverduePayment} isLoading={isLoading} />)
  }

  if (consumer.hasBankAccountUnlinked) {
    // @ts-ignore: OPERATION BLEED STOPPER
    statusMessageArr.push(<StatusMessage key='accountStatus-BankAccountUnlink' {...statusMessages.BankAccountUnlink} isLoading={isLoading} />)
  }

  return statusMessageArr
}
