import { Heading, Text, Button } from '@afterpaytouch/core'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { Route } from '../../../config/router'

const I18N_NAMESPACE = ['verifyIdentity']

export const HardIDCheckStart: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const router = useRouter()

  return (
    <>
      <Heading bold size='XL'>
        {t('verifyIdentity:hardIDCheckStart:title')}
      </Heading>
      <br />
      <Text>{t('verifyIdentity:hardIDCheckStart:detail')}</Text>
      <br />
      <div className='min-h-4'>
        <Button
          padding='Fluid'
          onClick={() => {
            router.push(Route.VERIFY_IDENTITY)
          }}
        >
          {t('verifyIdentity:hardIDCheckStart:cta')}
        </Button>
      </div>
    </>
  )
}
