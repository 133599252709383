import React, { FunctionComponent, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { Modal, Button, Text } from '@afterpaytouch/core'
import { AchFlowNavigation } from '../AchFlowNavigation'

import { AchFlowModalContext } from '../AchFlowModalContext'

const I18N_NAMESPACE = ['ach']

export const Error: FunctionComponent = () => {
  const { closeModal } = useContext(AchFlowModalContext)
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <>
      <Modal.Header divider>
        <AchFlowNavigation />
      </Modal.Header>
      <Modal.Content>
        <div className='mb-4'>
          <Text>{t('ach:plaid:error:detail')}</Text>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button padding='Fluid' onClick={() => closeModal()} testNameSpace='ach-plaid-error-cta'>
          {t('ach:plaid:error:CTA')}
        </Button>
      </Modal.Footer>
    </>
  )
}
