import React, { FunctionComponent, useCallback, useContext, useEffect } from 'react'
import { OrderRefundSteps, OrderRefundModalContext, OrderRefundModalProvider } from './OrderRefundModalContext'
import { Modal } from '@afterpaytouch/core'
import { ReturnType } from './ReturnType'
import { MailReturnDetails } from './MailReturnDetails'
import { MailReturnConfirm } from './MailReturnConfirm'
import { InstoreReturn } from './InstoreReturn'
import { OrderResponse } from '@afterpaytouch/portal-api'

interface OrderRefundModalProps {
  order: OrderResponse
  isModalOpen: boolean
  closeModal: () => void
  reopenGetHelpModal: () => void
  animate: boolean
  isOverdueOrComplete: boolean
}

export const OrderRefundModalSteps: FunctionComponent<OrderRefundModalProps> = ({
  order,
  isModalOpen,
  closeModal,
  reopenGetHelpModal,
  animate,
  isOverdueOrComplete,
}) => {
  const { step, resetState, isLoading, setOrder, setIsOverdueOrComplete } = useContext(OrderRefundModalContext)

  // @ts-ignore: OPERATION BLEED STOPPER
  const handleLoad = (): void => (isLoading ? null : closeModal())

  const renderStep = useCallback((): React.ReactNode => {
    switch (step) {
      case OrderRefundSteps.ReturnType:
        return <ReturnType reopenGetHelpModal={reopenGetHelpModal} />
      case OrderRefundSteps.MailReturnDetails:
        return <MailReturnDetails />
      case OrderRefundSteps.MailReturnConfirm:
        return <MailReturnConfirm />
      case OrderRefundSteps.InstoreReturn:
        return <InstoreReturn />
    }
  }, [step, reopenGetHelpModal])

  useEffect(() => {
    setOrder(order)
    setIsOverdueOrComplete(isOverdueOrComplete)
  }, [isModalOpen, setOrder, order, setIsOverdueOrComplete, isOverdueOrComplete])

  useEffect(() => {
    if (!isModalOpen) {
      resetState()
    }
  }, [isModalOpen, resetState])

  return (
    <Modal
      show={isModalOpen}
      onClose={handleLoad}
      onBackdropClick={handleLoad}
      onEscapeKeyDown={handleLoad}
      loading={isLoading}
      showCloseButton={false}
      animate={animate}
    >
      {renderStep()}
    </Modal>
  )
}

// @ts-ignore: OPERATION BLEED STOPPER
export const OrderRefundModal: FunctionComponent<OrderRefundModalProps> = ({
  order,
  isModalOpen = false,
  closeModal,
  reopenGetHelpModal,
  animate,
  isOverdueOrComplete,
}) => {
  return (
    isModalOpen && (
      <OrderRefundModalProvider closeModal={closeModal}>
        <OrderRefundModalSteps
          order={order}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          reopenGetHelpModal={reopenGetHelpModal}
          animate={animate}
          isOverdueOrComplete={isOverdueOrComplete}
        />
      </OrderRefundModalProvider>
    )
  )
}
