import React, { FunctionComponent, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Heading, Modal, Text, Radio } from '@afterpaytouch/core'
import { CloseAccountFlowContext, CloseAccountFlowSteps } from '../CloseAccountFlowContext'
import { CloseAccountReasons } from '@afterpaytouch/portal-api/consumer/account/types'
import { TrackOnMount, useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

const I18N_NAMESPACE = ['common', 'account']

export const SelectCloseAccountReason: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const closeAccountReasonsMap = {
    [CloseAccountReasons.BreakFromAfterpay]: t('account:closeAccount:closeAccountReason:option:1'),
    [CloseAccountReasons.MovingToDifferentService]: t('account:closeAccount:closeAccountReason:option:3'),
    [CloseAccountReasons.ApplyingForLoan]: t('account:closeAccount:closeAccountReason:option:2'),
    [CloseAccountReasons.Other]: t('account:closeAccount:closeAccountReason:option:4'),
  }
  const { setStep, closeAccountReason, setCloseAccountReason } = useContext(CloseAccountFlowContext)

  const testNameSpace = 'close-account-reason'

  const handleSelectCloseAccountReason = (e): void => {
    logEvent(TrackingEvent.SELECTED_CLOSE_ACCOUNT_REASON, {
      reason: e.target.value,
    })
    setCloseAccountReason(e.target.value)
  }

  return (
    <>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading style={{ textAlign: 'left' }} size='M'>
            {t('account:closeAccount:closeAccountReason:header')}
          </Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Text>{t('account:closeAccount:closeAccountReason:content.0')}</Text>
        <div className='flex flex-col content-around'>
          <div className='my-4'>
            <div className='mb-2'>
              <Heading size='M'>{t('account:closeAccount:closeAccountReason:reason:header')}</Heading>
            </div>
            <Radio.Group
              id={`${testNameSpace}-options`}
              name={`${testNameSpace}-options`}
              testNameSpace={`${testNameSpace}-options`}
              onClick={handleSelectCloseAccountReason}
            >
              {Object.keys(closeAccountReasonsMap).map((itemKey, i) => (
                <Radio.Button
                  testNameSpace={`${testNameSpace}-option-${i}`}
                  key={itemKey}
                  value={itemKey}
                  kind='Option'
                  icon='Select'
                  labelWidth='Full'
                  label={closeAccountReasonsMap[itemKey]}
                />
              ))}
            </Radio.Group>
          </div>
          <Button
            onClick={() => {
              setStep(CloseAccountFlowSteps.SelectCloseAccountOption)
            }}
            disabled={closeAccountReason == null}
            testNameSpace={testNameSpace}
          >
            {t('account:closeAccount:selectCloseAccountReason:continueButton')}
          </Button>
        </div>
        <TrackOnMount eventName={TrackingEvent.VIEWED_CLOSE_MY_ACCOUNT} />
      </Modal.Content>
    </>
  )
}
