import { FingerPrint } from '../model'
import fingerprint from '@fingerprintjs/fingerprintjs'
import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { createPortalApi } from '../state'

export const createFingerPrint = async (): Promise<FingerPrint> => {
  const fp = await fingerprint.load()
  const result = await fp.get()
  return {
    fingerprint: fingerprint.hashComponents(result.components),
    // @ts-ignore: OPERATION BLEED STOPPER
    language: result.components.languages.value[0][0],
    // @ts-ignore: OPERATION BLEED STOPPER
    timezoneOffset: result.components.timezoneOffset.value,
    // @ts-ignore: OPERATION BLEED STOPPER
    resolution: result.components.screenResolution.value.join('x'),
  }
}

// @ts-ignore: OPERATION BLEED STOPPER
export const getBaseQuery: BaseQueryFn<
  {
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data'] // @TODO: Data to go into request body
  },
  unknown,
  FetchBaseQueryError
> = async (args, api) => {
  const { url, method } = args
  try {
    // @ts-ignore: OPERATION BLEED STOPPER
    const result = await createPortalApi()[method](url)
    return { data: result }
  } catch (e) {
    const error = e as AxiosError // @TODO: Double check this return response
    return {
      error: { status: error.response?.status, data: error.response?.data },
    }
  }
}
