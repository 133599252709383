import { Button, Heading, Modal, Text, Logo, Money, Skeleton, useIsCashTheme } from '@afterpaytouch/core'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'
import { useConsumerCreditLimitOverride, useGetCreditLimitQuery } from '../../../../../state'
import styles from './style.module.scss'
import { ExtendedPanel } from '../../../../Panel'

const I18N_NAMESPACE = ['common', 'index']

interface FirstPurchaseModalProps {
  isModalOpen: boolean
  closeModal: () => void
}

export const FirstPurchaseModal: FunctionComponent<FirstPurchaseModalProps> = ({ isModalOpen, closeModal }) => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(I18N_NAMESPACE)
  const isCashTheme = useIsCashTheme()

  const {
    data: creditLimitData,
    isLoading: isCreditLimitLoading,
    isFetching: isCreditLimitFetching,
    isError: isCreditLimitError,
    refetch: refetchGetCreditLimitQuery,
  } = useGetCreditLimitQuery()

  const consumer = {
    // @ts-ignore: OPERATION BLEED STOPPER
    availableToSpend: useConsumerCreditLimitOverride(creditLimitData?.creditLimit),
  }

  const isSkeletonShown = isCreditLimitLoading || isCreditLimitFetching

  return (
    <Modal
      show={isModalOpen}
      onClose={closeModal}
      onHide={closeModal}
      onBackdropClick={closeModal}
      backgroundColor='Bondi Mint'
      backdropKind='Darker'
      testNameSpace='first-purchase'
    >
      <Modal.Header>
        <div className={styles.headerLogoContainer}>
          <Logo size='Large' name={t('common:brandName')} title={'common:brandName'} description={'common:brandName'} />
        </div>
      </Modal.Header>
      {!isCreditLimitError && (
        <Modal.Content>
          <div className={styles.contentHeadingContainer}>
            <Heading size='XXL'>{t('index:welcome:firstPurchase:modal:content:heading')}</Heading>
          </div>
          <div className={styles.contentText1Container}>
            <Text>{t('index:welcome:firstPurchase:modal:content:amountLabel')}</Text>
          </div>
          <div className={styles.contentAmountContainer}>
            <Heading size='XXXL'>
              {isSkeletonShown && <Skeleton kind={isCashTheme ? 'Default' : 'Primary'} />}
              {Money({
                ...consumer.availableToSpend,
                locale: locale as SupportedLocale,
              })}
            </Heading>
            {!isSkeletonShown && <div className={styles.contentAmountUnderline} />}
          </div>
          <div className={styles.contentText2Container}>
            <Heading size='M'>{t('index:welcome:firstPurchase:modal:content:amountExplainHeading')}</Heading>
            <Text>{t('index:welcome:firstPurchase:modal:content:amountExplainBody')}</Text>
          </div>
          <div className={styles.contentText3Container}>
            <Heading size='M'>{t('index:welcome:firstPurchase:modal:content:fyiHeading')}</Heading>
            <Text>{t('index:welcome:firstPurchase:modal:content:fyiBody')}</Text>
          </div>
        </Modal.Content>
      )}
      <Modal.Footer>
        {isCreditLimitError ? (
          <ExtendedPanel.Error onClick={refetchGetCreditLimitQuery} />
        ) : (
          <Button kind='Secondary' padding='Fluid' onClick={closeModal}>
            {t('index:welcome:firstPurchase:modal:footer:cta')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
