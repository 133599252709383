import React, { FunctionComponent, ReactNode } from 'react'
import { useAuthenticatedGlobalFetch } from '../../hooks'

interface AuthenticatedGlobalFetchProviderProps {
  children: ReactNode
}

export const AuthenticatedGlobalFetchProvider: FunctionComponent<AuthenticatedGlobalFetchProviderProps> = ({ children }) => {
  useAuthenticatedGlobalFetch()
  return <>{children}</>
}
