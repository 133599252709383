import { consumerApplePayService } from '@afterpaytouch/portal-api/consumer/account/applepay'
import { ApplePayHelper } from './helper'
import { useConsumerSelector, ConsumerAppSelector, useAppSelector, useAppDispatch } from '..'
import { useStore } from 'react-redux'
import { useRouter } from 'next/router'
import { OrderChannel, PreferredPaymentMethod } from '../../model'
import { applePayService } from '@afterpaytouch/portal-api/consumer/applePay'
import { ApplePayState, checkIfApplePayAvailable, setIsEnabledForMPANFlow } from '.'
import { createSelector } from '@reduxjs/toolkit'
import { useFlag } from '@afterpaytouch/feature'
import { LaunchDarklyFlags } from '../../hooks/useFlag'
import { useEffect } from 'react'

/**
 * Hooks
 */

export const {
  useValidateMerchantMutation,
  useCaptureApplePayTokenMutation,
  endpoints: { validateMerchant, captureApplePayToken },
} = applePayService

export const { useSetApplePayPreferredMutation } = consumerApplePayService

export const useApplePayState = (): ApplePayState => useAppSelector(applePaySelector)

export const useIsApplePayAvailable = (): boolean | undefined => {
  const { isApplePayAvailable } = useApplePayState()
  return isApplePayAvailable
}

export const useIsApplePayAvailableAndPreferred = (): boolean => {
  const { preferredPaymentMethod } = useConsumerSelector()
  const isPreferredMethod = preferredPaymentMethod === PreferredPaymentMethod.APPLE_PAY
  const { isApplePayAvailable } = useApplePayState()

  return Boolean(isApplePayAvailable) && isPreferredMethod
}

interface useApplePayReturn {
  isApplePayAvailable: boolean
  applePayInstance: ApplePayHelper
}

export const useApplePay = (): useApplePayReturn => {
  const { isApplePayAvailable } = useApplePayState()
  const store = useStore()
  const { locale } = useRouter()

  // @ts-ignore: OPERATION BLEED STOPPER
  return { isApplePayAvailable, applePayInstance: ApplePayHelper.getInstance({ store, locale }) }
}

export const useSetupApplePay = (): void => {
  const isEnabledForMPANApplePayFlow = useFlag(LaunchDarklyFlags.EnableMpanApplePayFlow, false)
  const isApplePayAvailable = useIsApplePayAvailable()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setIsEnabledForMPANFlow(isEnabledForMPANApplePayFlow))

    if (typeof isApplePayAvailable === 'undefined') {
      dispatch(checkIfApplePayAvailable(isEnabledForMPANApplePayFlow))
    }
  }, [dispatch, isEnabledForMPANApplePayFlow, isApplePayAvailable])
}

/**
 * Selectors
 */
export const applePaySelector: ConsumerAppSelector<ApplePayState> = (state) => state.applepay

// @ts-ignore: OPERATION BLEED STOPPER
export const orderChannelSelector: ConsumerAppSelector<OrderChannel> = createSelector(applePaySelector, (applePay) => applePay.transactionData.orderChannel)
// @ts-ignore: OPERATION BLEED STOPPER
export const transactionIdSelector: ConsumerAppSelector<string> = createSelector(applePaySelector, (applePay) => applePay.transactionData.transactionId)
// @ts-ignore: OPERATION BLEED STOPPER
export const traceIdSelector: ConsumerAppSelector<string> = createSelector(applePaySelector, (applePay) => applePay.transactionData.traceId)

export const applePayMPANFlowSelector: ConsumerAppSelector<boolean> = createSelector(applePaySelector, (applePay) => applePay.isEnabledForMPANFlow ?? false)

/**
 * Hooks
 */

export const useTraceId = (): string => useAppSelector(traceIdSelector)
