// @TODO: Look into what can be hoisted here

import { AxiosError } from 'axios'
import { NextPage, GetServerSideProps } from 'next'
import { SSRConfig } from 'next-i18next'
import { ErrorCode } from '@afterpaytouch/portal-api/types'

export * from './auth'
export * from './convergence'
export * from './country'
export * from './state'
export * from './dispute'
export * from './cardScan'
export * from './identity'
export * from './consumer'

export interface PortalApiError {
  errorCode: ErrorCode
  errorId: string
  message: string
  httpStatusCode: number
  errorInfo: any
}
// @TODO: Remove in favour of portal-api isAxiosError
export const isAxiosError = (e: Error): e is AxiosError => (e as AxiosError).isAxiosError ?? false
// @TODO: Remove in favour of portal-api isPortalApiError
export const isPortalApiError = (e: any): e is PortalApiError => typeof e?.errorCode === 'string'
// @TODO: Remove in favour of portal-api isPortalAxiosError
export const isPortalAxiosError = (e: Error): e is AxiosError<PortalApiError> & { response: PortalApiError } =>
  isAxiosError(e) && isPortalApiError(e.response?.data)

export type ConsumerPage<T = {}> = NextPage<T & SSRConfig> & {
  getLayout?: (page: any) => JSX.Element
}

export * from '@afterpaytouch/portal-api/types'

export type ConsumerProps<T> = T & SSRConfig

export type GetConsumerSSP<T = {}> = GetServerSideProps<ConsumerProps<T>>
