import { useToast } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent, useContext, useEffect } from 'react'
import { TrackingEvent } from '../../../model/amplitude'
import { useCreateRelinkTokenMutation, useValidateBankAccountMutation } from '../../../state'
import { AchFlowModalContext } from '../AchFlowModalContext'
import { achOauthFlowSessionStorageInstance, BasePlaid, Error } from '../BasePlaid'
import { handleErrorStatus } from '../BasePlaid/utils'
import { ACH_FLOW_BANK_ACCOUNT_TO_BE_RELINKED_ID } from '../BasePlaid/BasePlaid'

/**
 *  Solution Review
 *  Pass the redirect url to the server and get the Plaid link token =>
 *  Open the Plaid in the iFrame, redirect back to /plaid-finished page once consumer finishes =>
 *  On the /plaid-finished page post the message to the window =>
 *  On this Page, listen the window message =>
 *  Call validation api =>
 *  Jump to the success state or error state of relink step
 */
const I18N_NAMESPACE = ['ach']

export const RelinkPlaid: FunctionComponent<{ onSuccess?: () => void }> = ({ onSuccess }) => {
  const [validateBankAccount, { isError, isLoading, isSuccess }] = useValidateBankAccountMutation()
  const { setResult, setShowCloseButton, setIsLoading, bankAccountToBeRelinked, closeModal } = useContext(AchFlowModalContext)
  const setToast = useToast()
  const { t } = useTranslation(I18N_NAMESPACE)

  const setErrorStatus = handleErrorStatus({ setResult, setIsLoading, setShowCloseButton })
  const onHandlePlaidFlowFinished = (): any => {
    validateBankAccount({
      achBankAccountId: bankAccountToBeRelinked?.id ?? achOauthFlowSessionStorageInstance.getSessionStorageByItemKey(ACH_FLOW_BANK_ACCOUNT_TO_BE_RELINKED_ID),
    })
  }

  useEffect(() => {
    if (isSuccess) {
      // @ts-ignore: OPERATION BLEED STOPPER
      onSuccess()
      setToast({
        message: t('ach:relinkBankAccount:success:toast'),
        kind: 'Success',
      })
      closeModal()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setErrorStatus()
    }
  }, [isError])

  useEffect(() => {
    if (isLoading) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoading])

  if (isError) {
    return <Error />
  }

  const amplitudeEvents = {
    onError: TrackingEvent.ACH.RelinkBankAccountInPlaidError,
    onSuccess: TrackingEvent.ACH.RelinkBankAccountInPlaidSuccess,
    onOauthError: TrackingEvent.ACH.ViewAddBankAccountOauthError,
    onOauthSuccess: TrackingEvent.ACH.SelectedContinueInPlaidOauthSuccess,
  }

  return (
    <BasePlaid
      useCreateToken={useCreateRelinkTokenMutation}
      onHandlePlaidFlowFinished={onHandlePlaidFlowFinished}
      amplitudeEvents={amplitudeEvents}
      bankAccountId={bankAccountToBeRelinked?.id}
    />
  )
}
