import React, { FunctionComponent, useContext, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { Modal, Button, Icon } from '@afterpaytouch/core'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { AchFlowSteps, AchFlowModalContext } from '../AchFlowModalContext'
import { AchFlowNavigation } from '../AchFlowNavigation'
import { useCheckAchEligibleQuery } from '../../../state'

const I18N_NAMESPACE = ['ach']

export const SelectPaymentMethodType: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const amplitude = useAmplitudeWithEnduringEventProperties()

  const { setStep, setEligible } = useContext(AchFlowModalContext)
  const { data, isLoading, isError } = useCheckAchEligibleQuery()

  useEffect(() => {
    amplitude.logEvent(TrackingEvent.ACH.ViewSelectNewPaymentMethod)
  }, [])

  useEffect(() => {
    typeof data !== 'undefined' && !isLoading && setEligible(data.addBankAccount)
  }, [data, isLoading])

  return (
    <>
      <Modal.Header divider>
        <AchFlowNavigation />
      </Modal.Header>
      <Modal.Content>
        <div className='mb-6 mt-2'>
          <Button.Ghost
            loading={isLoading}
            disabled={isError || isLoading}
            padding='Fluid'
            onClick={() => {
              setStep(AchFlowSteps.AddBankAccount)
              amplitude.logEvent(TrackingEvent.ACH.SelectedPaymentMethodType, { paymentMethod: 'bank' })
            }}
            testNameSpace='ach-add-bank-account'
          >
            <Icon.Bank size='Small' />
            {t('ach:selectPaymentMethodType:account')}
          </Button.Ghost>
        </div>
        <Button.Ghost
          padding='Fluid'
          onClick={() => {
            setStep(AchFlowSteps.AddCard)
            amplitude.logEvent(TrackingEvent.ACH.SelectedPaymentMethodType, { paymentMethod: 'card' })
          }}
          testNameSpace='ach-add-credit-card'
        >
          <Icon.Card size='Small' />
          {t('ach:selectPaymentMethodType:card')}
        </Button.Ghost>
      </Modal.Content>
    </>
  )
}
