import { useTranslation } from 'next-i18next'
import { Heading, Link, Tag, Text, Skeleton, Icon, Span, Panel, useIsCashTheme } from '@afterpaytouch/core'
import { Money as IMoney } from '@afterpaytouch/portal-api'
import React, { FunctionComponent } from 'react'
import Image from 'next/image'
import { Money } from '../../'
import { PayMonthlyLimitBreakdownModal, usePayMonthlyLimitBreakdownModal } from './PayMonthlyLimitBreakdownModal'
import clsx from 'clsx'
import { useConsumerHasCreditFacility } from '../../../state'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { useDisplayIllustrations } from '../../../state/convergence/hooks'

const I18N_NAMESPACE = ['common', 'index']

interface MonthlyPaymentsCardProps {
  consumer: {
    totalOwedForPCL?: IMoney
    creditFacilityLimit?: IMoney
    pclTotalOwedPrincipal?: IMoney
    availableToSpendPCL?: IMoney
  }
  isLoading: boolean
}

export const MonthlyPaymentsCard: FunctionComponent<MonthlyPaymentsCardProps> = ({ consumer, isLoading }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isCashTheme = useIsCashTheme()

  const isDisplayIllustrations = useDisplayIllustrations()
  const [isPayMonthlyLimitBreakdownModalOpen, { openModal: openPayMonthlyLimitBreakdownModal, closeModal: closePayMonthlyLimitBreakdownModal }] =
    usePayMonthlyLimitBreakdownModal()
  const hasCreditFacility = useConsumerHasCreditFacility()

  const containerClassNames = clsx('relative grid h-full w-full [&>*:first-child]:grid', hasCreditFacility && 'cursor-pointer')
  const containerOnClick = hasCreditFacility ? openPayMonthlyLimitBreakdownModal : undefined

  return (
    <>
      <div className={containerClassNames} data-testid='monthly-payments-card-container' onClick={containerOnClick}>
        <Panel>
          <Panel.Content inset>
            <div className='min-w-100 py-4 pl-3.5 sm:max-w-full sm:pr-4 md:pr-3' data-testid='monthly-payments-card'>
              <div className='flex flex-row'>
                <div className='mr-1 flex min-w-fit items-center justify-center'>
                  <Heading lineClamp={1} size='S'>
                    {hasCreditFacility ? t('index:welcome:existingUser:monthlyPayments:heading') : t('index:welcome:existingUser:monthlyPayments:heading-none')}
                  </Heading>
                </div>
                {!hasCreditFacility && (
                  <div className='flex min-w-fit items-center'>
                    <Tag.Mint testNameSpace='monthly-payments-card'>{`${t('index:welcome:existingUser:monthlyPayments:tag')}`}</Tag.Mint>
                  </div>
                )}
              </div>
              <div className='flex flex-row items-center justify-between'>
                <div>
                  <MaskedElement>
                    <div className='mt-2 flex flex-row'>
                      {hasCreditFacility ? (
                        <Money value={consumer.totalOwedForPCL ?? { amount: '0.00', currency: 'AUD' }} size='XL' bold testNameSpace='monthly-payments-card' />
                      ) : (
                        <Text lineClamp={2} color='Gray50' size='XXS' testNameSpace='monthly-payments-card'>
                          {t('index:welcome:existingUser:monthlyPayments:body')}
                        </Text>
                      )}
                    </div>
                  </MaskedElement>
                  <MaskedElement>
                    <div className='mt-1 flex flex-grow-0'>
                      {hasCreditFacility ? (
                        <div className='flex flex-wrap gap-1'>
                          <Span lineClamp={1} color='Gray50' size='XXS'>
                            {t('index:welcome:existingUser:monthlyPayments:availableToSpend')}
                          </Span>
                          <Money value={consumer.availableToSpendPCL ?? { amount: '0.00', currency: 'AUD' }} size='XXS' color='Gray50' />
                          <Icon.Info color='Gray50' size='ExtraSmall' />
                        </div>
                      ) : (
                        <Text size='XXS' color='Gray10'>
                          <Link kind='Underline' bold={false} target='_blank' href={t('common:urls:payMonthlyUrl')}>
                            {t('index:welcome:existingUser:monthlyPayments:learnMore')}
                          </Link>
                        </Text>
                      )}
                    </div>
                  </MaskedElement>
                </div>
                {isDisplayIllustrations && !hasCreditFacility && (
                  <div className='h-14 w-14 flex-shrink-0 justify-center rounded-md text-center'>
                    <div className='flex h-full w-full min-w-fit items-center justify-center'>
                      <Image
                        width={56}
                        height={56}
                        src={`/images/cha-ching.svg`}
                        alt={t('index:welcome:existingUser:monthlyPayments:heading-none')}
                        data-testid='monthly-payments-image'
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Panel.Content>
        </Panel>
        {isLoading && <Skeleton kind={isCashTheme ? 'Default' : 'Primary'} />}
      </div>
      {isPayMonthlyLimitBreakdownModalOpen && (
        <PayMonthlyLimitBreakdownModal isModalOpen={isPayMonthlyLimitBreakdownModalOpen} closeModal={closePayMonthlyLimitBreakdownModal} consumer={consumer} />
      )}
    </>
  )
}
