import React, { FunctionComponent } from 'react'
import { Button, Heading, Link, Text } from '@afterpaytouch/core'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useConsumerSizes } from '../../utils/responsive'
import { useDisplayIllustrations } from '../../state/convergence/hooks'
const I18N_NAMESPACE = ['common', 'orders']

interface OrderAutopayProps {
  testNameSpace?: string
  onHandleTurnOnAutopay: () => void
}

export const OrderAutopay: FunctionComponent<OrderAutopayProps> = ({ testNameSpace = 'autopay-pcl', onHandleTurnOnAutopay = () => {} }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { textSize } = useConsumerSizes()
  const isDisplayIllustrations = useDisplayIllustrations()

  return (
    <div className={'bg-dark-prominent rounded-[12px] p-4'} {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}` })}>
      <div className={'flex flex-row justify-between'}>
        {isDisplayIllustrations && (
          <div className={'mr-2 flex min-w-fit items-center justify-center'}>
            <Image
              width={47}
              height={41}
              src={`/images/autopay-icon.svg`}
              alt={t('orders:orderAutopay:autopay')}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </div>
        )}
        <div>
          <div className={'flex flex-row'}>
            <Heading size={textSize} testNameSpace={testNameSpace}>{`${t('orders:orderAutopay:header')}`}</Heading>
          </div>
          <div className={'flex flex-row'}>
            <Text size={'XS'} color='Gray40' testNameSpace={`${testNameSpace}-subheader`}>{`${t('orders:orderAutopay:subheader')}`}</Text>
          </div>
          <div className={'flex flex-wrap md:gap-x-1'}>
            <Text size={'XS'} color='Gray60' testNameSpace={`${testNameSpace}-body`}>
              {`${t('orders:orderAutopay:body')}`}
              <Link
                size={'XS'}
                kind={'Underline'}
                testNameSpace={testNameSpace}
                href={t('orders:orderAutopay:paymentAuthorisationUrl:PCL')}
                target='_blank'
              >{`${t('orders:orderAutopay:paymentAuthorisation')}`}</Link>
            </Text>
          </div>
        </div>
        <div className={'flex flex-row items-center lg:ml-2'}>
          <Button.Secondary testNameSpace={testNameSpace} size='Small' onClick={onHandleTurnOnAutopay} aria-label={t('orders:orderAutopay:header')}>
            {t('orders:orderAutopay:cta')}
          </Button.Secondary>
        </div>
      </div>
    </div>
  )
}
