import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Text, Icon } from '@afterpaytouch/core'
import styles from './style.module.scss'

const I18N_NAMESPACE = ['common', 'index']

interface CreditLimitButtonProps {
  openModal: () => void
}

export const CreditLimitButton: FunctionComponent<CreditLimitButtonProps> = ({ openModal }) => {
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <>
      <div>
        <Text renderAs='span' size='M' bold>
          {t('index:welcome:existingUser:label:estAvailable')}
        </Text>
        <Button.Link onClick={openModal} aria-label={t('index:welcome:existingUser:htmlAttribute:ariaLabel:openModal')} testNameSpace='credit-limit-button'>
          <span className={styles['ml-0_50']}>
            <Icon.Info
              size='Small'
              role='img'
              title={t('index:welcome:existingUser:htmlAttribute:icon:info:title')}
              description={t('index:welcome:existingUser:htmlAttribute:icon:info:description')}
            />
          </span>
        </Button.Link>
      </div>
    </>
  )
}
