import { TFunction } from 'next-i18next'
import { DropdownItems, FieldType, Schema } from '@afterpaytouch/form'
import { dobValidation, expiryDateValidation, nameMaxLengthValidation, namePattern, requiredValidation } from '../VerifyIdentity/validations'
import { IdentityPassportCountries } from '@afterpaytouch/portal-api/identity/types'
import { PassportFormData } from './Passport'
import { InputOnFocus } from '@afterpaytouch/core'
import { getDateSchema, getDropdownFieldSchema, getInputBirthdaySchema, getInputFieldSchema } from '../VerifyIdentity/utils'
import { RegisterOptions } from 'react-hook-form'
import { ConsumerAccountResponse, Country } from '@afterpaytouch/portal-api'
import { COUNTRY_FIELDS_HIDDEN } from './constants'
import { passportPattern } from './validations'

export interface GetSchemaAndFormDataResult<T> {
  // @ts-ignore: OPERATION BLEED STOPPER
  schema: Schema<T>
  initialFormData: T
}

const setHiddenFieldsByCountry = (schema: Schema<PassportFormData>, country: Country): void => {
  const hiddenFields = COUNTRY_FIELDS_HIDDEN[country] ?? []
  Object.entries(schema).forEach(([fieldName]) => {
    const isHidden: boolean = hiddenFields.includes(fieldName)

    if (isHidden) {
      schema[fieldName].show = false
    }
  })
}

const getFormData = (consumer: ConsumerAccountResponse | undefined): PassportFormData => {
  return {
    givenNames: consumer?.givenNames ?? '',
    otherGivenNames: consumer?.otherGivenNames ?? '',
    surname: consumer?.surname ?? '',
    // @ts-ignore: OPERATION BLEED STOPPER
    nameAlert: undefined,
    dateOfBirth: consumer?.dateOfBirth ?? '',
    countryOfIssue: '',
    passportNumber: '',
    expiryDate: '',
    // @ts-ignore: OPERATION BLEED STOPPER
    terms: undefined,
    // @ts-ignore: OPERATION BLEED STOPPER
    footer: undefined,
  }
}

export const transformCountries = (permittedCountries: IdentityPassportCountries): DropdownItems[] => {
  if (typeof permittedCountries === 'undefined' || permittedCountries === null) {
    // @ts-ignore: OPERATION BLEED STOPPER
    return null
  }
  return Object.entries(permittedCountries)?.map(([key, value]) => ({
    key,
    label: value,
  }))
}

interface SchemaUpdates {
  passportOptions: RegisterOptions
  showProfileDobAlert: boolean
  showProfileNameAlert: boolean
  permittedCountries: IdentityPassportCountries
}

interface PassportSchemaOptions {
  consumer: ConsumerAccountResponse
  permittedCountries: IdentityPassportCountries
  schemaUpdates: SchemaUpdates
  components: any
  t: TFunction
  onBlur: (e: InputOnFocus, v: unknown) => void
  isProfileEditable: boolean
  error: boolean
}

export const getFormSchema = (opts: PassportSchemaOptions): GetSchemaAndFormDataResult<PassportFormData> => {
  const { consumer, permittedCountries, components, t, onBlur, schemaUpdates, error, isProfileEditable } = opts
  const { countryCode, preferredLocale } = consumer
  const { showProfileDobAlert, showProfileNameAlert, passportOptions } = schemaUpdates
  const initialFormData = getFormData(consumer)
  const { givenNames, otherGivenNames, surname, dateOfBirth } = initialFormData
  const required = requiredValidation(t)
  const namePatterns = namePattern(t)
  const dobValidateOptions = dobValidation(t)
  const nameMaxLengthOptions = nameMaxLengthValidation(t)
  const expiryOptions = expiryDateValidation()

  const schema: Schema<PassportFormData> = {
    givenNames: getInputFieldSchema({
      label: `${t('verifyIdentity:passport:givenNames')}`,
      value: givenNames,
      inline: true,
      // @ts-ignore: OPERATION BLEED STOPPER
      options: {
        ...required('givenNames'),
        ...namePatterns('givenNames'),
        ...nameMaxLengthOptions('givenNames', countryCode),
      },
      otherProps: {
        onBlur,
      },
      error,
      show: isProfileEditable,
    }),
    otherGivenNames: getInputFieldSchema({
      label: `${t('verifyIdentity:passport:otherGivenNames')}`,
      value: otherGivenNames,
      inline: true,
      // @ts-ignore: OPERATION BLEED STOPPER
      options: {
        ...namePatterns('otherGivenNames'),
        ...nameMaxLengthOptions('otherGivenNames', countryCode),
      },
      otherProps: {
        onBlur,
      },
      error,
      show: isProfileEditable,
    }),
    surname: getInputFieldSchema({
      label: `${t('verifyIdentity:passport:surname')}`,
      value: surname,
      // @ts-ignore: OPERATION BLEED STOPPER
      options: {
        ...required('surname'),
        ...namePatterns('surname'),
        ...nameMaxLengthOptions('surname', countryCode),
      },
      otherProps: {
        onBlur,
      },
      error,
      show: isProfileEditable,
    }),
    nameAlert: {
      type: FieldType.Component,
      show: showProfileNameAlert,
      Component: components.nameAlert,
    },
    dateOfBirth: getInputBirthdaySchema({
      label: `${t('verifyIdentity:passport:dateOfBirth')}`,
      value: dateOfBirth,
      options: { ...required('dateOfBirth') },
      otherValidations: dobValidateOptions('dateOfBirth'),
      locale: preferredLocale,
      error,
      show: isProfileEditable,
    }),
    dobAlert: {
      type: FieldType.Component,
      show: showProfileDobAlert,
      Component: components.dobAlert,
    },
    countryOfIssue: getDropdownFieldSchema({
      label: `${t('verifyIdentity:passport:countryOfIssue')}`,
      options: { ...required('countryOfIssue') },
      items: transformCountries(permittedCountries),
      error,
      show: isProfileEditable,
    }),
    profileCard: {
      type: FieldType.Component,
      Component: components.profileCard,
      show: !isProfileEditable,
    },
    passportNumber: getInputFieldSchema({
      label: `${t('verifyIdentity:passport:number')}`,
      // @ts-ignore: OPERATION BLEED STOPPER
      options: {
        ...required('passportNumber'),
        ...passportOptions,
        ...(countryCode === Country.NZ && passportPattern({ countryOfIssue: Country.NZ, t })),
      },
      error,
    }),
    expiryDate: getDateSchema({
      label: `${t('verifyIdentity:passport:expiryDate')}`,
      format: 'DD/MM/YYYY',
      options: {
        ...required('passportExpiryDate'),
        ...expiryOptions(t('verifyIdentity:error:invalid', { context: 'passportExpiryDate' })),
      },
      show: consumer.countryCode === Country.NZ,
    }),
    terms: {
      type: FieldType.Component,
      Component: components.terms,
    },
    footer: {
      type: FieldType.Component,
      Component: components.footer,
    },
  }

  setHiddenFieldsByCountry(schema, countryCode)

  return { schema, initialFormData }
}
