import { useEffect } from 'react'
import { createModalHook, useFlag } from '../../../hooks'
import { useGetConsumerTagsWithDataQuery, useCreateConsumerTagMutation, useUpdateConsumerTagMutation } from '../../../state'

export const useWelcomeModal = createModalHook()

export type OpenWelcomeModal = ReturnType<typeof useWelcomeModal>[1]['openModal']

export const useShowWelcomeModalOnMount = (openWelcomeModal: OpenWelcomeModal): void => {
  const isWelcomeModalEnabled: boolean = useFlag('consumer-portal-welcome-modal-enabled', false)

  const { data: consumerTags, isSuccess: isGetConsumerTagsSuccess } = useGetConsumerTagsWithDataQuery()
  const isWelcomeEnabledAndGetTagsSuccess = isWelcomeModalEnabled && isGetConsumerTagsSuccess
  const hasSeenWelcomeModal = consumerTags?.['portal.consumer-has-seen-welcome-modal'] === String(true)
  const hasSeenWelcomeModalDataReturned = consumerTags?.['portal.consumer-has-seen-welcome-modal'] !== undefined

  const [createConsumerTag] = useCreateConsumerTagMutation()
  const [updateConsumerTag] = useUpdateConsumerTagMutation()

  useEffect(() => {
    if (isWelcomeEnabledAndGetTagsSuccess && !hasSeenWelcomeModalDataReturned) {
      openWelcomeModal()
      createConsumerTag({ body: { name: 'portal.consumer-has-seen-welcome-modal', data: String(true) } })
    }

    if (isWelcomeEnabledAndGetTagsSuccess && !hasSeenWelcomeModal && hasSeenWelcomeModalDataReturned) {
      openWelcomeModal()
      updateConsumerTag({ body: { name: 'portal.consumer-has-seen-welcome-modal', data: String(true) } })
    }
  }, [hasSeenWelcomeModal, isWelcomeEnabledAndGetTagsSuccess, hasSeenWelcomeModalDataReturned])
}
