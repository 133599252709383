import React, { FunctionComponent } from 'react'
import { Modal, Button, Icon } from '@afterpaytouch/core'
import styles from './style.module.css'

interface Props {
  challengeURL: string
  show: boolean
  onClose: () => void
  showCloseButton: boolean
}
export const ThreeDS: FunctionComponent<Props> = ({ challengeURL, show, onClose, showCloseButton }) => {
  return (
    <div className={styles.drawerWrapper}>
      {/* customise the modal padding and the close button */}
      {/* TODO add the Modal loading */}
      <Modal show={show} onClose={onClose} onEscapeKeyDown={onClose} noPadding showCloseButton={false} animate={false}>
        <Modal.Content>
          {showCloseButton && (
            <div className={styles.close}>
              {/* TODO aria-lable translation */}
              <Button.Link type='button' size='Regular' aria-label='Close' onClick={onClose}>
                <Icon.Close size='Small' color='White' />
              </Button.Link>
            </div>
          )}
          <div className={styles.threeDSWrapper}>
            <iframe src={challengeURL} className={styles.threeDSIframe} />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}
