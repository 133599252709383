import { Route } from '../../config/router'

// Static breadcrumbs. These can be enhanced at runtime with additional data from apis if needed
export const Breadcrumbs: Partial<Record<Route, string>> = {
  [Route.UPCOMING_PAYMENTS]: 'upcomingPayments',
  [Route.ORDERS]: 'orders',
  [Route.ORDER]: 'orders',
  [Route.ACCOUNT]: 'account',
  [Route.RECURRING_PAYMENTS]: 'recurringPayments',
}
