import { Button, Heading, Modal, Tag, Text } from '@afterpaytouch/core'
import React, { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { PreferredDayModal } from '../AccountTabs/PaymentMethods/PreferredDayModal'
import { useAlignPayIntroModal } from './AlignPaydayIntroModal.hooks'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { useDisplayIllustrations } from '../../state/convergence/hooks'

const I18N_NAMESPACE = ['index']
export interface AlignPaydayIntroModalProps {
  isModalOpen: boolean
  closeModal: () => void
}

export const AlignPaydayIntroModal: FunctionComponent<AlignPaydayIntroModalProps> = ({ isModalOpen, closeModal }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isDisplayIllustrations = useDisplayIllustrations()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const [isPreferredDayModalOpen, { openModal: openPreferredDayModal, closeModal: closePreferredDayModal }] = useAlignPayIntroModal()

  const handleOpen = (): void => {
    openPreferredDayModal()
    closeModal()
    logEvent(TrackingEvent.ALIGN_PAY_DAY.MODAL_CTA_CLICKED)
  }

  useEffect(() => {
    if (isModalOpen) {
      logEvent(TrackingEvent.ALIGN_PAY_DAY.MODAL_VIEWED)
    }
  }, [isModalOpen])

  return (
    <>
      <Modal show={isModalOpen} onClose={closeModal}>
        <Modal.Content>
          <div className=''>
            {isDisplayIllustrations && (
              <div className='mt-10 text-center'>
                <Image
                  width={315}
                  height={131}
                  src='/images/calendar-illustration.svg'
                  alt={t('index:alignPayday:imageAlt')}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
            )}
            <div className='mt-6'>
              <Tag.Mint>{t('index:alignPayday:tag')}</Tag.Mint>
            </div>
            <div className='mt-2'>
              <Heading size='XXL'>{t('index:alignPayday:heading')}</Heading>
            </div>
            <div className='mt-2'>
              <Text>{t('index:alignPayday:description:1')}</Text>
            </div>
            <div className='mt-6'>
              <Text color='Gray40'>{t('index:alignPayday:description:2')}</Text>
            </div>
            <div className='mt-6'>
              <Button onClick={handleOpen} padding='Fluid' testNameSpace='align-payday-intro-modal'>
                {t('index:alignPayday:cta')}
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
      <PreferredDayModal isModalOpen={isPreferredDayModalOpen} closeModal={closePreferredDayModal} animate={false} />
    </>
  )
}
