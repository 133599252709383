import { useMemo, useCallback } from 'react'
import { TFunction, useTranslation } from 'next-i18next'
import type { Money as IMoney } from '@afterpaytouch/portal-api'
import { AmountDueSummaryResponse } from '@afterpaytouch/portal-api/consumer/amountDueSummary/types'
import {
  useConsumerAccountStatus,
  useConsumerCreditLimitOverride,
  useConsumerGivenName,
  useConsumerIdentityIsValid,
  useConsumerIsDeactivated,
  useConsumerIsFrozenStatusAccount,
  useGetAmountDueSummaryQuery,
  useGetCreditLimitQuery,
  useGetOrderTransactionsQuery,
  useGetOverduePaymentsQuery,
  useOverduePaymentsHasResults,
  useGetBankAccountsQuery,
  useGetCreditFacilitySummaryQuery,
  useConsumerHasCreditFacility,
} from '../../../state'
import { AccountStatus } from '../../../model'
import { constructStatusMessages, IStatusMessages } from './StatusMessage/utils'
import { AchBankAccountStatuses } from '@afterpay/types'

const I18N_NAMESPACE = ['common', 'index']

export const useExistingUserLocalization = (): {
  t: TFunction
  locale: string
} => {
  const {
    t,
    i18n: { language },
  } = useTranslation(I18N_NAMESPACE)

  return { t, locale: language }
}

export const useExistingUserStatusMessages = (
  t: TFunction,
  locale: string,
  consumer: {
    amountDueSummary?: AmountDueSummaryResponse
  }
): IStatusMessages => {
  return useMemo(() => constructStatusMessages(t, locale, consumer), [t, locale, consumer])
}

export const useExistingUserEffects = (): {
  consumer: {
    givenName?: string
    accountStatus?: AccountStatus
    numActiveOrders: number
    numActiveOrdersNonPcl: number
    numActiveOrdersForPCL: number
    totalNonPclOrders: number
    totalPclOrders: number
    hasPCLOrders: boolean
    totalOwed?: IMoney
    pclTotalOwedPrincipal?: IMoney
    totalOwedForPBI?: IMoney
    totalOwedForPCL?: IMoney
    availableToSpend: IMoney
    availableToSpendPCL?: IMoney
    hasOverduePayments: boolean
    isFrozen?: boolean
    isDeactivated: boolean
    isVerified: boolean
    amountDueSummary?: AmountDueSummaryResponse
    hasBankAccountUnlinked?: boolean
    creditFacilityLimit?: IMoney
  }
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  refetchExistingUser: () => void
} => {
  const {
    isLoading: isOrderTransactionsLoading,
    isFetching: isOrderTransactionsFetching,
    isError: isOrderTransactionsError,
    refetch: refetchGetOrderTransactionsQuery,
  } = useGetOrderTransactionsQuery({})

  const {
    data: creditLimitData,
    isLoading: isCreditLimitLoading,
    isFetching: isCreditLimitFetching,
    isError: isCreditLimitError,
    refetch: refetchGetCreditLimitQuery,
  } = useGetCreditLimitQuery()

  const {
    isLoading: isOverduePaymentsLoading,
    isFetching: isOverduePaymentsFetching,
    isError: isOverduePaymentsError,
    refetch: refetchGetOverduePaymentsQuery,
  } = useGetOverduePaymentsQuery()

  const {
    data: getAmountDueSummaryData,
    isLoading: isGetAmountDueSummaryLoading,
    isFetching: isGetAmountDueSummaryFetching,
    isError: isGetAmountDueSummaryError,
    refetch: refetchGetAmountDueSummaryQuery,
  } = useGetAmountDueSummaryQuery()

  const {
    data: bankAccountsData,
    isError: isGetBankAccountsError,
    isLoading: isGetBankAccountsLoading,
    isFetching: isGetBankAccountsFetching,
    refetch: refetchBankAccounts,
  } = useGetBankAccountsQuery()

  const {
    data: creditFacilitySummaryData,
    isLoading: isGetCreditFacilitySummaryLoading,
    isFetching: isGetCreditFacilitySummaryFetching,
    isError: isGetCreditFacilitySummaryError,
    refetch: refetchGetCreditFacilitySummary,
  } = useGetCreditFacilitySummaryQuery(undefined, { skip: !useConsumerHasCreditFacility() })

  const refetchExistingUser = useCallback((): void => {
    if (isOrderTransactionsError) {
      refetchGetOrderTransactionsQuery()
    }
    if (isCreditLimitError) {
      refetchGetCreditLimitQuery()
    }
    if (isOverduePaymentsError) {
      refetchGetOverduePaymentsQuery()
    }
    if (isGetAmountDueSummaryError) {
      refetchGetAmountDueSummaryQuery()
    }
    if (isGetBankAccountsError) {
      refetchBankAccounts()
    }
    if (isGetCreditFacilitySummaryError) {
      refetchGetCreditFacilitySummary()
    }
  }, [
    isOrderTransactionsError,
    refetchGetOrderTransactionsQuery,
    isCreditLimitError,
    refetchGetCreditLimitQuery,
    isOverduePaymentsError,
    refetchGetOverduePaymentsQuery,
    isGetAmountDueSummaryError,
    refetchGetAmountDueSummaryQuery,
    isGetBankAccountsError,
    refetchBankAccounts,
    isGetCreditFacilitySummaryError,
    refetchGetCreditFacilitySummary,
  ])

  // TODO: Refactor so each field is sourced from selector hooks
  return {
    consumer: {
      givenName: useConsumerGivenName(),
      accountStatus: useConsumerAccountStatus(),
      numActiveOrders: Number(getAmountDueSummaryData?.activeNonPclOrders ?? 0) + Number(getAmountDueSummaryData?.activePclOrders ?? 0),
      numActiveOrdersNonPcl: getAmountDueSummaryData?.activeNonPclOrders ?? 0,
      numActiveOrdersForPCL: getAmountDueSummaryData?.activePclOrders ?? 0,
      totalNonPclOrders: getAmountDueSummaryData?.totalNonPclOrders ?? 0,
      totalPclOrders: getAmountDueSummaryData?.totalPclOrders ?? 0,
      hasPCLOrders: (getAmountDueSummaryData?.totalPclOrders ?? 0) > 0,
      totalOwed: getAmountDueSummaryData?.total,
      pclTotalOwedPrincipal: creditFacilitySummaryData?.totalOwedPrincipal,
      totalOwedForPBI: getAmountDueSummaryData?.nonPclTotal,
      totalOwedForPCL: getAmountDueSummaryData?.pclTotal,
      availableToSpend: useConsumerCreditLimitOverride(creditLimitData?.creditLimit ?? { amount: '0.00', currency: 'AUD', symbol: '$' }),
      availableToSpendPCL: creditFacilitySummaryData?.availableToSpend,
      hasOverduePayments: useOverduePaymentsHasResults(),
      isFrozen: useConsumerIsFrozenStatusAccount(),
      isDeactivated: useConsumerIsDeactivated(),
      isVerified: useConsumerIdentityIsValid(), // @TODO: We will likely have to use the identity verify API here as apparently the ID status returned here cannot be relied on
      amountDueSummary: getAmountDueSummaryData,
      hasBankAccountUnlinked: bankAccountsData?.accounts?.some((account) => account.status === AchBankAccountStatuses.UNLINKED) ?? false,
      creditFacilityLimit: creditFacilitySummaryData?.facilityLimit,
    },
    isLoading:
      isOrderTransactionsLoading ||
      isCreditLimitLoading ||
      isOverduePaymentsLoading ||
      isGetAmountDueSummaryLoading ||
      isGetBankAccountsLoading ||
      isGetCreditFacilitySummaryLoading,
    isFetching:
      isOrderTransactionsFetching ||
      isCreditLimitFetching ||
      isOverduePaymentsFetching ||
      isGetAmountDueSummaryFetching ||
      isGetBankAccountsFetching ||
      isGetCreditFacilitySummaryFetching,
    isError: isOrderTransactionsError || isCreditLimitError || isOverduePaymentsError || isGetBankAccountsError || isGetCreditFacilitySummaryError,
    refetchExistingUser,
  }
}
