import React, { FunctionComponent, useContext, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Heading, Modal, Text, Icon, useToast } from '@afterpaytouch/core'
import { CloseAccountFlowContext, CloseAccountFlowSteps } from '../CloseAccountFlowContext'
import { useDeactivateAccountMutation } from '../../../state/account'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { useRouter } from 'next/router'
import { Route } from '../../../config/router'
import { TrackOnMount, useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

const I18N_NAMESPACE = ['common', 'account']

export const DeactivateAccount: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const setToast = useToast()
  const { closeAccountReason, setStep, closeModal } = useContext(CloseAccountFlowContext)
  const router = useRouter()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const locale = router?.locale as SupportedLocale

  const testNameSpace = 'deactivate-account'
  const handleBack = (): void => {
    setStep(CloseAccountFlowSteps.SelectCloseAccountOption)
  }

  const [setDeactivateAccountMutation, { isLoading: isDeactivateAccountLoading, isSuccess: isDeactivateAccountSuccess }] = useDeactivateAccountMutation()

  useEffect(() => {
    if (isDeactivateAccountSuccess) {
      setToast({
        message: t('account:closeAccount:confirmCloseAccount:deactivateAccountSuccessToastMessage'),
        kind: 'Failure',
        linkLabel: t('account:closeAccount:confirmCloseAccount:deactivateAccountSuccessToastMessageLink'),
        linkUrl: `/${locale}${Route.ACCOUNT__PROFILE__REACTIVATEACCOUNTMODAL}`,
        linkTarget: '_self',
        testNameSpace: 'deactivate-account-success',
      })
      logEvent(TrackingEvent.VIEWED_DEACTIVATE_ACCOUNT_SUCCESS_TOAST)
      closeModal()
    }
  }, [locale, isDeactivateAccountSuccess])

  const submitDeactivateAccount = (): void => {
    if (closeAccountReason === null) {
      return
    }
    logEvent(TrackingEvent.PRESSED_CONFIRM_DEACTIVATE_MY_ACCOUNT)
    setDeactivateAccountMutation({
      body: { deactivateReason: closeAccountReason },
    })
  }

  return (
    <>
      <Modal.Header divider>
        <div className='flex'>
          <div className='mr-2 flex'>
            <Button.Link aria-label={t('ach:navigation:back')} onClick={handleBack} testNameSpace='close-account-back-to-previous-step'>
              <Icon.CaretLeft />
            </Button.Link>
          </div>
          <div className='flex'>
            <Heading size='M'>{t('account:closeAccount:deactivateAccount:header')}</Heading>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Text>{t('account:closeAccount:deactivateAccount:description')}</Text>
        <div className='flex flex-row items-center'>
          <span className='mr-2 flex'>
            <Icon.Check size='Small' />
          </span>
          <p className='flex'>
            <Text>{t('account:closeAccount:deactivateAccount:content.0')}</Text>
          </p>
        </div>
        <div className='flex flex-row items-center'>
          <span className='mr-2 flex'>
            <Icon.Check size='Small' />
          </span>
          <p className='flex'>
            <Text>{t('account:closeAccount:deactivateAccount:content.1')}</Text>
          </p>
        </div>
        <div className='flex flex-row items-center'>
          <span className='mr-2 flex'>
            <Icon.Check size='Small' />
          </span>
          <p className='flex'>
            <Text>{t('account:closeAccount:deactivateAccount:content.2')}</Text>
          </p>
        </div>
        <div className='flex flex-col content-around'>
          <Button
            onClick={submitDeactivateAccount}
            disabled={isDeactivateAccountLoading || closeAccountReason === null}
            testNameSpace={testNameSpace}
            kind='Secondary'
          >
            {t('account:closeAccount:deactivateAccount:cta')}
          </Button>
        </div>
        <TrackOnMount eventName={TrackingEvent.VIEWED_CONFIRM_DEACTIVATE_MY_ACCOUNT} />
      </Modal.Content>
    </>
  )
}
