export enum CardScanCheckpoint {
  AddPaymentWeb = 'ADD_PAYMENT_WEB',
  PayNowWeb = 'PAYNOW_WEB',
}

export interface ScanResult {
  readonly canceled: boolean
  readonly cannotScan: boolean
  readonly stopped: boolean
  readonly completed: boolean
  readonly cardImageVerificationId: string
}

export interface ScanConfiguration {
  localization?: {
    instructionsLoading?: string
    instructionsReading?: string
    instructionsScan?: string
    instructionsCapturing?: string
    instructionsProcessing?: string
    instructionsWrongCard?: string
    instructionsTroubleScanning?: string
    securityNotification?: string
  }
  css?: string
  enableCannotScanButton?: boolean
}

export interface ScanIntent {
  readonly isAvailable: () => Promise<boolean>
  readonly configureScan: (config: ScanConfiguration) => void
  readonly startScan: () => Promise<ScanResult>
  readonly stopScan: () => void
  readonly iframe: HTMLIFrameElement
}

export enum BrowserType {
  Others = 'others',
  Chrome = 'chrome',
  Safari = 'safari',
  Samsung = 'samsung',
}

export enum CardScanStatus {
  Initial,
  Creating,
  Ready,
  Scanning,
  Finished,
}

export enum CardScanError {
  Unsupported = 'unsupported',
  DidntMatch = 'didnt_match',
  CannotScan = 'cannot_scan',
  TooManyTries = 'too_many_tries',
  NoPermission = 'no_permission',
  UserCanceled = 'user_canceled',
  TerminalError = 'terminal_error',
}

export enum CardScanAmplitudeErrorReason {
  NotMatched = 'Not Matched',
  RateLimit = 'Rate Limit',
  NoPermission = 'No Permission',
  OtherReasons = 'Other Reasons',
}

export const isScanIntent = (scanIntent: any): scanIntent is ScanIntent => {
  return scanIntent !== null && typeof scanIntent !== 'undefined' && 'iframe' in scanIntent
}

export const isNoPermissionError = (error: Error): boolean => {
  return ['Permission denied', 'Permission denied by system'].includes(error?.message) || error?.name === 'NotAllowedError'
}
