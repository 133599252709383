import { useIsBreakpointMediumAndUp, Button, Text } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { NextRouter, useRouter } from 'next/router'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { useConsumerCountry } from '../../state'
import { mapLocales } from '../../utils/mapLocales'

const I18N_NAMESPACE = ['common']

export const HelpButton: FunctionComponent = (): JSX.Element | null => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const router: NextRouter = useRouter()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false)
  const country = useConsumerCountry()
  const locale = country && mapLocales('country', country)
  const isBpMediumUp = useIsBreakpointMediumAndUp()

  const openZDHelp = (): void => {
    window.zE('webWidget', 'setLocale', locale)
    window.zE('webWidget', 'show')
    window.zE('webWidget', 'open')
    logEvent(TrackingEvent.CLICKED_NEED_HELP, { outboundLink: router.pathname })
    setIsHelpOpen(true)
  }

  useEffect(() => {
    if (isHelpOpen) {
      window.zE('webWidget:on', 'close', function () {
        window.zE('webWidget', 'hide')
        setIsHelpOpen(false)
      })
    }
  }, [isHelpOpen])

  if (isBpMediumUp) {
    return !isHelpOpen ? (
      <div className='pointer-events-none fixed bottom-4 left-0 right-0 ml-auto mr-auto flex w-full max-w-[1440px] justify-end px-8'>
        <div className='pointer-events-auto'>
          <Button size='Regular' onClick={openZDHelp} testNameSpace='help-site'>
            {t('common:helpButton')}
          </Button>
        </div>
      </div>
    ) : null
  }

  return (
    <span className='cursor-pointer'>
      <Text size='XS' onClick={openZDHelp} underline={true} bold={true}>
        {t('common:navigation:help')}
      </Text>
    </span>
  )
}
