import React, { FunctionComponent, useEffect } from 'react'
import { Message, Text, Link } from '@afterpaytouch/core'
import { Trans, useTranslation } from 'next-i18next'
import { AppLink } from '../../../AppLink'
import { Route } from '../../../../config/router'
import { IdentityErrorCode } from '@afterpaytouch/portal-api/identity/config'
import { IdentityDocument, IdentityPiece } from '../../../../model'
import { useAmplitudeWithEnduringEventProperties } from '../../../../integrations/amplitude'
import { TrackingEvent } from '../../../../model/amplitude'
import styles from '../styles.module.css'

interface IdentityErrorMessageProps {
  identityType: IdentityDocument | IdentityPiece
  error?: IdentityErrorCode | null
  testNameSpace?: string
}

const I18N_NAMESPACE = ['verifyIdentity']
const ANALYTIC_ERROR: Record<IdentityDocument | IdentityPiece, string> = {
  licence: 'Hard_ID_Decline_Licence',
  medicare: 'Hard_ID_Decline_Medicare',
  passport: 'Hard_ID_Decline_Passport',
  address: 'Soft_ID_Decline_Address',
}

export const ErrorMessage: FunctionComponent<IdentityErrorMessageProps> = ({ error, identityType: docType, testNameSpace = '' }): JSX.Element | null => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  useEffect(() => {
    if (typeof error !== 'undefined' && error !== null) {
      logEvent(TrackingEvent.VIEWED_NON_TERMINAL_ERROR, { errorCode: ANALYTIC_ERROR[docType] })
    }
  }, [error])

  if (typeof error === 'undefined' || error === null) {
    return null
  }
  const renderError = (
    <Text renderAs={'span'} size='XS'>
      <Trans
        i18nKey={`verifyIdentity:error:${
          error === IdentityErrorCode.MaxAttemptsReached || error === IdentityErrorCode.MaxAttemptsReachedForType ? 'maxAttemptsReachedV2' : error
        }`}
        components={{
          changeVerification: <AppLink route={Route.VERIFY_IDENTITY} />,
          HelpLink: (
            <Link
              color='Fire'
              key='description-link'
              onClick={() => logEvent(TrackingEvent.PRESSED_HARDID_RETRY_PAGE_HELP_LINK)}
              target='_blank'
              kind='Underline'
              href={t('common:urls:identityHelpUrl')}
              testNameSpace='verifyIdentity'
            />
          ),
        }}
      />
    </Text>
  )

  const OPTIONS = {
    [IdentityErrorCode.IdentityAlreadyRejected]: {
      kind: 'Info',
      iconName: 'WarningAlt',
      heading: `${t('verifyIdentity:error:unableToRetryHeading')}`,
    },
    [IdentityErrorCode.MaxAttemptsReached]: {
      kind: 'Error',
      iconName: 'Profile',
      heading: `${t('verifyIdentity:error:maxAttemptsReachedV2Heading')}`,
    },
    [IdentityErrorCode.MaxAttemptsReachedForType]: {
      kind: 'Error',
      iconName: 'Profile',
      heading: `${t('verifyIdentity:error:maxAttemptsReachedV2Heading')}`,
    },
    Other: {
      kind: 'Error',
      iconName: 'Profile',
      heading: `${t('verifyIdentity:error:errorHeading')}`,
    },
  }

  return (
    <div className={styles.messageWrapper}>
      <Message {...(OPTIONS[error] ?? OPTIONS.Other)} description={renderError} testNameSpace={testNameSpace} />
    </div>
  )
}
