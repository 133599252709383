import React, { FunctionComponent } from 'react'
import { useConsumerSizes } from '../../../utils/responsive'
import { useTranslation } from 'next-i18next'
import { Button } from '@afterpaytouch/core'

interface CtaButtonProps {
  lock: boolean
  submitted: boolean
}

const I18N_NAMESPACE = ['common', 'verifyIdentity']

export const CTAButton: FunctionComponent<CtaButtonProps> = ({ lock, submitted }) => {
  const isLocked = lock || submitted
  const { buttonSize } = useConsumerSizes()
  const { t } = useTranslation(I18N_NAMESPACE)
  return (
    <Button loading={isLocked} type='submit' size={buttonSize} disabled={isLocked} testNameSpace={'passport-verify'} padding='Fluid'>
      {t('verifyIdentity:passport:cta')}
    </Button>
  )
}
