import { destroyCookie } from 'nookies'
import { AsyncAction, createFingerprint, createProfilingId, authLogout, resetAuthData, resetConsumerData } from './index'
import { COOKIE_SHOW_NEW_PORTAL, COOKIE_SHOW_OLD_PORTAL } from '../utils/redirects'

export const initialise = (): AsyncAction<void> => async (dispatch) => {
  await dispatch(createProfilingId())
  await dispatch(createFingerprint())
}

export const clearCookie = (): void => {
  destroyCookie(null, COOKIE_SHOW_NEW_PORTAL)
  destroyCookie(null, COOKIE_SHOW_OLD_PORTAL)
}

export const clearState = (): AsyncAction<void> => async (dispatch) => {
  clearCookie()
  await dispatch(resetAuthData())
  await dispatch(resetConsumerData())
  await dispatch(authLogout())
}
