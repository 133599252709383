import { OrderResponse } from '@afterpaytouch/portal-api'
import { SUP_KEYS } from '../../model'

export const regularOrderLowerBound = 604800000 // 7 * 24 * 3600 * 1000
export const regularOrderUpperBound = 10368000000 // 120 * 24 * 3600 * 1000
export const supOrderLowerBound = 0
export const supOrderUpperBound = 2592000000 // 30 * 24 * 3600 * 1000

export const isSupOrder = (order: OrderResponse | null): boolean => SUP_KEYS.includes(order?.source ?? '')
export const checkCreateDisputeLowerBound = (order: OrderResponse): boolean => {
  const times = Date.now() - order.orderDate
  return times >= (isSupOrder(order) ? supOrderLowerBound : regularOrderLowerBound)
}

export const checkCreateDisputeUpperBound = (order: OrderResponse): boolean => {
  const times = Date.now() - order.orderDate
  return times <= (isSupOrder(order) ? supOrderUpperBound : regularOrderUpperBound)
}

export const checkCreateDisputeRefundableAmount = (order: OrderResponse): boolean => {
  return Number(order?.orderSummary?.totalAfterRefund?.amount) > 0
}

export const checkAmount = (amount: number): boolean => {
  return /^\d+(\.\d{1,2})?$/.test(String(amount))
}

export const isDateInvalid = (date: string | null, order: OrderResponse): boolean =>
  !date || (Boolean(order?.orderDate) && new Date(`${date} 23:59:59.999`).getTime() < order.orderDate)
