import { PaymentStatus } from '@afterpaytouch/portal-api'
import { PaymentArrangementSchedule, PaymentArrangementScheduleResponse } from '@afterpaytouch/portal-api/consumer/hardship/types'
import { PayNowScheduleOption } from '../orders/types'

export const daysTillDue = (dueDate, todayDate = new Date()): number => {
  const formattedDueDate = new Date(dueDate)
  formattedDueDate.setHours(0, 0, 0, 0)
  todayDate.setHours(0, 0, 0, 0)
  const oneDay = 24 * 60 * 60 * 1000
  return Math.round((formattedDueDate.getTime() - todayDate.getTime()) / oneDay)
}

export const getFirstPayableItem = (repaymentSchedule: PaymentArrangementScheduleResponse): PaymentArrangementSchedule => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return repaymentSchedule?.paymentSchedules?.find((payment) => payment.paymentStatus !== PaymentStatus.PAID)
}

export const mapToPayNowScheduleOption = (paymentSchedule): PayNowScheduleOption[] => {
  return [
    {
      selected: true, // there is only one installment shown so will always be selected
      id: paymentSchedule?.paymentPlanUuid,
      schedule: paymentSchedule,
    },
  ]
}

export const isPaymentArrangementSchedule = (payment?: any): payment is PaymentArrangementSchedule => {
  return (payment as PaymentArrangementSchedule)?.paymentPlanUuid !== undefined
}

export const isHardshipScheduleDueToday = (date: string): boolean => new Date(date).toDateString() === new Date().toDateString()

export const isHardshipScheduleOverdue = (schedule: PaymentArrangementSchedule): boolean => schedule.paymentStatus === PaymentStatus.OVERDUE
