import { BASE_PATH } from '../src/env'
import { Theme } from '@afterpaytouch/core'

const FAVICON_BY_THEME: Record<Theme, string> = {
  [Theme.AP]: [BASE_PATH, 'favicon.ico'].join('/'),
  [Theme.Cash]: [BASE_PATH, 'cash', 'favicon.ico'].join('/'),
}

export const getFaviconHref = (theme = Theme.AP): string => {
  if (theme === Theme.Cash) {
    return FAVICON_BY_THEME[Theme.Cash]
  }
  return FAVICON_BY_THEME[Theme.AP]
}
