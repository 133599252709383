import { isUndefinedOrEmpty } from '@afterpay/utils/string'
import { isValidAddressComponent, isValidAddressInput, isValidEmail, valueIsNotNullOrUndefined, isValidName } from './validation'
import { isValidPostcode } from './postcode-validations'
import { CountryCode } from 'libphonenumber-js/types'
import { ConsumerCheckoutAddress } from '@afterpay/types'
import { isCallable } from '@afterpay/utils'

export interface AddressValidationConfig {
  state: boolean
  postcode: boolean
  suburb: boolean
}

export const requiredAddressFieldsByCountry: Record<CountryCode | string, AddressValidationConfig> = {
  AU: {
    state: true,
    postcode: true,
    suburb: true,
  },
  NZ: {
    state: true,
    postcode: true,
    suburb: true,
  },
  US: {
    state: true,
    postcode: true,
    suburb: true,
  },
  CA: {
    state: true,
    postcode: true,
    suburb: true,
  },
  GB: {
    state: false,
    postcode: true,
    suburb: true,
  },
  IM: {
    state: false,
    postcode: true,
    suburb: true,
  },
  JE: {
    state: false,
    postcode: true,
    suburb: true,
  },
  GG: {
    state: false,
    postcode: true,
    suburb: true,
  },
  FR: {
    state: false,
    postcode: true,
    suburb: true,
  },
  ES: {
    state: false,
    postcode: true,
    suburb: true,
  },
  IT: {
    state: false,
    postcode: true,
    suburb: true,
  },
  DEFAULT: {
    state: false,
    postcode: false,
    suburb: true,
  },
}

export const requiredFormFields = {
  FR: {
    fiscalIdentification: false,
    nationality: true,
  },
  ES: {
    fiscalIdentification: true,
    nationality: false,
  },
  IT: {
    fiscalIdentification: true,
    nationality: true,
  },
  DEFAULT: {
    fiscalIdentification: false,
    nationality: false,
  },
}

export const validateProfileField = (country, key, value, isMobileLogin = false, isNie = false): boolean => {
  const requiredFields = requiredAddressFieldsByCountry[country] ?? requiredAddressFieldsByCountry.DEFAULT
  const formFields = requiredFormFields[country] ?? requiredFormFields.DEFAULT

  const validate = {
    givenNames: (name) => isValidName({ name }),
    surname: (name) => isValidName({ name }),
    otherGivenNames: (name) => isValidName({ name, optional: true }),
    dateOfBirth: (input) => !isUndefinedOrEmpty(input),
    phoneNumber: (input) => !isUndefinedOrEmpty(input),
    address1: (input) => isValidAddressComponent({ input }),
    address2: (input) => isValidAddressComponent({ input, optional: true }),
    area2: (input) => isValidAddressComponent({ input, optional: true }),
    countryCode: (input) => !isUndefinedOrEmpty(input),
    postcode: (input) => isValidPostcode(country, input, !requiredFields.postcode),
    state: (input) => isValidAddressComponent({ input, optional: !requiredFields.state }),
    suburb: (input) => isValidAddressComponent({ input, optional: !requiredFields.suburb }),
    email: (input) => (isMobileLogin ? isValidEmail(input) : true),
    creditCheckAgree: (input) => valueIsNotNullOrUndefined(input),
    fiscalIdentification: (input) => (formFields.fiscalIdentification ? !isUndefinedOrEmpty(input) : true),
    nationality: (input) => (formFields.nationality || isNie ? !isUndefinedOrEmpty(input) : true),
    addressType: () => true,
    id: () => true,
  }

  return isCallable(validate[key]) && validate[key](value)
}
export const isValidAddress = (address: ConsumerCheckoutAddress): boolean => {
  const requiredFields = requiredAddressFieldsByCountry[address?.countryCode] ?? requiredAddressFieldsByCountry.DEFAULT

  return (
    isValidAddressInput(address?.address1) &&
    isValidPostcode(address?.countryCode, address?.postcode, !requiredFields.postcode) &&
    isValidAddressInput(address?.state, !requiredFields.state) &&
    isValidAddressInput(address?.suburb, !requiredFields.suburb)
  )
}

export const getAddressOptionalFields = (country): string[] => {
  const requiredFields = requiredAddressFieldsByCountry[country] ?? requiredAddressFieldsByCountry.DEFAULT
  return Object.keys(requiredFields).filter((key) => requiredFields[key] === false)
}
