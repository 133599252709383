import React, { FC, ReactNode, useCallback, useContext, useEffect } from 'react'

import { DisputeCreationModalContext, DisputeCreationModalProvider, DisputeCreationModalSteps } from './DisputeCreationModalContext'
import { Modal } from '@afterpaytouch/core'
import { OrderResponse } from '@afterpaytouch/portal-api'

import { SelectReason } from './SelectReason'
import { ContactMerchant } from './ContactMerchant'
import { SubmitEvidence } from './SubmitEvidence'
import { CreateFailure, CreateSuccess } from './Result'
import { Alibaba } from './Alibaba'
import { Decline } from './Decline'
import { ContactMerchantInfo } from './ContactMerchantInfo'
import { DisagreeWithDeliveryWarn } from './DisagreeWithDeliveryWarn'
import { NoPermission } from './NoPermission'
import { Disclaimer } from './Disclaimer/Disclaimer'
import { TimeLimitTip } from './TimeLimitTip'
import { Prezzee } from './Prezzee'

export interface DisputeCreationModalProps {
  isModalOpen: boolean
  closeModal: () => void
  order: OrderResponse
  refreshDisputeInfo: () => void
  animate: boolean
  reopenGetHelpModal: () => void
}

export const DisputeCreationSteps: FC<Omit<DisputeCreationModalProps, 'order' | 'refreshDisputeInfo'>> = ({
  isModalOpen,
  closeModal,
  animate,
  reopenGetHelpModal,
}) => {
  const {
    step,
    isLoading,
    result: { success, error },
    resetState,
  } = useContext(DisputeCreationModalContext)

  const renderStep: () => ReactNode = useCallback(() => {
    if (success) {
      return <CreateSuccess />
    }

    if (error) {
      return <CreateFailure />
    }

    switch (step) {
      case DisputeCreationModalSteps.SelectReason:
        return <SelectReason />
      case DisputeCreationModalSteps.ContactMerchant:
        return <ContactMerchant reopenGetHelpModal={reopenGetHelpModal} />
      case DisputeCreationModalSteps.ContactMerchantInfo:
        return <ContactMerchantInfo />
      case DisputeCreationModalSteps.SubmitEvidence:
        return <SubmitEvidence />
      case DisputeCreationModalSteps.Alibaba:
        return <Alibaba />
      case DisputeCreationModalSteps.Decline:
        return <Decline />
      case DisputeCreationModalSteps.DisagreeWithDeliveryWarn:
        return <DisagreeWithDeliveryWarn />
      case DisputeCreationModalSteps.NoPermission:
        return <NoPermission />
      case DisputeCreationModalSteps.Disclaimer:
        return <Disclaimer />
      case DisputeCreationModalSteps.TimeLimitTip:
        return <TimeLimitTip />
      case DisputeCreationModalSteps.Prezzee:
        return <Prezzee />
    }
  }, [success, error, step, reopenGetHelpModal])

  useEffect(() => {
    return () => {
      resetState()
    }
  }, [])

  return (
    <Modal
      show={isModalOpen}
      onClose={closeModal}
      onBackdropClick={closeModal}
      onHide={closeModal}
      onEscapeKeyDown={closeModal}
      testNameSpace='dispute-creation-modal'
      loading={isLoading}
      animate={animate}
    >
      {renderStep()}
    </Modal>
  )
}

export const DisputeCreationModal: FC<DisputeCreationModalProps> = ({ isModalOpen, closeModal, order, refreshDisputeInfo, animate, reopenGetHelpModal }) => {
  return isModalOpen ? (
    <DisputeCreationModalProvider closeModal={closeModal} order={order} refreshDisputeInfo={refreshDisputeInfo}>
      <DisputeCreationSteps isModalOpen={isModalOpen} closeModal={closeModal} animate={animate} reopenGetHelpModal={reopenGetHelpModal} />
    </DisputeCreationModalProvider>
  ) : null
}
