import { useEffect } from 'react'
import { createModalHook } from '../../hooks'
import { useIsConsumerLendingIntroModalEnabled } from '../../state'
import { useLocalStorage } from '@afterpay/utils/storage'

export const useConsumerLendingIntroModal = createModalHook()

export type OpenConsumerLendingIntroModal = ReturnType<typeof useConsumerLendingIntroModal>[1]['openModal']

export const useShowConsumerLendingIntroModalOnMount = (
  openConsumerLendingIntroModal: OpenConsumerLendingIntroModal,
  consumer: { numActiveOrdersForPCL: number }
): void => {
  const isConsumerLendingIntroModalEnabled = useIsConsumerLendingIntroModalEnabled()
  const [hasSeenConsumerLendingIntroModal, setHasSeenConsumerLendingIntroModal] = useLocalStorage('hasSeenConsumerLendingModal', false)

  useEffect(() => {
    if (consumer.numActiveOrdersForPCL === 0 && isConsumerLendingIntroModalEnabled && !hasSeenConsumerLendingIntroModal) {
      openConsumerLendingIntroModal()
      setHasSeenConsumerLendingIntroModal(true)
    }
  }, [
    isConsumerLendingIntroModalEnabled,
    consumer.numActiveOrdersForPCL,
    hasSeenConsumerLendingIntroModal,
    openConsumerLendingIntroModal,
    setHasSeenConsumerLendingIntroModal,
  ])
}
