import { Heading, Modal, ModalProps, Divider } from '@afterpaytouch/core'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import { AddCardForm } from '../AddCardForm'
import { useConsumerSizes } from '../../../../utils/responsive'
import styles from '../style.module.css'

const I18N_NAMESPACE = ['account']

export const AddCardModal: FunctionComponent<Omit<ModalProps, 'children'> & { onCallback?: () => void }> = ({ onCallback = () => {}, ...props }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { sectionHeadingSize } = useConsumerSizes()

  const handleClose = (): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    props.onClose()
  }

  const handleCallback = async (): Promise<void> => {
    handleClose()
    onCallback()
  }

  return (
    <Modal {...props} onClose={handleClose} onBackdropClick={handleClose} onEscapeKeyDown={handleClose} testNameSpace='add-credit-card-modal'>
      <Modal.Header>
        <div className={styles.textCenter}>
          <Heading size={sectionHeadingSize} testNameSpace='modal'>
            {t('account:tab:payment-methods:modal:header')}
          </Heading>
        </div>
      </Modal.Header>
      <Divider kind={'Hairline'} />
      <Modal.Content>
        <AddCardForm callback={handleCallback} displayStorePaymentToken={false} />
      </Modal.Content>
    </Modal>
  )
}
