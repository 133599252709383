import { Icon, Text } from '@afterpaytouch/core'
import { LoyaltyTierBenefit } from '@afterpaytouch/portal-api'
import React, { FunctionComponent } from 'react'
import { PulseBenefitModal, usePulseBenefitModal } from '../PulseBenefitModal'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'

export interface PulseBenefitRowProps {
  pulseBenefit: LoyaltyTierBenefit
}

export const PulseBenefitRow: FunctionComponent<PulseBenefitRowProps> = ({ pulseBenefit }) => {
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const [isPulseBenefitModalOpen, { openModal: openPulseBenefitModal, closeModal: closePulseBenefitModal }] = usePulseBenefitModal()

  const testNameSpace = 'pulse-benefit-row'

  const handleClick = (): void => {
    openPulseBenefitModal()
    logEvent(TrackingEvent.CLICKED_PULSE_REWARDS_ACCOUNT_PAGE)
  }

  return (
    <>
      <div className='flex cursor-pointer flex-row items-center justify-between' onClick={handleClick} data-testid={`${testNameSpace}-container`}>
        <div className='flex flex-row items-center'>
          <div className='bg-dark/[0.05] mr-2 flex h-16 min-w-[4rem] items-center justify-center rounded-xl'>
            <Text size='XXL' renderAs='span' testNameSpace={`${testNameSpace}-icon`}>
              {pulseBenefit.icon}
            </Text>
          </div>
          <Text size='XS' color='Gray40' bold testNameSpace={testNameSpace}>
            {pulseBenefit.title}
          </Text>
        </div>
        <div>
          <Icon.CaretRight size='Small' color='Gray80' />
        </div>
      </div>
      <PulseBenefitModal isModalOpen={isPulseBenefitModalOpen} closeModal={closePulseBenefitModal} pulseBenefit={pulseBenefit} />
    </>
  )
}
