import React, { FunctionComponent, useState, ReactNode } from 'react'
import clsx, { ClassValue } from 'clsx'

import { ToastContext, Toast, Container, useIsBreakpointMediumAndUp, Text } from '@afterpaytouch/core'
import { AccountBar, Logo, Navigation, AppContainer, Footer, Gravatar } from '../../component'
import { BreadCrumbProvider } from '../../component/Breadcrumb/BreadcrumbContext'
import styles from './style.module.scss'
import { useConsumerName } from '../../state'
import { LaunchDarklyFlags, useFlag } from '../../hooks'

interface ToastProviderProps {
  children: ReactNode
}

interface MainLayoutProps {
  children: ReactNode
}

export const ToastProvider: FunctionComponent<ToastProviderProps> = ({ children }) => {
  const [toastList, setToastList] = useState([])
  const value = { toastList, setToastList }
  const classNames: ClassValue = clsx(styles.toast, toastList.length === 0 && styles['pointer-events-none'])

  return (
    <ToastContext.Provider value={value}>
      <div className={classNames}>
        <Toast kind='Static' />
      </div>
      {children}
    </ToastContext.Provider>
  )
}

export const MainLayout: FunctionComponent<MainLayoutProps> = ({ children }) => {
  const [menuActive, setMenuActive] = useState<boolean>(false)
  const toggleMenu = (): void => setMenuActive(!menuActive)
  // @ts-ignore: OPERATION BLEED STOPPER
  const isBpMediumUp: boolean = useIsBreakpointMediumAndUp()
  const showOverlay: boolean = !isBpMediumUp && menuActive
  const classNames: ClassValue = clsx(styles.layout, isBpMediumUp && styles.bpMediumUp)
  const name = useConsumerName()
  const isMaintenanceMode = useFlag(LaunchDarklyFlags.MaintenanceMode)
  const maintenanceModeText = useFlag(LaunchDarklyFlags.MaintenanceModeText, '')
  const isHelpButtonEnabled = useFlag('consumer-portal-help-button-enabled', true)
  return (
    <Container>
      <AppContainer>
        <BreadCrumbProvider>
          <div className={classNames}>
            <ToastProvider>
              <div className={styles.header}>
                <div className={styles.innerHead}>
                  <div className={styles.logo}>
                    <Logo />
                  </div>
                  {!isMaintenanceMode && (
                    <div className={styles.navigation}>
                      <Navigation menuActive={menuActive} onMenuToggle={toggleMenu} />
                    </div>
                  )}
                  {!isMaintenanceMode && !isBpMediumUp && (
                    <div className={styles.profile}>
                      <Gravatar size='M' alt={name} name={name} title={name} />
                    </div>
                  )}
                </div>
              </div>
              {isBpMediumUp && (
                <div className={styles.accountBar}>
                  <AccountBar />
                </div>
              )}
              {!isMaintenanceMode && <div className={styles.content}>{children}</div>}
              {isMaintenanceMode && (
                <div className={styles.content}>
                  <Text testNameSpace='maintenance'>{maintenanceModeText}</Text>
                </div>
              )}
              <div className={styles.footer}>
                <Footer isHelpDisplayed={isHelpButtonEnabled} />
              </div>
              {showOverlay && <div className={styles.overlay}></div>}
            </ToastProvider>
          </div>
        </BreadCrumbProvider>
      </AppContainer>
    </Container>
  )
}

export const getLayout =
  (props = {}) =>
  (page) => {
    return <MainLayout {...props}>{page}</MainLayout>
  }
