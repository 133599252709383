import React, { FunctionComponent, useContext, useMemo } from 'react'
import { Icon, Text } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { BreadcrumbContext } from './BreadcrumbContext'
import { getBreadcrumbs } from './utils'
import { BreadcrumbItem } from '../../model/breadcrumbs'
import { useConsumerSizes } from '../../utils/responsive'

const I18N_NAMESPACE = ['common']

interface BreadcrumbProps {
  bold?: boolean
}

export const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({ bold = true }) => {
  const { crumbs } = useContext(BreadcrumbContext)
  const { t } = useTranslation(I18N_NAMESPACE)
  const { pathname } = useRouter()
  const breadcrumbs = useMemo(() => getBreadcrumbs(t, crumbs), [crumbs, t])
  const breadcrumb: BreadcrumbItem[] | string = breadcrumbs[pathname]
  const { sectionHeadingSize } = useConsumerSizes()
  return breadcrumb instanceof Object ? (
    <>
      {breadcrumb.map(({ route, link }) =>
        link !== undefined ? (
          <span key={route}>
            <Link href={link} passHref className='text-inherit no-underline' data-testid='breadcrumb-link'>
              <Text size={sectionHeadingSize} bold={bold} renderAs='span'>
                {route}
              </Text>
            </Link>
            <span className='relative ml-2 mr-3'>
              <Icon.CaretRight size='Small' />
            </span>
          </span>
        ) : (
          <Text key={route} renderAs='span' size={sectionHeadingSize} testNameSpace='breadcrumb'>
            {route}
          </Text>
        )
      )}
    </>
  ) : (
    <Text size={sectionHeadingSize} bold={bold} renderAs='span' testNameSpace='breadcrumb'>
      {breadcrumb}
    </Text>
  )
}
