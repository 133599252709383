import React, { FunctionComponent, ReactNode, useContext, useEffect } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Modal, Button, Heading, Text, Icon, Span } from '@afterpaytouch/core'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { AchFlowSteps, AchFlowModalContext } from '../AchFlowModalContext'
import { AchFlowNavigation } from '../AchFlowNavigation'
import { Error } from './Error'
import { NewTabLink } from '../../NewTabLink'

const I18N_NAMESPACE = ['ach']

interface AuthorizationLinkProps {
  children?: ReactNode
}

const AuthorizationLink: FunctionComponent<AuthorizationLinkProps> = ({ children }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  return (
    <Span bold underline size='XS'>
      <NewTabLink href={t('common:urls:paymentAuthorization')}>{children}</NewTabLink>
    </Span>
  )
}

export const AddBankAccount: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const amplitude = useAmplitudeWithEnduringEventProperties()

  const {
    setStep,
    eligible,
    setResult,
    result: { error },
  } = useContext(AchFlowModalContext)

  useEffect(() => {
    if (!eligible) {
      setResult({ error: true, success: false })
      amplitude.logEvent(TrackingEvent.ACH.ViewedBankAccountErrorEligibility)
    } else {
      amplitude.logEvent(TrackingEvent.ACH.ViewAddBankAccountInfo)
    }
  }, [eligible])

  const renderRulesContent = (): ReactNode => (
    <>
      <Modal.Content>
        <div className='mb-4'>
          <Text>{t('ach:addBankAccount:title')}</Text>
        </div>
        <Heading>{t('ach:addBankAccount:title2')}</Heading>
        <ul className='list-none pl-0'>
          <li className='mb-4 flex'>
            <div className='mr-4 inline-block'>
              <Icon.BankStatement />
            </div>
            <Text>{t('ach:addBankAccount:rule1')} </Text>
          </li>
          <li className='mb-4 flex'>
            {/* TODO replace this icon */}
            <div className='mr-4 inline-block'>
              <Icon.Dollar />
            </div>
            <Text>
              <Trans
                i18nKey='ach:addBankAccount:rule2'
                components={{
                  strong: <Span bold />,
                }}
              />
            </Text>
          </li>
          <li className='mb-4 flex'>
            <div className='mr-4 inline-block'>
              <Icon.Dollar />
            </div>
            <Text>{t('ach:addBankAccount:rule3')}</Text>
          </li>
          <li className='mb-4 flex'>
            <div className='mr-4 inline-block'>
              <Icon.Info />
            </div>
            <Text>
              <Trans
                i18nKey='ach:addBankAccount:rule4'
                components={{
                  strong: <Span bold />,
                }}
              />
            </Text>
          </li>
        </ul>
        <Text size='XS'>
          <Trans
            i18nKey='ach:addBankAccount:detail'
            components={{
              strong: <AuthorizationLink />,
            }}
          />
        </Text>
      </Modal.Content>
      <Modal.Footer>
        <Button
          padding='Fluid'
          onClick={() => {
            setStep(AchFlowSteps.CreatePlaid)
            amplitude.logEvent(TrackingEvent.ACH.ClickedAddBankAccountInfoContinue)
          }}
          testNameSpace='ach-add-bank-account-cta'
        >
          {t('ach:addBankAccount:CTA')}
        </Button>
      </Modal.Footer>
    </>
  )

  return (
    <>
      <Modal.Header divider>
        <AchFlowNavigation />
      </Modal.Header>
      {error ? <Error /> : renderRulesContent()}
    </>
  )
}
