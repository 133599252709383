import { isCallable } from '@afterpay/utils'

const isObject = (object): boolean => {
  return typeof object !== 'object' || object == null
}
/**
 * Compares two objects are the same by the enumerable primitive properties
 *
 * @param {object} objectA
 * @param {object} objectB
 * @param {boolean} ignoreEmpty //Treat null, empty and undefined values the same
 * @param {array} ignoreKeys // ignore certain keys from comparison
 * @returns {boolean}
 */
export const compareObjects = (objectA, objectB, ignoreEmpty = false, ignoreKeys: string[] = [], ignoreCase = false): boolean => {
  if (objectA === objectB) {
    return true
  }

  if (isObject(objectA) || isObject(objectB)) {
    return false
  }

  const keysA = Object.keys(objectA).filter((key) => !ignoreKeys.includes(key))
  const keysB = Object.keys(objectB).filter((key) => !ignoreKeys.includes(key))

  if (keysA.length !== keysB.length) {
    return false
  }

  for (const key of keysA) {
    const itemA = ignoreEmpty ? objectA[key] : objectA[key] ?? ''
    const itemB = ignoreEmpty ? objectB[key] : objectB[key] ?? ''

    if (!keysB.includes(key)) {
      return false
    }

    if (isCallable(itemA) || isCallable(itemB)) {
      if (itemA.toString() !== itemB.toString()) {
        return false
      }
    } else {
      const newItemA = typeof itemA === 'string' && ignoreCase ? itemA.toUpperCase() : itemA
      const newItemB = typeof itemB === 'string' && ignoreCase ? itemB.toUpperCase() : itemB
      if (!compareObjects(newItemA, newItemB)) {
        return false
      }
    }
  }

  return true
}
