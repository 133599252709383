import React, { FunctionComponent, ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Text, Modal, Tabs, Button, Span } from '@afterpaytouch/core'
import { InitiateCustomAmountPaymentResponse, InitiateInstallmentPaymentResponse } from '@afterpaytouch/portal-api'
import {
  useAppDispatch,
  setApplePayTraceId,
  useIsApplePayAvailable,
  useCardScanRequired,
  useCheckAchEligibleQuery,
  useGetTotalPayableHardshipRepayments,
  useIsPaymentTypeSupportedForAch,
} from '../../../state'
import { Money } from '../..'
import { SelectPayment } from './SelectPayment'
import { PayNowModalContext } from '../PayNowModalContext'
import { NewPayment } from './NewPayment'
import { PaymentResult } from './PaymentResult'
import { PaymentNavigation } from '../PaymentNavigation'
import { useInitCustomAmountPayment, useInitInstallmentPayment } from '../hooks'
import { getPaymentLabel } from './utils'
import { useFlag } from '../../../hooks'
import { isCustomAmountPayment as checkCustomAmountPayment, isInstallmentPayment as checkInstallmentPayment } from '../utils'
import { TrackOnMount } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { AchFlowSteps } from '../../Ach'
import { achOauthFlowSessionStorageInstance, PAYMENT_DATA } from '../../Ach/BasePlaid'
import { SessionStorageUpdateActionType } from '../../../utils/sessionStorageHelper'
import { useRouter } from 'next/router'

const I18N_NAMESPACE = ['common', 'payments', 'ach', 'orders']

const PaymentTabs = {
  SelectPayment: 'select',
  NewPayment: 'new',
} as const

type PaymentTabsProps = (typeof PaymentTabs)[keyof typeof PaymentTabs]

export const Payment: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const router = useRouter()
  const dispatch = useAppDispatch()
  const {
    setIsLoading,
    isFetching,
    payment,
    order,
    result,
    paymentMethod,
    setResult,
    isBankPayment,
    setIsBankPayment,
    hasAccountLevelPayments,
    setIsEligibleToUseBankAccount,
    openAchFlowModal,
    isAddCheckingAccountSuccess,
    setIsAddCheckingAccountSuccess,
    isThreedsModalOpen,
    payNowStep,
    setAchFlowInitiateStep,
  } = useContext(PayNowModalContext)
  const [tabValue, setTabValue] = useState<PaymentTabsProps>(PaymentTabs.SelectPayment)
  const [initiateInstallmentPayment, { data: installmentData, isLoading: isInstallmentLoading }] = useInitInstallmentPayment({
    orderId: String(order?.orderId),
  })
  const [initiateCustomAmountPayment, { data: customAmountData, isLoading: isCustomAmountLoading, isError: isCustomAmountError }] = useInitCustomAmountPayment({
    orderId: String(order?.orderId),
  })
  const isCustomAmountPayment = checkCustomAmountPayment(payment)
  const isInstallmentPayment = checkInstallmentPayment(payment)
  const totalRepayments = useGetTotalPayableHardshipRepayments()
  const paymentData = isCustomAmountPayment ? customAmountData : installmentData
  const payable = isCustomAmountPayment ? payment?.amount : payment?.total
  const hasInitiated = Boolean(payment?.initiateData)
  const { success, error } = result
  const [initialData, setInitialData] = useState<InitiateInstallmentPaymentResponse | InitiateCustomAmountPaymentResponse>()

  useEffect(() => {
    setInitialData(hasInitiated ? payment?.initiateData : paymentData)
  }, [hasInitiated, payment?.initiateData, paymentData])

  const paymentSubmitted = result.success || result.error
  const isApplePayAvailable = useIsApplePayAvailable()
  const handleTabChange = (value: PaymentTabsProps): void => {
    if (value === PaymentTabs.NewPayment) {
      setIsBankPayment(false)
    }
    if (!isFetching) {
      setTabValue(value)
    }
  }
  const paymentLabel = useMemo(() => getPaymentLabel(payment, payNowStep, t), [payment, payNowStep, t])
  const cardScanRequired = useCardScanRequired()

  const isAchFlagEnabled = useFlag('ach-eligibility-enabled', false)
  const { data: bankAccountEligibleData } = useCheckAchEligibleQuery(undefined, {
    skip: !isAchFlagEnabled,
  })

  useEffect(() => {
    setIsEligibleToUseBankAccount((!hasAccountLevelPayments && bankAccountEligibleData?.manualPayment) ?? false)
  }, [bankAccountEligibleData, hasAccountLevelPayments, setIsEligibleToUseBankAccount])

  const isAchAddCheckingAccountEnabled = isAchFlagEnabled
  const showAddCheckingAccountOption = useIsPaymentTypeSupportedForAch(order?.paymentType) && isAchAddCheckingAccountEnabled

  // If no payment methods available, show add payment method tab
  useEffect(() => {
    if (
      typeof initialData !== 'undefined' &&
      initialData?.savedCards?.length <= 0 &&
      initialData?.savedBankAccounts?.length <= 0 &&
      (initialData?.cashAppPay == null || initialData?.cashAppPay.length <= 0)
    ) {
      setTabValue(PaymentTabs.NewPayment)
    }
  }, [initialData])

  const renderContent = (): ReactElement => {
    if (success) {
      return <PaymentResult type='success' payment={payable} paymentMethod={paymentMethod} merchant={order?.merchant} />
    }

    if (error) {
      return <PaymentResult type='error' message={result?.message} payment={payable} paymentMethod={paymentMethod} />
    }

    return (
      // TODO: improve the way 3ds iframe is shown on top of paynow modal. We need to shrink modal beneath when iframe is shown.
      <div className={isThreedsModalOpen ? 'max-h-[500px]' : undefined}>
        {!cardScanRequired && (
          <>
            {Boolean(order?.merchant) && (
              <div className='mb-4'>
                <Text testNameSpace='paynow-payment-merchant' renderAs='span'>
                  {hasAccountLevelPayments
                    ? t('payments:customPayment:heading:repaymentPlan', {
                        total: totalRepayments,
                      })
                    : t('payments:customPayment:heading:base', {
                        merchant: order?.merchant,
                      })}
                </Text>
              </div>
            )}
            <Money testNameSpace='paynow-payment-payable' bold size='XXXL' value={payable} />
            <div className='mb-4'>
              <Text testNameSpace='paynow-payment-label' renderAs='span'>
                {paymentLabel}
              </Text>
            </div>
            <div className='mb-4'>
              <Tabs value={tabValue} onChange={handleTabChange} kind='Pill' size='Medium' stretch>
                {Object.values(PaymentTabs).map((item) => (
                  <Tabs.Item key={item} value={item} testNameSpace={`paynow-payment-${item}`}>
                    {t(`payments:customPayment:payment:tab:${item}:title`)}
                  </Tabs.Item>
                ))}
              </Tabs>
            </div>
          </>
        )}
        <Tabs.Panel selectedValue={tabValue} value={PaymentTabs.SelectPayment}>
          {/* @ts-ignore: OPERATION BLEED STOPPER */}
          <SelectPayment initiateData={initialData} />
        </Tabs.Panel>
        <Tabs.Panel selectedValue={tabValue} value={PaymentTabs.NewPayment}>
          <>
            {/* @ts-ignore: OPERATION BLEED STOPPER */}
            <NewPayment initiateData={initialData} />
            {showAddCheckingAccountOption && (
              <>
                <div className='relative flex items-center py-5'>
                  <div className='bg-dark-subtle h-px flex-grow'></div>
                  <span className='mx-4 flex-shrink'>
                    <Span color='Gray30'>{t('orders:orderPaymentMethod:modal:or')}</Span>
                  </span>
                  <div className='bg-dark-subtle h-px flex-grow'></div>
                </div>
                <Button.Secondary
                  padding='Fluid'
                  onClick={() => {
                    achOauthFlowSessionStorageInstance.updateSessionStorage(SessionStorageUpdateActionType.EXTEND, undefined, {
                      [PAYMENT_DATA]: payment,
                    })
                    setAchFlowInitiateStep(AchFlowSteps.AddBankAccount)
                    openAchFlowModal()
                    setIsAddCheckingAccountSuccess(false)
                  }}
                  testNameSpace='ach-add-bank-account'
                >
                  {t('ach:addCheckingAccount')}
                </Button.Secondary>
              </>
            )}
          </>
        </Tabs.Panel>
      </div>
    )
  }

  useEffect(() => {
    if ((!hasInitiated && !hasAccountLevelPayments) || isAddCheckingAccountSuccess) {
      if (isInstallmentPayment) {
        // @ts-ignore: OPERATION BLEED STOPPER
        initiateInstallmentPayment({ schedules: payment?.ids })
      }
      if (isCustomAmountPayment) {
        initiateCustomAmountPayment({
          amount: payment?.amount,
        })
      }
    }
  }, [
    initiateInstallmentPayment,
    payment,
    isCustomAmountPayment,
    isInstallmentPayment,
    hasInitiated,
    initiateCustomAmountPayment,
    order,
    hasAccountLevelPayments,
    isAddCheckingAccountSuccess,
  ])

  useEffect(() => {
    if (isAddCheckingAccountSuccess) {
      if (isInstallmentPayment) {
        setInitialData(installmentData)
      }
      if (isCustomAmountPayment) {
        setInitialData(customAmountData)
      }
    }
  }, [customAmountData, installmentData, isAddCheckingAccountSuccess, isCustomAmountPayment, isInstallmentPayment])

  // Set required data by apple pay
  useEffect(() => {
    if (isApplePayAvailable) {
      // @ts-ignore: OPERATION BLEED STOPPER
      dispatch(setApplePayTraceId(initialData?.traceId))
    }
  }, [initialData, dispatch, isApplePayAvailable])

  useEffect(() => {
    setIsLoading(isInstallmentLoading || isCustomAmountLoading)
  }, [isInstallmentLoading, isCustomAmountLoading, setIsLoading])

  useEffect(() => {
    if (isCustomAmountError) {
      setResult({ success: false, error: true })
    }
  }, [isCustomAmountError, setResult])

  return (
    <>
      <TrackOnMount
        eventName={TrackingEvent.VIEWED_MAKE_A_PAYMENT_PAYMENT}
        eventProps={{ paymentType: order?.paymentType, outboundLink: router?.pathname, type: payNowStep }}
      />
      <Modal.Header divider={!paymentSubmitted || (isBankPayment && success)}>
        <PaymentNavigation />
      </Modal.Header>
      <Modal.Content>{renderContent()}</Modal.Content>
    </>
  )
}
