import clsx from 'clsx'
import React, { FunctionComponent, ReactNode } from 'react'
import { MARGINS } from './constants'
import styles from './style.module.css'

type MarginType = typeof MARGINS
type MarginKey = keyof MarginType
interface WrapperProps {
  marginType?: MarginType[MarginKey]
  children: ReactNode
}

export const Wrapper: FunctionComponent<WrapperProps> = ({ children, marginType = 'Default' }) => {
  const classes = clsx(styles[`wrapper-${marginType}`])

  return <div className={classes}>{children}</div>
}
