import { Country } from '@afterpay/types'
import { getAddressOptionalFields } from '@afterpay/utils'
import { InputAddressValidationRule, ProvidedAddress } from '@afterpaytouch/core'
import { AddressFieldSchema, FieldType, Schema } from '@afterpaytouch/form'
import { ConsumerAddress } from '@afterpaytouch/portal-api/consumer/types'
import { UpdateAccountDetailsRequest } from '@afterpaytouch/portal-api/consumer/account/types'
import { TFunction } from 'next-i18next'
import { app } from '../../../env'
import { GetSchemaAndFormDataResult, VerifyAddressFormData, VerifyAddressFormSchema } from './types'
import { ConsumerAccountResponse } from '@afterpaytouch/portal-api/types'

export const ALLOWED_ADDRESS_TYPES = ['street_address', 'subpremise', 'premise']

const getAddressFieldSchema = ({ value, error = false, otherProps = {}, options = {}, disabled = false }): AddressFieldSchema => ({
  type: FieldType.Address,
  apiKey: app.GOOGLEMAPS_API_KEY,
  show: true,
  props: {
    initialAddress: value,
    error,
    disabled,
    ...otherProps,
  },
  options,
})

export const getVerifyAddressPayload = (
  consumer: Pick<ConsumerAccountResponse, 'givenNames' | 'otherGivenNames' | 'surname' | 'dateOfBirth' | 'preferredLocale'>,
  formData: VerifyAddressFormData,
  defaultCountry: Country
): UpdateAccountDetailsRequest => {
  return {
    ...formData.address,
    givenNames: consumer.givenNames ?? '',
    otherGivenNames: consumer.otherGivenNames ?? '',
    surname: consumer.surname ?? '',
    dateOfBirth: consumer.dateOfBirth ?? '',
    countryCode: (formData.address.countryCode ?? defaultCountry) as Country,
    preferredLocale: consumer.preferredLocale,
    creditPolicyAgree: false,
  }
}

export const getSchema = (
  components: any,
  t: TFunction,
  initialAddress: ProvidedAddress | ProvidedAddress[] | ConsumerAddress,
  country: Country
): GetSchemaAndFormDataResult => {
  const schema: Schema<VerifyAddressFormSchema> = {
    address: getAddressFieldSchema({
      value: {
        ...initialAddress,
      },
      otherProps: {
        label: t('addNewAddress:addressInputLabel'),
        manualAddressLabel: t('addNewAddress:findAddressLabel'),
        address1Label: t('addNewAddress:address1Label'),
        address2Label: t('addNewAddress:address2Label'),
        postcodeLabel: t('addNewAddress:postcodeLabel'),
        componentRestrictions: { country },
        suburbLabel: t('addNewAddress:suburbLabel'),
        stateLabel: t('addNewAddress:stateLabel'),
        manualAddressLabelCaption: t('addNewAddress:findAddressLabelCaption'),
        optionalManualAddressFields: getAddressOptionalFields(country),
        allowedAddressType: ALLOWED_ADDRESS_TYPES,
        searchAddressLabel: t('addNewAddress:searchAddressLabel'),
        defaultExpanded: true,
        validateRule: InputAddressValidationRule.ByAddress,
      },
    }),
    submit: {
      type: FieldType.Component,
      Component: components.submit,
    },
  }

  return { schema }
}
