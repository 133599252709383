import { useFlag } from '@afterpaytouch/feature'
import { PaymentArrangementScheduleResponse } from '@afterpaytouch/portal-api/consumer/hardship/types'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useGetPaymentArrangementScheduleQuery } from '../../state'

interface UseHardshipProps {
  hardshipData: PaymentArrangementScheduleResponse
  isHardshipLoading: boolean
  refetchHardshipData: () => void
  isHardshipAndComplete: boolean
  isHardshipDataError: boolean
  setIsPayNowModalOpen: Dispatch<SetStateAction<boolean>>
  isPayNowModalOpen: boolean
}

export const useHardship = (hasAccountLevelPayments: boolean): UseHardshipProps => {
  const [isPayNowModalOpen, setIsPayNowModalOpen] = useState<boolean>(false)
  const hardshipPayNowEnabled = useFlag('consumer-portal-hardship-account-payments-enabled')
  const {
    data,
    isLoading,
    isError,
    error,
    refetch: refetchHardshipData,
  } = useGetPaymentArrangementScheduleQuery(undefined, { skip: !hasAccountLevelPayments || !hardshipPayNowEnabled })

  // When hardship plan is paid off and the account is in hardship status, API returns 404
  const isHardshipAndComplete = hasAccountLevelPayments && isError && (error as FetchBaseQueryError)?.status === 404
  const isHardshipDataError = hasAccountLevelPayments && isError && (error as FetchBaseQueryError)?.status !== 404

  useEffect(() => {
    if (hasAccountLevelPayments && !isPayNowModalOpen) {
      refetchHardshipData()
    }
  }, [hasAccountLevelPayments, isPayNowModalOpen, refetchHardshipData])

  return {
    // @ts-ignore: OPERATION BLEED STOPPER
    hardshipData: data,
    isHardshipLoading: isLoading,
    refetchHardshipData,
    isHardshipAndComplete,
    isHardshipDataError,
    setIsPayNowModalOpen,
    isPayNowModalOpen,
  }
}
