import React, { FunctionComponent, ReactElement, useContext } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import Image from 'next/image'
import { Button, Icon, Heading, Text, useMoney, Span } from '@afterpaytouch/core'
import { PayNowSteps, PayNowModalContext } from '../PayNowModalContext'
import { useConsumerSizes } from '../../../utils/responsive'
import { setCardScanRequired, useAppDispatch, useCardScanRequired } from '../../../state'
import { isCustomAmountPayment as checkCustomAmountPayment } from '../utils'
import router from 'next/router'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { useDisplayIllustrations } from '../../../state/convergence/hooks'

const I18N_NAMESPACE = ['common', 'payments']

// Fix nested components
/* eslint-disable react/no-unstable-nested-components */
export const PaymentNavigation: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const locale = router?.locale as SupportedLocale
  const dispatch = useAppDispatch()
  const isDisplayIllustrations = useDisplayIllustrations()
  const { step, setStep, result, payment, initialStep, isBankPayment, order, hasAccountLevelPayments } = useContext(PayNowModalContext)
  const { headingJumboSize } = useConsumerSizes()
  const paymentSubmitted = result.success || result.error
  const namespace = 'paynow-navigation'
  const cardScanRequired = useCardScanRequired()
  const isCustomAmountPayment = checkCustomAmountPayment(payment)
  const payable = isCustomAmountPayment ? payment?.amount : payment?.total
  const { formatMoney } = useMoney({
    locale,
    currency: payable?.currency,
  })

  const handleNavigation = (): void => {
    if (cardScanRequired) {
      dispatch(setCardScanRequired(false))
      return
    }

    return setStep(PayNowSteps.Installments)
  }
  const showBackButton = initialStep !== PayNowSteps.Payment || cardScanRequired
  const renderBack = (): ReactElement => {
    if (step === PayNowSteps.Installments || paymentSubmitted) {
      // @ts-ignore: OPERATION BLEED STOPPER
      return null
    }
    return (
      <div data-testid={namespace} className='absolute z-10'>
        <Button.Link aria-label={t('payments:customPayment:navigation:back')} size='Small' onClick={handleNavigation} testNameSpace={`${namespace}-back`}>
          <Icon.CaretLeft />
        </Button.Link>
      </div>
    )
  }
  const renderCardPayment = (success: boolean): ReactElement => {
    const Subtitle = (): ReactElement => {
      const amount = payable?.amount
      const merchant = order?.merchant

      // @ts-ignore: OPERATION BLEED STOPPER
      return !hasAccountLevelPayments && Boolean(amount) && Boolean(merchant) ? (
        <>
          <Text testNameSpace={`${namespace}-payment-success-subtitle`}>
            <Trans
              i18nKey={`payments:customPayment:payment:${success ? 'success' : 'error'}:subtitle`}
              values={{ amount: formatMoney(amount), merchant }}
              components={{
                strong: <Span bold />,
              }}
            />
          </Text>
          {success && (
            <Text>
              <Trans i18nKey={'payments:customPayment:payment:success:subtitle2'} />
            </Text>
          )}
        </>
      ) : null
    }

    return (
      <div className='mb-2 text-left'>
        {isDisplayIllustrations && (
          <div className='mb-4'>
            {success ? (
              <div data-testid={`${namespace}-payment-success`} className='my-6 text-center'>
                <Image
                  width={200}
                  height={116}
                  src={`/images/payment-success.svg`}
                  alt={t('payments:customPayment:payment:success:graphic')}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
            ) : (
              <div data-testid={`${namespace}-payment-error`} className='my-6 text-center'>
                <Image
                  width={120}
                  height={110}
                  src={`/images/payment-declined.svg`}
                  alt={t('payments:customPayment:payment:success:graphic')}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className='mb-5'>
          <Heading testNameSpace={`${namespace}-payment`} size={headingJumboSize}>
            <Trans i18nKey={`payments:customPayment:payment:${success ? 'success' : 'error'}:heading`} />
          </Heading>
        </div>
        <Subtitle />
      </div>
    )
  }
  const renderBankPayment = (success: boolean): ReactElement => {
    return success ? (
      <Heading size='L' testNameSpace='bank-heading-success'>
        {t('payments:customPayment:payment:success:bankAccount')}
      </Heading>
    ) : (
      <>
        <Icon name='WarningAlt' color='Fire' />
        <div className='mt-4'>
          <Heading size='L' testNameSpace='bank-heading-error'>
            {t('payments:customPayment:payment:error:heading')}
          </Heading>
        </div>
      </>
    )
  }
  // @ts-ignore: OPERATION BLEED STOPPER
  const renderHeader = (): ReactElement => {
    const { success } = result
    switch (step) {
      case PayNowSteps.Installments:
        return (
          <Heading testNameSpace={`${namespace}-installments`} size='L'>
            {t('payments:customPayment:installments:heading')}
          </Heading>
        )
      case PayNowSteps.Payment:
        if (paymentSubmitted) {
          return isBankPayment ? renderBankPayment(success) : renderCardPayment(success)
        }
        return (
          <Heading testNameSpace={`${namespace}-payment`} size='L'>
            {t('payments:customPayment:payment:heading')}
          </Heading>
        )
    }
  }
  return (
    <>
      {showBackButton && renderBack()}
      <div className='text-center'>{renderHeader()}</div>
    </>
  )
}
