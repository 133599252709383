import React, { FunctionComponent, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { Heading, Modal, Text, Button } from '@afterpaytouch/core'
import { ReactivateAccountFlowContext, ReactivateAccountFlowSteps } from './ReactivateAccountFlowContext'
import { useConsumerName, useConsumerSelector } from '../../state'
import { NewTabLink } from '../NewTabLink'
import { convertConsumerAddress } from '../../utils/consumer'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'

export const ConfirmInfo: FunctionComponent<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const I18N_NAMESPACE = ['common', 'account']
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const { closeModal, setStep } = useContext(ReactivateAccountFlowContext)

  const name = useConsumerName()
  const { contactAddress } = useConsumerSelector()

  const handleConfirmInfoAction = (): void => {
    closeModal()
    onSuccess()
  }

  return (
    <>
      <Modal.Header divider>
        <Heading size='L'>
          <div className='text-center'>{t('account:reactivateAccount:confirmInfo:title')}</div>
        </Heading>
      </Modal.Header>
      <Modal.Content>
        <Text>{t('account:reactivateAccount:confirmInfo:detail')}</Text>
        <br />
        <Text size='XS'>{t('account:reactivateAccount:confirmInfo:name')}</Text>
        <MaskedElement>
          <Text>{name}</Text>
        </MaskedElement>
        <div className='mb-4 mt-2'>
          <NewTabLink href={t('common:urls:changeNameHelp')} testNameSpace='reactivate-account-change-name'>
            <Text bold underline>
              {t('account:reactivateAccount:confirmInfo:changeName')}
            </Text>
          </NewTabLink>
        </div>
        <Text size='XS'>{t('account:account:reactivateAccount:confirmInfo:residentialAddress')}</Text>
        <MaskedElement>
          {/* @ts-ignore: OPERATION BLEED STOPPER */}
          <Text>{convertConsumerAddress(contactAddress)}</Text>
        </MaskedElement>
        <div className='-ml-2 mb-4 mt-2'>
          <Button.Link
            onClick={() => {
              logEvent(TrackingEvent.PRESSED_EDIT_ADDRESS_IN_REACTIVATE_ACCOUNT_FLOW)
              setStep(ReactivateAccountFlowSteps.EditAddress)
            }}
            testNameSpace='reactivate-account-confirm-info-change-address'
          >
            {t('account:account:reactivateAccount:confirmInfo:changeAddressNew')}
          </Button.Link>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button padding='Fluid' onClick={handleConfirmInfoAction} testNameSpace='reactivate-account-confirm-info-cta'>
          {t('common:close')}
        </Button>
      </Modal.Footer>
    </>
  )
}
