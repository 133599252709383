import React, { FC, useCallback, useMemo } from 'react'
import { useDisputeTranslation } from '../hooks'
import { TrackOnMount, useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { Button, Dropdown, Heading, Icon, Input, InputBirthday, Modal, Text } from '@afterpaytouch/core'
import { DisputeCreationModalSteps, useDisputeCreationModalContext } from '../DisputeCreationModalContext'
import { useConsumerSizes } from '../../../utils/responsive'
import { useRouter } from 'next/router'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { LaunchDarklyFlags, useFlag } from '../../../hooks'
import { checkCreateDisputeLowerBound, isDateInvalid } from '../utils'
import { DisputeReason } from '../../../model'

export const ContactMerchantInfo: FC = () => {
  const t = useDisputeTranslation()
  const { textSize } = useConsumerSizes()
  const router = useRouter()
  const locale = router?.locale as SupportedLocale
  const orderMismatchEnabled = useFlag(LaunchDarklyFlags.IrOrderMismatchFlowEnabled)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const {
    order,
    setStep,
    contactMerchantDate,
    setContactMerchantDate,
    contactMerchantName,
    setContactMerchantName,
    expectedDeliveryDate,
    setExpectedDeliveryDate,
    contactMerchantReason,
    setContactMerchantReason,
    setReason,
  } = useDisputeCreationModalContext()

  const [
    heading,
    selectDateHeading,
    contactNameHeading,
    deliveryDateHeading,
    reasonHeading,
    reasonDeliveryDelayed,
    reasonDisagreeWithDelivery,
    reasonNoOrder,
    reasonOrderMismatch,
    btnContinue,
    dateErrorMsg,
  ] = useMemo<string[]>(
    () => [
      t('contactMerchantInfo:head'),
      t('contactMerchantInfo:selectDate'),
      t('contactMerchantInfo:contactName'),
      t('contactMerchantInfo.deliveryDate'),
      t('contactMerchantInfo:reasonHead'),
      t('contactMerchantInfo:reason:deliveryDelayed'),
      t('contactMerchantInfo:reason:disagreeWithDelivery'),
      t('contactMerchantInfo:reason:noOrder'),
      t('contactMerchantInfo:reason:orderMismatch'),
      t('contactMerchantInfo:continue'),
      t('contactMerchantInfo:error:invalidDate'),
    ],
    [t]
  )

  const contactMerchantDateInvalid = useMemo<boolean>(() => {
    return isDateInvalid(contactMerchantDate, order)
  }, [contactMerchantDate, order])
  const expectedDeliveryDateInvalid = useMemo<boolean>(() => {
    return isDateInvalid(expectedDeliveryDate, order)
  }, [expectedDeliveryDate, order])
  const continueBtnDisabled = useMemo<boolean>(() => {
    return contactMerchantDateInvalid || expectedDeliveryDateInvalid || !contactMerchantReason
  }, [contactMerchantDateInvalid, expectedDeliveryDateInvalid, contactMerchantReason])

  const backClickHandler = useCallback(() => {
    setStep(DisputeCreationModalSteps.ContactMerchant)
  }, [setStep])
  const dateSelectionChangeHandler = useCallback(
    (e, date): void => {
      setContactMerchantDate(date ?? null)
    },
    [setContactMerchantDate]
  )
  const contactNameChangeHandler = useCallback(
    (e, name): void => {
      setContactMerchantName(name.trim())
    },
    [setContactMerchantName]
  )
  const expectedDeliveryDateChangeHandler = useCallback(
    (e, date): void => {
      setExpectedDeliveryDate(date ?? null)
    },
    [setExpectedDeliveryDate]
  )
  const contactMerchantReasonChangeHandler = useCallback(
    (reason) => {
      setContactMerchantReason(reason.trim())
    },
    [setContactMerchantReason]
  )
  const continueClickHandler = useCallback(() => {
    if (contactMerchantReason === reasonDisagreeWithDelivery) {
      setStep(DisputeCreationModalSteps.DisagreeWithDeliveryWarn)
    } else if (contactMerchantReason === reasonOrderMismatch) {
      setReason(DisputeReason.OrderMismatch)
      setStep(DisputeCreationModalSteps.Disclaimer)
    } else {
      if (orderMismatchEnabled && !checkCreateDisputeLowerBound(order)) {
        setStep(DisputeCreationModalSteps.TimeLimitTip)
      } else {
        setReason(DisputeReason.ProductNotReceived)
        setStep(DisputeCreationModalSteps.SubmitEvidence)
      }
    }
    logEvent(TrackingEvent.PRESSED_CREATE_DISPUTE_CONTACT_MERCHANT_INFO)
  }, [contactMerchantReason, reasonDisagreeWithDelivery, reasonOrderMismatch, logEvent, setStep, setReason, orderMismatchEnabled, order])

  return (
    <>
      <TrackOnMount eventName={TrackingEvent.VIEWED_CREATE_DISPUTE_CONTACT_MERCHANT_INFO} />
      <Modal.Header divider={true}>
        <div className='absolute z-10 pt-1'>
          <Button.Link size='Small' onClick={backClickHandler}>
            <Icon.CaretLeft size='Small' />
          </Button.Link>
        </div>
        <div className='text-center'>
          <Heading size='L'>{heading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className='my-2'>
          <Text size={textSize}>{selectDateHeading}</Text>
        </div>
        <InputBirthday
          size='Medium'
          defaultValue={contactMerchantDate ?? undefined}
          onChange={dateSelectionChangeHandler}
          locale={locale}
          minAge={Number.NEGATIVE_INFINITY}
          maxAge={Number.POSITIVE_INFINITY}
          error={contactMerchantDateInvalid}
          errorText={dateErrorMsg}
        />
        <div className='my-2'>
          <Text size={textSize}>{contactNameHeading}</Text>
        </div>
        <Input.Text size='Medium' onChange={contactNameChangeHandler} value={contactMerchantName} characterLimit={30} />
        <div className='my-2'>
          <Text size={textSize}>{deliveryDateHeading}</Text>
        </div>
        <InputBirthday
          size='Medium'
          onChange={expectedDeliveryDateChangeHandler}
          defaultValue={expectedDeliveryDate ?? undefined}
          locale={locale}
          minAge={Number.NEGATIVE_INFINITY}
          maxAge={Number.POSITIVE_INFINITY}
          error={expectedDeliveryDateInvalid}
          errorText={dateErrorMsg}
        />
        <div className='my-2'>
          <Text size={textSize}>{reasonHeading}</Text>
        </div>
        <Dropdown
          size='Medium'
          testNameSpace='dispute-contact-merchant-reason-list'
          defaultValue={contactMerchantReason}
          onSelect={contactMerchantReasonChangeHandler}
          scrollIntoView={true}
          error={!contactMerchantReason}
        >
          <Dropdown.MenuItem value={reasonDeliveryDelayed}>{reasonDeliveryDelayed}</Dropdown.MenuItem>
          <Dropdown.MenuItem value={reasonNoOrder}>{reasonNoOrder}</Dropdown.MenuItem>
          <Dropdown.MenuItem value={reasonDisagreeWithDelivery}>{reasonDisagreeWithDelivery}</Dropdown.MenuItem>
          {orderMismatchEnabled && <Dropdown.MenuItem value={reasonOrderMismatch}>{reasonOrderMismatch}</Dropdown.MenuItem>}
        </Dropdown>
        <div className='mt-4'>
          <Button padding='Fluid' onClick={continueClickHandler} disabled={continueBtnDisabled}>
            {btnContinue}
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}
