import { useState } from 'react'

interface UseScriptResponse {
  loaded: boolean
  error: boolean
}

interface UseScriptProps {
  url: string
  name: string
}

const scripts = {}

export const useScript = ({ url = '', name = '' }: UseScriptProps): UseScriptResponse => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(url.length === 0)

  if (typeof window !== 'undefined' && typeof scripts[name] === 'undefined' && url.length > 0) {
    scripts[name] = 'loading'
    const script = document.createElement('script')
    script.src = url
    script.async = true
    script.onerror = () => {
      scripts[name] = undefined
      setError(true)
    }
    script.onload = () => {
      if (typeof window[name] !== 'undefined') {
        scripts[name] = 'loaded'
        setLoaded(true)
      }
    }

    document.body.appendChild(script)
  }

  return { loaded, error }
}
