import React, { FC } from 'react'
import { Button, Heading, Modal, Text } from '@afterpaytouch/core'
import { useDisputeTranslation } from '../hooks'
import { useConsumerSizes } from '../../../utils/responsive'
import { useDisputeCreationModalContext } from '../DisputeCreationModalContext'
import styles from './styles.module.css'
import { TermLink } from '../../TermLink'
import { Trans } from 'next-i18next'

export const Prezzee: FC = () => {
  const { textSize } = useConsumerSizes()
  const t = useDisputeTranslation()
  const { closeModal } = useDisputeCreationModalContext()

  const heading = t('contactPrezzee.head')
  const phone = t('contactPrezzee.phone')
  const trackerLink = t('contactPrezzee.trackerLink')
  const supportLink = t('contactPrezzee.supportLink')
  const email = t('contactPrezzee.email')
  const cta = t('contactPrezzee.cta')

  const components = {
    trackerLink: <TermLink href={trackerLink} underline={false} />,
    supportLink: <TermLink href={supportLink} underline={false} />,
    phone: <TermLink href={`tel:${phone}`} underline={false} />,
    email: <TermLink href={`mailto:${email}`} underline={false} />,
  }

  return (
    <>
      <Modal.Header divider={true}>
        <div className='text-center'>
          <Heading size='L'>{heading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className='whitespace-pre-line'>
          <Text size={textSize} renderAs='div'>
            <p className={styles.contactPrezzee}>
              <Trans i18nKey='dispute:contactPrezzee.subheading' components={components} tOptions={{ phone, email }} />
            </p>
          </Text>
        </div>
        <div className='mt-4'>
          <Button.Ghost padding='Fluid' size='Large' onClick={closeModal}>
            {cta}
          </Button.Ghost>
        </div>
      </Modal.Content>
    </>
  )
}
