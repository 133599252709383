import React, { FunctionComponent } from 'react'
import { CreditCard } from '@afterpaytouch/portal-api/consumer/account/creditcards/types'
import { ThreeDS } from '../../../ThreeDS'
import { CardForm } from '../../../CardForm'
import { CardScanForm, useCardScan } from '../../../CardScanForm'
import { CardBrand } from '@afterpay/types'
import { useAddCard } from '../../../CardForm/hooks'

interface CardFormProps {
  callback: (paymentMethod?: CreditCard) => void
  callbackError?: string
  ctaLabel?: string
  merchantEnabledCards?: CardBrand[] | null
  displayStorePaymentToken?: boolean
}

export const AddCardForm: FunctionComponent<CardFormProps> = ({
  callback,
  callbackError,
  ctaLabel,
  merchantEnabledCards = null,
  displayStorePaymentToken = true,
}: CardFormProps) => {
  const {
    serverError,
    isConfirmError,
    isInitiateError,
    isTopazError,
    isLoading,
    handleSubmit,
    resetState,
    threeDS: { threeDSError, showModalCloseButton, showThreeDSIframe, threeDSIframeURL, threeDSOnClose },
  } = useAddCard({ callback })
  const isFormSubmitting = isLoading
  const { cardScanRequired, handleSuccess, handleCancel, handleError, isCardScanError, cardScanError } = useCardScan()

  if (cardScanRequired) {
    return <CardScanForm onCancel={handleCancel} onSuccess={handleSuccess} onError={handleError} />
  }

  return (
    <>
      <CardForm
        handleSubmit={handleSubmit}
        handleOnFocus={resetState}
        submitting={isFormSubmitting}
        showError={serverError !== null || isTopazError || isInitiateError || isConfirmError || threeDSError !== null || isCardScanError}
        error={serverError ?? threeDSError ?? callbackError ?? cardScanError}
        ctaLabel={ctaLabel}
        // @ts-ignore: OPERATION BLEED STOPPER
        merchantEnabledCards={merchantEnabledCards}
        displayStorePaymentToken={displayStorePaymentToken}
      />
      <ThreeDS challengeURL={threeDSIframeURL} show={showThreeDSIframe} onClose={threeDSOnClose} showCloseButton={showModalCloseButton} />
    </>
  )
}
