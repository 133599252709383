import React, { FunctionComponent, useEffect } from 'react'
import { Button, useModalDeepLink } from '@afterpaytouch/core'
import { usePayMonthlyCloseCreditFacilityModal } from '../hook'
import { PayMonthlyCloseCreditFacilityModal } from '../PayMonthlyCloseCreditFacilityModal'
import { useSearchParam } from '../../../hooks'
import { stringToBoolean } from '@afterpay/utils'
import { useTranslation } from 'next-i18next'
import { useLazyGetCloseCreditFacilityEligibilityQuery } from '../../../state'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

export const ClosePayMonthlyButton: FunctionComponent<{ openDate: string }> = ({ openDate }) => {
  const I18N_NAMESPACE = ['common', 'payMonthly']
  const { t } = useTranslation(I18N_NAMESPACE)
  const testNameSpace = 'pay-monthly-close-credit-facility'
  const isCloseCreditFacilityModalOpenParam = stringToBoolean(useSearchParam(`modal-${testNameSpace}`) ?? '')
  const [isPayMonthlyCloseCreditFacilityModalOpen, { openModal, closeModal }] = usePayMonthlyCloseCreditFacilityModal(isCloseCreditFacilityModalOpenParam)
  useModalDeepLink(isPayMonthlyCloseCreditFacilityModalOpen, testNameSpace)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const [getCreditFacilityCloseEligibility, { data, isFetching, isLoading }] = useLazyGetCloseCreditFacilityEligibilityQuery()

  const getCloseEligibility = (): void => {
    getCreditFacilityCloseEligibility()
    logEvent(TrackingEvent.CREDIT_FACILITY.PressedCloseClFacility)
  }

  useEffect(() => {
    if (!isFetching && !isLoading && data != null) {
      openModal()
    }
  }, [isFetching, isLoading, data])

  return (
    <>
      <Button kind='Ghost' loading={isFetching || isLoading} size='Regular' onClick={getCloseEligibility} testNameSpace={testNameSpace}>
        {t('payMonthly:closeCreditFacility:button')}
      </Button>
      <PayMonthlyCloseCreditFacilityModal
        show={isPayMonthlyCloseCreditFacilityModalOpen}
        closeModal={closeModal}
        openDate={openDate}
      ></PayMonthlyCloseCreditFacilityModal>
    </>
  )
}
