import { Button, Heading, Icon, Modal } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'
import { PreferredDaySteps, useSetPreferredDayModalContext } from '../PreferredDayModalContext'

const I18N_NAMESPACE = ['account']

export const PreferredDayModalHeader: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { step, setStep, closeModal, result } = useSetPreferredDayModalContext()
  const isConfirmPage = step === PreferredDaySteps.ConfirmNewSchedule
  const isResultPage = step === PreferredDaySteps.PreferredPaydayResult
  const isSuccess = result?.success
  const goBack = (): void => {
    if (isConfirmPage) {
      setStep(PreferredDaySteps.SelectPreferredDay)
    }
  }
  const getHeading = (): string => {
    switch (step) {
      case PreferredDaySteps.SelectPreferredDay:
        return t('account:tab:payment-methods:preferred-day:modal:title')
      case PreferredDaySteps.ConfirmNewSchedule:
      case PreferredDaySteps.PreferredPaydayResult: {
        // @ts-ignore: OPERATION BLEED STOPPER
        return result?.success ? null : t('account:tab:payment-methods:preferred-day:modal:confirmTitle')
      }
    }
  }
  return (
    <Modal.Header divider={!isResultPage || !isSuccess}>
      <div className='flex justify-between'>
        <div className='my-auto h-4 w-4'>
          {isConfirmPage && (
            <Button.Link size='Small' onClick={goBack}>
              <Icon.CaretLeft />
            </Button.Link>
          )}
        </div>
        <div className='text-center'>
          <Heading size={'L'}>{getHeading()}</Heading>
        </div>
        <Button.Link size='Small' onClick={closeModal}>
          <Icon.Close />
        </Button.Link>
      </div>
    </Modal.Header>
  )
}
