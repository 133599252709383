import React, { FunctionComponent, ReactNode } from 'react'
import amplitude from 'amplitude-js'
import { Amplitude, AmplitudeProvider as Provider, LogOnMount, useAmplitude } from 'react-amplitude-hooks'
import type { Callback } from 'react-amplitude-hooks/dist/components/Amplitude'
import { useRoutePageName } from '../../config/router'
import { app } from '../../env'
import { useConsumerSelector } from '../../state'
import { useEnduringEventProperties, useUserProperties } from './hooks'
import { AmplitudeEventTypesProperties, PageNames } from './types'

export const viewedPageEventName = (pageName: PageNames): string => `Viewed ${pageName} Page`

export interface TrackingEventProperty {
  [key: string]: any
}
export interface TrackingProps {
  eventName: string
  eventProps?: TrackingEventProperty
}

export interface AmplitudeUserProps {
  children: ReactNode
}

export interface AmplitudeProviderProps {
  children: ReactNode
}

export const AmplitudeUser: FunctionComponent<AmplitudeUserProps> = ({ children }) => {
  const userProperties = useUserProperties()
  return <Amplitude userProperties={userProperties}>{children}</Amplitude>
}

/**
 * Dynamically wraps the AmplitudeProvider HOC from react-amplitude-hooks when in browser,
 * loading configuration and the consumer account from state.
 */
export const AmplitudeProvider: FunctionComponent<AmplitudeProviderProps> = ({ children }) => {
  const { uuid } = useConsumerSelector()
  const { AMPLITUDE_CLIENT_SIDE_ID } = app
  const hasValidApiKey = typeof AMPLITUDE_CLIENT_SIDE_ID !== 'undefined' && AMPLITUDE_CLIENT_SIDE_ID.length > 0
  const amplitudeInstance = amplitude.getInstance()

  if (hasValidApiKey) {
    return (
      <Provider amplitudeInstance={amplitudeInstance} apiKey={AMPLITUDE_CLIENT_SIDE_ID} userId={uuid}>
        <AmplitudeUser>{children}</AmplitudeUser>
      </Provider>
    )
  } else {
    return <>{children}</>
  }
}

export const TrackPageViewed: FunctionComponent<{
  eventProps?: TrackingEventProperty
}> = ({ eventProps }) => {
  const pageName = useRoutePageName()
  const eventProperties = useEnduringEventProperties()
  return <LogOnMount eventType={viewedPageEventName(pageName)} eventProperties={{ ...eventProperties, ...eventProps, pageName }} />
}

export const TrackOnMount: FunctionComponent<TrackingProps> = ({ eventName, eventProps }) => {
  const eventProperties = useEnduringEventProperties()
  return <LogOnMount eventType={eventName} eventProperties={{ ...eventProperties, ...eventProps }} />
}

interface HasPortalLogEvent {
  logEvent: <AmplitudeEventType extends keyof AmplitudeEventTypesProperties>(
    eventType: AmplitudeEventType,
    eventPropertiesIn?: AmplitudeEventTypesProperties[AmplitudeEventType],
    callback?: Callback
  ) => void
}

type UseAmplitudeWithEnduringEventProperties = Omit<ReturnType<typeof useAmplitude>, 'logEvent'> & HasPortalLogEvent
export function useAmplitudeWithEnduringEventProperties(eventProps: TrackingEventProperty = {}): UseAmplitudeWithEnduringEventProperties {
  const amplitude = useAmplitude({ ...useEnduringEventProperties(), ...eventProps })
  return {
    ...amplitude,
    logEvent: <AmplitudeEventType extends keyof AmplitudeEventTypesProperties>(
      eventType: AmplitudeEventType,
      eventPropertiesIn?: AmplitudeEventTypesProperties[AmplitudeEventType],
      callback?: Callback
    ) => amplitude.logEvent(eventType, eventPropertiesIn, callback),
  }
}
