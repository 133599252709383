import { RegisterOptions } from 'react-hook-form'

export const passportPattern = ({ countryOfIssue, t }): RegisterOptions => {
  const PASSPORT_VALIDATION_RULES = {
    AU: /^[A-Za-z]{1,2}\d{7}$/,
    NZ: /^[A-Za-z]{1,2}\d{6}$/,
    Other: /^[A-Za-z0-9]{1,14}$/,
  }

  const validationRegex = PASSPORT_VALIDATION_RULES[countryOfIssue] ?? PASSPORT_VALIDATION_RULES.Other
  return {
    pattern: {
      value: validationRegex,
      message: t('verifyIdentity:passport:invalidNumber'),
    },
  }
}
