import { useTranslation } from 'next-i18next'
import { Heading, Link, Tag, Text, Skeleton, Panel, useIsCashTheme } from '@afterpaytouch/core'
import { Money as IMoney } from '@afterpaytouch/portal-api'
import React, { FunctionComponent } from 'react'
import Image from 'next/image'
import { Money } from '../..'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { useDisplayIllustrations } from '../../../state/convergence/hooks'

const I18N_NAMESPACE = ['common', 'index']

interface MonthlyPaymentsCardProps {
  consumer: {
    hasPCLOrders: boolean
    totalOwedForPCL?: IMoney
    creditFacilityLimit?: IMoney
    numActiveOrdersForPCL?: number
  }
  isLoading: boolean
}

export const MonthlyPaymentsCardUS: FunctionComponent<MonthlyPaymentsCardProps> = ({ consumer, isLoading }) => {
  const isCashTheme = useIsCashTheme()
  const { t } = useTranslation(I18N_NAMESPACE)
  const isDisplayIllustrations = useDisplayIllustrations()

  return (
    <>
      <div className='relative grid h-full w-full [&>*:first-child]:grid' data-testid='monthly-payments-card-us-container'>
        <Panel>
          <Panel.Content inset>
            <div className='min-w-100 py-4 pl-3.5 sm:max-w-full sm:pr-4 md:pr-3' data-testid='monthly-payments-card-us'>
              <div className='flex flex-row'>
                <div className='mr-1 flex min-w-fit items-center justify-center'>
                  <Heading lineClamp={1} size='S'>
                    {t('index:welcome:existingUser:monthlyPayments:headingMonthlyPayments')}
                  </Heading>
                </div>
                {!consumer.hasPCLOrders ? (
                  <div className='flex min-w-fit items-center'>
                    <Tag.Mint testNameSpace='monthly-payments-card-us'>{`${t('index:welcome:existingUser:monthlyPayments:tag')}`}</Tag.Mint>
                  </div>
                ) : consumer.numActiveOrdersForPCL !== 0 ? (
                  <div className='flex min-w-fit items-center'>
                    <Tag.Mint testNameSpace='monthly-payments-card-us'>
                      {`${t('index:welcome:existingUser:monthlyPayments:activeOrders', { numActive: consumer.numActiveOrdersForPCL })}`}
                    </Tag.Mint>
                  </div>
                ) : null}
              </div>
              <div className='flex flex-row items-center justify-between'>
                <div>
                  <MaskedElement>
                    <div className='mt-2 flex flex-row'>
                      {consumer.hasPCLOrders ? (
                        <Money
                          value={consumer.totalOwedForPCL ?? { amount: '0.00', currency: 'AUD' }}
                          size='XL'
                          bold
                          testNameSpace='monthly-payments-card-us'
                        />
                      ) : (
                        <Text lineClamp={2} color='Gray50' size='XXS' testNameSpace='monthly-payments-card-us'>
                          {t('index:welcome:existingUser:monthlyPayments:body')}
                        </Text>
                      )}
                    </div>
                  </MaskedElement>
                  <div className='mt-1 flex flex-grow-0'>
                    {consumer.hasPCLOrders ? (
                      <div className='flex flex-wrap items-center gap-1'>
                        <Text lineClamp={1} color='Gray50' size='XXS'>
                          {t('index:welcome:existingUser:monthlyPayments:totalYouOwe')}
                        </Text>
                      </div>
                    ) : (
                      <Text size='XXS' color='Gray10'>
                        <Link kind='Underline' bold={false} target='_blank' href={t('common:urls:payMonthlyUrl')}>
                          {t('index:welcome:existingUser:monthlyPayments:learnMore')}
                        </Link>
                      </Text>
                    )}
                  </div>
                </div>
                {isDisplayIllustrations && (
                  <div className='h-14 w-14 flex-shrink-0 justify-center rounded-md text-center'>
                    <div className='flex h-full w-full min-w-fit items-center justify-center'>
                      <Image
                        width={56}
                        height={56}
                        src={`/images/cha-ching.svg`}
                        alt={t('index:welcome:existingUser:monthlyPayments:heading-none')}
                        data-testid='monthly-payments-image'
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Panel.Content>
        </Panel>
        {isLoading && <Skeleton kind={isCashTheme ? 'Default' : 'Primary'} />}
      </div>
    </>
  )
}
