/**
 * A hook that returns the string value of the target search param.
 * If the target search param is a string[] then the first value is returned.
 *
 * @param {string} target - The name of the search param to get the value of.
 * @returns {string | undefined} The string value of the target search param, or the string value of the first value in the target array, or undefined if the target search param is not found.
 * @example
 * import { useSearchParam } from '@afterpaytouch/core'
 *
 * const tabAccSearchParam = useSearchParam(`tab-acc`)
 */
export const useSearchParam = (target: string): string | undefined => {
  // return the outermost frame, otherwise it will adopt the location of iframes
  const search = new URLSearchParams(window.location.search)
  const targetSearchParam = search.get(target)

  if (targetSearchParam === null) {
    return undefined
  }

  return Array.isArray(targetSearchParam) ? targetSearchParam[0] : targetSearchParam
}
