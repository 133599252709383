import { ColorTypes, Text } from '@afterpaytouch/core'
import React, { FunctionComponent, HTMLProps, SyntheticEvent } from 'react'

import styles from './style.module.css'
export interface TermLinkProps extends HTMLProps<HTMLAnchorElement> {
  href: string
  onClick?: (e?: SyntheticEvent) => void
  testNameSpace?: string
  color?: ColorTypes
  underline?: boolean
}
export const TermLink: FunctionComponent<TermLinkProps> = ({
  href = '',
  onClick = () => {},
  testNameSpace = '',
  children,
  color,
  underline = true,
  ...otherProps
}) => (
  <a
    className={styles.termlink}
    onClick={onClick}
    href={href}
    target='_blank'
    rel='noreferrer'
    {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-term-link` })}
    {...otherProps}
  >
    <Text bold underline={underline} renderAs='span' color={color}>
      {children}
    </Text>
  </a>
)
