import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState, useEffect } from 'react'
import { Heading, Button, Grid, useIsBreakpointLargeAndUp, Money as fMoney, useModalDeepLink, Text, useDate, Panel } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { PaymentScheduleDueResult, SupportedLocale } from '../../model'
import styles from './style.module.scss'
import { useConsumerSizes } from '../../utils/responsive'
import { Route } from '../../config/router'
import {
  usePaymentScheduleHasResults,
  useGetPaymentScheduleQuery,
  useAccountProgressionIsExistingUser,
  getInstallmentSequenceOffset,
  useConsumerHasAccountLevelPayments,
  useShouldPaymentSchedulePaymentSelectInstallment,
} from '../../state'
import { UpcomingPaymentsPanelLoading } from './UpcomingPaymentsPanelLoading'
import { PayNowModal } from '../PayNowModal'
import { getMerchantName } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import { PaymentStatus } from '@afterpaytouch/portal-api'
import Link from 'next/link'
import {
  formatDueInDays,
  isPaymentScheduleAllowedToBePaid,
  isPaymentScheduleOverdue,
  isPaymentSchedulePending,
  itemDueIn,
} from '../../state/paymentSchedule/utils'
import { UpcomingHardshipPaymentItem } from '../UpcomingHardshipPaymentItem'
import { useHardship } from '../../hooks'
import { NewUpcomingPaymentItem } from '../NewUpcomingPaymentItem'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { Money } from '../Money'
import { useSearchParam } from '../../hooks/useSearchParam/useSearchParam'
import { stringToBoolean } from '@afterpay/utils/string'
import { PaymentArrangementSchedule } from '@afterpaytouch/portal-api/consumer/hardship/types'
import { getInstallmentSequence, getTotalInstallmentCount } from '../../state/orders/utils'
import { ExtendedPanel } from '../Panel'

const I18N_NAMESPACE = ['index']

export const UpcomingPaymentsPanel: FunctionComponent = () => {
  const hasAccountLevelPayments = useConsumerHasAccountLevelPayments()
  const { data, isFetching, isLoading, isError, refetch } = useGetPaymentScheduleQuery({}, { refetchOnMountOrArgChange: true })
  const { hardshipData, isHardshipLoading, isHardshipDataError, isHardshipAndComplete, refetchHardshipData, setIsPayNowModalOpen } =
    useHardship(hasAccountLevelPayments)
  const hasPaymentSchedule = usePaymentScheduleHasResults()
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const isBreakpointLargeAndUp = useIsBreakpointLargeAndUp()
  const router = useRouter()
  const locale = router?.locale as SupportedLocale
  const { formatUnixDate } = useDate({
    locale,
    year: 'numeric',
  })
  const { formatDate } = useDate({
    locale,
    weekday: 'short',
    year: 'numeric',
    month: 'short',
  })

  const payNowModalName = 'pay-now'
  // @ts-ignore: OPERATION BLEED STOPPER
  const isPayNowFlowModalOpenParam = stringToBoolean(useSearchParam(`modal-${payNowModalName}`))
  const orderIdParam = useSearchParam('order-id')
  const selectedPaymentScheduleParam = useMemo(
    () => data?.results?.find((paymentSchedule) => paymentSchedule.orderNumber === orderIdParam),
    [orderIdParam, data]
  )
  const [isModalOpen, setIsModalOpen] = useState(isPayNowFlowModalOpenParam)
  useModalDeepLink(isModalOpen, payNowModalName)

  const [selectedPaymentScheduleItem, setSelectedPaymentScheduleItem] = useState<PaymentScheduleDueResult | PaymentArrangementSchedule>(
    // @ts-ignore: OPERATION BLEED STOPPER
    selectedPaymentScheduleParam
  )
  useEffect(() => {
    if (selectedPaymentScheduleParam != null) {
      setSelectedPaymentScheduleItem(selectedPaymentScheduleParam)
    }
  }, [selectedPaymentScheduleParam])

  useEffect(() => {
    setIsPayNowModalOpen(isModalOpen)
  }, [isModalOpen])

  const closePayNowModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const openPayNowModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleSelectUpcomingPaymentItem = useCallback((paymentScheduleItem: PaymentArrangementSchedule | PaymentScheduleDueResult) => {
    setSelectedPaymentScheduleItem(paymentScheduleItem)
    openPayNowModal()
  }, [])

  const selectedPaymentScheduleItemSchedule = selectedPaymentScheduleItem as PaymentScheduleDueResult
  const shouldPaymentSchedulePaymentSelectInstallment = useShouldPaymentSchedulePaymentSelectInstallment(
    selectedPaymentScheduleItemSchedule?.paymentSchedule?.paymentType,
    selectedPaymentScheduleItemSchedule?.isInstallmentLastAndOwed
  )

  const { subheadingSize, textSize } = useConsumerSizes()

  const listSize = 2
  const hasHardshipSchedules = hardshipData?.paymentSchedules?.length > 0

  const renderCta = useCallback(
    (isItemAllowedToBePaid: boolean, paymentScheduleItem: PaymentScheduleDueResult): ReactNode => {
      const { installmentDueDate, amountOwed } = paymentScheduleItem.paymentSchedule
      return isItemAllowedToBePaid ? (
        <Button
          size={'Regular'}
          testNameSpace='payNow'
          onClick={() => {
            logEvent(TrackingEvent.CLICKED_PAY_NOW, {
              locale,
              installmentDueDate,
              installmentStatus: formatDueInDays(itemDueIn(paymentScheduleItem)),
              outboundLink: router.pathname,
            })
            handleSelectUpcomingPaymentItem(paymentScheduleItem)
          }}
        >
          {t('index:payments:pay', { money: fMoney({ ...amountOwed, locale }) })}
        </Button>
      ) : isPaymentSchedulePending(paymentScheduleItem.paymentSchedule) ? (
        <Text>{t('orders:paymentLabels:pending')}</Text>
      ) : (
        <Money value={amountOwed} size={textSize} />
      )
    },
    [t, locale, data?.results]
  )

  const upcomingPaymentItems = useMemo((): ReactNode => {
    if (!hasPaymentSchedule || hasAccountLevelPayments) {
      return null
    }
    // @ts-ignore: OPERATION BLEED STOPPER
    const upcomingPaymentList = data.results

    return (
      <Grid
        gutter={{
          lg: 3,
        }}
        verticalGutter={{
          sm: 1,
          md: 1,
        }}
      >
        {upcomingPaymentList.slice(0, listSize).map((item, i) => {
          const isItemAllowedToBePaid = isPaymentScheduleAllowedToBePaid(item, upcomingPaymentList)
          const renderBorderRight = i === 0 && isBreakpointLargeAndUp
          const isOverDue = isPaymentScheduleOverdue(item.paymentSchedule)
          const dueOn = !isBreakpointLargeAndUp ? '' : `${t('index:payments:dueOn')}`
          const dueStatus = isOverDue ? `${t('index:payments:overdue')}` : ` ${dueOn} ${formatDate(item.paymentSchedule.installmentDueDate.toString())}`
          const installmentSequence = getInstallmentSequence(item.paymentSchedule.installmentSequence, item.paymentUpFront, item.paymentSchedule.paymentType)
          const installmentsCount = getTotalInstallmentCount(item.installmentsCount, item.paymentUpFront, item.paymentSchedule.paymentType)
          return (
            <Grid.Item sm={12} lg={6} key={`${item.paymentSchedule.id}-${i}`}>
              {/* @ts-ignore: OPERATION BLEED STOPPER */}
              <div className={renderBorderRight ? styles.borderRight : null}>
                <NewUpcomingPaymentItem
                  orderId={item.orderNumber}
                  paymentType={item.paymentSchedule?.paymentType}
                  dateDue={dueStatus}
                  merchant={item.merchant}
                  installmentSequence={installmentSequence}
                  installmentsCount={installmentsCount}
                  isOverdue={isOverDue}
                  action={() => renderCta(isItemAllowedToBePaid, item)}
                  hasOpenRegzDispute={item.openRegZDispute ?? false}
                />
              </div>
            </Grid.Item>
          )
        })}
      </Grid>
    )
  }, [data, isBreakpointLargeAndUp, hasPaymentSchedule, router.locale])

  const upcomingHardshipPaymentItems = useMemo((): ReactNode => {
    if (!hasHardshipSchedules) {
      return null
    }
    const upcomingHardshipPaymentList = hardshipData?.paymentSchedules
    return (
      <Grid
        gutter={{
          lg: 3,
        }}
        verticalGutter={{
          sm: 1,
          md: 1,
        }}
      >
        {upcomingHardshipPaymentList
          .filter((item) => item.paymentStatus !== PaymentStatus.PAID)
          .slice(0, listSize)
          .map((item, i) => {
            const renderBorderRight = i === 0 && isBreakpointLargeAndUp
            return (
              <Grid.Item sm={12} lg={6} key={`paymentPlan-${item.paymentScheduleUuid}`}>
                {/* @ts-ignore: OPERATION BLEED STOPPER */}
                <div className={renderBorderRight ? styles.borderRight : null} data-testid='upcoming-hardship-item'>
                  <UpcomingHardshipPaymentItem
                    installmentSequence={i + 1}
                    paymentItem={item}
                    locale={router?.locale as SupportedLocale}
                    onSelect={() => {
                      logEvent(TrackingEvent.CLICKED_PAY_NOW, {
                        locale,
                        installmentDueDate: formatUnixDate(item?.overdueDatetime),
                        installmentStatus: item?.paymentStatus,
                        outboundLink: router?.pathname,
                      })
                      handleSelectUpcomingPaymentItem(item)
                    }}
                    isAllowedToBePaid={i === 0}
                  />
                </div>
              </Grid.Item>
            )
          })}
      </Grid>
    )
  }, [hardshipData, hasAccountLevelPayments])

  const renderOpenPayNowModal = (): ReactNode => {
    const orderProps = !hasAccountLevelPayments && {
      merchant: getMerchantName((selectedPaymentScheduleItem as PaymentScheduleDueResult)?.merchant),
      paymentType: (selectedPaymentScheduleItem as PaymentScheduleDueResult)?.paymentSchedule?.paymentType,
      orderId: (selectedPaymentScheduleItem as PaymentScheduleDueResult)?.orderNumber,
    }

    if ((selectedPaymentScheduleItem as PaymentScheduleDueResult)?.clearpayPartiallyMigrated) {
      // @ts-ignore: OPERATION BLEED STOPPER
      return <PayNowModal order={orderProps} isModalOpen={isModalOpen} closeModal={closePayNowModal} initalStep={'ClearpayPartiallyMigratedRedirection'} />
    }

    // Note while this rendering condition of pay now modal seems verbose, it's actually critical for our ach oauth redirection when parsing query params from deep link.
    // Because we need to strictly leverage the first render of pay now modal to set inital payment for now pay now modal
    // Removing this rendering condition would cause a NaN display during oauth redirection
    return isModalOpen && selectedPaymentScheduleItem ? (
      shouldPaymentSchedulePaymentSelectInstallment ? (
        <PayNowModal
          // @ts-ignore: OPERATION BLEED STOPPER
          order={orderProps}
          isModalOpen={isModalOpen}
          closeModal={closePayNowModal}
          initalStep={'Installments'}
          isAchClosePayNowModal
          // @ts-ignore: OPERATION BLEED STOPPER
          achFlowEntryDeepLink={`${window.location.origin}/${locale}${Route.INDEX__PAYNOW_ADDBANKACCOUNTMODAL}&order-id=${orderProps.orderId}`}
        />
      ) : (
        <PayNowModal
          isModalOpen={isModalOpen}
          closeModal={closePayNowModal}
          // @ts-ignore: OPERATION BLEED STOPPER
          order={orderProps}
          initalStep='Payment'
          isAchClosePayNowModal
          // @TODO: Offset installmentSequence to retrieve 0 based index. Can be removed in future when order api also returns installmentSequence
          payment={{
            ids: [
              {
                id: (selectedPaymentScheduleItem as PaymentScheduleDueResult)?.paymentSchedule.id,
                sequence: getInstallmentSequenceOffset((selectedPaymentScheduleItem as PaymentScheduleDueResult)?.paymentSchedule.installmentSequence),
              },
            ],
            total: (selectedPaymentScheduleItem as PaymentScheduleDueResult)?.paymentSchedule.amountOwed,
            isOverdue: (selectedPaymentScheduleItem as PaymentScheduleDueResult)?.paymentSchedule.status === PaymentStatus.OVERDUE,
          }}
          // @ts-ignore: OPERATION BLEED STOPPER
          achFlowEntryDeepLink={`${window.location.origin}/${locale}${Route.INDEX__PAYNOW_ADDBANKACCOUNTMODAL}&order-id=${orderProps.orderId}`}
        />
      )
    ) : null
  }

  const isUpcomingPaymentsPanelLoading = isFetching || isLoading || isHardshipLoading

  if (
    !useAccountProgressionIsExistingUser() ||
    (!hasPaymentSchedule && !hasAccountLevelPayments) ||
    (!hasHardshipSchedules && hasAccountLevelPayments) ||
    isHardshipAndComplete
  ) {
    return null
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  const showSeeAllCta = hardshipData?.paymentSchedules?.length > listSize || data?.results.length > listSize
  const headingTitle = hasAccountLevelPayments ? t('index:payments:hardshipTitle') : t('index:payments:title')
  const handleRefetch = (): void => (hasAccountLevelPayments ? refetchHardshipData() : refetch())

  return (
    <Panel>
      <Panel.Content>
        <Grid>
          <Grid.Item>
            <div className={styles.title}>
              <Heading size={subheadingSize}>{headingTitle}</Heading>
            </div>
          </Grid.Item>
          {showSeeAllCta && (
            <Grid.Item sm='auto'>
              <Link href={Route.UPCOMING_PAYMENTS} target='_self' data-testid='see-all-upcoming-payments-link'>
                <Button.Secondary size='Small'>{t('index:payments:all')}</Button.Secondary>
              </Link>
            </Grid.Item>
          )}
        </Grid>
        {isUpcomingPaymentsPanelLoading ? (
          <UpcomingPaymentsPanelLoading listSize={listSize} />
        ) : hasAccountLevelPayments ? (
          upcomingHardshipPaymentItems
        ) : (
          upcomingPaymentItems
        )}
        {(isError || isHardshipDataError) && <ExtendedPanel.Error onClick={handleRefetch} />}
      </Panel.Content>
      {renderOpenPayNowModal()}
    </Panel>
  )
}
