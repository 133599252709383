import React from 'react'
import { FieldType, Schema } from '@afterpaytouch/form'
import { Button, ButtonSizes, InputOnFocus, Message, Text } from '@afterpaytouch/core'
import { IdentityMedicareColour } from '@afterpaytouch/portal-api/identity/types'
import { TFunction, Trans } from 'next-i18next'
import { TermLink } from '../../TermLink'
import { SupportedLocale } from '../../../model'
import { dobValidation, expiryDateValidation, nameMaxLengthValidation, namePattern, requiredValidation } from '../VerifyIdentity/validations'
import { getDateSchema, getDropdownFieldSchema, getInputBirthdaySchema, getInputFieldSchema } from '../VerifyIdentity/utils'

export interface MedicareForm {
  colour: IdentityMedicareColour
  name: string
  dateOfBirth: string
  number: string
  reference: string
  expiryDate: string
  profileNameAlert?: boolean
  profileDobAlert?: boolean
  terms?: boolean
  footer?: boolean
}

export interface MedicareSchemaOptions {
  t: TFunction
  showProfileNameAlert: boolean
  showProfileDobAlert: boolean
  profileDateOfBirth: string
  locale: SupportedLocale
  submitted: boolean
  buttonSize: ButtonSizes
  selectedColour?: IdentityMedicareColour
  onNameBlur?: (e: InputOnFocus) => any
  error: boolean
}

export const expiryDateFormat: Record<IdentityMedicareColour, string> = {
  [IdentityMedicareColour.GREEN]: 'MM/YYYY',
  [IdentityMedicareColour.BLUE]: 'DD/MM/YY',
  [IdentityMedicareColour.YELLOW]: 'DD/MM/YY',
}

export const formatNumberForBackend = (value: string): string => {
  // Remove spaces from the medicare number to match the backend format
  // XXXX XXXXX X will become XXXXXXXXXX
  return value.replace(/[^0-9]/g, '')
}

export const getMedicareSchema = (opts: MedicareSchemaOptions): Schema<MedicareForm> => {
  const { t, showProfileNameAlert, showProfileDobAlert, profileDateOfBirth, locale, selectedColour, submitted, buttonSize, onNameBlur, error } = opts
  const required = requiredValidation(t)
  const namePatterns = namePattern(t)
  const nameMaxLengthOptions = nameMaxLengthValidation(t)
  const dobValidationOptions = dobValidation(t)
  const expiryOptions = expiryDateValidation()

  const ctaButtonString = t('verifyIdentity:medicare:cta')
  const profileUpdateHeading = t('verifyIdentity:alert:profileUpdateHeading')
  const profileUpdateName = t('verifyIdentity:alert:profileUpdateName')
  const profileUpdateDob = t('verifyIdentity:alert:profileUpdateDOB')

  return {
    colour: getDropdownFieldSchema({
      label: t('verifyIdentity:medicare:cardColour'),
      id: 'medicareCardColourDropdown',
      options: {
        ...required('cardColour'),
      },
      items: [
        { key: IdentityMedicareColour.GREEN, label: t('verifyIdentity:medicare:cardColourGreen') },
        { key: IdentityMedicareColour.BLUE, label: t('verifyIdentity:medicare:cardColourBlue') },
        { key: IdentityMedicareColour.YELLOW, label: t('verifyIdentity:medicare:cardColourYellow') },
      ],
      error,
    }),
    name: getInputFieldSchema({
      label: t('verifyIdentity:medicare:fullName'),
      // @ts-ignore: OPERATION BLEED STOPPER
      options: {
        ...required('fullName'),
        ...namePatterns('fullName'),
        ...nameMaxLengthOptions('fullName'),
      },
      otherProps: {
        kind: 'text',
        capitalize: true,
        onBlur: onNameBlur,
      },
      error,
    }),
    profileNameAlert: {
      type: FieldType.Component,
      show: showProfileNameAlert,
      Component: () => <Message kind='Success' iconName='Profile' heading={profileUpdateHeading} description={profileUpdateName} />,
    },
    dateOfBirth: getInputBirthdaySchema({
      label: t('verifyIdentity:medicare:DOB'),
      value: profileDateOfBirth,
      locale,
      options: {
        ...required('dateOfBirth'),
      },
      otherValidations: dobValidationOptions('dateOfBirth'),
      error,
    }),
    profileDobAlert: {
      type: FieldType.Component,
      show: showProfileDobAlert,
      Component: () => <Message kind='Success' iconName='Profile' heading={profileUpdateHeading} description={profileUpdateDob} />,
    },
    number: getInputFieldSchema({
      label: t('verifyIdentity:medicare:cardNumber'),
      options: {
        ...required('medicareNumber'),
        validate: {
          minimumLength: (value: string) => formatNumberForBackend(value).length === 10 || `${t('verifyIdentity:error:invalidCard')}`,
        },
      },
      inline: true,
      otherProps: {
        format: '0000 00000 0',
      },
      error,
    }),
    reference: getInputFieldSchema({
      label: t('verifyIdentity:medicare:cardReference'),
      options: {
        ...required('medicareReference'),
      },
      otherProps: {
        format: '0',
      },
      inline: 105,
      error,
    }),
    expiryDate: getDateSchema({
      label: t('verifyIdentity:medicare:expiry', { context: selectedColour }),
      // @ts-ignore: OPERATION BLEED STOPPER
      format: expiryDateFormat[selectedColour] ?? expiryDateFormat.GREEN,
      options: {
        ...required('expiryDate'),
        ...expiryOptions(t('verifyIdentity:error:expiredCard')),
      },
      otherProps: {
        kind: 'tel',
      },
      error,
    }),
    terms: {
      type: FieldType.Component,
      Component: () => (
        <Text color='Gray40' size='XXS'>
          <Trans
            i18nKey='verifyIdentity:medicare:terms'
            components={{
              terms: <TermLink key='verify-document-terms-of-service' href={t('common:urls:termsOfServiceUrl')} />,
              privacy: <TermLink key='verify-document-privacy-policy' href={t('common:urls:privacyPolicyUrl')} />,
            }}
          />
        </Text>
      ),
    },
    footer: {
      type: FieldType.Component,
      Component: () => (
        <Button.Primary loading={submitted} size={buttonSize} type='submit' disabled={submitted} padding='Fluid'>
          {ctaButtonString}
        </Button.Primary>
      ),
    },
  }
}
