import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'

import { Logo as LogoCore } from '@afterpaytouch/core'
import { isValidLogo } from './utils'

const I18N_NAMESPACE = ['common']

export const Logo: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const logo = t('common:brandName')

  if (!isValidLogo(logo)) {
    return null
  }

  return <LogoCore size='Large' name={logo} title={logo} description={logo} />
}
