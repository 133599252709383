import { DateOfBirthFieldSchema } from '@afterpaytouch/form'
import { TFunction } from 'next-i18next'
import { RegisterOptions, ValidationValueMessage } from 'react-hook-form'
import { Country } from '@afterpaytouch/portal-api/types'
import { COUNTRY_TO_NAME_RESTRICTIONS } from './constants'
import { NameField } from './types'
import { COUNTRY_TO_LICENCE_VERSION_RESTRICTIONS } from '../DriversLicence/constants'

export const namePattern =
  (t: TFunction) =>
  (name: string): RegisterOptions => {
    // This pattern validates for characters, spaces, digits, hyphens, apostrophes and ampersands

    // For example
    //     aC12 3'456
    //     Hg&6-543''21
    //     1334DG -F343

    // Not allowed:
    //     Ac24*2343
    //     ∴a892ljaa
    //     *&23LKJ@@
    const validationRegex = /^$|^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F- ']*$/

    return {
      pattern: {
        value: validationRegex,
        // The name should match the localisations in 'verifyIdentity:error:invalidName_${name}`
        message: t('verifyIdentity:error:invalidName', { context: name }),
      },
    }
  }

export const requiredValidation =
  (t: TFunction) =>
  (name: string): RegisterOptions => ({
    // The name should match the localisations in 'verifyIdentity:error:required_${name}`
    required: t('verifyIdentity:error:required', { context: name }) as unknown as string,
  })

export const dobValidation =
  (t: TFunction, country?: Country) =>
  (name: string): Omit<DateOfBirthFieldSchema, 'type'> => {
    const maxAgeValue = country === Country.NZ ? 120 : 90

    return {
      minAge: {
        value: 18,
        message: t('verifyIdentity:error:minAge', { context: name }),
      },
      maxAge: {
        value: maxAgeValue,
        message: t('verifyIdentity:error:maxAge', { context: name }),
      },
    }
  }

export const nameMaxLengthValidation =
  (t: TFunction) =>
  (name: NameField, country?: Country): RegisterOptions => {
    // @ts-ignore: OPERATION BLEED STOPPER
    const maxLengthMap = COUNTRY_TO_NAME_RESTRICTIONS[country] ?? COUNTRY_TO_NAME_RESTRICTIONS.DEFAULT
    const value = maxLengthMap[name].maxLength

    return {
      maxLength: {
        value,
        message: t('verifyIdentity:error:nameTooLong', { context: name }),
      },
    }
  }

export const expiryDateValidation = () => (validationMessage: string) => ({
  validate: {
    expired: (date: string) => new Date(date).getTime() - new Date().getTime() > 0 || validationMessage,
  },
})

export const licenceVersionLengthValidation =
  (t: TFunction) =>
  (country?: Country): { minLength: ValidationValueMessage<number>; maxLength: ValidationValueMessage<number> } => {
    // @ts-ignore: OPERATION BLEED STOPPER
    const lengthMap = COUNTRY_TO_LICENCE_VERSION_RESTRICTIONS[country] ?? COUNTRY_TO_LICENCE_VERSION_RESTRICTIONS.DEFAULT
    const minValue = lengthMap.minLength
    const maxValue = lengthMap.maxLength

    return {
      minLength: {
        value: minValue,
        message: t('verifyIdentity:licence:invalidVersion'),
      },
      maxLength: {
        value: maxValue,
        message: t('verifyIdentity:licence:invalidVersion'),
      },
    }
  }
