import { portalAction, PortalThunk, AsyncAction } from '../../'
import {
  EmailSurveyCheckCodeRequest,
  EmailSurveyLinkCheckRequest,
  EmailSurveyLinkCheckResponse,
  EmailSurveySendCodeRequest,
  ForcePasswordResetLinkCheckRequest,
  ForcePasswordResetLinkCheckResponse,
  ForcePasswordResetRequest,
  ForcePasswordResetSendLinkRequest,
} from '../../../model'
import { setSessionStorageItem } from '@afterpay/utils/storage'

export enum ConsumerActions {
  ACCOUNT_LOOKUP = 'consumer/account',
  FORCE_PASSWORD_RESET_SEND_LINK = 'consumer/forcePasswordResetSendLink',
  FORCE_PASSWORD_RESET_LINK_CHECK = 'consumer/forcePasswordResetLinkCheck',
  FORCE_PASSWORD_RESET = 'consumer/forcePasswordReset',
  PROTECT_ACCOUNT_LINK_CHECK = 'consumer/protectAccountLinkCheck',
  CONFIRM_ORDER_LINK_CHECK = 'consumer/confirmOrderLinkCheck',
  CONFIRM_ORDER_SEND_CODE = 'consumer/confirmOrderSendCode',
  CONFIRM_ORDER_CHECK_CODE = 'consumer/confirmOrderCheckCode',
}

export const forcePasswordResetSendLink: PortalThunk<ForcePasswordResetSendLinkRequest, void> = portalAction(
  ConsumerActions.FORCE_PASSWORD_RESET_SEND_LINK,
  async (request: ForcePasswordResetSendLinkRequest, thunk) =>
    await thunk.extra.portalApi().post<ForcePasswordResetSendLinkRequest, void>('/portal/force-password-reset/send-email', request)
)

export const forcePasswordResetLinkCheck: PortalThunk<ForcePasswordResetLinkCheckRequest, ForcePasswordResetLinkCheckResponse> = portalAction(
  ConsumerActions.FORCE_PASSWORD_RESET_LINK_CHECK,
  async (request: ForcePasswordResetLinkCheckRequest, thunk) =>
    await thunk.extra
      .portalApi()
      .post<ForcePasswordResetLinkCheckRequest, ForcePasswordResetLinkCheckResponse>('/portal/force-password-reset/check-link', request)
)

export const forcePasswordReset: PortalThunk<ForcePasswordResetRequest, void> = portalAction(
  ConsumerActions.FORCE_PASSWORD_RESET,
  async (request: ForcePasswordResetRequest, thunk) =>
    await thunk.extra.portalApi().post<ForcePasswordResetRequest, void>('/portal/force-password-reset/confirm-password', request)
)

export const protectAccountLinkCheck: PortalThunk<EmailSurveyLinkCheckRequest, EmailSurveyLinkCheckResponse> = portalAction(
  ConsumerActions.PROTECT_ACCOUNT_LINK_CHECK,
  async (request: EmailSurveyLinkCheckRequest, thunk) =>
    await thunk.extra
      .portalApi()
      .post<EmailSurveyLinkCheckRequest, EmailSurveyLinkCheckResponse>('/portal/declined-order-email-survey/protect-account', request)
)

export const confirmOrderLinkCheck: PortalThunk<EmailSurveyLinkCheckRequest, EmailSurveyLinkCheckResponse> = portalAction(
  ConsumerActions.CONFIRM_ORDER_LINK_CHECK,
  async (request: EmailSurveyLinkCheckRequest, thunk) =>
    await thunk.extra.portalApi().post<EmailSurveyLinkCheckRequest, EmailSurveyLinkCheckResponse>('/portal/declined-order-email-survey/confirm-order', request)
)

export const sendOtpCode: PortalThunk<EmailSurveySendCodeRequest, {}> = portalAction(
  ConsumerActions.CONFIRM_ORDER_SEND_CODE,
  async (request: EmailSurveySendCodeRequest, thunk) =>
    await thunk.extra.portalApi().post<EmailSurveySendCodeRequest, {}>('/portal/declined-order-email-survey/otp/code', request)
)

export const checkOtpCode: PortalThunk<EmailSurveyCheckCodeRequest, {}> = portalAction(
  ConsumerActions.CONFIRM_ORDER_CHECK_CODE,
  async (request: EmailSurveyCheckCodeRequest, thunk) =>
    await thunk.extra.portalApi().post<EmailSurveyCheckCodeRequest, {}>('/portal/declined-order-email-survey/otp/check-code', request)
)

export const sendForcePasswordResetOtpCode: PortalThunk<EmailSurveySendCodeRequest, {}> = portalAction(
  ConsumerActions.CONFIRM_ORDER_SEND_CODE,
  async (request: EmailSurveySendCodeRequest, thunk) =>
    await thunk.extra.portalApi().post<EmailSurveySendCodeRequest, {}>('/portal/force-password-reset/otp/code', request)
)

export const checkForcePasswordResetOtpCode: PortalThunk<EmailSurveyCheckCodeRequest, {}> = portalAction(
  ConsumerActions.CONFIRM_ORDER_CHECK_CODE,
  async (request: EmailSurveyCheckCodeRequest, thunk) =>
    await thunk.extra.portalApi().post<EmailSurveyCheckCodeRequest, {}>('/portal/force-password-reset/otp/check-code', request)
)

export const resetConsumerData = (): AsyncAction<void> => async () => {
  setSessionStorageItem('isReturning', null)
  setSessionStorageItem('profileConfirmed', null)
  setSessionStorageItem('consumerId', null)
  setSessionStorageItem('consumerUUID', null)
  setSessionStorageItem('email', null)
  setSessionStorageItem('phoneNumber', null)
  setSessionStorageItem('maskedMobile', null)
  setSessionStorageItem('givenNames', null)
  setSessionStorageItem('otherGivenNames', null)
  setSessionStorageItem('surname', null)
  setSessionStorageItem('dateOfBirth', null)
  setSessionStorageItem('contactAddress', null)
  setSessionStorageItem('shippingAddress', null)
  setSessionStorageItem('billingAddress', null)
  setSessionStorageItem('passwordExists', null)
  setSessionStorageItem('smsVerificationEnabled', null)
  setSessionStorageItem('smsVerificationRequired', null)
  setSessionStorageItem('resetPasswordVerificationCode', null)
  setSessionStorageItem('resetPasswordViaChannel', null)
  setSessionStorageItem('securityVerificationViaChannel', null)
  setSessionStorageItem('isChangingMobile', null)
  setSessionStorageItem('isChangingEmail', null)
  setSessionStorageItem('changeEmailToken', null)
  setSessionStorageItem('changeMobileToken', null)
  setSessionStorageItem('unverifiedEmail', null)
  setSessionStorageItem('isInCheckoutProcess', null)
  setSessionStorageItem('identityCheckStatus', null)
  setSessionStorageItem('hasProfileCheckAttemptsLeft', null)
  setSessionStorageItem('hardIdCheckSkipped', null)
  setSessionStorageItem('allowedTransactionCountryCodes', null)
  setSessionStorageItem('persistentLoggedIn', null)
  setSessionStorageItem('allowOverduePayment', null)
  setSessionStorageItem('preferredPaymentMethod', null)
  setSessionStorageItem('isIdCheckRequired', null)
  setSessionStorageItem('routingKey', null)
  setSessionStorageItem('visitedLoginPage', null)
  setSessionStorageItem('currentSessionDonation', null)
  setSessionStorageItem('wasCrossRegion', null)
  setSessionStorageItem('registrationHeadingExperiment', null)
  setSessionStorageItem('tags', null)
  setSessionStorageItem('promotePersistentLogin', null)
  setSessionStorageItem('frequentConsumer', null)
  setSessionStorageItem('identityDocuments', null)
  setSessionStorageItem('verifiedIdentityDocuments', null)
  setSessionStorageItem('checkoutApplePayPlacementVariation', null)
  setSessionStorageItem('bootFromPersistentLogin', null)
  setSessionStorageItem('registrationId', null)
  setSessionStorageItem('softRetrySkipped', null)
  setSessionStorageItem('softRetryEnabled', null)
  setSessionStorageItem('nzV2FlowEnabled', null)
  setSessionStorageItem('originalUrl', null)
}
