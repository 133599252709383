import { Heading, Modal, ModalProps, Divider, Text, Button, Span, Link, Tabs } from '@afterpaytouch/core'
import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useMemo, useState, ReactNode } from 'react'
import { TFunction, Trans, useTranslation } from 'next-i18next'
import { useCheckAchEligibleQuery, useGetCreditCardsQuery, useCardScanRequired, useIsPaymentTypeSupportedForAch } from '../../state'
import { useConsumerSizes } from '../../utils/responsive'
import { PaymentMethodSelect } from '../PaymentMethodSelect'
import {
  CardBrand,
  isApplePay,
  isBankAccount,
  isCard,
  isCashAppPay,
  PaymentMethodType,
  PaymentType,
  AchBankAccountStatuses,
  PaymentCardDetails,
} from '@afterpay/types'
import { CreditCard } from '@afterpaytouch/portal-api/consumer/account/creditcards/types'
import { ErrorObject } from '../../model'
import { AddCardForm } from '../AccountTabs/PaymentMethods/AddCardForm'
import { getMerchantName } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import { CardExpiryStatus, Currency, OrderMerchant, SavedPaymentMethod } from '@afterpaytouch/portal-api'
import { isOrderTypeUnsupportedForPaymentMethod } from '../../state/orders/utils'
import { PaymentMethods } from '../../state/orders/types'
import { NewTabLink } from '../NewTabLink/NewTabLink'
import { AchFlowModal } from '../Ach'
import { useCashAppPaysHasResults, useGetCashAppPaysQuery } from '../../state/cashAppPays/hooks'
import { useRouter } from 'next/router'
import { SupportedLocale } from '@afterpaytouch/shop-api'
import { useACHModal } from '../Ach/hooks'
import { useFlag } from '../../hooks/useFlag'
import { Route } from '../../config/router'
import { isCallable } from '@afterpay/utils'
import { useShouldBlockChaseCard } from '../PayNowModal/Payment/hooks'

const I18N_NAMESPACE = ['orders', 'account', 'ach']

export interface UseUpdatePaymentBusinessLogicReturn {
  updatePaymentMethodToApplePay: () => Promise<void>
  updateCreditCard: (paymentMethod: PaymentMethods) => Promise<void>
  updateBankAccount: (paymentMethod: PaymentMethods) => Promise<void>
  updateCashApp: (paymentMethod: PaymentMethods) => Promise<void>
}

export interface UseUpdatePaymentBusinessLogicProps {
  orderId?: string
  agreementId?: number
  setError: Dispatch<SetStateAction<ErrorObject | null>>
  t: TFunction
  modalOnClose: () => void
}

interface PaymentAuthorizationLinkProps {
  children?: ReactNode
}

interface ChangePaymentMethodProps extends Omit<ModalProps, 'children'> {
  orderId?: string
  agreementId?: number
  displayStorePaymentToken?: boolean
  enabledCardTypes?: CardBrand[]
  merchant?: OrderMerchant
  paymentMethod?: SavedPaymentMethod | null
  paymentType?: PaymentType
  currency?: Currency
  isGetOrderDataLoading?: boolean
  enableAchBankAccount?: boolean
  useUpdatePaymentBusinessLogic?: (UseUpdatePaymentBusinessLogicProps) => UseUpdatePaymentBusinessLogicReturn
  achFlowEntryDeepLink?: string
  agreementUsingPreferredPaymentMethod?: boolean
}

export const ChangePaymentMethodTabs = {
  PaymentMethods: 'paymentMethods',
  NewPaymentMethods: 'newPaymentMethods',
} as const

export type ChangePaymentMethodTabsProps = (typeof ChangePaymentMethodTabs)[keyof typeof ChangePaymentMethodTabs]

const PaymentAuthorizationLink: FunctionComponent<PaymentAuthorizationLinkProps> = ({ children }) => {
  const { textSize } = useConsumerSizes()
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <Span bold underline size={textSize}>
      <NewTabLink href={t('common:urls:paymentAuthorization')}>{children}</NewTabLink>
    </Span>
  )
}

const RelinkText: FunctionComponent<{ locale: SupportedLocale }> = ({ locale }): JSX.Element => {
  const { t } = useTranslation(I18N_NAMESPACE)
  return (
    <Link href={`/${locale}${Route.ACCOUNT__PAYMENT_METHODS}`} kind='Underline'>
      <Text bold>{t('orders:orderPaymentMethod:modal:info:bank_account_relink_account')}</Text>
    </Link>
  )
}

// @ts-ignore: OPERATION BLEED STOPPER
export const ChangePaymentMethod: FunctionComponent<ChangePaymentMethodProps> = (props: ChangePaymentMethodProps) => {
  /* props */
  const {
    orderId,
    agreementId,
    displayStorePaymentToken = true,
    enabledCardTypes,
    merchant,
    paymentMethod,
    paymentType = PaymentType.PBI,
    currency,
    isGetOrderDataLoading = false,
    enableAchBankAccount = true,
    useUpdatePaymentBusinessLogic,
    onClose,
    achFlowEntryDeepLink,
    agreementUsingPreferredPaymentMethod,
    ...modalProps
  } = props

  /* api hooks */
  const { data, isLoading: isGetCreditCardLoading } = useGetCreditCardsQuery()

  const isAchFlagEnabled = useFlag('ach-eligibility-enabled', false)

  const isAchAddCheckingAccountEnabled = enableAchBankAccount && isAchFlagEnabled
  const showAddCheckingAccountOption = useIsPaymentTypeSupportedForAch(paymentType) && isAchAddCheckingAccountEnabled

  const {
    data: cashData,
    isLoading: isLoadingCashData,
    isFetching: isFetchingCashData,
    isError: isLoadingCashError,
    refetch: refetchCash,
  } = useGetCashAppPaysQuery()
  const hasCashAppPayData: boolean = useCashAppPaysHasResults()

  const { data: bankAccountEligibleData, isLoading: isBankAccountEligibleDataLoading } = useCheckAchEligibleQuery(undefined, {
    skip: !isAchAddCheckingAccountEnabled,
  })

  /* states */
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethods>()
  const [error, setError] = useState<ErrorObject | null>(null)
  const [anyPaymentDisabled, setAnyPaymentDisabled] = useState(false)
  const [linkedAccountFingerprint, setLinkedAccountFingerprint] = useState<string>()

  const router = useRouter()
  const locale = router?.locale as SupportedLocale

  /**
   * ACH flow
   */
  const { achFlowInitialStep, isAchFlowModalOpen, openAchFlowModal, closeAchFlowModal, bankData, isLoadingBankData, segmentValue, setSegmentValue } =
    useACHModal<ChangePaymentMethodTabsProps>(true, 'change-payment-add-bank-account', ChangePaymentMethodTabs.PaymentMethods)

  /* util hooks */
  const { sectionHeadingSize, textSize } = useConsumerSizes()
  const { t } = useTranslation(I18N_NAMESPACE)
  // @ts-ignore: OPERATION BLEED STOPPER
  const { updateCreditCard, updatePaymentMethodToApplePay, updateBankAccount, updateCashApp } = useUpdatePaymentBusinessLogic({
    orderId,
    agreementId,
    setError,
    t,
    modalOnClose: onClose,
  })
  const cardScanRequired = useCardScanRequired()
  const { isChaseCreditCardBlocked } = useShouldBlockChaseCard()

  /* component variables */
  // @ts-ignore: OPERATION BLEED STOPPER
  const merchantName = getMerchantName(merchant)
  const savedPaymentMethod = paymentMethod
  const isLoading =
    isGetOrderDataLoading || isGetCreditCardLoading || isLoadingBankData || isBankAccountEligibleDataLoading || isLoadingCashData || isFetchingCashData
  const isBankAccountPaymentMethodUpdateEnabled = isAchFlagEnabled && bankAccountEligibleData?.orderDefault
  const hasUnlinkedBankAccounts = bankData?.accounts?.some((account) => account.status === AchBankAccountStatuses.UNLINKED)

  /* memorization */
  const isSamePaymentMethod = useMemo(() => {
    let same = false
    if (savedPaymentMethod?.type === PaymentMethodType.APPLE_PAY) {
      same = savedPaymentMethod.type === selectedPaymentMethod
    } else if (savedPaymentMethod?.type === PaymentMethodType.CASH_APP_PAY && isCashAppPay(selectedPaymentMethod)) {
      same = savedPaymentMethod.cashtag === selectedPaymentMethod.cashtag
    } else if (
      (savedPaymentMethod?.type === PaymentMethodType.CREDIT_CARD && isCard(selectedPaymentMethod)) ||
      (savedPaymentMethod?.type === PaymentMethodType.ACH_BANK_ACCOUNT && isBankAccount(selectedPaymentMethod))
    ) {
      same = savedPaymentMethod.id === selectedPaymentMethod.id
    }

    return same
  }, [selectedPaymentMethod, savedPaymentMethod])
  const isChaseBlocked = isChaseCreditCardBlocked(selectedPaymentMethod as PaymentCardDetails, true)
  const isInvalidPaymentMethod = typeof selectedPaymentMethod === 'undefined' || isSamePaymentMethod || isChaseBlocked

  /**
   * PaymentSelect component chooses selected payment based on the "preferred" props
   * Ideally in this modal we want the selected payment to be the same as the one in the order
   * TODO: we have to consider banks and also fallback if they already deleted the previous payment method.
   */
  const processedCreditCard = useMemo(() => {
    return data?.creditcards != null
      ? data.creditcards
          .map((card) => ({
            ...card,
            preferred: savedPaymentMethod?.id === card.id,
          }))
          .filter((card) => card.cardExpiryStatus !== CardExpiryStatus.EXPIRED)
      : []
  }, [data, savedPaymentMethod])

  /* funcs */
  const updatePaymentMethod = (paymentMethod: PaymentMethods) => (): void => {
    if (isApplePay(paymentMethod)) {
      updatePaymentMethodToApplePay()
    }

    if (isCard(paymentMethod)) {
      updateCreditCard(paymentMethod)
    }

    if (isBankAccount(paymentMethod)) {
      updateBankAccount(paymentMethod)
    }

    if (isCashAppPay(paymentMethod)) {
      updateCashApp(paymentMethod)
    }
  }

  const handlePaymentMethodChange = (method: PaymentMethods): void => {
    setError(null)
    setSelectedPaymentMethod(method)
  }

  useEffect(() => {
    if (isChaseBlocked) {
      setError({ errorCode: 'unsupported_card_issuer_bank' })
    }
  }, [isChaseBlocked, selectedPaymentMethod, setError])

  const addCardCallback = (newCard: CreditCard): void => {
    updatePaymentMethod(newCard)()
    isCallable(onClose) && onClose()
  }

  const getOrderPaymentMethodChangeModalInfo = (): ReactNode => {
    if (hasUnlinkedBankAccounts) {
      return (
        <div className='mt-3 flex justify-around'>
          <Text size='XS' color='Fire'>
            <Trans i18nKey='orders:orderPaymentMethod:modal:info:bank_account_unlinked' components={{ relinkAccount: <RelinkText locale={locale} /> }} />
          </Text>
        </div>
      )
    }

    if (anyPaymentDisabled) {
      return <Text color='Gray40'>{t('orders:orderPaymentMethod:modal:info:only_debit_accepted')}</Text>
    }
    if (isBankAccount(selectedPaymentMethod) && !isSamePaymentMethod) {
      return (
        <Text size={textSize}>
          <Trans
            i18nKey='orders:orderPaymentMethod:modal:info:bank_account_selected'
            values={{ numberMask: selectedPaymentMethod.numberMask }}
            components={{ paymentAuthorized: <PaymentAuthorizationLink /> }}
          />
        </Text>
      )
    }
    if (bankData?.accounts?.length > 0 && !isBankAccountPaymentMethodUpdateEnabled) {
      return <Text>{t('orders:orderPaymentMethod:modal:info:bank_account_not_available_for_select')}</Text>
    }
  }

  /* useEffect */
  useEffect(() => {
    const modalShow = modalProps?.show ?? false
    if (!modalShow) {
      setError(null)
      setSelectedPaymentMethod(undefined)
    }
  }, [modalProps.show])

  useEffect(() => {
    if (paymentType !== null) {
      const hasCards = processedCreditCard.length > 0
      // @ts-ignore: OPERATION BLEED STOPPER
      if (hasCards || (hasCashAppPayData && cashData.cashAppPay.length > 0)) {
        setSegmentValue(ChangePaymentMethodTabs.PaymentMethods)
        if (paymentType) {
          setAnyPaymentDisabled(processedCreditCard.some((cc) => isOrderTypeUnsupportedForPaymentMethod(cc, paymentType)))
        }
      } else {
        setSegmentValue(ChangePaymentMethodTabs.NewPaymentMethods)
      }
    }
  }, [paymentType, processedCreditCard, cashData, hasCashAppPayData])

  useEffect(() => {
    if (!isAchFlowModalOpen) {
      setLinkedAccountFingerprint(undefined)
    }
  }, [isAchFlowModalOpen])

  // Once bank account is added, update payment method to it.
  useEffect(() => {
    if (linkedAccountFingerprint === null || isAchFlowModalOpen) {
      return
    }
    const linkedAccountBankAccount = bankData?.accounts?.find((account) => account.fingerprint === linkedAccountFingerprint)
    if (typeof linkedAccountBankAccount !== 'undefined') {
      updateBankAccount(linkedAccountBankAccount)
      setLinkedAccountFingerprint(undefined)
    }
  }, [linkedAccountFingerprint, bankData, isAchFlowModalOpen])

  return (
    modalProps.show &&
    (!isAchFlowModalOpen ? (
      <Modal {...modalProps} onClose={onClose}>
        <Modal.Header>
          <div className='text-center'>
            <Heading size={sectionHeadingSize} testNameSpace='modal'>
              {t('orders:orderPaymentMethod:modal:header')}
            </Heading>
          </div>
        </Modal.Header>

        <Divider kind={'Hairline'} />
        <Modal.Content>
          {!cardScanRequired && (
            <>
              <Text size={textSize} testNameSpace='modal-intro'>
                {t('orders:orderPaymentMethod:modal:body', { merchantName })}
              </Text>
              <div className='mt-4'>
                <Tabs value={segmentValue} onChange={(value) => setSegmentValue(value)} kind='Pill' size='Medium' stretch>
                  {Object.values(ChangePaymentMethodTabs).map((item) => (
                    <Tabs.Item key={item} value={item} testNameSpace={item}>
                      {t(`orders:orderPaymentMethod:modal:tab:${item}:title`)}
                    </Tabs.Item>
                  ))}
                </Tabs>
              </div>

              <div className='mt-4' data-testid={'change-payment-method'}>
                <Tabs.Panel selectedValue={segmentValue} value={ChangePaymentMethodTabs.PaymentMethods}>
                  <>
                    <PaymentMethodSelect
                      paymentMethods={[
                        // @ts-ignore: OPERATION BLEED STOPPER
                        ...(hasCashAppPayData ? cashData.cashAppPay : []),
                        ...processedCreditCard,
                        ...(bankData?.accounts != null ? bankData.accounts : []),
                      ]}
                      onChange={handlePaymentMethodChange}
                      // @ts-ignore: OPERATION BLEED STOPPER
                      currency={currency}
                      paymentType={paymentType}
                      testNameSpace='change-payment'
                      isSelectBankAccountEnabled={isBankAccountPaymentMethodUpdateEnabled}
                      loading={isLoading}
                      defaultPaymentMethod={paymentMethod}
                      agreementUsingPreferredPaymentMethod={agreementUsingPreferredPaymentMethod}
                    />
                    {error !== null && (
                      <div className='mt-4'>
                        <Text color='Fire'>
                          {error.status === 422
                            ? t('orders:orderPaymentMethod:modal:errors:card_expiry')
                            : t(
                                `orders:orderPaymentMethod:modal:errors:${error.errorCode}`,
                                t('orders:orderPaymentMethod:modal:errors:unspecified_error'),
                                error?.options
                              )}
                        </Text>
                      </div>
                    )}
                    {<div className='mt-4'>{getOrderPaymentMethodChangeModalInfo()}</div>}
                    <div className='mt-4'>
                      <Button.Primary
                        padding='Fluid'
                        // @ts-ignore: OPERATION BLEED STOPPER
                        onClick={updatePaymentMethod(selectedPaymentMethod)}
                        disabled={isInvalidPaymentMethod}
                        testNameSpace='change-payment-submit'
                      >
                        {t('orders:orderPaymentMethod:modal:cta')}
                      </Button.Primary>
                    </div>
                  </>
                </Tabs.Panel>

                <Tabs.Panel selectedValue={segmentValue} value={ChangePaymentMethodTabs.NewPaymentMethods}>
                  <>
                    <AddCardForm
                      callback={addCardCallback}
                      ctaLabel={t('orders:orderPaymentMethod:modal:ctaAddPaymentmethod')}
                      // @ts-ignore: OPERATION BLEED STOPPER
                      callbackError={error !== null && t(`orders:orderPaymentMethod:modal:errors:${error.errorCode}`, error?.options)}
                      merchantEnabledCards={enabledCardTypes}
                      displayStorePaymentToken={displayStorePaymentToken}
                    />
                    {showAddCheckingAccountOption && (
                      <>
                        <div className='relative flex items-center py-5'>
                          <div className='bg-dark-subtle h-px flex-grow'></div>
                          <span className='mx-4 flex-shrink'>
                            <Span color='Gray30'>{t('orders:orderPaymentMethod:modal:or')}</Span>
                          </span>
                          <div className='bg-dark-subtle h-px flex-grow'></div>
                        </div>
                        <Button.Secondary padding='Fluid' onClick={openAchFlowModal} testNameSpace='ach-add-bank-account'>
                          {t('ach:addCheckingAccount')}
                        </Button.Secondary>
                      </>
                    )}
                  </>
                </Tabs.Panel>
              </div>
            </>
          )}
        </Modal.Content>
      </Modal>
    ) : (
      <AchFlowModal
        animate={false}
        show={isAchFlowModalOpen}
        initialStep={achFlowInitialStep}
        closeModal={closeAchFlowModal}
        onSuccess={(LinkedAccountFingerprint) => setLinkedAccountFingerprint(LinkedAccountFingerprint)}
        initialEligible={bankAccountEligibleData?.addBankAccount}
        achFlowEntryDeepLink={achFlowEntryDeepLink}
      />
    ))
  )
}
