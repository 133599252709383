import { Money } from '@afterpaytouch/portal-api/types'
import { OrderChannel, OrderPaymentSchedule, OrderPpaDocTypes } from '@afterpaytouch/portal-api/consumer/ordertransactions/types'
import { PaymentStatus } from '@afterpaytouch/portal-api/payments/types'
import { isBankAccount, isCard, isCashAppPay, PaymentType } from '@afterpay/types'
import { isValidMoney } from '../../component/Money/utils'
import { PayNowScheduleOption, PaymentMethods } from './types'
import { PaymentArrangementSchedule } from '@afterpaytouch/portal-api/consumer/hardship/types'
import { isPaymentArrangementSchedule } from '../hardship/utils'
import { ppaDocumentLabel } from './constants'

interface CalculateAmountPayableProps {
  options: Array<{
    schedule?: OrderPaymentSchedule | PaymentArrangementSchedule
  }>
  payoutAmount?: Money
  makeAPaymentV2ModalEnabled?: boolean
}

export const isOrderTypeUnsupportedForPaymentMethod = (paymentMethod: PaymentMethods, paymentType: PaymentType): boolean => {
  const supportedOrderTypes = isCard(paymentMethod) || isBankAccount(paymentMethod) || isCashAppPay(paymentMethod) ? paymentMethod.supportedOrderTypes : null

  // @TODO: Remove this override when cashapp has been updated to return a supportedOrderTypes prop in the api
  if (isCashAppPay(paymentMethod) && paymentType === PaymentType.PBI) {
    return false
  }
  if (supportedOrderTypes === null || !Array.isArray(supportedOrderTypes)) {
    return true
  }

  return !supportedOrderTypes.includes(paymentType)
}

export const isScheduleDueToday = (schedule: OrderPaymentSchedule): boolean =>
  new Date(schedule?.installmentDueDate).toDateString() === new Date().toDateString()

export const isScheduleOverdue = (schedule: OrderPaymentSchedule): boolean => schedule?.status === PaymentStatus.OVERDUE

export const isScheduleCompleted = (schedule: OrderPaymentSchedule): boolean => parseFloat(schedule?.amountPayable?.amount) <= 0

export const isSchedulePartialRefund = (schedule: OrderPaymentSchedule): boolean =>
  schedule?.status === PaymentStatus.PARTIAL_REFUND || schedule?.paymentStatus === PaymentStatus.PARTIAL_REFUND

export const isScheduleRefunded = (schedule: OrderPaymentSchedule): boolean =>
  schedule?.status === PaymentStatus.REFUNDED || schedule?.paymentStatus === PaymentStatus.REFUNDED

export const isScheduleOwed = (schedule: OrderPaymentSchedule): boolean => schedule?.status === PaymentStatus.OWED

export const isSchedulePayable = (schedule: OrderPaymentSchedule): boolean =>
  isScheduleDueToday(schedule) || isScheduleOverdue(schedule) || isScheduleOwed(schedule)

export const isScheduleDue = (schedule: OrderPaymentSchedule): boolean => isScheduleDueToday(schedule) || isScheduleOverdue(schedule)

export const isScheduleDueInFuture = (schedule: OrderPaymentSchedule): boolean => !isScheduleDueToday(schedule) && isScheduleOwed(schedule)

export const isSchedulePaid = (schedule: OrderPaymentSchedule): boolean => {
  return schedule?.status === PaymentStatus.PAID
}

export const hasScheduleFee = (schedule: OrderPaymentSchedule): boolean => isValidMoney(schedule?.owedFee)

export const isOrderPaymentSchedule = (schedule?: any): schedule is OrderPaymentSchedule => {
  return (schedule as OrderPaymentSchedule)?.originalInstallmentDueDate !== undefined
}

export const calculateAmountPayable = ({ options, payoutAmount }: CalculateAmountPayableProps): Money => {
  if (options && isPaymentArrangementSchedule(options[0]?.schedule)) {
    // @ts-ignore: OPERATION BLEED STOPPER
    return options?.reduce(
      // @ts-ignore: OPERATION BLEED STOPPER
      (prev, current) => {
        const schedule = current.schedule as PaymentArrangementSchedule
        const currentAmount = schedule?.outstandingBalance
        return {
          amount: parseFloat(String(Number(prev.amount) + Number(currentAmount.amount))).toFixed(2),
          currency: currentAmount.currency,
          symbol: currentAmount.symbol,
        }
      },
      { amount: '0', currency: undefined, symbol: '' }
    )
  }
  // @TODO: Confirm if this can be removed after consumer-portal-make-a-payment-v2 is fully rolled out
  if (options?.length === 1) {
    const schedule = options[0]?.schedule as OrderPaymentSchedule
    return schedule?.amountPayable ?? payoutAmount
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  if (isValidMoney(payoutAmount)) {
    // @ts-ignore: OPERATION BLEED STOPPER
    return payoutAmount
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  return options?.reduce(
    // @ts-ignore: OPERATION BLEED STOPPER
    (prev, current) => {
      const schedule = current.schedule as OrderPaymentSchedule
      const currentAmount = schedule?.amountPayable
      return {
        amount: parseFloat(String(Number(prev.amount) + Number(currentAmount.amount))).toFixed(2),
        currency: currentAmount.currency,
        symbol: currentAmount.symbol,
      }
    },
    { amount: '0', currency: undefined, symbol: '' }
  )
}

export const validatePaymentSelection = (options: PayNowScheduleOption[] = []): boolean => {
  // Find the last selected schedule and index.
  const reversedOptions = [...options].reverse()
  const lastSelectedIndex = reversedOptions.findIndex((option) => option.selected)
  const lastSelectedOption = reversedOptions[lastSelectedIndex]

  // If only a single payout option is available, the attempt is valid
  // @ts-ignore: OPERATION BLEED STOPPER
  if (options.length === 1 && isValidMoney(options[0].payoutAmount)) {
    return true
  }

  if (lastSelectedOption === undefined) {
    return false
  }

  // If the last selected schedule is due in the future, check our array (of schedules prior to our selected schedule)
  // for schedules that have not been selected but need to be paid. Otherwise, the payment attempt is considered valid
  return isScheduleDueInFuture(lastSelectedOption?.schedule as OrderPaymentSchedule)
    ? Boolean(
        reversedOptions
          .slice() // Avoid mutating the array
          .splice(lastSelectedIndex)
          .every(({ selected, schedule }) => selected && isSchedulePayable(schedule as OrderPaymentSchedule))
      )
    : true
}

export const getPaymentScheduleSetPayable = (schedules: OrderPaymentSchedule[]): OrderPaymentSchedule[] =>
  schedules?.filter((schedule) => isScheduleOverdue(schedule) || isScheduleOwed(schedule))

export const getFirstDueSchedule = (schedules: OrderPaymentSchedule[]): OrderPaymentSchedule =>
  getLastScheduleDueToday(schedules) ?? getLastScheduleOverdue(schedules) ?? getFirstScheduleOwed(schedules)

export const getLastScheduleDueToday = (schedules: OrderPaymentSchedule[] = []): OrderPaymentSchedule =>
  // @ts-ignore: OPERATION BLEED STOPPER
  [...schedules].reverse().find((schedule) => isScheduleDueToday(schedule) && isScheduleOwed(schedule))

export const getLastScheduleOverdue = (schedules: OrderPaymentSchedule[] = []): OrderPaymentSchedule =>
  // @ts-ignore: OPERATION BLEED STOPPER
  [...schedules].reverse().find((schedule) => isScheduleOverdue(schedule))
// @ts-ignore: OPERATION BLEED STOPPER
export const getFirstScheduleOwed = (schedules: OrderPaymentSchedule[] = []): OrderPaymentSchedule => schedules.find((schedule) => isScheduleOwed(schedule))

export const getOrderChannelLabel = (channel: OrderChannel): string => (channel?.includes('IN_STORE') ? 'inStore' : 'online')

export const hasInstallmentLastAndOwed = (schedules: OrderPaymentSchedule[] = []): boolean =>
  schedules.some((schedule) => Boolean(schedule?.isInstallmentLastAndOwed))

export const isFinalOrderDue = (schedules: OrderPaymentSchedule[] = []): boolean => {
  const firstDueSchedule = getFirstDueSchedule(schedules)
  return schedules[schedules?.length - 1] === firstDueSchedule
}

export const getPpaDocumentTitle = (docTypes: OrderPpaDocTypes): string => {
  return ppaDocumentLabel[docTypes] ?? 'fallback'
}

// NOTE: The reason why we -1 to the total for PCL orders, is because they can contain an initial downpayment which skews how many schedules there actually are from the total owable
export const getTotalInstallmentCount = (installmentsCount: number, paymentUpFront: boolean | undefined, paymentType: PaymentType | undefined): number =>
  Boolean(paymentUpFront) && paymentType === PaymentType.PCL ? installmentsCount - 1 : installmentsCount

// NOTE: The reason why we -1 to the total for PCL orders, is because they can contain an initial downpayment which skews how many schedules there actually are from the total owable
export const getInstallmentSequence = (installmentSequence: number, paymentUpFront: boolean | undefined, paymentType: PaymentType | undefined): number =>
  Boolean(paymentUpFront) && paymentType === PaymentType.PCL ? installmentSequence - 1 : installmentSequence
