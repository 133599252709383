import { Dispatch, SetStateAction, useState } from 'react'

export enum PAGE_STATUSES {
  ERROR = 'error',
  LOADING = 'loading',
  SUCCESS = 'success',
}

export const usePageStatus = (defaultState?: PAGE_STATUSES): [PAGE_STATUSES, Dispatch<SetStateAction<PAGE_STATUSES>>] => {
  const [pageStatus, setPageStatus] = useState<PAGE_STATUSES>(defaultState ?? PAGE_STATUSES.LOADING)
  return [pageStatus, setPageStatus]
}
