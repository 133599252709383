import { CountryNameRules } from './types'
import { IdentityErrorCode } from '@afterpaytouch/portal-api/identity'

export const FULL_NAME_MAX_LENGTH = 52

export const COUNTRY_TO_NAME_RESTRICTIONS: CountryNameRules = {
  NZ: {
    givenNames: {
      maxLength: 30,
    },
    otherGivenNames: {
      maxLength: 30,
    },
    surname: {
      maxLength: 30,
    },
    fullName: {
      maxLength: FULL_NAME_MAX_LENGTH,
    },
  },
  DEFAULT: {
    givenNames: {
      maxLength: 20,
    },
    otherGivenNames: {
      maxLength: 20,
    },
    surname: {
      maxLength: 40,
    },
    fullName: {
      maxLength: FULL_NAME_MAX_LENGTH,
    },
  },
}

export const USER_VERIFICATION_ERRORS: Array<Partial<IdentityErrorCode>> = [
  IdentityErrorCode.IdentityCheckRejected,
  IdentityErrorCode.DuplicateIdentity,
  IdentityErrorCode.IdentityAlreadyRejected,
  IdentityErrorCode.IdentityAlreadyVerified,
  IdentityErrorCode.DeclinedDueToCountryOrigin,
]
