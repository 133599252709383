import { useCallback, useEffect, useMemo } from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { useRouter } from 'next/router'
import { SerializedError } from '@reduxjs/toolkit'
import { TFunction } from 'next-i18next'
import { TopazResult } from '@afterpaytouch/topaz-api'
import { isUndefinedOrEmpty } from '@afterpay/utils/string'
import { getConfirmPayError } from '../../../state/paymentSchedule/utils'
import { getTopazError } from '../../../state/topaz/utils'
import { usePayNowModalContext } from '../PayNowModalContext'
import { useGetOrderTransactionQuery, usePayHardshipInstallmentMutation, usePreferredCreditCard, useUpdatePreferredCardMutation } from '../../../state'
import { getMerchantName } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import { isCardScanRequired } from '../../../state/cardScan/utils'
import { useFlag } from '../../../hooks'
import { AxiosError } from 'axios'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { AmplitudeEventTypesProperties } from '../../../integrations/amplitude/types'
import { TrackingEvent } from '../../../model/amplitude'
import { isCard, PaymentCardDetails } from '@afterpay/types'
import { CreditCard, ErrorObject } from '../../../model'
import { HardshipRequest } from '@afterpaytouch/portal-api/consumer/hardship/types'
import { isCustomAmountPayment as checkCustomPayment } from '../utils'
import { useMoney } from '@afterpaytouch/core'
import { SupportedLocale } from '@afterpaytouch/shop-api/types'

interface TopazConfirmPaynow {
  topazData: TopazResult
  isTopazError: boolean
  isInstallmentError: boolean
  isCustomAmountError: boolean
  isInstallmentSuccess: boolean
  isCustomAmountSuccess: boolean
  isPayHardshipInstallmentSuccess?: boolean
  isPayHardshipInstallmentError?: boolean
  installmentError: FetchBaseQueryError | SerializedError
  customAmountError: FetchBaseQueryError | SerializedError
  hardshipInstallmentError?: FetchBaseQueryError | SerializedError
  t: TFunction
}

export const usePaymentConfirmResult = ({
  topazData,
  isTopazError,
  isInstallmentError,
  isCustomAmountError,
  isInstallmentSuccess,
  isCustomAmountSuccess,
  isPayHardshipInstallmentSuccess,
  isPayHardshipInstallmentError,
  installmentError,
  customAmountError,
  hardshipInstallmentError,
  t,
}: TopazConfirmPaynow): void => {
  const router = useRouter()
  const { setResult, order, hasAccountLevelPayments, payment } = usePayNowModalContext()
  const orderId = order?.orderId
  const { data } = useGetOrderTransactionQuery(orderId as string, { skip: hasAccountLevelPayments })
  const { paymentType } = data ?? {}
  // @ts-ignore: OPERATION BLEED STOPPER
  const merchantName = getMerchantName(data?.merchant)
  const cardScanEnabled = useFlag('card-scanning-new-consumer-portal-custom-payment-enabled')
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const total = useMemo(() => (checkCustomPayment(payment) ? payment?.amount : payment?.total), [payment])
  const { formatMoney } = useMoney({
    locale: router?.locale as SupportedLocale,
    currency: total?.currency,
  })
  useEffect(() => {
    const logResult = (event: keyof AmplitudeEventTypesProperties): void => {
      logEvent(event, {
        orderId,
        outboundLink: router?.pathname,
        paymentType,
        amount: formatMoney(total?.amount),
        isHardship: hasAccountLevelPayments,
      })
    }
    const topazError = getTopazError(topazData)
    // 4|5xx or 2xx and error response
    if (isTopazError || (!isUndefinedOrEmpty(topazError) && topazError !== 'unacceptableCard')) {
      setResult({
        error: true,
        success: false,
        message: t(`payments:customPayment:payment:error:messages:${topazError ?? 'default'}`, { brandName: merchantName }),
      })
    }

    if (isInstallmentError || isCustomAmountError || isPayHardshipInstallmentError) {
      logResult(TrackingEvent.CLICKED_MAKE_A_PAYMENT_SUBMIT_PAYMENT_FAILURE)
      if (cardScanEnabled && (isCardScanRequired(installmentError) || isCardScanRequired(customAmountError))) {
        setResult({
          error: false,
          success: false,
        })
      } else {
        setResult({
          error: true,
          success: false,
          message: t(
            `payments:customPayment:payment:error:messages:${
              getConfirmPayError(installmentError ?? customAmountError ?? hardshipInstallmentError) ?? 'default'
            }`,
            {
              brandName: merchantName,
            }
          ),
        })
      }
    }

    if (isInstallmentSuccess || isCustomAmountSuccess || isPayHardshipInstallmentSuccess) {
      logResult(TrackingEvent.CLICKED_MAKE_A_PAYMENT_SUBMIT_PAYMENT_SUCCESS)
      setResult({
        error: false,
        success: true,
      })
    }
  }, [
    topazData,
    isTopazError,
    isInstallmentError,
    isCustomAmountError,
    isInstallmentSuccess,
    isCustomAmountSuccess,
    isPayHardshipInstallmentSuccess,
    isPayHardshipInstallmentError,
    installmentError,
    customAmountError,
    hardshipInstallmentError,
    setResult,
    t,
    merchantName,
    cardScanEnabled,
    logEvent,
    orderId,
    router?.pathname,
    paymentType,
    formatMoney,
    total?.amount,
  ])
}
type UseConfirmHardshipCardPaymentResult = [
  () => Promise<any>,
  {
    isSuccess: boolean
    isError: boolean
    isLoading: boolean
    error: FetchBaseQueryError | SerializedError | ErrorObject
  }
]
export const useConfirmHardshipCardPayment = (): UseConfirmHardshipCardPaymentResult => {
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const preferredCard = usePreferredCreditCard()
  const [setPreferredCard, { isSuccess: isPreferredCardSuccess, isLoading: isPreferredCardLoading }] = useUpdatePreferredCardMutation()
  const [payHardshipInstallment, { isSuccess, isError, error, isLoading: isPayHardshipInstallmentLoading }] = usePayHardshipInstallmentMutation()
  const { order, payment, paymentMethod, payNowStep } = usePayNowModalContext()
  const isLoading = isPreferredCardLoading || isPayHardshipInstallmentLoading

  const setCardAsPreferred = async (paymentMethod): Promise<void> => {
    try {
      await setPreferredCard({
        params: { id: paymentMethod.id.toString() },
        cache: { invalidatesTags: ['CreditCards'] },
      })
    } catch (e) {}
  }

  const confirmHardshipPayment = async (): Promise<void> => {
    const isCustomAmountPayment = checkCustomPayment(payment)
    const hardshipParams: HardshipRequest = isCustomAmountPayment
      ? {
          amount: payment?.amount,
          paymentPlanUuid: String(order?.orderId),
        }
      : {
          amount: payment?.total,
          paymentPlanUuid: String(order?.orderId),
          paymentScheduleUuid: payment?.scheduleId,
        }

    try {
      await payHardshipInstallment(hardshipParams).unwrap()
    } catch (e) {
      const error = e as AxiosError
      logEvent(TrackingEvent.VIEWED_FAILED_PAYMENT_MODAL, {
        failureReason: error.response ?? error.message ?? 'Custom payment - Card payment issue',
        // @ts-ignore: OPERATION BLEED STOPPER
        failureCode: error.code,
        isHardship: true,
      })
    }
  }

  const handleConfirmHardshipPayment = useCallback(async (): Promise<any> => {
    if (paymentMethod && isCard(paymentMethod) && preferredCard?.id !== paymentMethod.id) {
      return await setCardAsPreferred(paymentMethod)
    }
    return await confirmHardshipPayment()
  }, [paymentMethod, preferredCard, setCardAsPreferred, confirmHardshipPayment])

  useEffect(() => {
    if (isPreferredCardSuccess) {
      confirmHardshipPayment()
    }
  }, [isPreferredCardSuccess])

  return [
    handleConfirmHardshipPayment,
    {
      isSuccess,
      isError,
      isLoading,
      // @ts-ignore: OPERATION BLEED STOPPER
      error,
    },
  ]
}
interface IuseShouldBlockChaseCard {
  isChaseCreditCardBlocked: (card: PaymentCardDetails | CreditCard, isBlockedFlow?: boolean) => boolean
}

export const useShouldBlockChaseCard = (): IuseShouldBlockChaseCard => {
  const isBlockChaseEnabled = useFlag('rocketship.block-chase-cc-enabled')

  const isChaseCreditCardBlocked = (card: PaymentCardDetails | CreditCard, isBlockedFlow: boolean = false): boolean => {
    const chaseBrands = ['JPMORGAN CHASE', 'JP MORGAN CHASE', 'SKYMILES BY JP MORGAN CHASE', 'CHASE', 'AMAZON.CA BY JPMORGAN CHASE']
    const isChaseBank = chaseBrands.some((brand) => card?.issuingBank?.toUpperCase().startsWith(brand))
    return isChaseBank && card?.creditCardType === 'CREDIT' && isBlockedFlow && isBlockChaseEnabled
  }

  return { isChaseCreditCardBlocked }
}
