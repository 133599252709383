import React, { FunctionComponent, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Icon, Radio, Span, Text } from '@afterpaytouch/core'
import { BankAccountAddPublicTokenResponse } from '@afterpaytouch/portal-api'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { useConsumerSizes } from '../../../utils/responsive'
import { BankAccountItem } from './BankAccountItem'
import { BankDetailsFromPlaid } from '@afterpay/types'

const I18N_NAMESPACE = ['ach']

export const BankAccountsList: FunctionComponent<{ onSelect: (a: BankDetailsFromPlaid) => void; data: BankAccountAddPublicTokenResponse }> = ({
  data,
  onSelect,
}) => {
  const { radioSize, smallTextSize } = useConsumerSizes()

  const { t } = useTranslation(I18N_NAMESPACE)
  const [allAccounts, setAllAccounts] = useState<BankDetailsFromPlaid[]>([])

  // Consumer can only link the valid unlinked account
  const [validLinkedAccounts, setValidLinkedAccounts] = useState<BankDetailsFromPlaid[]>([])
  const [validUnlinkedAccounts, setValidUnlinkedAccounts] = useState<BankDetailsFromPlaid[]>([])
  const [validLinkedToOthersAccounts, setValidLinkedToOthersAccounts] = useState<BankDetailsFromPlaid[]>([])
  const [invalidAccountsLength, setInvalidAccountsLength] = useState<number>(0)
  const amplitude = useAmplitudeWithEnduringEventProperties()

  useEffect(() => {
    if (typeof data?.accounts === 'undefined' || data?.accounts?.length === 0) {
      return
    }
    const checkingAccounts = data.accounts
    setAllAccounts(checkingAccounts)
    amplitude.logEvent(TrackingEvent.ACH.ViewAddBankAccountInfo, { accounts: allAccounts })
    setValidLinkedAccounts(
      checkingAccounts.filter(function (a) {
        return a.validAccount && a.linkedThisConsumer
      })
    )
    setValidUnlinkedAccounts(
      checkingAccounts.filter(function (a) {
        return a.validAccount && !a.linkedThisConsumer && !a.linkedOtherConsumers
      })
    )
    setValidLinkedToOthersAccounts(
      checkingAccounts.filter(function (a) {
        return a.validAccount && a.linkedOtherConsumers
      })
    )
    setInvalidAccountsLength(
      checkingAccounts.filter(function (a) {
        return !a.validAccount
      }).length
    )
  }, [data])

  if (allAccounts.length === 0) {
    return null
  }
  return (
    <>
      <div className={'mb-4 mt-4 max-h-[35vh] overflow-y-auto'} data-testid='ach-bank-accounts-list'>
        <Radio.Group
          testNameSpace='ach-link-bank-account-selection-list'
          id='ach-link-bank-account-selection-list'
          name='ach-link-bank-account-selection-list'
          icon='Select'
          kind='Option'
        >
          {validUnlinkedAccounts.map((account, i) => (
            <Radio.Button
              key={account.fingerprint}
              size={radioSize}
              labelWidth='Full'
              value={account.fingerprint}
              testNameSpace={`ach-link-bank-account-valid-${i}`}
              label={() => <BankAccountItem name={data.bank.name} numberMask={account.numberMask} />}
              labelPlacement='Middle'
              onChange={() => {
                onSelect(account)
                amplitude.logEvent(TrackingEvent.ACH.SelectedBankAccount, { account })
              }}
            />
          ))}
          {validLinkedAccounts.map((account, i) => (
            <Radio.Button
              disabled
              key={account.fingerprint}
              size={radioSize}
              labelWidth='Full'
              labelPlacement='Middle'
              value={account.fingerprint}
              testNameSpace={`ach-link-bank-account-linked-${i}`}
              label={() => <BankAccountItem name={data.bank.name} numberMask={account.numberMask} linked />}
            />
          ))}
          {validLinkedToOthersAccounts.map((account, i) => (
            <Radio.Button
              disabled
              key={account.fingerprint}
              size={radioSize}
              labelWidth='Full'
              labelPlacement='Middle'
              value={account.fingerprint}
              testNameSpace={`ach-link-bank-account-linkedToOthers-${i}`}
              label={() => <BankAccountItem name={data.bank.name} numberMask={account.numberMask} warning />}
            />
          ))}
        </Radio.Group>
      </div>
      {invalidAccountsLength > 0 && (
        <Text size={smallTextSize}>
          <Trans
            i18nKey={invalidAccountsLength > 1 ? 'ach:linkBankAccount:canNotLinkPlural' : 'ach:linkBankAccount:canNotLink'}
            components={{
              strong: <Span bold />,
            }}
            values={{
              sum: invalidAccountsLength,
            }}
          />
        </Text>
      )}
      {validLinkedAccounts.length > 0 && (
        <div className='mb-2 mt-2 flex'>
          <span className='mr-2'>
            <Icon.Link size='Small' color='Gray50' />
          </span>
          <Text size={smallTextSize}>{t('ach:linkBankAccount:linked')}</Text>
        </div>
      )}
      {validLinkedToOthersAccounts.length > 0 && (
        <div className='mb-2 flex'>
          <span className='mr-2'>
            <Icon.WarningAlt size='Small' color='Gray50' />
          </span>
          <Text size={smallTextSize}>{t('ach:linkBankAccount:warning')}</Text>
        </div>
      )}
    </>
  )
}
