import { Theme } from '@afterpaytouch/core'
import { Country } from '@afterpaytouch/portal-api/types'

export const THEME_COOKIE_NAME = 'x-apt-theme'

export enum ConvergenceUnavailable {
  Disabled = 'Disabled',
  DisabledWithOverride = 'DisabledWithOverride',
}

export enum ConvergenceAvailable {
  Enabled = 'Enabled',
}

export type ConvergenceRollout = ConvergenceAvailable | ConvergenceUnavailable

export interface SetThemeRequest {
  theme: Theme
}

export const DEFAULT_THEME = Theme.AP

export const ConvergenceCountryTheme: Record<Country, Theme> = {
  [Country.AU]: Theme.AP,
  [Country.CA]: Theme.AP,
  [Country.ES]: Theme.AP,
  [Country.FR]: Theme.AP,
  [Country.GB]: Theme.AP,
  [Country.IT]: Theme.AP,
  [Country.NZ]: Theme.AP,
  [Country.US]: Theme.Cash,
}

export const APT_USER_ID = 'apt-user-id'
export const APT_USER_COUNTRY = 'apt-user-country'

export const portalMigrationEnabledCountry = [Country.US]
