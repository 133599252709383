import React, { FunctionComponent } from 'react'
import styles from './style.module.scss'

interface NewTabLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  testNameSpace?: string
}

export const NewTabLink: FunctionComponent<NewTabLinkProps> = ({ href, children, tabIndex = 0, testNameSpace = '', onClick = () => {} }) => (
  <a
    onClick={onClick}
    href={href}
    className={styles.link}
    tabIndex={tabIndex}
    target='_blank'
    rel='noopener noreferrer'
    {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-new-tab-link` })}
  >
    {children}
  </a>
)
