import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { useConsumerSelector, useIdentityPassportCountriesQuery, useIdentityPassportMutation, useIsPartialVerified } from '../../../state'
import { useTranslation } from 'next-i18next'
import { getFormSchema } from './utils'
import { Country } from '@afterpaytouch/portal-api/types'
import { Channel, IdentityDocumentVerificationStatus, IdentityPassportRequest } from '@afterpaytouch/portal-api/identity/types'
import { Message } from '@afterpaytouch/core'
import { CTAButton } from './CTAButton'
import { passportPattern } from './validations'
import FormBuilder from '@afterpaytouch/form'
import { FormWrapper } from '../FormWrapper'
import { getIdentityVerifyErrorCode, IdentityErrorCode } from '@afterpaytouch/portal-api/identity'
import { DocumentProps } from '../../../model'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { isDifferentWithProfileDOB, isDifferentWithProfileNames, IsRetryPageContext } from '../VerifyIdentity/helper'
import { compareObjects } from '../../../utils/comparison'
import { USER_VERIFICATION_ERRORS } from '../VerifyIdentity/constants'
import { Terms } from './Terms'
import { HardIdProfileCard, ErrorMessage, HardIdHelpLink, HardIdHeader } from '../VerifyIdentity/components'
import { joinStrings } from '@afterpay/utils'

const I18N_NAMESPACE = ['common', 'verifyIdentity']

export interface PassportFormData {
  givenNames: string
  otherGivenNames: string
  surname: string
  nameAlert: string
  passportNumber: string
  dateOfBirth?: string
  dobAlert?: string
  countryOfIssue?: string
  expiryDate?: string
  terms: string
  footer: string
  profileCard?: string
}

const getRequest = (data: PassportFormData, initialFormData: PassportFormData, countryCode?: Country): IdentityPassportRequest => {
  const { countryOfIssue, passportNumber, givenNames, otherGivenNames, surname, dateOfBirth, expiryDate } = data
  return {
    channel: Channel.PORTAL,
    givenNames: givenNames ?? initialFormData.givenNames,
    otherGivenNames: otherGivenNames ?? initialFormData.otherGivenNames,
    surname: surname ?? initialFormData.surname,
    countryCode: (countryOfIssue as Country) ?? countryCode ?? Country.NZ,
    number: passportNumber,
    // @ts-ignore: OPERATION BLEED STOPPER
    dateOfBirth: dateOfBirth ?? initialFormData.dateOfBirth,
    expiryDate: expiryDate ?? '',
  }
}

export const Passport: FunctionComponent<DocumentProps> = ({ onSuccess }) => {
  const amplitude = useAmplitudeWithEnduringEventProperties()
  const consumer = useConsumerSelector()
  const { countryCode, givenNames, otherGivenNames, surname, dateOfBirth } = consumer
  const { t } = useTranslation(I18N_NAMESPACE)
  const profileDOB = dateOfBirth ?? ''
  const [schemaUpdates, setSchemaUpdates] = useState({
    passportOptions: {},
    permittedCountries: {},
    showProfileDobAlert: false,
    showProfileNameAlert: false,
  })

  const { data: permittedCountries } = useIdentityPassportCountriesQuery()
  const [identityVerifyPassport] = useIdentityPassportMutation()
  const [submittedRequest, setSubmittedRequest] = useState(undefined)

  const [lock, setLock] = useState(false)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [error, setError] = useState<IdentityErrorCode>(null)
  const isPartialVerified = useIsPartialVerified()
  const isRetryPage = useContext(IsRetryPageContext)
  const isProfileEditable = !(isPartialVerified && isRetryPage)
  const verifyButtonAnalytics =
    isPartialVerified && isRetryPage ? TrackingEvent.PRESSED_HARDID_RETRY_PAGE_VERIFY_BUTTON : TrackingEvent.PRESSED_HARDID_PASSPORT_PAGE_VERIFY_BUTTON

  const { schema, initialFormData } = useMemo(() => {
    const onBlur = (): void => {
      const fullName = joinStrings([formData?.givenNames, formData?.otherGivenNames, formData?.surname])

      setSchemaUpdates((currSchema) => ({
        ...currSchema,
        showProfileNameAlert: isDifferentWithProfileNames(fullName, { givenNames, otherGivenNames, surname }),
      }))
    }

    const components = {
      profileCard: () => <HardIdProfileCard />,
      nameAlert: () => (
        <Message
          kind='Success'
          iconName='Profile'
          heading={t('verifyIdentity:alert:profileUpdateHeading')}
          description={t('verifyIdentity:alert:profileUpdateName')}
          testNameSpace='verify-passport-profile-update'
        />
      ),
      dobAlert: () => (
        <Message
          kind='Success'
          iconName='Profile'
          heading={t('verifyIdentity:alert:profileUpdateHeading')}
          description={t('verifyIdentity:alert:profileUpdateDOB')}
          testNameSpace='verify-passport-dob-update'
        />
      ),
      terms: () => <Terms />,
      footer: () => <CTAButton lock={lock} submitted={lock} />,
    }
    const isUserFormError = USER_VERIFICATION_ERRORS.includes(error)
    // @ts-ignore: OPERATION BLEED STOPPER
    return getFormSchema({ consumer, permittedCountries, components, t, onBlur, schemaUpdates, error: isUserFormError, isProfileEditable })
  }, [consumer, t, schemaUpdates, lock, permittedCountries, error, isProfileEditable, givenNames, otherGivenNames, surname])

  const [passportCountry, setPassportCountry] = useState(undefined)
  const [formData, setFormData] = useState(initialFormData)

  useEffect(() => {
    // @ts-ignore: OPERATION BLEED STOPPER
    setSchemaUpdates((currentSchema) => ({
      ...currentSchema,
      permittedCountries,
    }))
  }, [permittedCountries])

  useEffect(() => {
    if (typeof error !== 'undefined') {
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [error])

  const onChange = (e: PassportFormData): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    setError(null)
    const { countryOfIssue, dateOfBirth = initialFormData.dateOfBirth } = e
    let updatedPassportOptions = {}
    if (countryCode !== Country.NZ && countryOfIssue !== passportCountry) {
      // @ts-ignore: OPERATION BLEED STOPPER
      setPassportCountry(countryOfIssue)

      updatedPassportOptions = {
        ...schema.passportNumber.options,
        ...passportPattern({ countryOfIssue, t }),
      }
    }

    setSchemaUpdates((currentSchema) => ({
      ...currentSchema,
      passportOptions: updatedPassportOptions,
      showProfileDobAlert: isDifferentWithProfileDOB(dateOfBirth, profileDOB),
    }))

    setFormData(e)
  }

  const onFormSubmit = async (data: PassportFormData): Promise<void> => {
    amplitude.logEvent(verifyButtonAnalytics)
    setLock(true)
    // @ts-ignore: OPERATION BLEED STOPPER
    setError(null)
    const request: IdentityPassportRequest = getRequest(data, initialFormData, countryCode)

    if (compareObjects(request, submittedRequest)) {
      setError(IdentityErrorCode.IdentityAlreadyRejected)
      setLock(false)
      return
    }

    try {
      const response = await identityVerifyPassport(request).unwrap()
      if (response.status === IdentityDocumentVerificationStatus.VERIFIED) {
        // @ts-ignore: OPERATION BLEED STOPPER
        onSuccess()
      } else if (response.status === IdentityDocumentVerificationStatus.REJECTED) {
        // @ts-ignore: OPERATION BLEED STOPPER
        setSubmittedRequest(request)
        setError(IdentityErrorCode.IdentityCheckRejected)
        setLock(false)
      }
    } catch (e) {
      const error = getIdentityVerifyErrorCode(e)
      setError(error ?? IdentityErrorCode.Default)
      setLock(false)
    }
  }

  return (
    <>
      <HardIdHeader documentType='passport' />
      <ErrorMessage identityType='passport' error={error} testNameSpace={'verify-password-error-message'} />
      <FormWrapper>
        <FormBuilder schema={schema} testNameSpace={'verify-passport'} onChange={onChange} onSubmit={onFormSubmit} disabled={lock} />
      </FormWrapper>
      <HardIdHelpLink />
    </>
  )
}
