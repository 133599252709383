import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'
import { Text, Skeleton, useIsCashTheme } from '@afterpaytouch/core'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'

const I18N_NAMESPACE = ['common', 'index']

interface GreetingProps {
  consumer: { givenName?: string }
  firstPurchase?: boolean
  isLoading: boolean
  withComma?: boolean
}

export const Greeting: FunctionComponent<GreetingProps> = ({ consumer, firstPurchase = false, isLoading, withComma = false }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isCashTheme = useIsCashTheme()
  const interpolationProps = {
    givenName: consumer.givenName,
  }
  return (
    <div className='relative mb-2'>
      <MaskedElement>
        <Text renderAs='span' size='XL' bold testNameSpace='salute'>
          {firstPurchase
            ? t('index:welcome:firstPurchase:greeting', interpolationProps)
            : withComma
            ? t('index:welcome:greeting:defaultWithComma', interpolationProps)
            : t('index:welcome:greeting:default', interpolationProps)}
        </Text>
      </MaskedElement>
      {isLoading && <Skeleton kind={isCashTheme ? 'Default' : 'Primary'} />}
    </div>
  )
}
