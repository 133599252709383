import React, { ReactNode, useMemo, useState, useRef, useEffect, useCallback } from 'react'
import { Text, Radio, Skeleton } from '@afterpaytouch/core'
import { useFlag } from '../../hooks'
import { AchBankAccountStatuses, BankAccountDetails, CardDetails, CashAppPayDetail, isBankAccount, isCard, isCashAppPay, PaymentType } from '@afterpay/types'
import { PaymentMethodType, PreferredPaymentMethod, SavedPaymentMethod } from '@afterpaytouch/portal-api/types'
import { useTranslation } from 'next-i18next'
import { useConsumerSizes } from '../../utils/responsive'
import { IconPosition, PaymentMethodRow } from '../PaymentMethodRow'
import { isConsumerLendingOrder, useConsumerHasAccountLevelPayments, useConsumerSelector, useIsApplePayAvailable } from '../../state'
import { maskCreditCardNumber } from '../../utils/card'
import { maskBankAccountNumber } from '../../utils/bankAccount'
import { isOrderTypeUnsupportedForPaymentMethod } from '../../state/orders/utils'
import { Currency } from '../../model'
import { PaymentMethods } from '../../state/orders/types'

const I18N_NAMESPACE = ['common', 'payments']

interface PaymentMethodSelectProps {
  paymentMethods: Array<CardDetails | BankAccountDetails | CashAppPayDetail>
  onChange: (method: PaymentMethods) => void
  currency: Currency
  testNameSpace?: string
  paymentType?: PaymentType
  loading?: boolean
  isSelectBankAccountEnabled?: boolean
  defaultPaymentMethod?: SavedPaymentMethod | null
  linkedAccountId?: Number
  agreementUsingPreferredPaymentMethod?: boolean
}

const PaymentMethodLoading = (rowNumber: number = 4): JSX.Element => {
  return (
    <>
      {[...new Array(rowNumber)].map((_, i) => (
        <div key={`payment-method-loading-${i}`} className='relative my-2 h-[64px]' data-testid='skeleton-div'>
          <Skeleton />
        </div>
      ))}
    </>
  )
}

export const PaymentMethodSelect = ({
  paymentMethods = [],
  onChange,
  currency,
  testNameSpace,
  paymentType = PaymentType.PBI,
  loading = false,
  isSelectBankAccountEnabled = false,
  defaultPaymentMethod,
  linkedAccountId,
  agreementUsingPreferredPaymentMethod = false,
}: PaymentMethodSelectProps): React.ReactElement => {
  /* util hooks */
  const { t } = useTranslation(I18N_NAMESPACE)
  const { radioSize, textSize } = useConsumerSizes()

  /* api hooks */
  const isApplePayAvailable = useIsApplePayAvailable()
  const { preferredPaymentMethod } = useConsumerSelector() // user level preference
  const hasAccountLevelPayments = useConsumerHasAccountLevelPayments()
  const isConsumerLending = isConsumerLendingOrder(paymentType)
  const isApplePayDisabled: boolean = useFlag('consumer-portal-is-apple-pay-disabled', false)
  const isAmexSoftCloseEnabled = useFlag('amex-soft-close-enabled', false)
  const showApplePayOption = isApplePayAvailable && !hasAccountLevelPayments && !isConsumerLending && !isApplePayDisabled

  const preferredPaymentMethodId = useMemo(() => {
    // If a bank account has just been added, make this the preferred payment method
    if (linkedAccountId !== null) {
      const newlyAddedBankAccount = paymentMethods.find((method) => method.id === linkedAccountId)
      if (newlyAddedBankAccount !== undefined && isBankAccount(newlyAddedBankAccount)) {
        return newlyAddedBankAccount.id
      }
    }

    if (defaultPaymentMethod != null) {
      if (defaultPaymentMethod.type === PaymentMethodType.APPLE_PAY && isApplePayAvailable) {
        // order level preference is apple pay, and applepay is available
        return PaymentMethodType.APPLE_PAY
      } else if (defaultPaymentMethod.type !== PaymentMethodType.APPLE_PAY) {
        // order level preference is not apple pay, make sure the payment method exists
        const foundPaymentMethod = paymentMethods.find((method) => method.id === defaultPaymentMethod.id)
        if (foundPaymentMethod != null) {
          return foundPaymentMethod.id
        }
      }
    }

    // when we reach here, the order level preferred method doesn't exist in the given paymentMethods
    // we need to fallback to user level preference
    if (preferredPaymentMethod === PreferredPaymentMethod.CASH_APP_PAY) {
      // if account level is cash app pay, find cash app pay
      const foundPaymentMethod = paymentMethods.find((method) => isCashAppPay(method))
      if (foundPaymentMethod != null) {
        return foundPaymentMethod.id
      }
    }
    if (preferredPaymentMethod === PreferredPaymentMethod.APPLE_PAY && isApplePayAvailable) {
      // if account level is apple pay and applepay is available
      return PaymentMethodType.APPLE_PAY
    }

    // if account level is credit card or bank account, or above logic fails
    const foundPaymentMethod = paymentMethods.find((method) => {
      return !isCashAppPay(method) && method.preferred
    })
    if (foundPaymentMethod != null) {
      return foundPaymentMethod.id
    }
    return null
  }, [defaultPaymentMethod, isApplePayAvailable, paymentMethods, preferredPaymentMethod])

  useEffect(() => {
    if (preferredPaymentMethodId != null) {
      if (preferredPaymentMethodId === PaymentMethodType.APPLE_PAY) {
        onChange(PaymentMethodType.APPLE_PAY)
      } else {
        const foundPaymentMethod = paymentMethods.find((method) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
          return method.id === preferredPaymentMethodId && handleIsPaymentMethodDisplayDisabled(method) === false
        })
        if (foundPaymentMethod != null) {
          onChange(foundPaymentMethod)
        }
      }
    }
  }, [preferredPaymentMethodId])

  /* funcs */
  const renderLabel = (method: PaymentMethods): ReactNode => {
    // TO DO: Update below to truncatedNumber when response is updated

    if (isCard(method)) {
      const { maskedPan, truncatedNumber, expiryDate, cardBrand } = method
      const showAmexError = isAmexSoftCloseEnabled && cardBrand === 'AMEX'
      // TO DO: Update below to truncatedNumber when response is updated
      return (
        <PaymentMethodRow
          number={maskCreditCardNumber(truncatedNumber ?? maskedPan)}
          expiryDate={expiryDate}
          cardType={cardBrand}
          iconPosition={IconPosition.Right}
          // @ts-ignore: OPERATION BLEED STOPPER
          disabled={showAmexError || handleIsPaymentMethodDisplayDisabled(method)}
          testNameSpace='card'
          textColorFire={showAmexError}
        />
      )
    }
    if (isBankAccount(method)) {
      const { numberMask, issuingBank } = method

      return (
        <PaymentMethodRow
          testNameSpace='bank-account'
          bankName={issuingBank}
          number={maskBankAccountNumber(numberMask)}
          iconPosition={IconPosition.Right}
          // @ts-ignore: OPERATION BLEED STOPPER
          disabled={handleIsPaymentMethodDisplayDisabled(method)}
          type={PaymentMethodType.ACH_BANK_ACCOUNT}
          textColorFire={method.status === AchBankAccountStatuses.UNLINKED}
        />
      )
    }
    if (isCashAppPay(method)) {
      const { cashtag } = method
      return (
        <PaymentMethodRow
          testNameSpace='cash-app-pay'
          bankName={t('common:cashAppPay')}
          number={cashtag}
          iconPosition={IconPosition.Right}
          // @ts-ignore: OPERATION BLEED STOPPER
          disabled={handleIsPaymentMethodDisplayDisabled(method)}
          type={PaymentMethodType.CASH_APP_PAY}
        />
      )
    }
  }
  const handleChange = (method: PaymentMethods): void => {
    onChange(method)
  }
  const handleIsPaymentMethodDisplayDisabled = useCallback(
    (method: PaymentMethods): boolean | null => {
      const isOrderTypeUnsupported = isOrderTypeUnsupportedForPaymentMethod(method, paymentType)

      if (isBankAccount(method)) {
        return !isSelectBankAccountEnabled || isOrderTypeUnsupported || method.status === AchBankAccountStatuses.UNLINKED
      }

      return isOrderTypeUnsupported
    },
    [isSelectBankAccountEnabled, paymentType]
  )

  const isPreferredPaymentMethodDisabled = useMemo(
    // @ts-ignore: OPERATION BLEED STOPPER
    () => Boolean(handleIsPaymentMethodDisplayDisabled(paymentMethods.find((method) => method.id === preferredPaymentMethodId))),
    [handleIsPaymentMethodDisplayDisabled, paymentMethods, preferredPaymentMethodId]
  )

  const sortedPaymentMethods = useMemo(() => {
    return Array.from(new Set([paymentMethods?.find((x) => x.id === preferredPaymentMethodId), ...paymentMethods]))
  }, [paymentMethods, preferredPaymentMethodId])

  /* states & refs */
  const [isApplePaySelected, setIsApplePaySelected] = useState(preferredPaymentMethodId === PaymentMethodType.APPLE_PAY)
  const preAuthMessageRef = useRef(null)

  /* useEffect */
  useEffect(() => {
    if (isApplePaySelected) {
      // @ts-ignore: OPERATION BLEED STOPPER
      preAuthMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isApplePaySelected])

  if (paymentMethods.length === 0) {
    // @ts-ignore: OPERATION BLEED STOPPER
    return null
  }
  return loading ? (
    PaymentMethodLoading()
  ) : (
    <div className={'max-h-[35vh] overflow-y-auto'}>
      <Radio.Group
        testNameSpace={testNameSpace}
        id='payment-selection-list'
        name='payment-selection-list'
        // @ts-ignore: OPERATION BLEED STOPPER
        defaultValue={agreementUsingPreferredPaymentMethod || isPreferredPaymentMethodDisabled ? null : String(preferredPaymentMethodId)}
        icon='Select'
        kind='Option'
      >
        {sortedPaymentMethods.map(
          (method) =>
            method !== undefined && (
              <Radio.Button
                size={radioSize}
                key={method.id}
                // @ts-ignore: OPERATION BLEED STOPPER
                disabled={(isAmexSoftCloseEnabled && method.cardBrand === 'AMEX') || handleIsPaymentMethodDisplayDisabled(method)}
                labelWidth='Full'
                value={String(method.id)}
                label={() => renderLabel(method)}
                labelPlacement='Middle'
                testNameSpace={testNameSpace}
                onChange={() => {
                  setIsApplePaySelected(false)
                  handleChange(method)
                }}
              />
            )
        )}
        {showApplePayOption && (
          <Radio.Button
            size={radioSize}
            labelWidth='Full'
            value={PaymentMethodType.APPLE_PAY}
            testNameSpace={testNameSpace}
            label={() => (
              <PaymentMethodRow
                testNameSpace='apple-pay'
                type={PaymentMethodType.APPLE_PAY}
                isPreferred={preferredPaymentMethodId === PaymentMethodType.APPLE_PAY}
                iconPosition={IconPosition.Right}
                bodyComponent={
                  <div className='flex flex-row items-center'>
                    <div className='ml-2 md:ml-4'>
                      <Text size={textSize}>{t('common:applePay')}</Text>
                    </div>
                  </div>
                }
              />
            )}
            labelPlacement='Middle'
            onChange={() => {
              setIsApplePaySelected(true)
              handleChange(PaymentMethodType.APPLE_PAY)
            }}
          />
        )}
      </Radio.Group>
      {isApplePaySelected && showApplePayOption && (
        <div className='mt-4' ref={preAuthMessageRef}>
          <Text>
            {t('payments:paymentMethodSelect:applePay:preAuthMessage', {
              currency,
            })}
          </Text>
        </div>
      )}
    </div>
  )
}
