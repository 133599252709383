import React, { useCallback, FunctionComponent, useEffect, useContext, useState } from 'react'
import { Modal } from '@afterpaytouch/core'
import { Installments } from './Installments'
import { Payment } from './Payment'
import { PayNowModalInitialStepProp, PayNowSteps, PayNowModalContext, PayNowModalProvider } from './PayNowModalContext'
import { AchFlowModal } from '../Ach'
import { ClearpayPartiallyMigratedRedirection } from './ClearpayPartiallyMigratedRedirection/ClearpayPartiallyMigratedRedirection'
import { PAYMENT_DATA, achOauthFlowSessionStorageInstance } from '../Ach/BasePlaid'

interface PayNowCommonProps {
  isModalOpen: boolean
  closeModal: () => void
  achFlowEntryDeepLink?: string
  isAchClosePayNowModal?: boolean
}

type PayNowModalProps = PayNowCommonProps & PayNowModalInitialStepProp

export const PayNowModalSteps: FunctionComponent<PayNowCommonProps> = ({ isModalOpen, closeModal, achFlowEntryDeepLink, isAchClosePayNowModal }) => {
  const {
    step,
    resetState,
    isLoading,
    isAchFlowModalOpen,
    closeAchFlowModal,
    isEligibleToUseBankAccount,
    setIsAddCheckingAccountSuccess,
    achFlowInitialStep,
    setStep,
    setPayment,
    setLinkedAccountId,
  } = useContext(PayNowModalContext)
  const [isParentModalAnimate, setIsParentModalAnimate] = useState(true)
  const renderStep = useCallback((): React.ReactNode => {
    switch (step) {
      case PayNowSteps.Installments:
        return <Installments />
      case PayNowSteps.Payment:
        return <Payment />
      case PayNowSteps.ClearpayPartiallyMigratedRedirection:
        return <ClearpayPartiallyMigratedRedirection />
    }
  }, [step])

  useEffect(() => {
    if (isAchFlowModalOpen) {
      setIsParentModalAnimate(false)
    }
  }, [isAchFlowModalOpen])

  const handleAchSuccess = (_, id: Number): void => {
    setIsAddCheckingAccountSuccess(true)
    const paymentData = achOauthFlowSessionStorageInstance.getSessionStorageByItemKey(PAYMENT_DATA)
    if (paymentData !== null) {
      setPayment(paymentData)
      setStep(PayNowSteps.Payment)
    }
    if (id !== null) {
      setLinkedAccountId(id)
    }
  }

  useEffect(() => {
    if (!isModalOpen) {
      resetState()
    }
  }, [isModalOpen, resetState])

  return !isAchFlowModalOpen ? (
    <Modal
      show={isModalOpen}
      onClose={closeModal}
      onHide={closeModal}
      onBackdropClick={closeModal}
      onEscapeKeyDown={closeModal}
      testNameSpace='paynow'
      loading={isLoading}
      animate={isParentModalAnimate}
    >
      {renderStep()}
    </Modal>
  ) : (
    <AchFlowModal
      show={isAchFlowModalOpen}
      initialStep={achFlowInitialStep}
      closeModal={closeAchFlowModal}
      initialEligible={isEligibleToUseBankAccount}
      onSuccess={handleAchSuccess}
      animate={false}
      achFlowEntryDeepLink={achFlowEntryDeepLink}
    />
  )
}

export const PayNowModal: FunctionComponent<PayNowModalProps> = ({ isModalOpen = false, closeModal, achFlowEntryDeepLink, ...otherProps }) => {
  const { initalStep, order } = otherProps

  const orderProps = {
    orderId: order?.orderId,
    merchant: order?.merchant,
    paymentType: order?.paymentType,
  }
  const paymentProps =
    initalStep === PayNowSteps.Payment
      ? {
          ...otherProps.payment,
        }
      : null

  return isModalOpen ? (
    // @ts-ignore: OPERATION BLEED STOPPER
    <PayNowModalProvider initialStep={initalStep} closeModal={closeModal} payment={paymentProps} order={orderProps}>
      <PayNowModalSteps isModalOpen={isModalOpen} closeModal={closeModal} achFlowEntryDeepLink={achFlowEntryDeepLink} />
    </PayNowModalProvider>
  ) : null
}
