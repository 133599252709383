import { DisputePermissionError, ErrorCode } from '@afterpaytouch/portal-api'

export enum DisputeReason {
  ProductNotReceived = 'PRODUCT_NOT_RECEIVED',
  SingleUseCard = 'SINGLE_USE_CARD',
  OrderMismatch = 'ORDER_MISMATCH',
}

export enum DisputeSubReason {
  // SingleUseCard
  OutOfStock = 'OUT_OF_STOCK',
  MerchantCancelled = 'MERCHANT_CANCELLED',

  // ProductNotReceived
  NotReceivedGoods = 'NOT_RECEIVED_GOODS',
}

export const MAX_FILE_LENGTH = 3
export const MAX_FILE_SIZE = 5242880 // 5M
export const MAX_NOTE_LENGTH = 500

export const ACCEPT_FILE_MIME: string[] = [
  'image/gif',
  'application/pdf',
  'image/jpeg',
  'image/png',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

export const SUP_KEYS: string[] = ['SINGLE_USE_CARD']

export const isInvalidAmountError = (errorCode: string): errorCode is ErrorCode.InvalidAmount => ErrorCode.InvalidAmount === errorCode?.toLowerCase()
export const isConflictError = (errorCode: string): errorCode is ErrorCode.Conflict => ErrorCode.Conflict === errorCode?.toLowerCase()
export const isTimesLimitError = (errorCode: string): errorCode is ErrorCode.LimitTimesExceeded => ErrorCode.LimitTimesExceeded === errorCode?.toLowerCase()
export const isExternalDisputeExistError = (errorCode: string): errorCode is DisputePermissionError.ExternalDisputeExisted =>
  DisputePermissionError.ExternalDisputeExisted === errorCode?.toUpperCase()
export const isDuplicatedEntityError = (errorCode: string): errorCode is DisputePermissionError.DuplicatedEntity =>
  DisputePermissionError.DuplicatedEntity === errorCode?.toUpperCase()
export const isCreatingTooEarly = (errorCode: string): errorCode is ErrorCode.DisputeCreatingTooEarly =>
  ErrorCode.DisputeCreatingTooEarly === errorCode?.toLowerCase()
export const isCreatingTooLate = (errorCode: string): errorCode is ErrorCode.DisputeCreatingTooLate =>
  ErrorCode.DisputeCreatingTooLate === errorCode?.toLowerCase()

export enum ProcessDisputeAction {
  CancelDispute = 'CONSUMER_CANCEL',
  AcceptDispute = 'CONSUMER_CONFIRM_RECEPTION',
}
export enum ProcessDisputeNote {
  CancelDispute = 'Dispute cancelled',
  AcceptDispute = 'Dispute accepted',
}
