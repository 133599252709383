import { NewCard, ResultCode } from '@afterpaytouch/topaz-api'
import { useFlag } from '../../../../hooks'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { SerializedError } from '@reduxjs/toolkit'
import { InitiateInstallmentPaymentResponse, InitiateNewResponse, PaymentMethodType } from '@afterpaytouch/portal-api'
import { useCallback, useContext, useEffect, useState } from 'react'
import {
  setCardScanCard,
  setCardScanCheckpoint,
  setCardScanTraceId,
  useAppDispatch,
  useConfirmCustomAmountPayNowMutation,
  useConfirmNewMutation,
  usePayHardshipInstallmentMutation,
  useSetupOrderMutation,
  useSetupPaymentMutation,
} from '../../../../state'
import { isValidTopazResponse } from '../../../../state/topaz/utils'
import { ThreeDSReturn, useThreeDS } from '../../../CardForm'
import { CardScanCheckpoint } from '../../../../model'
import { PayNowModalContext } from '../../PayNowModalContext'
import { useConfirmInstallmentPayment } from '../../hooks'
import { CardDefaultValue } from '@afterpaytouch/form/lib/types'
import { useTranslation } from 'next-i18next'
import { usePaymentConfirmResult } from '../hooks'
import { isCustomAmountPayment as checkCustomAmountPayment, isInstallmentPayment as checkInstallmentPayment } from '../../utils'
import { HardshipRequest } from '@afterpaytouch/portal-api/consumer/hardship/types'

const I18N_NAMESPACE = ['common', 'payments']
interface INewPaymentMethodLogic {
  handleAccountCardPayment: (boolean, NewCard) => Promise<void>
  handleOrderCardPayment: (boolean, NewCard, CardDefaultValue) => Promise<void>
  confirmCard: () => Promise<void>
  isFetching: boolean
  isInstallmentError: boolean
  installmentError: FetchBaseQueryError | SerializedError
  isTopazError: boolean
  isCustomAmountError: boolean
  customAmountError: FetchBaseQueryError | SerializedError
  serverError: string | null
  threeDs: Omit<ThreeDSReturn, 'setShowModalCloseButton' | 'checkThreeDS'>
}
export const useNewPaymentMethodLogic = (initiateData: InitiateNewResponse | InitiateInstallmentPaymentResponse): INewPaymentMethodLogic => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const dispatch = useAppDispatch()
  const isThreeDSEnabled = useFlag('consumer-portal-three-ds-enabled')
  const { order, payment, hasAccountLevelPayments, paymentMethod, setResult } = useContext(PayNowModalContext)
  const [setupTopazOrder] = useSetupOrderMutation()
  const [setupTopazPayment, { data: topazData, isError: isTopazError, isLoading: isTopazLoading }] = useSetupPaymentMutation()
  const [confirmInstallment, { isSuccess: isInstallmentSuccess, isError: isInstallmentError, error: installmentError, isLoading: isInstallmentLoading }] =
    useConfirmInstallmentPayment({ orderId: String(order?.orderId) })
  const [confirmCustomAmount, { isSuccess: isCustomAmountSuccess, isError: isCustomAmountError, error: customAmountError, isLoading: isCustomAmountLoading }] =
    useConfirmCustomAmountPayNowMutation()
  const [confirmAccountPayment, { isSuccess: isConfirmAccountSuccess, isLoading: isConfirmAccountLoading }] = useConfirmNewMutation()
  const [
    payHardshipInstallment,
    {
      isSuccess: isPayHardshipInstallmentSuccess,
      isError: isPayHardshipInstallmentError,
      isLoading: isPayHardshipInstallmentLoading,
      error: hardshipInstallmentError,
    },
  ] = usePayHardshipInstallmentMutation()
  const isInstallmentPayment = checkInstallmentPayment(payment)
  const isCustomAmountPayment = checkCustomAmountPayment(payment)
  const isFetching = isTopazLoading || isInstallmentLoading || isCustomAmountLoading || isConfirmAccountLoading || isPayHardshipInstallmentLoading

  const [serverError, setServerError] = useState<string | null>(null)

  const confirmCard = async (storeToken: boolean = false): Promise<void> => {
    if (hasAccountLevelPayments) {
      await confirmAccountPayment({
        traceId: initiateData?.traceId,
        preferred: false,
        cardholderName: (paymentMethod as CardDefaultValue)?.cardHolderName,
      })
    } else if (isInstallmentPayment) {
      await confirmInstallment({
        traceId: initiateData.traceId,
        storeToken,
        paymentMethodType: PaymentMethodType.CREDIT_CARD,
      })
    } else if (isCustomAmountPayment) {
      await confirmCustomAmount({
        orderId: Number(order?.orderId),
        body: {
          traceId: initiateData.traceId,
          storeToken,
          paymentMethodType: PaymentMethodType.CREDIT_CARD,
        },
      })
    }
  }

  const handleAccountCardPayment = async (storeToken: boolean = false, topazParam: NewCard): Promise<void> => {
    try {
      const topazResponse = await setupTopazOrder({
        traceId: initiateData?.traceId,
        ...topazParam,
      }).unwrap()
      if (isValidTopazResponse(topazResponse)) {
        if (isThreeDSEnabled) {
          setShowModalCloseButton(true)
          await checkThreeDS(initiateData?.traceId, storeToken)
        } else {
          await confirmCard(storeToken)
        }
      }
    } catch (error) {
      setResult({ error: true, success: false })
    }
  }

  const handleOrderCardPayment = async (storeToken: boolean = false, topazParam: NewCard, card: CardDefaultValue): Promise<void> => {
    try {
      const topazResponse = await setupTopazPayment({
        ...topazParam,
        traceId: initiateData.traceId,
      }).unwrap()
      dispatch(
        setCardScanCard({
          cardNumber: card?.cardNumber,
          expiryDate: card?.expiryDate,
        })
      )
      dispatch(setCardScanTraceId(initiateData.traceId))
      dispatch(setCardScanCheckpoint(CardScanCheckpoint.PayNowWeb))
      if (isValidTopazResponse(topazResponse)) {
        if (isThreeDSEnabled) {
          setShowModalCloseButton(true)
          await checkThreeDS(initiateData?.traceId, storeToken)
        } else {
          await confirmCard(storeToken)
        }
      }
      if (topazResponse?.resultCode === ResultCode.UNACCEPTABLE_CARD) {
        setServerError(t('payments:customPayment:payment:error:messages:blockedCardError'))
      }
    } catch (error) {
      setResult({ error: true, success: false })
    }
  }

  const payHardship = useCallback(async (): Promise<void> => {
    const isCustomAmountPayment = checkCustomAmountPayment(payment)
    const hardshipParams: HardshipRequest = isCustomAmountPayment
      ? {
          amount: payment?.amount,
          paymentPlanUuid: String(order?.orderId),
        }
      : {
          amount: payment?.total,
          paymentPlanUuid: String(order?.orderId),
          paymentScheduleUuid: payment?.scheduleId,
        }
    try {
      await payHardshipInstallment(hardshipParams)
    } catch {
      setServerError(t('payments:customPayment:payment:error:generic'))
    }
  }, [payment, payHardshipInstallment, order?.orderId])

  useEffect(() => {
    if (isConfirmAccountSuccess) {
      payHardship()
    }
  }, [isConfirmAccountSuccess, payHardship])

  const { checkThreeDS, setShowModalCloseButton, showThreeDSIframe, threeDSError, threeDSIframeURL, threeDSOnClose, setThreeDSError, showModalCloseButton } =
    useThreeDS({ initiateCardData: initiateData, confirmCard })

  usePaymentConfirmResult({
    // @ts-ignore: OPERATION BLEED STOPPER
    topazData,
    isTopazError,
    isInstallmentError,
    isCustomAmountError,
    isInstallmentSuccess,
    isCustomAmountSuccess,
    isPayHardshipInstallmentSuccess,
    isPayHardshipInstallmentError,
    installmentError,
    // @ts-ignore: OPERATION BLEED STOPPER
    customAmountError,
    hardshipInstallmentError,
    t,
  })

  return {
    handleAccountCardPayment,
    handleOrderCardPayment,
    confirmCard,
    isFetching,
    isInstallmentError,
    installmentError,
    isTopazError,
    isCustomAmountError,
    // @ts-ignore: OPERATION BLEED STOPPER
    customAmountError,
    serverError,
    threeDs: {
      showThreeDSIframe,
      threeDSError,
      threeDSIframeURL,
      threeDSOnClose,
      setThreeDSError,
      showModalCloseButton,
    },
  }
}
