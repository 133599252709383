const locales = [
  {
    locale: 'en-AU',
    country: 'AU',
  },
  {
    locale: 'en-NZ',
    country: 'NZ',
  },
  {
    locale: 'en-US',
    country: 'US',
  },
  {
    locale: 'en-GB',
    country: 'GB',
  },
  {
    locale: 'en-CA',
    country: 'CA',
  },
  {
    locale: 'fr-CA',
    country: 'CA',
  },
  {
    locale: 'es-ES',
    country: 'ES',
  },
  {
    locale: 'fr-FR',
    country: 'FR',
  },
  {
    locale: 'it-IT',
    country: 'IT',
  },
]

export const mapLocales = (fromKey, fromvalue): string => {
  const toKey = fromKey === 'country' ? 'locale' : 'country'
  return (
    locales.filter((localeObj) => {
      return localeObj[fromKey] === fromvalue
    })[0][toKey] ?? ''
  )
}
