import React, { FunctionComponent, ReactNode, useContext } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Button, Dropdown, Heading, Modal, Span, Text } from '@afterpaytouch/core'
import { CloseAccountFlowContext, CloseAccountFlowSteps } from '../CloseAccountFlow/CloseAccountFlowContext'
import { NewTabLink } from '../../NewTabLink'

const I18N_NAMESPACE = ['common', 'account']

const HelpLink: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  return (
    <Span underline bold>
      <NewTabLink href={t('common:urls:reduceAmountUrl')}>{children}</NewTabLink>
    </Span>
  )
}

export const SelectCloseAccountReason: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)

  const selectCloseAccountReasons = {
    DO_NOT_WANT_USE_APT: t('account:closeAccount:selectCloseAccountReason:dropdown:0'),
    NEED_BREAK_FROM_APT: t('account:closeAccount:selectCloseAccountReason:dropdown:1'),
    APPLYING_FOR_NEW_MORTGAGE: t('account:closeAccount:selectCloseAccountReason:dropdown:2'),
    UNABLE_TO_SHOP_RIGHT_NOW: t('account:closeAccount:selectCloseAccountReason:dropdown:3'),
  }

  const { setStep, closeAccountReason, setCloseAccountReason } = useContext(CloseAccountFlowContext)

  const testNameSpace = 'select-close-account-reason'

  return (
    <>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading size='L'>{t('account:closeAccount:selectCloseAccountReason:header')}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Text>
          {t('account:closeAccount:selectCloseAccountReason:content.0')}
          <br />
          <br />
          {t('account:closeAccount:selectCloseAccountReason:content.1')}
          <br />
          <br />
          {t('account:closeAccount:selectCloseAccountReason:content.2')}
        </Text>
        <div className='flex flex-col content-around'>
          <div className='my-6'>
            <Dropdown
              size='Medium'
              label={t('account:closeAccount:selectCloseAccountReason:dropdown:label')}
              onSelect={(value) => setCloseAccountReason(value as keyof typeof selectCloseAccountReasons)}
              testNameSpace={testNameSpace}
            >
              {Object.keys(selectCloseAccountReasons).map((itemKey, index) => (
                <Dropdown.MenuItem key={index} value={itemKey}>
                  {selectCloseAccountReasons[itemKey]}
                </Dropdown.MenuItem>
              ))}
            </Dropdown>
          </div>
          <Button
            onClick={() => {
              setStep(CloseAccountFlowSteps.ConfirmCloseAccount)
            }}
            disabled={closeAccountReason == null}
            testNameSpace={testNameSpace}
          >
            {t('account:closeAccount:selectCloseAccountReason:continueButton')}
          </Button>
          {closeAccountReason === 'NEED_BREAK_FROM_APT' && (
            <Text>
              <br />
              <Trans
                i18nKey={'account:closeAccount:selectCloseAccountReason:reduceAmountInfo'}
                components={{
                  helpLink: <HelpLink />,
                }}
              />
            </Text>
          )}
        </div>
      </Modal.Content>
    </>
  )
}
