import React, { FunctionComponent, ReactElement } from 'react'
import { Heading, Button, Modal, Text } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'

const I18N_NAMESPACE = ['common']

export const ClearpayPartiallyMigratedRedirection: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const namespace = 'paynow-clearpay-redirection'

  const handleOnClick = (): void => window.location.replace(t('common:redirectToClearpayModal:link'))

  const renderContent = (): ReactElement => {
    return (
      <>
        <Modal.Content>
          <div>
            <Text size='M'>{t('common:redirectToClearpayModal:body')}</Text>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button.Primary padding='Fluid' testNameSpace={namespace} onClick={handleOnClick}>
            {t('common:redirectToClearpayModal:buttonLabel')}
          </Button.Primary>
        </Modal.Footer>
      </>
    )
  }

  return (
    <>
      <div className='text-center'>
        <Modal.Header divider={true}>
          <Heading testNameSpace={`${namespace}`} size='L'>
            {t('common:redirectToClearpayModal:header')}
          </Heading>
        </Modal.Header>
      </div>
      <Modal.Content>{renderContent()}</Modal.Content>
    </>
  )
}
