import React, { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Grid, Text } from '@afterpaytouch/core'
import { TermLink } from '../../TermLink'

const I18N_NAMESPACE = ['common', 'verifyIdentity']

export const Terms: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <Grid>
      <Text color='Gray40' size='XXS'>
        <Trans
          i18nKey='verifyIdentity:licence:terms'
          components={{
            terms: <TermLink key='verify-identity-licence-terms' href={t('common:urls:termsOfServiceUrl')} />,
            privacy: <TermLink key='verify-identity-licence-privacy' href={t('common:urls:privacyPolicyUrl')} />,
          }}
        />
      </Text>
    </Grid>
  )
}
