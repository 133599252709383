import { useTranslation } from 'next-i18next'
import { Heading, Text, Skeleton, Tag, Panel, useIsCashTheme } from '@afterpaytouch/core'
import { Money as IMoney } from '@afterpaytouch/portal-api'
import React, { FunctionComponent } from 'react'
import { Money } from '../..'
import Image from 'next/image'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { useDisplayIllustrations } from '../../../state/convergence/hooks'

const I18N_NAMESPACE = ['common', 'index']

interface PayInFourCardProps {
  consumer: {
    totalOwedForPBI?: IMoney
    availableToSpend?: IMoney
    numActiveOrdersNonPcl?: number
  }
  isLoading: boolean
}

export const PayInFourCardUS: FunctionComponent<PayInFourCardProps> = ({ consumer, isLoading }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isCashTheme = useIsCashTheme()
  const isDisplayIllustrations = useDisplayIllustrations()
  if (typeof consumer.availableToSpend === 'undefined' || typeof consumer.totalOwedForPBI === 'undefined') {
    return null
  }
  return (
    <div className='relative grid h-full w-full [&>*:first-child]:grid' data-testid='pay-in-four-card-us-container'>
      <Panel>
        <Panel.Content inset>
          <div className='min-w-100 py-4 pl-3.5 sm:pr-4 md:pr-3' data-testid='pay-in-four-card-us'>
            <div className='flex flex-row'>
              <div className='mr-2 flex min-w-fit items-center'>
                <Heading size='S'>{t('common:tagline')}</Heading>
              </div>
              {typeof consumer.numActiveOrdersNonPcl !== 'undefined' && consumer.numActiveOrdersNonPcl !== 0 ? (
                <div className='flex min-w-fit items-center'>
                  <Tag.Mint testNameSpace='pay-in-four-card-us'>
                    {`${t('index:welcome:existingUser:payInFour:activeOrders', { numActive: consumer.numActiveOrdersNonPcl })}`}
                  </Tag.Mint>
                </div>
              ) : null}
            </div>
            <div className='flex flex-row items-center justify-between'>
              <div>
                <MaskedElement>
                  <div className='mt-2 flex min-w-fit flex-row items-center'>
                    <Money value={consumer.totalOwedForPBI} testNameSpace='pay-in-four-card-us' bold size='XL' />
                  </div>
                </MaskedElement>
                <div className='mt-1 flex flex-row'>
                  <Text color='Gray50' size='XXS'>{`${t('index:welcome:existingUser:payInFour:totalYouOwe')}`}</Text>
                </div>
              </div>
              {isDisplayIllustrations && (
                <div className='h-14 w-14 flex-shrink-0 justify-center rounded-md text-center'>
                  <div className='flex h-full w-full min-w-fit items-center justify-center'>
                    <Image
                      width={56}
                      height={56}
                      src={`/images/pay-in-4.svg`}
                      alt={t('common:tagline')}
                      data-testid='pay-in-four-car-us-image'
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Panel.Content>
      </Panel>
      {isLoading && <Skeleton kind={isCashTheme ? 'Default' : 'Primary'} />}
    </div>
  )
}
