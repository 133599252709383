import Script from 'next/script'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { app } from '../../env'

type callBackFn = () => void
declare global {
  interface Window {
    zE: (widget: string, action: string, callBack?: string | callBackFn) => {}
    zESettings: {
      webWidget: {
        chat: {
          suppress: boolean
        }
        contactForm: {
          suppress: boolean
        }
        helpCenter: {
          suppress: boolean
        }
        talk: {
          suppress: boolean
        }
        answerBot: {
          suppress: boolean
        }
        color: {
          theme: string
          resultLists: string
        }
      }
    }
  }
}

// @ts-ignore: OPERATION BLEED STOPPER
export const ZenDesk: FunctionComponent = () => {
  const { ZENDESK_SRC } = app
  const [isSettingsSet, setSettingsSet] = useState<boolean>(false)

  useEffect(() => {
    window.zESettings = {
      webWidget: {
        chat: {
          suppress: true,
        },
        contactForm: {
          suppress: true,
        },
        helpCenter: {
          suppress: false,
        },
        talk: {
          suppress: true,
        },
        answerBot: {
          suppress: true,
        },
        color: {
          theme: '#B2FCE4',
          resultLists: '#000000',
        },
      },
    }
    setSettingsSet(true)
  }, [isSettingsSet])

  return (
    isSettingsSet && (
      <>
        <Script
          src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_SRC}`}
          strategy='lazyOnload'
          onLoad={() => {
            window.zE('webWidget', 'hide')
          }}
          id='ze-snippet'
        />
      </>
    )
  )
}
