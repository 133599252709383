import React, { createContext, Dispatch, FunctionComponent, SetStateAction, useState, ReactNode } from 'react'
import { GetCloseCreditFacilityEligibilityResponse, SelectClosePayMonthlyReasonProps } from '@afterpaytouch/portal-api/consumer/creditFacility/types'

export const noop = (): void => {}

export const CloseCreditFacilityFlowSteps = {
  SelectCloseCreditFacilityReason: 'SelectCloseCreditFacilityReason',
  ConfirmCloseCreditFacility: 'ConfirmCloseCreditFacility',
  IneligibleCloseCreditFacility: 'IneligibleCloseCreditFacility',
} as const

type CloseCreditFacilityFlowStepsType = typeof CloseCreditFacilityFlowSteps
type CloseCreditFacilityStepsKeys = keyof CloseCreditFacilityFlowStepsType
export type CloseCreditFacilityStepsProps = CloseCreditFacilityFlowStepsType[CloseCreditFacilityStepsKeys]

export interface CloseCreditFacilityContextProps {
  openDate: string
  show: boolean
  step: CloseCreditFacilityStepsProps
  setStep: Dispatch<SetStateAction<CloseCreditFacilityStepsProps>>
  resetState: (data: GetCloseCreditFacilityEligibilityResponse) => void
  closeModal: () => void
  closeCreditFacilityReason?: SelectClosePayMonthlyReasonProps
  setCloseCreditFacilityReason: Dispatch<SetStateAction<SelectClosePayMonthlyReasonProps>>
}

const defaultStep = CloseCreditFacilityFlowSteps.SelectCloseCreditFacilityReason
export const closeCreditFacilityFlowContextDefaults: CloseCreditFacilityContextProps = {
  show: false,
  step: defaultStep,
  setStep: noop,
  resetState: noop,
  closeModal: noop,
  closeCreditFacilityReason: undefined,
  setCloseCreditFacilityReason: noop,
  openDate: '',
}

export const CloseCreditFacilityFlowContext = createContext<CloseCreditFacilityContextProps>(closeCreditFacilityFlowContextDefaults)

interface CloseCreditFacilityProviderProps {
  show?: boolean
  closeModal: () => void
  initialStep?: CloseCreditFacilityStepsProps
  children: ReactNode
  openDate: string
}

export const CloseCreditFacilityFlowModalProvider: FunctionComponent<CloseCreditFacilityProviderProps> = ({ show = false, closeModal, children, openDate }) => {
  const [step, setStep] = useState<CloseCreditFacilityStepsProps>(defaultStep)
  const [closeCreditFacilityReason, setCloseCreditFacilityReason] = useState<SelectClosePayMonthlyReasonProps>()
  const resetState = (data: GetCloseCreditFacilityEligibilityResponse): void => {
    if (data.eligible) {
      setStep(defaultStep)
    } else {
      setStep(CloseCreditFacilityFlowSteps.IneligibleCloseCreditFacility)
    }
    setCloseCreditFacilityReason(undefined)
  }
  const value = {
    show,
    step,
    setStep,
    resetState,
    closeModal,
    closeCreditFacilityReason,
    setCloseCreditFacilityReason,
    openDate,
  }

  return <CloseCreditFacilityFlowContext.Provider value={value}>{children}</CloseCreditFacilityFlowContext.Provider>
}
