import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApplePayError, ApplePayStatus } from '@afterpay/types'
import { OrderChannel } from '../../model'

export interface ApplePayState {
  status: ApplePayStatus
  error?: ApplePayError
  transactionData: {
    transactionId: string | null
    orderChannel: OrderChannel | null
    traceId: string | null
  }
  isApplePayAvailable?: boolean
  isEnabledForMPANFlow: boolean
}

export const applePayInitialState: ApplePayState = {
  status: ApplePayStatus.Initial,
  transactionData: {
    transactionId: null,
    orderChannel: null,
    traceId: null,
  },
  isApplePayAvailable: undefined,
  isEnabledForMPANFlow: false,
}

const applePaySlice = createSlice({
  name: 'applepay',
  initialState: applePayInitialState,
  reducers: {
    setApplePayStatus(state: ApplePayState, action: PayloadAction<ApplePayStatus>) {
      state.status = action.payload
    },
    setApplePayFailure(state: ApplePayState, action: PayloadAction<ApplePayError>) {
      state.status = ApplePayStatus.Error
      state.error = action.payload
    },
    resetApplePayError(state: ApplePayState) {
      state.status = ApplePayStatus.Initial
      state.error = undefined
    },
    setApplePayTransactionId(state: ApplePayState, action: PayloadAction<string>) {
      state.transactionData.transactionId = action.payload
    },
    setApplePayOrderChannel(state: ApplePayState, action: PayloadAction<OrderChannel>) {
      state.transactionData.orderChannel = action.payload
    },
    setApplePayTraceId(state: ApplePayState, action: PayloadAction<string>) {
      state.transactionData.traceId = action.payload
    },
    setIsApplePayAvailable(state: ApplePayState, action: PayloadAction<boolean>) {
      state.isApplePayAvailable = action.payload
    },
    setIsEnabledForMPANFlow(state: ApplePayState, action: PayloadAction<boolean>) {
      state.isEnabledForMPANFlow = action.payload
    },
  },
})

export const {
  resetApplePayError,
  setApplePayStatus,
  setApplePayFailure,
  setApplePayOrderChannel,
  setApplePayTraceId,
  setApplePayTransactionId,
  setIsApplePayAvailable,
  setIsEnabledForMPANFlow,
} = applePaySlice.actions
export default applePaySlice.reducer
