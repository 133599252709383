import { PaymentMethodType, PreferredPaymentMethod } from '@afterpay/types'
import { SupportedLocale, IdentityCheckStatus } from '@afterpaytouch/portal-api'

export const isIdentityValid = (identity: IdentityCheckStatus | undefined): boolean =>
  identity === IdentityCheckStatus.VERIFIED || identity === IdentityCheckStatus.NOT_REQUIRED

export const hasPreferredPaymentMethod = (preferredPaymentMethod: PreferredPaymentMethod | PaymentMethodType | null | undefined): boolean =>
  preferredPaymentMethod !== null

// AU, NZ and UK require hard ID checks, also needs to chek feature flags
export const isHardIdVerificationRequiredLocale = (locale: SupportedLocale | undefined): boolean => {
  return locale === 'en-NZ' || locale === 'en-GB' || locale === 'en-AU'
}
