import { marketingPreferencesService } from '@afterpaytouch/portal-api/consumer/subscriptions/marketing'
import { MarketingPreferencesResponse } from '@afterpaytouch/portal-api/consumer/subscriptions/marketing/types'
import { creditCheckService } from '@afterpaytouch/portal-api/consumer/creditCheck'
import { CreditCheckConsentStatusResponse } from '@afterpaytouch/portal-api/consumer/creditCheck/types'

export const {
  useGetMarketingPreferencesQuery,
  useLazyGetMarketingPreferencesQuery,
  useSubscribeToMarketingEmailsMutation,
  useUnsubscribeFromMarketingEmailsMutation,
  endpoints: { getMarketingPreferences },
} = marketingPreferencesService

export const useMarketingPreferencesData = (): MarketingPreferencesResponse => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getMarketingPreferences.useQueryState()?.data
}

export const {
  useGetConsentStatusQuery,
  useUpdateConsentStatusMutation,
  endpoints: { getConsentStatus },
} = creditCheckService

export const useCreditCheckConsentStatusData = (): CreditCheckConsentStatusResponse => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getConsentStatus.useQueryState()?.data
}
