import React, { FunctionComponent, useContext } from 'react'
import { Modal } from '@afterpaytouch/core'
import { AddCardForm } from '../../AccountTabs/PaymentMethods/AddCardForm'
import { AchFlowModalContext } from '../AchFlowModalContext'
import { AchFlowNavigation } from '../AchFlowNavigation'

export const AddCard: FunctionComponent = () => {
  const { closeModal } = useContext(AchFlowModalContext)

  return (
    <>
      <Modal.Header divider>
        <AchFlowNavigation />
      </Modal.Header>
      <Modal.Content>
        <AddCardForm callback={closeModal} displayStorePaymentToken={false} />
      </Modal.Content>
    </>
  )
}
