import { useIdentityVerifyMutation } from '../state'
import { IdentityDocument } from '../model'
import { IdentityCheckStatus, Channel, TransformedIdentityVerificationDocument } from '@afterpaytouch/portal-api/identity/types'
import { Route, verifyDocumentRoute } from '../config/router'
import { Country, ErrorCode } from '@afterpaytouch/portal-api'
import { useFlag } from '@afterpaytouch/feature'

export const useIdCheck: (
  validDocumentsForCountry: IdentityDocument[]
) => (identityCheckStatus?: IdentityCheckStatus, verifiedIdentityDocuments?: TransformedIdentityVerificationDocument[]) => Promise<Route> = (
  validDocumentsForCountry
) => {
  const [identityVerify] = useIdentityVerifyMutation()

  return async (identityCheckStatus, verifiedIdentityDocuments) => {
    switch (identityCheckStatus) {
      case IdentityCheckStatus.VERIFIED:
      case IdentityCheckStatus.NOT_REQUIRED:
        return Route.HOME
      case IdentityCheckStatus.PARTIAL_VERIFIED: {
        try {
          const { identityCheckStatus: updatedIdentityCheckStatus } = await identityVerify({
            channel: Channel.PORTAL,
          }).unwrap()

          const acceptableStatus = [IdentityCheckStatus.VERIFIED, IdentityCheckStatus.NOT_REQUIRED]
          if (acceptableStatus.includes(updatedIdentityCheckStatus)) {
            return Route.HOME
          }

          const nextEligibleDocument = validDocumentsForCountry.filter((doc) => !(verifiedIdentityDocuments?.includes(doc as any) ?? false))

          if (updatedIdentityCheckStatus === IdentityCheckStatus.FAILED || nextEligibleDocument.length === 0) {
            return Route.VERIFY_ERROR
          } else {
            return verifyDocumentRoute(nextEligibleDocument[0], true)
          }
        } catch (e) {
          if ([ErrorCode.InvalidAddress].includes(e?.data?.errorCode)) {
            return Route.VERIFY_ADDRESS
          }
          return Route.VERIFY_ERROR
        }
      }
      default:
        return Route.VERIFY_ERROR
    }
  }
}

const DEFAULT_DOCUMENTS: IdentityDocument[] = ['licence', 'passport']
const DEFAULT_ALLOWED_DOCUMENTS = {
  [Country.AU]: ['licence', 'medicare', 'passport'],
}

export const useAllowedDocumentsByCountry = (country: Country): IdentityDocument[] => {
  const allowedDocuments = useFlag('allowed-documents-by-country', DEFAULT_ALLOWED_DOCUMENTS)

  return allowedDocuments[country] || DEFAULT_ALLOWED_DOCUMENTS[country] || DEFAULT_DOCUMENTS
}
