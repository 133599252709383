import { Link, Text } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'
import { HelpButton } from '../HelpButton'
import { useRouter } from 'next/router'

import styles from './style.module.scss'
import { SupportedLocale } from '../../model'
import clsx from 'clsx'

const I18N_NAMESPACE = ['common']

interface FooterProps {
  isHelpDisplayed?: boolean
  center?: boolean
}

export const Footer: FunctionComponent<FooterProps> = ({ isHelpDisplayed = true, center = false }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const router = useRouter()
  const isUK = router.locale === ('en-GB' as SupportedLocale)

  const classNames = clsx(styles.footer, center && styles.center)

  return (
    <div className={classNames}>
      <Text size='XS'>{t('common:footerCopyright', { year: new Date().getFullYear() })}</Text>
      <Link size='XS' kind='Underline' href={t('common:urls:termsOfServiceUrl')} target='_blank' testNameSpace='footer-terms'>
        {t('common:footerTerms')}
      </Link>
      <Link size='XS' kind='Underline' href={t('common:urls:privacyPolicyUrl')} target='_blank' testNameSpace='footer-privacy-policy'>
        {t('common:footerPrivacy')}
      </Link>
      {isUK && (
        <Link size='XS' kind='Underline' href={t('common:urls:cookiesUrl')} target='_blank' testNameSpace='footer-cookies'>
          {t('common:footerCookies')}
        </Link>
      )}
      {isHelpDisplayed && <HelpButton />}
    </div>
  )
}
