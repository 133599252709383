import { PaymentRoutingResponse, paymentRoutingService, Routing } from '@afterpaytouch/portal-api/consumer/paymentRouting'
import { createSelector } from '@reduxjs/toolkit'
import { ConsumerAppSelector } from '..'

export const {
  useGetPaymentRoutingQuery,
  endpoints: { getPaymentRouting },
} = paymentRoutingService

export const paymentRoutingSelector = getPaymentRouting.select()

// @ts-ignore: OPERATION BLEED STOPPER
export const paymentRoutingDataSelector: ConsumerAppSelector<PaymentRoutingResponse> = createSelector(paymentRoutingSelector, (state) => state.data)

export const routingSelector: ConsumerAppSelector<Routing> = createSelector(paymentRoutingDataSelector, (data) => data.routing)
