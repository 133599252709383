import { PaymentStatus } from '@afterpaytouch/portal-api'
import { hardshipService } from '@afterpaytouch/portal-api/consumer/hardship'
import { PaymentArrangementSchedule, PaymentArrangementScheduleResponse } from '@afterpaytouch/portal-api/consumer/hardship/types'

export const {
  useGetPaymentArrangementScheduleQuery,
  usePayHardshipInstallmentMutation,
  endpoints: { getPaymentArrangementSchedule },
} = hardshipService

export const useGetPaymentArrangements = (): PaymentArrangementScheduleResponse => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getPaymentArrangementSchedule.useQueryState()?.data
}

export const useGetTotalPayableHardshipRepayments = (): number => {
  const repaymentSchedule = getPaymentArrangementSchedule.useQueryState()?.data
  // @ts-ignore: OPERATION BLEED STOPPER
  return repaymentSchedule?.paymentSchedules?.filter((payment) => payment.paymentStatus !== PaymentStatus.PAID).length
}

export const useGetPayableHardshipRepayments = (): PaymentArrangementSchedule[] => {
  const repaymentSchedule = getPaymentArrangementSchedule.useQueryState()?.data
  // @ts-ignore: OPERATION BLEED STOPPER
  return repaymentSchedule?.paymentSchedules?.filter((payment) => payment.paymentStatus !== PaymentStatus.PAID)
}
