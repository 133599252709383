import React, { createContext, Dispatch, FunctionComponent, ReactNode, SetStateAction, useContext, useState } from 'react'

interface PreferredDayModalContextResult {
  success: boolean
  error: boolean
  message?: string
}
export interface PreferredDayModalContextProps {
  step: PreferredDayModalStepsProps
  setStep: Dispatch<SetStateAction<PreferredDayModalStepsProps>>
  selectedPreferredDay: string
  setSelectedPreferredDay: Dispatch<SetStateAction<string>>
  closeModal: () => void
  show: boolean
  resetState: () => void
  examplePaymentSchedule: string[]
  setExamplePaymentSchedule: Dispatch<SetStateAction<string[]>>
  result: PreferredDayModalContextResult
  setResult: Dispatch<SetStateAction<PreferredDayModalContextResult>>
}

export const PreferredDaySteps = {
  SelectPreferredDay: 'SelectPreferredDay',
  ConfirmNewSchedule: 'ConfirmNewSchedule',
  PreferredPaydayResult: 'PreferredPaydayResult',
} as const

const defaultStep = PreferredDaySteps.SelectPreferredDay

type PreferredDayStepsType = typeof PreferredDaySteps
type PreferredDayStepsKeys = keyof PreferredDayStepsType
export type PreferredDayStepsProps = PreferredDayStepsType[PreferredDayStepsKeys]

const noop = (): void => {}

export const preferredDayModalContextDefults: PreferredDayModalContextProps = {
  step: defaultStep,
  setStep: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  selectedPreferredDay: null,
  setSelectedPreferredDay: noop,
  closeModal: noop,
  show: false,
  resetState: noop,
  examplePaymentSchedule: [],
  setExamplePaymentSchedule: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  result: null,
  setResult: noop,
}

export const PreferredDayModalContext = createContext<PreferredDayModalContextProps>(preferredDayModalContextDefults)

export const useSetPreferredDayModalContext = (): PreferredDayModalContextProps => {
  return { ...useContext(PreferredDayModalContext) }
}

type PreferredDayModalStepsType = typeof PreferredDaySteps
type PreferredDayModalStepsKeys = keyof PreferredDayModalStepsType
export type PreferredDayModalStepsProps = PreferredDayModalStepsType[PreferredDayModalStepsKeys]

interface PreferredDayModalProviderProps {
  initialStep?: PreferredDayModalStepsProps
  children: ReactNode
  show: boolean
  closeModal: () => void
}

export const PreferredDayModalProvider: FunctionComponent<PreferredDayModalProviderProps> = ({ children, closeModal, show }) => {
  const [step, setStep] = useState<PreferredDayModalStepsProps>(defaultStep)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [selectedPreferredDay, setSelectedPreferredDay] = useState<string>(null)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [examplePaymentSchedule, setExamplePaymentSchedule] = useState<string[]>(null)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [result, setResult] = useState<PreferredDayModalContextResult>(null)
  const resetState = (): void => {
    setStep(defaultStep)
    // @ts-ignore: OPERATION BLEED STOPPER
    setSelectedPreferredDay(null)
    // @ts-ignore: OPERATION BLEED STOPPER
    setResult(null)
  }

  const value = {
    step,
    setStep,
    selectedPreferredDay,
    setSelectedPreferredDay,
    closeModal,
    resetState,
    show,
    examplePaymentSchedule,
    setExamplePaymentSchedule,
    result,
    setResult,
  }
  return <PreferredDayModalContext.Provider value={value}>{children}</PreferredDayModalContext.Provider>
}
