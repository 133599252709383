import { ConsumerAddress, SupportedLocale } from '@afterpaytouch/portal-api'
import { intlFormat, parseISO } from 'date-fns'

export const getConsumerName = (givenNames?: string | null, surname?: string | null): string => {
  const sanitizedGivenNames = givenNames ?? ''
  const sanitizedSurname = surname ?? ''
  const bothNamesExist = sanitizedGivenNames.length > 0 && sanitizedSurname.length > 0

  return `${sanitizedGivenNames}${bothNamesExist ? ' ' : ''}${sanitizedSurname}`
}

export const convertConsumerAddress = (address?: ConsumerAddress): string => {
  if (typeof address === 'undefined') {
    return ''
  }
  return `${address?.address1} ${address?.address2 ?? ''}, ${address?.suburb}, ${address?.state}, ${address?.postcode}`
}

export const convertConsumerDateOfBirth = ({ dateOfBirth, locale = 'en-AU' }: { dateOfBirth?: string; locale: SupportedLocale }): string => {
  if (typeof dateOfBirth === 'undefined') {
    return ''
  }
  const date = parseISO(dateOfBirth)

  return intlFormat(
    date,
    {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit',
    },
    { locale }
  )
}
