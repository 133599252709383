import React, { FunctionComponent, useEffect, useState } from 'react'
import Image from 'next/image'
import { Heading, Panel, Text, useIsBreakpointMediumAndUp } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { useConsumerSizes } from '../../utils/responsive'
import QRCode from 'qrcode'
import { useConsumerSelector } from '../../state'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'

const I18N_NAMESPACE = ['common', 'index']
const CHANNEL_ID = 'NEW_PORTAL'

export const MobileAppDownloadPanel: FunctionComponent = () => {
  const {
    t,
    i18n: { language: currentLocale },
  } = useTranslation(I18N_NAMESPACE)
  const { subheadingSize, textSize } = useConsumerSizes()
  const isBpMediumUp = useIsBreakpointMediumAndUp()
  const amplitude = useAmplitudeWithEnduringEventProperties()
  const imageLayout = isBpMediumUp ? 'fixed' : 'intrinsic'
  const { uuid } = useConsumerSelector()
  const defaultQRCodeImg = `/images/${currentLocale}/img-default-mobile-link.svg`
  const [qrCodeLink, setQRCodeLink] = useState(defaultQRCodeImg)

  useEffect(() => {
    ;(async () => {
      try {
        const qrCodeImg = await QRCode.toDataURL(`${t('common:urls:mobilePageBranchLink')}?uuid=${uuid}&channel_id=${CHANNEL_ID}`, {
          margin: 2,
          scale: 50,
        })
        amplitude.logEvent(TrackingEvent.GENERATE_MOBILE_APP_QR_CODE_SUCCESS)
        setQRCodeLink(qrCodeImg)
      } catch (error) {
        amplitude.logEvent(TrackingEvent.GENERATE_MOBILE_APP_QR_CODE_ERROR)

        setQRCodeLink(defaultQRCodeImg)
      }
    })()
  }, [uuid, defaultQRCodeImg])

  // eslint-disable-next-line react/no-unstable-nested-components
  const APMobileApp = (): JSX.Element => {
    const apMobileAppImgSrc = `/images/${currentLocale}/img-mobile-screen.svg`

    return (
      <div className='relative'>
        <Image
          src={apMobileAppImgSrc}
          width={345}
          height={192}
          alt='AP Mobile App'
          layout={imageLayout}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
        <div className='absolute left-0 top-[23%]'>
          <Image
            src={qrCodeLink}
            alt='AP Mobile App QR Code'
            layout={imageLayout}
            width={125}
            height={125}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div className='mb-24'>
      <Panel>
        <div className='w-full'>
          <Panel.Content>
            <div className='flex flex-col justify-between lg:flex-row'>
              <div className='flex grow basis-1/2 flex-col'>
                <div className='mt-6'>
                  <Heading size={subheadingSize}>{t('index:mobileAppDownloadPanel:heading')}</Heading>
                </div>
                <div className='mb-3 mt-3'>
                  <Text size={textSize}>{t('index:mobileAppDownloadPanel:text')}</Text>
                </div>
                <Text bold={true} size={textSize}>
                  {t('index:mobileAppDownloadPanel:downloadForFree')}
                </Text>
              </div>
              <div className='flex grow basis-1/2 justify-center sm:mt-3'>
                <APMobileApp />
              </div>
            </div>
          </Panel.Content>
        </div>
      </Panel>
    </div>
  )
}
