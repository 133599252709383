import { ConsumerCheckoutAddress, Country } from '@afterpay/types'
import { addressHasNoPO, Button, Heading, Icon, InputAddress, InputAddressData, InputAddressValidationRule, Modal, Text, useToast } from '@afterpaytouch/core'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { ConsumerAddress, SupportedLocale } from '@afterpaytouch/portal-api'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { FunctionComponent, ReactNode, useState } from 'react'
import { app } from '../../env'
import { useFlag } from '../../hooks'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { useConsumerContactAddress } from '../../state'
import { useUpdateAccountMutation } from '../../state/account'
import { mapLocales } from '../../utils/mapLocales'
import { isValidAddress } from '../../utils/profile-validation'
import { isCallable } from '@afterpay/utils'

const I18N_NAMESPACE = ['common', 'account']

interface EditAddressModalContentProps {
  country?: string
  closeModal: () => void
  handleBack?: () => void
}

export const EditAddressModalContent: FunctionComponent<EditAddressModalContentProps> = ({ country, closeModal, handleBack }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const contactAddress = useConsumerContactAddress()
  const router = useRouter()
  const locale = router?.locale as SupportedLocale
  const addressCountry = typeof country === 'undefined' ? mapLocales('locale', locale) : country
  // @ts-ignore: OPERATION BLEED STOPPER
  const [newContactAddress, setNewContactAddress] = useState<ConsumerAddress>(contactAddress)
  const [isNewContactAddressValid, setIsNewContactAddressValid] = useState<boolean>(true)
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)
  const [isManualAddress, setIsManualAddress] = useState<boolean>(false)
  const setToast = useToast()
  const showBackButton = isCallable(handleBack)
  const testNameSpace = 'edit-address-modal'
  const isAutocompleteInputAddressEnabled = useFlag('consumer-portal-autocomplete-input-address-on-edit-profile-enabled', false)

  const onAddressChange = (address: InputAddressData, manuallyTouched?: boolean, validity?: boolean): void => {
    if (!validity) {
      setIsNewContactAddressValid(false)
    }

    if (!validateNewAddress(address)) {
      setIsNewContactAddressValid(false)
      return
    }

    setIsNewContactAddressValid(true)
    // @ts-ignore: OPERATION BLEED STOPPER
    setNewContactAddress({
      ...contactAddress,
      address1: address.address1,
      address2: address.address2,
      postcode: address.postcode,
      state: address.state,
      suburb: address.suburb,
    })
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  const onAddressBlur = (validity: boolean): void => !validity && setIsNewContactAddressValid(false)

  const [updateAccount, { isError: isUpdateAccountError, isLoading: isUpdateAccountLoading }] = useUpdateAccountMutation()

  const onButtonClick = async (): Promise<void> => {
    if (!isNewContactAddressValid) {
      return
    }

    try {
      setIsButtonLoading(true)
      await updateAccount({ body: { contactAddress: newContactAddress } }).unwrap()
      logEvent(TrackingEvent.EDIT_ADDRESS_SUCCESS)
      setToast({
        message: t('account:edit:address:toast:success:message'),
        kind: 'Success',
        manualDismiss: true,
        toastTime: 2000,
        testNameSpace,
      })
      setIsButtonLoading(false)
      closeModal()
    } catch (err) {
      logEvent(TrackingEvent.EDIT_ADDRESS_ERROR)
      setIsButtonLoading(false)
    }
  }

  const handleCustomValidation = (parsedData: InputAddressData): string | null => {
    const hasPOErrors = !addressHasNoPO(parsedData)

    return hasPOErrors ? t('account:edit:address:error:noPoBox') : null
  }

  const handleManualModeChange = (isManual: boolean): void => setIsManualAddress(isManual)

  const validateNewAddress = (address: InputAddressData): boolean => {
    const { address1, address2, postcode, state, suburb, area2, countryCode } = address

    const consumerCheckoutAddress: ConsumerCheckoutAddress = {
      name: address1,
      address1,
      address2,
      area2,
      suburb,
      postcode,
      state,
      countryCode: countryCode as Country,
    }

    return isValidAddress(consumerCheckoutAddress)
  }

  const renderManualAddressError = (): ReactNode => (
    <Text color='Fire' size='XS'>
      {t('signUp:error.blankAddress')}
    </Text>
  )

  const isButtonDisabled: boolean = isUpdateAccountLoading || !isNewContactAddressValid
  const isInputAddressDisabled: boolean = isUpdateAccountLoading
  const isInputAddressError: boolean = isUpdateAccountError || !isNewContactAddressValid
  const isManualAddressError: boolean = isManualAddress && !isNewContactAddressValid
  // @ts-ignore: OPERATION BLEED STOPPER
  const shouldEnableAutocompleteInputAddress = (): boolean => {
    if (!isAutocompleteInputAddressEnabled) {
      return true
    }
    if (isAutocompleteInputAddressEnabled && !isManualAddress) {
      return false
    }
  }
  const isAutocompleteAddressEnabled: boolean = shouldEnableAutocompleteInputAddress()

  const inputAddressClass = clsx(!isAutocompleteAddressEnabled && !isManualAddress && 'h-40')

  return (
    <>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading testNameSpace={testNameSpace} size='L'>
            {t('account:edit:address:header:heading')}
          </Heading>
        </div>
        {showBackButton && (
          <div className='absolute left-6 top-4'>
            <Button.Link size='Small' onClick={handleBack} aria-label={t('common:back')}>
              <Icon.CaretLeft size='Small' />
            </Button.Link>
          </div>
        )}
      </Modal.Header>
      <Modal.Content>
        <MaskedElement>
          <div className={inputAddressClass}>
            <InputAddress
              defaultExpanded={isAutocompleteAddressEnabled}
              address1Label={t('account:edit:address:content:inputAddress:address1Label')}
              address2Label={t('account:edit:address:content:inputAddress:address2Label')}
              apiKey={app.GOOGLEMAPS_API_KEY}
              componentRestrictions={{ country: addressCountry }}
              error={isInputAddressError}
              // @ts-ignore: OPERATION BLEED STOPPER
              initialAddress={contactAddress}
              label={t('signUp:address')}
              errorMessage={t('signUp:error.blankAddress')}
              onCustomValidation={handleCustomValidation}
              // @ts-ignore: OPERATION BLEED STOPPER
              manualAddressLabel={!isAutocompleteAddressEnabled && t('signUp:findAddressLabel')}
              onAddressChange={onAddressChange}
              // @ts-ignore: OPERATION BLEED STOPPER
              onManualModeChange={!isAutocompleteAddressEnabled && handleManualModeChange}
              onAddressBlur={onAddressBlur}
              disabled={isInputAddressDisabled}
              postcodeLabel={t('account:edit:address:content:inputAddress:postcodeLabel')}
              // @ts-ignore: OPERATION BLEED STOPPER
              searchAddressLabel={!isAutocompleteAddressEnabled && t('signUp:searchAddressLabel')}
              stateLabel={t('account:edit:address:content:inputAddress:stateLabel')}
              suburbLabel={t('account:edit:address:content:inputAddress:suburbLabel')}
              validateRule={InputAddressValidationRule.ByAddress}
            />
          </div>
          {isManualAddressError && renderManualAddressError()}
        </MaskedElement>
      </Modal.Content>
      <Modal.Footer>
        <Button padding='Fluid' disabled={isButtonDisabled} loading={isButtonLoading} onClick={onButtonClick} testNameSpace={testNameSpace}>
          <Text bold>{t('account:edit:address:content:cta')}</Text>
        </Button>
      </Modal.Footer>
    </>
  )
}
