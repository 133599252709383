import { createSelector } from '@reduxjs/toolkit'

import { useAppSelector, CardScanState, ConsumerAppSelector } from '../'
import { CardScanCheckpoint, CardScanStatus } from '../../model'

// /* Selectors */

export const cardScanStateSelector: ConsumerAppSelector<CardScanState> = (state) => state.cardScan

// @ts-ignore: OPERATION BLEED STOPPER
export const cardScanCardNumberSelector: ConsumerAppSelector<string> = createSelector(cardScanStateSelector, (state) => state.cardNumber)

// @ts-ignore: OPERATION BLEED STOPPER
export const cardScanCardExpiryDateSelector: ConsumerAppSelector<string> = createSelector(cardScanStateSelector, (state) => state.expiryDate)

// @ts-ignore: OPERATION BLEED STOPPER
export const cardScanCheckpointSelector: ConsumerAppSelector<CardScanCheckpoint> = createSelector(cardScanStateSelector, (state) => state.checkpoint)

// @ts-ignore: OPERATION BLEED STOPPER
export const cardScanStatusSelector: ConsumerAppSelector<CardScanStatus> = createSelector(cardScanStateSelector, (state) => state.status)

// @ts-ignore: OPERATION BLEED STOPPER
export const cardScanTraceIdSelector: ConsumerAppSelector<string> = createSelector(cardScanStateSelector, (state) => state.traceId)

// @ts-ignore: OPERATION BLEED STOPPER
export const cardScanRequiredSelector: ConsumerAppSelector<boolean> = createSelector(cardScanStateSelector, (state) => state.cardScanRequired)

// /* React Hooks */

export const useCardScanCardNumber = (): string => useAppSelector(cardScanCardNumberSelector)

export const useCardScanExpiryDate = (): string => useAppSelector(cardScanCardExpiryDateSelector)

export const useCardScanCheckpoint = (): CardScanCheckpoint => useAppSelector(cardScanCheckpointSelector)

export const useCardScanStatus = (): CardScanStatus => useAppSelector(cardScanStatusSelector)

export const useCardScanTraceId = (): string => useAppSelector(cardScanTraceIdSelector)

export const useCardScanRequired = (): boolean => useAppSelector(cardScanRequiredSelector)

export const useIsReadyToScan = (): boolean => useCardScanStatus() === CardScanStatus.Ready
