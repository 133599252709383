import { identityService } from '@afterpaytouch/portal-api/identity'
import { useConsumerSelector } from '..'
import { COUNTRY_FIELDS_HIDDEN } from '../../component/IDFlow/DriversLicence/constants'

export const useCountryStateIsShown = (): boolean => {
  const { countryCode } = useConsumerSelector()

  return !COUNTRY_FIELDS_HIDDEN[countryCode]?.includes('state') ?? true // TODO: FIX TYPE
}

export const {
  useIdentityVerifyMutation,
  useIdentityPassportMutation,
  useIdentityPassportCountriesQuery,
  useIdentityMedicareMutation,
  useIdentityLicenseMutation,
} = identityService
