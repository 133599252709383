import React, { FC, useMemo } from 'react'
import { useDisputeTranslation } from '../hooks'
import { Button, Heading, Modal, Text } from '@afterpaytouch/core'
import { useConsumerSizes } from '../../../utils/responsive'
import { useDisputeCreationModalContext } from '../DisputeCreationModalContext'

export const DisagreeWithDeliveryWarn: FC = () => {
  const { smallTextSize } = useConsumerSizes()
  const t = useDisputeTranslation()
  const { closeModal } = useDisputeCreationModalContext()

  const [heading, content, confirm] = useMemo<string[]>(() => {
    return [t('contactMerchantInfo.unable.title'), t('contactMerchantInfo.unable.content'), t('contactMerchantInfo.unable.cta')]
  }, [t])

  return (
    <>
      <Modal.Header divider={true}>
        <div className='text-center'>
          <Heading size='L'>{heading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className='whitespace-pre-line'>
          <Text size={smallTextSize} renderAs='span'>
            {content}
          </Text>
        </div>
        <div className='mt-4'>
          <Button padding='Fluid' size='Large' onClick={closeModal}>
            {confirm}
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}
