import { Country, Currencies, Currency, Money, SupportedLocale, SupportedLocales } from '@afterpaytouch/portal-api'

export const isMoney = (value?: any): value is Money => {
  return Boolean(value?.amount ?? value?.currency ?? value?.value)
}

export const isValidMoney = (money: Money): boolean => {
  return Number(money?.amount) > 0
}

export const sumMoney = (...moneys: Money[]): Money => {
  return moneys.reduce((prev, curr) => ({
    ...prev,
    amount: (parseFloat(prev?.amount ?? '0') + parseFloat(curr?.amount ?? '0')).toString(),
  }))
}

export const mapLocaleToCurrency = (locale: SupportedLocale): Currency | undefined => {
  switch (locale) {
    case SupportedLocales.EN_AU:
      return Currencies.AUD
    case SupportedLocales.EN_US:
      return Currencies.USD
    case SupportedLocales.EN_NZ:
      return Currencies.NZD
    case SupportedLocales.EN_GB:
      return Currencies.GBP
    case SupportedLocales.EN_CA:
    case SupportedLocales.FR_CA:
      return Currencies.CAD
  }
}

// @ts-ignore: OPERATION BLEED STOPPER
export const mapContryCodeToCurrency = (country: Country): Currency => {
  switch (country) {
    case Country.AU:
      return 'AUD'
    case Country.US:
      return 'USD'
    case Country.NZ:
      return 'NZD'
    case Country.GB:
      return 'GBP'
    case Country.CA:
      return 'CAD'
    case Country.ES:
      return 'EUR'
  }
}
