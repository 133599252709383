import { useMemo } from 'react'
import { SupportedLocale } from '../model'

export const useFAQLink = (locale: SupportedLocale): string =>
  useMemo(() => {
    switch (locale) {
      case 'en-AU':
        return 'https://help.afterpay.com/hc/en-au/articles/360044314071-Can-I-change-the-date-of-a-payment-due-'
      case 'en-NZ':
        return 'https://help.afterpay.com/hc/en-nz/articles/360044314071-Can-I-change-the-date-that-a-payment-is-due-'
      case 'en-US':
        return 'https://help.afterpay.com/hc/en-us/articles/360044314071-Can-I-change-the-date-a-payment-is-due-'
      case 'en-GB':
        return 'https://help.clearpay.co.uk/hc/en-gb/articles/900001764106-Can-I-change-the-date-that-a-payment-is-due-'
      case 'en-CA':
        return 'https://help.afterpay.com/hc/en-ca/articles/360044314071-Can-I-change-the-date-a-payment-is-due-'
      default:
        return 'https://help.afterpay.com/hc/en-au/articles/360044314071-Can-I-change-the-date-of-a-payment-due-'
    }
  }, [locale])

export const useIdentityVerificationFAQLink = (locale: SupportedLocale): string =>
  useMemo(() => {
    switch (locale) {
      case 'en-AU':
        return 'https://help.afterpay.com/hc/en-au/articles/360001114266-How-can-I-verify-my-account-'
      case 'en-NZ':
        return 'https://help.afterpay.com/hc/en-nz/articles/360001114266-How-can-I-verify-my-account-'
      case 'en-US':
        return 'https://help.clearpay.com/hc/en-150/articles/900007072583-How-can-I-verify-my-account-'
      default:
        return 'https://help.afterpay.com/hc/en-au/articles/360001114266-How-can-I-verify-my-account-'
    }
  }, [locale])
