import React, { FC, useCallback, useMemo } from 'react'
import { Button, Heading, Modal, Text } from '@afterpaytouch/core'
import { useDisputeTranslation } from '../hooks'
import { DisputeSubReason } from '../../../model'
import { DisputeCreationModalSteps, useDisputeCreationModalContext } from '../DisputeCreationModalContext'
import { useConsumerSizes } from '../../../utils/responsive'
import { TrackOnMount, useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

export const SelectReason: FC = () => {
  const t = useDisputeTranslation()
  const { setSubReason, setStep } = useDisputeCreationModalContext()
  const { textSize } = useConsumerSizes()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const subReasons = useMemo(() => {
    return [
      { value: DisputeSubReason.OutOfStock, desc: t('selectReason:subReason:outOfStock') },
      { value: DisputeSubReason.MerchantCancelled, desc: t('selectReason:subReason:merchantCancelled') },
    ]
  }, [t])
  const subReasonSelectedHandler = useCallback(
    (reason: DisputeSubReason) => {
      setSubReason(reason)
      setStep(DisputeCreationModalSteps.ContactMerchant)
      logEvent(TrackingEvent.PRESSED_CREATE_DISPUTE_SELECT_PROBLEM_CTA)
    },
    [logEvent, setStep, setSubReason]
  )

  const heading = t('selectReason:head')
  const subtitle = t('selectReason:subtitle')

  return (
    <>
      <TrackOnMount eventName={TrackingEvent.VIEWED_CREATE_DISPUTE_SELECT_PROBLEM} />
      <Modal.Header divider={true}>
        <div className='text-center'>
          <Heading size='L'>{heading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <Text size={textSize} renderAs='span'>
            {subtitle}
          </Text>
        </div>
        {subReasons.map(({ value, desc }) => (
          <div className='mt-4' key={value}>
            <Button.Ghost padding='Fluid' size='Large' onClick={() => subReasonSelectedHandler(value)}>
              <div className='w-full whitespace-nowrap'>
                <span className='block w-full overflow-hidden text-ellipsis' title={desc}>
                  {desc}
                </span>
              </div>
            </Button.Ghost>
          </div>
        ))}
      </Modal.Content>
    </>
  )
}
