import { createSelector } from '@reduxjs/toolkit'
import {
  OrderPaymentSchedule,
  OrdersResponse,
  OrdersResult,
  OrderTransactionRefundStatus,
  OrderResponse,
  OrderPpaResponse,
  OrderPpaDocumentResponse,
  OrderChannel,
} from '@afterpaytouch/portal-api/consumer/ordertransactions/types'
import { Money } from '@afterpaytouch/portal-api/types'
import { defaultOrdersParams, GetOrderTransactionsQuery, orderTransactionsService } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import { PaymentType } from '@afterpay/types'
import { PaymentStatus } from '@afterpaytouch/portal-api/payments/types'
import { isValidMoney } from '../../component/Money/utils'
import { hasInstallmentLastAndOwed, isFinalOrderDue, isOrderTypeUnsupportedForPaymentMethod } from './utils'
import { PaymentMethods } from './types'
import { ConsumerAppSelector, useAppSelector } from '../'

export const {
  useGetOrderTransactionsQuery,
  useLazyGetOrderTransactionQuery,
  useGetOrderTransactionQuery,
  useChangeOrderTransactionPaymentMethodMutation,
  useGetOrderTransactionPpaQuery,
  useGetOrderTransactionPpaDocumentQuery,
  useInitiateCustomAmountPaymentMutation,
  useConfirmCustomAmountPayNowMutation,
  useInitiateLendingPayoutMutation,
  useConfirmLendingPayoutMutation,
  useInitiateLendingCustomAmountPaymentMutation,
  useLazyGetOrderTransactionPpaDocumentQuery,
  useToggleAutopayMutation,
  useNotifyReturnMutation,
  useConfirmLendingCustomAmountPaymentMutation,
  endpoints: { getOrderTransactions, getOrderTransaction, getOrderTransactionPpa, getOrderTransactionPpaDocument, confirmCustomAmountPayNow },
} = orderTransactionsService

/* Selectors */

export const ordersSelector = getOrderTransactions.select(defaultOrdersParams)

// @ts-ignore: OPERATION BLEED STOPPER
export const ordersDataSelector: ConsumerAppSelector<OrdersResponse> = createSelector(ordersSelector, (state) => state.data) // contains data from the slice/query

export const ordersHasTransactions: ConsumerAppSelector<boolean> = createSelector(ordersDataSelector, (data) => Boolean(data?.results.length))

export const ordersCompleteSelector: ConsumerAppSelector<OrdersResult[]> = createSelector(ordersDataSelector, (data) =>
  data?.results?.filter((order) => parseInt(order.owedAmount.amount) <= 0)
)

/* React Hooks */

export const useOrdersDataSelector = (): OrdersResponse => useAppSelector(ordersDataSelector)

export const useOrdersHasTransactions = (): boolean => useAppSelector(ordersHasTransactions)

export const useOrdersCompleteSelector = (): OrdersResult[] => useAppSelector(ordersCompleteSelector)

// @TODO: The above hooks need to be refactored
// Example RTKQ selector hook, uses memorised cache key value to retrieve from RTKQ cache.

// @TODO: Seperate hooks for /ordertransactions and /ordertransactions/{orderId}

export const useGetOrder = (orderId: string): OrderResponse => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getOrderTransaction.useQueryState(orderId)?.data
}

export const useGetOrderPaymentType = (orderId: string): PaymentType => {
  return getOrderTransaction.useQueryState(orderId).data?.paymentType as PaymentType
}

export const useOrderGetPaymentScheduleSet = (orderId: string): OrderPaymentSchedule[] => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getOrderTransaction.useQueryState(orderId).data?.paymentScheduleSet
}

export const useGetOrderPaymentUpFront = (orderId: string): boolean => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getOrderTransaction.useQueryState(orderId).data?.paymentUpFront
}

export const useHasOrderRefundedStatus = (orderId: string): boolean => {
  const refundedStatus = getOrderTransaction.useQueryState(orderId).data?.refundedStatus
  return refundedStatus === OrderTransactionRefundStatus.FULLY_REFUNDED || refundedStatus === OrderTransactionRefundStatus.PARTIALLY_REFUNDED
}

export const useHasOrderTotalOwed = (orderId: string): boolean => {
  const orderSummary = getOrderTransaction.useQueryState(orderId).data?.orderSummary
  // @ts-ignore: OPERATION BLEED STOPPER
  return isValidMoney(orderSummary?.totalOwed)
}

export const useHasOrderIsAutopayDisabled = (orderId: string): boolean => {
  return getOrderTransaction.useQueryState(orderId)?.data?.autopayDisabled === true
}

export const useOrderHasAutopayEnabled = (orderId: string): boolean => {
  return getOrderTransaction.useQueryState(orderId)?.data?.autopayDisabled === false
}

export const useHasInstallmentLastAndOwed = (orderId: string): boolean => {
  const paymentScheduleSet = useOrderGetPaymentScheduleSet(orderId)
  return hasInstallmentLastAndOwed(paymentScheduleSet)
}

export const useGetFinalInstalment = (orderId: string): OrderPaymentSchedule => {
  const paymentScheduleSet = useOrderGetPaymentScheduleSet(orderId)
  return paymentScheduleSet?.[paymentScheduleSet?.length - 1]
}

export const useIsFinalOrderDue = (orderId: string): boolean => {
  const paymentScheduleSet = useOrderGetPaymentScheduleSet(orderId)
  return isFinalOrderDue(paymentScheduleSet)
}

export const useHasOrderTransactions = (params = defaultOrdersParams): boolean => {
  const orders = getOrderTransactions.useQueryState(params).data?.results
  return Boolean(orders?.length)
}

export const useIsOrderConsumerLending = (orderId: string): boolean => {
  const paymentType = getOrderTransaction.useQueryState(orderId).data?.paymentType
  return paymentType === PaymentType.PCL
}

export const useIsClearpayPartiallyMigrated = (orderId: string): boolean => {
  const isClearpayPartiallyMigrated = getOrderTransaction.useQueryState(orderId).data?.clearpayPartiallyMigrated
  return isClearpayPartiallyMigrated ?? false
}

export const useIsOrderTypeUnsupportedForPaymentMethod = (paymentMethod: PaymentMethods, paymentType: PaymentType): boolean => {
  return isOrderTypeUnsupportedForPaymentMethod(paymentMethod, paymentType)
}

export const useActiveOrders = (params: GetOrderTransactionsQuery): OrdersResult[] => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getOrderTransactions.useQueryState(params).data?.results?.filter((order) => parseInt(order.owedAmount.amount) > 0)
}

export const useOrdersCompletedOrders = (params: GetOrderTransactionsQuery): OrdersResult[] => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getOrderTransactions.useQueryState(params).data?.results?.filter((order) => parseInt(order.owedAmount.amount) <= 0)
}

export const useOrderGetDownpayment = (orderId: string): Money => {
  const paymentUpFront = useGetOrderPaymentUpFront(orderId)
  const paymentScheduleSet = useOrderGetPaymentScheduleSet(orderId)
  // @ts-ignore: OPERATION BLEED STOPPER
  return paymentUpFront ? paymentScheduleSet[0]?.amount : null
}

export const useOrderIsPayByInstallment = (orderId: string): boolean => {
  const paymentType = getOrderTransaction.useQueryState(orderId).data?.paymentType
  return paymentType === PaymentType.PBI || paymentType === PaymentType.PAD
}

export const useOrderRemainingSchedules = (orderId: string): OrderPaymentSchedule[] =>
  // @ts-ignore: OPERATION BLEED STOPPER
  getOrderTransaction.useQueryState(orderId).data?.paymentScheduleSet?.filter((order) => isValidMoney(order.amountPayable))

export const useOrderOwedPaymentSchedules = (orderId: string): OrderPaymentSchedule[] | null => {
  const paymentScheduleSet = useOrderGetPaymentScheduleSet(orderId)
  if (paymentScheduleSet !== undefined) {
    const owedPaymentSchedules = paymentScheduleSet.filter(
      (ps) => ps.status.toString() === PaymentStatus[PaymentStatus.OWED] || ps.status.toString() === PaymentStatus[PaymentStatus.OVERDUE]
    )

    return owedPaymentSchedules
  }

  return null
}

export const useOrderNextPaymentDateSelector = (orderId: string): OrderPaymentSchedule | null => {
  const owedPaymentSchedules = useOrderOwedPaymentSchedules(orderId)

  if (owedPaymentSchedules !== null && owedPaymentSchedules !== undefined && owedPaymentSchedules.length > 0) {
    const now = new Date(Date.now())
    const timeUntilPaymentScheduleDueDates = owedPaymentSchedules.map((ps) => new Date(ps.installmentDueDate).getTime() - now.getTime())
    return owedPaymentSchedules[
      timeUntilPaymentScheduleDueDates.indexOf(
        timeUntilPaymentScheduleDueDates.reduce((a, b) => {
          return Math.min(a, b)
        })
      )
    ]
  } else {
    return null
  }
}

export const useOrderPpa = (orderId: string): OrderPpaResponse => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getOrderTransactionPpa.useQueryState(orderId).data
}

export const useOrderPpaDocument = (documentId: string): OrderPpaDocumentResponse => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getOrderTransactionPpaDocument.useQueryState(documentId).data
}

export const useGetOrderChannel = (orderId: string): OrderChannel => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getOrderTransaction.useQueryState(orderId)?.data?.channel
}
