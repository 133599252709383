import { isUndefinedOrEmpty } from '@afterpay/utils'
import { AllCountryCode } from '@afterpaytouch/core'

export const POSTCODE_REGEX_VALIDATION: {
  [x: string]: { validPostcodeRegex: RegExp; removeSpaces: boolean; allowedRegex?: RegExp; restrictedRegex?: RegExp }
} = {
  AU: {
    // 4 digits in length e.g. 1234
    validPostcodeRegex: /^\d{4}$/,
    removeSpaces: true,
  },
  NZ: {
    // 4 digits in length e.g. 1234
    validPostcodeRegex: /^\d{4}$/,
    removeSpaces: true,
  },
  US: {
    // PAYL-6496
    // 5 digits in length e.g 12345
    // OR
    // 5 digits and a dash followed by 4 digits e.g. 12345-1234
    validPostcodeRegex: /^(\d{5}|\d{5}-\d{4})$/,
    removeSpaces: true,
  },
  CA: {
    // Canadian postal codes are in the A1A 1A1 format, where A is a letter and 1 is a digit.
    // They do not include the letters D, F, I, O, Q or U
    // The first character position also does not make use of the letters W or Z.
    // The space in between is optional
    validPostcodeRegex: /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/,
    removeSpaces: true,
  },
  GB: {
    // E.g. DE11 7GW
    // Source for regex: https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Modern_postcode_system
    // The "|[Gg][Ii][Rr] 0[Aa]{2}" part has been removed as it only applies to non-geographical territories.
    // One limitation of United Kingdom postcodes is that there is an optional space for formatting which can appear
    // after the second, third or fouth character entered. This means the FE can't add in the space to the postcode
    // as they type it in - the regex needs the entire postcode to be able to determine where the space should be.
    // This is handled automatically by the backend, which will inject the space as required.
    validPostcodeRegex: /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2})$/,
    // PAYL-7935 List of allowed United Kingdom postcodes
    allowedRegex:
      /^(AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GU|HA|HD|HG|HP|HR|HU|HS|HX|IG|IP|IV|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE).*$/i,
    restrictedRegex: /^(AI|AS|BBN|BF|BI|BX|IM|JE|FI|GY|GX|GIR|PC|SI|STH|TDC|TK|QC|XX).*$/i, // Restrictions for British overseas territories, Falkand Islands etc
    removeSpaces: false, // Allow for optional spaces in the postcode
  },
  IM: {
    // Isle of Man
    validPostcodeRegex: /^IM[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i,
    removeSpaces: false,
  },
  JE: {
    // Jersey
    validPostcodeRegex: /^JE[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i,
    removeSpaces: false,
  },
  GG: {
    // Guernsey (postcode is GY and country code is GG)
    validPostcodeRegex: /^GY[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i,
    removeSpaces: false,
  },
  // from this point downwards, postcode regexes got from here
  // https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b
  NL: {
    // Netherlands
    validPostcodeRegex: /^(?:NL-)?\d{4}\s{0,1}[A-Za-z]{2}$/i,
    removeSpaces: false,
  },
  CN: {
    // China
    validPostcodeRegex: /^\d{6}$/i,
    removeSpaces: true,
  },
  LV: {
    // Latvia
    validPostcodeRegex: /^[Ll][Vv][- ]{0,1}\d{4}$/i,
    removeSpaces: false,
  },
  FR: {
    // France
    validPostcodeRegex: /^\d{5}$/i,
    removeSpaces: true,
  },
  GI: {
    // Gibraltar
    validPostcodeRegex: /^[Gg][Xx][1]{2}\s{0,1}[1][Aa]{2}$/i,
    removeSpaces: false,
  },
  GR: {
    // Greece
    validPostcodeRegex: /^\d{3}\s{0,1}\d{2}$/i,
    removeSpaces: false,
  },
  IE: {
    // Ireland
    validPostcodeRegex: /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/i,
    removeSpaces: false,
  },
  NO: {
    // Norway
    validPostcodeRegex: /^\d{4}$/i,
    removeSpaces: true,
  },
  HK: {
    // Hong Kong
    validPostcodeRegex: /^HKG$/i,
    removeSpaces: true,
  },
  AE: {
    // United Arab Emirates
    validPostcodeRegex: /^UAE$/i,
    removeSpaces: true,
  },
  DE: {
    // Germany
    validPostcodeRegex: /^\d{5}$/i,
    removeSpaces: true,
  },
  IT: {
    // Italy
    validPostcodeRegex: /^\d{5}$/i,
    removeSpaces: true,
  },
  ES: {
    // Spain
    validPostcodeRegex: /^\d{5}$/i,
    removeSpaces: true,
  },
}

export const isValidPostcode = (country: AllCountryCode, postcode: string, optional: boolean = false): boolean => {
  if (optional && (isUndefinedOrEmpty(postcode) || postcode === country)) {
    return true
  }
  if (isUndefinedOrEmpty(postcode)) {
    return false
  }

  let isValid = optional
  let isAllowed = true
  let isRestricted = false
  const regexRules = POSTCODE_REGEX_VALIDATION[country]

  if (typeof regexRules !== 'undefined') {
    postcode = regexRules.removeSpaces ? postcode.replace(/\s+/g, '') : postcode
    isValid = regexRules.validPostcodeRegex.test(postcode)

    if (typeof regexRules.allowedRegex !== 'undefined') {
      isAllowed = regexRules.allowedRegex.test(postcode)
    }

    if (typeof regexRules.restrictedRegex !== 'undefined') {
      isRestricted = regexRules.restrictedRegex.test(postcode)
    }
  }

  return isValid && isAllowed && !isRestricted
}
