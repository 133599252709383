import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Route } from '../../config/router'
import { useConsumerHasCreditFacility } from '../../state'
import { useFlag } from '../useFlag'

export const useGatePayMonthlyPage = (): void => {
  const router = useRouter()
  const isPayMonthlyEnabled = useFlag('consumer-portal-pay-monthly-enabled', false)
  const hasCreditFacility = useConsumerHasCreditFacility()
  const isPayMonthylyPageAccessible = isPayMonthlyEnabled && hasCreditFacility
  useEffect(() => {
    // User has navigated to Pay Monthly page when the navigation item is hidden
    if (!isPayMonthylyPageAccessible) {
      router.push(Route.HOME)
    }
  }, [isPayMonthylyPageAccessible, router])
}
