import React, { FC, useMemo } from 'react'
import { Button, Heading, Modal, Text } from '@afterpaytouch/core'
import { DisputePermissionError } from '@afterpaytouch/portal-api'

import { useDisputePermissionError } from '../../../state'
import { useDisputeTranslation } from '../hooks'
import { useConsumerSizes } from '../../../utils/responsive'
import { useDisputeCreationModalContext } from '../DisputeCreationModalContext'
import { AmplitudeEventTypes } from '../../../integrations/amplitude/types'
import { TrackOnMount } from '../../../integrations/amplitude'

export const NoPermission: FC = () => {
  const { textSize } = useConsumerSizes()
  const t = useDisputeTranslation()
  const disputePermissionError: DisputePermissionError | undefined = useDisputePermissionError()
  const { closeModal } = useDisputeCreationModalContext()

  const [heading, cta] = useMemo(() => {
    return [t('result:failure:head'), t('result.failure.closeCTA')]
  }, [t])
  const errorMsg = useMemo<string>(() => {
    switch (disputePermissionError) {
      case DisputePermissionError.ExternalDisputeExisted:
        return t('result.failure.externalDisputeExist')
      case DisputePermissionError.DisputeTimesLimitExceeded:
        return t('result.failure.timesLimit.message')
      case DisputePermissionError.DuplicatedEntity:
        return t('result.failure.duplicateEntity')
      default:
        return t('result.failure.unspecific')
    }
  }, [t, disputePermissionError])

  return (
    <>
      <TrackOnMount
        eventName={AmplitudeEventTypes.VIEWED_DISPUTE_PERMISSION_CHECKER}
        eventProps={{
          reason: disputePermissionError,
        }}
      />
      <Modal.Header divider={true}>
        <div className='text-center'>
          <Heading size='L'>{heading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Text size={textSize}>{errorMsg}</Text>
        <div className='mt-16'>
          <Button padding='Fluid' onClick={closeModal}>
            {cta}
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}
