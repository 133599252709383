import { CashAppPaysResponse } from '@afterpaytouch/portal-api'
import { cashAppPaysService } from '@afterpaytouch/portal-api/consumer/account/cash-app-pays'
import { createSelector } from '@reduxjs/toolkit'
import { useAppSelector } from '../hooks'
import { ConsumerAppSelector } from '../store'

export const {
  useGetCashAppPaysQuery,
  endpoints: { getCashAppPays },
  useSetPreferredCashAppPayMutation,
  useRemoveCashAppPayMutation,
} = cashAppPaysService

export const cashAppPaysSelector = getCashAppPays.select()

export const cashAppPaysDataSelector: ConsumerAppSelector<CashAppPaysResponse | undefined> = createSelector(cashAppPaysSelector, (state) => state.data)

export const cashAppPaysHasResultsSelector: ConsumerAppSelector<boolean> = createSelector(cashAppPaysDataSelector, (data) => Boolean(data?.cashAppPay?.length))

export const useCashAppPaysHasResults = (): boolean => useAppSelector(cashAppPaysHasResultsSelector)
