import { isEmptyObject } from '@afterpay/utils'
import { GetConsumerLoyaltyStatusResponseBody, GetConsumerLoyaltyStatusResponseBodyUserNotFoundOrIsHeldOut } from '@afterpaytouch/portal-api'

export const isGetConsumerLoyaltyStatusResponseBody = (x: any): x is GetConsumerLoyaltyStatusResponseBody => {
  if (x?.pulseStatus?.currentTier != null) {
    return true
  }
  return false
}

export const isGetConsumerLoyaltyStatusResponseBodyUserNotFoundOrIsHeldOut = (x: any): x is GetConsumerLoyaltyStatusResponseBodyUserNotFoundOrIsHeldOut => {
  return isEmptyObject(x)
}

export const isGetConsumerLoyaltyStatusPlatinumOrAbove = (x: any): x is GetConsumerLoyaltyStatusResponseBody => x?.pulseStatus?.currentTier >= 1
