import { ErrorCode } from '@afterpaytouch/portal-api/types'

export const confirmPayErrors: Partial<Record<ErrorCode, string>> = {
  [ErrorCode.CardNotEnabled]: 'cardNotEnabled',
  [ErrorCode.InsufficientFunds]: 'insufficientFunds', // @TODO: Confirm if this is a valid error code
  [ErrorCode.UnsupportedCardType]: 'unsupportedCardType',
  [ErrorCode.UnsupportedCardIssuer]: 'unsupportedCardIssuer',
  [ErrorCode.CardExpiry]: 'cardExpiry',
  [ErrorCode.PreconditionFailed]: 'preconditionFailed',
  [ErrorCode.PaymentFailure]: 'paymentFailure',
}
