import { TFunction } from 'next-i18next'
import { PaymentSetupProps, PayNowInput, PayNowInputProps } from '../PayNowModalContext'
import { getSingleInstallmentSequence } from '../utils'

export const getPaymentLabel = (payment: PaymentSetupProps, payNowStep: PayNowInputProps, t: TFunction): string => {
  const installmentLabels: string[] = Array.from(Array(4).keys()).map((n) => t(`payments:customPayment:payment:label:sequence:${n}`))

  switch (payNowStep) {
    case PayNowInput.NEXT:
      return t('payments:customPayment:installmentsv2:base:headings:next')
    case PayNowInput.REMAINING:
      return t('payments:customPayment:installmentsv2:base:headings:total')
    case PayNowInput.CUSTOM:
      return t('payments:customPayment:installmentsv2:base:subheadings:custom:payment')
    default:
      // @ts-ignore: OPERATION BLEED STOPPER
      return installmentLabels[getSingleInstallmentSequence(payment)] ?? t('payments:customPayment:payment:label:total')
  }
}
