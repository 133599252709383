import { ActionCreatorWithoutPayload, createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authLogin, authLogout, createFingerprint } from '../../'
import { authenticate, Authentication, AuthenticationErrors, FingerPrint, unauthenticate } from '../../../model'
import { ErrorCode } from '@afterpaytouch/portal-api/types'
import { v4 as uuid } from 'uuid'
import { authCheckCode, authLogoutEndpoint } from './hooks'
import { AuthCheckCodeStatus, PasswordResetTwoFactorChallenge } from '@afterpaytouch/portal-api/consumer/auth/types'

export interface AuthenticationState {
  profilingId: string | null
  fingerprint: FingerPrint | null
  status: Authentication
  error?: AuthenticationErrors
  passwordResetVerifyCode?: string
  passwordResetChallenges?: PasswordResetTwoFactorChallenge[]
}

export const userInitialState: AuthenticationState = {
  status: unauthenticate(),
  profilingId: null,
  fingerprint: null,
}

const authErrors: Partial<Record<ErrorCode, AuthenticationErrors>> = {
  [ErrorCode.AccountLocked]: AuthenticationErrors.AccountLocked,
  [ErrorCode.AccountDisabled]: AuthenticationErrors.AccountDisabled,
  [ErrorCode.AuthenticationFailure]: AuthenticationErrors.IncorrectPassword,
  [ErrorCode.AuthenticationFailureMaxAttempts]: AuthenticationErrors.FailureMaxAttempts,
  [ErrorCode.TooManyOTPRequests]: AuthenticationErrors.TooManyOTPRequests,
  [ErrorCode.PaywatchCheckFailed]: AuthenticationErrors.PaywatchCheckFailed,
}

const slice: Slice = createSlice({
  name: 'auth',
  initialState: userInitialState,
  reducers: {
    createProfilingId: {
      reducer(state: AuthenticationState, action: PayloadAction<string>) {
        state.profilingId = action.payload
      },
      prepare() {
        return { payload: uuid() }
      },
    },
    resetAuthState(state: AuthenticationState) {
      // @ts-ignore: OPERATION BLEED STOPPER
      state.profilingId = undefined
      // @ts-ignore: OPERATION BLEED STOPPER
      state.fingerprint = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFingerprint.fulfilled, (state, action) => {
        state.fingerprint = action.payload
      })
      .addCase(authLogout.fulfilled, (state) => {
        state.status = unauthenticate()
      })
      .addMatcher(authLogin.matchPending, (state) => {
        state.error = undefined
        state.status = unauthenticate()
      })
      .addMatcher(authLogin.matchRejected, (state, action) => {
        state.status = unauthenticate()
        if (action.meta.rejectedWithValue) {
          // @ts-expect-error: OPERATION BLEED STOPPER
          state.error = authErrors[action.payload.errorCode] ?? AuthenticationErrors.OtherError
        } else {
          state.error = AuthenticationErrors.OtherError
        }
      })
      .addMatcher(authLogin.matchFulfilled, (state, action) => {
        const isPersistentSession = action.meta.arg.originalArgs['remember-me']
        state.status = authenticate(action.payload.user.requires2fa, isPersistentSession, isPersistentSession)
      })
      .addMatcher(authLogoutEndpoint.matchFulfilled, (state) => {
        state.status = unauthenticate()
      })
      .addMatcher(authCheckCode.matchFulfilled, (state, action) => {
        state.passwordResetVerifyCode = action.meta.arg.originalArgs.code
        if (action.payload.status === AuthCheckCodeStatus.TWO_FACTOR_REQUIRED) {
          state.passwordResetChallenges = action.payload.challenges
        }
      })
  },
})

// below is to make typescript happy
export const createProfilingId = slice.actions.createProfilingId as ActionCreatorWithoutPayload<string>
export const resetAuthState = slice.actions.resetAuthState as ActionCreatorWithoutPayload<string>

export const userReducer = slice.reducer
