import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Heading, Modal, Text } from '@afterpaytouch/core'
import { useConsumerSizes } from '../../utils/responsive'
import { OrderResponse } from '@afterpaytouch/portal-api'
import { useOrderRefundModal } from './hooks'
import { DisputeCreationModal, useDisputeCreationModal } from '../DisputeCreationModal'
import { OrderRefundModal } from '../OrderRefundModal'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { useRouter } from 'next/router'
import { NewTabLink } from '../NewTabLink'
import { isScheduleOverdue, isScheduleCompleted } from '../../state/orders/utils'

const I18N_NAMESPACE = ['orders']

interface GetHelpModalProps {
  order: OrderResponse
  onClose: () => void
  show: boolean
  reopenGetHelpModal: () => void
  disputeProps: {
    refreshDisputeInfo?: () => void
  }
  animate: boolean
  isConsumerLendingOrHardship: boolean
}

export const GetHelpModal: FunctionComponent<GetHelpModalProps> = ({
  order,
  onClose,
  show,
  disputeProps,
  reopenGetHelpModal,
  animate,
  isConsumerLendingOrHardship,
}) => {
  const { textSize, sectionHeadingSize } = useConsumerSizes()
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const router = useRouter()

  const [isOrderRefundModalOpen, { openModal: openOrderRefundModal, closeModal: closeOrderRefundModal }] = useOrderRefundModal()
  const isAnyScheduleOverdue = useMemo(() => order?.paymentScheduleSet?.some((schedule) => isScheduleOverdue(schedule)), [order])
  const isAllScheduleComplete = useMemo(() => order?.paymentScheduleSet?.every((schedule) => isScheduleCompleted(schedule)), [order])
  const isAnyScheduleOverdueOrAllComplete = isAnyScheduleOverdue || isAllScheduleComplete

  const {
    disputeCtaVisible,
    disputeCtaEnabled,
    disputeCtaDisabledTitle,
    isModalOpen: isDisputeCreationModalOpen,
    openModal: openDisputeCreationModal,
    closeModal: closeDisputeCreationModal,
  } = useDisputeCreationModal(order)

  return (
    <>
      <Modal show={show} onClose={onClose} onBackdropClick={onClose} onEscapeKeyDown={onClose} testNameSpace='get-help' animate={animate}>
        <div className='text-center'>
          <Modal.Header divider>
            <Heading size={sectionHeadingSize}>{t('orders:reportProblemModal:title')}</Heading>
          </Modal.Header>
        </div>
        <Modal.Content>
          <div className='pt-2'>
            <Text size={textSize}>{t('orders:reportProblemModal:body')}</Text>
          </div>
          <div className='mt-6'>
            <Button.Ghost
              padding='Fluid'
              onClick={() => {
                openOrderRefundModal()
                onClose()
                // @ts-ignore: OPERATION BLEED STOPPER
                logEvent(TrackingEvent.CLICKED_IM_RETURNING_MY_ORDER, { locale: router?.locale })
              }}
              testNameSpace='report-problem-modal-return'
              disabled={isConsumerLendingOrHardship}
            >
              <Text bold size={textSize}>
                {t('orders:reportProblemModal:return')}
              </Text>
            </Button.Ghost>
          </div>
          {disputeCtaVisible && (
            <div className='mt-6'>
              <Button.Ghost
                disabled={!disputeCtaEnabled}
                padding='Fluid'
                testNameSpace='create-dispute'
                onClick={() => {
                  openDisputeCreationModal()
                  onClose()
                }}
              >
                <Text size={textSize} title={disputeCtaDisabledTitle} bold>
                  {t('orders:reportProblemModal:dispute')}
                </Text>
              </Button.Ghost>
            </div>
          )}
          <div className='mt-6 py-[1px]'>
            <NewTabLink href={t('common:urls:helpUrl:newRequest')}>
              <Button.Ghost padding='Fluid' testNameSpace='contact-support'>
                <Text size={textSize} title={disputeCtaDisabledTitle} bold>
                  {t('orders:reportProblemModal:contact')}
                </Text>
              </Button.Ghost>
            </NewTabLink>
          </div>
        </Modal.Content>
      </Modal>
      <OrderRefundModal
        order={order}
        isModalOpen={isOrderRefundModalOpen}
        closeModal={closeOrderRefundModal}
        reopenGetHelpModal={reopenGetHelpModal}
        animate={false}
        isOverdueOrComplete={isAnyScheduleOverdueOrAllComplete}
      />
      <DisputeCreationModal
        order={order}
        isModalOpen={isDisputeCreationModalOpen}
        closeModal={closeDisputeCreationModal}
        // @ts-ignore: OPERATION BLEED STOPPER
        refreshDisputeInfo={disputeProps.refreshDisputeInfo}
        animate={false}
        reopenGetHelpModal={reopenGetHelpModal}
      />
    </>
  )
}
