import React, { FunctionComponent, ReactNode } from 'react'
import { DriversLicence, Medicare, Passport } from '../'
import { IdentityDocument, DocumentProps } from '../../../model'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'

interface Props extends DocumentProps {
  documentType?: IdentityDocument
}

export const VerifyDocument: FunctionComponent<Props> = ({ documentType, onSuccess, onFailure }) => {
  const renderDocuments = (): ReactNode => {
    switch (documentType) {
      case 'licence':
        return <DriversLicence onSuccess={onSuccess} />
      case 'passport':
        return <Passport onSuccess={onSuccess} />
      case 'medicare':
        return <Medicare onSuccess={onSuccess} />
      default:
        return <DriversLicence onSuccess={onSuccess} />
    }
  }

  return <MaskedElement>{renderDocuments()}</MaskedElement>
}
