import React, { FunctionComponent, ReactElement, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Icon, Heading } from '@afterpaytouch/core'
import { AchFlowSteps, AchFlowModalContext } from '../AchFlowModalContext'

const I18N_NAMESPACE = ['common', 'ach']

export const AchFlowNavigation: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const {
    initialStep,
    step,
    setStep,
    result: { error, success, httpStatusCode },
  } = useContext(AchFlowModalContext)

  const handleBack = (): void => setStep(AchFlowSteps.SelectPaymentMethodType)
  const renderBackButton = (): ReactElement => {
    // The consumer can only back to the previous step in the AddBankAccount and AddCard stage.
    // And if any errors, do not show the back button.
    if (error || (step !== AchFlowSteps.AddBankAccount && step !== AchFlowSteps.AddCard) || step === initialStep) {
      // @ts-ignore: OPERATION BLEED STOPPER
      return
    }
    return (
      <div className='absolute z-10'>
        <Button.Link aria-label={t('ach:navigation:back')} size='Small' onClick={handleBack} testNameSpace='ach-flow-navigation-back'>
          <Icon.CaretLeft />
        </Button.Link>
      </div>
    )
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  const renderHeader = (): ReactElement => {
    if (success) {
      return <Heading size='L'>{t('ach:success:heading')}</Heading>
    }
    switch (step) {
      case AchFlowSteps.SelectPaymentMethodType:
        return <Heading size='L'>{t('ach:selectPaymentMethodType:heading')}</Heading>
      case AchFlowSteps.AddBankAccount:
        return error ? <Heading size='L'>{t('ach:addBankAccount:error:heading')}</Heading> : <Heading size='L'>{t('ach:addBankAccount:heading')}</Heading>
      case AchFlowSteps.AddCard:
        return <Heading size='L'>{t('ach:addCard:heading')}</Heading>
      case AchFlowSteps.CreatePlaid:
        // @ts-ignore: OPERATION BLEED STOPPER
        return error ? <Heading size='L'>{t('ach:genericError:heading')}</Heading> : null
      case AchFlowSteps.LinkBankAccount:
        return error ? (
          httpStatusCode === 412 ? (
            <Heading size='L'>{t('ach:linkBankAccount:error:headingNoAccount')}</Heading>
          ) : (
            <Heading size='L'>{t('ach:genericError:heading')}</Heading>
          )
        ) : (
          <Heading size='L'>{t('ach:linkBankAccount:heading')}</Heading>
        )
    }
  }
  return (
    <>
      {renderBackButton()}
      <div className='text-center'>{renderHeader()}</div>
    </>
  )
}
