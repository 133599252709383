import React, { useCallback, FunctionComponent, useEffect, useContext } from 'react'
import { Modal } from '@afterpaytouch/core'
import { AchFlowSteps, AchFlowModalContext, AchFlowModalProvider, AchStepsProps } from './AchFlowModalContext'
import { SelectPaymentMethodType } from './SelectPaymentMethodType'
import { AddCard } from './AddCard'
import { AddBankAccount } from './AddBankAccount'
import { CreatePlaid } from './CreatePlaid'
import { LinkBankAccount } from './LinkBankAccount'
import { BankAccountDetails } from '@afterpay/types'
import { RelinkPlaid } from './RelinkPlaid'

interface AchFlowModalProps {
  show: boolean
  closeModal: () => void
  initialStep?: AchStepsProps
  initialEligible?: boolean
  bankAccountToBeRelinked?: BankAccountDetails
  onSuccess?: (LinkedAccountFingerprint?: string, id?: Number) => void
  achFlowEntryDeepLink?: string
  animate?: boolean
}

export const AchFlowModalSteps: FunctionComponent<AchFlowModalProps> = ({ show, closeModal, onSuccess, animate }) => {
  const { step, resetState, isLoading, showCloseButton } = useContext(AchFlowModalContext)
  const renderStep = useCallback((): React.ReactNode => {
    switch (step) {
      case AchFlowSteps.SelectPaymentMethodType:
        return <SelectPaymentMethodType />
      case AchFlowSteps.AddBankAccount:
        return <AddBankAccount />
      case AchFlowSteps.AddCard:
        return <AddCard />
      case AchFlowSteps.CreatePlaid:
        return <CreatePlaid />
      case AchFlowSteps.RelinkPlaid:
        return <RelinkPlaid onSuccess={onSuccess} />
      case AchFlowSteps.LinkBankAccount:
        return <LinkBankAccount onSuccess={onSuccess} />
    }
  }, [step, onSuccess])

  useEffect(() => {
    if (!show) {
      resetState()
    }
  }, [show, resetState])

  return (
    <Modal
      showCloseButton={showCloseButton}
      show={show}
      onClose={closeModal}
      onHide={closeModal}
      // prevent the consumer from closing the button to quit in the plaid flow
      onBackdropClick={step === AchFlowSteps.CreatePlaid || step === AchFlowSteps.RelinkPlaid ? undefined : closeModal}
      onEscapeKeyDown={closeModal}
      testNameSpace='ach-flow'
      loading={isLoading}
      animate={animate}
    >
      {renderStep()}
    </Modal>
  )
}

export const AchFlowModal: FunctionComponent<AchFlowModalProps> = ({
  show = false,
  closeModal,
  initialStep,
  initialEligible,
  bankAccountToBeRelinked,
  onSuccess,
  achFlowEntryDeepLink,
  animate,
}) => {
  return show ? (
    <AchFlowModalProvider
      closeModal={closeModal}
      initialStep={initialStep}
      initialEligible={initialEligible}
      bankAccountToBeRelinked={bankAccountToBeRelinked}
      achFlowEntryDeepLink={achFlowEntryDeepLink}
    >
      <AchFlowModalSteps show={show} closeModal={closeModal} onSuccess={onSuccess} animate={animate} />
    </AchFlowModalProvider>
  ) : null
}
