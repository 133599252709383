import { Money as IMoney, IdentityCheckStatus } from '@afterpaytouch/portal-api'
import { defaultOrdersParams } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import { TFunction, useTranslation } from 'next-i18next'
import { useCallback } from 'react'

import {
  useConsumerIdentityIsValid,
  useConsumerHasPreferredPaymentMethod,
  useOrdersHasTransactions,
  useConsumerGivenName,
  useGetCreditLimitQuery,
  useConsumerLocaleHardIdentityCheckIsRequired,
  useConsumerIdentityStatus,
  useConsumerCreditLimitOverride,
  useGetOrderTransactionsQuery,
} from '../../../state'

const I18N_NAMESPACE = ['common', 'index']

export const useNewUserLocalization = (): {
  t: TFunction
  locale: string
} => {
  const {
    t,
    i18n: { language },
  } = useTranslation(I18N_NAMESPACE)

  return { t, locale: language }
}

export const useNewUserEffects = (): {
  consumer: {
    isVerified: boolean
    isLocaleHardIdentityCheckRequired: boolean
    identityCheckStatus: IdentityCheckStatus
    hasPreferredPaymentMethod: boolean
    hasTransactions: boolean
    givenName: string
    availableToSpend: IMoney
  }
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  refetchNewUser: () => void
} => {
  const isConsumerVerified = useConsumerIdentityIsValid()
  const {
    data: creditLimitData,
    isLoading: isCreditLimitLoading,
    isFetching: isCreditLimitFetching,
    isError: isCreditLimitError,
    refetch: refetchGetCreditLimitQuery,
  } = useGetCreditLimitQuery(undefined, { skip: !isConsumerVerified })

  const {
    isFetching: isGetOrderTransactionsQueryFetching,
    isLoading: isGetOrderTransactionsQueryLoading,
    isError: isGetOrderTransactionsQueryError,
    refetch: refetchGetOrderTransactionsQuery,
  } = useGetOrderTransactionsQuery(defaultOrdersParams, {
    refetchOnFocus: false,
  })

  const refetchNewUser = useCallback((): void => {
    if (isCreditLimitError) {
      refetchGetCreditLimitQuery()
    }
    if (isGetOrderTransactionsQueryError) {
      refetchGetOrderTransactionsQuery()
    }
  }, [isCreditLimitError, isGetOrderTransactionsQueryError, refetchGetCreditLimitQuery, refetchGetOrderTransactionsQuery])

  return {
    consumer: {
      isVerified: isConsumerVerified, // @TODO: We will likely have to use the identity verify API here as apparently the ID status returned here cannot be relied on
      isLocaleHardIdentityCheckRequired: useConsumerLocaleHardIdentityCheckIsRequired(),
      // @ts-ignore: OPERATION BLEED STOPPER
      identityCheckStatus: useConsumerIdentityStatus(),
      hasPreferredPaymentMethod: useConsumerHasPreferredPaymentMethod(),
      hasTransactions: useOrdersHasTransactions(),
      // @ts-ignore: OPERATION BLEED STOPPER
      givenName: useConsumerGivenName(),
      // @ts-ignore: OPERATION BLEED STOPPER
      availableToSpend: useConsumerCreditLimitOverride(creditLimitData?.creditLimit),
    },
    isLoading: isCreditLimitLoading || isGetOrderTransactionsQueryLoading,
    isFetching: isCreditLimitFetching || isGetOrderTransactionsQueryFetching,
    isError: isCreditLimitError || isGetOrderTransactionsQueryError,
    refetchNewUser,
  }
}
