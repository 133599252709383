import { LicenseType } from './types'

const alphanumericPattern = /^[a-zA-Z0-9]*$/

const numericPattern = /^[0-9]*$/

// a New Zealand licence number expects it in this format:
//     2 alphabetical characters, followed by 6 numerical characters
// For example:
//     aC123456
//     Hg654321

const nzPattern = /^[a-zA-Z]{2}\d{6}$/

export const AU_STATES_TO_LICENCE_TYPE_MAP: Record<string, LicenseType> = {
  NSW: {
    minLength: 6,
    maxLength: 8,
    pattern: alphanumericPattern,
  },
  VIC: {
    minLength: 1,
    maxLength: 10,
    pattern: numericPattern,
  },
  QLD: {
    minLength: 8,
    maxLength: 9,
    pattern: numericPattern,
  },
  WA: {
    minLength: 7,
    maxLength: 7,
    pattern: numericPattern,
  },
  SA: {
    minLength: 6,
    maxLength: 6,
    pattern: alphanumericPattern,
  },
  TAS: {
    minLength: 6,
    maxLength: 8,
    pattern: alphanumericPattern,
  },
  ACT: {
    minLength: 1,
    maxLength: 10,
    pattern: numericPattern,
  },
  NT: {
    minLength: 1,
    maxLength: 10,
    pattern: numericPattern,
  },
}

export const NZ_LICENCE_TYPE_MAP: Record<string, LicenseType> = {
  DEFAULT: {
    minLength: 8,
    maxLength: 8,
    pattern: nzPattern,
  },
}

export const COUNTRY_TO_LICENCE_VERSION_RESTRICTIONS = {
  NZ: {
    minLength: 3,
    maxLength: 3,
    pattern: numericPattern,
  },
  DEFAULT: {
    minLength: 0,
    maxLength: 0,
    pattern: alphanumericPattern,
  },
}

export const COUNTRY_FIELDS_HIDDEN = {
  AU: ['licenceVersion', 'profileCard'],
  NZ: ['state', 'dlCardNumber'],
} as const

export const COUNTRY_STATES_MAP = {
  AU: AU_STATES_TO_LICENCE_TYPE_MAP,
  NZ: NZ_LICENCE_TYPE_MAP,
}

export const LICENCE_CARD_NUMBER_RESTRICTION = {
  ACT: {
    minLength: 10,
    maxLength: 10,
    pattern: alphanumericPattern,
  },
  NSW: {
    minLength: 10,
    maxLength: 10,
    pattern: numericPattern,
  },
  NT: {
    minLength: 6,
    maxLength: 8,
    pattern: numericPattern,
  },
  QLD: {
    minLength: 10,
    maxLength: 10,
    pattern: alphanumericPattern,
  },
  SA: {
    minLength: 9,
    maxLength: 9,
    pattern: alphanumericPattern,
  },
  TAS: {
    minLength: 9,
    maxLength: 9,
    pattern: alphanumericPattern,
  },
  VIC: {
    minLength: 8,
    maxLength: 8,
    pattern: alphanumericPattern,
  },
  WA: {
    minLength: 8,
    maxLength: 10,
    pattern: alphanumericPattern,
  },
}
