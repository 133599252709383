import { Trans, useTranslation } from 'next-i18next'
import React, { FunctionComponent, ReactNode, useEffect } from 'react'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { Heading, Modal, Span, Text } from '@afterpaytouch/core'
import { Logo } from '../Logo'

interface DateComponentProps {
  children?: ReactNode
}

const I18N_NAMESPACE = ['index']
const TEST_NAME_SPACE = 'termination-notice'

export interface TerminationNoticeModalProps {
  isModalOpen: boolean
  closeModal: () => void
}

const Date: FunctionComponent<DateComponentProps> = ({ children }) => <Span className='font-bold'>{children}</Span>

export const TerminationNoticeModal: FunctionComponent<TerminationNoticeModalProps> = ({ isModalOpen, closeModal }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const handleClose = (): void => {
    closeModal()
    logEvent(TrackingEvent.TERMINATION_NOTICE.CLOSE_BUTTON_CLICKED)
  }

  useEffect(() => {
    if (isModalOpen) {
      logEvent(TrackingEvent.TERMINATION_NOTICE.MODAL_VIEWED)
    }
  }, [isModalOpen])

  return (
    <Modal testNameSpace={TEST_NAME_SPACE} show={isModalOpen} onClose={handleClose}>
      <Modal.Content>
        <div className='justify-cetner mb-10 flex'>
          <Logo />
        </div>
        <div className='mt-6 text-center'>
          <Heading size='XXL' testNameSpace={TEST_NAME_SPACE}>
            {t('index:terminationNotice:heading')}
          </Heading>
          <div className='mt-10 text-left'>
            <Text>
              <Trans
                i18nKey={'index:terminationNotice.content.windDownDate'}
                components={{
                  Date: <Date />,
                }}
              />
            </Text>
          </div>
          <div className='mt-4 text-left'>
            <Text>{t('terminationNotice.content.body')}</Text>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}
