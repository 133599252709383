import { Money, OrderPaymentSchedule } from '@afterpaytouch/portal-api/types'
import { isHardshipScheduleOverdue } from '../../../state'
import { PayNowInputProps, PayNowInput } from '../PayNowModalContext'
import { PaymentArrangementScheduleResponse } from '@afterpaytouch/portal-api/consumer/hardship/types'

export const isOwedFeeOwing = (installment: Money): boolean => parseFloat(installment?.amount) > 0

export const calculateLateFeesPayable = (orderPaymentSchedules: OrderPaymentSchedule[]): Money => {
  return orderPaymentSchedules
    .map((schedule) => schedule?.owedFee)
    .reduce(
      // @ts-ignore: OPERATION BLEED STOPPER
      (prev, current) => {
        return {
          amount: String(Number(prev.amount) + Number(current.amount)),
          currency: current.currency,
          symbol: current.symbol,
        }
      },
      { amount: '0', currency: undefined, symbol: '' }
    )
}

export const isHardshipScheduleOverdueSetPayment = (step: PayNowInputProps, repayments: PaymentArrangementScheduleResponse): boolean => {
  switch (step) {
    case PayNowInput.NEXT:
      return isHardshipScheduleOverdue(repayments.paymentSchedules[0])
    case PayNowInput.REMAINING:
      return repayments.paymentSchedules.some((repayment) => isHardshipScheduleOverdue(repayment))
    case PayNowInput.CUSTOM:
      return false
  }
}
