import React, { FunctionComponent, useContext } from 'react'
import { OrderRefundModalContext, OrderRefundSteps } from '../OrderRefundModalContext'
import { OrderRefundNavigation } from '../OrderRefundNavigation/OrderRefundNavigation'
import { Button, Grid, Modal, Text } from '@afterpaytouch/core'
import { useConsumerSizes } from '../../../utils/responsive'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useDisplayIllustrations } from '../../../state/convergence/hooks'

const I18N_NAMESPACE = ['orders']

export const InstoreReturn: FunctionComponent = () => {
  const { setStep, closeModal } = useContext(OrderRefundModalContext)
  const { smallTextSize, textSize } = useConsumerSizes()
  const { t } = useTranslation(I18N_NAMESPACE)
  const isDisplayIllustrations = useDisplayIllustrations()

  const inStoreContent = [
    {
      icon: 'icon-senditems',
      iconWidth: 41,
      iconHeight: 48,
      heading: t('orders:returningOrderModal:instoreReturn:confirm:accordion:content1:heading'),
      body: t('orders:returningOrderModal:instoreReturn:confirm:accordion:content1:body'),
    },
    {
      icon: 'icon-keepyourreturn',
      iconWidth: 40,
      iconHeight: 40,
      heading: t('orders:returningOrderModal:instoreReturn:confirm:accordion:content2:heading'),
      body: t('orders:returningOrderModal:instoreReturn:confirm:accordion:content2:body'),
    },
    {
      icon: 'icon-app-balance',
      iconWidth: 36,
      iconHeight: 56,
      heading: t('orders:returningOrderModal:instoreReturn:confirm:accordion:content3:heading'),
      body: t('orders:returningOrderModal:instoreReturn:confirm:accordion:content3:body'),
    },
  ]

  return (
    <>
      <OrderRefundNavigation handleBack={() => setStep(OrderRefundSteps.ReturnType)} heading={t('orders:returningOrderModal:instoreReturn:confirm:heading')} />
      <Modal.Content>
        <div className='mt-2'>
          <Text size={textSize} testNameSpace='instore-confirm-modal-return'>
            {t('orders:returningOrderModal:instoreReturn:confirm:body')}
          </Text>
        </div>
        <div className='my-6'>
          {inStoreContent.map((item, index) => (
            <Grid key={index}>
              {isDisplayIllustrations && (
                <Grid.FixedItem width={64}>
                  <Image
                    src={`/images/${item.icon}.svg`}
                    height={item.iconHeight}
                    width={item.iconWidth}
                    alt={t('orders:returningOrderModal:alt', { title: item.heading })}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Grid.FixedItem>
              )}
              <div data-testid='instore-confirm-modal-content'>
                <Text bold size={smallTextSize}>
                  {item.heading}
                </Text>
                <Text size={smallTextSize}>{item.body}</Text>
              </div>
            </Grid>
          ))}
        </div>
        <Button padding='Fluid' onClick={closeModal} testNameSpace='instore-confirm-modal-cta' aria-label={t('orders:returningOrderModal.aria.nav.close')}>
          {t('orders:returningOrderModal:mailReturn:confirm:cta')}
        </Button>
      </Modal.Content>
    </>
  )
}
