import React, { FunctionComponent, useState } from 'react'
import { Grid, Text, Button, Link } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'

import { useConsumerName, useConsumerSelector, useLogout } from '../../state'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { TrackOnMount } from '../../integrations/amplitude'
import styles from './style.module.css'
import { Gravatar } from '../Gravatar'

interface ProfileCardProps {
  allowLogout?: boolean
  authLogout?: boolean
  onLogout?: () => any
  logoutText?: string
  onEdit?: () => any
  header?: string
  hideSubheader?: boolean
  customSubheader?: string
}

const I18N_NAMESPACE = ['common']

export const ProfileCardID: FunctionComponent<ProfileCardProps> = ({
  allowLogout = false,
  authLogout = false,
  header,
  onEdit,
  hideSubheader = true,
  customSubheader,
}) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const consumerName = useConsumerName()

  const { email } = useConsumerSelector()
  const hasOnEdit = typeof onEdit !== 'undefined'
  const [showEditButton, setShowEditButton] = useState(hasOnEdit)
  const consumerNameHeader = consumerName !== '' ? consumerName : null
  const defaultHeader = customSubheader ?? consumerNameHeader ?? email
  const logout = useLogout()

  const handleEditClick = (): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    onEdit()
    setShowEditButton(!showEditButton)
  }

  const renderAuthLogout = (): JSX.Element => {
    return (
      <Text renderAs='span'>
        <TrackOnMount eventName='Viewed Logout Button' />
        <Link kind='Underline' onClick={logout} testNameSpace='profile-card-logout'>
          {t('common:logOut')}
        </Link>
      </Text>
    )
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  const renderLink = (): JSX.Element => {
    if (allowLogout && authLogout) {
      return renderAuthLogout()
    }
  }

  return (
    <Grid>
      <MaskedElement>
        <div className={styles.profileCard}>
          <Gravatar size='M' alt={consumerName} name={consumerName} title={consumerName} />
          <div className={styles.variableColumnWrapper}>
            <Text renderAs='div'>
              <div className={styles.variableColumn}>
                <Text size='M' bold>
                  {/* @ts-ignore: OPERATION BLEED STOPPER */}
                  {header?.length > 0 ? header : defaultHeader}
                </Text>
                {hasOnEdit && showEditButton && (
                  <div className={styles.editWrapper}>
                    <Button.Link size='Regular' onClick={handleEditClick} testNameSpace='edit-profile'>
                      {t('common:edit')}
                    </Button.Link>
                  </div>
                )}
              </div>
              <div className={styles.variableColumn}>
                {renderLink()}
                {/* @ts-ignore: OPERATION BLEED STOPPER */}
                {!hideSubheader && header?.length > 0 && !allowLogout && (
                  <Text renderAs='span' color='Gray50'>
                    {defaultHeader}
                  </Text>
                )}
              </div>
            </Text>
          </div>
        </div>
      </MaskedElement>
    </Grid>
  )
}
