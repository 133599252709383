import {
  DocumentVerificationResult,
  IdentityVerificationDocument,
  IdentityDocumentVerificationStatus,
  TransformedIdentityVerificationDocument,
} from '@afterpaytouch/portal-api/identity/types'

export const toVerifiedDocument = (documentVerificationResults: DocumentVerificationResult[]): IdentityVerificationDocument[] =>
  documentVerificationResults.reduce((acc, item) => (item.status === IdentityDocumentVerificationStatus.VERIFIED ? [...acc, item.type] : [...acc]), [])

export const transformIdentityDocument = (identityVerificationDocument: IdentityVerificationDocument[]): TransformedIdentityVerificationDocument[] => {
  return identityVerificationDocument.map((doc) =>
    doc === IdentityVerificationDocument.LICENCE
      ? (doc.split('_')[1].toLowerCase() as TransformedIdentityVerificationDocument)
      : (doc.toLowerCase() as TransformedIdentityVerificationDocument)
  )
}
