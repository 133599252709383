import { validateProfileField } from '../../../utils/profile-validation'
import { RegisterOptions } from 'react-hook-form'

import { isValidObject } from '../../../utils/object'

export const validAddressOption = (address, country): boolean => {
  if (!isValidObject(address)) {
    return false
  }

  const addressKeys = Object.keys(address)

  for (const key of addressKeys) {
    const isValidValue = validateProfileField(country, key, address[key])
    if (!isValidValue) {
      return false
    }
  }
  return true
}

export const requiredAddress = ({ name, country, t }): RegisterOptions => {
  return {
    validate: {
      requiredAddressFields: (v) => {
        const hasRequiredAddressFields = validAddressOption(v, country)
        return hasRequiredAddressFields || t('verifyIdentity:error:required', { context: name })
      },
    },
  }
}
