import React, { FunctionComponent } from 'react'
import Script from 'next/script'

import { app } from '../../env'

const { BOUNCER_SRC } = app

export const Bouncer: FunctionComponent = () => {
  return (
    <>
      <Script src={BOUNCER_SRC} strategy='beforeInteractive' />
    </>
  )
}
