import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { ConsumerAppDispatch, ConsumerAppState } from '.'
import { HttpService } from '@afterpaytouch/portal-api'
import { HttpService as TopazHttpService } from '@afterpaytouch/topaz-api'
import { HttpService as ShopHttpService } from '@afterpaytouch/shop-api'
import { isPortalAxiosError, PortalApiError } from '../model'

export interface ConsumerThunkConfig {
  state: ConsumerAppState
  dispatch: ConsumerAppDispatch
  extra: {
    portalApi: () => HttpService
    topaz: () => TopazHttpService
    shop: () => ShopHttpService
  }
}

export interface PortalThunkConfig extends ConsumerThunkConfig {
  rejectValue: PortalApiError
}

export type AsyncAction<T> = ThunkAction<Promise<T>, ConsumerAppState, any, AnyAction>

export type PortalThunk<Request, Response> = AsyncThunk<Response, Request, PortalThunkConfig>

// @TODO: Why is an action creator defined in the types file?
export const portalAction = <Request, Response>(
  name: string,
  creator: AsyncThunkPayloadCreator<Response, Request, PortalThunkConfig>
): PortalThunk<Request, Response> =>
  // @ts-ignore: OPERATION BLEED STOPPER
  createAsyncThunk<Response, Request, PortalThunkConfig>(name, async (args, thunk) => {
    try {
      return await creator(args, thunk)
    } catch (e) {
      if (isPortalAxiosError(e)) {
        // @ts-ignore: OPERATION BLEED STOPPER
        return thunk.rejectWithValue(e.response.data)
      } else {
        throw e
      }
    }
  })
