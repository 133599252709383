import { createSelector } from '@reduxjs/toolkit'
import { authService } from '@afterpaytouch/portal-api/consumer/auth'

import { useAppSelector, AuthenticationState, ConsumerAppSelector, useAppDispatch, clearState } from '../../'
import { Authentication, FingerPrint, FingerprintHeader } from '../../../model'
import { PasswordResetTwoFactorChallenge } from '@afterpaytouch/portal-api/consumer/auth/types'

// /* Selectors */

export const authenticationStateSelector: ConsumerAppSelector<AuthenticationState> = (state) => state.auth.user
export const authenticationSelector: ConsumerAppSelector<Authentication> = createSelector(authenticationStateSelector, (state) => state.status)

// @ts-ignore: OPERATION BLEED STOPPER
export const fingerprintSelector: ConsumerAppSelector<FingerPrint> = createSelector(authenticationStateSelector, (state) => state.fingerprint)

// @ts-ignore: OPERATION BLEED STOPPER
export const fingerprintHeader: ConsumerAppSelector<FingerprintHeader> = createSelector(fingerprintSelector, (fingerprint) => ({
  'X-Device-Fingerprint': JSON.stringify(fingerprint),
}))

export const fingerprintHeaderValue: ConsumerAppSelector<string | undefined> = createSelector(fingerprintSelector, (fingerprint) =>
  fingerprint !== null ? JSON.stringify(fingerprint) : undefined
)

// @ts-ignore: OPERATION BLEED STOPPER
export const profilingIdSelector: ConsumerAppSelector<string> = createSelector(authenticationStateSelector, (state) => state?.profilingId)

/* React Hooks */

export const useIsConsumerAuthenticated = (): boolean => useAppSelector((state) => state.auth.user.status.authenticated)
export const useAuthentication = (): Authentication => useAppSelector(authenticationSelector)

export const useProfilingId = (): string => useAppSelector(profilingIdSelector)

export const useLogout = (): (() => void) => {
  const dispatch = useAppDispatch()
  return () => {
    dispatch(clearState())
  }
}

export const usePasswordResetVerifyCode = (): string => useAppSelector((state) => state.auth.user.passwordResetVerifyCode)
export const usePasswordResetFactorChallenges = (): PasswordResetTwoFactorChallenge[] => useAppSelector((state) => state.auth.user.passwordResetChallenges)

export const {
  useAuthLoginMutation,
  useAuthCheckChallengeMutation,
  useAuthCheckCodeMutation,
  useAuthLogoutEndpointMutation,
  useAuthResetCodeMutation,
  useAuthResetMutation,
  useGet2faCodeMutation,
  useCheck2faCodeMutation,
  endpoints: { authLogin, authLogoutEndpoint, authCheckChallenge, authCheckCode, authReset, authResetCode },
} = authService
