import { Button, Icon, IconNames, Text, useIsBreakpointMediumAndUp } from '@afterpaytouch/core'
import { useFlag } from '../../hooks'
import clsx, { ClassValue } from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { NextRouter, useRouter } from 'next/router'
import React, { FunctionComponent, HTMLAttributeAnchorTarget, ReactNode, useRef, useState } from 'react'
import { useRootClose } from 'react-overlays'
import { Route } from '../../config/router'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import styles from './style.module.scss'
import { getIsAppRoute } from '../../utils/url'
import { useConsumerHasCreditFacility, useLogout } from '../../state'
import { AmplitudeEventTypesProperties } from '../../integrations/amplitude/types'
import { isCallable } from '@afterpay/utils'

const I18N_NAMESPACE = ['common']

interface NavigationProps {
  onMenuToggle?: () => void
  menuActive?: boolean
}

export interface INavigationItemProps {
  text: string
  testId: string
  // it can be internal link or external link
  route?: Route[] | string
  target?: HTMLAttributeAnchorTarget
  icon?: IconNames
  trackingEvent?: string
  subItems?: INavigationItemProps[]
  disabled?: boolean
  onClick?: () => void
}

interface MenuToggleProps {
  handleToggleMenu: () => void
}

interface NavigationMenuWithToggleProps {
  items: INavigationItemProps[]
  onMenuToggle: () => void
  menuActive: boolean
}

interface Selectable {
  onSelect?: () => void
}

interface NavigationMenuProps extends Selectable {
  items: INavigationItemProps[]
}

interface NavigationWithSubMenuProps extends Selectable {
  itemWithSubMenu: INavigationItemProps
}

export const NavigationMenu: FunctionComponent<NavigationMenuProps> = ({ items, onSelect }) => {
  const router: NextRouter = useRouter()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  return (
    <ul className={styles.list}>
      {items.map((item) =>
        item?.subItems != null ? (
          <NavigationItemWithSubMenu key={item.testId} itemWithSubMenu={item} onSelect={onSelect}></NavigationItemWithSubMenu>
        ) : (
          !item.disabled && (
            <NavigationItem
              key={item.testId}
              {...item}
              onSelect={() => {
                if (isCallable(item.onClick)) {
                  item.onClick()
                }
                if (isCallable(onSelect)) {
                  onSelect()
                }
                // @ts-expect-error TODO: fix type error
                item.trackingEvent != null && logEvent(item.trackingEvent, { outboundLink: router.pathname })
              }}
            />
          )
        )
      )}
    </ul>
  )
}

const NavigationItem: FunctionComponent<INavigationItemProps & Selectable> = ({ text, testId, icon, route, target = '_self', onSelect, disabled = false }) => {
  const isAppRoute = Array.isArray(route) && getIsAppRoute(route[0])
  const router: NextRouter = useRouter()
  const isSelectedRoute = isAppRoute && route?.includes(router.route as Route)
  const classNames: ClassValue = clsx(styles.item, isSelectedRoute && [styles.selectedRoute, 'bg-primary'], disabled && styles.disabled)

  const renderLinkItem = (): ReactNode => {
    const item = (
      <div>
        <span className={styles.icon}>
          <Icon name={icon} size='Small' />
        </span>
        <Text size='S' bold renderAs='span'>
          {text}
        </Text>
      </div>
    )
    if (disabled) {
      return item
    }
    // app link
    if (isAppRoute) {
      return (
        // @ts-ignore: OPERATION BLEED STOPPER
        <Link href={route[0]} target={target} data-testid={`${testId}-a`}>
          {item}
        </Link>
      )
    }
    // external link, eg. shop page, help page
    else if (typeof route === 'string') {
      return (
        <a target={target} href={route} data-testid={`${testId}-a`}>
          {item}
        </a>
      )
    }
    // button, eg. switch to old portal, logout
    else {
      return item
    }
  }
  return (
    // <li data-testid={testId} className='bg-primary bg-unicorn-primary' onClick={onSelect}>
    <li data-testid={testId} className={classNames} onClick={onSelect}>
      {renderLinkItem()}
    </li>
  )
}

const NavigationItemWithSubMenu: FunctionComponent<NavigationWithSubMenuProps> = ({ itemWithSubMenu, onSelect }) => {
  const router: NextRouter = useRouter()
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const [collapsed, setCollapsed] = useState(false)
  const iconRender = collapsed ? (
    <Icon.CaretUp title={t('common:navigation:caretLabels:hide')} size='ExtraSmall' color='Black' role='img' />
  ) : (
    <Icon.CaretDown title={t('common:navigation:caretLabels:display')} size='ExtraSmall' color='Black' role='img' />
  )

  return (
    <ul className={clsx(styles.list, styles.subList)}>
      <li key={itemWithSubMenu.testId} data-testid={itemWithSubMenu.testId} className={styles.itemWithSub} onClick={() => setCollapsed(!collapsed)}>
        <div>
          <span className={styles.icon}>
            <Icon name={itemWithSubMenu.icon} size='Small' />
          </span>
          <Text size='S' bold renderAs='span'>
            {itemWithSubMenu.text}
          </Text>
          <span className={clsx(styles.icon, 'float-right')}>{iconRender}</span>
        </div>
      </li>
      {/* @ts-ignore: OPERATION BLEED STOPPER */}
      {itemWithSubMenu.subItems.map((item) => {
        const classNames: ClassValue = clsx(
          styles.item,
          styles.subItem,
          item.route?.includes(router.route as Route) && styles.selectedRoute,
          collapsed && styles.hide
        )
        return (
          !item.disabled && (
            <li
              key={item.testId}
              data-testid={item.testId}
              className={classNames}
              onClick={() => {
                if (isCallable(onSelect)) {
                  onSelect()
                }
                item.trackingEvent != null && logEvent(item.trackingEvent as keyof AmplitudeEventTypesProperties, { outboundLink: router.pathname })
              }}
            >
              {/* @ts-ignore: OPERATION BLEED STOPPER */}
              <Link href={item.route} target={item.target} data-testid={`${item.testId}-a`}>
                <div>
                  <Text size='S' bold renderAs='span'>
                    {item.text}
                  </Text>
                </div>
              </Link>
            </li>
          )
        )
      })}
    </ul>
  )
}

const MenuToggle: FunctionComponent<MenuToggleProps> = ({ handleToggleMenu }): JSX.Element => {
  const { t } = useTranslation(I18N_NAMESPACE)
  return (
    <Button.Link onClick={() => handleToggleMenu()} testNameSpace='menu-toggle' aria-label='Toggle'>
      <Icon.Menu size='Medium' title={t('common:toggleMenu')} />
    </Button.Link>
  )
}

const NavigationMenuWithToggle: FunctionComponent<NavigationMenuWithToggleProps> = ({ items, onMenuToggle = () => {}, menuActive }): JSX.Element => {
  const overlayRef = useRef()

  useRootClose(
    // @ts-ignore: OPERATION BLEED STOPPER
    overlayRef,
    () => {
      onMenuToggle()
    },
    {
      disabled: !menuActive,
    }
  )

  return (
    <>
      <MenuToggle handleToggleMenu={onMenuToggle} />
      {menuActive && (
        // @ts-ignore: OPERATION BLEED STOPPER
        <div ref={overlayRef}>
          <NavigationMenu items={items} onSelect={onMenuToggle} />
        </div>
      )}
    </>
  )
}

export const Navigation: FunctionComponent<NavigationProps> = ({ onMenuToggle, menuActive }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isBpMediumUp = useIsBreakpointMediumAndUp()
  const classNames: ClassValue = clsx(styles.nav, isBpMediumUp && styles.bpMediumUp)
  const isPayMonthlyEnabled = useFlag('consumer-portal-pay-monthly-enabled', false)

  const hasCreditFacility = useConsumerHasCreditFacility()

  const isPayMonthlyNavigationItemEnabled = isPayMonthlyEnabled && hasCreditFacility
  const logout = useLogout()

  const items: INavigationItemProps[] = [
    {
      text: t('common:navigation:home'),
      testId: 'home',
      icon: 'Home',
      route: [Route.HOME],
      trackingEvent: TrackingEvent.CLICKED_HOME,
    },
    {
      text: t('common:navigation:upcomingPayments'),
      testId: 'upcoming-payments',
      icon: 'Calendar',
      route: [Route.UPCOMING_PAYMENTS],
      trackingEvent: TrackingEvent.CLICKED_UPCOMING_PAYMENTS,
    },
    {
      text: t('common:navigation:orders'),
      testId: 'orders',
      icon: 'Order',
      route: [Route.ORDERS],
      trackingEvent: TrackingEvent.CLICKED_ORDERS,
    },
    {
      text: t('common:navigation:payMonthly'),
      testId: 'pay-monthly',
      icon: 'Loan',
      route: [Route.PAY_MONTHLY],
      trackingEvent: TrackingEvent.CLICKED_PAY_MONTHLY,
      disabled: !isPayMonthlyNavigationItemEnabled,
    },
    {
      text: t('common:navigation:recurringPayments'),
      testId: 'recurringPayments',
      icon: 'MoneyTransfer',
      route: [Route.RECURRING_PAYMENTS],
      trackingEvent: TrackingEvent.CLICKED_RECURRING_PAYMENTS,
    },
    {
      text: t('common:navigation:help'),
      testId: 'help',
      icon: 'Chatting',
      route: t('common:urls:helpUrl'),
      target: '_blank',
      trackingEvent: TrackingEvent.CLICKED_HELP,
    },
    {
      text: t('common:navigation:account'),
      testId: 'profile',
      icon: 'Profile',
      route: [Route.ACCOUNT],
      trackingEvent: TrackingEvent.CLICKED_ACCOUNT,
    },
    {
      text: t('common:navigation:shop'),
      testId: 'shop',
      icon: 'Shop',
      route: t('common:urls:afterpaySite'),
      target: '_blank',
      trackingEvent: TrackingEvent.CLICKED_SHOP,
    },
    {
      text: t('common:navigation:logout'),
      testId: 'logout',
      icon: 'Exit',
      onClick: () => {
        logout()
      },
    },
  ]

  return (
    <>
      <nav className={classNames}>
        {isBpMediumUp ? (
          <NavigationMenu items={items} />
        ) : (
          <NavigationMenuWithToggle
            items={items}
            // @ts-ignore: OPERATION BLEED STOPPER
            menuActive={menuActive}
            // @ts-ignore: OPERATION BLEED STOPPER
            onMenuToggle={onMenuToggle}
          />
        )}
      </nav>
    </>
  )
}
