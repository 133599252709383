import { createSelector } from '@reduxjs/toolkit'
import { billingAgreementsService, defaultGetBillingAgreementsParams } from '@afterpaytouch/portal-api/consumer/billingAgreements'
import { Agreement, AgreementStatus, BillingAgreementResponse, BillingAgreementsResponse } from '@afterpaytouch/portal-api/consumer/billingAgreements/types'
import { ConsumerAppSelector, useAppSelector } from '../'

export const {
  useGetBillingAgreementsQuery,
  useGetBillingAgreementQuery,
  useChangeBillingAgreementPaymentMethodMutation,
  useCancelBillingAgreementMutation,
  endpoints: { getBillingAgreements, getBillingAgreement },
} = billingAgreementsService

/* Selectors */

// @TODO: Investigate if this select function requires memorisation for performance reasons
export const billingAgreementsSelector = getBillingAgreements.select(defaultGetBillingAgreementsParams)

// @ts-ignore: OPERATION BLEED STOPPER
export const billingAgreementsDataSelector: ConsumerAppSelector<BillingAgreementsResponse> = createSelector(billingAgreementsSelector, (state) => state.data) // contains data from the slice/query

export const hasBillingAgreement: ConsumerAppSelector<boolean> = createSelector(billingAgreementsDataSelector, (data) => Boolean(data?.results.length))

export const hasActiveBillingAgreement: ConsumerAppSelector<boolean> = createSelector(
  billingAgreementsDataSelector,
  (data) => data?.results?.find((agreement) => agreement.status === AgreementStatus.ACTIVE) !== undefined
)

export const hasInactiveBillingAgreement: ConsumerAppSelector<boolean> = createSelector(
  billingAgreementsDataSelector,
  (data) => data?.results?.find((agreement) => agreement.status !== AgreementStatus.ACTIVE) !== undefined
)

export const activeBillingAgreement: ConsumerAppSelector<Agreement[]> = createSelector(billingAgreementsDataSelector, (data) =>
  data?.results?.filter((agreement) => agreement.status === AgreementStatus.ACTIVE)
)

export const inactiveBillingAgreement: ConsumerAppSelector<Agreement[]> = createSelector(billingAgreementsDataSelector, (data) =>
  data?.results?.filter((agreement) => agreement.status !== AgreementStatus.ACTIVE)
)

export const findBillingAgreementById = (id: number): ConsumerAppSelector<Agreement> =>
  // @ts-ignore: OPERATION BLEED STOPPER
  createSelector([billingAgreementsDataSelector], (data) => data?.results?.find((agreement) => agreement.id === id))

/* React Hooks */

export const useBillingAgreementsDataSelector = (): BillingAgreementsResponse => useAppSelector(billingAgreementsDataSelector)

export const useHasBillingAgreement = (): boolean => useAppSelector(hasBillingAgreement)

export const useHasActiveBillingAgreement = (): boolean => useAppSelector(hasActiveBillingAgreement)

export const useHasInactiveBillingAgreement = (): boolean => useAppSelector(hasInactiveBillingAgreement)

export const useActiveBillingAgreementsDataSelector = (): Agreement[] => useAppSelector(activeBillingAgreement)

export const useInactiveBillingAgreementsDataSelector = (): Agreement[] => useAppSelector(inactiveBillingAgreement)

export const useFindBillingAgreementByIdDataSelector = (id: number): Agreement => useAppSelector(findBillingAgreementById(id))

export const useGetBillingAgreement = (agreementId: number): BillingAgreementResponse => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getBillingAgreement.useQueryState(agreementId)?.data
}
