import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { Button, GridWall, Modal, Text, useDate } from '@afterpaytouch/core'
import { useConsumerSizes } from '../../../utils/responsive'
import { OrderRefundModalContext, OrderRefundSteps } from '../OrderRefundModalContext'
import { OrderRefundNavigation } from '../OrderRefundNavigation'
import { Money } from '../../Money'
import { useRouter } from 'next/router'
import FormBuilder, { FieldType, Schema } from '@afterpaytouch/form'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { isDateAfterOrderDate, isAfterToday } from './utils'
import { useNotifyReturnMutation } from '../../../state'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { useTranslation } from 'next-i18next'

const I18N_NAMESPACE = ['orders']

interface MailReturnDetailsFormSchema {
  itemSent: string
  itemSentInfo: boolean
  dateSent: string
  dateError: boolean
  returnReason: string
  submitError: boolean
  footer: boolean
}

export const MailReturnDetails: FunctionComponent = () => {
  const { setStep, order, setIsLoading } = useContext(OrderRefundModalContext)
  const { merchant, merchantAmount, id, orderDate } = order ?? {}

  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const router = useRouter()
  const locale = router?.locale as SupportedLocale
  const { textSize, smallTextSize } = useConsumerSizes()
  const { formatUnixDate } = useDate({ locale, year: 'numeric', month: 'long' })
  const { getLocalFormat } = useDate({ locale, year: 'numeric', month: '2-digit', day: '2-digit' })
  const localFormat = getLocalFormat()
  const { t } = useTranslation(I18N_NAMESPACE)

  const [notifyReturn, { isError: isNotifyReturnError }] = useNotifyReturnMutation()

  // orderReturnStatus.showSendWarning is false when form opens so the return message is only displayed once the user changes sent status
  const [orderReturnStatus, setOrderReturnStatus] = useState<{ returned: boolean; showSendWarning: boolean }>({ returned: false, showSendWarning: false })
  const [returnForm, setReturnForm] = useState<{ itemSent: string; returnReason: string; dateSent: string }>({
    // @ts-ignore: OPERATION BLEED STOPPER
    itemSent: undefined,
    // @ts-ignore: OPERATION BLEED STOPPER
    returnReason: undefined,
    // @ts-ignore: OPERATION BLEED STOPPER
    dateSent: undefined,
  })
  const [displayDateLocale, setDisplayDateLocale] = useState<boolean>(false)
  const [isDateValid, setIsDateValid] = useState<boolean>(true)
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [isDateTouched, setIsDateTouched] = useState<boolean>(false)
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>('')
  const returnFormChecks =
    returnForm.itemSent !== undefined &&
    returnForm.returnReason !== undefined &&
    (!orderReturnStatus.returned || (orderReturnStatus.returned && isDateValid && isDateTouched))

  const handleFormChange = (form: MailReturnDetailsFormSchema): void => {
    setReturnForm({ ...form })

    if (form.itemSent === 'true') {
      setOrderReturnStatus({ returned: true, showSendWarning: false })
      isDateTouched && setIsDateValid(false)
    } else {
      setOrderReturnStatus({ returned: false, showSendWarning: true })
      setIsDateValid(true)
    }

    if (/202[0-9]-[0-9]{2}-[0-9]{2}/.test(form.dateSent)) {
      setIsDateTouched(true)
      const formDate = new Date(form.dateSent).getTime()
      isDateAfterOrderDate(formDate, orderDate) && !isAfterToday(formDate) ? setIsDateValid(true) : setIsDateValid(false)
    }
  }

  useEffect(() => {
    returnFormChecks ? setIsFormReady(true) : setIsFormReady(false)
  }, [returnFormChecks, setIsFormReady])

  const handleSubmit = (): void => {
    submitReturnRequest()
  }

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event

    if (target?.name === 'dateSent') {
      setDisplayDateLocale(true)
    } else {
      setDisplayDateLocale(false)
    }
  }

  const submitReturnRequest = async (): Promise<void> => {
    try {
      setIsLoading(true)
      await notifyReturn({
        orderId: id,
        body: {
          orderReturned: returnForm.itemSent === 'true',
          returnDate: returnForm.dateSent,
          returnReason: returnForm.returnReason,
        },
      }).unwrap()
      setIsLoading(false)
      setStep(OrderRefundSteps.MailReturnConfirm)
      // @ts-ignore: OPERATION BLEED STOPPER
      logEvent(TrackingEvent.SUBMIT_SUCCESS_FOR_RETURNING_ORDER_BY_MAIL, { locale: router?.locale })
    } catch (err) {
      setIsLoading(false)
      if (err.status === 412) {
        setIsFormReady(false)
      }
      setSubmitErrorMessage(err.data.message ?? t('orders:returningOrderModal:mailReturn:details:submitError'))
      // @ts-ignore: OPERATION BLEED STOPPER
      logEvent(TrackingEvent.SUBMIT_FAILURE_FOR_RETURNING_ORDER_BY_MAIL, { locale: router?.locale })
    }
  }

  const returnReasons = [
    { key: 'PRODUCT_NOT_AS_DESCRIBED', label: t('orders:returningOrderModal:mailReturn:details:returnReasons:described') },
    { key: 'PRODUCT_NEVER_ARRIVED', label: t('orders:returningOrderModal:mailReturn:details:returnReasons:arrived') },
    { key: 'PRODUCT_WAS_FAULTY', label: t('orders:returningOrderModal:mailReturn:details:returnReasons:faulty') },
    { key: 'CHANGE_OF_MIND', label: t('orders:returningOrderModal:mailReturn:details:returnReasons:mind') },
    { key: 'OTHER', label: t('orders:returningOrderModal:mailReturn:details:returnReasons:other') },
  ]

  const schema: Schema<MailReturnDetailsFormSchema> = useMemo(
    () => ({
      itemSent: {
        type: FieldType.Dropdown,
        items: [
          { label: t('orders:returningOrderModal:mailReturn:details:sentOptions:false'), key: 'false' },
          { label: t('orders:returningOrderModal:mailReturn:details:sentOptions:true'), key: 'true' },
        ],
        props: {
          label: t('orders:returningOrderModal:mailReturn:details:sentOptions:label'),
          defaultValue: returnForm.itemSent,
          id: 'item-sent',
        },
        testNameSpace: 'mail-details-modal-order-returned',
      },
      itemSentInfo: {
        type: FieldType.Component,
        Component: () => (
          <div>
            <Text size={smallTextSize} testNameSpace='mail-details-modal-not-returned-info'>
              {t('orders:returningOrderModal:mailReturn:details:postInfo')}
            </Text>
          </div>
        ),
        show: orderReturnStatus.showSendWarning,
      },
      dateSent: {
        type: FieldType.Date,
        props: {
          icon: 'Calendar',
          iconPosition: 'Left',
          label: displayDateLocale ? localFormat : t('orders:returningOrderModal:mailReturn:details:postDate'),
          error: !isDateValid,
        },
        format: localFormat,
        show: orderReturnStatus.returned,
        testNameSpace: 'mail-details-modal-order-return-date',
      },
      dateError: {
        type: FieldType.Component,
        show: !isDateValid && orderReturnStatus.returned,
        Component: () => (
          <>
            <Text size={smallTextSize} color='Fire' testNameSpace='mail-details-modal-incorrect-date'>
              {t('orders:returningOrderModal:mailReturn:details:postDateError')}
            </Text>
          </>
        ),
      },
      returnReason: {
        type: FieldType.Dropdown,
        items: returnReasons.map(({ key, label }) => ({ key, label })),
        props: {
          label: t('orders:returningOrderModal:mailReturn:details:returnReason'),
          defaultValue: returnForm.returnReason,
          id: 'return-reason',
        },
        testNameSpace: 'mail-details-modal-return-reason',
      },
      submitError: {
        type: FieldType.Component,
        show: isNotifyReturnError,
        Component: () => (
          <>
            <Text size={smallTextSize} color='Fire'>
              {submitErrorMessage}
            </Text>
          </>
        ),
      },
      footer: {
        type: FieldType.Component,
        Component: () => (
          <Button.Primary
            padding='Fluid'
            type='submit'
            disabled={!isFormReady}
            testNameSpace='mail-details-modal-mail-return-cta'
            aria-label={t('orders:returningOrderModal:aria:nav:submit')}
          >
            {t('orders:returningOrderModal:mailReturn:details:submitCTA')}
          </Button.Primary>
        ),
      },
    }),
    [
      localFormat,
      isDateValid,
      isFormReady,
      orderReturnStatus,
      isNotifyReturnError,
      returnForm.itemSent,
      returnForm.returnReason,
      smallTextSize,
      displayDateLocale,
      submitErrorMessage,
    ]
  )

  return (
    <>
      <OrderRefundNavigation
        handleBack={() => {
          setStep(OrderRefundSteps.ReturnType)
          setIsDateTouched(false)
        }}
        heading={t('orders:returningOrderModal:mailReturn:details:heading')}
      />
      <Modal.Content>
        <GridWall columns={2} gutter='none'>
          <Text size={textSize} bold>
            {t('orders:returningOrderModal:mailReturn:details:retailer')}
          </Text>
          <Text size={textSize} testNameSpace='mail-details-modal-details-merchant'>
            {merchant?.name}
          </Text>
          <Text size={textSize} bold>
            {t('orders:returningOrderModal:mailReturn:details:orderTotal')}
          </Text>
          <Money size={textSize} value={merchantAmount} testNameSpace='mail-details-modal-details-amount' />
          <Text size={textSize} bold>
            {t('orders:returningOrderModal:mailReturn:details:orderId')}
          </Text>
          <Text size={textSize} testNameSpace='mail-details-modal-details-orderId'>
            {id}
          </Text>
          <Text size={textSize} bold>
            {t('orders:returningOrderModal:mailReturn:details:orderDate')}
          </Text>
          <Text size={textSize} testNameSpace='mail-details-modal-details-date'>
            {formatUnixDate(orderDate)}
          </Text>
        </GridWall>
        <div className='mt-6'>
          <FormBuilder schema={schema} onChange={handleFormChange} onSubmit={handleSubmit} onFocus={handleFocus} formOptions={{ mode: 'onChange' }} />
        </div>
      </Modal.Content>
    </>
  )
}
