import { useEffect } from 'react'

export const useFocusRef = (ref: React.MutableRefObject<HTMLInputElement>, ms: number = 250): void => {
  useEffect(() => {
    const input = ref.current
    let focusTimeout
    if (input != null) {
      focusTimeout = setTimeout(() => input.focus(), ms)
    }
    return () => clearTimeout(focusTimeout)
  }, [ref, ms])
}
