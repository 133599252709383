import React, { useEffect, useMemo, FunctionComponent, useState } from 'react'
import { Modal, Heading, Text, Button, Dropdown, useToast, Divider, Link, ToastOptions, Skeleton, useDate, DateComponent } from '@afterpaytouch/core'
import styles from './style.module.css'
import { Trans, useTranslation } from 'next-i18next'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import getPaymentRescheduleErrorMsg from '../../utils/errorCodeMsg'
import { SupportedLocale } from '../../model'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { OrderResponse } from '@afterpaytouch/portal-api/consumer/ordertransactions/types'
import { useOrderNextPaymentDateSelector, useLazyGetPaymentRescheduleQuery, useSetPaymentRescheduleMutation } from '../../state'
import { useFAQLink } from '../../utils/useFAQLink'
import { TrackingEvent } from '../../model/amplitude'

const I18N_NAMESPACE = ['common', 'orders']

interface ChangePaymentDateProps {
  order: OrderResponse
  locale: SupportedLocale
  closeModal: () => void
}

const PAYMENT_RESCHEDULE_MODAL_TYPES = {
  ERROR: 'error',
  DROP_DOWN: 'dropDown',
  GET_PAYMENT_OPTIONS_LOADING: 'getPaymentOptionsLoading',
  SUBMIT_NEW_PAYMENT_LOADING: 'submitNewPaymentLoading',
}

const initialState = {
  modalType: PAYMENT_RESCHEDULE_MODAL_TYPES.GET_PAYMENT_OPTIONS_LOADING,
  modalData: {
    newPaymentDate: null,
    nextPaymentDate: null,
    paymentScheduleId: null,
  },
}

export const ChangePaymentDate: FunctionComponent<ChangePaymentDateProps> = ({ order, locale, closeModal }) => {
  const { t } = useTranslation(I18N_NAMESPACE)

  const setToast = useToast()
  const [modalType, setModalType] = useState(initialState.modalType)
  const [modalData, setModalData] = useState(initialState.modalData)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const { formatDate } = useDate({ locale, year: 'numeric', month: 'long' })
  const { formatDate: formateDateWithWeekday } = useDate({ locale, year: 'numeric', month: 'long', weekday: 'long' })

  const nextOrderPaymentReschedule = useOrderNextPaymentDateSelector(String(order.id))

  const amplitudeChangePaymentDateFAQs = (orderId: number): void => {
    logEvent(TrackingEvent.CLICKED_CHANGE_PAYMENT_DATE_FAQS, {
      orderId,
    })
  }

  const amplitudeChangePaymentDateConfirm = (orderId: number, originalPaymentDueDate, updatedPaymentDueDate): void => {
    logEvent(TrackingEvent.CLICKED_CONFIRM_PAYMENT_DATE_CHANGE, {
      orderId,
      originalPaymentDueDate,
      updatedPaymentDueDate,
    })
  }

  const amplitudeViewChangePaymentDateError = (errorCode, errorReason): void => {
    logEvent(TrackingEvent.VIEWED_CHANGE_PAYMENT_DATE_ERROR, {
      errorCode,
      errorReason,
    })
  }

  useEffect(() => {
    if (nextOrderPaymentReschedule != null) {
      setModalData({
        ...modalData,
        // @ts-ignore: OPERATION BLEED STOPPER
        paymentScheduleId: nextOrderPaymentReschedule.id.toString(),
        // @ts-ignore: OPERATION BLEED STOPPER
        nextPaymentDate: nextOrderPaymentReschedule.originalInstallmentDueDate,
      })
    }
  }, [nextOrderPaymentReschedule])

  const [
    getPaymentRescheduleQuery,
    {
      data: getPaymentRescheduleData,
      isLoading: isGetPaymentRescheduleLoading,
      isSuccess: isGetPaymentRescheduleSuccess,
      isError: isGetPaymentRescheduleError,
      error: getPaymentRescheduleError,
    },
  ] = useLazyGetPaymentRescheduleQuery()

  useEffect(() => {
    if (modalData.paymentScheduleId) {
      // When the getPaymentRescheduleQuery function returned from a LazyQuery, it always initiates a new request to the server even if there is cached data. Set preferCacheValue(the second argument to the function) as true if you want it to use cache.
      // Since we have a type error with RTK 1.70, the second parameter is removed for now, which means we couldn't leverage the cache management feature of lazy query of RTK right now
      getPaymentRescheduleQuery({ params: { paymentScheduleId: modalData.paymentScheduleId } })
    }
  }, [modalData.paymentScheduleId])

  const [
    setPaymentRescheduleMutation,
    {
      isError: isSetPaymentRescheduleError,
      isLoading: isSetPaymentRescheduleLoading,
      error: setPaymentRescheduleError,
      isSuccess: isSetPaymentScheduleSuccess,
    },
  ] = useSetPaymentRescheduleMutation()
  const faqLink = useFAQLink(locale)

  const getToastOptions = useMemo((): ToastOptions => {
    return {
      message: `${t('orders:orderPaymentReschedule:successToastMsg')} ${formatDate(modalData.newPaymentDate)}`,
      kind: 'Success',
      manualDismiss: true,
      toastTime: 2000,
      testNameSpace: 'change-next-payment-date-success',
    }
  }, [locale, modalData.newPaymentDate])

  const submitPaymentReschedule = (): void => {
    amplitudeChangePaymentDateConfirm(order.id, modalData.nextPaymentDate, modalData.newPaymentDate)
    setPaymentRescheduleMutation({
      params: {
        // @ts-ignore: OPERATION BLEED STOPPER
        paymentScheduleId: modalData.paymentScheduleId,
      },
      // @ts-ignore: OPERATION BLEED STOPPER
      body: { newPaymentDate: modalData.newPaymentDate },
      cache: {
        invalidatesTags: [{ type: 'OrderTransactions', id: order.id }],
      },
    })
  }

  const closePaymentRescheduleModal = (): void => {
    closeModal()
    setModalData({ ...modalData, newPaymentDate: null })
    setModalType(PAYMENT_RESCHEDULE_MODAL_TYPES.DROP_DOWN)
  }

  const paymentRescheduleDescText = (): JSX.Element => {
    if (modalData.nextPaymentDate) {
      const paymentDueDate = <DateComponent date={modalData.nextPaymentDate} locale={locale} year='numeric' month='long' />
      const paymentRescheduleFaqLink = (
        <Link href={faqLink} target='_blank' kind='Underline' onClick={() => amplitudeChangePaymentDateFAQs(order.id)} testNameSpace='faq-link' />
      )
      return (
        <Text>
          <Trans components={{ paymentDueDate, paymentRescheduleFaqLink }} i18nKey='orders:orderPaymentReschedule:desc' />
        </Text>
      )
    } else {
      return <></>
    }
  }

  const renderErrorTypeModal = (): JSX.Element => (
    <div>
      {isGetPaymentRescheduleError
        ? getPaymentRescheduleErrorMsg(faqLink, t, ((getPaymentRescheduleError as FetchBaseQueryError)?.data as any)?.errorCode)
        : getPaymentRescheduleErrorMsg(faqLink, t, ((setPaymentRescheduleError as FetchBaseQueryError)?.data as any)?.errorCode)}
      <br />
      <Button
        kind='Primary'
        padding='Fluid'
        onClick={() => {
          closePaymentRescheduleModal()
        }}
      >
        {t('orders:orderPaymentReschedule:closeButton')}
      </Button>
    </div>
  )

  const renderDropdownModal = (): JSX.Element => (
    <>
      {paymentRescheduleDescText()}
      <div className={styles.dropDownList}>
        <Dropdown
          id='paymentDate'
          label={t('orders:orderPaymentReschedule:selectDate')}
          // @ts-ignore: OPERATION BLEED STOPPER
          onSelect={(value) => setModalData({ ...modalData, newPaymentDate: value })}
        >
          {!isGetPaymentRescheduleLoading &&
            getPaymentRescheduleData != null &&
            getPaymentRescheduleData.newPaymentDateOptions.map((dateOption, index) => (
              <Dropdown.MenuItem value={dateOption} key={index}>
                {formateDateWithWeekday(dateOption)}
              </Dropdown.MenuItem>
            ))}
        </Dropdown>
      </div>
      <Button
        kind='Primary'
        padding='Fluid'
        testNameSpace='submit-reschedule'
        onClick={() => {
          submitPaymentReschedule()
        }}
        disabled={modalData?.newPaymentDate === null}
      >
        {modalType === PAYMENT_RESCHEDULE_MODAL_TYPES.SUBMIT_NEW_PAYMENT_LOADING ? (
          <div className={styles.skeleton}>
            <Skeleton kind='Primary' />
          </div>
        ) : (
          t('orders:orderPaymentReschedule:confirmButton')
        )}
      </Button>
    </>
  )

  useEffect(() => {
    if (isSetPaymentScheduleSuccess) {
      setToast(getToastOptions)
      closePaymentRescheduleModal()
    }
    if (isSetPaymentRescheduleError) {
      setModalType(PAYMENT_RESCHEDULE_MODAL_TYPES.ERROR)
    }
  }, [isSetPaymentScheduleSuccess, isSetPaymentRescheduleError])

  useEffect(() => {
    if (isGetPaymentRescheduleSuccess) {
      setModalType(PAYMENT_RESCHEDULE_MODAL_TYPES.DROP_DOWN)
    }

    if (isGetPaymentRescheduleError) {
      setModalType(PAYMENT_RESCHEDULE_MODAL_TYPES.ERROR)
      amplitudeViewChangePaymentDateError(
        (getPaymentRescheduleError as FetchBaseQueryError)?.status as any,
        ((getPaymentRescheduleError as FetchBaseQueryError)?.data as any)?.errorCode
      )
    }
  }, [isGetPaymentRescheduleError, isGetPaymentRescheduleSuccess])

  useEffect(() => {
    if (isGetPaymentRescheduleLoading) {
      setModalType(PAYMENT_RESCHEDULE_MODAL_TYPES.GET_PAYMENT_OPTIONS_LOADING)
    }
  }, [isGetPaymentRescheduleLoading])

  useEffect(() => {
    if (isSetPaymentRescheduleLoading) {
      setModalType(PAYMENT_RESCHEDULE_MODAL_TYPES.SUBMIT_NEW_PAYMENT_LOADING)
    }
  }, [isSetPaymentRescheduleLoading])

  const showErrorTypeModal = modalType === PAYMENT_RESCHEDULE_MODAL_TYPES.ERROR

  return (
    <>
      <Modal
        show={true}
        onClose={() => closePaymentRescheduleModal()}
        onHide={() => closePaymentRescheduleModal()}
        onBackdropClick={() => closePaymentRescheduleModal()}
        loading={modalType === PAYMENT_RESCHEDULE_MODAL_TYPES.GET_PAYMENT_OPTIONS_LOADING}
      >
        <Modal.Header>
          <div className={styles.modalHeader}>
            <Heading>{showErrorTypeModal ? t('orders:orderPaymentReschedule:errorMsgHeader') : t('orders:orderPaymentReschedule:header')}</Heading>
          </div>
        </Modal.Header>
        <Divider kind={'Hairline'} />
        <Modal.Content>{showErrorTypeModal ? renderErrorTypeModal() : renderDropdownModal()}</Modal.Content>
      </Modal>
    </>
  )
}
