import React, { FunctionComponent, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Heading, Modal, Text, Icon } from '@afterpaytouch/core'
import { CloseAccountFlowContext, CloseAccountFlowSteps } from '../CloseAccountFlowContext'
import { CloseAccountReasons } from '@afterpaytouch/portal-api/consumer/account/types'
import { useConsumerSizes } from '../../../utils/responsive'
import { NewTabLink } from '../../NewTabLink'
import { TrackOnMount, useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

const I18N_NAMESPACE = ['common', 'account']

export const SelectCloseAccountOption: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const { closeAccountReason, setStep } = useContext(CloseAccountFlowContext)

  const handleClickCloseAccountOptions = (): void => {
    logEvent(TrackingEvent.PRESSED_NO_CLOSE_MY_ACCOUNT)
    setStep(CloseAccountFlowSteps.ConfirmCloseAccount)
  }
  const { subSectionHeadingSize } = useConsumerSizes()
  const testNameSpace = 'select-close-account-options'

  const handleBack = (): void => {
    setStep(CloseAccountFlowSteps.SelectCloseAccountReason)
  }

  const deactivateAccountButtonClassName =
    'mb-2 flex w-full flex-row items-center bg-transparent px-4 text-left align-middle hover:cursor-pointer border-0' +
    (closeAccountReason === CloseAccountReasons.BreakFromAfterpay ? ' border-dark-standard border-b border-solid ' : '')
  return (
    <>
      <Modal.Header divider>
        <div className='flex'>
          <Button.Link aria-label={t('ach:navigation:back')} onClick={handleBack} testNameSpace='close-account-back-to-previous-step'>
            <Icon.CaretLeft />
          </Button.Link>
          <Heading size='M'>{t('account:closeAccount:closeAccountOptions:header')}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Text>{t('account:closeAccount:closeAccountOptions:description')}</Text>
        <div className='flex flex-col content-around'>
          <div className='my-4'>
            <div className='border-dark-subtle w-full rounded-sm border border-solid'>
              <button
                className={deactivateAccountButtonClassName}
                data-testid={`${testNameSpace}-deactivate-account-button`}
                onClick={() => {
                  logEvent(TrackingEvent.SELECTED_DEACTIVATE_ACCOUNT, { route: closeAccountReason })
                  setStep(CloseAccountFlowSteps.DeactivateAccount)
                }}
              >
                <p className='flex flex-col text-sm'>
                  <Text size={subSectionHeadingSize} bold>
                    {t('account:closeAccount:closeAccountOptions:option:title:0')}
                  </Text>
                  <Text color='Gray50'>{t('account:closeAccount:closeAccountOptions:option:description:0')}</Text>
                </p>
                <span className='ml-2 flex'>
                  <Icon.CaretRight color='Gray50' size='ExtraSmall' />
                </span>
              </button>
              {closeAccountReason === CloseAccountReasons.BreakFromAfterpay && (
                <NewTabLink
                  onClick={() => {
                    logEvent(TrackingEvent.SELECTED_REDUCE_SPENDING_LIMIT)
                  }}
                  href={t('common:urls:reduceSpendLimitUrl')}
                  testNameSpace={`${testNameSpace}-reduce-limit-link`}
                >
                  <span className='border-dark-standard bg-0 flex w-full flex-row items-center border-0 bg-transparent px-4 pb-3 text-left align-middle no-underline hover:cursor-pointer'>
                    <span className='flex flex-col text-sm'>
                      <Text size={subSectionHeadingSize} bold color='Black'>
                        {t('account:closeAccount:closeAccountOptions:option:title:1')}
                      </Text>
                      <Text color='Gray50'>{t('account:closeAccount:closeAccountOptions:option:description:1')}</Text>
                    </span>
                    <div className='ml-2 flex'>
                      <Icon.CaretRight color='Gray50' size='ExtraSmall' />
                    </div>
                  </span>
                </NewTabLink>
              )}
            </div>
          </div>
          <Button onClick={handleClickCloseAccountOptions} testNameSpace={testNameSpace} kind='Error'>
            {t('account:closeAccount:closeAccountOptions:cta')}
          </Button>
        </div>
        <TrackOnMount eventName={TrackingEvent.VIEWED_BEFORE_YOU_GO} />
      </Modal.Content>
    </>
  )
}
