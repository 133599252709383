import { Country } from '@afterpay/types'
import { useFlag as useFeatureFlag } from '@afterpaytouch/feature'
import { SupportedLocale } from '@afterpaytouch/portal-api'

export const LaunchDarklyFlags = {
  AchEligibilityEnabled: 'ach-eligibility-enabled',
  BillingAddressDetails: 'billing-address-details',
  BillingAgreementAchPaymentMethodEnabled: 'billing-agreement-ach-payment-method-enabled',
  CardScanningNewConsumerPortalAddPaymentEnabled: 'card-scanning-new-consumer-portal-add-payment-enabled',
  CardScanningNewConsumerPortalCustomPaymentEnabled: 'card-scanning-new-consumer-portal-custom-payment-enabled',
  ChangePaymentDateForConsumerLendingEnabled: 'change-payment-date-for-consumer-lending-enabled',
  ConsumerPortalAvailableToSpendDisabled: 'consumer-portal-available-to-spend-disabled',
  ConsumerPortalHardshipAccountPaymentsEnabled: 'consumer-portal-hardship-account-payments-enabled',
  ConsumerPortalHeaderLendingEnabled: 'consumer-portal-header-lending-enabled',
  ConsumerPortalHelpButtonEnabled: 'consumer-portal-help-button-enabled',
  ConsumerPortalIsApplePayDisabled: 'consumer-portal-is-apple-pay-disabled',
  ConsumerPortalIdentityVerificationDisabled: 'consumer-portal-identity-verification-disabled',
  ConsumerPortalModalLendingEnabled: 'consumer-portal-modal-lending-enabled',
  ConsumerPortalMonthlyPaymentsLendingEnabled: 'consumer-portal-monthly-payments-lending-enabled',
  ConsumerPortalPayMonthlyEnabled: 'consumer-portal-pay-monthly-enabled',
  ConsumerPortalOrderDetailV2Enabled: 'consumer-portal-order-detail-v2-enabled',
  ConsumerPortalOrderEnableDisputeEnabled: 'consumer-portal-order-enable-dispute',
  ConsumerPortalPpaLinkDisplay: 'consumer-portal-ppa-link-display',
  ConsumerPortalPrescreenedOffersOptOutEnabled: 'consumer-portal-prescreened-offers-opt-out-enabled',
  ConsumerPortalPulseEnabled: 'consumer-portal-pulse-enabled',
  ConsumerPortalStorePanelEnabled: 'consumer-portal-store-panel-enabled',
  ConsumerPortalThreeDSEnabled: 'consumer-portal-three-ds-enabled',
  ConsumerPortalPreferencesDisabled: 'consumer-portal-preferences-disabled',
  ConsumerPortalWelcomeModalEnabled: 'consumer-portal-welcome-modal-enabled',
  ConsumerPortalAutocompleteInputAddressEnabled: 'consumer-portal-autocomplete-input-address-on-edit-profile-enabled',
  ConsumerPortalGetHelpRedirectFritesUsers: 'consumer-portal-get-help-redirect-frites-users',
  ConsumerPortalHideCardHolderName: 'consumer-portal-hide-card-holder-name',
  ConsumerPortalOrderPageFilterChipsEnabled: 'consumer-portal-order-page-filter-chips-enabled',
  ConsumerPortalRevokeConsentEnabled: 'consumer-portal-revoke-consent-enabled',
  ConsumerPortalArcadeTheme: 'rocketship.consumer-portal-arcade-theme',
  DisableChangePaymentDateButton: 'disable-change-payment-date-button',
  InviteAFriendPanelNewConsumerPortal: 'invite-a-friend-panel-new-consumer-portal',
  IrChargebackInfoEnabled: 'ir-chargeback-info-enabled',
  IrDisputeCreateButtonEnabledNew: 'ir-dispute-create-button-enabled-new',
  IrDisputeCreationMerchantContactInfoEnabled: 'ir-dispute-creation-merchant-contact-info-enabled', // archived flag
  IrDisputeTrackingConsumerPortalEnabled: 'ir-dispute-tracking-consumer-portal-enabled',
  IrReportLostAndStolenCardNewConsumerButtonEnabled: 'ir-report-lost-and-stolen-card-new-consumer-button-enabled',
  IrDisputePermissionControlEnabled: 'ir-dispute-permission-control-enabled',
  IrOrderMismatchFlowEnabled: 'ir-order-mismatch-flow-enabled',
  IrPushBackDisputeConsumerEnabled: 'ir-push-back-dispute-consumer-enabled',
  MaintenanceMode: 'maintenance-mode',
  MaintenanceModeText: 'maintenance-mode-text',
  SingleUsePaymentFinalizedOrderDelay: 'single-use-payment-finalized-order-delay',
  CloseOrReactivateAccountFlow: 'close-or-reactivate-account-flow-enabled',
  PayInThreeEnabled: 'pay-in-three-enabled',
  PortalAlignPayday: 'portal-align-payday-enabled',
  PpaDocsEndpointDrivenEnabled: 'paylater.enable-ppa-docs-endpoint-driven',
  EnableRemoveCashAppPay: 'enable-remove-cash-app-pay',
  GravatarEnabled: 'gravatar-enabled',
  ConsumerPortalPayMonthlyDecreaseLimit: 'consumer-portal-pay-monthly-decrease-limit-enabled',
  ConsumerPortalPayMonthlyClose: 'consumer-portal-pay-monthly-close-credit-facility-enabled',
  ConsumerPortalMobileAppDownloadPanelEnabled: 'consumer-portal-mobile-app-download-panel-enabled',
  ConsumerPortalTerminationNoticeModalCountryList: 'consumer-portal-termination-notice-modal-country-list',
  IsAmexSoftCloseEnabled: 'amex-soft-close-enabled',
  AccountClosure: 'consumer-portal-account-close',
  AutopayToggleEnabled: 'paylater.autopay-toggle-enabled',
  RemoveAlignPayDay: 'remove-align-pay-day',
  EnableMpanApplePayFlow: 'rocketship.enable-mpan-apple-pay-flow',
  BlockChaseCCEnabled: 'rocketship.block-chase-cc-enabled',
  LoginMigration: 'consumer-portal-login-migration-variant',
  BlockSignUpFromFRITES: 'rocketship.block-sign-up-from-FRITES',
  REGISTRATION_STATE_VALIDATION: 'rocketship.enable-registration-state-validation',
  SOFT_ID_VERIFICATION_CHECK_ENABLED: 'soft-identifier-verification-check-enabled',
  UK_MRKT_REGS_FCA: 'uk-mrkt-regs-fca',
  ENABLE_DOB_INFO: 'enable-dob-info',
  VerifyMobilesLookupAuthz: 'rocketship.portal.verify-mobiles_lookup-authz',
  VerifyMobilesLookupAction: 'rocketship.portal.verify-mobiles_lookup-action',
} as const
export type LaunchDarklyFlag = (typeof LaunchDarklyFlags)[keyof typeof LaunchDarklyFlags]

export interface LaunchDarklyFlagsValues {
  [LaunchDarklyFlags.AchEligibilityEnabled]: boolean
  [LaunchDarklyFlags.BillingAddressDetails]: boolean
  [LaunchDarklyFlags.BillingAgreementAchPaymentMethodEnabled]: boolean
  [LaunchDarklyFlags.CardScanningNewConsumerPortalAddPaymentEnabled]: boolean
  [LaunchDarklyFlags.CardScanningNewConsumerPortalCustomPaymentEnabled]: boolean
  [LaunchDarklyFlags.ChangePaymentDateForConsumerLendingEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalAvailableToSpendDisabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalHardshipAccountPaymentsEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalHeaderLendingEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalHelpButtonEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalIsApplePayDisabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalIdentityVerificationDisabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalModalLendingEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalMonthlyPaymentsLendingEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalPayMonthlyEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalOrderDetailV2Enabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalOrderEnableDisputeEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalPpaLinkDisplay]: boolean
  [LaunchDarklyFlags.ConsumerPortalPrescreenedOffersOptOutEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalPulseEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalStorePanelEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalThreeDSEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalPreferencesDisabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalWelcomeModalEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalAutocompleteInputAddressEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalGetHelpRedirectFritesUsers]: boolean
  [LaunchDarklyFlags.ConsumerPortalHideCardHolderName]: boolean
  [LaunchDarklyFlags.ConsumerPortalOrderPageFilterChipsEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalRevokeConsentEnabled]: boolean
  [LaunchDarklyFlags.DisableChangePaymentDateButton]: boolean
  [LaunchDarklyFlags.InviteAFriendPanelNewConsumerPortal]: boolean
  [LaunchDarklyFlags.IrDisputeCreateButtonEnabledNew]: boolean
  [LaunchDarklyFlags.IrDisputeTrackingConsumerPortalEnabled]: boolean
  [LaunchDarklyFlags.IrReportLostAndStolenCardNewConsumerButtonEnabled]: boolean
  [LaunchDarklyFlags.IrDisputePermissionControlEnabled]: boolean
  [LaunchDarklyFlags.IrChargebackInfoEnabled]: boolean
  [LaunchDarklyFlags.IrOrderMismatchFlowEnabled]: boolean
  [LaunchDarklyFlags.IrPushBackDisputeConsumerEnabled]: boolean
  [LaunchDarklyFlags.MaintenanceModeText]: string
  [LaunchDarklyFlags.MaintenanceMode]: boolean
  [LaunchDarklyFlags.CloseOrReactivateAccountFlow]: boolean
  [LaunchDarklyFlags.SingleUsePaymentFinalizedOrderDelay]: {
    merchants: Array<{
      id: number
      pendingDuration: number
    }>
  }
  [LaunchDarklyFlags.PayInThreeEnabled]: boolean
  [LaunchDarklyFlags.PortalAlignPayday]: boolean
  [LaunchDarklyFlags.PpaDocsEndpointDrivenEnabled]: boolean
  [LaunchDarklyFlags.GravatarEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalPayMonthlyDecreaseLimit]: boolean
  [LaunchDarklyFlags.ConsumerPortalPayMonthlyClose]: boolean
  [LaunchDarklyFlags.ConsumerPortalMobileAppDownloadPanelEnabled]: boolean
  [LaunchDarklyFlags.ConsumerPortalTerminationNoticeModalCountryList]: {
    countries: Country[]
    locales: SupportedLocale[]
  }
  [LaunchDarklyFlags.IsAmexSoftCloseEnabled]: boolean
  [LaunchDarklyFlags.AccountClosure]: boolean
  [LaunchDarklyFlags.AutopayToggleEnabled]: boolean
  [LaunchDarklyFlags.RemoveAlignPayDay]: boolean
  [LaunchDarklyFlags.EnableMpanApplePayFlow]: boolean
  [LaunchDarklyFlags.BlockChaseCCEnabled]: boolean
  [LaunchDarklyFlags.LoginMigration]: string
  [LaunchDarklyFlags.BlockSignUpFromFRITES]: boolean
  [LaunchDarklyFlags.REGISTRATION_STATE_VALIDATION]: boolean
  [LaunchDarklyFlags.SOFT_ID_VERIFICATION_CHECK_ENABLED]: boolean
  [LaunchDarklyFlags.UK_MRKT_REGS_FCA]: boolean
  [LaunchDarklyFlags.ENABLE_DOB_INFO]: boolean
}

export function useFlag(flag: LaunchDarklyFlag): boolean
export function useFlag<LaunchDarklyFlag extends keyof LaunchDarklyFlagsValues>(
  flag: LaunchDarklyFlag,
  defaultValue: LaunchDarklyFlagsValues[LaunchDarklyFlag]
): LaunchDarklyFlagsValues[LaunchDarklyFlag]
export function useFlag<LaunchDarklyFlag extends keyof LaunchDarklyFlagsValues>(
  flag: LaunchDarklyFlag,
  defaultValue?: LaunchDarklyFlagsValues[LaunchDarklyFlag]
): LaunchDarklyFlagsValues[LaunchDarklyFlag] | boolean {
  return useFeatureFlag(flag, defaultValue) ?? false
}
