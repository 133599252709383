import { TFunction } from 'next-i18next'
import { camelCase } from '@afterpay/utils/string'
import { Route } from '../../config/router'
import { Breadcrumbs as BreadcrumbsType } from '../../model/breadcrumbs'
import { BreadcrumbRoute } from './BreadcrumbContext'
import { Breadcrumbs } from './constants'

export const mapDynamicCrumbToRoute = (crumb: string): string | null => (crumb ? camelCase(crumb.toLowerCase()) : null)

export const getBreadcrumbs = (t: TFunction, crumbs?: BreadcrumbRoute): BreadcrumbsType => {
  const staticCrumbs = { ...Object.assign({}, ...Object.entries(Breadcrumbs).map(([key, value]) => ({ [key]: t(`common:breadcrumbs:${value}`) }))) }
  const dynamicCrumbs = {
    ...(crumbs !== undefined && {
      [Route.ORDER]: [
        {
          route: t(`common:breadcrumbs:${Breadcrumbs[Route.ORDERS]}`),
          link: Route.ORDERS,
        },
        {
          route: t('common:breadcrumbs:order', {
            channel: t(`common:channel:${mapDynamicCrumbToRoute(String(crumbs[Route.ORDER]?.channel))}`),
            orderId: crumbs[Route.ORDER]?.orderId,
          }),
        },
      ],
    }),
  }
  return {
    ...staticCrumbs,
    ...dynamicCrumbs,
  }
}
