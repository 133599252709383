import React, { FunctionComponent, useEffect, ReactNode } from 'react'
import styles from './style.module.css'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'

interface AppContainerProps {
  children: ReactNode
}

export const AppContainer: FunctionComponent<AppContainerProps> = ({ children }) => {
  const amplitude = useAmplitudeWithEnduringEventProperties()

  useEffect(() => {
    const handleBeforeUnload = (): void => {
      amplitude.logEvent(TrackingEvent.PRESSED_CLOSE_BROWSER_TAB)
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return <div className={styles.container}>{children}</div>
}
