import React, { FunctionComponent } from 'react'
import { Heading, Button, Grid, Panel } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import styles from './style.module.scss'
import { Stores } from '../Stores'
import { useConsumerSizes } from '../../utils/responsive'
import { NewTabLink } from '../NewTabLink'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { useGetCategories } from '../Stores/hooks'
import { TrackingEvent } from '../../model/amplitude'
import { useRouter } from 'next/router'

const I18N_NAMESPACE = ['common', 'index']

export interface storePanelProps {
  title: string
  category: `index:stores:category:${'trending' | 'new' | 'shopSmall'}`
}

export const StoresPanel: FunctionComponent<storePanelProps> = ({ title, category }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const { subheadingSize } = useConsumerSizes()
  const router = useRouter()

  const { isGetCategoriesDataError } = useGetCategories({ category })
  const categoryLink = category ? `${t('common:urls:afterpaySite')}/categories/${t(category)}` : t('common:urls:afterpaySite')

  const onClickSeeAllStores = (): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    logEvent(TrackingEvent.CLICKED_SHOP_SEE_MORE, { category: t(`${title}`), locale: router?.locale })
  }

  if (isGetCategoriesDataError) {
    return null
  }

  return (
    <Panel>
      {/* @ts-ignore: OPERATION BLEED STOPPER */}
      <Panel.Content>
        <Grid>
          <Grid.Item>
            <div className={styles.title}>
              <Heading size={subheadingSize}>{t(`${title}`)}</Heading>
            </div>
          </Grid.Item>
          <Grid.Item sm='auto'>
            <NewTabLink href={categoryLink} testNameSpace='afterpay-site'>
              <Button.Secondary size='Small' onClick={onClickSeeAllStores}>
                {t('index:stores:more')}
              </Button.Secondary>
            </NewTabLink>
          </Grid.Item>
        </Grid>
        <div className={styles.stores}>
          <Stores category={category} />
        </div>
      </Panel.Content>
    </Panel>
  )
}
