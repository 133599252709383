import { Heading, Panel, Text } from '@afterpaytouch/core'
import React, { FunctionComponent, ReactElement } from 'react'
import { useConsumerSizes } from '../../utils/responsive'
import { useTranslation } from 'next-i18next'
import { useGetCreditFacilityQuery } from '../../state'
import { ClosedStatusMessage } from './ClosedStatusMessage'
import { AccountClosedPanel } from './AccountClosedPanel'
import { CreditFacilityDocumentTypes } from '@afterpaytouch/portal-api'

const I18N_NAMESPACE = ['common', 'payMonthly']

export const PayMonthlyPanel: FunctionComponent<{ children: ReactElement }> = ({ children }) => {
  const { headingSize } = useConsumerSizes()
  const { t } = useTranslation(I18N_NAMESPACE)
  const { data: creditFacilityResponse } = useGetCreditFacilityQuery()

  const isAccountClosed = creditFacilityResponse?.creditFacility?.status === 'CLOSED'
  const pclStatement = creditFacilityResponse?.creditFacility?.documents?.find((d) => d.type === CreditFacilityDocumentTypes.PCLStatement)
  const creditContract = creditFacilityResponse?.creditFacility?.documents?.find((d) => d.type === CreditFacilityDocumentTypes.CreditContract)
  return (
    <>
      <Panel testNameSpace='pay-monthly'>
        <Panel.Head>
          <div className='mb-2'>
            <Heading size={headingSize}>{t('payMonthly:panel:head:heading')}</Heading>
          </div>
          <Text>{t('payMonthly:panel:head:description')}</Text>
        </Panel.Head>
        <Panel.Content bordered={isAccountClosed}>
          {isAccountClosed ? <ClosedStatusMessage pclStatement={pclStatement} creditContract={creditContract} /> : children}
        </Panel.Content>
      </Panel>
      {isAccountClosed && <AccountClosedPanel></AccountClosedPanel>}
    </>
  )
}
