import { Route } from '../config/router'

export const getLocaleFromHref = (href: string): string | undefined => {
  return new URL(href).pathname.split('/')[1] || undefined
}

export const getIsAppRoute = (r: string): boolean => {
  const appRoutes = Object.values(Route)
  return appRoutes.includes(r as Route)
}
