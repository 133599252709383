import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { RouterState } from 'connected-next-router/types'
import { ConsumerAppDispatch, ConsumerAppState, ConsumerAppSelector } from '.'

/* Selectors */

/* React Hooks */

export const routerSelector: ConsumerAppSelector<RouterState> = (state) => state.router

export const useAppDispatch = (): ConsumerAppDispatch => useDispatch<ConsumerAppDispatch>()

export const useAppSelector: TypedUseSelectorHook<ConsumerAppState> = useSelector

export const useAppRouter = (): RouterState => useAppSelector(routerSelector)
