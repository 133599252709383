import React, { FC } from 'react'
import { Button, Heading, Modal, Text } from '@afterpaytouch/core'
import { useDisputeTranslation } from '../hooks'
import { useConsumerSizes } from '../../../utils/responsive'
import { useDisputeCreationModalContext } from '../DisputeCreationModalContext'
import { TrackOnMount } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

export const Decline: FC = () => {
  const { textSize } = useConsumerSizes()
  const t = useDisputeTranslation()
  const { order, closeModal } = useDisputeCreationModalContext()

  const declineHeading = t('decline:head', { tradingName: order.merchant.name })
  const subHeading = t('decline:subheading', { tradingName: order.merchant.name })
  const cta = t('decline:cta')

  return (
    <>
      <TrackOnMount eventName={TrackingEvent.VIEWED_CREATE_DISPUTE_CUSTOM_DECLINE_FLOW} />
      <Modal.Header divider={true}>
        <div className='text-center'>
          <Heading size='L'>{declineHeading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <Text size={textSize} renderAs='span'>
            {subHeading}
          </Text>
        </div>
        <div className='mt-4'>
          <Button.Ghost padding='Fluid' size='Large' onClick={closeModal}>
            {cta}
          </Button.Ghost>
        </div>
      </Modal.Content>
    </>
  )
}
