import React, { FunctionComponent } from 'react'
import { Grid, Skeleton, useIsBreakpointLargeAndUp } from '@afterpaytouch/core'
import styles from './style.module.scss'

export const UpcomingPaymentsPanelLoading: FunctionComponent<{
  listSize: number
}> = ({ listSize }) => {
  const isBreakpointLargeAndUp = useIsBreakpointLargeAndUp()

  return (
    <Grid
      gutter={{
        lg: 3,
      }}
      verticalGutter={{
        sm: 1,
        md: 1,
      }}
    >
      {[...Array(listSize)].map((a, i) => (
        <Grid.Item sm={12} lg={6} key={i}>
          {/* @ts-ignore: OPERATION BLEED STOPPER */}
          <div className={i === 0 && isBreakpointLargeAndUp && styles.borderRight}>
            <Grid>
              <Grid.Item lg={6}>
                <div className={styles.skeletonContainer}>
                  <Skeleton />
                </div>
              </Grid.Item>
              <Grid.FixedItem>
                <div className={styles.skeletonContainer}>
                  <Skeleton />
                </div>
              </Grid.FixedItem>
            </Grid>
          </div>
        </Grid.Item>
      ))}
    </Grid>
  )
}
