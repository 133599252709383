import {
  ButtonPadding,
  ButtonSizes,
  CheckboxSizes,
  IconSizes,
  InputSizes,
  PaymentProgressSizes,
  RadioButtonSizes,
  TextSizes,
  CarouselBreakpointsSizes,
  useIsBreakpointLargeAndUp,
  useIsBreakpointMediumAndUp,
} from '@afterpaytouch/core'

interface ResponsiveSizes {
  buttonSize: ButtonSizes
  buttonPaddingType: ButtonPadding
  checkboxSize: CheckboxSizes
  harveyBallCaptionSize: TextSizes
  harveyBallSize: PaymentProgressSizes
  headingSize: TextSizes
  headingJumboSize: TextSizes
  subheadingSize: TextSizes
  inputSize: InputSizes
  radioSize: RadioButtonSizes
  sectionHeadingSize: TextSizes
  subSectionHeadingSize: TextSizes
  smallTextSize: TextSizes
  textSize: TextSizes
  iconSize: IconSizes
  storesItemsSize: CarouselBreakpointsSizes
  storesEmptyItemsSize: number
}

// @TODO: Update for consumer portal
export const useConsumerSizes = (): ResponsiveSizes => {
  const isBpMediumUp = useIsBreakpointMediumAndUp()
  const isBpLargeUp = useIsBreakpointLargeAndUp()
  const buttonSize = isBpLargeUp ? 'Regular' : 'Small'
  const buttonPaddingType = isBpMediumUp ? 'Fixed' : 'Fluid'
  const checkboxSize = isBpLargeUp ? 'Medium' : 'Small'
  const harveyBallCaptionSize = isBpLargeUp ? 'S' : 'XS'
  const harveyBallSize = isBpLargeUp ? 'Regular' : 'Small'
  const headingSize = isBpLargeUp ? 'XXL' : 'XL'
  const headingJumboSize = isBpLargeUp ? 'XXXL' : 'XXL'
  const subheadingSize = isBpMediumUp ? 'XL' : 'L'
  const inputSize = isBpLargeUp ? 'Large' : 'Medium'
  const radioSize = isBpLargeUp ? 'Medium' : 'Small'
  const sectionHeadingSize = isBpLargeUp ? 'L' : 'M'
  const subSectionHeadingSize = isBpLargeUp ? 'M' : 'S'
  const smallTextSize = isBpLargeUp ? 'S' : 'XS'
  const textSize = isBpLargeUp ? 'M' : 'S'
  const iconSize = isBpMediumUp ? 'Medium' : 'Small'
  const storesItemsSize = isBpLargeUp ? 6 : isBpMediumUp ? 4 : 2
  const storesEmptyItemsSize = isBpLargeUp ? storesItemsSize : storesItemsSize + 1
  return {
    buttonSize,
    buttonPaddingType,
    checkboxSize,
    harveyBallCaptionSize,
    harveyBallSize,
    headingSize,
    headingJumboSize,
    subheadingSize,
    inputSize,
    radioSize,
    sectionHeadingSize,
    subSectionHeadingSize,
    smallTextSize,
    textSize,
    iconSize,
    storesItemsSize,
    storesEmptyItemsSize,
  }
}
