import { useGetSelectedTheme } from '@afterpaytouch/core'
import React, { FunctionComponent } from 'react'
import { getThemeClass } from './ThemeWrapper.utils'

interface ThemeWrapperProps extends React.AnchorHTMLAttributes<HTMLDivElement> {}

export const ThemeWrapper: FunctionComponent<ThemeWrapperProps> = ({ children }) => {
  const theme = useGetSelectedTheme()
  return (
    <div className={`${getThemeClass(theme)} flex min-h-full flex-row`} data-testid='theme'>
      {children}
    </div>
  )
}
