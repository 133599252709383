import { cardScanService, GetCardScanResultRequest } from '@afterpaytouch/portal-api/consumer/cardScan'

import {
  AsyncAction,
  cardScanCheckpointSelector,
  cardScanTraceIdSelector,
  getScanIntent,
  setScanIntent,
  setCardScanStatus,
  setCardScanTraceId,
  setCardScanRequired,
  setCardScanSuccessCallback,
} from '../'
import { ScanIntent, isScanIntent, CardScanCheckpoint, CardScanStatus, CardScanError, isNoPermissionError, ScanResult } from '../../model'
import { app } from '../../env'

const { getBouncerSecret, getCardScanResult } = cardScanService.endpoints

export const activateCardScan =
  (traceId?: string, callback?: () => any): ((dispatch) => void) =>
  (dispatch) => {
    if (traceId) {
      dispatch(setCardScanTraceId(traceId))
    }

    if (callback != null) {
      setCardScanSuccessCallback(callback)
    }

    dispatch(setCardScanRequired(true))
  }

export const initialiseScanIntent = (): AsyncAction<ScanIntent | { error: CardScanError }> => async (dispatch, getState) => {
  const store = getState()
  const traceId: string = cardScanTraceIdSelector(store)
  const checkpoint: CardScanCheckpoint = cardScanCheckpointSelector(store)
  let scanIntent: ScanIntent = getScanIntent()

  dispatch(setCardScanStatus(CardScanStatus.Initial))
  // clear current intent
  if (isScanIntent(scanIntent)) {
    scanIntent?.iframe?.parentElement?.removeChild(scanIntent?.iframe)
    // @ts-ignore: OPERATION BLEED STOPPER
    scanIntent = null
    setScanIntent(scanIntent)
  }

  // create new intent
  dispatch(setCardScanStatus(CardScanStatus.Creating))
  try {
    const {
      // @ts-ignore: OPERATION BLEED STOPPER
      data: { id, secret },
    } = await dispatch(
      getBouncerSecret.initiate(
        {
          checkpoint,
          traceId,
        },
        { forceRefetch: true }
      )
    )
    // @ts-ignore: OPERATION BLEED STOPPER
    scanIntent = new window.stripeCardVerify.Scan(id, secret, app.STRIPE_CARD_SCAN_PUBLISHABLE_KEY)
    setScanIntent(scanIntent)

    // initialize intent
    const supported: boolean = await scanIntent.isAvailable()
    if (supported) {
      dispatch(setCardScanStatus(CardScanStatus.Ready))
      return scanIntent
    }
    dispatch(setCardScanStatus(CardScanStatus.Initial))
    return {
      error: CardScanError.Unsupported,
    }
  } catch (e) {
    dispatch(setCardScanStatus(CardScanStatus.Initial))
    throw new Error()
  }
}
// TODO: replace mock intent by real intent
// const mockStartScan = (): any => {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve({
//         completed: true,
//         canceled: false,
//       })
//     }, 1500)
//   })
// }
// @ts-ignore: OPERATION BLEED STOPPER
export const startCardScan = (): AsyncAction<true | { error: CardScanError }> => async (dispatch, getState) => {
  const store = getState()
  const traceId: string = cardScanTraceIdSelector(store)
  const checkpoint: CardScanCheckpoint = cardScanCheckpointSelector(store)
  const scanIntent: ScanIntent | null = getScanIntent()
  if (!isScanIntent(scanIntent)) {
    return
  }

  try {
    dispatch(setCardScanStatus(CardScanStatus.Scanning))
    const scanResult: ScanResult = await /* mockStartScan() */ scanIntent.startScan()
    const { completed, canceled, cardImageVerificationId } = scanResult
    if (completed) {
      const parameters: GetCardScanResultRequest = {
        civ: {
          id: cardImageVerificationId,
        },
        traceId,
        checkpoint,
      }
      const {
        // @ts-ignore: OPERATION BLEED STOPPER
        data: { isMatched, isRetryable },
      } = await dispatch(getCardScanResult.initiate(parameters, { forceRefetch: true }))
      dispatch(setCardScanStatus(CardScanStatus.Finished))

      if (isMatched) {
        return true
      }

      if (isRetryable) {
        return {
          error: CardScanError.DidntMatch,
        }
      }

      return {
        error: CardScanError.TooManyTries,
      }
    }

    dispatch(setCardScanStatus(CardScanStatus.Ready))
    if (canceled) {
      return {
        error: CardScanError.UserCanceled,
      }
    }

    return {
      error: CardScanError.CannotScan,
    }
  } catch (e) {
    dispatch(setCardScanStatus(CardScanStatus.Initial))
    if (isNoPermissionError(e?.error)) {
      return {
        error: CardScanError.NoPermission,
      }
    }

    return {
      error: CardScanError.TerminalError,
    }
  }
}
