export const maskCreditCardNumber = (creditCardNumber: string): string => {
  if (creditCardNumber?.length < 3) {
    return creditCardNumber
  }

  const match = creditCardNumber.match(/\d{3,4}$/)
  if (match == null) {
    return creditCardNumber
  }

  return `•••• ${match[0]}`
}
