import { Money, Text, TextSizes } from '@afterpaytouch/core'
import { Money as IMoney, SupportedLocale } from '@afterpaytouch/portal-api'
import { useFlag } from '../../../hooks'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'
import styles from './style.module.scss'
import { ButtonDisplay } from './ButtonDisplay'

const I18N_NAMESPACE = ['common', 'index']

interface HeaderInfoDisplayProps {
  amount: IMoney
  amountSize: TextSizes
  locale: SupportedLocale
  isCreditLimit?: boolean
  isOwedAmount?: boolean
}

export const HeaderInfoDisplay: FunctionComponent<HeaderInfoDisplayProps> = ({ amount, amountSize, locale, isCreditLimit = false, isOwedAmount = false }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isConsumerLendingEnabled = useFlag('consumer-portal-header-lending-enabled', false)

  const containerTestNameSpace = 'header-info-display'
  const amountTestNameSpace = `${containerTestNameSpace}${isCreditLimit ? '-credit-limit-money' : isOwedAmount ? '-total-owed-money' : ''}`

  return (
    <div className={styles.container} data-testid={containerTestNameSpace}>
      <Text renderAs='span' size={amountSize} bold testNameSpace={amountTestNameSpace}>
        {Money({
          ...amount,
          locale,
        })}
      </Text>
      {!isConsumerLendingEnabled ? (
        <ButtonDisplay isCreditLimit={isCreditLimit} isOwedAmount={isOwedAmount} />
      ) : (
        <div className='flex sm:pb-2 md:pb-0'>
          <Text size={'S'}>{t('index:welcome:existingUser:label:totalOwing')}</Text>
        </div>
      )}
    </div>
  )
}
