import { ErrorCode } from '@afterpaytouch/portal-api/types'

export enum ProfileErrorCode {
  Default = 'default',
  InvalidAddress = 'invalidAddress',
  ExistingPhoneNumber = 'existingPhoneNumber',
  ExistingPhoneNumberSendEmail = 'existingPhoneNumberSendEmail',
  SameProfileInfoWarning = 'sameProfileInfoWarning',
}

export const ProfileErrors: Partial<Record<ErrorCode, ProfileErrorCode>> = {
  [ErrorCode.IneligibleState]: ProfileErrorCode.InvalidAddress,
  [ErrorCode.InvalidAddress]: ProfileErrorCode.InvalidAddress,
  [ErrorCode.AddressPoBoxNotAllowed]: ProfileErrorCode.InvalidAddress,
  [ErrorCode.InvalidObject]: ProfileErrorCode.InvalidAddress,
  [ErrorCode.AddressSuburbRequired]: ProfileErrorCode.InvalidAddress,
  [ErrorCode.PostcodeInvalid]: ProfileErrorCode.InvalidAddress,
  [ErrorCode.AddressStateInvalid]: ProfileErrorCode.InvalidAddress,
  [ErrorCode.InvalidLocalAreaOrVillageName]: ProfileErrorCode.InvalidAddress,
  [ErrorCode.ExistingPhoneNumber]: ProfileErrorCode.ExistingPhoneNumber,
  [ErrorCode.ExistingPhoneNumberSendEmail]: ProfileErrorCode.ExistingPhoneNumberSendEmail,
}

export const ACCEPTABLE_PASSWORD_STRENGTH = 2
