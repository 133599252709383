import { Grid, Text } from '@afterpaytouch/core'
import React, { FunctionComponent } from 'react'
import { KINDS } from './constants'
import { Gravatar } from '../Gravatar'

type KindsType = typeof KINDS
type KindsKeys = keyof KindsType
export type AvatarKinds = KindsType[KindsKeys]

export interface AvatarProps {
  name: string
  kind?: AvatarKinds
}

export const Avatar: FunctionComponent<AvatarProps> = ({ name, kind = KINDS.NORMAL }) => {
  const renderAvatar = (kind: AvatarKinds): JSX.Element => {
    switch (kind) {
      case KINDS.NORMAL:
        return <Gravatar size='S' alt={name} name={name} title={name} testNameSpace='avatar' />
      default:
        return (
          <span className='Gray10 text-sm'>
            <Gravatar size='S' alt={name} name={name} title={name} testNameSpace='avatar' />
          </span>
        )
    }
  }

  return kind === KINDS.NORMAL ? (
    <Grid center gutter='none'>
      <Grid.Item sm='auto'>
        <Text renderAs='span' size='M' bold testNameSpace='avatar'>
          {name}
        </Text>
      </Grid.Item>
      <Grid.Item>{renderAvatar(kind)}</Grid.Item>
    </Grid>
  ) : (
    <>{renderAvatar(kind)}</>
  )
}
