import { creditFacilityService } from '@afterpaytouch/portal-api/consumer/creditFacility'

export const {
  useGetCreditFacilityQuery,
  useGetCreditFacilitySummaryQuery,
  useLazyGetCloseCreditFacilityEligibilityQuery,
  useGetCloseCreditFacilityEligibilityQuery,
  useClosePayMonthlyCreditFacilityMutation,
  endpoints: { getCreditFacility },
} = creditFacilityService
