import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { Button, Text } from '@afterpaytouch/core'
import { InitiateInstallmentPaymentResponse, isPaymentScheduleCard } from '@afterpaytouch/portal-api/consumer/paymentSchedule/types'
import { PaymentMethodType } from '@afterpaytouch/portal-api/payments/types'
import { BankAccountDetails, CashAppPayDetail, isApplePay, isBankAccount, isCard, isCashAppPay, PaymentCardDetails, PaymentType } from '@afterpay/types'
import { PaymentMethodSelect } from '../../../PaymentMethodSelect'
import { isValidTopazResponse } from '../../../../state/topaz/utils'
import { PayNowModalContext, PaymentInstallment } from '../../PayNowModalContext'
import {
  useAppDispatch,
  useApplePay,
  setApplePayOrderChannel,
  setApplePayTransactionId,
  useGetOrderTransactionQuery,
  useSetupPaymentMutation,
  useConsumerCountry,
  useTraceId,
  useLazyGetCreditCardsQuery,
  useGetAccountData,
} from '../../../../state'
import { useAmplitudeWithEnduringEventProperties } from '../../../../integrations/amplitude'
import { useConfirmHardshipCardPayment, usePaymentConfirmResult, useShouldBlockChaseCard } from '../hooks'
import { TrackingEvent } from '../../../../model/amplitude'
import { useConfirmInitCustomAmountPayment, useConfirmInstallmentPayment } from '../../hooks'
import { PaymentMethods } from '../../../../state/orders/types'
import { isOrderTypeUnsupportedForPaymentMethod } from '../../../../state/orders/utils'
import { isCustomAmountPayment as checkCustomAmountPayment, isInstallmentPayment as checkInstallmentPayment } from '../../utils'

import { useFlag } from '../../../../hooks'
import { ResultCode } from '@afterpaytouch/topaz-api'

const I18N_NAMESPACE = ['common', 'payments']

export interface SelectPaymentProps {
  initiateData: InitiateInstallmentPaymentResponse
}

export const SelectPayment: FunctionComponent<SelectPaymentProps> = ({ initiateData }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const router = useRouter()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const countryCode = useConsumerCountry()
  const accountData = useGetAccountData()

  const {
    payment,
    paymentMethod,
    setResult,
    setIsLoading,
    setPaymentMethod,
    setIsFetching,
    order,
    setIsBankPayment,
    isBankPayment,
    hasAccountLevelPayments,
    isEligibleToUseBankAccount,
    payNowStep,
    linkedAccountId,
  } = useContext(PayNowModalContext)
  const { isOverdue = false } = payment as PaymentInstallment
  const [setupTopazPayment, { data: topazData, isError: isTopazError, isLoading: isTopazLoading }] = useSetupPaymentMutation()
  const [confirmInstallment, { isSuccess: isInstallmentSuccess, isError: isInstallmentError, error: installmentError, isLoading: isInstallmentLoading }] =
    useConfirmInstallmentPayment({ orderId: String(order?.orderId) })
  const [confirmCustomAmount, { isSuccess: isCustomAmountSuccess, isError: isCustomAmountError, error: customAmountError, isLoading: isCustomAmountLoading }] =
    useConfirmInitCustomAmountPayment({ orderId: String(order?.orderId) })
  const [payHardshipInstallment, { isSuccess: isHardshipPaymentSuccess, isError: isHardshipPaymentError, isLoading: isHardshipPaymentLoading }] =
    useConfirmHardshipCardPayment()
  const [getCreditCards, { data: fetchedCardData, isLoading: isCardDataLoading }] = useLazyGetCreditCardsQuery()
  const isCustomAmountPayment = checkCustomAmountPayment(payment)
  const isInstallmentPayment = checkInstallmentPayment(payment)
  const { isChaseCreditCardBlocked } = useShouldBlockChaseCard()
  const { savedCards = [], savedBankAccounts = [], cashAppPay = [] } = initiateData ?? {}
  const paymentMethods = useMemo(() => {
    if (hasAccountLevelPayments) {
      // Note: we dont support bank accounts for customers in a payment arrangement
      if (fetchedCardData != null) {
        return [...cashAppPay, ...fetchedCardData.creditcards]
      }
    }
    return [...cashAppPay, ...savedCards, ...savedBankAccounts]
  }, [cashAppPay, savedCards, savedBankAccounts, hasAccountLevelPayments, fetchedCardData])

  const isAmexSoftCloseEnabled = useFlag('amex-soft-close-enabled', false)
  const [isCardBlocked, setIsCardBlocked] = useState(false)
  const [isBrandAllowed, setIsBrandAllowed] = useState(true)
  const [showAmexError, setShowAmexError] = useState(false)
  const [isBankAndOverdue, setIsBankAndOverdue] = useState(false)
  const handlePaymentMethodChange = (method: PaymentMethods): void => {
    handlePaymentMethod(method)
    handleIsBankAccount(method)
    // @ts-ignore: OPERATION BLEED STOPPER
    if (!method?.preferred) {
      logEvent(TrackingEvent.CHANGE_PAYMENT_METHOD)
    }
  }
  const handlePaymentMethod = (method: PaymentMethods): void => {
    // Reset flag before every change
    setIsBrandAllowed(true)
    setIsCardBlocked(false)
    setIsBankAndOverdue(false)
    // Remove Chase credit card check after Sept 16 2024 https://block.atlassian.net/browse/LEND-428
    if ((isPaymentScheduleCard(method) && !method.allowed) || isChaseCreditCardBlocked(method as PaymentCardDetails, false)) {
      setIsCardBlocked(true)
    } else if (isCard(method) && method.cardBrand === 'AMEX' && isAmexSoftCloseEnabled) {
      setIsBrandAllowed(false)
      setShowAmexError(true)
    } else if (isCard(method) && data != null && !data.enabledCardTypes.includes(method.cardBrand)) {
      setIsBrandAllowed(false)
    } else {
      setShowAmexError(false)
      setPaymentMethod(method)
    }
  }

  const handleIsBankAccount = (method: PaymentMethods): void => {
    if (isBankAccount(method)) {
      handleIsBankAndOverdue()
    } else {
      setIsBankPayment(false)
    }
  }

  const handleIsBankAndOverdue = (): void => {
    if (isOverdue) {
      setIsBankAndOverdue(true)
    } else {
      setIsBankPayment(true)
    }
  }

  const dispatch = useAppDispatch()
  const { data, isLoading: isGetOrderTransactionLoading } = useGetOrderTransactionQuery(String(order?.orderId), { skip: hasAccountLevelPayments })
  const isFetching =
    isTopazLoading || isInstallmentLoading || isCustomAmountLoading || isGetOrderTransactionLoading || isCardDataLoading || isHardshipPaymentLoading
  const orderPaymentMethod = data?.paymentMethod
  const paymentType = data?.paymentType

  const hasUnsupportedPaymentMethod = useMemo(() => {
    // @ts-ignore: OPERATION BLEED STOPPER
    return Boolean(paymentType) && paymentMethods.some((pm) => isOrderTypeUnsupportedForPaymentMethod(pm, paymentType))
  }, [paymentType, paymentMethods])

  const { applePayInstance } = useApplePay()
  const traceId = useTraceId()
  const currency = isInstallmentPayment ? payment?.total?.currency : payment?.amount?.currency

  const handleApplePayPayment = async (): Promise<void> => {
    // Set data needed in the helper
    // @ts-ignore: OPERATION BLEED STOPPER
    dispatch(setApplePayOrderChannel(data.channel))
    // @ts-ignore: OPERATION BLEED STOPPER
    dispatch(setApplePayTransactionId(data?.id.toString()))

    const total: ApplePayJS.ApplePayLineItem = {
      label: t('common:brandName'),
      amount: isInstallmentPayment ? payment.total.amount : payment.amount.amount,
      type: 'final',
    }

    applePayInstance.initialise(
      {
        // @ts-ignore: OPERATION BLEED STOPPER
        countryCode,
        currencyCode: currency,
        // @ts-ignore: OPERATION BLEED STOPPER
        supportedCountries: [data.countryCode ?? countryCode],
        total,
        accountData,
        // @ts-ignore: OPERATION BLEED STOPPER
        ...(data.paymentType === PaymentType.PCL && { merchantCapabilities: ['supports3DS', 'supportsDebit'] }),
      },
      logEvent
    )

    try {
      // Confirmed with backend team that paymentSourceId is not required, but it is returned in case if we need it in the future
      await applePayInstance.startSession()

      if (isInstallmentPayment) {
        await confirmInstallment({
          traceId,
          storeToken: false,
          paymentMethodType: PaymentMethodType.APPLE_PAY,
        })
      }
      if (isCustomAmountPayment) {
        await confirmCustomAmount({
          traceId,
          storeToken: false,
          paymentMethodType: PaymentMethodType.APPLE_PAY,
        })
      }
    } catch (e) {
      // The consumer click 'cancel' when the apple pay is processing, the e is undefined.
      if (typeof e === 'undefined') {
        logEvent(TrackingEvent.VIEWED_FAILED_PAYMENT_MODAL, {
          failureReason: 'Custom payment - ApplePay payment issue - cancelled by consumer',
          failureCode: '',
        })
      } else {
        logEvent(TrackingEvent.VIEWED_FAILED_PAYMENT_MODAL, {
          failureReason: e?.response ?? e?.message ?? 'Custom payment - ApplePay payment issue',
          failureCode: e?.code,
        })
      }
    }
  }

  const handleBankAccountPayment = async (paymentMethod: BankAccountDetails): Promise<void> => {
    try {
      if (isInstallmentPayment) {
        await confirmInstallment({
          traceId: initiateData.traceId,
          storeToken: false,
          paymentMethodId: paymentMethod.id,
          // @ts-ignore: OPERATION BLEED STOPPER
          paymentMethodType: paymentMethod?.type,
        })
      }
      if (isCustomAmountPayment) {
        await confirmCustomAmount({
          traceId: initiateData.traceId,
          storeToken: false,
          paymentMethodId: paymentMethod.id,
          // @ts-ignore: OPERATION BLEED STOPPER
          paymentMethodType: paymentMethod?.type,
        })
      }
    } catch (e) {
      const error = e as AxiosError
      logEvent(TrackingEvent.VIEWED_FAILED_PAYMENT_MODAL, {
        failureReason: error.response ?? error.message ?? 'Custom payment - ACH payment issue',
        // @ts-ignore: OPERATION BLEED STOPPER
        failureCode: error.code,
      })
    }
  }

  const handleCashAppPayPayment = async (paymentMethod: CashAppPayDetail): Promise<void> => {
    try {
      if (isInstallmentPayment) {
        await confirmInstallment({
          traceId: initiateData.traceId,
          storeToken: false,
          paymentMethodId: paymentMethod.id,
          paymentMethodType: PaymentMethodType.CASH_APP_PAY,
        })
      }
      if (isCustomAmountPayment) {
        await confirmCustomAmount({
          traceId: initiateData.traceId,
          storeToken: false,
          paymentMethodId: paymentMethod.id,
          paymentMethodType: PaymentMethodType.CASH_APP_PAY,
        })
      }
    } catch (e) {
      const error = e as AxiosError
      logEvent(TrackingEvent.VIEWED_FAILED_PAYMENT_MODAL, {
        failureReason: error.response ?? error.message ?? 'Custom payment - ACH payment issue',
        // @ts-ignore: OPERATION BLEED STOPPER
        failureCode: error.code,
      })
    }
  }

  const handleCreditCardPayment = async (paymentMethod: PaymentMethods): Promise<void> => {
    try {
      // if (isSavedPaymentMethod(paymentMethod)) {
      if (isPaymentScheduleCard(paymentMethod)) {
        const topazResponse = await setupTopazPayment({ traceId: initiateData.traceId, token: paymentMethod.token }).unwrap()
        if (isValidTopazResponse(topazResponse)) {
          if (isInstallmentPayment) {
            await confirmInstallment({
              traceId: initiateData.traceId,
              storeToken: false,
              paymentMethodId: paymentMethod.id,
              paymentMethodType: paymentMethod?.type,
            })
          }
          if (isCustomAmountPayment) {
            await confirmCustomAmount({
              traceId: initiateData.traceId,
              storeToken: false,
              paymentMethodId: paymentMethod.id,
              paymentMethodType: paymentMethod?.type,
            })
          }
        }
        if (topazResponse?.resultCode === ResultCode.UNACCEPTABLE_CARD) {
          setIsCardBlocked(true)
        }
      }
      // }
    } catch (e) {
      const error = e as AxiosError
      logEvent(TrackingEvent.VIEWED_FAILED_PAYMENT_MODAL, {
        failureReason: error.response ?? error.message ?? 'Custom payment - Card payment issue',
        // @ts-ignore: OPERATION BLEED STOPPER
        failureCode: error.code,
      })
    }
  }

  useEffect(() => {
    setIsLoading(isCardDataLoading)
  }, [isCardDataLoading])

  useEffect(() => {
    if (isHardshipPaymentSuccess) {
      setResult({
        error: false,
        success: true,
      })
    }
    if (isHardshipPaymentError) {
      setResult({
        error: true,
        success: false,
      })
    }
  }, [isHardshipPaymentSuccess, isHardshipPaymentError])

  const handleSubmit = async (): Promise<void> => {
    logEvent(TrackingEvent.CLICKED_SUBMIT_PAYMENT, {
      isNewPaymentMethod: false,
      orderId: order?.orderId,
      outboundLink: router?.pathname,
      isHardship: hasAccountLevelPayments,
    })

    if (isCard(paymentMethod)) {
      if (hasAccountLevelPayments) {
        return await payHardshipInstallment()
      }
      return await handleCreditCardPayment(paymentMethod)
    }

    if (isApplePay(paymentMethod)) {
      return await handleApplePayPayment()
    }

    if (isBankAccount(paymentMethod)) {
      return await handleBankAccountPayment(paymentMethod)
    }

    if (isCashAppPay(paymentMethod)) {
      return await handleCashAppPayPayment(paymentMethod)
    }
  }

  usePaymentConfirmResult({
    // @ts-ignore: OPERATION BLEED STOPPER
    topazData,
    isTopazError,
    isInstallmentError,
    isCustomAmountError,
    isInstallmentSuccess,
    isCustomAmountSuccess,
    installmentError,
    customAmountError,
    t,
  })

  useEffect(() => {
    if (hasAccountLevelPayments) {
      // Need to manually fetch cards for payment arrangements
      getCreditCards()
    }
  }, [hasAccountLevelPayments, getCreditCards])

  useEffect(() => {
    setIsFetching(isFetching)
  }, [setIsFetching, isFetching])

  const isSubmitDisabled = useMemo(
    () => !paymentMethod || isFetching || isCardBlocked || !isBrandAllowed || isBankAndOverdue || showAmexError,
    [isFetching, isCardBlocked, isBrandAllowed, isBankAndOverdue, paymentMethod, showAmexError]
  )
  return (
    <div data-testid='paynow-payment-select'>
      <div className='mb-5'>
        <PaymentMethodSelect
          testNameSpace='paynow-payment-select'
          onChange={handlePaymentMethodChange}
          paymentMethods={paymentMethods}
          currency={currency}
          loading={isFetching}
          paymentType={data?.paymentType}
          isSelectBankAccountEnabled={isEligibleToUseBankAccount}
          defaultPaymentMethod={orderPaymentMethod}
          linkedAccountId={linkedAccountId}
        />
      </div>
      {isCardBlocked && (
        <div className='mb-5'>
          <Text color='Fire' size='S'>
            {t('payments:customPayment:payment:error:messages:blockedCardError')}
          </Text>
        </div>
      )}
      {!isBrandAllowed && (
        <div className='mb-5'>
          <Text color='Fire' size='S'>
            {!showAmexError &&
              t('payments:customPayment:payment:error:messages:preconditionFailed', {
                brandName: order?.merchant,
              })}
          </Text>
        </div>
      )}
      {isBankPayment && (
        <div className='mb-5'>
          <Text size='XS' testNameSpace='bank-payment-notification'>
            {t('payments:paymentMethodSelect:bankAccount:processingTime')}
          </Text>
        </div>
      )}
      {isBankAndOverdue && (
        <div className='mb-5'>
          <Text size='XS' color='Gray50' testNameSpace='bank-payment-overdue'>
            {t('payments:paymentMethodSelect:bankAccount:overdue')}
          </Text>
        </div>
      )}
      {hasUnsupportedPaymentMethod && (
        <div className='mb-5'>
          <Text size='S' color='Gray40' testNameSpace='only-debit-accepted'>
            {t('orders:orderPaymentMethod:modal:info:only_debit_accepted')}
          </Text>
        </div>
      )}
      <Button.Primary
        loading={isFetching}
        disabled={isSubmitDisabled}
        testNameSpace='paynow-payment-submit'
        padding='Fluid'
        onClick={() => {
          handleSubmit()
        }}
      >
        {t('payments:customPayment:payment:cta')}
      </Button.Primary>
    </div>
  )
}
