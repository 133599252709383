import { creditCardsService } from '@afterpaytouch/portal-api/consumer/account/creditcards'
import { CreditCard, CreditCardResponse } from '@afterpaytouch/portal-api/consumer/account/creditcards/types'
import { createSelector } from '@reduxjs/toolkit'
import { ConsumerAppSelector, useAppSelector } from '../'

/* Selectors */

export const {
  useGetCreditCardsQuery,
  useLazyGetCreditCardsQuery,
  useConfirmNewMutation,
  useDeleteCardMutation,
  useInitiateNewMutation,
  useUpdatePreferredCardMutation,
  useUpdateBillingAddressMutation,
  endpoints: { getCreditCards },
} = creditCardsService

// @TODO: Investigate if this select function requires memorisation for performance reasons
export const creditCardsSelector = getCreditCards.select()

// @ts-ignore: OPERATION BLEED STOPPER
export const creditCardsDataSelector: ConsumerAppSelector<CreditCardResponse> = createSelector(creditCardsSelector, (state) => state.data)

export const creditCardsHasResultsSelector: ConsumerAppSelector<boolean> = createSelector(creditCardsDataSelector, (data) => Boolean(data?.creditcards?.length))

export const preferredCreditCardSelector: ConsumerAppSelector<CreditCard | undefined> = createSelector(creditCardsDataSelector, (data) => {
  return data?.creditcards?.find((card) => card.preferred)
})

export const hasPreferredCreditCardSelector: ConsumerAppSelector<boolean> = createSelector(preferredCreditCardSelector, (result) => Boolean(result))

/* React Hooks */

export const useCreditCardsData = (): CreditCardResponse => useAppSelector(creditCardsDataSelector)

export const useCreditCardsHasResults = (): boolean => useAppSelector(creditCardsHasResultsSelector)

export const usePreferredCreditCard = (): CreditCard | undefined => useAppSelector(preferredCreditCardSelector)

export const useHasPreferredCreditCard = (): boolean => useAppSelector(hasPreferredCreditCardSelector)
