import React, { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Message } from '@afterpaytouch/core'
import { useConsumerSelector, useIdentityLicenseMutation, useIsPartialVerified } from '../../../state'
import { getInitialFormData, getLicenceCardNumberValidation, getLicenceValidations, getSchema, isLicenceNumValid, setHiddenFieldsByCountry } from './utils'
import { Terms } from './Terms'
import { FormWrapper } from '..'
import FormBuilder from '@afterpaytouch/form'
import { DriversLicenceForm, LicenceNumOpts, SchemaUpdates } from './types'
import { isDifferentWithProfileDOB, isDifferentWithProfileNames, IsRetryPageContext } from '../VerifyIdentity/helper'
import { DocumentProps } from '../../../model'
import { Channel, IdentityDocumentVerificationStatus, IdentityLicenseRequest } from '@afterpaytouch/portal-api/identity/types'
import { Country } from '@afterpaytouch/portal-api/types'
import { joinStrings } from '@afterpay/utils/string'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { getIdentityVerifyErrorCode, IdentityErrorCode } from '@afterpaytouch/portal-api/identity'
import { compareObjects } from '../../../utils/comparison'
import { USER_VERIFICATION_ERRORS } from '../VerifyIdentity/constants'
import { useCountryStateIsShown } from '../../../state/identity/hooks'
import { HardIdProfileCard, ErrorMessage, HardIdHelpLink, HardIdHeader } from '../VerifyIdentity/components'

const I18N_NAMESPACE = ['common', 'verifyIdentity']

export const DriversLicence: FunctionComponent<DocumentProps> = ({ onSuccess }) => {
  const amplitude = useAmplitudeWithEnduringEventProperties()
  const [lock, setLock] = useState(false)
  const [error, setError] = useState<IdentityErrorCode | null>(null)
  const { t } = useTranslation(I18N_NAMESPACE)
  const consumer = useConsumerSelector()
  const { countryCode, dateOfBirth, givenNames, otherGivenNames, surname } = consumer
  const { state: initialState } = getInitialFormData(consumer)
  const lastSelectedState = useRef(initialState)
  const showCardNumber = countryCode === Country.AU
  const [schemaUpdates, setSchemaUpdates] = useState<SchemaUpdates>({
    licenceNumOpts: {} as LicenceNumOpts,
    cardNumOpts: {} as LicenceNumOpts,
    showProfileDobAlert: false,
    showProfileNameAlert: false,
    licenceMaxLength: 0,
  })
  const [submittedRequest, setSubmittedRequest] = useState(undefined)
  const [identifyVerifyLicence] = useIdentityLicenseMutation()
  const profileDOB = dateOfBirth ?? ''
  const countryStateIsShowing = useCountryStateIsShown()
  const isPartialVerified = useIsPartialVerified()
  const isRetryPage = useContext(IsRetryPageContext)
  const isProfileEditable = !(isPartialVerified && isRetryPage)
  const verifyButtonAnalytics =
    isPartialVerified && isRetryPage ? TrackingEvent.PRESSED_HARDID_RETRY_PAGE_VERIFY_BUTTON : TrackingEvent.PRESSED_HARDID_DRIVERS_LICENCE_PAGE_VERIFY_BUTTON

  const { schema, initialFormData } = useMemo(() => {
    const onNameBlur = (): void => {
      // @ts-ignore: OPERATION BLEED STOPPER
      const fullName = joinStrings([formData.givenNames, formData.otherGivenNames, formData.surname])

      setSchemaUpdates((currSchema) => ({
        ...currSchema,
        showProfileNameAlert: isDifferentWithProfileNames(fullName, { givenNames, otherGivenNames, surname }),
      }))
    }
    const components = {
      profileCard: () => <HardIdProfileCard />,
      terms: () => <Terms />,
      footer: () => {
        return (
          <Button.Primary loading={lock} type='submit' disabled={lock} padding='Fluid'>
            {t('verifyIdentity:licence:cta')}
          </Button.Primary>
        )
      },
      dobAlert: () => (
        <Message
          kind='Success'
          iconName='Profile'
          heading={t('verifyIdentity:alert:profileUpdateHeading')}
          description={t('verifyIdentity:alert:profileUpdateDOB')}
        />
      ),
      nameAlert: () => (
        <Message
          kind='Success'
          iconName='Profile'
          heading={t('verifyIdentity:alert:profileUpdateHeading')}
          description={t('verifyIdentity:alert:profileUpdateName')}
        />
      ),
    }
    // @ts-ignore: OPERATION BLEED STOPPER
    const isUserFormError = USER_VERIFICATION_ERRORS.includes(error)
    return getSchema(consumer, components, t, schemaUpdates, onNameBlur, isUserFormError, isProfileEditable)
  }, [error, consumer, t, schemaUpdates, isProfileEditable, lock])
  const [formData, setFormData] = useState(initialFormData)

  setHiddenFieldsByCountry(schema, countryCode)

  const onSubmit = async (driversLicenceData: DriversLicenceForm): Promise<void> => {
    setError(null)
    amplitude.logEvent(verifyButtonAnalytics)
    setLock(true)
    const { dlCardNumber, ...driverLicence } = driversLicenceData

    // NZ does not have a state of issuer however, IdentityLicenseRequest needs one
    // so default to consumer state
    const request: IdentityLicenseRequest = {
      channel: Channel.PORTAL,
      countryCode,
      // @ts-ignore: OPERATION BLEED STOPPER
      state: initialState,
      dateOfBirth: consumer?.dateOfBirth,
      surname: consumer?.surname,
      givenNames: consumer?.givenNames,
      otherGivenNames: consumer?.otherGivenNames,
      ...driverLicence,
    }

    if (showCardNumber) {
      request.dlCardNumber = dlCardNumber
    }

    if (compareObjects(request, submittedRequest)) {
      setLock(false)
      setError(IdentityErrorCode.IdentityAlreadyRejected)
      return
    }

    try {
      const response = await identifyVerifyLicence(request).unwrap()
      if (response.status === IdentityDocumentVerificationStatus.VERIFIED) {
        // @ts-ignore: OPERATION BLEED STOPPER
        onSuccess()
      } else if (response.status === IdentityDocumentVerificationStatus.REJECTED) {
        setLock(false)
        // @ts-ignore: OPERATION BLEED STOPPER
        setSubmittedRequest(request)
        setError(IdentityErrorCode.IdentityCheckRejected)
      }
    } catch (e) {
      const error = getIdentityVerifyErrorCode(e)
      setError(error ?? IdentityErrorCode.Default)
      setLock(false)
    }
  }

  const onError = (data: string): void => {
    console.log('error', data)
  }

  useEffect(() => {
    if (typeof error !== 'undefined') {
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [error])

  const onChange = (data: DriversLicenceForm): void => {
    setError(null)
    setFormData(data)
    // state might be undefined for places like NZ (formbuilder doesnt register hidden fields)
    // so default to consumer state
    const { state = initialState, dateOfBirth, licenceNumber, dlCardNumber } = data
    // @ts-ignore: OPERATION BLEED STOPPER
    const { licenceNumOpts, licenceMaxLength } = getLicenceValidations(countryCode, state, t, countryStateIsShowing)
    // @ts-ignore: OPERATION BLEED STOPPER
    const cardNumOpts = getLicenceCardNumberValidation(countryCode, state, t, countryStateIsShowing)
    const didStateUpdate = lastSelectedState.current !== state
    // licence num should not clear for countries with no state selection
    const shouldLicenceNumClear = didStateUpdate && countryStateIsShowing && !isLicenceNumValid(licenceNumber, licenceNumOpts) && licenceNumber.length > 0

    const shouldLicenceCardNumClear =
      // @ts-ignore: OPERATION BLEED STOPPER
      didStateUpdate && countryStateIsShowing && showCardNumber && dlCardNumber?.length > 0 && !isLicenceNumValid(dlCardNumber, cardNumOpts)

    setSchemaUpdates((currSchema) => ({
      ...currSchema,
      showProfileDobAlert: isDifferentWithProfileDOB(dateOfBirth, profileDOB),
      licenceNumOpts,
      licenceMaxLength,
      cardNumOpts,
      showCardNumber,
      // using a random key to clear the licence num field
      ...(shouldLicenceNumClear && { licenceNumberKey: Math.random().toString(36) }),
      ...(shouldLicenceCardNumClear && { licenceCardNumberKey: Math.random().toString(36) }),
    }))

    lastSelectedState.current = state
  }

  return (
    <>
      <HardIdHeader documentType='licence' />
      <ErrorMessage identityType='licence' error={error} />
      <FormWrapper>
        <FormBuilder schema={schema} testNameSpace={'verify-licence'} onChange={onChange} onSubmit={onSubmit} onError={onError} disabled={lock} />
      </FormWrapper>
      <HardIdHelpLink />
    </>
  )
}
