import { createSelector } from '@reduxjs/toolkit'

import { useAppSelector, DisputeState, ConsumerAppSelector } from '../'
import { DisputePermissionError } from '@afterpaytouch/portal-api'

// /* Selectors */

export const disputeStateSelector: ConsumerAppSelector<DisputeState> = (state) => state.dispute

// @ts-ignore: OPERATION BLEED STOPPER
export const isDisputePermittedSelector: ConsumerAppSelector<boolean> = createSelector(disputeStateSelector, (state) => state.isDisputePermitted)

// @ts-ignore: OPERATION BLEED STOPPER
export const disputePermissionErrorSelector: ConsumerAppSelector<DisputePermissionError | undefined> = createSelector(
  disputeStateSelector,
  (state) => state.disputePermissionError
)

// /* React Hooks */

export const useIsDisputePermitted = (): boolean => useAppSelector(isDisputePermittedSelector)

export const useDisputePermissionError = (): DisputePermissionError | undefined => useAppSelector(disputePermissionErrorSelector)
