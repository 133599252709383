import React, { FC, useMemo } from 'react'
import { SupportedLocale } from '@afterpaytouch/portal-api'

import { useDisputeCreationModalContext } from '../DisputeCreationModalContext'
import { useDisputeTranslation } from '../hooks'
import { Heading, Modal, Text, useDate } from '@afterpaytouch/core'
import { useConsumerSizes } from '../../../utils/responsive'
import { useRouter } from 'next/router'

export const TimeLimitTip: FC = () => {
  const t = useDisputeTranslation()
  const { smallTextSize } = useConsumerSizes()
  const { order } = useDisputeCreationModalContext()
  const router = useRouter()
  const locale = router?.locale as SupportedLocale
  const formatDateLocale = locale === 'en-US' ? 'en-AU' : locale
  const { nextDay } = useDate({ locale: formatDateLocale, month: 'numeric', year: 'numeric', day: '2-digit' })

  const [heading, content] = useMemo(() => {
    const orderDay = new Date(order.orderDate)
    return [
      t('timeLimitTip:heading'),
      t('timeLimitTip:content', { time: nextDay(`${orderDay.getFullYear()}-${orderDay.getMonth() + 1}-${orderDay.getDate()}`, 8) }),
    ]
  }, [t, nextDay, order.orderDate])

  return (
    <>
      <Modal.Header divider={true}>
        <div className='text-center'>
          <Heading size='L'>{heading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className='whitespace-pre-line'>
          <Text size={smallTextSize} renderAs='span'>
            {content}
          </Text>
        </div>
      </Modal.Content>
    </>
  )
}
