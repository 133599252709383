import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react'
import { Button, Grid, Message, Span, Text, Link } from '@afterpaytouch/core'
import FormBuilder from '@afterpaytouch/form'
import { useRouter } from 'next/router'
import { Channel, Country, ErrorCode } from '@afterpaytouch/portal-api/types'
import { FormWrapper } from '../FormWrapper'
import { Trans, useTranslation } from 'next-i18next'
import styles from './style.module.css'
import { useConsumerSelector, useConsumerCountry, useIdentityVerifyMutation } from '../../../state'
import { getSchemaAndFormData, VerifyRetryForm, transformToBackend } from './utils'
import { compareObjects } from '../../../utils/comparison'
import { validAddressOption } from './validations'
import { useUpdateAccountDetailMutation } from '../../../state/account'
import { getIdentityVerifyErrorCode } from '@afterpaytouch/portal-api/identity/utils'
import { IdentityCheckStatus } from '@afterpaytouch/portal-api'
import { ProfileErrorCode, ProfileErrors } from '../../../model'
import { Route } from '../../../config/router'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { isCallable } from '@afterpay/utils'

const I18N_NAMESPACE = ['common', 'verifyIdentity']

interface Props {
  onSuccess?: () => any
}

const keysToIgnoreInCompare = ['dateOfBirth']

const TermsOfServiceUrl: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <Span bold underline size='XS'>
      <Link href={t('common:urls:termsOfServiceUrl')} target='_blank' kind='Underline' color='Orca'>
        {children}
      </Link>
    </Span>
  )
}

const PrivacyPolicyUrl: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  return (
    <Span bold underline size='XS'>
      <Link href={t('common:urls:privacyPolicyUrl')} target='_blank' kind='Underline' color='Orca'>
        {children}
      </Link>
    </Span>
  )
}

export const VerifyRetry: FunctionComponent<Props> = ({ onSuccess }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const consumer = useConsumerSelector()
  const country = useConsumerCountry()
  const route = useRouter()
  const [hasError, setHasError] = useState(false)
  const [hasAddressError, setHasAddressError] = useState(false)
  const [hasResponseError, setHasResponseError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [updateAccountDetails, { isError: isUpdateAccountDetailsError, isSuccess: isUpdateAccountDetailsSuccess, error: updateAccountDetailsError }] =
    useUpdateAccountDetailMutation()
  const [profileError, setProfileError] = useState<ProfileErrorCode>()
  const isSameProfileInfoWarning = profileError === ProfileErrorCode.SameProfileInfoWarning
  const [identityVerify] = useIdentityVerifyMutation()

  const { schema, initialFormData } = useMemo(() => {
    const renderError = (): JSX.Element => {
      let errorMessage = t('verifyIdentity:error:unableToUpdateAccountDetails')
      if (profileError === ProfileErrorCode.InvalidAddress) {
        errorMessage = t('verifyIdentity:error:invalidAddress')
      }
      return (
        <Grid>
          <Text color='Fire' testNameSpace='verify-retry-error'>
            {errorMessage}
          </Text>
        </Grid>
      )
    }

    const components = {
      profileError: () => renderError(),
      footer: () => {
        return (
          <>
            <Text size='XS' color='Gray40'>
              <Trans
                i18nKey='verifyIdentity:retry:terms'
                components={{
                  TermsOfServiceUrl: <TermsOfServiceUrl />,
                  PrivacyPolicyUrl: <PrivacyPolicyUrl />,
                }}
              />
            </Text>
            <br />
            <Button.Secondary loading={isLoading} disabled={isLoading} type='submit' padding='Fluid'>
              {t('verifyIdentity:retry:cta')}
            </Button.Secondary>
          </>
        )
      },
    }

    // @ts-ignore: OPERATION BLEED STOPPER
    return getSchemaAndFormData(consumer, components, t, country, hasAddressError, profileError)
  }, [consumer, t, country, isLoading, hasAddressError, profileError])

  const [formData, setFormData] = useState(initialFormData)

  const isInvalidForm = (data: VerifyRetryForm): boolean => {
    // @ts-ignore: OPERATION BLEED STOPPER
    const areFormValuesSameAsProfile = compareObjects(initialFormData, data, true, keysToIgnoreInCompare)
    if (areFormValuesSameAsProfile) {
      setProfileError(ProfileErrorCode.SameProfileInfoWarning)
    }
    const isValidAddress = !validAddressOption(formData?.address, country)
    setHasAddressError(isValidAddress)
    return areFormValuesSameAsProfile || hasError || isValidAddress
  }

  const handleOnChange = (data: VerifyRetryForm): void => {
    setFormData(data)
    if (hasAddressError) {
      setHasAddressError(false)
    }
    if (isSameProfileInfoWarning) {
      // @ts-ignore: OPERATION BLEED STOPPER
      const areFormValuesSameAsProfile = compareObjects(initialFormData, data, true, keysToIgnoreInCompare)
      if (!areFormValuesSameAsProfile) {
        setProfileError(undefined)
      }
    }
  }

  const handleStateChange = (isValid: boolean): void => {
    setHasError(!isValid)
  }

  const handleSubmit = async (inputData: VerifyRetryForm): Promise<void> => {
    const data = transformToBackend(inputData)
    if (isInvalidForm(data)) {
      return
    }

    setIsLoading(true)
    const requestFormData = {
      ...data,
      ...data.address,
      countryCode: (data?.address?.countryCode ?? country) as Country,
      preferredLocale: consumer.preferredLocale,
      creditPolicyAgree: false,
    }

    await updateAccountDetails(requestFormData)

    try {
      const { identityCheckStatus: updatedIdentityCheckStatus } = await identityVerify({
        channel: Channel.PORTAL,
      }).unwrap()
      switch (updatedIdentityCheckStatus) {
        case IdentityCheckStatus.VERIFIED:
          isCallable(onSuccess) && onSuccess()
          break
        case IdentityCheckStatus.PARTIAL_VERIFIED:
        case IdentityCheckStatus.NOT_VERIFIED:
        case IdentityCheckStatus.FAILED:
          route.push(Route.VERIFY_IDENTITY)
          break
        default:
          setHasResponseError(true)
          break
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      if ([ErrorCode.InvalidAddress].includes(e?.data?.errorCode)) {
        route.push(Route.VERIFY_ADDRESS)
      } else {
        setHasResponseError(true)
      }
    }
  }

  useEffect(() => {
    if (isUpdateAccountDetailsError && updateAccountDetailsError !== null) {
      const errorCode = getIdentityVerifyErrorCode(updateAccountDetailsError)
      // @ts-ignore: OPERATION BLEED STOPPER
      setProfileError(ProfileErrors[errorCode] ?? ProfileErrorCode.Default)
    }
  }, [isUpdateAccountDetailsError, isUpdateAccountDetailsSuccess, updateAccountDetailsError])

  const renderGenericError = (): JSX.Element => {
    const warningHeading = t('verifyIdentity:error:generic:title')
    const warningDescription = (
      <Grid>
        <Text size='XS'>{t('verifyIdentity:error:generic:detail')}</Text>
      </Grid>
    )

    return (
      <div className={styles.messageContainer}>
        <Message testNameSpace='verify-retry-generic' kind='Error' iconName='Profile' heading={warningHeading} description={warningDescription} />
      </div>
    )
  }
  const renderSameProfileInfoWarning = (): JSX.Element => {
    const warningHeading = t('verifyIdentity:retry:sameProfileDetailsHeading')
    const warningDescription = (
      <Grid>
        <Text size='XS'>{t('verifyIdentity:retry:sameProfileDetailsDescription')}</Text>
      </Grid>
    )

    return (
      <div className={styles.messageContainer}>
        <Message testNameSpace='verify-retry-same-profile' kind='Info' iconName='WarningAlt' heading={warningHeading} description={warningDescription} />
      </div>
    )
  }

  useEffect(() => {
    if (typeof profileError !== 'undefined') {
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [profileError])

  return (
    <>
      <div className={styles.container}>
        <Grid>
          <Text>{t('verifyIdentity:retry:description')}</Text>
        </Grid>
        {isSameProfileInfoWarning ? renderSameProfileInfoWarning() : hasResponseError ? renderGenericError() : null}
        <MaskedElement>
          <FormWrapper>
            <FormBuilder
              schema={schema}
              onSubmit={handleSubmit}
              onChange={handleOnChange}
              onStateChange={handleStateChange}
              disabled={isLoading}
              testNameSpace='verify-retry'
            />
          </FormWrapper>
        </MaskedElement>
      </div>
    </>
  )
}
