import React, { FunctionComponent, useContext, useState, useEffect, useCallback, useMemo } from 'react'
import { Modal, Button, Text, useMoney } from '@afterpaytouch/core'
import { InstallmentItems } from './InstallmentItems'
import { PayNowModalContext, PayNowSteps, PayNowInput, PayNowInputProps } from '../PayNowModalContext'
import {
  useGetOrderTransactionQuery,
  getFirstPayableItem,
  isHardshipScheduleOverdue,
  useConsumerHasAccountLevelPayments,
  useGetPaymentArrangements,
  useGetTotalPayableHardshipRepayments,
} from '../../../state'
import {
  calculateAmountPayable,
  getPaymentScheduleSetPayable,
  hasInstallmentLastAndOwed as checkInstallmentLastAndOwed,
  isScheduleOverdue,
  getTotalInstallmentCount,
  getInstallmentSequence,
} from '../../../state/orders/utils'
import { Money as MoneyType, PaymentStatus, SupportedLocale } from '@afterpaytouch/portal-api'
import { Money } from '../../Money'
import { PaymentNavigation } from '../PaymentNavigation'
import { useInitCustomAmountPayment, useInitInstallmentPayment } from '../hooks'
import { getMerchantName } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { calculateLateFeesPayable, isHardshipScheduleOverdueSetPayment } from './utils'
import { TrackOnMount, useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { useFlag } from '../../../hooks'

const I18N_NAMESPACE = ['payments']

export const Installments: FunctionComponent = () => {
  const {
    order,
    payment,
    setPayment,
    setStep,
    setIsLoading,
    setOrder,
    setResult,
    payNowStep,
    setPayNowStep,
    hasAccountLevelPayments,
    setHasAccountLevelPayments,
  } = useContext(PayNowModalContext)
  const orderId = order?.orderId
  const consumerHasAccountLevelPayments = useConsumerHasAccountLevelPayments()
  const { data, isError, isLoading } = useGetOrderTransactionQuery(String(orderId), { skip: consumerHasAccountLevelPayments })
  const { paymentScheduleSet = [], loan, paymentType, paymentUpFront } = data ?? {}
  const { payoutAmount } = loan ?? {}
  const [initiateInstallmentPayment, { isLoading: isInitiateInstallmentLoading }] = useInitInstallmentPayment({ orderId: String(orderId) })
  const [initiateCustomAmountPayment, { isLoading: isInitiateCustomLoading, isError: isInitiateCustomError }] = useInitCustomAmountPayment({
    orderId: String(orderId),
  })
  const payableSchedules = getPaymentScheduleSetPayable(paymentScheduleSet)
  const totalAmountPayable = calculateAmountPayable({
    options: payableSchedules?.map((schedule) => ({
      schedule,
    })),
    payoutAmount,
  })

  const isHardshipEnabled = useFlag('consumer-portal-hardship-account-payments-enabled')
  const repaymentSchedule = useGetPaymentArrangements()
  const repaymentScheduleLength = useGetTotalPayableHardshipRepayments()
  const payableRepayment = useMemo(() => getFirstPayableItem(repaymentSchedule), [repaymentSchedule])
  const totalRepaymentScheduleAmount = calculateAmountPayable({
    options: repaymentSchedule?.paymentSchedules?.map((schedule) => ({
      schedule,
    })),
  })
  const isFinalHardshipRepayment = repaymentScheduleLength === 1

  // @ts-ignore: OPERATION BLEED STOPPER
  const [installmentTotal, setInstallmentTotal] = useState<MoneyType>(null)
  const [customAmount, setCustomAmount] = useState<string>('0')
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(true)
  const isCustomInputActive = useMemo(() => payNowStep === PayNowInput.CUSTOM, [payNowStep])
  const belowMaxAmount = parseFloat(customAmount) <= parseFloat(hasAccountLevelPayments ? totalRepaymentScheduleAmount?.amount : totalAmountPayable?.amount)
  const isCustomInputActiveAndValidAmount = isCustomInputActive && parseFloat(customAmount) !== 0
  const totalRepayment = useMemo(
    () => (hasAccountLevelPayments ? totalRepaymentScheduleAmount : totalAmountPayable),
    [hasAccountLevelPayments, totalRepaymentScheduleAmount, totalAmountPayable]
  )
  const hasInstallmentLastAndOwed = checkInstallmentLastAndOwed(paymentScheduleSet)
  const getCustomAmount = useCallback(
    (amount: string): MoneyType => ({
      amount,
      symbol: totalRepayment?.symbol,
      currency: totalRepayment?.currency,
    }),
    [totalRepayment]
  )
  const handleSetCustomAmount = useCallback(
    (amount: string): void => {
      setCustomAmount(amount)
      setInstallmentTotal({
        amount,
        symbol: totalRepayment?.symbol,
        currency: totalRepayment?.currency,
      })
    },
    [totalRepayment]
  )
  const setPaymentForAccount = useCallback(
    (step: PayNowInputProps): void => {
      // For hardship repayments
      const isOverdue = isHardshipScheduleOverdueSetPayment(step, repaymentSchedule)
      if (step === PayNowInput.NEXT) {
        // NEXT - 'total' is set
        // @ts-ignore: OPERATION BLEED STOPPER
        setPayment({
          ...payment,
          total: installmentTotal,
          amount: null,
          scheduleId: payableRepayment?.paymentScheduleUuid,
          isOverdue,
        })
      } else {
        // REMAINING || CUSTOM - 'amount' is set (No paymentScheduleUuid is provided for these payments)
        setPayment({
          ...payment,
          amount: installmentTotal,
          // @ts-ignore: OPERATION BLEED STOPPER
          total: null,
          // @ts-ignore: OPERATION BLEED STOPPER
          scheduleId: null,
          isOverdue,
        })
      }
      setOrder({
        ...order,
        orderId: payableRepayment?.paymentPlanUuid,
      })
    },
    [installmentTotal, payableRepayment, order, payment, setOrder, setPayment, repaymentSchedule]
  )

  const lateFeesInformation = useMemo(
    () => ({
      isNextInstallmentOverdue: hasAccountLevelPayments ? isHardshipScheduleOverdue(payableRepayment) : isScheduleOverdue(payableSchedules[0]),
      nextInstallmentFees: !hasAccountLevelPayments ? payableSchedules[0]?.owedFee : null,
      totalOwedFees: !hasAccountLevelPayments ? calculateLateFeesPayable(payableSchedules) : null,
    }),
    [payableSchedules, hasAccountLevelPayments, payableRepayment]
  )

  const customMoneyAmount = getCustomAmount(customAmount)
  const totalInstallmentCount = getTotalInstallmentCount(paymentScheduleSet.length, paymentUpFront, paymentType)
  const nextInstallment = hasAccountLevelPayments
    ? payableRepayment
    : {
        ...payableSchedules[0],
        installmentSequence: getInstallmentSequence(payableSchedules[0]?.installmentSequence, paymentUpFront, paymentType),
      }

  const router = useRouter()
  const { t } = useTranslation(I18N_NAMESPACE)
  const { formatMoney } = useMoney({ currency: installmentTotal?.currency, locale: router?.locale as SupportedLocale })
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const handleChange = (input: PayNowInputProps): void => {
    if (hasAccountLevelPayments) {
      handleAccountPaymentChange(input)
    } else {
      handleInstallmentChange(input)
    }
    logEvent(TrackingEvent.CHANGE_SELECTED_PAYMENT_TYPE, { type: input })
  }

  const handleInstallmentChange = (input: PayNowInputProps): void => {
    switch (input) {
      case PayNowInput.NEXT:
        setInstallmentTotal(payableSchedules[0].amountPayable)
        setPayNowStep(PayNowInput.NEXT)
        break
      case PayNowInput.REMAINING:
        setInstallmentTotal(totalAmountPayable)
        setPayNowStep(PayNowInput.REMAINING)
        break
      case PayNowInput.CUSTOM:
        setInstallmentTotal(customMoneyAmount)
        setPayNowStep(PayNowInput.CUSTOM)
        break
    }
  }

  const handleAccountPaymentChange = (input: PayNowInputProps): void => {
    switch (input) {
      case PayNowInput.NEXT:
        setInstallmentTotal(payableRepayment.outstandingBalance)
        setPaymentForAccount(input)
        setPayNowStep(PayNowInput.NEXT)
        break
      case PayNowInput.REMAINING:
        setInstallmentTotal(totalRepaymentScheduleAmount)
        setPaymentForAccount(input)
        setPayNowStep(PayNowInput.REMAINING)
        break
      case PayNowInput.CUSTOM:
        setPaymentForAccount(input)
        setInstallmentTotal(customMoneyAmount)
        setPayNowStep(PayNowInput.CUSTOM)
        break
    }
  }

  const handleSubmit = (): void => {
    if (hasAccountLevelPayments) {
      setStep(PayNowSteps.Payment)
    } else if (isCustomInputActive) {
      handleCustomPaymentSubmit()
    } else {
      handleInstallmentPaymentSubmit()
    }
  }

  const handleInstallmentPaymentSubmit = async (): Promise<void> => {
    // @ts-ignore: OPERATION BLEED STOPPER
    logEvent(TrackingEvent.CLICKED_CONFIRM_PAYMENT_AMOUNT, { paymentType })
    try {
      const schedulesForPayment = payNowStep === PayNowInput.NEXT ? [payableSchedules[0]] : [...payableSchedules]
      const initiateData = await initiateInstallmentPayment({ schedules: schedulesForPayment })
      // @ts-ignore: OPERATION BLEED STOPPER
      setPayment({
        ...payment,
        initiateData,
        amount: null,
        total: installmentTotal,
        ids: schedulesForPayment.map(({ id, installmentSequence }) => ({ id, sequence: installmentSequence })),
        isOverdue: schedulesForPayment.some((schedule) => schedule.status === PaymentStatus.OVERDUE),
      })
      setStep(PayNowSteps.Payment)
    } catch (e) {
      logEvent(TrackingEvent.CLICKED_MAKE_A_PAYMENT_CONFIRM_AMOUNT_FAILURE, {
        id: orderId,
        outboundLink: router?.pathname,
        error: e?.code,
      })
    }
  }

  const handleCustomPaymentSubmit = async (): Promise<void> => {
    logEvent(TrackingEvent.CLICKED_MAKE_A_CUSTOM_PAYMENT, { orderId, customAmount: customMoneyAmount?.amount })
    try {
      const initiateData = await initiateCustomAmountPayment({ amount: customMoneyAmount })
      setPayment({
        initiateData,
        // @ts-ignore: OPERATION BLEED STOPPER
        total: null,
        amount: customMoneyAmount,
      })
      setStep(PayNowSteps.Payment)
    } catch (e) {
      logEvent(TrackingEvent.CLICKED_MAKE_A_PAYMENT_CONFIRM_CUSTOM_PAYMENT_AMOUNT_FAILURE, {
        id: orderId,
        outboundLink: router?.pathname,
        errorCode: e?.code,
        errorMsg: e?.response,
      })
    }
  }

  useEffect(() => {
    const isPayNowStepUnset = payNowStep === null
    const isInstallmentTotalUnset = installmentTotal === null
    if (!hasAccountLevelPayments) {
      if (payableSchedules.length <= 0) {
        return
      }
      if (isPayNowStepUnset) {
        setPayNowStep(hasInstallmentLastAndOwed ? PayNowInput.REMAINING : PayNowInput.NEXT)
      }
      if (!isPayNowStepUnset && isInstallmentTotalUnset) {
        setInstallmentTotal(payNowStep === PayNowInput.REMAINING ? totalAmountPayable : payableSchedules[0]?.amountPayable)
      }
    }
  }, [hasInstallmentLastAndOwed, payNowStep, installmentTotal, payableSchedules, setPayNowStep, totalAmountPayable, hasAccountLevelPayments])

  useEffect(() => {
    if (hasAccountLevelPayments) {
      if (payNowStep === null) {
        setPayNowStep(isFinalHardshipRepayment ? PayNowInput.REMAINING : PayNowInput.NEXT)
        setInstallmentTotal(isFinalHardshipRepayment ? totalRepaymentScheduleAmount : payableRepayment.outstandingBalance)
      }
      if (installmentTotal === null && payNowStep !== null) {
        setInstallmentTotal(payNowStep === PayNowInput.REMAINING ? totalRepaymentScheduleAmount : payableRepayment?.outstandingBalance)
      }
    }
  }, [hasAccountLevelPayments, payNowStep, installmentTotal, isFinalHardshipRepayment, totalRepaymentScheduleAmount, payableRepayment, setPayNowStep])

  useEffect(() => {
    if (hasAccountLevelPayments && payNowStep !== null && installmentTotal !== null) {
      setPaymentForAccount(payNowStep)
    }
  }, [hasAccountLevelPayments, payNowStep, installmentTotal])

  useEffect(() => {
    // Maintains custom amount if the user clicks back from payment methods modal
    if (payNowStep === PayNowInput.CUSTOM && customAmount !== '0') {
      setInstallmentTotal(customMoneyAmount)
    }
  }, [payNowStep, customAmount])

  useEffect(() => {
    // Clears custom amount to stop CTA being enabled in error
    if (payNowStep !== PayNowInput.CUSTOM && customAmount !== '0') {
      setCustomAmount('0')
    }
  }, [payNowStep, customAmount])

  useEffect(() => {
    if (isCustomInputActive) {
      if (isCustomInputActiveAndValidAmount && belowMaxAmount) {
        setIsSubmitEnabled(true)
      } else {
        setIsSubmitEnabled(false)
      }
    }
  }, [belowMaxAmount, customMoneyAmount, isCustomInputActive, isCustomInputActiveAndValidAmount])

  useEffect(() => {
    !isCustomInputActive && setIsSubmitEnabled(true)
  }, [isCustomInputActive])

  useEffect(() => {
    setIsLoading(isLoading || isInitiateInstallmentLoading || isInitiateCustomLoading)
  }, [isLoading, isInitiateInstallmentLoading, setIsLoading, isInitiateCustomLoading])

  useEffect(() => {
    // @ts-ignore: OPERATION BLEED STOPPER
    setOrder({ merchant: getMerchantName(data?.merchant), paymentType: data?.paymentType, orderId })
  }, [data, orderId, setOrder])

  useEffect(() => {
    if (isError || isInitiateCustomError) {
      setResult({ error: true, success: false })
    }
  }, [isError, setResult, isInitiateCustomError])

  useEffect(() => {
    setHasAccountLevelPayments(consumerHasAccountLevelPayments && isHardshipEnabled)
  }, [setHasAccountLevelPayments, consumerHasAccountLevelPayments, isHardshipEnabled])

  return (
    <>
      <TrackOnMount eventName={TrackingEvent.VIEWED_MAKE_A_PAYMENT_INSTALLMENTS} eventProps={{ outboundLink: router?.pathname, paymentType }} />
      <Modal.Header divider>
        <PaymentNavigation />
      </Modal.Header>
      <Modal.Content>
        <div className='mt-2'>
          <Text>{t('payments:customPayment:installmentsv2:body')}</Text>
        </div>
        <InstallmentItems
          schedules={{
            nextInstallment,
            remainingInstallments: totalRepayment,
            isLastInstallment: hasAccountLevelPayments ? isFinalHardshipRepayment : hasInstallmentLastAndOwed,
            totalInstallmentCount,
            // @ts-ignore: OPERATION BLEED STOPPER
            lateFeesInformation,
          }}
          customAmount={{
            setCustomAmount: handleSetCustomAmount,
            isCustomInputActive,
            // @ts-ignore: OPERATION BLEED STOPPER
            currencySymbol: totalRepayment?.symbol,
            belowMaxAmount,
          }}
          handleChange={handleChange}
        />
        {!belowMaxAmount && (
          <div className='mb-6'>
            <Text color='Fire' size='S' testNameSpace='paynow-custom-error'>
              {t('payments:customPayment:customAmount:error:maximumAmountExceeded', { amount: formatMoney(totalRepayment.amount) })}
            </Text>
          </div>
        )}
        <Button.Primary padding='Fluid' disabled={!isSubmitEnabled} onClick={handleSubmit} testNameSpace='paynow-submit'>
          <Text bold testNameSpace='paynow-cta'>
            {t('payments:customPayment:installmentsv2:confirm:cta')}{' '}
            {(isCustomInputActiveAndValidAmount || !isCustomInputActive) && <Money value={installmentTotal} bold size='M' />}
          </Text>
        </Button.Primary>
      </Modal.Content>
    </>
  )
}
