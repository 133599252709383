import { AccountStatus, FrozenStatus, IdentityCheckStatus, PaymentMethodType, PreferredPaymentMethod } from '@afterpay/types'
import {
  ConsumerAccountResponse,
  ConsumerAddress,
  Country,
  CreditFacilityStatus,
  CreditFacilityStatuses,
  DocumentVerificationResult,
  Money,
  TransformedIdentityVerificationDocument,
} from '@afterpaytouch/portal-api'
import { accountService } from '@afterpaytouch/portal-api/consumer/account'
import { toVerifiedDocument, transformIdentityDocument } from '../../utils/array'
import { hasPreferredPaymentMethod, isHardIdVerificationRequiredLocale, isIdentityValid } from './consumer/utils'
import { lookupService } from '@afterpaytouch/portal-api/consumer/lookup'
import { createSelector } from '@reduxjs/toolkit'
import { IdentityCheckMetadata, SupportedLocale } from '@afterpaytouch/portal-api/types'
import { ConsumerState } from './consumer/reducer'
import { ConsumerAppSelector } from '../store'
import { useAppSelector } from '../hooks'
import { getConsumerName } from './consumer/helper'
export const {
  useUpdateAccountMutation,
  useDeactivateAccountMutation,
  useReactivateAccountMutation,
  useUpdateAccountDetailMutation,
  useGetAccountQuery,
  useLazyGetAccountQuery,
  useGetAccountCloseEligibleQuery,
  useCloseAccountMutation,
  useFinaliseAccountDetailsMutation,
  useUnsuitabilityQuestionsMutation,
  useCreditContractMutation,
  endpoints: { getAccount },
} = accountService

export const {
  useEmailLookupMutation,
  endpoints: { emailLookup },
} = lookupService

export const useGetAccountData = (): ConsumerAccountResponse => getAccount.useQueryState()?.data ?? ({} as ConsumerAccountResponse)

/* Selectors */

export const consumerSelector: ConsumerAppSelector<ConsumerState> = (state) => state.consumer

export const emailSelector: ConsumerAppSelector<string> = createSelector(consumerSelector, (state) => state.email ?? '')

export const preferredLocaleSelector: ConsumerAppSelector<SupportedLocale | undefined> = createSelector(consumerSelector, (state) => state.preferredLocale)

export const preferredPaymentMethod: ConsumerAppSelector<PreferredPaymentMethod | undefined> = createSelector(
  consumerSelector,
  (state) => state.preferredPaymentMethod
)

export const consumerNameSelector: ConsumerAppSelector<string> = createSelector(consumerSelector, (state) => getConsumerName(state.givenNames, state.surname))

export const consumerAccountCountrySelector: ConsumerAppSelector<Country | undefined> = createSelector(consumerSelector, (state) => state.countryCode)

export const smsVerificationEnabledSelector: ConsumerAppSelector<{ smsVerificationEnabled: boolean }> = createSelector(consumerSelector, (state) => ({
  smsVerificationEnabled: state.smsVerificationEnabled,
}))

export const identityCheckMetaDataSelector: ConsumerAppSelector<IdentityCheckMetadata | undefined> = createSelector(
  consumerSelector,
  (state) => state.identityCheckMetadata
)

/* React Hooks */

export const useConsumerSelector = (): ConsumerState => useAppSelector(consumerSelector)

export const useConsumerName = (): string => useAppSelector(consumerNameSelector)

export const useConsumerAccountCountry = (): Country | undefined => useAppSelector(consumerAccountCountrySelector)

export const usePreferredPaymentMethod = (): PreferredPaymentMethod | undefined => useAppSelector(preferredPaymentMethod)

export const useConsumerPreferredLocale = (): SupportedLocale | undefined => {
  return useAppSelector(preferredLocaleSelector)
}

export const useConsumerFeatureFlagReady = (): boolean => {
  const consumer = useConsumerSelector()
  return consumer?.hasFeatureFlagsReady ?? false
}

export const useConsumerAccountStatus = (): AccountStatus | undefined => {
  return useConsumerSelector()?.accountStatus
}

export const useConsumerAccountIsSuspended = (): boolean => useConsumerAccountStatus() === AccountStatus.SUSPENDED

export const useConsumerAccountIsInHardship = (): boolean =>
  [AccountStatus.HARDSHIP, AccountStatus.PAYMENT_ARRANGEMENT].includes(useConsumerAccountStatus() as AccountStatus)

export const useConsumerEmail = (): string | null => useConsumerSelector()?.email ?? null

export const useConsumerMobile = (): string | undefined => useConsumerSelector()?.phoneNumber

export const useConsumerContactAddress = (): ConsumerAddress | null | undefined => useConsumerSelector()?.contactAddress

export const useConsumerIdentityStatus = (): IdentityCheckStatus | undefined => useConsumerSelector()?.identityCheckStatus

export const useConsumerIdentityIsValid = (): boolean => isIdentityValid(useConsumerSelector()?.identityCheckStatus)

export const useConsumerPreferredPaymentMethod = (): PreferredPaymentMethod | PaymentMethodType | null | undefined =>
  useConsumerSelector()?.preferredPaymentMethod

export const useConsumerLocaleHardIdentityCheckIsRequired = (): boolean => isHardIdVerificationRequiredLocale(useConsumerPreferredLocale())

export const useConsumerHasPreferredPaymentMethod = (): boolean => hasPreferredPaymentMethod(useConsumerSelector()?.preferredPaymentMethod)

export const useConsumerIsFrozenStatus = (): FrozenStatus | undefined => useConsumerSelector()?.frozenStatus

export const useConsumerIsFrozenStatusAccount = (): boolean | undefined => useConsumerIsFrozenStatus()?.accountFrozen

export const useConsumerIsDeactivated = (): boolean => {
  const accountFrozen = useConsumerIsFrozenStatus()
  return Boolean(accountFrozen?.accountFrozen) && Boolean(accountFrozen?.selfExclusion)
}

export const useConsumerCountry = (): Country | undefined => useConsumerSelector()?.countryCode

export const useConsumerCreatedDate = (): number | undefined => useConsumerSelector()?.createdDate

export const useConsumerCreatedMonth = (): number | string => {
  const date = new Date(useConsumerCreatedDate() as number).getMonth()
  return !isNaN(date) ? date : ''
}

export const useConsumerCreatedYear = (): number | string => {
  const date = new Date(useConsumerCreatedDate() as number).getFullYear()
  return !isNaN(date) ? date : ''
}

export const useConsumerHasAccountLevelPayments = (): boolean => useConsumerAccountStatus() === AccountStatus.PAYMENT_ARRANGEMENT

export const useConsumerCreatedMonthByLocale = (locale: string): string => {
  const date = useConsumerCreatedDate()
  return !isNaN(date as number) ? new Intl.DateTimeFormat(locale, { month: 'long' }).format(date) : ''
}

export const useIsPartialVerified = (): boolean => useConsumerIdentityStatus() === IdentityCheckStatus.PARTIAL_VERIFIED

export const useConsumerCreditLimitOverride = (creditLimit: Money): Money => {
  const isSuspended = useConsumerAccountIsSuspended()
  const isInHardship = useConsumerAccountIsInHardship()
  return isSuspended || isInHardship ? { ...creditLimit, amount: '0.00' } : creditLimit
}

export const useConsumerGivenName = (): string | undefined => useConsumerSelector()?.givenNames

export const useConsumerSurname = (): string | undefined => useConsumerSelector()?.surname

export const useConsumerDocumentVerificationResults = (): DocumentVerificationResult[] | undefined => useConsumerSelector()?.documentVerificationResults

export const useConsumerVerifiedIdentityDocuments = (): TransformedIdentityVerificationDocument[] =>
  transformIdentityDocument(toVerifiedDocument(useConsumerDocumentVerificationResults() ?? []))

export const useConsumerHasRestrictedAccount = (): boolean => {
  const restrictedAccounts = [AccountStatus.HARDSHIP, AccountStatus.SUSPENDED, AccountStatus.PAYMENT_ARRANGEMENT]
  return restrictedAccounts.includes(useConsumerAccountStatus() as AccountStatus)
}

export const useConsumerLatestFacilityStatus = (): ConsumerAccountResponse['latestFacilityStatus'] | undefined => useConsumerSelector()?.latestFacilityStatus

export const useConsumerHasCreditFacility = (): boolean => {
  const latestFacilityStatus = useConsumerLatestFacilityStatus()

  const validStatuses: CreditFacilityStatus[] = [CreditFacilityStatuses.Closed, CreditFacilityStatuses.Open]

  return latestFacilityStatus !== undefined && validStatuses.includes(latestFacilityStatus)
}
