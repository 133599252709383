import { useRouter } from 'next/router'
import { PageNames } from '../integrations/amplitude/types'
import { IdentityDocument } from '../model'

// TODO: [PAYL-18020] CP: Better route enum
export enum Route {
  INDEX = '/',
  LOGIN_PASSWORD = '/password',
  CREATE_PASSWORD_VERIFY = '/create-password-verify',
  CREATE_PASSWORD = '/create-password',
  HOME = '/home',
  INDEX__PAYNOW_ADDBANKACCOUNTMODAL = '/?modal-pay-now=true&modal-add-bank-account=true',
  UPCOMING_PAYMENTS = '/upcoming-payments',
  UPCOMING_PAYMENTS__PAYNOW_ADDBANKACCOUNTMODAL = '/upcoming-payments?modal-pay-now=true&modal-add-bank-account=true',
  ORDERS = '/orders',
  ORDER = '/orders/[id]',
  ORDER__PAYNOW_ADDBANKACCOUNTMODAL = '/orders/[id]?modal-pay-now=true&modal-add-bank-account=true',
  ORDER__CHANGEPAYMENTMETHOD_ADDBANKACCOUNTMODAL = '/orders/[id]?modal-change-payment-method=true&modal-change-payment-add-bank-account=true',
  ACCOUNT = '/account',
  ACCOUNT__PROFILE = '/account?tab-acc=profile',
  ACCOUNT__PROFILE__CLOSEORREACTIVATEACCOUNTMODAL = '/account?tab-acc=profile&modal-cora=true',
  ACCOUNT__PULSE = '/account?tab-acc=pulse',
  ACCOUNT__PAYMENT_METHODS = '/account?tab-acc=payment-methods',
  ACCOUNT__PAYMENT_SETTINGS = '/account?tab-acc=payment-settings',
  ACCOUNT__PAYMENT_METHODS__ADDBANKACCOUNTMODAL = '/account?tab-acc=payment-methods&modal-add-bank-account=true',
  ACCOUNT__PREFERENCES = '/account?tab-acc=preferences',
  ACCOUNT__SECURITY = '/account?tab-acc=security',
  PPA = '/ppa/[documentId]',
  RECURRING_PAYMENTS = '/recurring-payments',
  PLAID_FINISHED = '/plaid-finished',
  RECURRING_PAYMENTS__CHANGEPAYMENTMETHOD_ADDBANKACCOUNTMODAL = '/recurring-payments?modal-change-payment-method=true&modal-add-bank-account=true',
  UNSUBSCRIBE = '/unsubscribe',
  VERIFY_IDENTITY = '/verify-identity',
  VERIFY_ERROR = '/verify-error',
  VERIFY_LICENCE = '/verify-identity/licence',
  VERIFY_MEDICARE = '/verify-identity/medicare',
  VERIFY_PASSPORT = '/verify-identity/passport',
  VERIFY_ADDRESS = '/verify-identity/address',
  RETRY_VERIFY_LICENCE = '/verify-identity/retry/licence',
  RETRY_VERIFY_PASSPORT = '/verify-identity/retry/passport',
  RETRY_VERIFY_MEDICARE = '/verify-identity/retry/medicare', // only being used in unit test
  VERIFY_RETRY = '/verify-retry',
  TERMINAL_ERROR = '/error',
  TERMINAL_ERROR_DEFAULT = '/error/default',
  PASSWORD_RESET_REQUIRED = '/password-reset-required',
  FORCE_PASSWORD_RESET = '/force-password-reset',
  PROTECT_ACCOUNT = '/order-email-survey/account-protect',
  CONFIRM_ORDER = '/order-email-survey/order-confirm',
  ACCOUNT__PROFILE__REACTIVATEACCOUNTMODAL = '/account?tab-acc=profile&modal-reactivate-account=true',
  PAY_MONTHLY = '/pay-monthly',
  PAY_MONTHLY_DECREASE_LIMIT = '/pay-monthly/decrease-limit',
  VERIFY_CHALLENGE = '/verify-challenge',
  VERIFY_2FA = '/verify-2fa',
  PROFILE_CONFIRM = '/profile-confirm',
  UNSUITABILITY_QUESTION_FORM = 'unsuitability-questions/form',
  CONTRACT_AGREEMENT = '/contract-agreement',
}

const UnauthenticatedRoutes: { [key: string]: Route } = {
  Unsubscribe: Route.UNSUBSCRIBE,
  PlaidFinished: Route.PLAID_FINISHED,
  PasswordResetRequired: Route.PASSWORD_RESET_REQUIRED,
  ForcePasswordReset: Route.FORCE_PASSWORD_RESET,
  ProtectAccount: Route.PROTECT_ACCOUNT,
  ConfirmOrder: Route.CONFIRM_ORDER,
  Index: Route.INDEX,
  VerifyChallenge: Route.VERIFY_CHALLENGE,
  Verify2FA: Route.VERIFY_2FA,
  LoginPassword: Route.LOGIN_PASSWORD,
  CreatePasswordVerify: Route.CREATE_PASSWORD_VERIFY,
  CreatePassword: Route.CREATE_PASSWORD,
} as const
const LoginFlowRoutes: { [key: string]: Route } = {
  PasswordResetRequired: Route.PASSWORD_RESET_REQUIRED,
  ForcePasswordReset: Route.FORCE_PASSWORD_RESET,
  VerifyChallenge: Route.VERIFY_CHALLENGE,
  LoginPassword: Route.LOGIN_PASSWORD,
  CreatePasswordVerify: Route.CREATE_PASSWORD_VERIFY,
  CreatePassword: Route.CREATE_PASSWORD,
} as const
export type UnauthenticatedRoute = (typeof UnauthenticatedRoutes)[keyof typeof UnauthenticatedRoutes]
export const UnauthenticatedRoutesList: UnauthenticatedRoute[] = Object.values(UnauthenticatedRoutes)
export const LoginFlowRoutesList: UnauthenticatedRoute[] = Object.values(LoginFlowRoutes)

export const routePageNames: Partial<Record<Route, PageNames>> = {
  [Route.INDEX]: PageNames.INDEX,
  [Route.LOGIN_PASSWORD]: PageNames.LOGIN_PASSWORD,
  [Route.CREATE_PASSWORD_VERIFY]: PageNames.CREATE_PASSWORD_VERIFY,
  [Route.CREATE_PASSWORD]: PageNames.CREATE_PASSWORD,
  [Route.PROFILE_CONFIRM]: PageNames.PROFILE_CONFIRM,
  [Route.HOME]: PageNames.HOME,
  [Route.UPCOMING_PAYMENTS]: PageNames.UPCOMING_PAYMENTS,
  [Route.ORDERS]: PageNames.ORDERS,
  [Route.ORDER]: PageNames.ORDER,
  [Route.ACCOUNT]: PageNames.ACCOUNT,
  [Route.PPA]: PageNames.PPA,
  [Route.RECURRING_PAYMENTS]: PageNames.RECURRING_PAYMENTS,
  [Route.UNSUBSCRIBE]: PageNames.UNSUBSCRIBE,
  [Route.VERIFY_IDENTITY]: PageNames.VERIFY_IDENTITY,
  [Route.VERIFY_LICENCE]: PageNames.VERIFY_LICENCE,
  [Route.VERIFY_MEDICARE]: PageNames.VERIFY_MEDICARE,
  [Route.VERIFY_PASSPORT]: PageNames.VERIFY_PASSPORT,
  [Route.RETRY_VERIFY_LICENCE]: PageNames.RETRY_VERIFY_HARD_ID,
  [Route.RETRY_VERIFY_PASSPORT]: PageNames.RETRY_VERIFY_HARD_ID,
  [Route.RETRY_VERIFY_MEDICARE]: PageNames.RETRY_VERIFY_HARD_ID,
  [Route.VERIFY_ADDRESS]: PageNames.SIGNUP_RETRY,
  [Route.VERIFY_RETRY]: PageNames.VERIFY_RETRY,
  [Route.FORCE_PASSWORD_RESET]: PageNames.FORCE_PASSWORD_RESET,
  [Route.PROTECT_ACCOUNT]: PageNames.PROTECT_ACCOUNT,
  [Route.CONFIRM_ORDER]: PageNames.CONFIRM_ORDER,
  [Route.PAY_MONTHLY]: PageNames.PAY_MONTHLY,
  [Route.PAY_MONTHLY_DECREASE_LIMIT]: PageNames.PAY_MONTHLY_DECREASE_LIMIT,
  [Route.TERMINAL_ERROR]: PageNames.ERROR,
  [Route.VERIFY_2FA]: PageNames.VERIFY_2FA,
  [Route.VERIFY_CHALLENGE]: PageNames.VERIFY_CHALLENGE,
  [Route.UNSUITABILITY_QUESTION_FORM]: PageNames.UNSUITABILITY_QUESTIONS,
  [Route.CONTRACT_AGREEMENT]: PageNames.CONTRACT_AGREEMENT,
}

export const useRoutePageName = (): PageNames => {
  const { pathname } = useRouter()
  return routePageNames[pathname]
}

export const useIsUnauthenticatedRoute = (): boolean => {
  return UnauthenticatedRoutesList.includes(useRouter().route as Route)
}
export const useIsLoginFlowRoute = (): boolean => {
  return LoginFlowRoutesList.includes(useRouter().route as Route)
}

export const verifyDocumentRoute = (document: IdentityDocument, retry = false): Route => `/verify-identity/${retry ? 'retry/' : ''}${document}` as Route

export const useIsAccountPage = (): boolean => {
  const router = useRouter()
  return router?.pathname === '/account'
}
