import { Panel, Skeleton, Text, useIsBreakpointLargeAndUp, useIsBreakpointMediumAndUp, useIsBreakpointSmall, useIsCashTheme } from '@afterpaytouch/core'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { useFlag } from '../../../hooks'
import React, { FunctionComponent } from 'react'
import { AppLink, ExtendedPanel } from '../..'
import styles from './style.module.scss'
import clsx from 'clsx'
import { useExistingUserEffects, useExistingUserLocalization, useExistingUserStatusMessages } from './hooks'
import { renderStatusMessage } from './StatusMessage/utils'
import { Greeting } from '../Greeting'
import { HeaderInfoDisplay } from '../HeaderInfoDisplay'
import { PayInFourCard } from '../PayInFourCard'
import { MonthlyPaymentsCard } from '../MonthlyPaymentsCard'
import { Route } from '../../../config/router'
import { ConsumerLendingIntroModal, useConsumerLendingIntroModal, useShowConsumerLendingIntroModalOnMount } from '../../ConsumerLendingIntroModal'
import { PayInFourCardUS } from '../PayInFourCard/PayInFourCardUS'
import { MonthlyPaymentsCardUS } from '../MonthlyPaymentsCard/MonthlyPaymentsCardUS'

export const ExistingUser: FunctionComponent = () => {
  const isCashTheme = useIsCashTheme()
  const isBpMediumUp = useIsBreakpointMediumAndUp()
  const isBpLargeUp = useIsBreakpointLargeAndUp()
  const isBpSmall = useIsBreakpointSmall()
  const classNames = clsx(isBpLargeUp && styles.bpLargeUp)
  const textGroupMainSize = isBpMediumUp ? 'XXXL' : 'XXL'
  const { t, locale } = useExistingUserLocalization()
  const {
    consumer,
    isLoading: isExistingUserLoading,
    isFetching: isExistingUserFetching,
    isError: isExistingUserError,
    refetchExistingUser,
  } = useExistingUserEffects()
  const statusMessages = useExistingUserStatusMessages(t, locale, consumer)
  const isConsumerLendingHeaderEnabled = useFlag('consumer-portal-header-lending-enabled', false)
  const isAvailableToSpendDisabled = useFlag('consumer-portal-available-to-spend-disabled', false)
  const isLoading = isExistingUserLoading || isExistingUserFetching

  const [isConsumerLendingIntroModalOpen, { openModal: openConsumerLendingIntroModal, closeModal: closeConsumerLendingIntroModal }] =
    useConsumerLendingIntroModal()
  useShowConsumerLendingIntroModalOnMount(openConsumerLendingIntroModal, consumer)

  if (isExistingUserError) {
    return <ExtendedPanel.Error onClick={refetchExistingUser} />
  }

  const contentClassName = clsx(styles.contentContainer, isLoading ? styles.invisible : styles.visible)
  const activeOrders = t('index:welcome:existingUser:label:activeOrders')

  return (
    <div className={classNames}>
      <Panel.Head>
        <div className={styles.headContainer}>
          <div className={styles.headContentContainer}>
            <Greeting consumer={consumer} isLoading={isLoading} />
            <div className={styles.contentSkeletonContainer}>
              {isLoading && <Skeleton kind={isCashTheme ? 'Default' : 'Primary'} />}
              <div className={contentClassName}>
                {!isConsumerLendingHeaderEnabled && !isAvailableToSpendDisabled && (
                  <div className={clsx(styles.textGroupContainer, isBpSmall && 'w-full')}>
                    <HeaderInfoDisplay amount={consumer.availableToSpend} amountSize={textGroupMainSize} locale={locale as SupportedLocale} isCreditLimit />
                  </div>
                )}
                <div className={isConsumerLendingHeaderEnabled ? 'flex' : styles.rightTextGroupContainer}>
                  <div className={styles.textGroupContainer}>
                    <HeaderInfoDisplay
                      amount={consumer.totalOwed ?? { amount: '0.00', currency: 'AUD', symbol: '$' }}
                      amountSize={textGroupMainSize}
                      locale={locale as SupportedLocale}
                      isOwedAmount
                    />
                  </div>
                  {isAvailableToSpendDisabled && (
                    <div className={styles.textGroupContainer}>
                      <Text renderAs='span' size={textGroupMainSize} bold>
                        {`${consumer.numActiveOrders}`}
                      </Text>
                      <AppLink route={Route.ORDERS} kind='Underline'>
                        <Text renderAs='span' size='M' bold>
                          {activeOrders}
                        </Text>
                      </AppLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isConsumerLendingHeaderEnabled && (
            <div className={'mt-4 sm:block md:flex md:flex-row md:flex-wrap'}>
              <div className={'md:mt-2 md:w-[259px] md:first:mr-4 lg:mr-4 lg:mt-0 lg:last:mr-0'}>
                {locale === 'en-US' ? (
                  <PayInFourCardUS consumer={consumer} isLoading={isLoading} />
                ) : (
                  <PayInFourCard consumer={consumer} isLoading={isLoading} />
                )}
              </div>
              <div className={'sm:mt-4 md:mt-2 md:w-[259px] lg:mr-4 lg:mt-0 lg:last:mr-0'}>
                {locale === 'en-US' ? (
                  <MonthlyPaymentsCardUS consumer={consumer} isLoading={isLoading} />
                ) : (
                  <MonthlyPaymentsCard consumer={consumer} isLoading={isLoading} />
                )}
              </div>
            </div>
          )}
        </div>
      </Panel.Head>
      <Panel.Content>{renderStatusMessage(consumer, statusMessages, isLoading)}</Panel.Content>
      <ConsumerLendingIntroModal isModalOpen={isConsumerLendingIntroModalOpen} closeModal={closeConsumerLendingIntroModal} />
    </div>
  )
}
