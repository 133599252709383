import { Button, Heading, Icon } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'
import styles from './styles.module.scss'

const I18N_NAMESPACE = ['common']

interface ErrorDialogProps {
  onClick: () => void
}

export const ErrorDialog: FunctionComponent<ErrorDialogProps> = ({ onClick }) => {
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <div className={styles.container}>
      <Heading>{t('common:error:defaultErrorMsg')}</Heading>
      <Button.Secondary size='Regular' onClick={onClick} aria-label={t('common:error:retryButton')}>
        <Icon.Refresh size='Small' color='White' />
        {t('common:error:retryButton')}
      </Button.Secondary>
    </div>
  )
}
