import { CardBrand } from '@afterpay/types'
import { Button, Checkbox, InputAddressData, Loader, Text } from '@afterpaytouch/core'
import React, { FunctionComponent, useState, useMemo, useEffect } from 'react'
import { useFlag } from '../../hooks'
import { useTranslation } from 'next-i18next'
import { useConsumerSizes } from '../../utils/responsive'
import { app } from '../../env'
import { useConsumerSelector, useConsumerName, useGetEnabledCardsQuery } from '../../state'
import FormBuilder, { CardDefaultValue, FieldType, Schema } from '@afterpaytouch/form'
import { requiredAddress } from './validations'
import { getAddressOptionalFields } from '@afterpay/utils'
import { cardsEnabled } from '../../utils/enabledCards'
import { ErrorDialog } from '../ErrorDialog'
import { convertConsumerAddress } from '../../utils/consumer'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { useRouter } from 'next/router'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { billingAddressFFFallback } from '../../utils/ldFallback'

const I18N_NAMESPACE = ['account']

export interface CardFormSchema {
  card: CardDefaultValue
  address?: InputAddressData
  warning?: boolean
  footer?: boolean
  storeToken?: boolean
}

interface CardFormProps {
  handleSubmit: (data?: any) => void
  error: string | null
  ctaLabel?: string
  showError?: boolean
  submitting?: boolean
  merchantEnabledCards?: CardBrand[]
  handleChange?: (data?: any) => void
  displayStorePaymentToken?: boolean
  handleOnFocus?: () => void
}

export const CardForm: FunctionComponent<CardFormProps> = ({
  handleSubmit,
  ctaLabel,
  showError = false,
  error,
  submitting,
  handleChange,
  merchantEnabledCards,
  displayStorePaymentToken = true,
  handleOnFocus,
}: CardFormProps) => {
  const consumerName = useConsumerName()
  const consumerData = useConsumerSelector()
  const { data: enabledCardsData, isLoading: isEnabledCardsLoading, isError: isEnabledCardsError, refetch: refetchEnabledCardsData } = useGetEnabledCardsQuery()

  const consumerCountry = consumerData?.contactAddress?.countryCode
  const consumerAddress = consumerData?.contactAddress
  const router = useRouter()
  const locale = router?.locale as SupportedLocale
  const needAddressDetails = useFlag('billing-address-details', billingAddressFFFallback(locale))
  const [residentialAsBilling, setResidentialAsBilling] = useState<boolean>(true)
  const isPaymentsTab = router?.query?.['tab-acc'] === 'payment-settings' || router?.query?.['tab-acc'] === 'payment-methods'
  const showSecondaryPaymentDisclaimer = isPaymentsTab && consumerCountry === 'US'

  const { t } = useTranslation(I18N_NAMESPACE)
  const { textSize, smallTextSize } = useConsumerSizes()
  const [isFormReady, setIsFormReady] = useState<boolean>(false)

  const [enabledCards, setEnabledCards] = useState<CardBrand[]>([])

  const isHiddenCardHolderName = useFlag('consumer-portal-hide-card-holder-name')
  const isAmexSoftCloseEnabled = useFlag('amex-soft-close-enabled', false)
  useEffect(() => {
    if (merchantEnabledCards !== null && !isAmexSoftCloseEnabled) {
      // @ts-ignore: OPERATION BLEED STOPPER
      setEnabledCards(merchantEnabledCards)
    } else if (merchantEnabledCards !== null && isAmexSoftCloseEnabled) {
      // @ts-ignore: OPERATION BLEED STOPPER
      setEnabledCards(merchantEnabledCards != null ? merchantEnabledCards.filter((card) => card !== 'AMEX') : merchantEnabledCards)
    }

    if (merchantEnabledCards === null && !isEnabledCardsLoading && !isEnabledCardsError && !isAmexSoftCloseEnabled) {
      // @ts-ignore: OPERATION BLEED STOPPER
      setEnabledCards(cardsEnabled(enabledCardsData))
    } else if (merchantEnabledCards === null && !isEnabledCardsError && !isEnabledCardsLoading && isAmexSoftCloseEnabled) {
      // @ts-ignore: OPERATION BLEED STOPPER
      const enabledCardRes = cardsEnabled(enabledCardsData)
      // @ts-ignore: OPERATION BLEED STOPPER
      setEnabledCards(enabledCardRes.filter((card) => card !== 'AMEX'))
    }
  }, [isEnabledCardsLoading, isEnabledCardsError, setEnabledCards, enabledCardsData, refetchEnabledCardsData, merchantEnabledCards, isAmexSoftCloseEnabled])

  /**
   * Form Schema for builders
   */
  // @ts-ignore: OPERATION BLEED STOPPER
  const schema: Schema<CardFormSchema> = useMemo(
    () => ({
      card: {
        type: FieldType.Card,
        showCardHolderNameField: !isHiddenCardHolderName,
        fieldTitle: t('account:tab:payment-methods:modal:form:title'),
        fieldTitleSize: textSize,
        acceptableCardBrands: enabledCards,
        defaultValues: {
          cardHolderName: consumerName,
          expiryDate: '',
          cardNumber: '',
          cvv: '',
        },
        errorMessages: {
          textSize: 'S',
          cardHolderName: {
            empty: '',
          },
          expiryDate: {
            empty: t('account:tab:payment-methods:modal:form:error:expiryDate:empty'),
            past: t('account:tab:payment-methods:modal:form:error:expiryDate:past'),
            expireSoon: t('account:tab:payment-methods:modal:form:error:expiryDate:expireSoon'),
          },
          cardNumber: {
            invalid: t('account:tab:payment-methods:modal:form:error:cardNumber:invalid'),
            brandNotAccepted: (cardBrand: CardBrand): string =>
              cardBrand === 'AMEX' && isAmexSoftCloseEnabled
                ? t('account:tab:payment-methods:modal:form:error:cardNumber:brandNotAcceptedAmex')
                : t('account:tab:payment-methods:modal:form:error:cardNumber:brandNotAccepted', { cardBrand }),
          },
          cvv: {
            invalid: t('account:tab:payment-methods:modal:form:error:cvv:invalid'),
          },
        },
        labels: {
          cardHolderName: t('account:tab:payment-methods:modal:form:labels:cardHolderName'),
          expiryDate: t('account:tab:payment-methods:modal:form:labels:expiryDate'),
          cvv: t('account:tab:payment-methods:modal:form:labels:cvv'),
          cardNumber: t('account:tab:payment-methods:modal:form:labels:cardNumber'),
        },
      },
      middle: {
        type: FieldType.Component,
        show: needAddressDetails,
        Component: () => (
          <Checkbox
            id='card-form-billing-address'
            label={t('account:tab:payment-methods:modal:form:billingAddress')}
            checked={residentialAsBilling}
            defaultChecked={undefined}
            onChange={(e) => setResidentialAsBilling(e.target.checked)}
            testNameSpace='billing-address'
          />
        ),
      },
      address: {
        type: FieldType.Address,
        apiKey: app.GOOGLEMAPS_API_KEY,
        show: needAddressDetails && !residentialAsBilling,
        props: {
          initialAddress: residentialAsBilling ? { ...consumerData?.contactAddress } : undefined,
          label: t('account:tab:payment-methods:modal:form:address:address'),
          manualAddressLabel: t('account:tab:payment-methods:modal:form:address:findAddressLabel'),
          address1Label: t('account:tab:payment-methods:modal:form:address:address1Label'),
          address2Label: t('account:tab:payment-methods:modal:form:address:address2Label'),
          postcodeLabel: t('account:tab:payment-methods:modal:form:address:postcodeLabel'),
          searchAddressLabel: t('account:tab:payment-methods:modal:form:address:searchAddressLabel'),
          componentRestrictions: { country: consumerCountry },
          suburbLabel: t('account:tab:payment-methods:modal:form:address:suburbLabel'),
          stateLabel: t('account:tab:payment-methods:modal:form:address:stateLabel'),
          // @ts-ignore: OPERATION BLEED STOPPER
          optionalManualAddressFields: getAddressOptionalFields(consumerCountry),
          testNameSpace: 'address',
        },
        options: needAddressDetails
          ? {
              ...requiredAddress({
                name: 'address',
                country: consumerCountry,
                t,
              }),
            }
          : { required: false },
      },
      warning: {
        type: FieldType.Component,
        show: showError,
        Component: () => (
          <div>
            <Text color='Fire' size='S'>
              {error}
            </Text>
          </div>
        ),
      },
      addressText: {
        type: FieldType.Component,
        show: residentialAsBilling && needAddressDetails,
        Component: () => (
          <Text size={textSize} color='Gray40'>
            {/* @ts-ignore: OPERATION BLEED STOPPER */}
            {convertConsumerAddress(consumerAddress)}
          </Text>
        ),
      },
      storeToken: {
        type: FieldType.Checkbox,
        show: displayStorePaymentToken,
        props: {
          label: () => <Text size={textSize}>{t('account:tab:payment-methods:modal:form:store')}</Text>,
          id: 'portal-store-payment-token',
        },
      },
      footer: {
        type: FieldType.Component,
        Component: () => (
          <Button.Primary
            type='submit'
            aria-label='submit'
            kind='Primary'
            padding='Fluid'
            loading={submitting}
            disabled={!isFormReady}
            testNameSpace='card-form'
          >
            {ctaLabel !== undefined ? ctaLabel : t('account:tab:payment-methods:modal:form:cta')}
          </Button.Primary>
        ),
      },
    }),
    [
      isHiddenCardHolderName,
      t,
      textSize,
      enabledCards,
      consumerName,
      needAddressDetails,
      residentialAsBilling,
      consumerData?.contactAddress,
      consumerCountry,
      showError,
      displayStorePaymentToken,
      isAmexSoftCloseEnabled,
      error,
      consumerAddress,
      submitting,
      isFormReady,
      ctaLabel,
    ]
  )

  const handleStateChange = (isValid: boolean): void => {
    setIsFormReady(isValid)
  }

  return isEnabledCardsError ? (
    <ErrorDialog onClick={refetchEnabledCardsData} />
  ) : isEnabledCardsLoading ? (
    <Loader pulseKind={'White'} />
  ) : (
    <div>
      {isHiddenCardHolderName && <div className={'mb-2'}>{<Text size={smallTextSize}>{t('account:tab:payment-methods:modal:legalDisclaimer')}</Text>}</div>}
      {showSecondaryPaymentDisclaimer && (
        <div className={'mb-2'}>
          <Text size={smallTextSize}>{t('account:tab:payment-methods:modal:disclaimer')}</Text>
        </div>
      )}
      <MaskedElement>
        <FormBuilder
          testNameSpace='card-form'
          schema={schema}
          onSubmit={handleSubmit}
          disabled={submitting}
          onStateChange={handleStateChange}
          onChange={handleChange}
          onFocus={handleOnFocus}
          formOptions={{ mode: 'onChange' }}
        />
      </MaskedElement>
    </div>
  )
}
