import React, { FC, useCallback, useMemo } from 'react'
import { useCreateDispute, useDisputeTranslation } from '../hooks'
import { useConsumerSizes } from '../../../utils/responsive'
import { Button, Heading, Icon, Modal, Text } from '@afterpaytouch/core'
import { DisputeCreationModalSteps, useDisputeCreationModalContext } from '../DisputeCreationModalContext'
import { DisputeReason } from '../../../model'

export const Disclaimer: FC = () => {
  const t = useDisputeTranslation()
  const { textSize } = useConsumerSizes()
  const { setStep, reason } = useDisputeCreationModalContext()
  const createDispute = useCreateDispute()

  const [heading, content, confirm] = useMemo(() => {
    return [t('preSubmit:disclaimer:heading'), t('preSubmit:disclaimer:content'), t('preSubmit:disclaimer:cta')]
  }, [t])

  const backHandler = useCallback(() => {
    if (reason === DisputeReason.OrderMismatch) {
      setStep(DisputeCreationModalSteps.ContactMerchantInfo)
      return
    }

    setStep(DisputeCreationModalSteps.SubmitEvidence)
  }, [setStep, reason])

  return (
    <>
      <Modal.Header divider={true}>
        <div className='absolute z-10 pt-1'>
          <Button.Link size='Small' onClick={backHandler}>
            <Icon.CaretLeft size='Small' />
          </Button.Link>
        </div>
        <div className='text-center'>
          <Heading size='L'>{heading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <Text size={textSize} renderAs='span'>
            {content}
          </Text>
        </div>
        <div className='mt-4'>
          <Button padding='Fluid' size='Large' onClick={createDispute}>
            {confirm}
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}
