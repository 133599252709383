// @TODO: Hoist this for use across rocketship
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { Country } from '../../model'
import { useConsumerSelector } from '../../state'
import { app } from '../../env'
import { useRouter } from 'next/router'
import { mapLocales } from '../../utils/mapLocales'
import { useIsCashTheme } from '@afterpaytouch/core'

interface AmplitudeCustomUserProperties {
  country: Country
  consumerId: number
  locale: SupportedLocale
  activeExperiment: string
  isNewPortalEnabled: boolean
  // hasSetupApplePay: boolean // TODO: change to enum
}

interface AmplitudeEnduringEventProperties {
  activeExperiment: string
  category: string
  consumerRedesign: boolean
  appEnvironment: string
  appVersion: string
  isCashTheme: boolean
}

export function useUserProperties(): AmplitudeCustomUserProperties {
  const { id } = useConsumerSelector()
  const router = useRouter()
  const locale = router.locale as SupportedLocale
  return {
    consumerId: id,
    locale,
    country: mapLocales('locale', locale) as Country,
    activeExperiment: '', // TODO: Integrate with LD A/B Test
    isNewPortalEnabled: true,
  }
}

export function useEnduringEventProperties(): AmplitudeEnduringEventProperties {
  const isCashTheme = useIsCashTheme()
  // @TODO: Add anything else that needs to go here
  const { APP_ENVIRONMENT, APP_VERSION } = app
  return {
    activeExperiment: '', // TODO: Integrate with LD A/B Test
    appEnvironment: APP_ENVIRONMENT,
    appVersion: APP_VERSION,
    category: 'Consumer',
    consumerRedesign: true,
    isCashTheme,
  }
}
