export enum SessionStorageActionType {
  SET = 'set-session-storage',
  GET = 'get-session-storage',
  REMOVE = 'remove-session-storage',
}

export enum SessionStorageUpdateActionType {
  EXTEND = 'extend-session-storage',
  TRIM = 'trim-session-storage',
}
/**
 * SessionStorageHelper handles the read/write/update of sessionStorage when we want to store complex object in sessionStorage
 * This helper could be quite useful for handling a variety of flows when we need to redirect the user to 3rd web app and
 * have to store certain statuses when user leaves portal so we could deliver a consisent FE experience to the user
 * with SessionStorageHelper, the user could dynamically update sessionStorage and
 * clean up all flow-specific sessionStorage after the flow finishes without removing them one by one
 * BasePlaid.tsx could be an example for the usage of SessionStorageHelper
 * Note: circular object is not supported by SessionStorageHelper
 */
export class SessionStorageHelper {
  readonly key: string
  static readonly instances: Map<string, SessionStorageHelper> = new Map()

  private constructor(key: string) {
    this.key = key
  }

  public static getInstance(key): SessionStorageHelper {
    if (!SessionStorageHelper.instances.has(key)) {
      SessionStorageHelper.instances.set(key, new SessionStorageHelper(key))
    }
    // @ts-ignore: OPERATION BLEED STOPPER
    return SessionStorageHelper.instances.get(key)
  }

  public handleSessionStorage(actionType: SessionStorageActionType, item?: any): any {
    switch (actionType) {
      case SessionStorageActionType.GET:
        // @ts-ignore: OPERATION BLEED STOPPER
        return JSON.parse(sessionStorage.getItem(this.key))
      case SessionStorageActionType.REMOVE:
        sessionStorage.removeItem(this.key)
        break
      case SessionStorageActionType.SET:
        sessionStorage.setItem(this.key, JSON.stringify(item))
        break
    }
  }

  public getSessionStorageByItemKey(itemKey: string): any {
    const existingSessionStorageObj = this.handleSessionStorage(SessionStorageActionType.GET)
    if (existingSessionStorageObj && Object.prototype.hasOwnProperty.call(existingSessionStorageObj, itemKey)) {
      return existingSessionStorageObj[itemKey]
    }
    return null
  }

  public updateSessionStorage(actionType: SessionStorageUpdateActionType, itemKey?: string, value?: any): void {
    const existingSessionStorage = sessionStorage.getItem(this.key)
    switch (actionType) {
      case SessionStorageUpdateActionType.EXTEND: {
        let existingSessionStorageObj = existingSessionStorage ? JSON.parse(existingSessionStorage) : {}
        existingSessionStorageObj = { ...existingSessionStorageObj, ...value }
        sessionStorage.setItem(this.key, JSON.stringify(existingSessionStorageObj))
        break
      }
      case SessionStorageUpdateActionType.TRIM:
        if (existingSessionStorage) {
          const existingSessionStorageObj = JSON.parse(existingSessionStorage)
          // Object.hasOwnProperty() yields the ESLint 'no-prototype-builtins'
          if (Object.prototype.hasOwnProperty.call(existingSessionStorageObj, itemKey)) {
            // dynamically delete object key could be dangerous, for example hasOwnProperty could be deleted by accident
            this.handleSessionStorage(
              SessionStorageActionType.SET,
              Object.keys(existingSessionStorage).reduce((prevValue, currValue) => {
                if (currValue !== itemKey) {
                  prevValue[currValue] = existingSessionStorage[currValue]
                }
                return prevValue
              }, {})
            )
          }
        }
        break
    }
  }
}
