import { createSelector } from '@reduxjs/toolkit'
import { ConsumerAppSelector, isConsumerLendingOrder, useAppSelector, useConsumerHasAccountLevelPayments } from '../'
import { OverduePaymentResponse, PaymentScheduleDueResponse } from '@afterpaytouch/portal-api/consumer/paymentSchedule/types'
import { paymentScheduleService } from '@afterpaytouch/portal-api/consumer/paymentSchedule'
import { PaymentType } from '@afterpay/types'
import { OrderMerchant } from '@afterpaytouch/portal-api/consumer/ordertransactions/types'
import { Money } from '@afterpaytouch/portal-api'
import { isPaymentSchedulePending, itemDueIn, formatDueInDays, isPaymentScheduleAllowedToBePaid } from './utils'

export const {
  useGetPaymentScheduleQuery,
  useGetOverduePaymentsQuery,
  useLazyGetPaymentScheduleQuery,
  useInitiateInstallmentPaymentMutation,
  useInitiateLendingInstallmentPaymentMutation,
  useConfirmLendingPayNowMutation,
  useConfirmPayNowMutation,
  endpoints: { getPaymentSchedule, getOverduePayments, confirmPayNow },
} = paymentScheduleService

// @TODO: Rework these selectors to use useQueryState (following state/bankAccounts pattern)
export const paymentScheduleSelector = getPaymentSchedule.select({})

// @ts-ignore: OPERATION BLEED STOPPER
export const paymentScheduleDataSelector: ConsumerAppSelector<PaymentScheduleDueResponse> = createSelector(paymentScheduleSelector, (state) => state.data)

export const paymentScheduleHasResults: ConsumerAppSelector<boolean> = createSelector(paymentScheduleDataSelector, (data) => Boolean(data?.results.length))

export const PaymentScheduleNextPaymentDateSelector: ConsumerAppSelector<string> = createSelector(
  paymentScheduleDataSelector,
  paymentScheduleHasResults,
  (data, hasResults) => {
    if (hasResults) {
      return data?.results[0].paymentSchedule[0].paymentDate
    }
  }
)

export const isPaymentScheduleIncludePendingPaymentSelector: ConsumerAppSelector<boolean> = createSelector(paymentScheduleDataSelector, (data) =>
  data?.results.some((item) => isPaymentSchedulePending(item.paymentSchedule))
)

export interface PaymentScheduleData {
  installmentsCount: number
  merchant: OrderMerchant
  installmentDueDate: string
  dueInDays: number
  formatDueInDays: string
  amountDue: Money
  orderId: string
  installmentSequence: number
  isAllowedToBePaid: boolean
  paymentScheduleId: number
  paymentType: PaymentType
  isPaymentPending: boolean
  paymentUpFront: boolean
  isInstallmentLastAndOwed: boolean
  clearpayPartiallyMigrated: boolean
  openRegZDispute: boolean
}

export const overduePaymentsSelector = getOverduePayments.select()

// @ts-ignore: OPERATION BLEED STOPPER
export const overduePaymentsDataSelector: ConsumerAppSelector<OverduePaymentResponse> = createSelector(overduePaymentsSelector, (state) => state.data)

export const overduePaymentsHasResults: ConsumerAppSelector<boolean> = createSelector(overduePaymentsDataSelector, (data) =>
  Boolean(data?.overduePaymentSchedules?.length)
)

// @ts-ignore: OPERATION BLEED STOPPER
const upcomingPaymentScheduleSelector: ConsumerAppSelector<PaymentScheduleData[]> = createSelector(
  paymentScheduleDataSelector,
  paymentScheduleHasResults,
  (data, hasResults) => {
    if (hasResults) {
      return data.results.map((result) => {
        const schedule = result.paymentSchedule
        return {
          merchant: result.merchant,
          installmentDueDate: schedule.installmentDueDate,
          dueInDays: itemDueIn(schedule),
          formatDueInDays: formatDueInDays(itemDueIn(schedule)),
          amountDue: schedule.amountPayable ?? schedule.amountOwed,
          orderId: result.orderNumber,
          installmentSequence: schedule.installmentSequence,
          installmentsCount: result.installmentsCount ?? 4,
          isAllowedToBePaid: isPaymentScheduleAllowedToBePaid(result, data.results),
          paymentScheduleId: schedule.id,
          paymentType: schedule?.paymentType,
          isPaymentPending: isPaymentSchedulePending(schedule),
          paymentUpFront: result.paymentUpFront,
          isInstallmentLastAndOwed: result.isInstallmentLastAndOwed,
          clearpayPartiallyMigrated: result.clearpayPartiallyMigrated ?? false,
          openRegZDispute: result.openRegZDispute ?? false,
        }
      })
    }
  }
)

/* React Hooks */

export const usePaymentScheduleDataSelector = (): PaymentScheduleDueResponse => useAppSelector(paymentScheduleDataSelector)

export const usePaymentScheduleHasResults = (): boolean => useAppSelector(paymentScheduleHasResults)

export const usePaymentScheduleNextPaymentDateSelector = (): string => useAppSelector(PaymentScheduleNextPaymentDateSelector)

export const useOverduePaymentsHasResults = (): boolean => useAppSelector(overduePaymentsHasResults)

export const useUpcomingPaymentScheduleSelector = (): PaymentScheduleData[] => useAppSelector(upcomingPaymentScheduleSelector)

export const useIsPaymentScheduleIncludePendingPaymentSelector = (): boolean => useAppSelector(isPaymentScheduleIncludePendingPaymentSelector)

export const useShouldPaymentSchedulePaymentSelectInstallment = (paymentType?: PaymentType, isInstallmentLastAndOwed?: boolean): boolean => {
  const hasAccountLevelPayments = useConsumerHasAccountLevelPayments()
  if (hasAccountLevelPayments) {
    return true
  }
  const isConsumerLending = isConsumerLendingOrder(paymentType)
  // @ts-ignore: OPERATION BLEED STOPPER
  return isConsumerLending && isInstallmentLastAndOwed
}
