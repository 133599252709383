// HTTPService can be reused for createApi of RTKQ
import { HttpService } from '@afterpaytouch/portal-api'
import { createApi } from '@reduxjs/toolkit/query/react'
import { api } from '../../env'
import axios from 'axios'
import type { Method, AxiosRequestConfig, AxiosError } from 'axios'
import type { FetchBaseQueryError, BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import type { PaymentSourceCaptureRequest, PaymentSourceCaptureResponse } from '@afterpay/types'

export const paymentHttpService = new HttpService(axios.create({ baseURL: api.PAYMENT_SOURCE_BASE_URL }))

// @ts-ignore: OPERATION BLEED STOPPER
const getBaseQuery: BaseQueryFn<
  {
    url: string
    method: Method
    data?: AxiosRequestConfig['data']
  },
  unknown,
  FetchBaseQueryError
> = async (args) => {
  const { url, method, data } = args
  try {
    const result = await paymentHttpService.request(url, method, data ?? null)
    return { data: result }
  } catch (e) {
    const error = e as AxiosError & Error
    const response = error.response?.data ?? error.message ?? null
    return { error: { status: error.response?.status, data: response } }
  }
}

// This can be extracted into its own package in the future if we ever need extra payments API
export const paymentService = createApi({
  reducerPath: 'payments-api',
  baseQuery: getBaseQuery,
  endpoints: (build) => ({
    captureApplePayPayment: build.mutation<PaymentSourceCaptureResponse, PaymentSourceCaptureRequest>({
      query: (request) => ({
        url: '/source-capture/v1/payment-sources/apple-pay',
        method: 'POST',
        data: request,
      }),
    }),
  }),
})

export const {
  useCaptureApplePayPaymentMutation,
  endpoints: { captureApplePayPayment },
} = paymentService
