import { joinStrings } from '@afterpay/utils/string'
import { FULL_NAME_MAX_LENGTH } from '../VerifyIdentity/constants'
import { createContext } from 'react'

export const isDifferentWithProfileDOB = (formDOB?: string, profileDOB?: string): boolean => {
  if (typeof formDOB === 'undefined') {
    return false
  }
  return formDOB !== profileDOB
}

export const isDifferentWithProfileNames = (formInputValue: string, names: { givenNames: string; otherGivenNames: string; surname: string }): boolean => {
  if (formInputValue.length === 0 || formInputValue.length > FULL_NAME_MAX_LENGTH) {
    return false
  }

  const { givenNames, otherGivenNames, surname } = names ?? {}
  return formInputValue.toLowerCase() !== joinStrings([givenNames, otherGivenNames, surname]).toLowerCase()
}

// This identifies whether the id page is a Retry version or not
export const IsRetryPageContext = createContext(false)
