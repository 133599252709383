import React from 'react'
import { PaymentRescheduleErrorCode } from '../model'
import { Trans } from 'next-i18next'
import { Link, Text } from '@afterpaytouch/core'

const getPaymentRescheduleErrorMsg = (faq: string, t, errorCode?: PaymentRescheduleErrorCode): JSX.Element => {
  const faqLink = <Link href={faq} kind='Underline' target='_blank' />
  let customErrMsg

  switch (errorCode) {
    case PaymentRescheduleErrorCode.CHANGE_PAYMENT_SCHEDULE_MAX_CHANGES_ON_ORDER:
      customErrMsg = t('orders:orderPaymentReschedule:errorMsgMaxChange')
      break
    case PaymentRescheduleErrorCode.CHANGE_PAYMENT_SCHEDULE_MERCHANT_BLACKLISTED:
      customErrMsg = t('orders:orderPaymentReschedule:errorMsgBlacklistedMerchant')
      break
    case PaymentRescheduleErrorCode.CHANGE_PAYMENT_SCHEDULE_RESTRICTED_PAYMENTS:
      customErrMsg = t('orders:orderPaymentReschedule:errorMsgRestrictedPayments')
      break
    case PaymentRescheduleErrorCode.CHANGE_PAYMENT_SCHEDULE_PAYMENT_OVERDUE:
      customErrMsg = t('orders:orderPaymentReschedule:errorMsgPaymentOverdue')
      break
    case PaymentRescheduleErrorCode.CHANGE_PAYMENT_SCHEDULE_MAX_CHANGES_IN_DURATION:
      customErrMsg = t('orders:orderPaymentReschedule:errorMsgPaymentMaxChangeDuration')
      break
    case PaymentRescheduleErrorCode.CHANGE_PAYMENT_SCHEDULE_TOO_CLOSE_TO_DUE_DATE:
      customErrMsg = t('orders:orderPaymentReschedule:errorMsgPaymentTooCloseDueDate')
      break
    case PaymentRescheduleErrorCode.CHANGE_PAYMENT_SCHEDULE_ACCOUNT_TOO_NEW:
      customErrMsg = <Trans components={{ faqLink }} i18nKey='orders:orderPaymentReschedule:errorMsgPaymentAccountTooNew' />
      break
    case PaymentRescheduleErrorCode.CHANGE_PAYMENT_SCHEDULE_ACCOUNT_HARDSHIP:
      customErrMsg = <Trans components={{ faqLink }} i18nKey='orders:orderPaymentReschedule:errorMsgPaymentAccountHardship' />
      break
    default:
      customErrMsg = null
  }

  return (
    <Text>
      {t('orders:orderPaymentReschedule:errorMsgCommonPart')}
      <br />
      <br />
      {customErrMsg}
    </Text>
  )
}
export default getPaymentRescheduleErrorMsg
