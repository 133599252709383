import { InputAddressData } from '@afterpaytouch/core'
import { RegisterOptions } from 'react-hook-form'
import { Country } from '../../model'
import { isUndefinedOrEmpty } from '@afterpay/utils/string'
import { isValidAddress, requiredAddressFieldsByCountry } from '@afterpay/utils'
import { isValidObject } from '../../utils/object'

export const validateAddress = (country, key, value): boolean => {
  const requiredFields = requiredAddressFieldsByCountry[country] ?? requiredAddressFieldsByCountry.DEFAULT

  const validate = {
    address1: (input) => isValidAddress({ input }),
    address2: (input) => isValidAddress({ input, optional: true }),
    area2: (input) => isValidAddress({ input, optional: true }),
    countryCode: (input) => !isUndefinedOrEmpty(input),
    postcode: (input) => isValidAddress({ input, optional: !requiredFields.postcode }),
    state: (input) => isValidAddress({ input, optional: !requiredFields.state }),
    suburb: (input) => isValidAddress({ input, optional: !requiredFields.suburb }),
  }

  const result = typeof validate[key] === 'function' && validate[key](value)
  return result
}

export const validAddressOption = (address: InputAddressData, country: Country): boolean => {
  if (!isValidObject(address)) {
    return false
  }

  const addressKeys = Object.keys(address)

  for (const key of addressKeys) {
    const isValidValue = validateAddress(country, key, address[key])
    if (!isValidValue) {
      return false
    }
  }
  return true
}

export const requiredAddress = ({ name, country, t }): RegisterOptions => {
  return {
    validate: {
      requiredAddressFields: (v) => {
        const hasRequiredAddressFields = validAddressOption(v, country)
        return hasRequiredAddressFields || t('account:tab:payment-methods:modal:form:address:error:required')
      },
    },
  }
}
