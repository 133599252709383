import { PaymentType } from '@afterpay/types'
import { BankAccountResponse } from '@afterpaytouch/portal-api'
import { bankAccountsService } from '@afterpaytouch/portal-api/consumer/account/bank-accounts'
import { BankAccountSupportedPaymentTypes } from './constants'

export const {
  useGetBankAccountsQuery,
  useLazyGetBankAccountsQuery,
  useUpdatePreferredBankMutation,
  useDeleteBankMutation,
  useCheckAchEligibleQuery,
  useCreateLinkTokenMutation,
  useCreateRelinkTokenMutation,
  useGetPlaidBankAccountsMutation,
  useLinkBankAccountMutation,
  useValidateBankAccountMutation,
  endpoints: { getBankAccounts },
} = bankAccountsService

// @ts-ignore: OPERATION BLEED STOPPER
export const useBankAccountsDataSelector = (): BankAccountResponse => getBankAccounts.useQueryState()?.data

export const useBankAccountsHasResults = (): boolean => {
  const accounts = useBankAccountsDataSelector()
  return Boolean(accounts?.accounts?.length)
}

export const useIsPaymentTypeSupportedForAch = (paymentType: PaymentType): boolean =>
  Object.values(BankAccountSupportedPaymentTypes).includes(paymentType as unknown as BankAccountSupportedPaymentTypes)
