import { TFunction } from 'next-i18next'
import { FieldType, Schema } from '@afterpaytouch/form'
import { Country } from '@afterpaytouch/portal-api/types'
import { getStatesListByCountry } from '../../../model'
import { DriversLicenceForm, GetSchemaAndFormDataResult, LicenceNumOpts, LicenceValidations, LicenseType, SchemaUpdates } from './types'
import { COUNTRY_FIELDS_HIDDEN, COUNTRY_STATES_MAP, LICENCE_CARD_NUMBER_RESTRICTION } from './constants'
import { dobValidation, licenceVersionLengthValidation, nameMaxLengthValidation, namePattern, requiredValidation } from '../VerifyIdentity/validations'
import { InputOnFocus } from '@afterpaytouch/core'
import { getDropdownFieldSchema, getInputBirthdaySchema, getInputFieldSchema } from '../VerifyIdentity/utils'
import { ConsumerState } from '../../../state'

export const setHiddenFieldsByCountry = (schema: Schema<DriversLicenceForm>, country: Country): void => {
  const hiddenFields = COUNTRY_FIELDS_HIDDEN[country] ?? []
  Object.entries(schema).forEach(([fieldName]) => {
    const isOmitted = hiddenFields.includes(fieldName)

    if (isOmitted as boolean) {
      schema[fieldName].show = false
    }
  })
}

const getFormData = (consumer: ConsumerState): DriversLicenceForm => {
  return {
    // @ts-ignore: OPERATION BLEED STOPPER
    profileCard: undefined,
    givenNames: consumer?.givenNames ?? '',
    otherGivenNames: consumer?.otherGivenNames ?? '',
    surname: consumer?.surname ?? '',
    licenceNumber: '',
    licenceVersion: '',
    state: consumer?.contactAddress?.state ?? '',
    dateOfBirth: consumer?.dateOfBirth ?? '',
    // @ts-ignore: OPERATION BLEED STOPPER
    terms: undefined,
    // @ts-ignore: OPERATION BLEED STOPPER
    footer: undefined,
  }
}

export const isLicenceNumValid = (input: string, licenceNumOpts: LicenceNumOpts): boolean => {
  const { maxLength, minLength, pattern } = licenceNumOpts
  const { length } = input
  return length >= minLength.value && length <= maxLength.value && pattern.value.test(input)
}

export const getLicenceValidations = (country: Country, state: string, t: TFunction, stateIsShown = true): LicenceValidations => {
  const statesMap: Record<string, LicenseType> = COUNTRY_STATES_MAP[country]

  if (typeof statesMap === 'undefined') {
    return {} as LicenceValidations
  }

  const stateKey = typeof state !== 'undefined' && state.length > 0 && stateIsShown ? state : 'DEFAULT'
  const { minLength, maxLength, pattern } = statesMap[stateKey]
  const message = t('verifyIdentity:licence:invalidNumber')

  return {
    licenceNumOpts: {
      minLength: {
        value: minLength,
        message,
      },
      maxLength: {
        value: maxLength,
        message,
      },
      pattern: {
        value: pattern,
        message,
      },
    },
    licenceMaxLength: maxLength,
  }
}

export const getLicenceCardNumberValidation = (country: Country, state: string, t: TFunction, stateIsShown = true): LicenceNumOpts => {
  const stateKey = typeof state !== 'undefined' && state.length > 0 && stateIsShown ? state : 'DEFAULT'

  if (country !== Country.AU && typeof LICENCE_CARD_NUMBER_RESTRICTION[stateKey] === 'undefined') {
    return {} as LicenceNumOpts
  }

  const { maxLength, minLength, pattern } = LICENCE_CARD_NUMBER_RESTRICTION[stateKey]
  const message = t('verifyIdentity:licence:invalidDlCardNumber')

  return {
    minLength: {
      value: minLength,
      message: t('verifyIdentity:licence:invalidDlCardNumber'),
    },
    maxLength: {
      value: maxLength,
      message: t('verifyIdentity:licence:invalidDlCardNumber'),
    },
    pattern: {
      value: pattern,
      message,
    },
  }
}

export const getInitialFormData = (consumer: ConsumerState): DriversLicenceForm => {
  return getFormData(consumer)
}

export const getSchema = (
  consumer: ConsumerState,
  components: any,
  t: TFunction,
  schemaUpdates: SchemaUpdates,
  onNameBlur: (ev: InputOnFocus, formValues: DriversLicenceForm) => void,
  error: boolean,
  isProfileEditable: boolean
): GetSchemaAndFormDataResult<DriversLicenceForm> => {
  const initialFormData = getFormData(consumer)
  const { givenNames, otherGivenNames, surname, dateOfBirth, state } = initialFormData
  const { countryCode, preferredLocale } = consumer
  const { licenceNumOpts, showProfileDobAlert, showProfileNameAlert, licenceMaxLength, licenceNumberKey, licenceCardNumberKey, cardNumOpts } = schemaUpdates
  const licenceVersValidation = licenceVersionLengthValidation(t)(countryCode)
  const required = requiredValidation(t)
  const dobValidateOptions = dobValidation(t, countryCode)
  const namePatterns = namePattern(t)
  const nameMaxLengthOptions = nameMaxLengthValidation(t)

  const schema: Schema<DriversLicenceForm> = {
    profileCard: {
      type: FieldType.Component,
      Component: components.profileCard,
      show: !isProfileEditable,
    },
    givenNames: getInputFieldSchema({
      label: t('verifyIdentity:licence:givenNames'),
      value: givenNames,
      inline: true,
      // @ts-ignore: OPERATION BLEED STOPPER
      options: {
        ...required('givenNames'),
        ...namePatterns('givenNames'),
        ...nameMaxLengthOptions('givenNames', countryCode),
      },
      otherProps: {
        onBlur: onNameBlur,
      },
      error,
      show: isProfileEditable,
    }),
    otherGivenNames: getInputFieldSchema({
      label: t('verifyIdentity:licence:otherGivenNames'),
      value: otherGivenNames,
      inline: true,
      // @ts-ignore: OPERATION BLEED STOPPER
      options: {
        ...namePatterns('otherGivenNames'),
        ...nameMaxLengthOptions('otherGivenNames', countryCode),
      },
      otherProps: {
        onBlur: onNameBlur,
      },
      error,
      show: isProfileEditable,
    }),
    surname: getInputFieldSchema({
      label: t('verifyIdentity:licence:surname'),
      value: surname,
      // @ts-ignore: OPERATION BLEED STOPPER
      options: {
        ...required('surname'),
        ...namePatterns('surname'),
        ...nameMaxLengthOptions('surname', countryCode),
      },
      otherProps: {
        onBlur: onNameBlur,
      },
      error,
      show: isProfileEditable,
    }),
    profileNameAlert: {
      type: FieldType.Component,
      show: showProfileNameAlert,
      Component: components.nameAlert,
    },
    dateOfBirth: getInputBirthdaySchema({
      value: dateOfBirth,
      locale: preferredLocale,
      label: t('verifyIdentity:licence:dateOfBirth'),
      options: {
        ...required('dateOfBirth'),
      },
      otherValidations: dobValidateOptions('dateOfBirth'),
      error,
      show: isProfileEditable,
    }),
    profileDobAlert: {
      type: FieldType.Component,
      show: showProfileDobAlert,
      Component: components.dobAlert,
    },
    state: getDropdownFieldSchema({
      label: `${t('verifyIdentity:licence:state')}`,
      items: getStatesListByCountry(countryCode).map(({ key, value }) => {
        return { key, label: value }
      }),
      id: 'driversLicenceStateDropdown', // this ID is used to maintain the same snapshot for testing
      value: state,
      options: {
        ...required('state'),
      },
      error,
    }),
    licenceNumber: getInputFieldSchema({
      label: `${t('verifyIdentity:licence:licenceNumber')}`,
      value: '',
      // @ts-ignore: OPERATION BLEED STOPPER
      options: {
        ...required('licenceNumber'),
        ...licenceNumOpts,
      },
      otherProps: {
        format: '*'.repeat(licenceMaxLength),
        ...(typeof licenceNumberKey !== 'undefined' && { key: licenceNumberKey }),
      },
      error,
    }),
    licenceVersion: getInputFieldSchema({
      label: `${t('verifyIdentity:licence:licenceVersion')}`,
      value: '',
      options: {
        ...required('licenceVersion'),
        ...licenceVersValidation,
      },
      otherProps: {
        format: '0'.repeat(licenceVersValidation.maxLength.value),
      },
      error,
    }),
    dlCardNumber: getInputFieldSchema({
      label: `${t('verifyIdentity:licence:dlCardNumber')}`,
      value: '',
      // @ts-ignore: OPERATION BLEED STOPPER
      options: {
        ...required('cardNumber'),
        ...cardNumOpts,
      },
      otherProps: {
        ...(typeof licenceCardNumberKey !== 'undefined' && { key: licenceCardNumberKey }),
      },
      error,
    }),
    terms: {
      type: FieldType.Component,
      Component: components.terms,
      show: isProfileEditable,
    },
    footer: {
      type: FieldType.Component,
      Component: components.footer,
    },
  }
  return { schema, initialFormData }
}
