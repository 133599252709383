import { LaunchDarklyContextProps } from '@afterpaytouch/feature'
import { LaunchDarklyUserProps } from '@afterpaytouch/feature/lib/types'
import { ConsumerAppState } from '../state'

export const isValidObject = (object: any): object is object => !!object && Object.keys(object).length > 0 && object.constructor === Object

export const getUserContext = (state?: ConsumerAppState): LaunchDarklyUserProps => {
  const uuid = state?.consumer?.uuid
  const country = state?.consumer?.countryCode ?? state?.consumer?.emailLookupCountry ?? undefined
  const email = state?.consumer?.email
  const consumerPhone = state?.consumer?.phoneNumber
  return {
    kind: 'user',
    name: 'afterpay-portal',
    uuid,
    country,
    email,
    ...(typeof consumerPhone !== 'undefined' && { consumerPhone }),
  }
}

export const getLDContext = (state: ConsumerAppState, onLoad: () => void, onDone: () => void): LaunchDarklyContextProps => {
  const userProps = getUserContext(state)

  return {
    userProps,
    onLoad,
    onDone,
  }
}
