import { useFlag } from '../../hooks'
import { FetchBaseQueryErrorType, OrderResponse } from '@afterpaytouch/portal-api'
import { orderTransactionsService } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import { useCallback, useEffect, useMemo } from 'react'

import { isValidObject } from '../../utils/object'
import { useTranslation } from 'next-i18next'
import { createModalHook } from '../../hooks/useModal'
import { useDisputeCreationModalContext } from './DisputeCreationModalContext'
import {
  DisputeReason,
  isConflictError,
  isCreatingTooEarly,
  isCreatingTooLate,
  isDuplicatedEntityError,
  isExternalDisputeExistError,
  isInvalidAmountError,
  isTimesLimitError,
} from '../../model'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { isConsumerLendingOrder, useConsumerAccountIsInHardship } from '../../state'

export interface UseDispute {
  disputeCtaVisible: boolean
  disputeCtaEnabled: boolean
  disputeCtaDisabledTitle: string
  isModalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

const I18N_NAMESPACE = ['common', 'dispute']

const useModal = createModalHook()

export const useDisputeTranslation = (): ((key: string, options?: any) => string) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  return (key: string, options?: any) => t(`dispute:${key}`, options)
}

export const useDisputeCreationModal = (orderDetail?: OrderResponse): UseDispute => {
  const t = useDisputeTranslation()
  const disputeCtaVisible: boolean = useFlag('ir-dispute-create-button-enabled-new')
  const [isModalOpen, { openModal, closeModal }] = useModal()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const isConsumerAccountInHardship = useConsumerAccountIsInHardship()

  const disputeCtaEnabled: boolean = useMemo(() => {
    if (!isValidObject(orderDetail)) {
      return false
    }

    return orderDetail.canBeDisputed && !isConsumerAccountInHardship
  }, [orderDetail])

  // @ts-ignore: OPERATION BLEED STOPPER
  const disputeCtaDisabledTitle: string = useMemo(() => {
    if (!isValidObject(orderDetail) || disputeCtaEnabled) {
      return null
    }

    if (isConsumerLendingOrder(orderDetail.paymentType)) {
      return t('disabledTips.clOrder')
    }

    return t('disabledTips:common')
  }, [orderDetail, t, disputeCtaEnabled])

  const openDisputeModal = useCallback(() => {
    openModal()
    logEvent(TrackingEvent.PRESSED_INITIAL_REPORTING_PROBLEM)
  }, [openModal, logEvent])

  return {
    disputeCtaVisible,
    disputeCtaEnabled,
    disputeCtaDisabledTitle,
    isModalOpen,
    openModal: openDisputeModal,
    closeModal,
  }
}
const { useCreateDisputeMutation } = orderTransactionsService

type UseCreateDispute = () => Promise<any>

export const useCreateDispute = (): UseCreateDispute => {
  const t = useDisputeTranslation()
  const [createDispute, { isLoading, error, isError, isSuccess }] = useCreateDisputeMutation()
  const {
    setIsLoading,
    setResult,
    refreshDisputeInfo,
    order,
    reason,
    subReason,
    files,
    usePartialRefund,
    partialAmount,
    contactMerchantDate,
    contactMerchantReason,
    contactMerchantName,
    expectedDeliveryDate,
    note,
    useNote,
  } = useDisputeCreationModalContext()

  const errorMessage: string = useMemo(() => {
    if (isInvalidAmountError((error as FetchBaseQueryErrorType)?.data?.errorCode)) {
      return t('result:failure:invalidAmount')
    }

    if (isConflictError((error as FetchBaseQueryErrorType)?.data?.errorCode)) {
      return t('result:failure:conflict')
    }

    if (isTimesLimitError((error as FetchBaseQueryErrorType)?.data?.errorCode)) {
      return t('result:failure:timesLimit.message')
    }

    if (isExternalDisputeExistError((error as FetchBaseQueryErrorType)?.data?.errorCode)) {
      return t('result.failure.externalDisputeExist')
    }

    if (isDuplicatedEntityError((error as FetchBaseQueryErrorType)?.data?.errorCode)) {
      return t('result.failure.duplicateEntity')
    }

    if (isCreatingTooEarly((error as FetchBaseQueryErrorType)?.data?.errorCode)) {
      return t('result.failure.tooEarly')
    }

    if (isCreatingTooLate((error as FetchBaseQueryErrorType)?.data?.errorCode)) {
      return t('result.failure.tooLate')
    }

    return t('result:failure:unspecific')
  }, [error, t])
  const errorTitle: string = useMemo(() => {
    if (isTimesLimitError((error as FetchBaseQueryErrorType)?.data?.errorCode)) {
      return t('result:failure:timesLimit.title')
    }

    return t('result:failure:head')
  }, [error, t])

  useEffect(() => {
    setIsLoading(isLoading)
    if (isSuccess) {
      setResult({
        success: true,
        error: false,
      })
      return
    }

    if (isError) {
      setResult({
        success: false,
        error: true,
        message: errorMessage,
        title: errorTitle,
      })
    }
  }, [isLoading, errorMessage, errorTitle, isError, isSuccess, setResult, setIsLoading, t])

  useEffect(() => {
    if (isSuccess) {
      refreshDisputeInfo()
    }
  }, [isSuccess, refreshDisputeInfo])

  return useCallback((): any => {
    const fd = new FormData()
    fd.append('reason', reason)
    fd.append('contactMerchantDate', contactMerchantDate as string)
    fd.append('contactMerchantName', contactMerchantName)
    fd.append('resolveFailureReason', contactMerchantReason)
    fd.append('expectedDeliveryDate', expectedDeliveryDate as string)

    if (reason === DisputeReason.OrderMismatch) {
      fd.append('reasonDetail', '')
      fd.append('amount', order?.orderSummary?.totalAfterRefund?.amount)
    } else {
      fd.append('reasonDetail', subReason)
      fd.append('amount', usePartialRefund ? String(partialAmount) : order?.orderSummary?.totalAfterRefund?.amount)
      if (useNote) {
        fd.append('subject', note.trim())
      }
      files.forEach((file: File) => {
        fd.append('files', file, file.name)
      })
    }

    return createDispute({
      orderId: order.id,
      formData: fd,
    })
  }, [
    reason,
    usePartialRefund,
    partialAmount,
    order,
    createDispute,
    subReason,
    contactMerchantDate,
    contactMerchantName,
    contactMerchantReason,
    expectedDeliveryDate,
    files,
    note,
    useNote,
  ])
}
