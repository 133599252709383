import { useEffect, useState } from 'react'

export const useHasLoadedOnce = (status: boolean): boolean => {
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)

  useEffect(() => {
    if (status) {
      setHasLoadedOnce(true)
    }
  }, [status])

  return hasLoadedOnce
}
