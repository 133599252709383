import React, { FunctionComponent, ReactNode } from 'react'
import styles from './style.module.css'

export interface FormWrapperProps {
  children: ReactNode
}

export const FormWrapper: FunctionComponent<FormWrapperProps> = ({ children }) => {
  return <div className={styles.formWrapper}>{children}</div>
}
