import { OrderPpaDocTypes } from '@afterpaytouch/portal-api/types'

export const ppaDocumentLabel: Partial<Record<OrderPpaDocTypes, string>> = {
  [OrderPpaDocTypes.CREDIT_SCORE_DISCLOSURE]: 'creditScoreDisclosure',
  [OrderPpaDocTypes.FINAL_LOAN_AGREEMENT]: 'finalLoanAgreement',
  [OrderPpaDocTypes.FINAL_PAYMENT_SCHEDULE]: 'finalPaymentSchedule',
  [OrderPpaDocTypes.INSTALLMENT_AGREEMENT]: 'installmentAgreement',
  [OrderPpaDocTypes.LOAN_AGREEMENT]: 'loanAgreement',
  [OrderPpaDocTypes.PAYMENT_AUTHORISATION]: 'paymentAuthorisation',
}
