import { useToast, useDate } from '@afterpaytouch/core'
import { titleCase } from '@afterpay/utils'
import { ErrorCode, OrderPaymentSchedule, SupportedLocale } from '@afterpaytouch/portal-api'
import { CreditCard } from '@afterpaytouch/portal-api/consumer/account/creditcards/types'
import { useRouter } from 'next/router'
import {
  useChangeOrderTransactionPaymentMethodMutation,
  useConsumerCountry,
  useGetOrderTransactionQuery,
  useOrderNextPaymentDateSelector,
  useOrderOwedPaymentSchedules,
  useApplePay,
  useAppDispatch,
  setApplePayOrderChannel,
  setApplePayTransactionId,
  useGetAccountData,
  useToggleAutopayMutation,
} from '../../state'
import { TrackingEvent } from '../../model/amplitude'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { ApplePayHelperResolve, HelperType } from '../../state/applepay/helper'
import { deviceDetails } from '../../utils/device'
import { maskCreditCardNumber } from '../../utils/card'
import { UseUpdatePaymentBusinessLogicProps, UseUpdatePaymentBusinessLogicReturn } from '../ChangePaymentMethod'
import { BankAccountDetails, CashAppPayDetail } from '@afterpay/types'

export const useEnableAutopay = (
  orderId: string,
  autopayEnabledSuccessMessage: () => JSX.Element
): {
  handleEnableAutopay: () => () => void
} => {
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const setToast = useToast()
  const { data: orderData } = useGetOrderTransactionQuery(orderId)
  const [toggleAutopay] = useToggleAutopayMutation()
  const router = useRouter()
  const handleEnableAutopay = () => async (): Promise<void> => {
    try {
      logEvent(TrackingEvent.PRESSED_AUTOPAY_ON, { outboundLink: router?.pathname })
      // @ts-ignore: OPERATION BLEED STOPPER
      await toggleAutopay({ orderId: orderData.id, enabled: true }).unwrap()
      setToast({
        message: autopayEnabledSuccessMessage,
        kind: 'Success',
        manualDismiss: true,
        toastTime: 5000,
        testNameSpace: 'autopay',
      })
    } catch (error) {
      logEvent(TrackingEvent.ENABLE_AUTOPAY_FAILED, {
        error: error?.data?.errorCode,
      })
      setToast({
        message: error?.data?.message ?? 'orders:orderAutopay:errors:unspecified_error',
        kind: 'Failure',
        testNameSpace: 'autopay',
      })
    }
  }
  return {
    handleEnableAutopay,
  }
}

export const useUpdatePaymentBusinessLogic = ({
  orderId,
  setError,
  t,
  modalOnClose,
}: UseUpdatePaymentBusinessLogicProps): UseUpdatePaymentBusinessLogicReturn => {
  const accountData = useGetAccountData()
  // @ts-ignore: OPERATION BLEED STOPPER
  const { data: orderData } = useGetOrderTransactionQuery(orderId)
  const acceptedCardTypes = orderData?.enabledCardTypes
  const [changePaymentMethod] = useChangeOrderTransactionPaymentMethodMutation()
  const setToast = useToast()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  // @ts-ignore: OPERATION BLEED STOPPER
  const owedPaymentSchedules = useOrderOwedPaymentSchedules(orderId)
  // @ts-ignore: OPERATION BLEED STOPPER
  const nextPaymentSchedule = useOrderNextPaymentDateSelector(orderId)
  const nextPaymentScheduleDueDate = nextPaymentSchedule?.installmentDueDate
  const countryCode = useConsumerCountry()
  const supportedCountries = orderData?.countryCode
  const { locale } = useRouter()
  const { formatDate } = useDate({ locale: locale as SupportedLocale })
  const nextDueDateFormatted = formatDate(nextPaymentScheduleDueDate)
  const totalLabel = `${t('common:brandName')} (ON ${nextDueDateFormatted})`
  const { applePayInstance } = useApplePay()
  const dispatch = useAppDispatch()

  const updatePaymentMethodToApplePay = async (): Promise<void> => {
    // @ts-ignore: OPERATION BLEED STOPPER
    dispatch(setApplePayOrderChannel(orderData.channel))
    // @ts-ignore: OPERATION BLEED STOPPER
    dispatch(setApplePayTransactionId(orderData?.id.toString()))

    const total: ApplePayJS.ApplePayLineItem = {
      label: totalLabel,
      // @ts-ignore: OPERATION BLEED STOPPER
      amount: nextPaymentSchedule?.amount?.amount,
      type: 'final',
    }

    /**
     * Apple pay line items: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916120-lineitems
     * i.e  label: '1 X Payment of $10.00, 2 X Payment of $15.00' etc.
     */
    // @ts-ignore: OPERATION BLEED STOPPER
    const owedAmounts = owedPaymentSchedules.map((item: OrderPaymentSchedule) => item?.amount?.amount)
    const owedCounters = {}
    for (let i = 0; i < owedAmounts.length; i++) {
      owedCounters[owedAmounts[i]] = owedCounters[owedAmounts[i]] !== undefined ? (owedCounters[owedAmounts[i]] as number) + 1 : 1
    }
    const lineItems: ApplePayJS.ApplePayLineItem[] = Object.keys(owedCounters).map((amount) => {
      const paymentLabel = owedCounters[amount] >= 2 ? 'Payments' : 'Payment'
      return {
        label: `${owedCounters[amount] as string} X ${paymentLabel} of`,
        amount,
        type: 'final',
      }
    })

    applePayInstance.initialise(
      {
        // @ts-ignore: OPERATION BLEED STOPPER
        countryCode,
        // @ts-ignore: OPERATION BLEED STOPPER
        currencyCode: nextPaymentSchedule.amount.currency,
        // @ts-ignore: OPERATION BLEED STOPPER
        supportedCountries: [supportedCountries],
        total,
        lineItems,
        accountData,
      },
      logEvent,
      HelperType.UPDATE_PAYMENT
    )

    try {
      const response: ApplePayHelperResolve = await applePayInstance.startSession()

      const payload = {
        paymentSourceId: response.applePayToken.paymentSourceId,
        deviceDetails: deviceDetails(),
      }

      try {
        await changePaymentMethod({
          // @ts-ignore: OPERATION BLEED STOPPER
          orderId,
          body: payload,
        }).unwrap()

        setError(null)
        modalOnClose()

        // Set apple pay session as success
        // @ts-ignore: OPERATION BLEED STOPPER
        response.session.completePayment({ status: 0 })

        setToast({
          message: t('orders:orderPaymentMethod:modal:tab:paymentMethods:success', {
            paymentMethod: 'Apple Pay',
          }),
          kind: 'Success',
        })
      } catch (e) {
        const errorCode = e?.data?.errorCode ?? 'unspecified_error'
        setError({ errorCode })
        // @ts-ignore: OPERATION BLEED STOPPER
        response.session.completePayment({ status: 1 })
      }
    } catch (e) {
      const errorCode = e?.data?.errorCode ?? 'unspecified_error'
      logEvent(TrackingEvent.UPDATE_PAYMENT_METHOD_IN_ORDER_FAILED, {
        error: errorCode,
      })
      setError({ errorCode })
    }
  }

  const updateCreditCard = async (paymentMethod: CreditCard): Promise<void> => {
    // @ts-ignore: OPERATION BLEED STOPPER
    if (acceptedCardTypes.includes(paymentMethod.cardBrand)) {
      try {
        await changePaymentMethod({
          // @ts-ignore: OPERATION BLEED STOPPER
          orderId,
          body: {
            cardId: paymentMethod.id,
          },
        }).unwrap()

        setToast({
          message: t('orders:orderPaymentMethod:modal:tab:paymentMethods:success', {
            paymentMethod: `${titleCase(paymentMethod.cardBrand)} ${maskCreditCardNumber(paymentMethod.truncatedNumber ?? paymentMethod.maskedPan)}`,
          }),
          kind: 'Success',
        })

        setError(null)
        modalOnClose()
      } catch (e) {
        // if no error code, put unspecified_error
        const errorCode = e?.data?.errorCode ?? 'unspecified_error'
        logEvent(TrackingEvent.UPDATE_PAYMENT_METHOD_IN_ORDER_FAILED, {
          error: errorCode,
        })
        setError({ errorCode, status: e?.status, options: { cardBrand: paymentMethod.cardBrand } })
      }
    } else {
      setError({ errorCode: ErrorCode.CardNotAccepted, options: { cardBrand: paymentMethod?.cardBrand } })
    }
  }

  const updateBankAccount = async (paymentMethod: BankAccountDetails): Promise<void> => {
    try {
      await changePaymentMethod({
        // @ts-ignore: OPERATION BLEED STOPPER
        orderId,
        body: {
          bankAccountId: paymentMethod.id.toString(),
        },
      }).unwrap()

      setToast({
        message: t('orders:orderPaymentMethod:modal:tab:paymentMethods:success', {
          paymentMethod: `${titleCase(paymentMethod.issuingBank)} ${maskCreditCardNumber(paymentMethod.numberMask)}`,
        }),
        kind: 'Success',
      })

      setError(null)
      modalOnClose()
    } catch (e) {
      // if no error code, put unspecified_error
      const errorCode = e?.data?.errorCode ?? 'unspecified_error'
      logEvent(TrackingEvent.UPDATE_PAYMENT_METHOD_IN_ORDER_FAILED, {
        error: errorCode,
      })
      setError({ errorCode, options: { cardBrand: paymentMethod } })
    }
  }

  const updateCashApp = async (paymentMethod: CashAppPayDetail): Promise<void> => {
    try {
      await changePaymentMethod({
        // @ts-ignore: OPERATION BLEED STOPPER
        orderId,
        body: {
          cashAppPayId: paymentMethod.id,
        },
      }).unwrap()

      setToast({
        message: t('orders:orderPaymentMethod:modal:tab:paymentMethods:success', {
          paymentMethod: `${paymentMethod.cashtag}`,
        }),
        kind: 'Success',
      })

      setError(null)
      modalOnClose()
    } catch (e) {
      // if no error code, put unspecified_error
      const errorCode = e?.data?.errorCode ?? 'unspecified_error'
      logEvent(TrackingEvent.UPDATE_PAYMENT_METHOD_IN_ORDER_FAILED, {
        error: errorCode,
      })
      setError({ errorCode, options: { cardBrand: paymentMethod } })
    }
  }

  return {
    updatePaymentMethodToApplePay,
    updateCreditCard,
    updateBankAccount,
    updateCashApp,
  }
}
