import { Button, Modal, Radio, Text } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent, useEffect } from 'react'
import { useCreatePaymentScheduleExampleMutation, useGetPreferredPayday } from '../../../../../state/preferredPaymentDay/hooks'
import { PreferredDaySteps, useSetPreferredDayModalContext } from '../PreferredDayModalContext'
import { Weekdays } from '../types'
import { useAmplitudeWithEnduringEventProperties } from '../../../../../integrations/amplitude'
import { TrackingEvent } from '../../../../../model/amplitude'
import { useAlignPaydayEntryPoint } from '../../hooks'

const I18N_NAMESPACE = ['account']

export const SelectPreferredDay: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const { setSelectedPreferredDay, setStep, selectedPreferredDay, setExamplePaymentSchedule, setResult } = useSetPreferredDayModalContext()
  const daysOfTheWeek: string[] = Object.values(Weekdays).map((n) => t(`account:tab:payment-methods:preferred-day:weekday:${n}`))
  const [getExamplePs, { data: paymentScheduleData, isLoading, isError }] = useCreatePaymentScheduleExampleMutation()
  const defaultPayday = useGetPreferredPayday()
  const testNameSpace = 'preferred-day-select'
  const isDisabled =
    selectedPreferredDay === null || selectedPreferredDay === undefined || selectedPreferredDay === '' || defaultPayday === selectedPreferredDay
  const alignPaydayEntryPoint = useAlignPaydayEntryPoint()
  const maintainCurrentDay = selectedPreferredDay ?? defaultPayday

  const handleSelectedDay = (): void => {
    getExamplePs({ preferredPaymentDay: selectedPreferredDay })
  }

  const handleChange = (day: string): void => {
    setSelectedPreferredDay(day)
    logEvent(TrackingEvent.ALIGN_PAY_DAY.PREFERRED_DAY_CLICKED, { preferredDay: day, route: alignPaydayEntryPoint, previousChoice: defaultPayday ?? '' })
  }

  useEffect(() => {
    if (isError) {
      setResult({ success: false, error: true })
      setStep(PreferredDaySteps.PreferredPaydayResult)
    }
  }, [isError])

  useEffect(() => {
    if (paymentScheduleData != null && !isError) {
      setExamplePaymentSchedule(paymentScheduleData?.instalmentDueDates)
      setStep(PreferredDaySteps.ConfirmNewSchedule)
    }
  }, [paymentScheduleData, setExamplePaymentSchedule, setStep])

  return (
    <>
      <Modal.Content>
        <div className='mb-4'>
          <Text>{t('account:tab:payment-methods:preferred-day:modal:subtitle')}</Text>
        </div>
        <div className='my-4 overflow-y-auto'>
          <Radio.Group kind='Option' name='preferred-day-select' id='preferred-day-select' testNameSpace={testNameSpace}>
            {daysOfTheWeek?.map((day) => (
              <Radio.Button
                value={day}
                label={day}
                onChange={() => handleChange(day)}
                key={`radio-button-key-${day}`}
                testNameSpace={`radio-button-key-${day}`}
                defaultChecked={Boolean(maintainCurrentDay) && maintainCurrentDay === day}
              />
            ))}
          </Radio.Group>
        </div>
        <Text color='Gray40'>{t('account:tab:payment-methods:preferred-day:modal:disclaimer')}</Text>
        <div className='mt-6'>
          <Button testNameSpace={testNameSpace} padding='Fluid' onClick={handleSelectedDay} disabled={isDisabled} loading={isLoading}>
            <Text bold>{t('account:tab:payment-methods:preferred-day:modal:continue:cta')}</Text>
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}
