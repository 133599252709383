import axios, { AxiosInstance, AxiosResponse } from 'axios'
// @TODO: Investigate if we will use async-mutex anywhere else. If not, it might make more sense to implement this as
// a utility.
import { Mutex } from 'async-mutex'
import { Store } from 'redux'
import { HttpService } from '@afterpaytouch/portal-api/api'
import { HttpService as TopazHttpService } from '@afterpaytouch/topaz-api/api'
import { HttpService as ShopHttpService } from '@afterpaytouch/shop-api/api'
import { ConsumerAppState, fingerprintHeader, fingerprintHeaderValue, profilingIdSelector } from '.'
import { api } from '../env'
import { isAxiosError } from '@afterpaytouch/portal-api/utils'
import { seonInterceptor } from '../integrations/seon'
import { store as baseStore } from './store'
import { Route } from '../config/router'
import { COOKIE_SHOW_NEW_PORTAL, switchToOldPortal } from '../utils/redirects'
import { getPortalMigrationEnabled } from '../utils/convergence'
import { setCookie } from 'nookies'
import { getCountryCodeFromLocale } from '../utils/consumer'

const CLIENT_PREFIX = 'client:'
const PORTAL = 'portal-redesign-v1'
const CommonHeaders = {
  'X-Requested-With': `${CLIENT_PREFIX}${PORTAL}`,
  'X-Device-Fingerprint': fingerprintHeaderValue,
  'X-Profiling-Session-Id': profilingIdSelector,
} as const

type HeaderValues = {
  -readonly [k in keyof typeof CommonHeaders]?: string
}

const getPortalAxios = (store: Store<ConsumerAppState>): AxiosInstance => {
  const headers: HeaderValues = {
    'X-Requested-With': `${CLIENT_PREFIX}${PORTAL}`,
  }
  if (typeof store?.subscribe !== 'undefined') {
    store.subscribe(() => {
      for (const [key, value] of Object.entries(CommonHeaders)) {
        headers[key] = typeof value === 'function' ? value(store.getState()) : value
      }
    })
  }

  const mutex = new Mutex()
  const instance: AxiosInstance = axios.create({
    baseURL: api.PORTAL_API_BASE_URL,
    withCredentials: true,
  })

  // add seon header
  instance.interceptors.request.use(seonInterceptor)
  instance.interceptors.request.use(async (config) => {
    const amplitudeSessionId = (await import('amplitude-js')).default.getInstance()?.getSessionId()
    for (const [key, value] of Object.entries(headers)) {
      if (value !== undefined) {
        config.headers = config.headers ?? {}
        config.headers[key] = value
      }
    }
    if (amplitudeSessionId !== null) {
      config.headers = config.headers ?? {}
      config.headers['x-amplitude-session-id'] = amplitudeSessionId
    }
    return config
  })

  const resumePersistentLogin = async (): Promise<boolean> => {
    const headers = fingerprintHeader(store.getState())
    try {
      // Eject the interceptor temporarily to ensure we don't get ourselves into a loop of calling apis and handling
      // 401 status codes
      instance.interceptors.response.eject(resumeSessionInterceptor)
      // returns 201 on resume, 409 on conflict, 401 otherwise
      await instance.post('/portal/consumers/persistent-login/resume', undefined, {
        headers,
        validateStatus: (status) => status === 201,
      })
      return true
    } catch (e) {
      return false
    }
  }

  const onAuthenticationFailed = async (error): Promise<AxiosResponse> => {
    if (isAxiosError(error) && error.response?.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        if (await resumePersistentLogin()) {
          const result = await instance.request(error.config ?? {})
          // @ts-ignore: OPERATION BLEED STOPPER
          instance.interceptors.response.use(null, onAuthenticationFailed)
          release()
          return result
        } else {
          // can't import this as it breaks on the server
          const client = await import('next/client')
          const countryCode = getCountryCodeFromLocale(client.router.locale)
          const loginMigrationEnabled = getPortalMigrationEnabled(countryCode)
          if (loginMigrationEnabled) {
            const now = Date.now()
            setCookie(null, COOKIE_SHOW_NEW_PORTAL, 'true', {
              path: '/',
              secure: true,
              sameSite: 'none',
              expires: new Date(now + 1000 * 60 * 60 * 24 * 365),
            })
            await client.router.push(Route.INDEX)
          } else {
            switchToOldPortal()
          }
        }
      } else {
        await mutex.waitForUnlock()
        return await instance.request(error.config ?? {})
      }
    }
    throw error
  }
  // @ts-ignore: OPERATION BLEED STOPPER
  const resumeSessionInterceptor = instance.interceptors.response.use(null, onAuthenticationFailed)
  return instance
}

export const getPortalAxiosInstance = (): HttpService => new HttpService(getPortalAxios(baseStore))
export const portalAxios = getPortalAxiosInstance()

const topazAxios: AxiosInstance = axios.create({
  baseURL: api.TOPAZ_BASE_URL,
})

const shopAxios: AxiosInstance = axios.create({
  baseURL: api.SHOP_API_BASE_URL,
})

export const createTopazApi = (): TopazHttpService => new TopazHttpService(topazAxios)

export const createShopApi = (): ShopHttpService => new ShopHttpService(shopAxios)

export const createPortalApi = (): HttpService => portalAxios
