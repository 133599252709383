import React, { useMemo, FunctionComponent } from 'react'
import { Button, Divider, Heading, Link, Modal, Text } from '@afterpaytouch/core'
import { useFlag } from '../../../../hooks'
import clsx from 'clsx'
import { Trans, useTranslation } from 'next-i18next'
import styles from './style.module.scss'

const I18N_NAMESPACE = ['common', 'index']

interface HeaderInfoModalProps {
  isModalOpen: boolean
  closeModal: () => void
  isCreditLimit?: boolean
  isOwedAmount?: boolean
}

export const HeaderInfoModal: FunctionComponent<HeaderInfoModalProps> = ({ isModalOpen, closeModal, isCreditLimit = false, isOwedAmount = false }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isConsumerLendingEnabled = useFlag('consumer-portal-header-lending-enabled', false)
  const creditLimitContentTranslations = useMemo(
    () => ({
      heading: isConsumerLendingEnabled ? t('index:welcome:modal:header:heading-payIn4') : t('index:welcome:modal:header:heading'),
      content: {
        '1': isConsumerLendingEnabled ? t('index:welcome:modal:content:1-payIn4') : t('index:welcome:modal:content:1'),
        '2': isConsumerLendingEnabled ? t('index:welcome:modal:content:2-payIn4') : t('index:welcome:modal:content:2'),
        '3': t('index:welcome:modal:content:3'),
        '4': {
          '1': isConsumerLendingEnabled ? t('index:welcome:modal:content:4:1-payIn4') : t('index:welcome:modal:content:4:1'),
          li1: t('index:welcome:modal:content:4:li1'),
          li2: t('index:welcome:modal:content:4:li2'),
        },
        '5': 'index:welcome:modal:content:5',
        helpLinkHref: t('index:welcome:modal:content:helpLinkHref'),
        footer: {
          button: isConsumerLendingEnabled ? t('index:welcome:modal:footer:button-payIn4') : t('index:welcome:modal:footer:button'),
        },
      },
    }),
    [t]
  )

  const creditLimitContent = (): JSX.Element => {
    return (
      <>
        <div className={styles.headerContainer} data-testid='credit-limit-text'>
          <Modal.Header>
            <div className={clsx(styles.headingContainer, 'text-center')}>
              <Heading size='L'>{creditLimitContentTranslations.heading}</Heading>
            </div>
            <Divider kind='Hairline' />
          </Modal.Header>
        </div>
        <Modal.Content>
          <Text>{creditLimitContentTranslations.content[1]}</Text>
          <br />
          <Text>{creditLimitContentTranslations.content[2]}</Text>
          <br />
          <Text bold>{creditLimitContentTranslations.content[3]}</Text>
          <br />
          <Text>{creditLimitContentTranslations.content[4][1]}</Text>
          <br />
          <ul>
            <li>
              <Text>{creditLimitContentTranslations.content[4].li1}</Text>
            </li>
            <li>
              <Text>{creditLimitContentTranslations.content[4].li2}</Text>
            </li>
          </ul>
          <br />
          <Text>
            <Trans
              i18nKey={creditLimitContentTranslations.content[5]}
              components={{
                helpLink: <Link href={creditLimitContentTranslations.content.helpLinkHref} target='_blank' kind='Underline' />,
              }}
            />
          </Text>
        </Modal.Content>
        <Modal.Footer>
          <Button kind='Primary' padding='Fluid' onClick={closeModal}>
            {creditLimitContentTranslations.content.footer.button}
          </Button>
        </Modal.Footer>
      </>
    )
  }

  const owedAmountContent = (): JSX.Element => {
    return (
      <>
        <div className={styles.headerContainer} data-testid='owed-amount-text'>
          <Modal.Header>
            <div className={clsx(styles.headingContainer, 'text-center')}>
              <Heading size='L'>{t('index:welcome:owedAmountModal:header:heading')}</Heading>
            </div>
            <Divider kind='Hairline' />
          </Modal.Header>
        </div>
        <Modal.Content>
          <Text>{t('index:welcome:owedAmountModal:content:1')}</Text>
          <br />
          <Text>{t('index:welcome:owedAmountModal:content:2')}</Text>
          <br />
          <Text>{t('index:welcome:owedAmountModal:content:3')}</Text>
        </Modal.Content>
        <Modal.Footer>
          <Button kind='Primary' padding='Fluid' onClick={closeModal}>
            {t('index:welcome:owedAmountModal:footer:button')}
          </Button>
        </Modal.Footer>
      </>
    )
  }

  return (
    <Modal show={isModalOpen} onClose={closeModal} onHide={closeModal} onBackdropClick={closeModal} onEscapeKeyDown={closeModal}>
      {isCreditLimit && creditLimitContent()}
      {isOwedAmount && owedAmountContent()}
    </Modal>
  )
}
