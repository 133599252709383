import { Modal } from '@afterpaytouch/core'
import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { ConfirmNewSchedule } from './ConfirmNewSchedule'
import { PreferredDayModalProvider, PreferredDaySteps, useSetPreferredDayModalContext } from './PreferredDayModalContext'
import { PreferredDayModalHeader } from './PreferredDayModalHeader'
import { PreferredPaydayResult } from './PreferredPaydayResult'
import { SelectPreferredDay } from './SelectPreferredDay'

export interface PreferredDayModalProps {
  isModalOpen: boolean
  closeModal: () => void
  animate?: boolean
}

export const PreferredDayModalSteps: FunctionComponent<PreferredDayModalProps> = ({ isModalOpen, closeModal, animate }) => {
  const { step, show, resetState } = useSetPreferredDayModalContext()
  const renderStep = useCallback((): React.ReactNode => {
    switch (step) {
      case PreferredDaySteps.SelectPreferredDay:
        return <SelectPreferredDay />
      case PreferredDaySteps.ConfirmNewSchedule:
        return <ConfirmNewSchedule />
      case PreferredDaySteps.PreferredPaydayResult:
        return <PreferredPaydayResult />
    }
  }, [step])

  useEffect(() => {
    if (!show) {
      resetState()
    }
  }, [show, resetState])

  return (
    <>
      <Modal testNameSpace='preferred-day-modal' show={isModalOpen} onBackdropClick={closeModal} onEscapeKeyDown={closeModal} animate={animate}>
        <PreferredDayModalHeader />
        {renderStep()}
      </Modal>
    </>
  )
}

export const PreferredDayModal: FunctionComponent<PreferredDayModalProps> = ({ isModalOpen, closeModal, animate = false }) => {
  return (
    <PreferredDayModalProvider show={isModalOpen} closeModal={closeModal}>
      <PreferredDayModalSteps isModalOpen={isModalOpen} closeModal={closeModal} animate={animate} />
    </PreferredDayModalProvider>
  )
}
