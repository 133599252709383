import React, { FunctionComponent } from 'react'
import { Text, Icon, Button } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { AppLink } from '../../../AppLink'
import { Route } from '../../../../config/router'
import { useFlag } from '../../../../hooks'
import { useIsPaymentScheduleIncludePendingPaymentSelector } from '../../../../state'

interface OwedAmountButtonProps {
  openModal: () => void
  isUpcomingPaymentsLinkRequired: boolean
}

export const OwedAmountButton: FunctionComponent<OwedAmountButtonProps> = ({ openModal, isUpcomingPaymentsLinkRequired }) => {
  const { t } = useTranslation()
  const isAchFlagEnabled = useFlag('ach-eligibility-enabled', false)
  const isPaymentPending = useIsPaymentScheduleIncludePendingPaymentSelector()

  return (
    <div>
      {isUpcomingPaymentsLinkRequired && (
        <AppLink route={Route.UPCOMING_PAYMENTS} kind='Underline'>
          <Text renderAs='span' size='M' testNameSpace='owed-amount-link' bold>
            {t('index:welcome:existingUser:label:totalOwed')} {isPaymentPending && `(${t('index:welcome:existingUser:label:pending')})`}
          </Text>
        </AppLink>
      )}
      {isAchFlagEnabled && (
        <Button.Link testNameSpace='owed-amount-button' onClick={openModal} aria-label={t('index:welcome:existingUser:htmlAttribute:ariaLabel:openModal')}>
          <span className='ml-2'>
            <Icon.Info
              size='Small'
              role='img'
              title={t('index:welcome:existingUser:htmlAttribute:icon:info:title')}
              description={t('index:welcome:existingUser:htmlAttribute:icon:info:description')}
            />
          </span>
        </Button.Link>
      )}
    </div>
  )
}
