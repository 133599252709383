import { Link, LinkKinds } from '@afterpaytouch/core'
import clsx from 'clsx'
import React, { FunctionComponent, ReactNode } from 'react'
import { Route } from '../../config/router'
import { push } from 'connected-next-router'
import styles from './style.module.css'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { useAppDispatch } from '../../state'
export interface AppLinkProps {
  route: Route
  kind?: LinkKinds
  noMargin?: boolean
  center?: boolean
  disabled?: boolean
  inline?: boolean
  trackEventName?: string
  children?: ReactNode
}

export const AppLink: FunctionComponent<AppLinkProps> = ({
  route,
  kind,
  noMargin = false,
  center = false,
  inline = true,
  disabled = false,
  trackEventName = '',
  children,
}) => {
  const amplitude = useAmplitudeWithEnduringEventProperties()
  const className = clsx(styles.wrapper, noMargin && styles.noMargin, center && styles.center, inline ? 'inline' : 'block')
  const dispatch = useAppDispatch()
  const testNameSpace = route.replace(/\//, '').replace(/\//g, '-')

  return (
    <span className={className}>
      <Link
        kind={kind}
        disabled={disabled}
        onClick={async () => {
          if (trackEventName !== '' || trackEventName !== undefined) {
            // @ts-expect-error TODO: fix type error
            amplitude.logEvent(trackEventName)
          }
          dispatch(push(route))
        }}
        testNameSpace={testNameSpace}
      >
        {children}
      </Link>
    </span>
  )
}
