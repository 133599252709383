import React, { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { TransProps } from 'react-i18next'
import { TermLink } from '../../TermLink'
import { Grid, Text } from '@afterpaytouch/core'

const I18N_NAMESPACE = ['common', 'verifyIdentity']

export const Terms: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const getTranslation = (): TransProps<string> => {
    const components = {
      terms: <TermLink key='verify-identity-passport-terms' href={t('common:urls:termsOfServiceUrl')} />,
      privacy: <TermLink key='verify-identity-passport-privacy' href={t('common:urls:privacyPolicyUrl')} />,
    }

    return { i18nKey: 'verifyIdentity:passport:terms', components }
  }

  return (
    <Grid>
      <Text color='Gray40' size='XXS'>
        <Trans {...getTranslation()} />
      </Text>
    </Grid>
  )
}
