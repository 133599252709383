export const maskBankAccountNumber = (bankAccountNumber: string): string => {
  if (bankAccountNumber?.length < 3) {
    return bankAccountNumber
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  return `•••• ${bankAccountNumber?.match(/\d{3,4}$/)[0]}`
}

// Separates bank name and number in form of array
export const formatBank = (bank: string): string[] => {
  const bankName = []
  const bankNumber = []
  bank.split('').forEach((char) => {
    if (/^[a-zA-Z ]*$/.test(char)) {
      // @ts-ignore: OPERATION BLEED STOPPER
      bankName.push(char)
    } else {
      // @ts-ignore: OPERATION BLEED STOPPER
      bankNumber.push(char)
    }
  })
  return [bankName.join('').trim(), maskBankAccountNumber(bankNumber.join(''))]
}
