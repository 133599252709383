import React, { FunctionComponent, useEffect } from 'react'
import Image from 'next/image'
import { Button, Heading, Icon, Modal, Text } from '@afterpaytouch/core'
import { useSetPreferredDayModalContext } from '../PreferredDayModalContext'
import { Trans, useTranslation } from 'next-i18next'
import { Weekdays } from '../types'
import { useConsumerSizes } from '../../../../../utils/responsive'
import { useAmplitudeWithEnduringEventProperties } from '../../../../../integrations/amplitude'
import { AmplitudeEventTypes } from '../../../../../integrations/amplitude/types'
import { useAlignPaydayEntryPoint } from '../../hooks'
import { useDisplayIllustrations } from '../../../../../state/convergence/hooks'

const I18N_NAMESPACE = ['account']

export const PreferredPaydayResult: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const isDisplayIllustrations = useDisplayIllustrations()
  const { selectedPreferredDay, closeModal, result } = useSetPreferredDayModalContext()
  const { headingJumboSize } = useConsumerSizes()
  const preferredDayIndex = Weekdays[selectedPreferredDay]
  const alignPaydayEntryPoint = useAlignPaydayEntryPoint()

  const handleClose = (): void => {
    closeModal()
    logEvent(AmplitudeEventTypes.ALIGN_PAY_DAY.OK_BUTTON_CLICKED, {
      preferredDay: selectedPreferredDay,
      route: alignPaydayEntryPoint,
      success: result?.success,
    })
  }

  useEffect(() => {
    if (result?.error) {
      logEvent(AmplitudeEventTypes.ALIGN_PAY_DAY.ERROR_MODAL_VIEWED, { route: alignPaydayEntryPoint })
    }
  }, [result])

  const renderSuccess = (): React.ReactNode => {
    return (
      <div className='flex flex-col justify-center'>
        {isDisplayIllustrations && (
          <Image
            width={238}
            height={116}
            src={`/images/payment-success.svg`}
            alt={'payment:success:graphic'}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
        <div className='pt-4 text-center'>
          <Heading size={headingJumboSize}>{t('account:tab:payment-methods:preferred-day:modal:result:header:success')}</Heading>
        </div>
        <div className='mb-3 px-16 text-center'>
          <Text>
            <Trans
              i18nKey='account:tab:payment-methods:preferred-day:modal:resultMessage:success'
              values={{ selectedDay: t(`account:tab:payment-methods:preferred-day:weekday:${preferredDayIndex}`) }}
              components={{
                preferredDay: <Text bold renderAs='span' />,
              }}
            />
          </Text>
        </div>
        <div className='bg-dark-prominent my-4 rounded-xl p-4'>
          <div className='flex'>
            <span className='pr-3'>
              <Icon.Info size='ExtraSmall' role='img' />
            </span>
            <span className='pr-8'>
              <Text size='S'>{t('account:tab:payment-methods:preferred-day:modal:result:info')}</Text>
            </span>
          </div>
        </div>
        <Button padding='Fluid' onClick={handleClose}>
          <Text bold>{t('account:tab:payment-methods:preferred-day:modal:ok:cta')}</Text>
        </Button>
      </div>
    )
  }

  const renderError = (): React.ReactNode => {
    return (
      <>
        <div className='pb-6 pt-2'>
          <Text color='Fire'>{t('account:tab:payment-methods:preferred-day:modal:resultMessage:error')}</Text>
        </div>
        <Button padding='Fluid' onClick={handleClose}>
          <Text bold>{t('account:tab:payment-methods:preferred-day:modal:close:cta')}</Text>
        </Button>
      </>
    )
  }
  return <Modal.Content>{result?.success ? renderSuccess() : renderError()}</Modal.Content>
}
