import React, { FunctionComponent, useRef, ReactNode, ReactElement, useState } from 'react'
import { Heading, Carousel, Card, Text, Tag } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { Category } from '@afterpaytouch/shop-api/types'
import { useRouter } from 'next/router'
import { useGetCategories } from './hooks'
import { useConsumerSizes } from '../../utils/responsive'
import styles from './style.module.scss'
import { OfferModal, OfferModalInfo } from './OfferModal'
import { EmptyCardSkeleton } from './EmptyCardSkeleton'
import { ExtendedPanel } from '../Panel'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'

const I18N_NAMESPACE = ['common', 'index']

export interface storesProps {
  category?: string
}

export const Stores: FunctionComponent<storesProps> = ({ category = 'index:stores:category:trending' }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const { storesEmptyItemsSize } = useConsumerSizes()
  const carouselControlsRef = useRef<HTMLDivElement>()
  const router = useRouter()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState<OfferModalInfo>()
  const [brokenLogoImgs, setBrokenLogoImgs] = useState([])
  const [brokenBgImgs, setBrokenBgImgs] = useState([])

  const { getCategoriesData, isGetCategoriesDataLoading, isGetCategoriesDataError, refetchGetCategories } = useGetCategories({ category })

  if (isGetCategoriesDataError) {
    return <ExtendedPanel.Error onClick={refetchGetCategories} />
  }

  const handleLogoImgError = (id): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    setBrokenLogoImgs([...brokenLogoImgs, id])
  }

  const handleBgImgError = (id): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    setBrokenBgImgs([...brokenBgImgs, id])
  }

  const getOfferTag = (card: Category): ReactElement[] => {
    const info = card.attributes
    if (info.offer_teaser === '' || info.offer_teaser == null || typeof info?.offer_teaser === 'undefined') {
      // @ts-ignore: OPERATION BLEED STOPPER
      return
    }
    const storeName = info.name ?? ''
    const offerTitle = info.offer_teaser ?? ''
    const offerDetail = info.offer_text ?? ''
    const offerTerms = info.offer_terms ?? ''
    const offerLogo = 'https:' + info.new_logo_url ?? ''
    const shopUrl = info.shop_url ?? ''

    return [
      <Tag
        key={`${card.id} - 0`}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsModalOpen(true)
          setModalInfo({
            offerTitle,
            offerDetail,
            offerTerms,
            offerLogo,
            storeName,
            shopUrl,
          })
        }}
      >
        {offerTitle}
      </Tag>,
    ]
  }

  const renderCards = (): ReactNode => {
    return getCategoriesData.data.map((card) => {
      const info = card.attributes
      const bgUrl = 'https:' + info.main_image_url ?? ''
      const shopUrl = info.shop_url ?? ''
      const logoUrl = 'https:' + info.new_logo_url ?? ''
      const storeName = info.name ?? ''
      const inStore = info.has_locations ?? false
      const locationLink = info.has_locations ? card.links.location_url : undefined

      if (bgUrl === '' || shopUrl === '' || logoUrl === '' || storeName === '') {
        return null
      }

      // @ts-ignore: OPERATION BLEED STOPPER
      const logoElement = !brokenLogoImgs.includes(card.id) ? (
        <img src={logoUrl} alt={storeName} className={styles.logoImg} onError={() => handleLogoImgError(card.id)} />
      ) : (
        <div className={styles.logoText}>
          <Text size='XXS' lineClamp={1} bold>
            {storeName}
          </Text>
        </div>
      )

      // @ts-ignore: OPERATION BLEED STOPPER
      const backgroundElement = !brokenBgImgs.includes(card.id) && (
        <img src={bgUrl} className={styles.bgImg} role='presentation' onError={() => handleBgImgError(card.id)} />
      )

      const onClickCard = (): void => {
        logEvent(TrackingEvent.CLICKED_SHOP_TILE, { outboundLink: shopUrl, locale: router?.locale, context: 'new_web_portal' })
      }

      return (
        <div key={card.id} onClick={onClickCard}>
          <Card background={backgroundElement} href={shopUrl} logo={logoElement} locationLink={locationLink} tags={getOfferTag(card)}>
            <Card.Footer>
              <Heading>{storeName}</Heading>
              {inStore && <Text size='XS'>{t('index:stores:inStore')}</Text>}
            </Card.Footer>
          </Card>
        </div>
      )
    })
  }

  const renderCarouselContent = (): ReactNode => {
    if (isGetCategoriesDataLoading || getCategoriesData == null || getCategoriesData.data == null || getCategoriesData.data.length === 0) {
      return (
        <>
          {/* @ts-ignore: OPERATION BLEED STOPPER */}
          <Carousel.Content controlContainerDom={carouselControlsRef.current}>
            {[...Array(storesEmptyItemsSize)].map((a, i) => (
              <EmptyCardSkeleton key={i} />
            ))}
          </Carousel.Content>
        </>
      )
    } else {
      return (
        <>
          {/* @ts-ignore: OPERATION BLEED STOPPER */}
          <Carousel.Controls>
            {/* @ts-ignore: OPERATION BLEED STOPPER */}
            <div className={styles.carouselControls} ref={carouselControlsRef}></div>
          </Carousel.Controls>
          {/* @ts-ignore: OPERATION BLEED STOPPER */}
          <Carousel.Content controlContainerDom={carouselControlsRef.current}>{renderCards()}</Carousel.Content>
        </>
      )
    }
  }
  return (
    <div className={styles.stores}>
      <Carousel>{renderCarouselContent()}</Carousel>
      <OfferModal show={isModalOpen} onClose={() => setIsModalOpen(false)} modalInfo={modalInfo}></OfferModal>
    </div>
  )
}
