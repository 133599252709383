import { Button, Heading, Link, Modal } from '@afterpaytouch/core'
import React, { FunctionComponent, ReactNode, useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { StatusMessage } from '../WelcomePanel/ExistingUser/StatusMessage'
import { CreditFacilityDocument } from '@afterpaytouch/portal-api'

export const ClosedStatusMessage: FunctionComponent<{ pclStatement?: CreditFacilityDocument; creditContract?: CreditFacilityDocument }> = ({
  pclStatement,
  creditContract,
}) => {
  const I18N_NAMESPACE = ['common', 'payMonthly']
  const { t } = useTranslation(I18N_NAMESPACE)

  const [isDocsLinksModalShow, setIsDocsLinksModalShow] = useState(false)

  const testNameSpace = 'close-status-message'
  const textChild = (
    <Trans
      i18nKey={'payMonthly:closeCreditFacility:closed:message'}
      components={{
        DocsLink: <Button.Link testNameSpace={testNameSpace} onClick={() => setIsDocsLinksModalShow(true)} />,
      }}
    />
  )

  const renderDocsLinksModal = (): ReactNode => {
    if (typeof creditContract === 'undefined' || creditContract?.url == null || typeof creditContract?.url === 'undefined') {
      return null
    }

    const hasNotPCLStatement = typeof pclStatement === 'undefined' || pclStatement?.url == null || typeof pclStatement?.url === 'undefined'
    return (
      <Modal testNameSpace={testNameSpace} show={isDocsLinksModalShow} onClose={() => setIsDocsLinksModalShow(false)}>
        <Modal.Header divider>
          <div className='text-center'>
            <Heading size='L'>{t('payMonthly:closeCreditFacility:closed:linkModal:heading')}</Heading>
          </div>
        </Modal.Header>
        <Modal.Content>
          {!hasNotPCLStatement && (
            <div className='mb-4'>
              <Link size='S' bold={false} kind='Underline' href={pclStatement.url as string} target='_blank' rel='noopener noreferrer' color='Orca'>
                {t('payMonthly:closeCreditFacility:closed:modal:statements')}
              </Link>
            </div>
          )}
          <Link size='S' bold={false} kind='Underline' href={creditContract.url} target='_blank' rel='noopener noreferrer' color='Orca'>
            {t('payMonthly:panel:content:details:creditContract')}
          </Link>
        </Modal.Content>
      </Modal>
    )
  }

  return (
    <>
      <StatusMessage iconName='Link' textChild={textChild} />
      {renderDocsLinksModal()}
    </>
  )
}
