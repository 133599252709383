import { Theme } from '@afterpaytouch/core'
import {
  THEME_COOKIE_NAME,
  SetThemeRequest,
  ConvergenceRollout,
  ConvergenceAvailable,
  Country,
  ConvergenceCountryTheme,
  DEFAULT_THEME,
  ConvergenceUnavailable,
  portalMigrationEnabledCountry,
} from '../model'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { isUndefinedOrEmpty } from '@afterpay/utils'
import { GetServerSidePropsContext } from 'next'
import { app } from '../env'
import { LaunchDarklyFlags } from '../hooks'

export const isConvergenceEnabled = (rollout: ConvergenceRollout): boolean => Object.values(ConvergenceAvailable).includes(rollout as ConvergenceAvailable)

export const getFallbackTheme = (): Theme => (app.COLD_START_THEME.length > 0 && isValidThemeValue(app.COLD_START_THEME) ? app.COLD_START_THEME : DEFAULT_THEME)

export const setConvergenceState = (country: Country, convergenceRollout: ConvergenceRollout, context: GetServerSidePropsContext): void => {
  if (convergenceRollout === ConvergenceAvailable.Enabled || convergenceRollout === ConvergenceUnavailable.DisabledWithOverride) {
    updateTheme({ theme: getThemeByLocale(country) }, context)
    return
  }
  destroyCookie(null, THEME_COOKIE_NAME)
}

export const getPortalMigrationEnabled = (country: Country): boolean => {
  const isPortalMigrationEnabledByCountry = portalMigrationEnabledCountry.includes(country)
  if (!isPortalMigrationEnabledByCountry) {
    return false
  }
  const cookies = parseCookies()
  const loginMigrationEnabledInCookie = (typeof cookies === 'object' && cookies[LaunchDarklyFlags.LoginMigration]) ?? 'false'
  const loginMigrationEnabled = loginMigrationEnabledInCookie.toString() === 'true'
  return loginMigrationEnabled
}

export const setPortalMigrationState = (loginMigrationValue: string, context: GetServerSidePropsContext, country: Country): void => {
  const isPortalMigrationEnabledByCountry = portalMigrationEnabledCountry.includes(country)
  const now = Date.now()
  if (loginMigrationValue === 'C' || !isPortalMigrationEnabledByCountry) {
    // hard false, the prod default is C while FF is off. Go to the old portal, save false in the cookie
    setCookie(context, 'consumer-portal-login-migration-variant', 'false', {
      path: '/',
      sameSite: 'lax',
      expires: new Date(now + 1000 * 60 * 60 * 24 * 365),
    })
    return
  }
  if (loginMigrationValue === 'A') {
    // serve true, stay in new portal, save true in the cookie
    setCookie(context, LaunchDarklyFlags.LoginMigration, 'true', {
      path: '/',
      sameSite: 'lax',
      expires: new Date(now + 1000 * 60 * 60 * 24 * 365),
    })
  }
  // loginMigrationValue === 'B', do nothing. soft false, cookie has the higher priority
}

export const getActiveTheme = (
  { cookies }: Pick<GetServerSidePropsContext['req'], 'cookies' | 'url'>,
  country: Country,
  convergenceRollout: ConvergenceRollout
): Theme => {
  const themeByLocale = getThemeByLocale(country)
  if (convergenceRollout === ConvergenceUnavailable.DisabledWithOverride) {
    const storedTheme = cookies[THEME_COOKIE_NAME]
    return isValidThemeValue(storedTheme) ? storedTheme : DEFAULT_THEME
  }

  return convergenceRollout === ConvergenceAvailable.Enabled ? themeByLocale : DEFAULT_THEME
}

export const getThemeByLocale = (country: Country | undefined): Theme => {
  return country ? ConvergenceCountryTheme[country] : DEFAULT_THEME
}

export const updateTheme = async (data: SetThemeRequest, context: GetServerSidePropsContext): Promise<void> => {
  try {
    setCookie(context, THEME_COOKIE_NAME, data.theme, {
      path: '/',
      secure: true,
      sameSite: 'none',
    })
  } catch (error) {
    console.error('Failed to set theme')
  }
}

export const isValidThemeValue = (value?: string): value is Theme => !isUndefinedOrEmpty(value) && Object.values(Theme).includes(value as Theme)
