import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { CreditCard, isFetchBaseQueryError, isPortalApiError, Money } from '@afterpaytouch/portal-api'
import { BankAccountDetails, PaymentCardDetails } from '@afterpay/types'
import { PaymentSchedule, PaymentScheduleDueResult, PaymentStatus } from '../../model'
import { confirmPayErrors } from './constants'

export const getPreferredPaymentMethod = (
  methods: Array<BankAccountDetails | PaymentCardDetails | CreditCard>
  // @ts-ignore: OPERATION BLEED STOPPER
): BankAccountDetails | PaymentCardDetails | CreditCard => methods?.find(({ preferred }) => preferred)

export const isPaymentScheduleAllowedToBePaid = (
  paymentScheduleDueResult: PaymentScheduleDueResult,
  paymentScheduleDueList: PaymentScheduleDueResult[]
): boolean => {
  return (
    !isPaymentSchedulePaid(paymentScheduleDueResult.paymentSchedule) &&
    havePreviousPaymentSchedulesBeenPaid(paymentScheduleDueResult, paymentScheduleDueList) &&
    isValidAmount(paymentScheduleDueResult.paymentSchedule.amountOwed)
  )
}

export const isPaymentSchedulePaid = (paymentSchedule: PaymentSchedule): boolean => {
  return paymentSchedule.status === PaymentStatus.PAID || paymentSchedule.status.toString() === 'PAID'
}

export const isPaymentScheduleOverdue = (paymentSchedule: PaymentSchedule): boolean => {
  return paymentSchedule.status === PaymentStatus.OVERDUE || paymentSchedule.status.toString() === 'OVERDUE'
}

export const isPaymentSchedulePending = (paymentSchedule: PaymentSchedule): boolean => {
  return paymentSchedule.status === PaymentStatus.PAYMENT_PENDING || paymentSchedule.status.toString() === 'PAYMENT_PENDING'
}

export const havePreviousPaymentSchedulesBeenPaid = (
  paymentScheduleDueResult: PaymentScheduleDueResult,
  paymentScheduleDueList: PaymentScheduleDueResult[]
): boolean => {
  // @ts-ignore: OPERATION BLEED STOPPER
  const isAllPreviousPaymentSchedulesBeenPaid: Boolean[] = paymentScheduleDueList.map((scheduleItem) => {
    const isSameOrder = scheduleItem.orderNumber === paymentScheduleDueResult.orderNumber
    const isPreviousItem = scheduleItem.paymentSchedule.id < paymentScheduleDueResult.paymentSchedule.id
    if (isSameOrder && isPreviousItem) {
      const isPreviousPaymentScheduleBeenPaid = isPaymentSchedulePaid(scheduleItem.paymentSchedule) || isPaymentSchedulePending(scheduleItem.paymentSchedule)
      return isPreviousPaymentScheduleBeenPaid
    } else {
      return null
    }
  })
  const havePreviousPaymentSchedulesBeenNotPaid = isAllPreviousPaymentSchedulesBeenPaid.includes(false)
  return !havePreviousPaymentSchedulesBeenNotPaid
}

export const isValidAmount = (amount: Money): boolean => {
  return parseFloat(amount.amount) > 0
}

export const itemDueIn = (item, fromDate = new Date()): number => {
  let dueIn = item.status
  // Support for ISO 8601 formats differs in that date-only strings (e.g. "1970-01-01") are treated as UTC, not local.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
  // Convert installment due date format YYYY-MM-DD to individual objects to get local time
  const dateParsed = (item.installmentDueDate || '').split('-')
  // Month is zero-based
  const installmentDueDate = new Date(dateParsed[0], dateParsed[1] - 1, dateParsed[2])
  const oneDay = 24 * 60 * 60 * 1000

  // The backend only considers dates and not datetimes
  // when calculating the payment status, this means the
  // payment is due "NOW" only if the two dates are the same
  fromDate.setHours(0, 0, 0, 0)
  installmentDueDate.setHours(0, 0, 0, 0)

  dueIn = Math.round((installmentDueDate.getTime() - fromDate.getTime()) / oneDay)

  return dueIn
}

// @ts-ignore: OPERATION BLEED STOPPER
export const getConfirmPayError = (error: FetchBaseQueryError | SerializedError): string | null => {
  if (isFetchBaseQueryError(error)) {
    const { data } = error
    return isPortalApiError(data) ? confirmPayErrors[data?.errorCode] ?? null : null
  }
}

// Temporarily offset installmentSequence to make it's index 0 based
export const getInstallmentSequenceOffset = (installmentSequence: number): number => {
  return (installmentSequence ?? 0) - 1
}

export const formatDueInDays = (dueIn: number): string => {
  if (dueIn === 0) {
    return 'upcomingPayments:activeUpcomingPayments:dueNow'
  }

  if (dueIn === 1) {
    return `upcomingPayments:activeUpcomingPayments:dayCount_one`
  }

  if (dueIn > 0) {
    return `upcomingPayments:activeUpcomingPayments:dayCount_other`
  }

  return 'upcomingPayments:activeUpcomingPayments:overDue'
}
