import { defaultOrdersParams } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import {
  useConsumerHasPreferredPaymentMethod,
  useConsumerIdentityIsValid,
  useGetCreditCardsQuery,
  useGetOrderTransactionsQuery,
  useHasOrderTransactions,
  useHasPreferredCreditCard,
} from '../../'
import { isExistingUser } from './utils'

export const useAccountProgressionIsExistingUser = (): boolean => {
  useGetCreditCardsQuery()
  useGetOrderTransactionsQuery(defaultOrdersParams)

  const hasOrders = useHasOrderTransactions()
  const identityIsValid = useConsumerIdentityIsValid()

  /*
   * Account lookup preferredPaymentMethod field can appear as null even when a payment method is set as preferred.
   * Use account lookup and fall back to individual payment method lookups to determine if consumer has any preferred payment method set.
   * https://apglobal.atlassian.net/browse/PAYL-17079
   * TODO: Add Bank Accounts lookup to determine hasPreferredPaymentMethod.
   */
  const consumerHasPreferredCreditCard = useHasPreferredCreditCard()
  const consumerHasPreferredPaymentMethod = useConsumerHasPreferredPaymentMethod()
  const hasPreferredPaymentMethod = consumerHasPreferredPaymentMethod || consumerHasPreferredCreditCard

  return isExistingUser(identityIsValid, hasPreferredPaymentMethod, hasOrders)
}
