import { InternalAxiosRequestConfig } from 'axios'
import { useFlag } from '@afterpaytouch/feature'
import React, { FunctionComponent, useEffect, ReactNode } from 'react'
import { useProfilingId } from '../../state'

import { useScript } from '../../utils/react'
import { getSessionStorageItem, setSessionStorageItem } from '@afterpay/utils/storage'
import { isUndefinedOrEmpty } from '@afterpay/utils/string'
import { BASE_PATH } from '../../env'

const seonStorageKey = 'seon-token'

type URLPredicate = (url: string, baseUrl?: string) => boolean

let isUrlEnabled: URLPredicate = () => false

interface ISeonConfig {
  session_id: string
  audio_fingerprint?: boolean
  canvas_fingerprint?: boolean
  webgl_fingerprint?: boolean
  onSuccess?: () => void
  onError?: () => void
}

declare global {
  interface Window {
    seon?: {
      config: (config: ISeonConfig) => void
      getBase64Session: (callback: (data: string) => void) => void
    }
  }
}

export const seonInterceptor = (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
  const { url, baseURL } = config
  const seonToken = getSessionStorageItem<string>(seonStorageKey)
  // @ts-ignore: OPERATION BLEED STOPPER
  if (isUrlEnabled(url, baseURL) && !isUndefinedOrEmpty(seonToken)) {
    config.headers = config.headers ?? {}
    // @ts-ignore: OPERATION BLEED STOPPER
    config.headers['X-SEON-Device-Info'] = seonToken
  }

  return config
}

const ensureArray = (value: any): any[] => (Array.isArray(value) ? value : [])
const isAbsolute = /^https?:\/\//

const getPath = (url: string, baseUrl: string = 'http://test'): string => {
  const fullUrl = isAbsolute.test(url) ? url : `${baseUrl}${url}`
  try {
    return new URL(fullUrl).pathname
  } catch (e) {
    return ''
  }
}

const createPredicate = (enabledUrls: string[]): URLPredicate => {
  if (enabledUrls.length === 0) {
    return () => false
  }
  if (enabledUrls.length === 1 && enabledUrls[0] === 'all') {
    return () => true
  }
  const regExps = enabledUrls.map((pattern) => {
    return new RegExp('^' + pattern.replace(/{var}/g, '[^/]+') + '$')
  })
  return (url, baseUrl) => {
    const path = getPath(url, baseUrl)
    return regExps.some((re) => re.test(path))
  }
}

interface SEONProps {
  children: ReactNode
}

export const SEON: FunctionComponent<SEONProps> = ({ children }) => {
  const { loaded } = useScript({
    url: `${(BASE_PATH as string) ?? ''}/vendor/seon-js-agent-4.1.2-op.js`,
    name: 'seon',
  })
  const profilingId = useProfilingId()
  const enabledUrls: string[] = ensureArray(useFlag<string[]>('seon-management-to-consumer-portal-api', []))
  isUrlEnabled = createPredicate(enabledUrls)

  useEffect(() => {
    const seonToken = getSessionStorageItem<string>(seonStorageKey)
    if (!isUndefinedOrEmpty(seonToken)) {
      return
    }

    if (loaded && profilingId !== null) {
      // @ts-ignore: OPERATION BLEED STOPPER
      window.seon.config({
        session_id: profilingId,
        audio_fingerprint: true,
        canvas_fingerprint: true,
        webgl_fingerprint: true,
        onSuccess: () => {
          // @ts-ignore: OPERATION BLEED STOPPER
          window.seon.getBase64Session((data: string) => {
            setSessionStorageItem<string>(seonStorageKey, data)
          })
        },
      })
    }
  }, [loaded, profilingId])

  return <>{children}</>
}
