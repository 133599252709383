import React, { FC } from 'react'
import { Button, Heading, Modal, Text } from '@afterpaytouch/core'
import { useDisputeTranslation } from '../hooks'
import { useConsumerSizes } from '../../../utils/responsive'
import { useDisputeCreationModalContext } from '../DisputeCreationModalContext'
import { TrackOnMount } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

export const CreateSuccess: FC = () => {
  const t = useDisputeTranslation()
  const { textSize } = useConsumerSizes()
  const { closeModal } = useDisputeCreationModalContext()

  const heading = t('result:success:head')
  const subtitle = t('result:success:subtitle')
  const cta = t('result:success:closeCTA')

  return (
    <>
      <TrackOnMount eventName={TrackingEvent.VIEWED_CREATE_DISPUTE_SUCCESS} />
      <Modal.Header divider={true}>
        <div className='text-center'>
          <Heading size='L'>{heading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Text size={textSize}>{subtitle}</Text>
        <div className='mt-9'>
          <Button padding='Fluid' onClick={closeModal}>
            {cta}
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}
