import getConfig from 'next/config'
import { Theme } from '@afterpaytouch/core'

const { publicRuntimeConfig = {} } = getConfig()
const { config = {} } = publicRuntimeConfig

export const api = {
  PORTAL_API_BASE_URL: config.PORTAL_API_BASE_URL ?? '',
  TOPAZ_BASE_URL: config.TOPAZ_BASE_URL ?? '',
  PAYMENT_SOURCE_BASE_URL: config.PAYMENT_SOURCE_BASE_URL ?? '',
  SHOP_API_BASE_URL: config.SHOP_API_BASE_URL ?? '',
}

export const app = {
  ADWORDS_ID: config.ADWORDS_ID ?? '',
  AMPLITUDE_CLIENT_SIDE_ID: config.AMPLITUDE_CLIENT_SIDE_ID ?? '',
  APP_ENVIRONMENT: config.APP_ENVIRONMENT ?? '',
  APP_VERSION: config.APP_VERSION ?? '',
  APPLE_PAY_MERCHANT_ID: config.APPLE_PAY_MERCHANT_ID ?? '',
  APPLE_PAY_ACQUIRING_MERCHANT_ID: config.APPLE_PAY_ACQUIRING_MERCHANT_ID ?? '',
  BRAND_NAME: config.BRAND_NAME ?? '',
  BROCHURE_BASE_URL: config.BROCHURE_BASE_URL ?? '',
  FRIENDBUY_MERCHANT_ID: config.FRIENDBUY_MERCHANT_ID ?? '',
  GOOGLEMAPS_API_KEY: config.GOOGLEMAPS_API_KEY ?? '',
  HELP_BASE_URL: config.HELP_BASE_URL ?? '',
  KASADA_SRC: config.KASADA_SRC ?? '',
  LAUNCH_DARKLY_CLIENT_SIDE_ID: config.LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '5baa19e1a3050e21323b5e58',
  SENTRY_DSN: config.SENTRY_DSN ?? '',
  COLD_START_THEME: config.COLD_START_THEME ?? Theme.AP,
  SNAP_CHAT_PIXEL_ID: config.SNAP_CHAT_PIXEL_ID ?? '',
  TM_ORGANISATION_ID: config.TM_ORGANISATION_ID ?? '',
  THREE_DS_REDIRECT_URL: config.THREE_DS_REDIRECT_URL ?? '',
  STRIPE_CARD_SCAN_PUBLISHABLE_KEY: config.STRIPE_CARD_SCAN_PUBLISHABLE_KEY ?? '',
  BOUNCER_SRC: config.BOUNCER_SRC ?? '',
  PLAID_URL: config.PLAID_URL ?? '',
  ZENDESK_SRC: config.ZENDESK_SRC ?? '',
  SHOP_URL: config.SHOP_URL ?? '',
  PORTAL_URL: config.PORTAL_URL ?? '',
}

export const DEBUG_MODE = publicRuntimeConfig.DEBUG_MODE

export const BASE_PATH = publicRuntimeConfig.BASE_PATH
