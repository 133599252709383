import React, { FunctionComponent, ReactNode, useContext } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Button, Heading, Modal, Text, Span } from '@afterpaytouch/core'
import { CloseAccountFlowContext } from '../CloseAccountFlowContext'
import { NewTabLink } from '../../NewTabLink'
import { TrackOnMount } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

interface HelpLinkProps {
  children?: ReactNode
}

const I18N_NAMESPACE = ['common', 'account']

const HelpLink: FunctionComponent<HelpLinkProps> = ({ children }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  return (
    <Span underline bold>
      <NewTabLink href={t('common:urls:helpUrl')}>{children}</NewTabLink>
    </Span>
  )
}

export const Error: FunctionComponent = () => {
  const { closeModal, closeAccountReason } = useContext(CloseAccountFlowContext)
  const { t } = useTranslation(I18N_NAMESPACE)
  const testNameSpace = 'close-account-error'

  const renderErrorMessage = (): ReactNode => {
    return (
      <>
        <Text>{t('account:closeAccount.closeAccount.error.description.0')}</Text>
        <br />
        <Text>{t('account:closeAccount.closeAccount.error.description.1')}</Text>
        <br />

        <Text>
          <Trans
            i18nKey={t('account:closeAccount.closeAccount.error.description.2')}
            components={{
              helpLink: <HelpLink />,
            }}
          />
        </Text>
      </>
    )
  }

  return (
    <>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading size='L' testNameSpace={testNameSpace}>
            {t('account:closeAccount.closeAccount.error.title')}
          </Heading>
        </div>
      </Modal.Header>
      <Modal.Content>{renderErrorMessage()}</Modal.Content>
      <Modal.Footer>
        <Button kind='Secondary' testNameSpace={testNameSpace} padding='Fluid' onClick={closeModal}>
          {t('common:close')}
        </Button>
      </Modal.Footer>
      <TrackOnMount eventName={TrackingEvent.VIEWED_CLOSE_MY_ACCOUNT_ERROR} eventProps={{ reason: closeAccountReason }} />
    </>
  )
}
