import { useEffect } from 'react'
import { setCookie } from 'nookies'
import { useIsCashTheme } from '@afterpaytouch/core'
import { isUndefinedOrEmpty } from '@afterpay/utils'
import { APT_USER_COUNTRY, APT_USER_ID } from '../../model/convergence'
import { useConsumerSelector } from '../account/account.hooks'

export const useDisplayIllustrations = (): boolean => {
  const isCashTheme = useIsCashTheme()
  return !isCashTheme
}

export const useSetPostLoginCookies = (): void => {
  const { uuid, countryCode } = useConsumerSelector() ?? {}

  useEffect(() => {
    if (!isUndefinedOrEmpty(uuid)) {
      setCookie(null, APT_USER_ID, uuid ?? '', {
        path: '/',
        sameSite: 'none',
        secure: true,
        maxAge: 31536000,
      })
    }

    if (!isUndefinedOrEmpty(countryCode)) {
      setCookie(null, APT_USER_COUNTRY, countryCode ?? '', {
        path: '/',
        sameSite: 'none',
        secure: true,
        maxAge: 31536000,
      })
    }
  }, [uuid, countryCode])
}
