export function memoize<T>(f: () => T): () => T {
  let result
  return () => {
    if (typeof result !== 'undefined') {
      return result
    }
    result = f()
    return result
  }
}
