import { Loader, Theme, ThemeProvider, TooltipProvider, useTheme } from '@afterpaytouch/core'
import { ThreatMatrix } from '@afterpaytouch/integrations/threat-matrix'
import { ErrorBoundary, Sentry, withSentryProfiler } from '../src/integrations/sentry'
import { getLaunchDarklyConfiguration, LaunchDarklyContext, withLDProvider } from '@afterpaytouch/feature'
import { Kasada } from '@afterpaytouch/integrations/kasada'
import { AppContextType, AppPropsType } from 'next/dist/shared/lib/utils'
import React, { ReactElement, useEffect } from 'react'
import { NextComponentType } from 'next'
import Head from 'next/head'
import type { AppInitialProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import { Router } from 'next/router'
import { Container as ModalContainer } from 'react-modal-promise'
import { ConnectedRouter } from 'connected-next-router'
import { api, app, DEBUG_MODE } from '../src/env'
import { AmplitudeProvider } from '../src/integrations/amplitude'
import { initialise, profilingIdSelector, setFeatureFlagState, store, useAppDispatch, useLazyGetAccountQuery, wrapper } from '../src/state'
import { MainLayout } from '../src/layouts/MainLayout'
import { SEON } from '../src/integrations/seon'
import { Bouncer } from '../src/integrations/bouncer'
import { getLDContext } from '../src/utils/object'
import { AuthenticatedGlobalFetchProvider, NewPortalRolloutCookieProvider } from '../src/component'
import { useHasLoadedOnce } from '../src/hooks/useHasLoadedOnce'
import { ZenDesk } from '../src/integrations/zendesk'
import { useIsUnauthenticatedRoute } from '../src/config/router'
import { ThemeWrapper } from '../src/component/ThemeWrapper/ThemeWrapper'
import { useSetPostLoginCookies } from '../src/state/convergence/hooks'
import { getFaviconHref } from '../server/favicon'

require('./global.css')

const ldConfig = getLaunchDarklyConfiguration({
  clientSideID: app.LAUNCH_DARKLY_CLIENT_SIDE_ID,
  options: {
    logger: {
      // We don't want LD console logs to appear as errors in sentry so set the logging severity to info for all messages.
      error: (message) => console.info(message),
      warn: (message) => console.info(message),
      info: () => {},
      debug: () => {},
    },
    sendEvents: !DEBUG_MODE,
  },
})

Sentry.init({
  dsn: app.SENTRY_DSN,
  environment: app.APP_ENVIRONMENT,
  version: app.APP_VERSION,
  ignoreHosts: ['api.amplitude.com', 'app.launchdarkly.com', 'events.launchdarkly.com'],
})

type Layout = (page: ReactElement) => ReactElement

type CustomAppComponent = NextComponentType & {
  getLayout?: Layout
}

interface CustomAppProps extends AppPropsType<Router, CustomPageProps> {
  Component: CustomAppComponent
  router: Router
}

interface CustomPageProps {
  theme: Theme
}

type ConsumerAppType = NextComponentType<AppContextType, AppInitialProps, CustomAppProps>

const ThemedFavicon = (): ReactElement => {
  const {
    theme: { selected },
  } = useTheme()

  return (
    <Head>
      <link rel='icon' type='image/x-icon' href={getFaviconHref(selected)} />
    </Head>
  )
}

const ConsumerApp: ConsumerAppType = ({ Component, pageProps }) => {
  const { getLayout: ComponentLayout } = Component
  const getLayout = ComponentLayout ?? ((page) => <MainLayout>{page}</MainLayout>)
  const [triggerAccount, { isSuccess: isAccountSuccess, isUninitialized: isAccountUninitialized }] = useLazyGetAccountQuery()
  const dispatch = useAppDispatch()
  const isUnauthenticatedRoute = useIsUnauthenticatedRoute()
  const isAccountLoadSkipped = isUnauthenticatedRoute
  const hasAccountLookupLoadedOnce = useHasLoadedOnce(isAccountLoadSkipped || (isAccountSuccess && !isAccountUninitialized))
  const { theme, ...remainingPageProps } = pageProps

  const ldContext = getLDContext(
    store.getState(),
    () => {
      store.dispatch(setFeatureFlagState(false))
    },
    () => {
      store.dispatch(setFeatureFlagState(true))
    }
  )

  useSetPostLoginCookies()

  useEffect(() => {
    if (!isUnauthenticatedRoute) {
      triggerAccount()
    }
    dispatch(initialise())
  }, [isUnauthenticatedRoute])

  return (
    <>
      <Kasada src={app.KASADA_SRC} urlList={[api.PORTAL_API_BASE_URL]} />
      <ThreatMatrix profilingSessionId={profilingIdSelector(store.getState())} tmOrganisationId={app.TM_ORGANISATION_ID} />
      <Bouncer />
      <ZenDesk />
      <ThemeProvider theme={theme}>
        <ThemedFavicon />
        {hasAccountLookupLoadedOnce ? (
          <ThemeWrapper>
            <ErrorBoundary dsn={app.SENTRY_DSN} fallbackError={<></>}>
              <ConnectedRouter>
                <AmplitudeProvider>
                  <LaunchDarklyContext {...ldContext}>
                    <TooltipProvider>
                      <SEON>
                        <NewPortalRolloutCookieProvider>
                          <AuthenticatedGlobalFetchProvider>
                            {getLayout(<Component {...remainingPageProps} />)}
                            <ModalContainer />
                          </AuthenticatedGlobalFetchProvider>
                        </NewPortalRolloutCookieProvider>
                      </SEON>
                    </TooltipProvider>
                  </LaunchDarklyContext>
                </AmplitudeProvider>
              </ConnectedRouter>
            </ErrorBoundary>
          </ThemeWrapper>
        ) : (
          <div className='flex h-screen w-full items-center'>
            <Loader alignCenter />
          </div>
        )}
      </ThemeProvider>
    </>
  )
}

// @ts-ignore: OPERATION BLEED STOPPER
const withTranslation: ConsumerAppType = appWithTranslation(ConsumerApp)
const withLaunchDarkly = withLDProvider(ldConfig)(withTranslation)
const withSentry: ConsumerAppType = withSentryProfiler(withLaunchDarkly, { name: 'ConsumerPortalApp' })
export default wrapper.withRedux(withSentry)
