import React, { FC, useCallback } from 'react'
import { Button, Heading, Icon, Modal, Text } from '@afterpaytouch/core'
import { useDisputeTranslation } from '../hooks'
import { useConsumerSizes } from '../../../utils/responsive'
import { DisputeCreationModalSteps, useDisputeCreationModalContext } from '../DisputeCreationModalContext'
import { TrackOnMount, useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

export const ContactMerchant: FC<{ reopenGetHelpModal: () => void }> = ({ reopenGetHelpModal }) => {
  const { textSize } = useConsumerSizes()
  const t = useDisputeTranslation()
  const { setStep, closeModal } = useDisputeCreationModalContext()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const confirmHandler = useCallback(() => {
    setStep(DisputeCreationModalSteps.ContactMerchantInfo)
    logEvent(TrackingEvent.PRESSED_CREATE_DISPUTE_CONTACT_MERCHANT)
  }, [logEvent, setStep])
  const cancelHandler = useCallback(() => {
    closeModal()
    logEvent(TrackingEvent.PRESSED_CREATE_DISPUTE_CANCEL_CONTACT_MERCHANT)
  }, [closeModal, logEvent])
  // Navigate back to the main help menu
  const backToHelpModal = (): void => {
    closeModal()
    reopenGetHelpModal()
  }

  const heading = t('contactMerchant:head')
  const subtitle = t('contactMerchant:subtitle')
  const confirm = t('contactMerchant:confirm')
  const cancel = t('contactMerchant:cancel')

  return (
    <>
      <TrackOnMount eventName={TrackingEvent.VIEWED_CREATE_DISPUTE_CONTACT_MERCHANT} />
      <Modal.Header divider={true}>
        <div className='absolute z-10 pt-1'>
          <Button.Link size='Small' onClick={backToHelpModal}>
            <Icon.CaretLeft size='Small' />
          </Button.Link>
        </div>
        <div className='text-center'>
          <Heading size='L'>{heading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <Text size={textSize} renderAs='span'>
            {subtitle}
          </Text>
        </div>
        <div className='mt-4'>
          <Button padding='Fluid' size='Large' onClick={confirmHandler}>
            {confirm}
          </Button>
        </div>
        <div className='mt-4'>
          <Button.Ghost padding='Fluid' size='Large' onClick={cancelHandler}>
            <div className='w-full'>{cancel} </div>
          </Button.Ghost>
        </div>
      </Modal.Content>
    </>
  )
}
