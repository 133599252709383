import { isUndefinedOrEmpty, reverse } from '@afterpay/utils/string'

interface NameValidation {
  name: string
  optional?: boolean
}

interface AddressValidation {
  input: string
  optional?: boolean
}

const VALID_CHARACTERS_REGEX = /^$|^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F- ']*$/
const INVALID_NAMES = ['registered', 'guest', 'user']

export const isValidName = ({ name, optional = false }: NameValidation): boolean => {
  if (isUndefinedOrEmpty(name)) {
    return optional
  }
  const isValidCharacters = VALID_CHARACTERS_REGEX.test(name)
  const isValidUser = !INVALID_NAMES.includes(name.toLowerCase())
  return isValidCharacters && isValidUser
}

export const isValidAddressComponent = ({ input, optional = false }: AddressValidation): boolean => {
  return isValidAddressInput(input, optional)
}

// PO box validation is not included as it has a different error message
export const isValidAddressInput = (input, optional = false): boolean => {
  if (optional && isUndefinedOrEmpty(input)) {
    return true
  }
  if (isUndefinedOrEmpty(input)) {
    return false
  }

  return true
}

export const isValidEmail = (email: string): boolean => {
  if (isUndefinedOrEmpty(email)) {
    return false
  }
  const emailRegex = /\S+@\S+\.\S+$/
  // Note: Safari does not support Lookbehind regex
  // Reversed email to check lookahead
  const reversedEmail = reverse(email)
  const startWithDotRegex = /^(?!\.)\S+/
  return emailRegex.test(email) && startWithDotRegex.test(reversedEmail) && !email.split('@')[0].includes('*')
}

export const valueIsNotNullOrUndefined = (value: any): boolean => {
  return value !== null && value !== undefined
}
