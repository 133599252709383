import { StoreLogo as CoreStoreLogo, StoreLogoBackground, StoreLogoSize, useThemeClass } from '@afterpaytouch/core'
import { OrderMerchant } from '@afterpaytouch/portal-api'
import { getMerchantName } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
interface StoreLogoProps {
  merchant: OrderMerchant
  background?: StoreLogoBackground
  size?: StoreLogoSize
}

// TODO: Move Skeleton state here and add isLoading prop
// TODO: Refactor to common component and reuse in OrderDetails
export const StoreLogo: FunctionComponent<StoreLogoProps> = ({ merchant, background, size }): JSX.Element => {
  const themeClass = useThemeClass()
  return (
    // @ts-ignore: OPERATION BLEED STOPPER
    <div className={clsx(styles.merchantTile, styles[themeClass], 'bg-dark-prominent')}>
      <CoreStoreLogo alt={getMerchantName(merchant)} url={merchant?.merchantLogoUrl} background={background} size={size} />
    </div>
  )
}
