import React, { FunctionComponent } from 'react'
import { Heading, Icon, Panel, Text, useIsBreakpointSmall } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import styles from './style.module.scss'
import { useConsumerSizes } from '../../utils/responsive'
import clsx from 'clsx'

const I18N_NAMESPACE = ['common', 'orders']

export const NoOrders: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isBpSmall = useIsBreakpointSmall()
  const classNames = clsx(isBpSmall && styles.isBpSmall)
  const { headingSize, textSize, iconSize } = useConsumerSizes()

  return (
    <div className={classNames}>
      <Panel>
        <Panel.Head>
          {isBpSmall && (
            <div className={styles.title}>
              <Text size={'M'}>{t('orders:orders')}</Text>
            </div>
          )}
          <div className={styles.header}>
            <div className={styles.icon}>
              <Icon.Check size={iconSize} title={'check icon'} />
            </div>
            <Heading size={headingSize}>{t('orders:noOrders:header')}</Heading>
          </div>
        </Panel.Head>
        <Panel.Content bordered={true}>
          <div className='py-3 md:py-2'>
            <Text size={textSize}>{t('orders:noOrders:contentSubheader')}</Text>
          </div>
        </Panel.Content>
      </Panel>
    </div>
  )
}
