import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { CardScanCheckpoint, CardScanStatus, ScanIntent } from '../../model'

export interface CardScanState {
  cardNumber?: string
  expiryDate?: string
  traceId?: string
  checkpoint?: CardScanCheckpoint
  status?: CardScanStatus
  cardScanRequired?: boolean
}

// Avoid non-serializable value in the state
let scanIntent: ScanIntent | null = null
export const setScanIntent = (intent: ScanIntent): ScanIntent => {
  scanIntent = intent
  return intent
}
export const getScanIntent = (): any | null => {
  return scanIntent
}

// @ts-ignore: OPERATION BLEED STOPPER
let successCallback: () => any | null = null
// @ts-ignore: OPERATION BLEED STOPPER
export const setCardScanSuccessCallback = (callback: () => any | null = null): void => {
  successCallback = callback
}
export const getCardScanSuccessCallback = (): (() => any | null) => successCallback

export const cardScanInitialState: CardScanState = {
  cardNumber: '',
  expiryDate: '',
  traceId: '',
  checkpoint: CardScanCheckpoint.AddPaymentWeb,
  status: CardScanStatus.Initial,
  cardScanRequired: false,
}

const slice: Slice = createSlice({
  name: 'cardScan',
  initialState: cardScanInitialState,
  reducers: {
    setCardScanCard(state: CardScanState, action: PayloadAction<{ cardNumber: string; expiryDate: string }>) {
      state.cardNumber = action.payload.cardNumber
      state.expiryDate = action.payload.expiryDate
    },
    setCardScanCheckpoint(state: CardScanState, action: PayloadAction<CardScanCheckpoint>) {
      state.checkpoint = action.payload
    },
    setCardScanTraceId(state: CardScanState, action: PayloadAction<string>) {
      state.traceId = action.payload
    },
    setCardScanStatus(state: CardScanState, action: PayloadAction<CardScanStatus>) {
      state.status = action.payload
    },
    setCardScanRequired(state: CardScanState, action: PayloadAction<boolean>) {
      state.cardScanRequired = action.payload
    },
  },
})

export const { setCardScanCard, setCardScanTraceId, setCardScanCheckpoint, setCardScanStatus, setCardScanRequired } = slice.actions

const { reducer } = slice
export const cardScanReducer = reducer
