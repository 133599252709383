import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { Grid, Heading, Radio, Text, Input, useDate } from '@afterpaytouch/core'
import { Money as MoneyType, OrderPaymentSchedule, SupportedLocale } from '@afterpaytouch/portal-api'
import { Money } from '../../../Money'
import { useRouter } from 'next/router'
import { PayNowInput, PayNowInputProps, PayNowModalContext } from '../../PayNowModalContext'
import { useTranslation, TFunction, Trans } from 'next-i18next'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { isCustomAmountPayment } from '../../utils'
import { isOwedFeeOwing } from '../utils'
import { isScheduleDueToday } from '../../../../state/orders/utils'
import { PaymentArrangementSchedule } from '@afterpaytouch/portal-api/consumer/hardship/types'
import { isHardshipScheduleDueToday, isHardshipScheduleOverdue, isPaymentArrangementSchedule, useGetTotalPayableHardshipRepayments } from '../../../../state'

const I18N_NAMESPACE = ['payments']

interface LateFeesProps {
  isNextInstallmentOverdue: boolean
  nextInstallmentFees: MoneyType
  totalOwedFees: MoneyType
}

interface ScheduleProps {
  nextInstallment: OrderPaymentSchedule | PaymentArrangementSchedule
  remainingInstallments: MoneyType
  isLastInstallment: boolean
  totalInstallmentCount: number
  lateFeesInformation: LateFeesProps
}

interface InstallmentLabelBaseProps {
  heading: string
  text: string
  displayAmount: boolean
  amount?: MoneyType
  testNameSpace: string
  isDueToday?: boolean
  isOverdue?: boolean
  isOwedFee?: boolean
  owedFeeAmount?: MoneyType
  isNextPayment?: boolean
  t?: TFunction
}

interface InstallmentItemsProps {
  schedules: ScheduleProps
  customAmount: {
    setCustomAmount: Dispatch<SetStateAction<string>>
    isCustomInputActive: boolean
    currencySymbol: string
    belowMaxAmount: boolean
  }
  handleChange: (event: PayNowInputProps) => void
}

interface InstallmentProps {
  schedules: ScheduleProps
  isPaymentArrangement: boolean
  t: TFunction
}

interface CustomInstallmentProps {
  displayCustomAmountInput: boolean
  currencySymbol: string
  handleChange: (_: any, value: string) => void
  belowMaxAmount: boolean
  customAmountState: string
  t: TFunction
}

export const InstallmentItems: FunctionComponent<InstallmentItemsProps> = ({ schedules, handleChange, customAmount }) => {
  const { payment, payNowStep } = useContext(PayNowModalContext)
  const [customAmountState, setCustomAmountState] = useState<string>(
    payNowStep !== PayNowInput.REMAINING && isCustomAmountPayment(payment) ? payment?.amount?.amount : ''
  )
  const { t } = useTranslation(I18N_NAMESPACE)
  const handleCustomChange = (_, value: string): void => {
    !isNaN(parseInt(value)) ? customAmount.setCustomAmount(value) : customAmount.setCustomAmount('0')
  }

  const isPaymentArrangement = isPaymentArrangementSchedule(schedules.nextInstallment)

  useEffect(() => {
    if (customAmountState !== '') {
      handleCustomChange(null, customAmountState)
    }
  }, [])

  return (
    <>
      <div className='mb-4 mt-6'>
        <Radio.Group id='paynow-installments-radio-list'>
          {!schedules.isLastInstallment && (
            <Radio.Button
              labelWidth='Full'
              labelPlacement='Top'
              kind='Option'
              value={PayNowInput.NEXT}
              name='paynow-selection-list'
              testNameSpace='paynow-next'
              label={() => <NextInstallment schedules={schedules} t={t} isPaymentArrangement={isPaymentArrangement} />}
              onClick={(e) => {
                handleChange((e.target as HTMLButtonElement).value as PayNowInputProps)
                setCustomAmountState('')
              }}
              defaultChecked={payNowStep && payNowStep === PayNowInput.NEXT}
            />
          )}
          <Radio.Button
            labelWidth='Full'
            labelPlacement='Top'
            kind='Option'
            value={PayNowInput.REMAINING}
            name='paynow-selection-list'
            testNameSpace='paynow-remaining'
            label={() => <RemainingInstallments schedules={schedules} t={t} isPaymentArrangement={isPaymentArrangement} />}
            onClick={(e) => {
              handleChange((e.target as HTMLButtonElement).value as PayNowInputProps)
              setCustomAmountState('')
            }}
            defaultChecked={payNowStep && payNowStep === PayNowInput.REMAINING}
          />
          <Radio.Button
            labelWidth='Full'
            labelPlacement={'Top'}
            kind='Option'
            value={PayNowInput.CUSTOM}
            name='paynow-selection-list'
            testNameSpace='paynow-custom'
            label={() => (
              <CustomInstallment
                displayCustomAmountInput={customAmount.isCustomInputActive}
                handleChange={handleCustomChange}
                currencySymbol={customAmount.currencySymbol}
                belowMaxAmount={customAmount.belowMaxAmount}
                customAmountState={customAmountState}
                t={t}
              />
            )}
            onClick={(e) => {
              handleChange((e.target as HTMLButtonElement).value as PayNowInputProps)
            }}
            defaultChecked={payNowStep && payNowStep === PayNowInput.CUSTOM}
          />
        </Radio.Group>
      </div>
    </>
  )
}

const InstallmentLabelBase: FunctionComponent<InstallmentLabelBaseProps> = ({
  heading,
  text,
  displayAmount,
  amount,
  testNameSpace,
  isOverdue = false,
  isOwedFee = false,
  owedFeeAmount = null,
  isDueToday = false,
  isNextPayment = false,
  t = () => {},
}) => {
  return (
    <div data-testid={testNameSpace}>
      <Grid center>
        <Grid.Item sm={6} md={8}>
          <Heading renderAs='span' size='M' bold testNameSpace={testNameSpace}>
            {heading}
          </Heading>
          <Text renderAs='span' size='S' color={isNextPayment && isOverdue ? 'Fire' : 'Gray40'} testNameSpace={testNameSpace}>
            {text}
          </Text>
        </Grid.Item>
        {displayAmount && (
          <Grid.Item>
            <div className='mr-2 text-right'>
              {/* @ts-ignore: OPERATION BLEED STOPPER */}
              <Money size='M' value={amount} testNameSpace={testNameSpace} />
              {isOverdue && (
                <Text color='Fire' testNameSpace='installment-payment-overdue'>
                  {t<string>('payments:customPayment:installmentsv2:labelBase:overdue')}
                </Text>
              )}
              {isDueToday && (
                <Text color='Gray40' testNameSpace='installment-payment-due-today'>
                  {t<string>('payments:customPayment:installmentsv2:labelBase:today')}
                </Text>
              )}
            </div>
          </Grid.Item>
        )}
      </Grid>
      {isOwedFee && (
        <div className='mr-2 text-right'>
          <Text testNameSpace={`owed-fee-${testNameSpace}`}>
            <Trans
              i18nKey='payments:customPayment:installmentsv2:labelBase:incLateFee'
              components={{
                // @ts-ignore: OPERATION BLEED STOPPER
                lateFeeAmount: <Money size='S' value={owedFeeAmount} testNameSpace={`owed-fee-${testNameSpace}`} />,
              }}
            />
          </Text>
        </div>
      )}
    </div>
  )
}

export const NextInstallment: FunctionComponent<InstallmentProps> = ({ schedules, t, isPaymentArrangement }) => {
  const router = useRouter()
  const { formatDate, formatUnixDate } = useDate({
    locale: router?.locale as SupportedLocale,
    year: 'numeric',
  })
  const totalNumberOfHardshipPayments = useGetTotalPayableHardshipRepayments()

  if (isPaymentArrangement) {
    const { nextInstallment } = schedules ?? {}
    const { overdueDatetime, outstandingBalance } = (nextInstallment as PaymentArrangementSchedule) ?? {}
    const dueDate = formatUnixDate(overdueDatetime)

    return InstallmentLabelBase({
      heading: t('payments:customPayment:installmentsv2:base:headings:next'),
      text: `1 ${t('payments:customPayment:installmentsv2:base:subheading:next')} ${totalNumberOfHardshipPayments} • ${dueDate}`,
      displayAmount: true,
      amount: outstandingBalance,
      testNameSpace: 'next-payment-option-hardship',
      isOverdue: isHardshipScheduleOverdue(schedules.nextInstallment as PaymentArrangementSchedule),
      isDueToday: isHardshipScheduleDueToday(dueDate),
      isNextPayment: true,
      t,
    })
  } else {
    const { nextInstallment, totalInstallmentCount, lateFeesInformation, isLastInstallment } = schedules ?? {}
    const { installmentDueDate, amountPayable, installmentSequence } = (nextInstallment as OrderPaymentSchedule) ?? {}
    const { isNextInstallmentOverdue, nextInstallmentFees } = lateFeesInformation ?? {}
    const formattedInstallmentDate = formatDate(installmentDueDate)

    return InstallmentLabelBase({
      heading: isLastInstallment
        ? t('payments:customPayment:installmentsv2:base:headings:total')
        : t('payments:customPayment:installmentsv2:base:headings:next'),
      text: `${installmentSequence} ${t('payments:customPayment:installmentsv2:base:subheading:next')} ${totalInstallmentCount} • ${formattedInstallmentDate}`,
      displayAmount: true,
      amount: amountPayable,
      testNameSpace: 'next-payment-option',
      isOverdue: isNextInstallmentOverdue,
      isOwedFee: isOwedFeeOwing(nextInstallmentFees),
      owedFeeAmount: nextInstallmentFees,
      isDueToday: isScheduleDueToday(nextInstallment as OrderPaymentSchedule),
      isNextPayment: true,
      t,
    })
  }
}

export const RemainingInstallments: FunctionComponent<InstallmentProps> = ({ schedules, t, isPaymentArrangement }) => {
  const router = useRouter()
  const { remainingInstallments, lateFeesInformation, nextInstallment } = schedules ?? {}
  const { isNextInstallmentOverdue, totalOwedFees } = lateFeesInformation ?? {}
  const { formatUnixDate } = useDate({
    locale: router?.locale as SupportedLocale,
    year: 'numeric',
  })
  const isInstallmentDueToday = useCallback((): boolean => {
    if (isPaymentArrangement) {
      const { overdueDatetime } = (nextInstallment as PaymentArrangementSchedule) ?? {}
      return isHardshipScheduleDueToday(formatUnixDate(overdueDatetime))
    } else {
      return isScheduleDueToday(nextInstallment as OrderPaymentSchedule)
    }
  }, [isPaymentArrangement, nextInstallment, formatUnixDate])

  return InstallmentLabelBase({
    heading: t('payments:customPayment:installmentsv2:base:headings:total'),
    text: isPaymentArrangement
      ? t('payments:customPayment:installmentsv2:base:subheadings:totalHardship')
      : t('payments:customPayment:installmentsv2:base:subheadings:total'),
    displayAmount: true,
    amount: remainingInstallments,
    testNameSpace: 'remaining-payment-option',
    isOverdue: isNextInstallmentOverdue,
    owedFeeAmount: totalOwedFees,
    isOwedFee: isOwedFeeOwing(totalOwedFees),
    isDueToday: schedules.isLastInstallment && isInstallmentDueToday(),
    t,
  })
}

export const CustomInstallment: FunctionComponent<CustomInstallmentProps> = ({
  displayCustomAmountInput,
  handleChange,
  currencySymbol,
  belowMaxAmount,
  customAmountState,
  t,
}) => {
  return (
    <>
      {InstallmentLabelBase({
        heading: t('payments:customPayment:installmentsv2:base:headings:custom'),
        text: t('payments:customPayment:installmentsv2:base:subheadings:custom'),
        displayAmount: false,
        testNameSpace: 'custom-payment-option',
      })}
      <div className={clsx(displayCustomAmountInput ? styles.customInputDiv : styles.customInputDivClose)}>
        {displayCustomAmountInput && (
          <Grid>
            <Grid.Item>
              <Input.Tel
                label={t('payments:customPayment:installmentsv2:base:subheadings:custom:input')}
                format={`${currencySymbol ?? '$'}[000{0.00}]`}
                fullWidth
                onChange={handleChange}
                error={!belowMaxAmount}
                autoFocus={displayCustomAmountInput}
                value={customAmountState}
                testNameSpace='paynow-custom-amount'
                inputMode='decimal'
              />
            </Grid.Item>
          </Grid>
        )}
      </div>
    </>
  )
}
