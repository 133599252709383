import React, { FunctionComponent, useCallback, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Modal } from '@afterpaytouch/core'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { AchFlowModalContext } from '../AchFlowModalContext'
import styles from './style.module.css'
import { app } from '../../../env'
import { Route } from '../../../config/router'
import { Error } from './Error'
import { handleErrorStatus } from './utils'
import { AmplitudeEventTypesProperties } from '../../../integrations/amplitude/types'
import { SessionStorageUpdateActionType, SessionStorageHelper } from '../../../utils/sessionStorageHelper'
import { OAUTH_STATE_ID } from '../../../config/plaid'

interface AmplitudeEvents {
  onError?: keyof AmplitudeEventTypesProperties
  onSuccess?: keyof AmplitudeEventTypesProperties
  onOauthError?: keyof AmplitudeEventTypesProperties
  onOauthSuccess?: keyof AmplitudeEventTypesProperties
}

interface BasePlaidProps {
  useCreateToken: any // type
  onHandlePlaidFlowFinished: (data: any) => void
  amplitudeEvents: AmplitudeEvents
  bankAccountId?: number
}

export const PAYMENT_DATA = 'payment-data'
export const PLAID_LINK_TOKEN_COOKIE = 'plaid-link-token'
export const ACH_FLOW_ENTRY_DEEP_LINK = 'from-deep-link'
export const ACH_FLOW_BANK_ACCOUNT_TO_BE_RELINKED_ID = 'bank-account-to-be-relinked-id'
export const ACH_OAUTH_FLOW = 'ach-oauth-flow'
export const achOauthFlowSessionStorageInstance = SessionStorageHelper.getInstance(ACH_OAUTH_FLOW)

export const BasePlaid: FunctionComponent<BasePlaidProps> = ({ useCreateToken, onHandlePlaidFlowFinished, amplitudeEvents, bankAccountId }) => {
  const { locale } = useRouter()
  const amplitude = useAmplitudeWithEnduringEventProperties()
  const {
    setResult,
    setShowCloseButton,
    setIsLoading,
    result: { error },
    achFlowEntryDeepLink,
    bankAccountToBeRelinked,
  } = useContext(AchFlowModalContext)

  const [createToken, { isError, data }] = useCreateToken()
  const oauthLinkToken = achOauthFlowSessionStorageInstance.getSessionStorageByItemKey(PLAID_LINK_TOKEN_COOKIE)
  const oauthStateId = achOauthFlowSessionStorageInstance.getSessionStorageByItemKey(OAUTH_STATE_ID)
  const haveActiveOauthSession = oauthLinkToken && oauthStateId
  const logErrorEvent = useCallback((): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    haveActiveOauthSession ? amplitude.logEvent(amplitudeEvents?.onOauthError, error) : amplitude.logEvent(amplitudeEvents?.onError, error)
  }, [haveActiveOauthSession])

  const setErrorStatus = handleErrorStatus({ setResult, setIsLoading, setShowCloseButton })

  const receiveMessage = (event): void => {
    if (event.origin !== window.location.origin || event?.data?.source === 'react-devtools-bridge') {
      return
    }
    try {
      const data = JSON.parse(event.data)
      if (data.isPlaidFlowFinished) {
        setShowCloseButton(true)
        onHandlePlaidFlowFinished(data)
        // Remove the event listener after user complete
        logErrorEvent()
        window.removeEventListener('message', receiveMessage, false)
      }

      if (data.error) {
        // @ts-ignore: OPERATION BLEED STOPPER
        haveActiveOauthSession ? amplitude.logEvent(amplitudeEvents?.onOauthError, error) : amplitude.logEvent(amplitudeEvents?.onError, error)
        setShowCloseButton(true)
        setResult({ success: false, error: true })
      }
    } catch (error) {
      // Expected JSON parse error
      if (!(error instanceof SyntaxError)) {
        logErrorEvent()
      }
    }
  }

  useEffect(() => {
    if (!haveActiveOauthSession) {
      createToken({
        redirectUri: `${window.location.origin}/${locale}${Route.PLAID_FINISHED}`,
        ...(bankAccountId != null && { achBankAccountId: bankAccountId }),
      })
      // Set ACH_FLOW_ENTRY_DEEP_LINK  in local storage for tracking the entry point of kicking off ach flow so it can be redirected to the ACH_FLOW_ENTRY_DEEP_LINK when oauth on bank side is finished
      achOauthFlowSessionStorageInstance.updateSessionStorage(SessionStorageUpdateActionType.EXTEND, undefined, {
        [ACH_FLOW_ENTRY_DEEP_LINK]: achFlowEntryDeepLink,
        [ACH_FLOW_BANK_ACCOUNT_TO_BE_RELINKED_ID]: bankAccountId,
      })
      // Hide the close the button once the consumer start the plaid flow.
      setShowCloseButton(false)
      setIsLoading(true)
    }
    // Add the event listener
    window.addEventListener('message', receiveMessage, false)
  }, [])

  useEffect(() => {
    if (data?.plaidLinkToken && !haveActiveOauthSession) {
      achOauthFlowSessionStorageInstance.updateSessionStorage(SessionStorageUpdateActionType.EXTEND, undefined, {
        [PLAID_LINK_TOKEN_COOKIE]: data.plaidLinkToken,
      })
    }
  }, [data, oauthStateId])

  useEffect(() => {
    if (isError) {
      setShowCloseButton(true)
      setErrorStatus()
      logErrorEvent()
    }
  }, [isError])

  if (error) {
    return <Error />
  }

  return (
    <Modal.Content>
      <div className={styles.iframeWrapper}>
        {typeof data?.plaidLinkToken !== 'undefined' || haveActiveOauthSession ? (
          <iframe
            onLoad={() => setIsLoading(false)}
            src={
              haveActiveOauthSession
                ? `${app.PLAID_URL}?useRedirectUri=true&token=${oauthLinkToken}&receivedRedirectUri=${encodeURIComponent(
                    `${window.location.origin}/${locale}${Route.PLAID_FINISHED}?oauth_state_id=${oauthStateId}`
                  )}`
                : `${app.PLAID_URL}?useRedirectUri=true&token=${data.plaidLinkToken}`
            }
            className={styles.iframe}
          />
        ) : (
          <Modal.Loader></Modal.Loader>
        )}
      </div>
    </Modal.Content>
  )
}
