import { useEffect } from 'react'
import { createModalHook, useFlag } from '../../hooks'
import { useConsumerCountry } from '../../state'
import { getLocaleFromHref } from '../../utils/url'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { getSessionStorageItem, setSessionStorageItem } from '@afterpay/utils/storage'

export const useTerminationNoticeModal = createModalHook()

export type OpenTerminationNoticeModal = ReturnType<typeof useTerminationNoticeModal>[1]['openModal']

const DEFAULT_TERMINATION_NOTICE_MODAL_LIST_VALUE = {
  countries: [],
  locales: [],
}

const TERMINATION_NOTICE_MODAL_TAG_NAME = 'portal.consumer-has-seen-termination-notice-modal'
const INTERVAL = 60 * 60 * 2 * 1000 // 2 hours

export const useShowTerminationNoticeModalOnMount = (openTerminationNoticeModal: OpenTerminationNoticeModal): void => {
  const { countries: enabledCountries, locales: supportedLocales } = useFlag(
    'consumer-portal-termination-notice-modal-country-list',
    DEFAULT_TERMINATION_NOTICE_MODAL_LIST_VALUE
  )

  const customerCountry = useConsumerCountry()
  const currentLocale = getLocaleFromHref(window.location.href) as SupportedLocale

  const isTerminationNoticeEnabled =
    customerCountry !== undefined && currentLocale !== undefined && enabledCountries.includes(customerCountry) && supportedLocales.includes(currentLocale)

  useEffect(() => {
    const currentTimestamp = new Date().getTime()
    const lastModalVisitTime = getSessionStorageItem<number>(TERMINATION_NOTICE_MODAL_TAG_NAME) ?? 0

    // If the user has seen the modal in the last 2 hours, don't show it again
    const isGreaterThanInterval = currentTimestamp - lastModalVisitTime > INTERVAL

    if (isTerminationNoticeEnabled && isGreaterThanInterval) {
      openTerminationNoticeModal()
      setSessionStorageItem(TERMINATION_NOTICE_MODAL_TAG_NAME, currentTimestamp)
    }
  }, [isTerminationNoticeEnabled])
}
