import { amountDueSummaryService } from '@afterpaytouch/portal-api/consumer/amountDueSummary'
import { AmountDueSummaryResponse } from '@afterpaytouch/portal-api/consumer/amountDueSummary/types'
import { createSelector } from '@reduxjs/toolkit'
import { ConsumerAppSelector, useAppSelector } from '..'

export const {
  useGetAmountDueSummaryQuery,
  useLazyGetAmountDueSummaryQuery,
  endpoints: { getAmountDueSummary },
} = amountDueSummaryService

// TODO: Convert selectors to useQueryState method (see account.hooks.ts)?
// TODO: Add tests
export const amountDueSummarySelector = getAmountDueSummary.select()

export const amountDueSummaryDataSelector: ConsumerAppSelector<AmountDueSummaryResponse | undefined> = createSelector(
  amountDueSummarySelector,
  (state) => state?.data
)

export const amountDueSummaryPaymentPeriodsSelector: ConsumerAppSelector<AmountDueSummaryResponse['paymentPeriods'] | undefined> = createSelector(
  amountDueSummaryDataSelector,
  (data) => data?.paymentPeriods
)

export const amountDueSummaryTotalNonPclOrdersSelector: ConsumerAppSelector<AmountDueSummaryResponse['totalNonPclOrders'] | undefined> = createSelector(
  amountDueSummaryDataSelector,
  (data) => data?.totalNonPclOrders
)

export const amountDueSummaryTotalPclOrdersSelector: ConsumerAppSelector<AmountDueSummaryResponse['totalPclOrders'] | undefined> = createSelector(
  amountDueSummaryDataSelector,
  (data) => data?.totalPclOrders
)

export const useAmountDueSummaryPaymentPeriodsSelector = (): AmountDueSummaryResponse['paymentPeriods'] | undefined =>
  useAppSelector(amountDueSummaryPaymentPeriodsSelector)

export const useAmountDueSummaryTotalNonPclOrdersSelector = (): AmountDueSummaryResponse['totalNonPclOrders'] | undefined =>
  useAppSelector(amountDueSummaryTotalNonPclOrdersSelector)

export const useAmountDueSummaryTotalPclOrdersSelector = (): AmountDueSummaryResponse['totalPclOrders'] | undefined =>
  useAppSelector(amountDueSummaryTotalPclOrdersSelector)

export const useConsumerHasNonPclOrders = (): boolean => (useAmountDueSummaryTotalNonPclOrdersSelector() ?? 0) > 0

export const useConsumerHasPclOrders = (): boolean => (useAmountDueSummaryTotalPclOrdersSelector() ?? 0) > 0

export const useConsumerHasBothPclAndNonPclOrders = (): boolean => {
  const hasPclOrders = useConsumerHasPclOrders()
  const hasNonPclOrders = useConsumerHasNonPclOrders()

  return hasPclOrders && hasNonPclOrders
}
