import NextHead from 'next/head'
import React, { FunctionComponent } from 'react'

export interface HeadProps {
  title: string
}

export const Head: FunctionComponent<HeadProps> = ({ title }) => {
  return (
    <NextHead>
      <title>{title}</title>
      <meta property='og:title' content={title} />
      <link rel='shortcut icon' href='/favicon.ico' />
      <meta name='viewport' content='initial-scale=1.0, width=device-width, maximum-scale=1.0, user-scalable=no' key='viewport' />
    </NextHead>
  )
}
