import { Heading, Modal, Text } from '@afterpaytouch/core'
import { Money as IMoney } from '@afterpaytouch/portal-api'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'
import { useConsumerSizes } from '../../../../utils/responsive'
import { Money } from '../../../Money'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'

const I18N_NAMESPACE = ['common', 'payMonthly']

export interface PayMonthlyLimitBreakdownModalProps {
  isModalOpen: boolean
  closeModal: () => void
  consumer: {
    creditFacilityLimit?: IMoney
    pclTotalOwedPrincipal?: IMoney
    availableToSpendPCL?: IMoney
  }
}

export const PayMonthlyLimitBreakdownModal: FunctionComponent<PayMonthlyLimitBreakdownModalProps> = ({ isModalOpen, closeModal, consumer }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { sectionHeadingSize, textSize, smallTextSize } = useConsumerSizes()

  return (
    <Modal
      show={isModalOpen}
      onClose={closeModal}
      onHide={closeModal}
      onBackdropClick={closeModal}
      onEscapeKeyDown={closeModal}
      testNameSpace='pay-monthly-limit-breakdown'
    >
      <Modal.Header>
        <div className='flex justify-center'>
          <div className='max-w-xs text-center'>
            <Heading size={sectionHeadingSize} testNameSpace='pay-monthly-limit-breakdown-modal'>
              {t('payMonthly:limitBreakdownModal:heading')}
            </Heading>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <MaskedElement>
          <div className='flex flex-col items-center'>
            <div className='mb-2 flex w-full max-w-xs justify-between'>
              <Text size={textSize} testNameSpace='pay-monthly-limit-breakdown-modal-limit'>
                {t('payMonthly:limitBreakdownModal:label:creditFacilityLimit')}
              </Text>
              <Money
                value={consumer.creditFacilityLimit ?? { amount: '0.00', currency: 'AUD' }}
                bold
                size={textSize}
                testNameSpace='pay-monthly-limit-breakdown-modal-limit'
              />
            </div>
            <div className='mb-2 flex w-full max-w-xs justify-between'>
              <Text size={textSize} testNameSpace='pay-monthly-limit-breakdown-modal-owed-principal'>
                {t('payMonthly:limitBreakdownModal:label:pclTotalOwedPrincipal')}
              </Text>
              <Money
                value={consumer.pclTotalOwedPrincipal ?? { amount: '0.00', currency: 'AUD' }}
                bold
                size={textSize}
                testNameSpace='pay-monthly-limit-breakdown-modal-owed-principal'
              />
            </div>
            <div className='flex w-full max-w-xs justify-between'>
              <Text size={textSize} testNameSpace='pay-monthly-limit-breakdown-modal-available-to-spend'>
                {t('payMonthly:limitBreakdownModal:label:availableToSpendPCL')}
              </Text>
              <Money
                value={consumer.availableToSpendPCL ?? { amount: '0.00', currency: 'AUD' }}
                bold
                size={textSize}
                testNameSpace='pay-monthly-limit-breakdown-modal-available-to-spend'
              />
            </div>
            <div className='flex w-full max-w-xs justify-start'>
              <Text size={smallTextSize}>{t('payMonthly:limitBreakdownModal:label:interestDisclaimer')}</Text>
            </div>
          </div>
        </MaskedElement>
      </Modal.Content>
    </Modal>
  )
}
