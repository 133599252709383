import React, { createContext, Dispatch, FunctionComponent, ReactNode, SetStateAction, useState } from 'react'

export const noop = (): void => {}

export const ReactivateAccountFlowSteps = {
  Info: 'Info',
  ConfirmInfo: 'ConfirmInfo',
  EditAddress: 'EditAddress',
} as const

type ReactivateAccountFlowStepsType = typeof ReactivateAccountFlowSteps
type ReactivateAccountStepsKeys = keyof ReactivateAccountFlowStepsType
export type ReactivateAccountStepsProps = ReactivateAccountFlowStepsType[ReactivateAccountStepsKeys]

export interface ReactivateAccountFlowContextProps {
  show: boolean
  step: ReactivateAccountStepsProps
  setStep: Dispatch<SetStateAction<ReactivateAccountStepsProps>>
  isFlowSuccess: boolean
  setIsFlowSuccess: Dispatch<SetStateAction<boolean>>
  closeModal: () => void
  resetState: () => void
}

const defaultStep = ReactivateAccountFlowSteps.Info
export const ReactivateAccountFlowContextDefaults: ReactivateAccountFlowContextProps = {
  show: false,
  step: defaultStep,
  setStep: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isFlowSuccess: null,
  setIsFlowSuccess: noop,
  closeModal: noop,
  resetState: noop,
}

export const ReactivateAccountFlowContext = createContext<ReactivateAccountFlowContextProps>(ReactivateAccountFlowContextDefaults)

interface ReactivateAccountFlowProviderProps {
  show?: boolean
  closeModal: () => void
  children: ReactNode
}

export const ReactivateAccountFlowModalProvider: FunctionComponent<ReactivateAccountFlowProviderProps> = ({ show = false, closeModal, children }) => {
  const [step, setStep] = useState<ReactivateAccountStepsProps>(defaultStep)
  const [isFlowSuccess, setIsFlowSuccess] = useState<boolean>(false)

  const resetState = (): void => {
    setStep(defaultStep)
    setIsFlowSuccess(false)
  }
  const value = {
    show,
    step,
    setStep,
    isFlowSuccess,
    setIsFlowSuccess,
    closeModal,
    resetState,
  }

  return <ReactivateAccountFlowContext.Provider value={value}>{children}</ReactivateAccountFlowContext.Provider>
}
