import { ColorTypes, Icon, IconProps, Skeleton, Text, useIsBreakpointMediumAndUp } from '@afterpaytouch/core'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent, ReactElement } from 'react'

const I18N_NAMESPACE = ['common', 'index']

export const lowerCaseFirstChar = (str: string): string => {
  return str[0].toLowerCase() + str.slice(1)
}

export interface StatusMessageProps {
  iconName: IconProps['name']
  textChild: string | ReactElement
  color?: ColorTypes
  isLoading?: boolean
}

export const StatusMessage: FunctionComponent<StatusMessageProps> = ({ iconName, textChild, color, isLoading }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isBpMediumUp = useIsBreakpointMediumAndUp()
  const textSize = isBpMediumUp ? 'M' : 'S'

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
  const textContainerClassNames = clsx(isLoading === true ? 'invisible' : 'visible')

  return (
    <div className='relative'>
      {(isLoading ?? false) && <Skeleton testNameSpace='status-message-skeleton' />}
      <div className={textContainerClassNames}>
        <Text renderAs='span' size={textSize} color={color}>
          <span className='mr-3'>
            <Icon
              name={iconName}
              size='Small'
              color={color}
              // @ts-ignore: OPERATION BLEED STOPPER
              title={t(`index:welcome:existingUser:htmlAttribute:icon:${lowerCaseFirstChar(iconName)}:title`)}
              // @ts-ignore: OPERATION BLEED STOPPER
              description={t(`index:welcome:existingUser:htmlAttribute:icon:${lowerCaseFirstChar(iconName)}:description`)}
            />
          </span>
          {textChild}
        </Text>
      </div>
    </div>
  )
}
