import React, { FunctionComponent } from 'react'

import { Heading, Text, Button, Icon } from '@afterpaytouch/core'
import styles from './style.module.scss'
import clsx from 'clsx'

export interface StepCtaProps {
  label: string
  onClick: () => void
}

interface StepProps {
  badge: string
  task: string
  subtask: string
  completed: boolean
  lastStepCompleted?: boolean
  cta: StepCtaProps
  testNameSpace?: string
}

export const Step: FunctionComponent<StepProps> = ({
  badge,
  task,
  subtask,
  completed,
  lastStepCompleted = true,
  cta: { label, onClick },
  testNameSpace,
}): JSX.Element => {
  const primaryBadgeClassName = clsx(styles.badge, 'bg-primary')
  const darkBadgeClassName = clsx(styles.badge, 'bg-dark')
  return (
    <div className={styles.step}>
      {completed ? (
        <div className={primaryBadgeClassName}>
          <Icon.Check size='ExtraSmall' />
        </div>
      ) : (
        <div className={darkBadgeClassName}>
          <Text bold renderAs='span'>
            {badge}
          </Text>
        </div>
      )}
      <div className={styles.task}>
        <div>
          <Heading renderAs='h3' size='L'>
            {task}
          </Heading>
          <Text size='S'>{subtask}</Text>
        </div>
        <div className={styles.cta}>
          {!completed && lastStepCompleted && (
            <Button.Secondary size='Regular' onClick={onClick} testNameSpace={testNameSpace}>
              {label}
            </Button.Secondary>
          )}
        </div>
      </div>
    </div>
  )
}
