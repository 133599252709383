import React, { FunctionComponent, useContext, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Heading, Modal, Text, useToast } from '@afterpaytouch/core'
import { CloseCreditFacilityFlowContext } from '../PayMonthlyCloseCreditFacilityContext'
import { useClosePayMonthlyCreditFacilityMutation } from '../../../state'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

export const ConfirmCloseCreditFacility: FunctionComponent = () => {
  const I18N_NAMESPACE = ['common', 'payMonthly']
  const { t } = useTranslation(I18N_NAMESPACE)
  const setToast = useToast()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const { closeModal, closeCreditFacilityReason, openDate } = useContext(CloseCreditFacilityFlowContext)
  const [closeCreditFacility, { isError, isLoading, isSuccess }] = useClosePayMonthlyCreditFacilityMutation()
  const testNameSpace = 'confirm-close-credit-facility'

  useEffect(() => {
    if (isSuccess) {
      setToast({
        message: t('payMonthly:closeCreditFacility:success:toast'),
        kind: 'Default',
        manualDismiss: true,
        testNameSpace,
      })
      closeModal()
    }
    if (isError) {
      setToast({
        message: t('payMonthly:closeCreditFacility:error:toast'),
        kind: 'Failure',
        manualDismiss: true,
        testNameSpace,
      })
      closeModal()
    }
  }, [isSuccess, isError])

  const submit = (): void => {
    logEvent(TrackingEvent.CREDIT_FACILITY.ConfirmCloseClFacility, {
      closeReason: closeCreditFacilityReason ?? 'CLOSE_PAY_MONTHLY',
    })
    closeCreditFacility({
      closeReason: closeCreditFacilityReason ?? 'CLOSE_PAY_MONTHLY',
    })
  }

  return (
    <>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading size='L'>{t('payMonthly:closeCreditFacility:modal:title')}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Text>
          {t('payMonthly:closeCreditFacility:confirmCloseCreditFacility:detail', {
            date: openDate,
          })}
        </Text>
      </Modal.Content>
      <Modal.Footer>
        <div className='mb-2 mt-5'>
          <Button testNameSpace={testNameSpace} padding='Fluid' loading={isLoading} onClick={submit}>
            {t('payMonthly:closeCreditFacility:confirmCloseCreditFacility:cta')}
          </Button>
        </div>
      </Modal.Footer>
    </>
  )
}
