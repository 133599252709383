import { CardBrand } from '@afterpay/types'
import { PaymentIconNames } from '@afterpaytouch/core'

const CARDS_NAMES = {
  AMEX: 'Amex',
  APPLEPAY: 'Applepay',
  CIRRUS: 'Cirrus',
  DINERSCLUB: 'Dinersclub',
  DISCOVER: 'Discover',
  GENERICBANK: 'GenericBank',
  JCB: 'Jcb',
  MAESTRO: 'Maestro',
  MASTERCARD: 'Mastercard',
  UNIONPAY: 'Unionpay',
  VISA: 'Visa',
}

export const cardIconName = (cardResponse: CardBrand): PaymentIconNames => CARDS_NAMES[cardResponse]

export const useCardIconName = (cardResponse: CardBrand): PaymentIconNames => {
  return cardIconName(cardResponse)
}
