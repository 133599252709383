import { Modal } from '@afterpaytouch/core'
import React, { FunctionComponent } from 'react'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { EditAddressModalContent } from './EditAddressModalContent'

interface EditAddressModalProps {
  isModalOpen: boolean
  closeModal: () => void
  country: string
}

export const EditAddressModal: FunctionComponent<EditAddressModalProps> = ({ isModalOpen, closeModal, country }) => {
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const testNameSpace = 'edit-address'

  const onShow = (): void => {
    logEvent(TrackingEvent.CLICKED_EDIT_ADDRESS)
  }

  return isModalOpen ? (
    <>
      <Modal
        show={isModalOpen}
        onShow={onShow}
        onClose={closeModal}
        onHide={closeModal}
        onBackdropClick={closeModal}
        onEscapeKeyDown={closeModal}
        testNameSpace={testNameSpace}
      >
        <EditAddressModalContent country={country} closeModal={closeModal} />
      </Modal>
    </>
  ) : null
}
