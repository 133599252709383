import React, { FunctionComponent, useContext, useEffect } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Heading, Modal, Text, Button, Link } from '@afterpaytouch/core'
import { ReactivateAccountFlowContext, ReactivateAccountFlowSteps } from './ReactivateAccountFlowContext'
import { useReactivateAccountMutation } from '../../state/account'
import { Error } from './Error'

export const Detail: FunctionComponent = () => {
  const I18N_NAMESPACE = ['common', 'account']

  const { t } = useTranslation(I18N_NAMESPACE)

  const { setStep, setIsFlowSuccess } = useContext(ReactivateAccountFlowContext)
  const [reactivate, { isError, isLoading, isSuccess }] = useReactivateAccountMutation()

  useEffect(() => {
    if (isSuccess) {
      setStep(ReactivateAccountFlowSteps.ConfirmInfo)
      setIsFlowSuccess(true)
    }
  }, [isSuccess, setStep, setIsFlowSuccess])

  if (isError) {
    return <Error />
  }

  return (
    <>
      <Modal.Header divider>
        <Heading size='L'>
          <div className='text-center'>{t('account:reactivateAccount:info:title')}</div>
        </Heading>
      </Modal.Header>
      <Modal.Content>
        <Text>
          <Trans
            i18nKey='account:reactivateAccount:info:detail'
            components={{
              tosLink: <Link href={t('account:reactivateAccount:info:tos')} kind='Underline' target='_blank' />,
              privacyLink: <Link href={t('account:reactivateAccount:info:privacy')} kind='Underline' target='_blank' />,
            }}
          />
        </Text>
      </Modal.Content>
      <Modal.Footer>
        <Button
          padding='Fluid'
          loading={isLoading}
          onClick={() => {
            reactivate()
          }}
          testNameSpace='reactivate-account-detail-cta'
        >
          {t('account:reactivateAccount:info:cta')}
        </Button>
      </Modal.Footer>
    </>
  )
}
