import React, { FC } from 'react'
import { Button, Heading, Link, Modal, Text } from '@afterpaytouch/core'
import { useDisputeTranslation } from '../hooks'
import { useConsumerSizes } from '../../../utils/responsive'
import { useDisputeCreationModalContext } from '../DisputeCreationModalContext'
import { TrackOnMount } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

export const Alibaba: FC = () => {
  const { textSize } = useConsumerSizes()
  const t = useDisputeTranslation()
  const { closeModal } = useDisputeCreationModalContext()
  const heading = t('contactAlibaba:head')
  const subheading = t('contactAlibaba:subheading')
  const cta = t('contactAlibaba:cta')

  return (
    <>
      <TrackOnMount eventName={TrackingEvent.VIEWED_CREATE_DISPUTE_ALIBABA_FLOW} />
      <Modal.Header divider={true}>
        <div className='text-center'>
          <Heading size='L'>{heading}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <Text size={textSize} renderAs='span'>
            {subheading}
          </Text>
          <Link kind='Underline' href='https://support.alibaba.com/ta/list.htm' target='_blank'>
            <Text size={textSize}>https://support.alibaba.com/ta/list.htm</Text>
          </Link>
        </div>
        <div className='mt-4'>
          <Button.Ghost padding='Fluid' size='Large' onClick={closeModal}>
            {cta}
          </Button.Ghost>
        </div>
      </Modal.Content>
    </>
  )
}
