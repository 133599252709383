import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { InitiateInstallmentPaymentResponse } from '@afterpaytouch/portal-api/consumer/paymentSchedule/types'
import { useFlag } from '../../../../hooks'
import { NewCard } from '@afterpaytouch/topaz-api'
import { useConsumerSelector, useGetOrderTransactionQuery, useAppDispatch, useInitiateNewMutation, activateCardScan } from '../../../../state'
import { CardForm, CardFormSchema } from '../../../CardForm'
import { isValidObject } from '../../../../utils/object'
import { mapNewCardToTopaz } from '../../../../state/topaz/utils'
import { PayNowModalContext } from '../../PayNowModalContext'
import { ThreeDS } from '../../../ThreeDS'
import { CardScanForm, useCardScan } from '../../../CardScanForm'
import { isCardScanRequired } from '../../../../state/cardScan/utils'
import { deviceDetails } from '../../../../utils/device'
import { useNewPaymentMethodLogic } from './hooks'
import { useAmplitudeWithEnduringEventProperties } from '../../../../integrations/amplitude'
import { TrackingEvent } from '../../../../model/amplitude'

const I18N_NAMESPACE = ['common', 'payments', 'cardScan']

export interface SelectPaymentProps {
  initiateData: InitiateInstallmentPaymentResponse
}

export const NewPayment: FunctionComponent<SelectPaymentProps> = ({ initiateData }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const dispatch = useAppDispatch()
  const { order, result, setPaymentMethod, setIsFetching, hasAccountLevelPayments, setResult, setIsThreedsModalOpen } = useContext(PayNowModalContext)
  const { data } = useGetOrderTransactionQuery(order?.orderId as string, { skip: hasAccountLevelPayments })
  const consumerData = useConsumerSelector()
  const needAddressDetails = useFlag('billing-address-details')
  const cardScanEnabled = useFlag('card-scanning-new-consumer-portal-custom-payment-enabled')
  const { cardScanRequired, isCardScanError, cardScanError, handleCancel, handleError } = useCardScan()
  const [initiateNewCard, { data: initiateCardData, isSuccess: isInitiateSuccess }] = useInitiateNewMutation()
  // @ts-ignore: OPERATION BLEED STOPPER
  const [initiateError, setInitiateError] = useState<string>(null)
  const handleChange = (data: CardFormSchema): void => setPaymentMethod(data.card)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const initiateCard = useCallback(async (): Promise<void> => {
    try {
      await initiateNewCard({
        // @TODO: Does the api actually require device details here?
        deviceDetails: deviceDetails(),
      }).unwrap()
    } catch (e) {
      setInitiateError(t('payments:customPayment:payment:error:generic'))
    }
  }, [initiateNewCard])

  const {
    handleAccountCardPayment,
    handleOrderCardPayment,
    confirmCard,
    isFetching,
    isInstallmentError,
    installmentError,
    isTopazError,
    isCustomAmountError,
    customAmountError,
    serverError,
    threeDs: { showThreeDSIframe, setThreeDSError, threeDSError, threeDSIframeURL, threeDSOnClose, showModalCloseButton },
  } = useNewPaymentMethodLogic(initiateData ?? initiateCardData)

  const handleSubmit = async (data: CardFormSchema): Promise<void> => {
    logEvent(TrackingEvent.CLICKED_MAKE_A_PAYMENT_NEW_PAYMENT_METHOD, { saveMethod: data?.storeToken, isHardship: hasAccountLevelPayments })
    const addressData = !isValidObject(data?.address) ? consumerData?.contactAddress : data.address
    // @ts-ignore: OPERATION BLEED STOPPER
    const topazParam: NewCard = mapNewCardToTopaz(data.card, needAddressDetails ? addressData : undefined)
    if (hasAccountLevelPayments && isInitiateSuccess) {
      return await handleAccountCardPayment(data?.storeToken, topazParam)
    }
    return await handleOrderCardPayment(data?.storeToken, topazParam, data?.card)
  }

  const resetError = (): void => {
    setThreeDSError(null)
  }

  useEffect(() => {
    if (cardScanEnabled && (isCardScanRequired(installmentError) || isCardScanRequired(customAmountError))) {
      setResult({
        error: false,
        success: false,
      })
      dispatch(activateCardScan())
    }
  }, [cardScanEnabled, customAmountError, installmentError, dispatch, setResult])

  useEffect(() => {
    if (hasAccountLevelPayments) {
      initiateCard()
    }
  }, [initiateCard, hasAccountLevelPayments])

  // @ts-ignore: OPERATION BLEED STOPPER
  useEffect(() => setPaymentMethod(null), [setPaymentMethod])

  useEffect(() => {
    setIsFetching(isFetching)
  }, [setIsFetching, isFetching])

  useEffect(() => {
    return () => {
      setThreeDSError(null)
    }
  }, [])

  useEffect(() => {
    setIsThreedsModalOpen(showThreeDSIframe)
  }, [showThreeDSIframe])

  const showError =
    isTopazError || isInstallmentError || isCustomAmountError || threeDSError !== null || serverError !== null || isCardScanError || initiateError !== null

  const errorMessage = serverError ?? result.message ?? threeDSError ?? cardScanError ?? initiateError

  if (cardScanRequired) {
    return <CardScanForm onCancel={handleCancel} onSuccess={confirmCard} onError={handleError} />
  }

  return (
    <div data-testid='paynow-payment-new'>
      <CardForm
        handleOnFocus={resetError}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        submitting={isFetching}
        showError={showError}
        error={errorMessage}
        // @ts-ignore: OPERATION BLEED STOPPER
        merchantEnabledCards={data?.enabledCardTypes ?? null}
        displayStorePaymentToken={!hasAccountLevelPayments}
        ctaLabel={t('payments:customPayment:payment:cta')}
      />
      <ThreeDS challengeURL={threeDSIframeURL} show={showThreeDSIframe} onClose={threeDSOnClose} showCloseButton={showModalCloseButton} />
    </div>
  )
}
