import { titleCase } from '@afterpay/utils'
import { preferredPaymentDayService } from '@afterpaytouch/portal-api/consumer/preferred-payment-day'
import { useFlag } from '../../hooks'

export const {
  useGetPreferredPaymentDayQuery,
  useCreatePaymentScheduleExampleMutation,
  useConfirmPreferredPaydayMutation,
  useDeletePreferredPaydayMutation,
  endpoints: { getPreferredPaymentDay },
} = preferredPaymentDayService

export const useGetPreferredPayday = (): string | null | undefined => {
  return titleCase(getPreferredPaymentDay.useQueryState().data?.preferredPaymentDay)
}
export const useIsPaydaySet = (): boolean => {
  return getPreferredPaymentDay.useQueryState().data?.preferredPaymentDay !== null
}

export const useGetPreferredPaymentDayData = (): ReturnType<typeof useGetPreferredPaymentDayQuery> => {
  const isEnabled = useFlag('portal-align-payday-enabled', false)
  return useGetPreferredPaymentDayQuery(undefined, { skip: !isEnabled })
}

export const useIsAlignPaydayEligible = (): boolean => {
  const isEnabled = useFlag('portal-align-payday-enabled', false)
  const { data } = useGetPreferredPaymentDayData()
  return data?.isEligible && isEnabled
}
