import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { Selector } from 'react-redux'
import { combineReducers } from 'redux'
import { createRouterMiddleware, routerReducer } from 'connected-next-router'
import { createPortalApi, userReducer, createTopazApi, createShopApi, cardScanReducer, disputeReducer, consumerReducer } from '.'
import { memoize } from '../utils/memoize'
import { DEBUG_MODE } from '../env'
import { createWrapper } from 'next-redux-wrapper'
import { apiService } from '@afterpaytouch/portal-api/api'
import { apiService as topazService } from '@afterpaytouch/topaz-api/api'
import { apiService as shopService } from '@afterpaytouch/shop-api/api'
import { paymentService, paymentHttpService } from './payments'
import { applePayReducer } from './applepay'

const { reducer: paymentReducer, middleware: paymentMiddleware } = paymentService

const { reducer: topazReducer, middleware: topazMiddleware } = topazService.service

const { reducer: shopReducer, middleware: shopMiddleware } = shopService.service

const { reducer: apiReducer, middleware: apiMiddleware } = apiService.service

export const combinedReducer = combineReducers({
  auth: combineReducers({
    user: userReducer,
  }),
  applepay: applePayReducer,
  // @TODO payments reducer to go here that should include cards, bankAccounts and applePay
  router: routerReducer,
  cardScan: cardScanReducer,
  dispute: disputeReducer,
  [paymentService.reducerPath]: paymentReducer,
  [apiService.service.reducerPath]: apiReducer,
  [topazService.service.reducerPath]: topazReducer,
  [shopService.service.reducerPath]: shopReducer,
  consumer: consumerReducer,
})

const apis = {
  portalApi: memoize(() => createPortalApi()),
  topaz: memoize(() => createTopazApi()),
  shop: memoize(() => createShopApi()),
  payments: memoize(() => paymentHttpService),
}

// Don't need to put topaz middleware in the extraArgument because
// we are not planning to call it manually, instead, put the RTKQ middleware
export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: { extraArgument: apis },
    }).concat(createRouterMiddleware(), apiMiddleware, topazMiddleware, shopMiddleware, paymentMiddleware),
  devTools: DEBUG_MODE,
})

setupListeners(store.dispatch)

type ConsumerReducerType = typeof combinedReducer

export type ConsumerAppStore = typeof store
export type ConsumerAppState = ReturnType<ConsumerReducerType>
export type ConsumerAppDispatch = ConsumerAppStore['dispatch']
export type ConsumerAppSelector<T> = Selector<ConsumerAppState, T>

export const wrapper = createWrapper<ConsumerAppStore>(() => store, {
  debug: DEBUG_MODE,
})
