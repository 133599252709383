import { useFlag } from '../../hooks'
import { useConsumerEmail } from '../../state'
import { md5 } from '../../utils/md5'

export const useGravatarSrc = (): string => {
  const email = useConsumerEmail()
  const isGravatarEnabled = useFlag('gravatar-enabled', false)

  if (!isGravatarEnabled || email === null) {
    return ''
  }

  return `https://www.gravatar.com/avatar/${md5(email.toLowerCase())}?d=404&s=100`
}
