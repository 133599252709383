import { joinStrings } from '@afterpay/utils'
import { useDate } from '@afterpaytouch/form/lib/utils/date'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { ProfileCardID } from '../../../'
import { useConsumerSelector } from '../../../../state'

const I18N_NAMESPACE = ['common', 'verifyIdentity']

export const HardIdProfileCard: React.FC = () => {
  const consumer = useConsumerSelector()
  const profileCardName = joinStrings([consumer.givenNames ?? '', consumer.surname ?? ''])
  const consumerDOB = consumer.dateOfBirth ?? ''
  const showDOB = consumerDOB.length > 0
  const { parseISODateString } = useDate('DD/MM/YYYY')
  const { t } = useTranslation(I18N_NAMESPACE)
  const { formattedDate } = parseISODateString(consumerDOB)
  const dobSubHeader = formattedDate.length > 0 ? t('verifyIdentity:partialVerified:dobLabel', { dob: formattedDate }) : undefined

  return <ProfileCardID header={profileCardName} hideSubheader={!showDOB} customSubheader={dobSubHeader} />
}
