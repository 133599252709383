import { Avatar, AvatarProps } from '@afterpaytouch/core'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import React, { FunctionComponent } from 'react'
import { useGravatarSrc } from './Gravatar.hooks'
import { DDPrivacyLevel } from '@afterpaytouch/integrations/datadog'

type GravatarProps = AvatarProps

export const Gravatar: FunctionComponent<GravatarProps> = (props) => {
  return (
    <MaskedElement privacyLevel={DDPrivacyLevel.Hidden}>
      <Avatar {...props} src={useGravatarSrc()} />
    </MaskedElement>
  )
}
