import React, { FunctionComponent } from 'react'
import { Heading, Text } from '@afterpaytouch/core'
import styles from './style.module.scss'
import { ItemSummary } from '@afterpaytouch/portal-api/consumer/ordertransactions/types'
import { useTranslation } from 'next-i18next'

const I18N_NAMESPACE = ['common', 'orders']

interface ProductSummaryProps {
  itemDetails: ItemSummary[]
}

const DetailRow: FunctionComponent<ItemSummary> = ({ name, quantity }) => {
  return (
    <div className={styles.rowContainer}>
      <Text size={'M'}>{name}</Text>
      <Text>Qty: {quantity}</Text>
    </div>
  )
}

export const ProductSummary: FunctionComponent<ProductSummaryProps> = ({ itemDetails }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const hasItems = itemDetails?.length > 0
  return (
    <>
      <Heading size={'L'}>{t('orders:productSummary:header')}</Heading>

      {hasItems ? (
        itemDetails.map((item, i) => <DetailRow key={i} name={item.name} quantity={item.quantity} />)
      ) : (
        <div className={styles.rowContainer}>
          <Text size={'M'}>{t('orders:productSummary:noItems')}</Text>
        </div>
      )}
    </>
  )
}
