import React, { FunctionComponent, HTMLAttributes, TableHTMLAttributes, TdHTMLAttributes, ThHTMLAttributes, ReactNode } from 'react'
import Link from 'next/link'
import styles from './styles.module.css'
import clsx, { ClassValue } from 'clsx'
import { useIsBreakpointLarge } from '@afterpaytouch/core'

export interface TableChildrenProps {
  children: ReactNode
}
export interface TableDataCellProps extends TdHTMLAttributes<HTMLTableCellElement> {
  tabIndex?: number
}

export interface TableLinkDataCellProps extends TdHTMLAttributes<HTMLTableCellElement> {
  tabIndex?: number
  href: string
  renderAs?: string
}

export interface TableRowCellProps extends HTMLAttributes<HTMLTableRowElement> {
  amplitudeFn?: () => void
  testNameSpace?: string
}

export interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  bordered?: boolean
}

export type TableComponent = FunctionComponent<TableChildrenProps> & {
  Head: FunctionComponent<HTMLAttributes<HTMLTableSectionElement>>
  Row: FunctionComponent<TableRowCellProps>
  Content: FunctionComponent<HTMLAttributes<HTMLTableSectionElement>>
  HeaderCell: FunctionComponent<ThHTMLAttributes<HTMLTableCellElement>>
  DataCell: FunctionComponent<TableDataCellProps>
  LinkDataCell: FunctionComponent<TableLinkDataCellProps>
}

export const Table: TableComponent = ({ children, ...otherProps }) => {
  const isBpLargeUp = useIsBreakpointLarge()
  const classNames: ClassValue = clsx(styles.container, isBpLargeUp && styles.isBpLargeUp)

  return (
    <div className={classNames}>
      <table {...otherProps} className={styles.table}>
        {children}
      </table>
    </div>
  )
}

export const TableHead: FunctionComponent<HTMLAttributes<HTMLTableSectionElement>> = ({ children, ...otherProps }) => {
  return <thead {...otherProps}>{children}</thead>
}

export const TableContent: FunctionComponent<HTMLAttributes<HTMLTableSectionElement>> = ({ children, ...otherProps }) => {
  return (
    <tbody className={styles.content} {...otherProps}>
      {children}
    </tbody>
  )
}

export const TableRow: FunctionComponent<TableRowCellProps> = ({ amplitudeFn = () => {}, children, testNameSpace = '' }) => {
  return (
    <tr className={styles.tableRow} onClick={() => amplitudeFn()} {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-tableRow` })}>
      {children}
    </tr>
  )
}

export const TableHeaderCell: FunctionComponent<ThHTMLAttributes<HTMLTableHeaderCellElement>> = ({ children, ...otherProps }) => {
  return (
    <th className={styles.headerCell} {...otherProps}>
      {children}
    </th>
  )
}

export const TableDataCell: FunctionComponent<TableDataCellProps> = ({ children, ...otherProps }) => {
  const classNames: ClassValue = clsx(styles.dataCell)

  return (
    <td className={classNames} {...otherProps}>
      {children}
    </td>
  )
}

export const TableLinkDataCell: FunctionComponent<TableLinkDataCellProps> = ({ children, href, renderAs, tabIndex, ...otherProps }) => {
  return (
    <td className={styles.dataCell} {...otherProps}>
      <Link href={href} as={renderAs} prefetch={false} tabIndex={tabIndex} className={clsx(styles.linkDataCell, 'text-dark')}>
        {children}
      </Link>
    </td>
  )
}

Table.Head = TableHead
Table.Row = TableRow
Table.Content = TableContent
Table.HeaderCell = TableHeaderCell
Table.DataCell = TableDataCell
Table.LinkDataCell = TableLinkDataCell
