import React, { FunctionComponent, ReactNode, useContext } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Button, Heading, Modal, Text, Span } from '@afterpaytouch/core'
import { NewTabLink } from '../NewTabLink'
import { ReactivateAccountFlowContext } from './ReactivateAccountFlowContext'

const I18N_NAMESPACE = ['common', 'account']

const HelpLink: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <Span underline>
      <NewTabLink href={t('common:urls:helpUrl')}>{children}</NewTabLink>
    </Span>
  )
}

const AssistanceText: FunctionComponent = () => (
  <Text>
    <Trans
      i18nKey={'account:closeAccount.confirmCloseAccount.error.message.assistance'}
      components={{
        helpLink: <HelpLink />,
      }}
    />
  </Text>
)

export const Error: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)

  const { closeModal } = useContext(ReactivateAccountFlowContext)
  return (
    <>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading size='L'>{t('account:reactivateAccount:error:title')}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Text>{t('account:reactivateAccount:error:detail')}</Text>
        <br />
        <AssistanceText />
      </Modal.Content>
      <Modal.Footer>
        <Button testNameSpace='reactivate-account-error-cta' padding='Fluid' onClick={closeModal}>
          {t('common:close')}
        </Button>
      </Modal.Footer>
    </>
  )
}
