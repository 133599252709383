import { Heading, Panel } from '@afterpaytouch/core'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useDisplayIllustrations } from '../../state/convergence/hooks'

export const AccountClosedPanel: FunctionComponent = () => {
  const I18N_NAMESPACE = ['common', 'payMonthly']
  const { t } = useTranslation(I18N_NAMESPACE)
  const isDisplayIllustrations = useDisplayIllustrations()

  return (
    <div className='mt-4 text-center'>
      <Panel testNameSpace='pay-monthly-closed'>
        <Panel.Content>
          <div className='pb-16 pt-16'>
            {isDisplayIllustrations && (
              <Image
                width={164}
                height={124}
                src={`/images/gift.svg`}
                alt=''
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            )}
            <div className='mb-4 mt-4'>
              <Heading bold>{t('payMonthly:closeCreditFacility:closed:heading:0')}</Heading>
              <Heading bold>{t('payMonthly:closeCreditFacility:closed:heading:1')}</Heading>
            </div>
            {isDisplayIllustrations && (
              <Image
                width={340}
                height={80}
                src={`/images/brands.svg`}
                alt=''
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            )}
          </div>
        </Panel.Content>
      </Panel>
    </div>
  )
}
