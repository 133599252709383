import React, { FunctionComponent, useState } from 'react'
import { VerifyIdentity } from './VerifyIdentity'
import { FirstPurchase } from './FirstPurchase'
import { Step, StepCtaProps } from './Step'

import { useNewUserEffects, useNewUserLocalization } from './NewUser.hooks'
import clsx from 'clsx'
import { Panel, Skeleton, useIsBreakpointLargeAndUp } from '@afterpaytouch/core'
import styles from './style.module.scss'
// import { BASE_PATH } from '../../../env'
// import Image from 'next/image'
import { AddCardModal } from '../../AccountTabs/PaymentMethods/AddCardModal'
import { useRouter } from 'next/router'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { redirectToOldPortalWithCookie } from '../../../utils/redirects'
import { LaunchDarklyFlags, useFlag } from '../../../hooks'
import { useIdentityVerificationFAQLink } from '../../../utils/useFAQLink'
import { mapLocales } from '../../../utils/mapLocales'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { Route } from '../../../config/router'
import { IdentityCheckStatus } from '@afterpay/types'
import { ExtendedPanel } from '../../Panel'
import { parseCookies } from 'nookies'

enum FEATURE_FLAG_BY_COUNTRY {
  NZ = 'nz-identity-verification-enabled',
  GB = 'uk-consumer-portal-identity-verification-enabled',
}

interface StepStatus {
  task: string
  subtask: string
  isCompleted: boolean
  cta?: StepCtaProps
}

export const NewUser: FunctionComponent = () => {
  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] = useState(false)
  const isBpLargeUp = useIsBreakpointLargeAndUp()
  const classNames = clsx(isBpLargeUp && styles.bpLargeUp)
  const { t } = useNewUserLocalization()
  const { consumer, isLoading, isFetching, isError, refetchNewUser } = useNewUserEffects()
  const router = useRouter()
  const locale = router?.locale as SupportedLocale

  const isSkeletonShown = isLoading || isFetching
  const featureFlagKeys = FEATURE_FLAG_BY_COUNTRY[mapLocales('locale', locale)]
  const isVerificationFeatureFlagEnabled = useFlag(featureFlagKeys)
  const isVerificationFeatureFlagDisabled: boolean = useFlag('consumer-portal-identity-verification-disabled', false)
  const cookies = parseCookies()
  const loginMigrationEnabledInCookie = cookies[LaunchDarklyFlags.LoginMigration] ?? 'false'
  const loginMigrationEnabled = loginMigrationEnabledInCookie.toString() === 'true'

  const identityVerificationLink = useIdentityVerificationFAQLink(locale)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  if (isError) {
    return <ExtendedPanel.Error onClick={refetchNewUser} />
  }

  // const imageSrc = [BASE_PATH, `images/${locale}/img-newuser@2x.png`].join('/')

  const openAddPaymentModal = (): void => setIsAddPaymentModalOpen(true)
  const closeAddPaymentModal = (): void => setIsAddPaymentModalOpen(false)

  const isLocaleHardIdentityCheckRequired = consumer.isLocaleHardIdentityCheckRequired
  const identityCheckStatus = consumer.identityCheckStatus

  const getStepOneTaskStatus = (): StepStatus => {
    // scenario logic: https://docs.google.com/document/d/1YjGFfiBoMHWkpMRtWF2g4IKGT784YlMH4471-NakMJk/edit?usp=sharing
    if (identityCheckStatus === IdentityCheckStatus.VERIFIED) {
      return {
        task: t('index:welcome:steps:verify:task-completed'),
        subtask: t('index:welcome:steps:verify:subtask-completed'),
        isCompleted: true,
      }
    } else if (identityCheckStatus !== IdentityCheckStatus.NOT_REQUIRED && !isVerificationFeatureFlagEnabled && isLocaleHardIdentityCheckRequired) {
      return {
        task: t('index:welcome:steps:verify:task'),
        subtask: t('index:welcome:steps:verify:subtask'),
        isCompleted: false,
      }
    } else if ((identityCheckStatus === IdentityCheckStatus.NOT_REQUIRED && !isLocaleHardIdentityCheckRequired) || isVerificationFeatureFlagDisabled) {
      return {
        task: t('index:welcome:steps:verify:task-not-required'),
        subtask: t('index:welcome:steps:verify:task-not-required-detail'),
        isCompleted: true,
      }
    } else if (identityCheckStatus === IdentityCheckStatus.NOT_VERIFIED || identityCheckStatus === IdentityCheckStatus.PARTIAL_VERIFIED) {
      return {
        task: t('index:welcome:steps:verify:task'),
        subtask: t('index:welcome:steps:verify:subtask'),
        isCompleted: false,
      }
    } else if (identityCheckStatus === IdentityCheckStatus.FAILED) {
      return {
        task: t('index:welcome:steps:verify:task'),
        subtask: t('index:welcome:steps:verify:subtask-failed'),
        isCompleted: false,
        cta: {
          label: t('index:welcome:steps:verify:cta:failedLabel'),
          onClick: () => redirectHelpLink(),
        },
      }
    } else {
      return {
        task: t('index:welcome:steps:verify:task'),
        subtask: t('index:welcome:steps:verify:subtask'),
        isCompleted: false,
      }
    }
  }

  const stepOneStatus = getStepOneTaskStatus()
  const isStepOneComplete = stepOneStatus?.isCompleted
  const isStepTwoComplete = isStepOneComplete && consumer.hasPreferredPaymentMethod
  const isStepThreeComplete = isStepTwoComplete && consumer.hasTransactions

  const stepsClassName = clsx(styles.steps, isSkeletonShown ? styles.invisible : styles.visible)

  const redirectToIDVFlow = (): void => {
    loginMigrationEnabled ? router.push(Route.VERIFY_RETRY) : redirectToOldPortalWithCookie('login-finished')
    // ref: https://github.com/AfterpayTouch/pay-later/blob/c317d8d3aba8d64b22e8252870dbfc46609312fe/client/consumer/application/main/controller/router.js#L221
    logEvent(TrackingEvent.CLICKED_VERIFY_NOW, { locale, outboundLink: router?.pathname })
  }

  const redirectHelpLink = (): void => {
    window.open(identityVerificationLink, '_blank')
  }

  const openShopPage = (): void => {
    const url = t('common:urls:afterpaySite')
    logEvent(TrackingEvent.CLICKED_SHOP_NOW, { outboundLink: url })
    window.open(url, '_blank')
  }

  return (
    <>
      <div className={classNames} data-testid='new-cx-welcome-panel'>
        <ExtendedPanel.HeadWithCrumb>
          <div className={styles.headContainer}>
            {consumer.isVerified ? (
              <FirstPurchase consumer={consumer} isSkeletonShown={isSkeletonShown} isParentLoading={isLoading || isFetching} />
            ) : (
              <VerifyIdentity consumer={consumer} isSkeletonShown={isSkeletonShown} />
            )}
            {/* {!isSkeletonShown && (
              <div className={styles.imageContainer}>
                <Image
                  priority={true}
                  src={imageSrc}
                  width={400}
                  height={172}
                  alt='Treat Yo Self'
                  layout='fixed'
                />
              </div>
            )} */}
          </div>
        </ExtendedPanel.HeadWithCrumb>
        {/* @ts-ignore: OPERATION BLEED STOPPER */}
        <Panel.Content>
          <div className={styles.contentSkeletonContainer}>
            {isSkeletonShown && <Skeleton kind='Default' />}
            <div className={stepsClassName}>
              <Step
                badge={t('common:numbers:one')}
                task={stepOneStatus?.task}
                subtask={stepOneStatus?.subtask}
                completed={isStepOneComplete}
                cta={
                  stepOneStatus?.cta ?? {
                    label: t('index:welcome:steps:verify:cta:label'),
                    onClick: redirectToIDVFlow,
                  }
                }
              />
              <div className={styles.divider} />
              <Step
                badge={t('common:numbers:two')}
                task={isStepTwoComplete ? t('index:welcome:steps:payment:task-completed') : t('index:welcome:steps:payment:task')}
                subtask={isStepTwoComplete ? t('index:welcome:steps:payment:subtask-completed') : t('index:welcome:steps:payment:subtask')}
                completed={isStepTwoComplete}
                lastStepCompleted={isStepOneComplete}
                testNameSpace='new-cx-add-payment-method'
                cta={{
                  label: t('index:welcome:steps:payment:cta:label'),
                  onClick: openAddPaymentModal,
                }}
              />
              <div className={styles.divider} />
              <Step
                badge={t('common:numbers:three')}
                task={t('index:welcome:steps:purchase:task')}
                subtask={t('index:welcome:steps:purchase:subtask')}
                completed={isStepThreeComplete}
                lastStepCompleted={isStepOneComplete}
                testNameSpace='new-cx-shop'
                cta={{
                  label: t('index:welcome:steps:purchase:cta:label'),
                  onClick: openShopPage,
                }}
              />
            </div>
          </div>
        </Panel.Content>
      </div>
      <AddCardModal show={isAddPaymentModalOpen} onClose={closeAddPaymentModal} />
    </>
  )
}
