import React, { FunctionComponent } from 'react'
import { Panel } from '@afterpaytouch/core'
import {
  useAccountProgressionIsExistingUser,
  useConsumerHasRestrictedAccount,
  useConsumerIsFrozenStatusAccount,
  useIsPulseTierPlatinumOrAbove,
} from '../../state'
import { AlignPaydayIntroModal, useAlignPayIntroModal, useShowAlignPayIntroModalOnMount } from '../AlignPaydayIntroModal'
import { PulseIntroModal, usePulseIntroModal, useShowPulseIntroModalOnMount } from '../PulseIntroModal'
import { ExistingUser } from './ExistingUser'
import { NewUser } from './NewUser'
import { WelcomeModal } from './WelcomeModal'
import { useShowWelcomeModalOnMount, useWelcomeModal } from './WelcomeModal/WelcomeModal.hooks'
import { TerminationNoticeModal, useShowTerminationNoticeModalOnMount, useTerminationNoticeModal } from '../TerminationNoticeModal'

export const WelcomePanel: FunctionComponent = () => {
  const existingUser = useAccountProgressionIsExistingUser()

  const [isWelcomeModalOpen, { openModal: openWelcomeModal, closeModal: closeWelcomeModal }] = useWelcomeModal()
  useShowWelcomeModalOnMount(openWelcomeModal)

  const [isPulseIntroModalOpen, { openModal: openPulseIntroModal, closeModal: closePulseIntroModal }] = usePulseIntroModal()
  useShowPulseIntroModalOnMount(openPulseIntroModal)

  const [isAlignPaydayModalOpen, { openModal: openAlignPaydayModal, closeModal: closeAlignPaydayModal }] = useAlignPayIntroModal()
  useShowAlignPayIntroModalOnMount(openAlignPaydayModal)

  const [isTerminationNoticeModalOpen, { openModal: openTerminationNoticeModal, closeModal: closeTerminationNoticeModal }] = useTerminationNoticeModal()
  useShowTerminationNoticeModalOnMount(openTerminationNoticeModal)

  const isPulseTierPlatinumOrAbove = useIsPulseTierPlatinumOrAbove()
  const isUserAccountRestricted = useConsumerHasRestrictedAccount()
  const isUserAccountFrozen = useConsumerIsFrozenStatusAccount()
  const isUserAccountRestrictedOrFrozen = isUserAccountRestricted || isUserAccountFrozen

  return (
    <>
      <Panel kind='Primary'>{existingUser ? <ExistingUser /> : <NewUser />}</Panel>
      <WelcomeModal isModalOpen={isWelcomeModalOpen} onClose={closeWelcomeModal} />
      <PulseIntroModal isModalOpen={isPulseIntroModalOpen} closeModal={closePulseIntroModal} />
      {isPulseTierPlatinumOrAbove && !isUserAccountRestrictedOrFrozen && (
        <AlignPaydayIntroModal isModalOpen={isAlignPaydayModalOpen} closeModal={closeAlignPaydayModal} />
      )}
      <TerminationNoticeModal isModalOpen={isTerminationNoticeModalOpen} closeModal={closeTerminationNoticeModal} />
    </>
  )
}
