import React, { FunctionComponent, ReactNode, useContext } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Button, Heading, Modal, Text, Span } from '@afterpaytouch/core'
import { ErrorCode, PortalApiError } from '@afterpaytouch/portal-api'
import { CloseAccountFlowContext } from '../CloseAccountFlow/CloseAccountFlowContext'
import { Route } from '../../../config/router'
import { NewTabLink } from '../../NewTabLink'

interface OrdersLinkProps {
  children?: ReactNode
}

interface HelpLinkProps {
  children?: ReactNode
}

const I18N_NAMESPACE = ['common', 'account']

const HelpLink: FunctionComponent<HelpLinkProps> = ({ children }) => {
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <Span underline>
      <NewTabLink href={t('common:urls:helpUrl')}>{children}</NewTabLink>
    </Span>
  )
}

const OrdersLink: FunctionComponent<OrdersLinkProps> = ({ children }) => (
  <Span underline>
    <NewTabLink href={Route.ORDERS}>{children}</NewTabLink>
  </Span>
)

const AssistanceText: FunctionComponent = () => (
  <Text>
    <Trans
      i18nKey={'account:closeAccount.confirmCloseAccount.error.message.assistance'}
      components={{
        helpLink: <HelpLink />,
      }}
    />
  </Text>
)

export const Error: FunctionComponent<{ error?: PortalApiError }> = ({ error }) => {
  const { t } = useTranslation(I18N_NAMESPACE)

  const { closeModal } = useContext(CloseAccountFlowContext)

  const errorCode = error?.errorCode ?? 'generic'

  const renderErrorMessage = (): ReactNode => {
    let errorMessage
    switch (errorCode) {
      case ErrorCode.accountBalanceNonZero:
        errorMessage = (
          <>
            <Text>{t('account:closeAccount.confirmCloseAccount.error.message.account_balance_non_zero_1')}</Text>
            <br />
            <Text>
              <Trans
                i18nKey={'account:closeAccount.confirmCloseAccount.error.message.account_balance_non_zero_2'}
                components={{
                  ordersLink: <OrdersLink />,
                }}
              />
            </Text>
            <br />
            <AssistanceText />
          </>
        )
        break
      case ErrorCode.accountAlreadyFrozen:
        errorMessage = (
          <div className='mb-4'>
            <Text>
              <Trans
                i18nKey={'account:closeAccount.confirmCloseAccount.error.message.account_already_frozen'}
                components={{
                  helpLink: <HelpLink />,
                }}
              />
            </Text>
          </div>
        )
        break
      case ErrorCode.accountHasSubscriptionOrders:
        errorMessage = (
          <>
            <Text>{t(`account:closeAccount.confirmCloseAccount.error.message.account_has_subscription_orders`)}</Text>
            <br />
            <AssistanceText />
          </>
        )
        break
      case ErrorCode.accountHasChargeAtShipOrders:
        errorMessage = (
          <>
            <Text>{t(`account:closeAccount.confirmCloseAccount.error.message.account_has_charge_at_ship_orders`)}</Text>
            <br />
            <AssistanceText />
          </>
        )
        break
      default:
        errorMessage = <Text>{t('account:closeAccount.confirmCloseAccount.error.message.generic')}</Text>
        break
    }
    return errorMessage
  }
  return (
    <>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading size='L' testNameSpace='confirm-close-account-error'>
            {t('account:closeAccount.confirmCloseAccount.error.title')}
          </Heading>
        </div>
      </Modal.Header>
      <Modal.Content>{renderErrorMessage()}</Modal.Content>
      <Modal.Footer>
        <Button testNameSpace='confirm-close-account-error' padding='Fluid' onClick={closeModal}>
          {t('common:close')}
        </Button>
      </Modal.Footer>
    </>
  )
}
