import { useAppDispatch, setCardScanRequired, getCardScanSuccessCallback, useCardScanRequired } from '../../state'
import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { isCallable } from '@afterpay/utils'

export interface UseCardScan {
  cardScanRequired: boolean
  handleCancel: () => void
  handleSuccess: () => void
  handleError: () => void
  cardScanError: string
  isCardScanError: boolean
}

const I18N_NAMESPACE = ['account']

export const useCardScan = (): UseCardScan => {
  const dispatch = useAppDispatch()
  const cardScanRequired = useCardScanRequired()
  const [error, setError] = useState<string>()
  const [isCardScanError, setIsCardScanError] = useState<boolean>(false)
  const { t } = useTranslation(I18N_NAMESPACE)

  const handleCancel = (): void => {
    dispatch(setCardScanRequired(false))
  }

  const handleSuccess = (): void => {
    dispatch(setCardScanRequired(false))
    const callback = getCardScanSuccessCallback()
    if (isCallable(callback)) {
      callback()
    }
  }

  const handleError = (): void => {
    dispatch(setCardScanRequired(false))
    setIsCardScanError(true)
    setError(t('account:tab:payment-methods:modal:form:error:server:unspecified_error'))
  }

  return {
    cardScanRequired,
    handleCancel,
    handleSuccess,
    handleError,
    isCardScanError,
    // @ts-ignore: OPERATION BLEED STOPPER
    cardScanError: error,
  }
}
