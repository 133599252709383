import React, { FunctionComponent, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { Modal, Button, Text } from '@afterpaytouch/core'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { AchFlowSteps, AchFlowModalContext, defaultResult } from '../AchFlowModalContext'

const I18N_NAMESPACE = ['ach']

export const Error: FunctionComponent = () => {
  const { setStep, setResult } = useContext(AchFlowModalContext)
  const { t } = useTranslation(I18N_NAMESPACE)
  const amplitude = useAmplitudeWithEnduringEventProperties()

  return (
    <>
      <Modal.Content>
        <div className='mb-4'>
          <Text>{t('ach:addBankAccount:error:detail1')}</Text>
        </div>
        <Text bold>{t('ach:addBankAccount:error:detail2')}</Text>
        <Text>{t('ach:addBankAccount:error:detail3')}</Text>
      </Modal.Content>
      <Modal.Footer>
        <Button
          padding='Fluid'
          onClick={() => {
            setResult(defaultResult)
            setStep(AchFlowSteps.AddCard)
            amplitude.logEvent(TrackingEvent.ACH.ClickAddCardInAddBankAccountError)
          }}
          testNameSpace='ach-add-bank-account-error-cta'
        >
          {t('ach:addBankAccount:error:CTA')}
        </Button>
      </Modal.Footer>
    </>
  )
}
