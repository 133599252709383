import React, { FormEvent, FunctionComponent, ReactNode } from 'react'
import styles from './style.module.css'

export interface FormProps {
  onSubmit?: (e: FormEvent) => any
  testNameSpace?: string
  children: ReactNode
}

export const Form: FunctionComponent<FormProps> = ({ onSubmit, children, testNameSpace = '' }) => {
  return (
    <form onSubmit={onSubmit} className={styles.form} noValidate={true} {...(testNameSpace.length > 0 && { 'data-testid': `${testNameSpace}-form` })}>
      {children}
    </form>
  )
}
