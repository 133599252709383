import { CreditCard } from '@afterpaytouch/portal-api/consumer/account/creditcards/types'
import { titleCase } from '@afterpay/utils'
import {
  useDeleteCardMutation,
  useUpdatePreferredCardMutation,
  useSetApplePayPreferredMutation,
  useUpdatePreferredBankMutation,
  useDeleteBankMutation,
  useGetOrderTransactionsQuery,
  useActiveOrders,
  useGetConsumerTagsWithDataQuery,
  useCreateConsumerTagMutation,
  useUpdateConsumerTagMutation,
} from '../../../state'
import { TrackingEvent } from '../../../model/amplitude'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { useToast } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { maskCreditCardNumber } from '../../../utils/card'
import { BankAccountDetails, CashAppPayDetail, isBankAccount, isCard, isCashAppPay } from '@afterpay/types'
import { PaymentMethodType } from '@afterpaytouch/portal-api'
import { useRemoveCashAppPayMutation, useSetPreferredCashAppPayMutation } from '../../../state/cashAppPays/hooks'
import { defaultOrdersParams } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import { createModalHook, useFlag } from '../../../hooks'
import { useEffect } from 'react'
import { EntryPoint, EntryPointProps } from './PreferredDayModal/types'
import { useIsAccountPage } from '../../../config/router'

const I18N_NAMESPACE = ['common', 'account']

export const useSetPreferredDayModal = createModalHook()
export const useTurnOffPreferredDayModal = createModalHook()

type HandleCallback = (callback: () => void) => Promise<void>

export const usePaymentMethodsBusinessLogic = (
  selectedPaymentMethod: CreditCard | BankAccountDetails | CashAppPayDetail | PaymentMethodType.APPLE_PAY | null,
  includingAgreements: boolean
): {
  handleRemoveCard: HandleCallback
  handlePreferredCard: HandleCallback
  handlePreferredApplePay: HandleCallback
  handlePreferredBank: HandleCallback
  handlePreferredCashAppPay: HandleCallback
  handleRemoveBank: HandleCallback
  handleRemoveCashAppPay: HandleCallback
} => {
  const cardString =
    isCard(selectedPaymentMethod) &&
    `${titleCase(selectedPaymentMethod?.cardBrand)} ${maskCreditCardNumber(selectedPaymentMethod?.truncatedNumber ?? selectedPaymentMethod?.maskedPan)}`
  const bankString =
    isBankAccount(selectedPaymentMethod) && `${titleCase(selectedPaymentMethod?.issuingBank)} ${maskCreditCardNumber(selectedPaymentMethod?.numberMask)}`
  const { t } = useTranslation(I18N_NAMESPACE)
  const [deleteCard] = useDeleteCardMutation()
  const [setPreferredCard] = useUpdatePreferredCardMutation()
  const [setApplePayAsPreferred] = useSetApplePayPreferredMutation()
  const [setPreferredBank] = useUpdatePreferredBankMutation()
  const [setPreferredCashAppPay] = useSetPreferredCashAppPayMutation()
  const [removeCashAppPay] = useRemoveCashAppPayMutation()
  const [deleteBank] = useDeleteBankMutation()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const setToast = useToast()
  const router = useRouter()

  const handleRemoveCard = async (callback): Promise<void> => {
    if (!isCard(selectedPaymentMethod)) {
      return
    }
    try {
      logEvent(TrackingEvent.CLICKED_REMOVE_CARD, { outboundLink: router?.pathname })
      await deleteCard(selectedPaymentMethod.id.toString()).unwrap()

      setToast({
        message: t('account:tab:payment-methods:removeModal:success', {
          paymentMethod: cardString,
        }),
        kind: 'Success',
        testNameSpace: 'remove-card',
      })

      callback()
    } catch (error) {
      logEvent(TrackingEvent.DELETE_CARD_FAILED, {
        error: error?.data?.errorCode,
      })

      if (error?.data?.errorCode === 'payment_method_with_active_agreement') {
        setToast({
          message: t('account:tab.payment-methods.removeModal.errorActiveAgreement'),
          kind: 'Failure',
        })
      } else {
        setToast({
          message: error?.data?.message ?? t('account:tab:payment-methods:removeModal:errorCard'),
          kind: 'Failure',
        })
      }

      callback()
    }
  }

  const handlePreferredCard = async (callback): Promise<void> => {
    if (!isCard(selectedPaymentMethod)) {
      return
    }
    try {
      logEvent(TrackingEvent.CLICKED_UPDATE_PREFERRED_CARD, { outboundLink: router?.pathname })
      await setPreferredCard({
        params: { id: selectedPaymentMethod.id.toString(), includingAgreements },
        cache: { invalidatesTags: ['BankAccounts', 'ConsumerAccount'] },
      }).unwrap()
      setToast({
        message: t('account:tab:payment-methods:preferredModal:success', {
          paymentMethod: cardString,
        }),
        kind: 'Success',
        testNameSpace: 'set-card-preferred',
      })

      callback()
    } catch (error) {
      logEvent(TrackingEvent.SET_PREFERRED_CARD_FAILED, {
        error: error?.data?.errorCode,
      })
      const errorMessage =
        error?.data?.errorCode === 'unsupported_card_issuer_bank'
          ? t('account:tab:payment-methods:preferredModal:error:unsupportedCardIssuer')
          : t('account:tab:payment-methods:preferredModal:errorCard')
      setToast({
        message: error?.data?.message ?? errorMessage,
        kind: 'Failure',
      })
    }
  }

  const handlePreferredBank = async (callback): Promise<void> => {
    if (!isBankAccount(selectedPaymentMethod)) {
      return
    }
    try {
      await setPreferredBank({
        params: { id: selectedPaymentMethod.id.toString() },
        cache: { invalidatesTags: ['CreditCards', 'ConsumerAccount'] },
      }).unwrap()
      setToast({
        message: t('account:tab:payment-methods:preferredModal:success', {
          paymentMethod: bankString,
        }),
        kind: 'Success',
        testNameSpace: 'set-bank-account-preferred',
      })

      callback()
    } catch (error) {
      setToast({
        message: error?.data?.message ?? t('account:tab:payment-methods:preferredModal:errorACH'),
        kind: 'Failure',
      })
    }
  }

  const handleRemoveBank = async (callback): Promise<void> => {
    if (!isBankAccount(selectedPaymentMethod)) {
      return
    }
    try {
      await deleteBank(selectedPaymentMethod.id.toString()).unwrap()
      setToast({
        message: t('account:tab:payment-methods:removeModal:success', {
          paymentMethod: bankString,
        }),
        kind: 'Success',
        testNameSpace: 'remove-bank-account',
      })

      callback()
    } catch (error) {
      setToast({
        message: error?.data?.message ?? t('account:tab:payment-methods:removeModal:errorACH'),
        kind: 'Failure',
      })
    }
  }

  const handlePreferredApplePay = async (callback): Promise<void> => {
    if (selectedPaymentMethod !== PaymentMethodType.APPLE_PAY) {
      return
    }
    try {
      await setApplePayAsPreferred({
        cache: { invalidatesTags: ['ConsumerAccount'] },
      }).unwrap()

      setToast({
        message: t('account:tab:payment-methods:preferredModal:success', {
          paymentMethod: 'Apple Pay',
        }),
        kind: 'Success',
      })
      callback()
    } catch (error) {
      logEvent(TrackingEvent.SET_PREFERRED_APPLE_PAY_ERROR, {
        error: error?.data?.errorCode,
      })

      if (error?.data?.message) {
        setToast({
          message: error?.data?.message,
          kind: 'Failure',
        })
      }
    }
  }

  const handlePreferredCashAppPay = async (callback): Promise<void> => {
    if (!isCashAppPay(selectedPaymentMethod)) {
      return
    }
    try {
      await setPreferredCashAppPay().unwrap()

      setToast({
        message: t('account:tab:payment-methods:preferredModal:success', {
          paymentMethod: selectedPaymentMethod.cashtag,
        }),
        kind: 'Success',
        testNameSpace: 'set-cash-app-preferred',
      })
      callback()
    } catch (error) {
      logEvent(TrackingEvent.SET_PREFERRED_APPLE_PAY_ERROR, {
        error: error?.data?.errorCode,
      })

      if (error?.data?.message) {
        setToast({
          message: error?.data?.message,
          kind: 'Failure',
        })
      }
    }
  }

  const handleRemoveCashAppPay = async (callback): Promise<void> => {
    if (!isCashAppPay(selectedPaymentMethod)) {
      return
    }
    try {
      await removeCashAppPay(selectedPaymentMethod.id).unwrap()

      setToast({
        message: t('account:tab:payment-methods:removeModal:success', {
          paymentMethod: selectedPaymentMethod.cashtag,
        }),
        kind: 'Success',
      })
      callback()
    } catch (error) {
      if (error?.data?.message) {
        setToast({
          message: error?.data?.message,
          kind: 'Failure',
        })
      }
    }
  }

  return {
    handleRemoveCard,
    handlePreferredCard,
    handlePreferredApplePay,
    handlePreferredBank,
    handleRemoveBank,
    handlePreferredCashAppPay,
    handleRemoveCashAppPay,
  }
}

export const useOnlyActiveSubscriptionOrders = (params = defaultOrdersParams): boolean => {
  useGetOrderTransactionsQuery(defaultOrdersParams)
  const active = useActiveOrders(params)
  const hasSubscriptions = active?.filter((order) => order?.subscriptionPayment).length > 0
  const activeNonSubscriptionOrder = active?.filter((order) => !order?.subscriptionPayment).length > 0
  return hasSubscriptions && !activeNonSubscriptionOrder
}

export const useHasSeenAlignPaydayTag = (): boolean => {
  const isAlignPaydayEnabled = useFlag('portal-align-payday-enabled', false)

  const { data: consumerTags, isSuccess: isGetConsumerTagsSuccesss } = useGetConsumerTagsWithDataQuery()
  const isAlignPaydayEnabledAndGetTagsSuccess = isAlignPaydayEnabled && isGetConsumerTagsSuccesss
  const hasSeenAlignPaydayTag = consumerTags?.['portal.consumer-has-seen-align-payday-tag'] === String(true)
  const alignPdayConsumerTagExists = consumerTags?.['portal.consumer-has-seen-align-payday-tag'] !== undefined

  const [createConsumerTag] = useCreateConsumerTagMutation()
  const [updateConsumerTag] = useUpdateConsumerTagMutation()

  useEffect(() => {
    if (isAlignPaydayEnabledAndGetTagsSuccess && !alignPdayConsumerTagExists) {
      createConsumerTag({ body: { name: 'portal.consumer-has-seen-welcome-modal', data: String(true) } })
    }

    if (isAlignPaydayEnabledAndGetTagsSuccess && !hasSeenAlignPaydayTag && alignPdayConsumerTagExists) {
      updateConsumerTag({ body: { name: 'portal.consumer-has-seen-welcome-modal', data: String(true) } })
    }
  }, [hasSeenAlignPaydayTag, isAlignPaydayEnabledAndGetTagsSuccess, alignPdayConsumerTagExists])

  return hasSeenAlignPaydayTag
}

export const useAlignPaydayEntryPoint = (): EntryPointProps => (useIsAccountPage() ? EntryPoint.PaymentSettings : EntryPoint.Modal)
