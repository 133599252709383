import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { DisputePermissionError } from '@afterpaytouch/portal-api'

export interface DisputeState {
  isDisputePermitted: boolean
  disputePermissionError?: DisputePermissionError
}

export const disputeInitialState: DisputeState = {
  isDisputePermitted: true,
  disputePermissionError: undefined,
}

const slice: Slice = createSlice({
  name: 'dispute',
  initialState: disputeInitialState,
  reducers: {
    setIsDisputePermitted(state: DisputeState, action: PayloadAction<boolean>) {
      state.isDisputePermitted = action.payload
    },
    setDisputePermissionError(state: DisputeState, action: PayloadAction<DisputePermissionError | undefined>) {
      state.disputePermissionError = action.payload
    },
  },
})

export const { setIsDisputePermitted, setDisputePermissionError } = slice.actions

const { reducer } = slice
export const disputeReducer = reducer
