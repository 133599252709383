import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Button, Heading, Input, Modal, Text, useToast } from '@afterpaytouch/core'
import { useCloseAccountMutation } from '../../../state/account'
import { CloseAccountFlowContext } from '../CloseAccountFlowContext'
import { TrackOnMount, useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

export const ConfirmCloseAccount: FunctionComponent<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const I18N_NAMESPACE = ['common', 'account']
  const { t } = useTranslation(I18N_NAMESPACE)
  const testNameSpace = 'confirm-close-account'
  const { closeModal, closeAccountReason } = useContext(CloseAccountFlowContext)
  const setToast = useToast()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [closeAccountMutation, { isLoading, isSuccess }] = useCloseAccountMutation()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  useEffect(() => {
    if (isSuccess) {
      setToast({
        message: t('account:closeAccount:closeAccount:confirmCloseAccount:toast'),
        testNameSpace,
      })
      logEvent(TrackingEvent.VIEWED_CLOSE_MY_ACCOUNT_SUCCESS_TOAST)
      closeModal()
      onSuccess()
    }
  }, [isSuccess])

  const submitCloseAccount = (): void => {
    if (closeAccountReason === null) {
      return
    }
    logEvent(TrackingEvent.PRESSED_CONFIRM_TO_CLOSE_MY_ACCOUNT)
    closeAccountMutation({ closeReason: closeAccountReason })
  }

  const handleOnChange = (e): void => {
    if (e.target.value === t('account:closeAccount:closeAccount:confirmCloseAccount:input')) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }

  return (
    <>
      <Modal.Header divider>
        <Heading size='L'>{t('account:closeAccount:closeAccount:confirmCloseAccount:title')}</Heading>
      </Modal.Header>
      <Modal.Content>
        <Text>{t('account:closeAccount:closeAccount:confirmCloseAccount:description:0')}</Text>
        <div className='mb-2 text-right'>
          <Text>
            <Trans i18nKey={'account:closeAccount:closeAccount:confirmCloseAccount:description:1'} components={{ strong: <strong /> }} />
          </Text>
        </div>
        <Input testNameSpace={testNameSpace} label={t('account:closeAccount:closeAccount:confirmCloseAccount:input')} fullWidth onChange={handleOnChange} />
      </Modal.Content>
      <Modal.Footer>
        <div className='mb-6 mt-2'>
          <Button disabled={isButtonDisabled} testNameSpace={testNameSpace} padding='Fluid' onClick={submitCloseAccount} loading={isLoading} kind='Error'>
            {t('account:closeAccount:closeAccount:confirmCloseAccount:cta')}
          </Button>
        </div>
        <TrackOnMount eventName={TrackingEvent.VIEWED_CLOSE_ACCOUNT_CONFIRM} />
      </Modal.Footer>
    </>
  )
}
