import { InitiateThreeDSErrorCode, InitiateThreeDSResponse, ResultCode, ThreeDSWorkflows } from '@afterpay/types'
import { NewCard } from '@afterpaytouch/topaz-api'
import { useFlag } from '../../hooks'
import { useTranslation } from 'next-i18next'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { useFinaliseThreeDSMutation, useInitiateThreeDSMutation } from '../../state/threeDS/hooks'
import { isCardScanRequired } from '../../state/cardScan/utils'
import { app } from '../../env'
import type { ConfirmNewRequest, CreditCard, InitiateNewResponse, SupportedLocale } from '@afterpaytouch/portal-api'
import { useToast } from '@afterpaytouch/core'
import { CardScanCheckpoint, ErrorObject } from '../../model'
import {
  useConfirmNewMutation,
  useConsumerSelector,
  useInitiateNewMutation,
  useSetupOrderMutation,
  setCardScanCard,
  setCardScanCheckpoint,
  activateCardScan,
  useAppDispatch,
} from '../../state'
import { useRouter } from 'next/router'
import { CardFormSchema } from './CardForm'
import { deviceDetails } from '../../utils/device'
import { isValidObject } from '../../utils/object'
import { mapNewCardToTopaz } from '../../state/topaz/utils'
import { maskCreditCardNumber } from '../../utils/card'
import { titleCase } from '@afterpay/utils'
import { billingAddressFFFallback } from '../../utils/ldFallback'

const I18N_NAMESPACE = ['account']

export interface ThreeDsProps {
  initiateCardData: InitiateNewResponse
  confirmCard: (storeToken?: boolean) => Promise<void>
}

export interface ThreeDSReturn {
  threeDSError: string | null
  setThreeDSError: Dispatch<SetStateAction<string | null>>
  threeDSOnClose: () => void
  checkThreeDS: (traceId: string, storeToken?: boolean) => Promise<void>
  showModalCloseButton: boolean
  setShowModalCloseButton: Dispatch<SetStateAction<boolean>>
  showThreeDSIframe: boolean
  threeDSIframeURL: string
}

export const useThreeDS = ({ initiateCardData, confirmCard }: ThreeDsProps): ThreeDSReturn => {
  const { t } = useTranslation(I18N_NAMESPACE)

  const [initiateThreeDSVerification, { data: initiate3DsData }] = useInitiateThreeDSMutation()
  const [finaliseThreeDSVerification] = useFinaliseThreeDSMutation()
  const [showThreeDSIframe, setShowThreeDSIframe] = useState<boolean>(false)
  const [threeDSIframeURL, setThreeDSIframeURL] = useState<string>('')
  const isThreeDSEnabled = useFlag('consumer-portal-three-ds-enabled')
  const [threeDSError, setThreeDSError] = useState<string | null>(null)
  const [showModalCloseButton, setShowModalCloseButton] = useState<boolean>(true)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  /**
   * Re-register event listener when state changes
   */
  useEffect(() => {
    if (Boolean(initiate3DsData?.challengeRequired) && isThreeDSEnabled) {
      window.addEventListener('message', receiveMessage, false)
    }

    return () => {
      window.removeEventListener('message', receiveMessage, false)
      setThreeDSError(null)
    }
  }, [initiate3DsData, isThreeDSEnabled])

  /* Listen for window.message event when user completes ThreeDS challenge
   *  check if the redirect url is one of the AP urls
   *  call finalise API
   *  returns IIFE because eventListener expect void instead of Promise<void>
   * */
  const receiveMessage = (event): void => {
    // eslint-disable-next-line prettier/prettier
    ;(async () => {
      let messageData

      try {
        messageData = JSON.parse(event.data)
      } catch {
        messageData = event?.data
      }

      if (messageData?.paymentsThreeDs?.userComplete === 'true' && event.origin === app.THREE_DS_REDIRECT_URL) {
        const { traceId } = initiateCardData
        // @ts-ignore: OPERATION BLEED STOPPER
        const { threeDSId } = initiate3DsData

        window.removeEventListener('message', receiveMessage, false) // Remove the event listener after user complete

        // At this step not allow consumer to exit the flow
        setShowModalCloseButton(false)
        try {
          await finaliseThreeDSVerification({
            threeDSId,
            traceID: traceId,
            workflow: ThreeDSWorkflows.BILLING,
          }).unwrap()

          logEvent(TrackingEvent.NEW_CARD_ADDED_3DS_PASSED, {
            traceId,
          })
          setShowThreeDSIframe(false)

          await confirmCard()
        } catch (error) {
          setThreeDSError(t('account:tab:payment-methods:threeDS:errors:paymentRequired'))
          setShowThreeDSIframe(false)
          logEvent(TrackingEvent.NEW_CARD_ADDED_3DS_FAILED, {
            error: error?.data?.errorCode,
          })
        }
      }
    })()
  }

  /*
   * If threeDS flag is on call initiate threeDS API
   * Flag should not be enabled for countries that doesn't need it.
   * */
  const checkThreeDS = async (traceId: string, storeToken: boolean = false): Promise<void> => {
    try {
      const response: InitiateThreeDSResponse = await initiateThreeDSVerification({
        traceID: traceId,
        saveCard: true,
        workflow: ThreeDSWorkflows.BILLING,
      }).unwrap()

      if (response?.challengeRequired) {
        // @ts-ignore: OPERATION BLEED STOPPER
        setThreeDSIframeURL(response?.challengeUri)
        setShowThreeDSIframe(true)
        logEvent(TrackingEvent.VIEWED_3DS_VERIFICATION_INITIATE, {
          challengeRequired: true,
        })
      } else {
        logEvent(TrackingEvent.VIEWED_3DS_VERIFICATION_INITIATE, {
          challengeRequired: false,
        })
        await confirmCard(storeToken)
      }
    } catch (error) {
      // TODO: map error code if something happen in ConfirmCard as server error
      /* eslint-disable no-unsafe-optional-chaining */
      if ('errorCode' in error?.data) {
        /* eslint-disable no-unsafe-optional-chaining */
        const { httpStatusCode, errorCode } = error?.data
        logEvent(TrackingEvent.VIEWED_3DS_VERIFICATION_INITIATE_FAILED, {
          error: errorCode,
        })
        switch (httpStatusCode) {
          case InitiateThreeDSErrorCode.PAYMENT_REQUIRED:
            setThreeDSError(t('account:tab:payment-methods:threeDS:errors:paymentRequired'))
            break
          case InitiateThreeDSErrorCode.PRECONDITION_FAILED:
            setThreeDSError(t('account:tab:payment-methods:threeDS:errors:preConditionFailed'))
            break
        }
      }
    }
  }

  const threeDSOnClose = (): void => {
    setShowThreeDSIframe(false)
    setThreeDSError(t('account:tab:payment-methods:threeDS:errors:userCancelled'))
    window.removeEventListener('message', receiveMessage, false) // Remove the event listener when the user cancel
  }

  return {
    threeDSError,
    setThreeDSError,
    threeDSOnClose,
    checkThreeDS,
    showModalCloseButton,
    setShowModalCloseButton,
    showThreeDSIframe,
    threeDSIframeURL,
  }
}

interface AddCardProps {
  callback: (paymentMethod?: CreditCard) => void
}

interface AddCardReturn {
  serverError: string
  isConfirmError: boolean
  isInitiateError: boolean
  isTopazError: boolean
  isLoading: boolean
  handleSubmit: (data: CardFormSchema) => Promise<void>
  resetState: () => void
  threeDS: Omit<ThreeDSReturn, 'setShowModalCloseButton' | 'checkThreeDS' | 'setThreeDSError'>
}

export const useAddCard = ({ callback }: AddCardProps): AddCardReturn => {
  const [initiateNewCard, { data: initiateCardData, isError: isInitiateError, isLoading: isInitiatingNewCard }] = useInitiateNewMutation()
  const [confirmNewCard, { isError: isConfirmError, isLoading: isConfirmingCard }] = useConfirmNewMutation()
  const [setupTopazOrder, { isError: isTopazError, isLoading: isSettingTopaz }] = useSetupOrderMutation()
  const hasInitiated = Boolean(initiateCardData?.traceId)
  const [threeDSReady, setThreeDSReady] = useState(false)
  const [isConfirmPaymentMethodError, setIsConfirmPaymentMethodError] = useState<boolean>(false)
  const isLoading = useMemo(() => {
    return isInitiatingNewCard || isConfirmingCard || isSettingTopaz
  }, [isInitiatingNewCard, isConfirmingCard, isSettingTopaz])
  const setToast = useToast()
  const [serverErrorCode, setServerErrorCode] = useState<ErrorObject | null>(null)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const isThreeDSEnabled = useFlag('consumer-portal-three-ds-enabled')
  const isAddPaymentCardScanEnabled = useFlag('card-scanning-new-consumer-portal-add-payment-enabled')
  const consumerData = useConsumerSelector()
  const router = useRouter()
  const locale = router?.locale as SupportedLocale
  const needAddressDetails = useFlag('billing-address-details', billingAddressFFFallback(locale))
  const { t } = useTranslation(I18N_NAMESPACE)
  const [confirmNewParam, setConfirmNewParam] = useState<null | ConfirmNewRequest>(null)
  const dispatch = useAppDispatch()
  const initiateCard = useCallback(async (): Promise<void> => {
    try {
      await initiateNewCard({
        deviceDetails: deviceDetails(),
      }).unwrap()
    } catch (e) {
      setServerErrorCode({ errorCode: e?.data?.errorCode ?? 'unspecified_error' })
    }
  }, [initiateNewCard])

  // Confirm add card
  const confirmCard =
    (confirmNewRequest: ConfirmNewRequest): (() => Promise<void>) =>
    async (): Promise<void> => {
      try {
        const confirmCard = await confirmNewCard(confirmNewRequest).unwrap()
        setToast({
          message: t('account:tab:payment-methods:modal:form:success', {
            paymentMethod: `${titleCase(confirmCard.cardBrand)} ${maskCreditCardNumber(confirmCard.truncatedNumber ?? confirmCard.maskedPan)}`,
          }),
          kind: 'Success',
          testNameSpace: 'add-card-success',
        })
        // @ts-ignore: OPERATION BLEED STOPPER
        logEvent(TrackingEvent.VIEWED_ADD_PAYMENT_METHOD_SUCCESS_TOAST, { locale: router?.locale })
        resetState()
        callback(confirmCard)
      } catch (e) {
        if (isCardScanRequired(e) && isAddPaymentCardScanEnabled) {
          dispatch(activateCardScan(confirmNewRequest.traceId, confirmCard(confirmNewRequest)))
          setIsConfirmPaymentMethodError(false)
        } else {
          setServerErrorCode({ errorCode: e?.data?.errorCode ?? 'unspecified_error' })
          setIsConfirmPaymentMethodError(true)
        }
      }
    }
  const { setThreeDSError, threeDSError, checkThreeDS, showModalCloseButton, setShowModalCloseButton, showThreeDSIframe, threeDSIframeURL, threeDSOnClose } =
    useThreeDS({
      // @ts-ignore: OPERATION BLEED STOPPER
      initiateCardData,
      // @ts-ignore: OPERATION BLEED STOPPER
      confirmCard: confirmCard(confirmNewParam),
    })

  const resetState = useCallback((): void => {
    setThreeDSError(null)
    setServerErrorCode(null)
    setConfirmNewParam(null)
    setThreeDSReady(false)

    if (isConfirmPaymentMethodError) {
      setIsConfirmPaymentMethodError(false)
      initiateCard()
    }
  }, [isConfirmPaymentMethodError])

  /**
   * Clear any error on unmount
   */
  useEffect(() => {
    if (!hasInitiated) {
      initiateCard()
    }
    return () => {
      resetState()
    }
  }, [])

  /**
   * Run threeds only when it's ready, it means when the confirm new card param is populated
   */
  useEffect(() => {
    const runThreeDS = async (): Promise<void> => {
      try {
        // @ts-ignore: OPERATION BLEED STOPPER
        await checkThreeDS(initiateCardData?.traceId)
      } catch (e) {}
    }
    if (threeDSReady && confirmNewParam !== null) {
      runThreeDS()
    }
  }, [threeDSReady, initiateCardData, confirmNewParam])

  /**
   * Form submission handler
   * @param data
   */
  const handleSubmit = async (data: CardFormSchema): Promise<void> => {
    logEvent(TrackingEvent.CLICKED_ADD_PAYMENT_METHOD, { outboundLink: router?.pathname })
    setShowModalCloseButton(true)
    const traceId = initiateCardData?.traceId
    if (traceId !== undefined) {
      try {
        const addressData = !isValidObject(data?.address) ? consumerData?.contactAddress : data.address
        // @ts-ignore: OPERATION BLEED STOPPER
        const topazParam: NewCard = mapNewCardToTopaz(data.card, needAddressDetails ? addressData : undefined)
        const topazResponse = await setupTopazOrder({
          ...topazParam,
          traceId,
        }).unwrap()

        // Topaz always returns 200, so we need to check the result code
        if (topazResponse?.resultCode === ResultCode.OK) {
          const confirmNewParam = {
            cardholderName: data.card?.cardHolderName,
            traceId,
            preferred: false,
          }
          if (isThreeDSEnabled) {
            setConfirmNewParam(confirmNewParam)
            setThreeDSReady(true)
          } else {
            await confirmCard(confirmNewParam)()
          }

          // save data.card in cardScan state
          dispatch(
            setCardScanCard({
              cardNumber: data.card?.cardNumber,
              expiryDate: data.card?.expiryDate,
            })
          )
          dispatch(setCardScanCheckpoint(CardScanCheckpoint.AddPaymentWeb))
        } else {
          if (topazResponse?.resultCode === ResultCode.DUPLICATE_CARD) {
            setServerErrorCode({ errorCode: 'duplicate_card' })
          } else if (topazResponse?.resultCode === ResultCode.UNACCEPTABLE_CARD) {
            setServerErrorCode({ errorCode: 'card_not_accepted' })
          } else {
            setServerErrorCode({ errorCode: 'unspecified_error' })
          }
        }
      } catch (e) {
        setServerErrorCode({ errorCode: e?.data?.errorCode ?? 'unspecified_error' })
      }
    }
  }

  const serverError = useMemo(
    () =>
      serverErrorCode?.errorCode === undefined
        ? null
        : t(
            `account:tab:payment-methods:modal:form:error:server:${serverErrorCode.errorCode}`,
            t('account:tab:payment-methods:modal:form:error:server:unspecified_error'),
            serverErrorCode?.options
          ),
    [serverErrorCode, t]
  )

  return {
    // @ts-ignore: OPERATION BLEED STOPPER
    serverError,
    isConfirmError,
    isInitiateError,
    isTopazError,
    isLoading,
    handleSubmit,
    resetState,
    threeDS: {
      threeDSError,
      showModalCloseButton,
      showThreeDSIframe,
      threeDSIframeURL,
      threeDSOnClose,
    },
  }
}
