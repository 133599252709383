import React, { FunctionComponent, useContext } from 'react'
import { OrderRefundModalContext, OrderRefundSteps, OrderRefundStepsProps } from '../OrderRefundModalContext'
import { OrderRefundNavigation } from '../OrderRefundNavigation'
import { Modal, Text, Link, Icon } from '@afterpaytouch/core'
import { useConsumerSizes } from '../../../utils/responsive'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import Image from 'next/image'
import { TrackingEvent } from '../../../model/amplitude'
import { useTranslation, Trans } from 'next-i18next'
import { AmplitudeEventTypesProperties } from '../../../integrations/amplitude/types'
import { useRouter } from 'next/router'
import { useDisplayIllustrations } from '../../../state/convergence/hooks'

const I18N_NAMESPACE = ['orders', 'common']

interface ReturnTypeProps {
  reopenGetHelpModal: () => void
}

enum ReturnTypes {
  Mail = 'Mail',
  InStore = 'InStore',
}

interface ReturnTypeButtonProps {
  type: ReturnTypes
  alt: string
  width?: number
  height?: number
  text: string
  step: OrderRefundStepsProps
  ampEvent: keyof AmplitudeEventTypesProperties
  testid: string
}

const ReturnTypeButton: FunctionComponent<ReturnTypeButtonProps> = ({ type, alt, width = 72, height = 72, text, step, ampEvent, testid }) => {
  const { setStep } = useContext(OrderRefundModalContext)
  const { textSize } = useConsumerSizes()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const isDisplayIllustrations = useDisplayIllustrations()
  const router = useRouter()

  return (
    <div className='mt-4 flex w-[48%] items-center justify-center'>
      <button
        onClick={() => {
          setStep(step)
          logEvent(ampEvent, { locale: router?.locale })
        }}
        type='button'
        className='hover:border-primary border-dark bg-light cursor-pointer rounded-xl border border-solid outline-none sm:h-[144px] sm:w-[144px] md:h-[168px] md:w-[168px]'
        data-testid={`return-type-modal-${testid}`}
        aria-label={text}
      >
        {isDisplayIllustrations ? (
          <Image
            src={`/images/${type === ReturnTypes.Mail ? 'icon-mail' : 'icon-instore'}.svg`}
            width={width}
            height={height}
            alt={alt}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        ) : (
          <Icon name={type === ReturnTypes.Mail ? 'Box' : 'CategoryShopping'} size='Large' />
        )}
        <div className='mt-5'>
          <Text size={textSize} bold renderAs='span'>
            {text}
          </Text>
        </div>
      </button>
    </div>
  )
}

export const ReturnType: FunctionComponent<ReturnTypeProps> = ({ reopenGetHelpModal }) => {
  const { closeModal } = useContext(OrderRefundModalContext)
  const { textSize } = useConsumerSizes()
  const { t } = useTranslation(I18N_NAMESPACE)

  const returnToGetHelpModal = (): void => {
    closeModal()
    reopenGetHelpModal()
  }

  return (
    <>
      <OrderRefundNavigation heading={t('orders:returningOrderModal:returnType:title')} handleBack={returnToGetHelpModal} />
      <Modal.Content>
        <Text size={textSize} testNameSpace='return-type-modal-body-text'>
          {t('orders:returningOrderModal:returnType:body1')}
        </Text>
        <br />
        <Text size={textSize} testNameSpace='return-type-modal-body-text'>
          <Trans
            i18nKey='orders:returningOrderModal:returnType:body2'
            components={{
              zdHelpLink: <Link href={t('common:urls:returningOrderModal:zdLink')} kind='Underline' target='_blank' bold={false} />,
            }}
          />
        </Text>
        <br />
        <Text size={textSize} bold testNameSpace='return-type-modal-return-cta'>
          {t('orders:returningOrderModal:returnType:cta')}
        </Text>
        <div className='flex justify-between'>
          <ReturnTypeButton
            type={ReturnTypes.Mail}
            step={OrderRefundSteps.MailReturnDetails}
            text={t('orders:returningOrderModal:returnType:mailButton')}
            alt={t('orders:returningOrderModal:alt', { title: t('orders:returningOrderModal:returnType:mailButton') })}
            ampEvent={TrackingEvent.CLICKED_RETURNING_ORDER_BY_MAIL}
            testid='mail'
          />
          <ReturnTypeButton
            type={ReturnTypes.InStore}
            step={OrderRefundSteps.InstoreReturn}
            text={t('orders:returningOrderModal:returnType:storeButton')}
            alt={t('orders:returningOrderModal:alt', { title: t('orders:returningOrderModal:returnType:storeButton') })}
            ampEvent={TrackingEvent.CLICKED_RETURNING_ORDER_IN_STORE}
            testid='instore'
          />
        </div>
      </Modal.Content>
    </>
  )
}
