// 'catchAll' locale is currently a workaround to allow URL prefix for the default locale
// See https://github.com/vercel/next.js/discussions/18419#discussioncomment-327128

const locales = [
  {
    redirect: 'au',
    locale: 'en-AU',
    code: 'AU',
  },
  {
    redirect: 'nz',
    locale: 'en-NZ',
    code: 'NZ',
  },
  {
    redirect: 'us',
    locale: 'en-US',
    code: 'US',
  },
  {
    redirect: 'uk',
    locale: 'en-GB',
    code: 'GB',
  },
  {
    redirect: 'ca',
    locale: 'en-CA',
    code: 'CA',
  },
  {
    redirect: 'ca',
    locale: 'fr-CA',
    code: 'CA',
  },
  {
    redirect: 'es',
    locale: 'es-ES',
    code: 'ES',
  },
  {
    redirect: 'fr',
    locale: 'fr-FR',
    code: 'FR',
  },
  {
    redirect: 'it',
    locale: 'it-IT',
    code: 'IT',
  },
]

// @TODO: this function may needs to be removed
const mapLocale = (fromKey, fromvalue) => {
  const toKey = fromKey === 'redirect' ? 'locale' : 'redirect'
  return locales.filter((localeObj) => {
    return localeObj[fromKey] === fromvalue
  })[0][toKey]
}
module.exports = {
  supportedLocales: [...locales.map((locale) => locale.locale), 'catchAll'],
  locales,
  mapLocale,
}
