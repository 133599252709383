import { BankAccountDetails } from '@afterpay/types'
import React, { createContext, Dispatch, FunctionComponent, SetStateAction, useContext, useState, ReactNode } from 'react'

export const noop = (): void => {}

export const AchFlowSteps = {
  SelectPaymentMethodType: 'SelectPaymentMethodType',
  AddCard: 'AddCard',
  AddBankAccount: 'AddBankAccount',
  CreatePlaid: 'CreatePlaid',
  RelinkPlaid: 'RelinkPlaid',
  LinkBankAccount: 'LinkBankAccount',
} as const

type AchStepsType = typeof AchFlowSteps
type AchStepsKeys = keyof AchStepsType
export type AchStepsProps = AchStepsType[AchStepsKeys]

export interface AchFlowResult {
  success: boolean
  error: boolean
  httpStatusCode?: number
}

export interface AchFlowModalContextProps {
  initialStep: AchStepsProps
  step: AchStepsProps
  setStep: Dispatch<SetStateAction<AchStepsProps>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  isFetching: boolean
  setIsFetching: Dispatch<SetStateAction<boolean>>
  eligible: boolean
  setEligible: Dispatch<SetStateAction<boolean>>
  linkToken: string
  setLinkToken: Dispatch<SetStateAction<string>>
  publicToken: string
  setPublicToken: Dispatch<SetStateAction<string>>
  result: AchFlowResult
  setResult: Dispatch<SetStateAction<AchFlowResult>>
  resetState: () => void
  closeModal: () => void
  showCloseButton: boolean
  setShowCloseButton: Dispatch<SetStateAction<boolean>>
  bankAccountToBeRelinked: BankAccountDetails
  achFlowEntryDeepLink: string
}

const defaultStep = AchFlowSteps.SelectPaymentMethodType
export const defaultResult = { success: false, error: false }
export const achFlowModalContextDefaults: AchFlowModalContextProps = {
  initialStep: defaultStep,
  step: defaultStep,
  setStep: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isLoading: null, // Is data loading for first time, used to show modal loader
  setIsLoading: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isFetching: null, // Is data fetching (are any endpoints in flight), used in business logic and button states
  setIsFetching: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  eligible: null,
  setEligible: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  linkToken: null,
  setLinkToken: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  publicToken: null,
  setPublicToken: noop,
  result: defaultResult,
  setResult: noop,
  resetState: noop,
  closeModal: noop,
  showCloseButton: true,
  setShowCloseButton: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  bankAccountToBeRelinked: null,
  // @ts-ignore: OPERATION BLEED STOPPER
  achFlowEntryDeepLink: null,
  oauthStateId: null,
}

export const AchFlowModalContext = createContext<AchFlowModalContextProps>(achFlowModalContextDefaults)

export const useAchFlowModalContext = (): AchFlowModalContextProps => {
  return { ...useContext(AchFlowModalContext) }
}

interface AchFlowModalProviderProps {
  closeModal: () => void
  initialStep?: AchStepsProps
  initialEligible?: boolean
  bankAccountToBeRelinked?: BankAccountDetails
  achFlowEntryDeepLink?: string
  children: ReactNode
}

export const AchFlowModalProvider: FunctionComponent<AchFlowModalProviderProps> = ({
  closeModal,
  children,
  initialStep = defaultStep,
  initialEligible,
  bankAccountToBeRelinked,
  achFlowEntryDeepLink,
}) => {
  const [step, setStep] = useState<AchStepsProps>(initialStep)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [result, setResult] = useState<AchFlowResult>(defaultResult)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [eligible, setEligible] = useState<boolean>(initialEligible)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [linkToken, setLinkToken] = useState<string>(null)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [publicToken, setPublicToken] = useState<string>(null)
  const [showCloseButton, setShowCloseButton] = useState<boolean>(true)

  const resetState = (): void => {
    setStep(defaultStep)
    setIsLoading(false)
    setIsFetching(false)
    setResult(defaultResult)
    // @ts-ignore: OPERATION BLEED STOPPER
    setEligible(null)
    // @ts-ignore: OPERATION BLEED STOPPER
    setLinkToken(null)
    // @ts-ignore: OPERATION BLEED STOPPER
    setPublicToken(null)
    setShowCloseButton(true)
  }

  const value = {
    initialStep,
    step,
    setStep,
    isLoading,
    setIsLoading,
    isFetching,
    setIsFetching,
    eligible,
    setEligible,
    linkToken,
    setLinkToken,
    publicToken,
    setPublicToken,
    result,
    setResult,
    resetState,
    closeModal,
    showCloseButton,
    setShowCloseButton,
    bankAccountToBeRelinked,
    achFlowEntryDeepLink,
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  return <AchFlowModalContext.Provider value={value}>{children}</AchFlowModalContext.Provider>
}
