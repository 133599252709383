import { Button, useModalDeepLink, useToast } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'
import { useSearchParam, useFlag } from '../../hooks'
import { useConsumerIsDeactivated, useGetAccountCloseEligibleQuery, useLogout } from '../../state'
import { CloseAccountFlowModal as DeactivateAccountFlowModal } from '../DeactivateAccount/CloseAccountFlow'
import { ReactivateAccountFlow } from '../ReactivateAccountFlow'
import { useCloseAccountModal, useReactivateAccountModal } from './hooks'
import { stringToBoolean } from '@afterpay/utils'
import { CloseAccountFlowModal } from '../CloseAccount/CloseAccountFlow'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'

export const CloseOrReactivateAccountButton: FunctionComponent = () => {
  const I18N_NAMESPACE = ['common', 'account']
  const { t } = useTranslation(I18N_NAMESPACE)
  const setToast = useToast()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const logout = useLogout()

  const isConsumerDeactivated = useConsumerIsDeactivated()
  const { data: eligibleData } = useGetAccountCloseEligibleQuery()
  const buttonText = isConsumerDeactivated ? t('account:reactivateAccount:button') : t('account:closeAccount:button')

  const [isCloseAccountModalOpen, { openModal: openCloseAccountModal, closeModal: closeCloseAccountModal }] = useCloseAccountModal()
  const isAccountClosureEnabled = useFlag('consumer-portal-account-close', false)
  const coraModalId = 'reactivate-account'
  const coraModalSearchParam = stringToBoolean(useSearchParam(`modal-${coraModalId}`) ?? '')
  const [isReactivateAccountModalOpen, { openModal: openReactivateAccountModal, closeModal: closeReactivateAccountModal }] =
    useReactivateAccountModal(coraModalSearchParam)
  useModalDeepLink(isReactivateAccountModalOpen, coraModalId)

  const openModal = (): void => {
    isConsumerDeactivated ? openReactivateAccountModal() : openCloseAccountModal()
    if (!isConsumerDeactivated) {
      logEvent(TrackingEvent.PRESSED_CLOSE_MY_ACCOUNT, {
        isAccountClosureEnabled,
      })
    }
  }
  const testNameSpace = 'close-or-reactivate-account'
  const isConsumerEligibleToCloseAccount = eligibleData?.eligible ?? false
  return (
    <>
      <Button.Link size='Regular' aria-label={buttonText} onClick={openModal} testNameSpace={testNameSpace}>
        {buttonText}
      </Button.Link>
      <ReactivateAccountFlow
        closeModal={() => {
          closeReactivateAccountModal()
        }}
        show={isReactivateAccountModalOpen}
        onSuccess={() => {
          setToast({
            message: t('account:reactivateAccount:confirmInfo:toastMessage'),
            kind: 'Success',
            manualDismiss: true,
            swipeToDismiss: true,
            testNameSpace: 'reactivate-account-success',
          })
        }}
      />

      {/* // TODO clean this code once it's fully rollout https://apglobal.atlassian.net/browse/IDENTITY-395 */}
      {isAccountClosureEnabled ? (
        <CloseAccountFlowModal
          onSuccess={() => {
            logout()
          }}
          isConsumerEligibleToCloseAccount={isConsumerEligibleToCloseAccount}
          show={isCloseAccountModalOpen}
          closeModal={closeCloseAccountModal}
        />
      ) : (
        <DeactivateAccountFlowModal closeModal={closeCloseAccountModal} show={isCloseAccountModalOpen} />
      )}
    </>
  )
}
