import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Icon, IconColors } from '@afterpaytouch/core'
import ReactPaginate from 'react-paginate'
import styles from './style.module.scss'
import { GetOrderTransactionsQuery } from '@afterpaytouch/portal-api/consumer/ordertransactions'

type OnSelectType = (arg: GetOrderTransactionsQuery) => void
type AmplitudeFnType = (pageNumber: number) => void

interface PaginateProps {
  limit: number
  offset: number
  total: number
  onSelect: OnSelectType
  amplitudeFn?: AmplitudeFnType
}

const I18N_NAMESPACE = ['common']

export const Paginate: FunctionComponent<PaginateProps> = ({ limit, offset, total, onSelect, amplitudeFn }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const pageCount: number = Math.ceil(total / limit)
  const [pageOffset, setPageOffset] = useState(offset)
  const [currentPage, setCurrentPage] = useState(0)
  const [prevIconColor, setPrevIconColor] = useState<IconColors>('Gray80')
  const [nextIconColor, setNextIconColor] = useState<IconColors>('Black')

  useEffect(() => {
    setPrevIconColor(currentPage === 0 ? 'Gray80' : 'Black')
    setNextIconColor(currentPage + 1 === pageCount ? 'Gray80' : 'Black')
    onSelect({ offset: pageOffset })
  }, [currentPage, pageOffset])

  const handlePageSelect = (data): void => {
    setCurrentPage(data.selected)
    setPageOffset(data.selected * limit)
    // @ts-ignore: OPERATION BLEED STOPPER
    amplitudeFn(data.selected)
  }

  return (
    <div>
      <ReactPaginate
        previousLabel={<Icon.CaretLeft title={t('common:paginate:previousTitle')} size={'ExtraSmall'} color={prevIconColor} role='img' />}
        nextLabel={<Icon.CaretRight title={t('common:paginate:nextTitle')} size={'ExtraSmall'} color={nextIconColor} role='img' />}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageSelect}
        containerClassName={styles.container}
        pageClassName={styles.pageItem}
        pageLinkClassName={styles.pageLink}
        nextClassName={styles.bookend}
        nextLinkClassName={styles.bookendLink}
        previousClassName={styles.bookend}
        previousLinkClassName={styles.bookendLink}
        breakClassName={styles.pageItem}
        breakLinkClassName={styles.pageLink}
        activeClassName={'bg-primary'}
      />
    </div>
  )
}
