import { Text, Link, Divider, Skeleton } from '@afterpaytouch/core'
import { CreditFacilityDocument } from '@afterpaytouch/portal-api'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent } from 'react'

const I18N_NAMESPACE = ['common', 'payMonthly']

export const PayMonthlyCreditContract: FunctionComponent<{ creditContract?: CreditFacilityDocument; isLoading: boolean }> = ({ creditContract, isLoading }) => {
  const { t } = useTranslation(I18N_NAMESPACE)

  const hasCreditContract = typeof creditContract !== 'undefined'
  return (
    <>
      <div className='relative'>
        {hasCreditContract && creditContract.url !== null && (
          <div className='flex justify-between' data-testid='pay-monthly-panel-creditContract-row'>
            <Text bold>{t('payMonthly:panel:content:details:creditContract')}</Text>
            <Link kind='Underline' href={creditContract.url} target='_blank' rel='noopener noreferrer' size='S'>
              {t('payMonthly:panel:content:details:view')}
            </Link>
          </div>
        )}
        {isLoading && <Skeleton testNameSpace='pay-monthly-panel-creditContract-skeleton' />}
      </div>
      <Divider kind='Hairline' margin='Regular' />
    </>
  )
}
