import React, { FunctionComponent, ReactNode, useCallback, useContext, useEffect } from 'react'
import { Modal } from '@afterpaytouch/core'
import { ReactivateAccountFlowContext, ReactivateAccountFlowModalProvider, ReactivateAccountFlowSteps } from './ReactivateAccountFlowContext'
import { Detail } from './Detail'
import { ConfirmInfo } from './ConfirmInfo'
import { EditAddressModalContent } from '../EditAddressModal'

export interface ReactivateAccountFlowProps {
  show: boolean
  closeModal: () => void
  onSuccess: () => void
}

export const ReactivateAccountSteps: FunctionComponent<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const { step, show, isFlowSuccess, closeModal, resetState, setStep } = useContext(ReactivateAccountFlowContext)

  const renderStep = useCallback((): ReactNode => {
    switch (step) {
      case ReactivateAccountFlowSteps.Info:
        return <Detail />
      case ReactivateAccountFlowSteps.ConfirmInfo:
        return <ConfirmInfo onSuccess={onSuccess} />
      case ReactivateAccountFlowSteps.EditAddress:
        return (
          <EditAddressModalContent
            closeModal={() => {
              setStep(ReactivateAccountFlowSteps.ConfirmInfo)
            }}
            handleBack={() => {
              setStep(ReactivateAccountFlowSteps.ConfirmInfo)
            }}
          />
        )
    }
  }, [step, onSuccess, setStep])

  useEffect(() => {
    if (!show) {
      resetState()
    }
  }, [show, resetState])

  const handleClose = (): void => {
    closeModal()
    if (isFlowSuccess) {
      onSuccess()
    }
  }
  return (
    <Modal show={show} onClose={handleClose} onHide={handleClose} onEscapeKeyDown={handleClose} testNameSpace='reactivate-account-flow'>
      {renderStep()}
    </Modal>
  )
}

export const ReactivateAccountFlow: FunctionComponent<ReactivateAccountFlowProps> = ({ show, closeModal, onSuccess }) => {
  return (
    <ReactivateAccountFlowModalProvider show={show} closeModal={closeModal}>
      <ReactivateAccountSteps onSuccess={onSuccess} />
    </ReactivateAccountFlowModalProvider>
  )
}
