import { useEffect } from 'react'
import { createModalHook, useFlag } from '../../hooks'
import { useConsumerHasRestrictedAccount, useCreateConsumerTagMutation, useGetConsumerTagsWithDataQuery, useUpdateConsumerTagMutation } from '../../state'

export const useAlignPayIntroModal = createModalHook()

export type OpenAlignPaydayIntroModal = ReturnType<typeof useAlignPayIntroModal>[1]['openModal']

export const useShowAlignPayIntroModalOnMount = (openAlignPaydayIntroModal: OpenAlignPaydayIntroModal): void => {
  const isAlignPaydayEnabled = useFlag('portal-align-payday-enabled')
  const { data: consumerTags, isSuccess: isGetConsumerTagsSuccesss } = useGetConsumerTagsWithDataQuery()
  const isAlignPaydayAndGetTagSuccess = isAlignPaydayEnabled && isGetConsumerTagsSuccesss

  const hasSeenAlignPaydayModal = consumerTags?.['portal.consumer-has-seen-align-payday-modal'] === String(true)
  const hasSeenAlignPaydayModalDataReturned = consumerTags?.['portal.consumer-has-seen-align-payday-modal'] !== undefined

  const consumerHasRestrictedAccount = useConsumerHasRestrictedAccount()

  const [createConsumerTag] = useCreateConsumerTagMutation()
  const [updateConsumerTag] = useUpdateConsumerTagMutation()

  useEffect(() => {
    if (!consumerHasRestrictedAccount) {
      if (isAlignPaydayAndGetTagSuccess && !hasSeenAlignPaydayModalDataReturned) {
        openAlignPaydayIntroModal()
        createConsumerTag({ body: { name: 'portal.consumer-has-seen-align-payday-modal', data: String(true) } })
      }

      if (isAlignPaydayAndGetTagSuccess && !hasSeenAlignPaydayModal && hasSeenAlignPaydayModalDataReturned) {
        openAlignPaydayIntroModal()
        updateConsumerTag({ body: { name: 'portal.consumer-has-seen-align-payday-modal', data: String(true) } })
      }
    }
  }, [hasSeenAlignPaydayModal, isAlignPaydayAndGetTagSuccess, hasSeenAlignPaydayModalDataReturned, consumerHasRestrictedAccount])
}
