import { useState, useEffect, useRef } from 'react'
import { useModalDeepLink } from '@afterpaytouch/core'
import { stringToBoolean } from '@afterpay/utils/string'
import { useGetBankAccountsQuery } from '../../state'
import { achOauthFlowSessionStorageInstance } from './BasePlaid'
import { AchFlowSteps, AchStepsProps } from './AchFlowModalContext'
import { createModalHook, useSearchParam } from '../../hooks'
import { SessionStorageActionType } from '../../utils/sessionStorageHelper'
import { ACH_FLOW_INITIAL_STEP } from '../../config/plaid'

interface UseACHModalReturn<T> {
  achFlowInitialStep: AchStepsProps
  setAchFlowInitiateStep: React.Dispatch<React.SetStateAction<AchStepsProps>>
  isAchFlowModalOpen: boolean
  openAchFlowModal: () => void
  closeAchFlowModal: () => void
  bankData: any // Replace `any` with the appropriate type for bank data
  isLoadingBankData: boolean
  isFetchingBankData: boolean
  isBankError: boolean
  refetchBank: () => void
  isAchSuccess: boolean
  setIsAchSuccess: React.Dispatch<React.SetStateAction<boolean>>
  segmentValue: string | number | T
  // segmentValue: T
  setSegmentValue: React.Dispatch<React.SetStateAction<string | number | T>>
}

/**
 * ACH flow modal
 * parse query params from ach modal deep link to set initial state of ACH flow modal
 */

export const useACHModal = <T>(
  flag: boolean = true,
  achFlowModalName: string = 'add-bank-account',
  defaultSegmentValue: string | number | T = 0
): UseACHModalReturn<T> => {
  // @ts-ignore: OPERATION BLEED STOPPER
  const isACHFlowModalOpenParam = stringToBoolean(useSearchParam(`modal-${achFlowModalName}`))
  // if query param from modal deep link suggests the modal state is false or null, the initial step should not rely on localStorage
  const achFlowRedirectStep = isACHFlowModalOpenParam ? achOauthFlowSessionStorageInstance.getSessionStorageByItemKey(ACH_FLOW_INITIAL_STEP) : null
  const [isAchSuccess, setIsAchSuccess] = useState(false)
  const [segmentValue, setSegmentValue] = useState<string | number | T>(defaultSegmentValue)
  const [achFlowInitialStep, setAchFlowInitiateStep] = useState<AchStepsProps>((achFlowRedirectStep as AchStepsProps) ?? AchFlowSteps.SelectPaymentMethodType)
  const [isAchFlowModalOpen, { openModal: openAchFlowModal, closeModal: closeAchFlowModal }] = createModalHook()(isACHFlowModalOpenParam)
  useModalDeepLink(isAchFlowModalOpen, achFlowModalName)
  const isFirstRender = useRef(true)

  const {
    data: bankData,
    isLoading: isLoadingBankData,
    isFetching: isFetchingBankData,
    isError: isBankError,
    refetch: refetchBank,
  } = useGetBankAccountsQuery(undefined, { skip: !flag })

  useEffect(() => {
    // this is to prevent unexpected running of this useEffect when multiple components using useACHModal are on a same page
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    if (isAchSuccess && !isAchFlowModalOpen && flag) {
      refetchBank()
      setAchFlowInitiateStep(AchFlowSteps.SelectPaymentMethodType)
      setSegmentValue(defaultSegmentValue)
    }
    // for example: on order details page, both change payment method modal and pay now modal have useACHModal, when isAchFlowModalOpen = false
    // for change payment method modal, the following block will be exceted and all localStorage will be cleaned in the first render
    if (!isAchFlowModalOpen) {
      achOauthFlowSessionStorageInstance.handleSessionStorage(SessionStorageActionType.REMOVE)
      setAchFlowInitiateStep(AchFlowSteps.SelectPaymentMethodType)
    }
  }, [isAchSuccess, isAchFlowModalOpen, refetchBank])

  return {
    achFlowInitialStep,
    setAchFlowInitiateStep,
    isAchFlowModalOpen,
    openAchFlowModal,
    closeAchFlowModal,
    bankData,
    isLoadingBankData,
    isFetchingBankData,
    isBankError,
    refetchBank,
    isAchSuccess,
    setIsAchSuccess,
    segmentValue,
    setSegmentValue,
  }
}
