import { Divider, IconNames, Option, Text, BaseOptionProps, Link } from '@afterpaytouch/core'
import { Trans, useTranslation } from 'next-i18next'
import React, { FunctionComponent, useEffect } from 'react'
import { Form } from '../Form'
import { Route, verifyDocumentRoute } from '../../../config/router'
import { IdentityDocument } from '../../../model'
import { useConsumerCountry, useIsPartialVerified, useConsumerVerifiedIdentityDocuments } from '../../../state'
import styles from './styles.module.css'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { AmplitudeEventTypesProperties } from '../../../integrations/amplitude/types'
import { useAllowedDocumentsByCountry } from '../../../utils/id-check'

interface Props {
  onSelect: (route: Route) => void
}

const I18N_NAMESPACE = ['common', 'verify', 'verifyIdentity']

const icons: Record<IdentityDocument, IconNames> = {
  licence: 'Car',
  medicare: 'Heartbeat',
  passport: 'Globe',
}

export const VerifyIdentity: FunctionComponent<Props> = ({ onSelect }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const country = useConsumerCountry()
  const verifiedIdentityDocuments = useConsumerVerifiedIdentityDocuments()
  const isPartialVerified = useIsPartialVerified()
  const amplitude = useAmplitudeWithEnduringEventProperties()

  const getPressedButtonEventName = (doc: IdentityDocument): keyof AmplitudeEventTypesProperties => {
    return {
      licence: TrackingEvent.PRESSED_HARDID_LANDING_PAGE_DRIVERS_LICENCE_BUTTON,
      medicare: TrackingEvent.PRESSED_HARDID_LANDING_PAGE_MEDICARE_BUTTON,
      passport: TrackingEvent.PRESSED_HARDID_LANDING_PAGE_PASSPORT_BUTTON,
    }[doc]
  }

  const getPropsByDocument = (doc: IdentityDocument): BaseOptionProps => ({
    icon: icons[doc],
    text: t(`verifyIdentity:document.${doc}`),
    onClick: (e) => {
      e.preventDefault()
      amplitude.logEvent(getPressedButtonEventName(doc))
      onSelect(verifyDocumentRoute(doc))
    },
  })

  // @ts-ignore: OPERATION BLEED STOPPER
  const transformedAllowedDocument = useAllowedDocumentsByCountry(country).filter((doc) => !(verifiedIdentityDocuments?.includes(doc as any) ?? false))

  useEffect(() => {
    if (transformedAllowedDocument.length === 1) {
      onSelect(verifyDocumentRoute(transformedAllowedDocument[0], isPartialVerified))
    }
  }, [])

  return (
    <Form>
      <Text>{t('verifyIdentity:description')}</Text>
      <Divider kind='Dashed' />
      <Text bold>{t('verifyIdentity:chooseDocument')}</Text>

      <div className={styles.documentOptions}>
        {transformedAllowedDocument.map((doc) => {
          const key = `verify-identity-${doc}`
          return <Option key={key} testNameSpace={key} {...getPropsByDocument(doc)} />
        })}
      </div>

      <Text>
        <Trans
          i18nKey='verifyIdentity:help'
          components={{
            a: (
              <Link
                key='description-link'
                target='_blank'
                kind='Underline'
                href={t('common:urls:identityHelpUrl')}
                testNameSpace='verifyIdentity'
                onClick={() => {
                  amplitude.logEvent(TrackingEvent.PRESSED_HARDID_LANDING_PAGE_HELP_LINK)
                }}
              />
            ),
          }}
        />
      </Text>
    </Form>
  )
}
