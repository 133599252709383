import React, { SetStateAction, Dispatch, FunctionComponent, useContext, useState, createContext, useCallback, ReactNode } from 'react'
import { OrderResponse } from '@afterpaytouch/portal-api'

export const OrderRefundSteps = {
  ReturnType: 'ReturnType',
  MailReturnDetails: 'MailReturnDetails',
  MailReturnConfirm: 'MainReturnConfirm',
  InstoreReturn: 'InstoreReturn',
} as const

type OrderRefundStepsType = typeof OrderRefundSteps
type OrderRefundStepsKeys = keyof OrderRefundStepsType
export type OrderRefundStepsProps = OrderRefundStepsType[OrderRefundStepsKeys]

const noop = (): void => {}

export interface OrderRefundModalContextProps {
  initialStep: OrderRefundStepsProps
  step: OrderRefundStepsProps
  setStep: Dispatch<SetStateAction<OrderRefundStepsProps>>
  order: OrderResponse
  setOrder: Dispatch<SetStateAction<OrderResponse>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  isOverdueOrComplete: boolean
  setIsOverdueOrComplete: Dispatch<SetStateAction<boolean>>
  closeModal: () => void
  resetState: () => void
}

export const orderRefundModalContextDefaults: OrderRefundModalContextProps = {
  // @ts-ignore: OPERATION BLEED STOPPER
  initialStep: null,
  // @ts-ignore: OPERATION BLEED STOPPER
  step: null,
  setStep: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  order: null,
  setOrder: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isLoading: null,
  setIsLoading: noop,
  // @ts-ignore: OPERATION BLEED STOPPER
  isOverdueOrComplete: null,
  setIsOverdueOrComplete: noop,
  closeModal: noop,
  resetState: noop,
}

export const OrderRefundModalContext = createContext<OrderRefundModalContextProps>(orderRefundModalContextDefaults)

export const useOrderRefundModalContext = (): OrderRefundModalContextProps => {
  return { ...useContext(OrderRefundModalContext) }
}

interface OrderRefundModalProviderProps {
  initialStep?: OrderRefundStepsProps
  closeModal: () => void
  children: ReactNode
}

export const OrderRefundModalProvider: FunctionComponent<OrderRefundModalProviderProps> = ({ initialStep: defaultStep, closeModal, children }) => {
  const initialStep = defaultStep ?? OrderRefundSteps.ReturnType
  const [step, setStep] = useState<OrderRefundStepsProps>(initialStep)
  // @ts-ignore: OPERATION BLEED STOPPER
  const [order, setOrder] = useState<OrderResponse>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOverdueOrComplete, setIsOverdueOrComplete] = useState<boolean>(false)

  const resetState = useCallback((): void => {
    setStep(initialStep)
    // @ts-ignore: OPERATION BLEED STOPPER
    setOrder(null)
    setIsLoading(false)
    setIsOverdueOrComplete(false)
  }, [initialStep])

  const value = {
    initialStep,
    step,
    setStep,
    order,
    setOrder,
    isLoading,
    setIsLoading,
    isOverdueOrComplete,
    setIsOverdueOrComplete,
    resetState,
    closeModal,
  }

  return <OrderRefundModalContext.Provider value={value}>{children}</OrderRefundModalContext.Provider>
}
