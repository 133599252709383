import {
  captureApplePayPayment,
  ConsumerAppDispatch,
  ConsumerAppStore,
  setApplePayFailure,
  setApplePayStatus,
  setupPayment,
  transactionIdSelector,
  validateMerchant,
  getPaymentRouting,
  captureApplePayToken,
} from '../'
import {
  ApplePayError,
  ApplePayStatus,
  ApplePayToken,
  PaymentSource,
  PaymentSourceBillingAddress,
  PaymentSourceCaptureCardTypes,
  PaymentSourceCaptureRequest,
  PaymentSourceCaptureMPANFlowRequest,
  ResultCode,
  TopazSetupRequest,
  isPaymentsSourceCaptureApiError,
  MPANPaymentSourceCaptureCardTypes,
} from '@afterpay/types'
import { ConsumerAccountResponse, SupportedLocale } from '@afterpaytouch/portal-api'
import { TrackingEvent } from '../../model/amplitude'
import { countriesWithBillingAddressRequirements } from '.'
import { Routing } from '@afterpaytouch/portal-api/consumer/paymentRouting'
import { orderChannelSelector, traceIdSelector, applePayMPANFlowSelector } from './hooks'

const supportedCardTypes = ['credit', 'debit']
const supportedCardTypesPSC = [PaymentSourceCaptureCardTypes.CREDIT, PaymentSourceCaptureCardTypes.DEBIT]
const supportedMpanFlowCardTypesPSC = [MPANPaymentSourceCaptureCardTypes.CREDIT, MPANPaymentSourceCaptureCardTypes.DEBIT]

export interface ExtraApplePayPaymentRequestParam {
  countryCode: string
  currencyCode: string
  supportedCountries: string[]
  total: ApplePayJS.ApplePayLineItem
  accountData: ConsumerAccountResponse
  lineItems?: ApplePayJS.ApplePayLineItem[]
  merchantCapabilities?: ApplePayJS.ApplePayMerchantCapability[]
}

export interface ApplePayHelperResolve {
  applePayToken: ApplePayToken
  session?: ApplePaySession
}

export enum HelperType {
  PAYMENT,
  UPDATE_PAYMENT,
}

export class ApplePayHelper {
  private static instance: ApplePayHelper
  dispatch: ConsumerAppDispatch
  session: ApplePaySession
  applePayPaymentRequest: ApplePayJS.ApplePayPaymentRequest
  store: ConsumerAppStore
  locale: SupportedLocale
  resolve: (token: ApplePayHelperResolve) => void
  reject: (reason?: any) => void
  logEvent: (eventType: string, eventPropertiesIn?: object, callback?: any) => void
  routingData: Routing
  extraRequestParam: ExtraApplePayPaymentRequestParam

  private constructor(store: ConsumerAppStore, locale: SupportedLocale) {
    this.store = store
    this.dispatch = store.dispatch
    this.locale = locale
  }

  public static getInstance({ store, locale }): ApplePayHelper {
    if (typeof ApplePayHelper.instance === 'undefined') {
      ApplePayHelper.instance = new ApplePayHelper(store, locale)
    }
    return ApplePayHelper.instance
  }

  public initialise(
    extraRequestParam: ExtraApplePayPaymentRequestParam,
    logEvent: (eventType: string, eventPropertiesIn?: object, callback?: any) => void,
    type: HelperType = HelperType.PAYMENT
  ): void {
    this.extraRequestParam = extraRequestParam
    this.applePayPaymentRequest = this.createPaymentRequest(extraRequestParam)
    this.logEvent = logEvent

    try {
      this.session = new ApplePaySession(4, this.applePayPaymentRequest)
      this.session.onvalidatemerchant = this.validateMerchant.bind(this)
      this.session.onpaymentauthorized = type === HelperType.PAYMENT ? this.handleAuthorizedPayment.bind(this) : this.handleAuthorizedUpdatePayment.bind(this)
      this.session.onshippingcontactselected = this.handleShippingContactSelected.bind(this)
      this.session.onshippingmethodselected = this.handleShippingMethodSelected.bind(this)
      this.session.onpaymentmethodselected = this.handlePaymentMethodSelected.bind(this)
      this.session.oncancel = this.handleCancel.bind(this)
    } catch (e) {
      this.logEvent(TrackingEvent.PORTAL_APPLE_PAY_ERROR, {
        errorMessage: e?.message,
        errorCode: e?.code,
        customMessage: 'Session Initialization Failed',
      })
      this.dispatch(setApplePayFailure(ApplePayError.SessionInitializationFailed))
    }
  }

  public async startSession(): Promise<ApplePayHelperResolve> {
    return await new Promise((resolve, reject) => {
      if (typeof this.session === 'undefined') {
        this.dispatch(setApplePayFailure(ApplePayError.SessionInitializationFailed))
        return reject(ApplePayError.SessionInitializationFailed)
      }
      this.resolve = resolve
      this.reject = reject
      this.dispatch(setApplePayStatus(ApplePayStatus.SessionInitialized))
      try {
        this.session.begin()
      } catch (e) {
        this.logEvent(TrackingEvent.PORTAL_APPLE_PAY_ERROR, {
          errorMessage: e?.message,
          errorCode: e?.code,
          customMessage: 'Beginning Session Failed',
        })
        this.dispatch(setApplePayFailure(ApplePayError.BeginningSessionFailed))
        reject(ApplePayError.BeginningSessionFailed)
      }
    })
  }

  private createPaymentRequest(extraRequestParam: ExtraApplePayPaymentRequestParam): ApplePayJS.ApplePayPaymentRequest {
    const { countryCode } = extraRequestParam.accountData ?? {}

    const isBillingAddressRequired = countriesWithBillingAddressRequirements.includes(countryCode)
    return {
      ...extraRequestParam,
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['visa', 'masterCard'],
      requiredBillingContactFields: isBillingAddressRequired ? ['postalAddress'] : undefined,
    }
  }

  private async validateMerchant(event: ApplePayJS.ApplePayValidateMerchantEvent): Promise<void> {
    try {
      const response = await this.dispatch(validateMerchant.initiate(event)).unwrap()
      this.session.completeMerchantValidation(response.sessionObject)
    } catch (e) {
      this.logEvent(TrackingEvent.PORTAL_APPLE_PAY_ERROR, {
        errorMessage: e?.message,
        errorCode: e?.code,
        customMessage: 'Merchant Validation Failed',
      })
      this.dispatch(setApplePayFailure(ApplePayError.MerchantValidationFailed))
      this.session.abort()
      this.reject(ApplePayError.MerchantValidationFailed)
    }
  }

  private async handleAuthorizedPayment(applePayPaymentToken: ApplePayJS.ApplePayPaymentAuthorizedEvent): Promise<void> {
    // @ts-ignore: OPERATION BLEED STOPPER
    const applePayToken: ApplePayToken = await this.exchangeApplePayToken(applePayPaymentToken.payment)
    if (applePayToken !== null) {
      const isTopazSuccessful = await this.setupTopaz(applePayToken)
      if (isTopazSuccessful) {
        this.dispatch(setApplePayStatus(ApplePayStatus.PaymentAuthorized))
        this.session.completePayment({ status: 0 })
        this.resolve({ applePayToken })
      } else {
        this.session.completePayment({ status: 1 })
        this.dispatch(setApplePayFailure(ApplePayError.PaymentRejected))
        this.reject(ApplePayError.PaymentRejected)
      }
    }
  }

  private async handleAuthorizedUpdatePayment(applePayPaymentToken: ApplePayJS.ApplePayPaymentAuthorizedEvent): Promise<void> {
    // @ts-ignore: OPERATION BLEED STOPPER
    const applePayToken: ApplePayToken = await this.exchangeApplePayToken(applePayPaymentToken.payment)
    if (applePayToken !== null) {
      this.dispatch(setApplePayStatus(ApplePayStatus.PaymentAuthorized))
      this.resolve({
        applePayToken,
        session: this.session,
      })
    }
  }

  private async exchangeApplePayToken(applePayPayment: ApplePayJS.ApplePayPayment): Promise<ApplePayToken | null> {
    const isEnabledApplePayMPANFlow = applePayMPANFlowSelector(this.store.getState())
    let paymentSourceCaptureResponse
    let request

    try {
      if (isEnabledApplePayMPANFlow) {
        request = await this.getPaymentRequestForMpanFlow(applePayPayment)
        try {
          paymentSourceCaptureResponse = await this.dispatch(captureApplePayToken.initiate(request)).unwrap()
        } catch (e) {
          console.error('Failed to fetch ApplePayToken data:', e)
        }
      } else {
        request = await this.getPaymentRequest(applePayPayment)
        paymentSourceCaptureResponse = await this.dispatch(captureApplePayPayment.initiate(request)).unwrap()
      }
      const paymentSourceCaptureResult = isEnabledApplePayMPANFlow ? paymentSourceCaptureResponse : paymentSourceCaptureResponse?.data
      const validCardType = isEnabledApplePayMPANFlow
        ? supportedMpanFlowCardTypesPSC.includes(paymentSourceCaptureResult?.card_type)
        : supportedCardTypesPSC.includes(paymentSourceCaptureResult?.card_type)
      if (validCardType) {
        const sourceId = isEnabledApplePayMPANFlow ? paymentSourceCaptureResult.payment_source_id : paymentSourceCaptureResult.source_id
        return { paymentSourceId: sourceId }
      } else {
        this.logEvent(TrackingEvent.PORTAL_APPLE_PAY_ERROR, {
          type: paymentSourceCaptureResult?.card_type,
          customMessage: 'Invalid PaymentSourceCapture card type',
        })
        this.dispatch(setApplePayFailure(ApplePayError.InvalidCardType))
        this.reject(ApplePayError.InvalidCardType)
        return null
      }
    } catch (e) {
      const errorPayload = e.data ?? {}
      const isPaymentsCaptureApiError = isPaymentsSourceCaptureApiError(errorPayload)
      const { code, errors, message } = isPaymentsCaptureApiError
        ? errorPayload.error
        : { code: errorPayload?.code, message: errorPayload?.message, errors: errorPayload?.errors }

      this.logEvent(TrackingEvent.PORTAL_APPLE_PAY_ERROR, {
        errorMessage: message ?? 'Unknown error',
        errorCode: code ?? '',
        errorsList: errors ?? [],
        customMessage: 'Failed to exchange Apple Pay token',
        request: {
          refId: request.transaction_reference?.id,
        },
      })

      this.dispatch(setApplePayFailure(ApplePayError.UnknownError))
      this.reject(ApplePayError.PaymentRejected)
      return null
    }
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  private async getPaymentRequest({ token, billingContact }: ApplePayJS.ApplePayPayment): Promise<PaymentSourceCaptureRequest> {
    const { countryCode, uuid } = this.extraRequestParam.accountData ?? {}
    const state = this.store.getState()
    const transactionToken = transactionIdSelector(state)
    const orderChannel = orderChannelSelector(state)
    const paymentSource = toPaymentSource(token)
    if (countriesWithBillingAddressRequirements.includes(countryCode)) {
      // @ts-ignore: OPERATION BLEED STOPPER
      paymentSource.billing_address = toBillingAddress(billingContact)
    }

    try {
      const routingData = await this.dispatch(getPaymentRouting.initiate()).unwrap()
      return {
        user_id: uuid,
        transaction_reference: {
          id: transactionToken,
        },
        source: paymentSource,
        payment_routing_key: routingData.routing[orderChannel] ?? '',
      }
    } catch (e) {
      this.dispatch(setApplePayFailure(ApplePayError.GetRoutingKeyFailed))
      this.reject(ApplePayError.GetRoutingKeyFailed)
    }
  }

  private async setupTopaz(applePayToken: ApplePayToken): Promise<boolean> {
    try {
      const state = this.store.getState()
      const traceId = traceIdSelector(state)
      const payload: TopazSetupRequest = {
        ...applePayToken,
        traceId,
      }

      const response = await this.dispatch(setupPayment.initiate(payload)).unwrap()
      return response.resultCode === ResultCode.OK
    } catch (e) {
      this.logEvent(TrackingEvent.PORTAL_APPLE_PAY_ERROR, {
        errorMessage: e?.message,
        errorCode: e?.errorCode,
        customMessage: 'Topaz setup failure',
      })
      return false
    }
  }

  private handleShippingContactSelected(): void {
    this.session.completeShippingContactSelection({
      newTotal: this.applePayPaymentRequest.total,
    })
  }

  private handleShippingMethodSelected(): void {
    this.session.completeShippingMethodSelection({
      newTotal: this.applePayPaymentRequest.total,
    })
  }

  private handlePaymentMethodSelected(event: ApplePayJS.ApplePayPaymentMethodSelectedEvent): void {
    if (supportedCardTypes.includes(event.paymentMethod.type)) {
      this.session.completePaymentMethodSelection({
        newTotal: this.applePayPaymentRequest.total,
      })
    } else {
      this.logEvent(TrackingEvent.PORTAL_APPLE_PAY_ERROR, { type: event.paymentMethod?.type, customMessage: 'Invalid card type' })
      this.dispatch(setApplePayFailure(ApplePayError.InvalidCardType))
      this.session.abort()
      this.reject(ApplePayError.InvalidCardType)
    }
  }

  private handleCancel(): void {
    this.dispatch(setApplePayStatus(ApplePayStatus.Cancelled))
    this.reject()
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  private async getPaymentRequestForMpanFlow({ token, billingContact }: ApplePayJS.ApplePayPayment): Promise<PaymentSourceCaptureMPANFlowRequest> {
    const { countryCode, uuid } = this.extraRequestParam.accountData ?? {}
    const state = this.store.getState()
    const transactionToken = transactionIdSelector(state)
    const paymentSource = toPaymentSource(token, true)

    if (countriesWithBillingAddressRequirements.includes(countryCode)) {
      // @ts-ignore: OPERATION BLEED STOPPER
      paymentSource.billing_address = toBillingAddress(billingContact)
    }

    try {
      return {
        user_id: uuid,
        transaction_reference: {
          id: transactionToken,
        },
        apple_pay: paymentSource,
        stored: false,
      }
    } catch (e) {
      this.dispatch(setApplePayFailure(ApplePayError.GetRoutingKeyFailed))
      this.reject(ApplePayError.GetRoutingKeyFailed)
    }
  }
}

const toPaymentSource = (
  { paymentData, paymentMethod, transactionIdentifier }: ApplePayJS.ApplePayPaymentToken,
  isEnabledApplePayMPANFlow: boolean = false
): PaymentSource => {
  return {
    token: isEnabledApplePayMPANFlow ? Buffer.from(JSON.stringify(paymentData)).toString('base64') : JSON.stringify(paymentData),
    payment_network: paymentMethod?.network as 'MasterCard' | 'Visa',
    payment_method_type: paymentMethod?.type as 'debit' | 'credit',
    payment_method_display_name: paymentMethod?.displayName,
    transaction_identifier: transactionIdentifier,
    // @ts-ignore: OPERATION BLEED STOPPER
    billing_address: undefined,
  }
}

const toBillingAddress = (billingContact: ApplePayJS.ApplePayPaymentContact): PaymentSourceBillingAddress => {
  return {
    city: billingContact?.locality ?? '',
    state: billingContact?.administrativeArea ?? '',
    // @ts-ignore: OPERATION BLEED STOPPER
    address_line_1: billingContact?.addressLines[0] ?? '',
    // @ts-ignore: OPERATION BLEED STOPPER
    address_line_2: billingContact?.addressLines[1] ?? '',
    country: billingContact?.countryCode?.toUpperCase() ?? '',
    postal_code: billingContact?.postalCode ?? '',
  }
}
