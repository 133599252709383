import { ActionCreatorWithoutPayload, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DocumentVerificationResult,
  IdentityCheckMetadata,
  IdentityCheckStatus,
  IdentityCheckStatuses,
  TransformedIdentityVerificationDocument,
} from '@afterpaytouch/portal-api/identity/types'
import { Country, CreditFacilityStatus, SubscriptionStatus } from '@afterpaytouch/portal-api/types'
import { emailLookup, getAccount } from '../'
import { authLogin } from '../../auth/user'
import { AccountStatus, ConsumerAddress, FrozenStatus, PreferredPaymentMethod } from '@afterpay/types'
import { ProfileErrorCode } from '../../../model'
import { SupportedLocale } from '@afterpaytouch/shop-api'
import { maskPhoneNumber, phoneNumberIsCleared, toVerifiedDocument, transformIdentityDocument } from '@afterpay/utils'

export interface ConsumerProfile {
  givenNames: string
  otherGivenNames: string
  surname?: string
  phoneNumber: string
  contactAddress?: ConsumerAddress | string
  dateOfBirth: string
  confirmed: boolean
}

export interface ConsumerState {
  givenNames: string
  otherGivenNames: string
  surname: string
  dateOfBirth: string
  countryCode: Country
  accountStatus?: AccountStatus
  creditCheckConsented?: boolean
  email?: string
  emailLookupCountry?: Country
  error?: ProfileErrorCode
  hasFeatureFlagsReady: boolean
  hasPassword: boolean
  id: number
  identityCheckMetadata?: IdentityCheckMetadata
  identityCheckStatus?: IdentityCheckStatus
  identityCheckStatuses?: IdentityCheckStatuses
  phoneNumber: string
  preferredLocale: SupportedLocale
  preferredPaymentMethod?: PreferredPaymentMethod
  sessionExpiryUrl?: string
  smsVerificationEnabled: boolean
  uuid?: string
  verifiedIdentityDocuments?: TransformedIdentityVerificationDocument[]
  verifyAccountMobileRequired?: boolean
  isUserVerifyAuthenticatorAvailable: boolean
  // backend determines if trusted session by using fingerprint, device id and ip address
  // there is a distinction between isTrustedCheckout and this: this does not have to do with auth
  // just whether or not the user is on a recognised device
  trustedSession: boolean
  latestFacilityStatus?: CreditFacilityStatus
  marketingOptIn?: SubscriptionStatus
  isEmailExists: boolean
  isMobileExists: boolean
  cashtag?: string | null
  contactAddress?: ConsumerAddress | null
  frozenStatus?: FrozenStatus
  createdDate?: number
  documentVerificationResults?: DocumentVerificationResult[]
  profileConfirmed?: boolean
}

export const consumerInitialState: ConsumerState = {
  error: undefined,
  hasFeatureFlagsReady: false,
  hasPassword: false,
  sessionExpiryUrl: undefined,
  smsVerificationEnabled: true,
  isUserVerifyAuthenticatorAvailable: false,
  trustedSession: false,
  creditCheckConsented: undefined,
  isEmailExists: false,
  isMobileExists: false,
  preferredLocale: 'en-AU',
  countryCode: Country.AU,
  phoneNumber: '',
  id: 0,
  givenNames: '',
  otherGivenNames: '',
  surname: '',
  dateOfBirth: '',
}

const slice = createSlice({
  name: 'consumer',
  initialState: consumerInitialState,
  reducers: {
    clearSessionExpired(state: ConsumerState) {
      state.sessionExpiryUrl = undefined
    },
    updateConsumerMobile(state: ConsumerState, action: PayloadAction<string>): ConsumerState {
      return {
        ...state,
        phoneNumber: action.payload,
      }
    },
    updateConsumerEmail(state: ConsumerState, action: PayloadAction<string>): ConsumerState {
      return {
        ...state,
        email: action.payload,
      }
    },
    setConsumerError(state: ConsumerState, action: PayloadAction<ProfileErrorCode | undefined>): ConsumerState {
      return {
        ...state,
        error: action.payload,
      }
    },
    setSessionExpired(state: ConsumerState, action: PayloadAction<string | undefined>): ConsumerState {
      return {
        ...state,
        sessionExpiryUrl: action.payload,
      }
    },
    clearConsumer(state: ConsumerState) {
      state.uuid = undefined
      state.givenNames = ''
      state.otherGivenNames = ''
      state.surname = ''
      state.dateOfBirth = ''
      state.preferredLocale = 'en-AU'
      state.preferredPaymentMethod = undefined
      state.accountStatus = undefined
      state.identityCheckStatus = undefined
      state.identityCheckStatuses = undefined
      state.hasPassword = false
      state.error = undefined
      state.cashtag = undefined
    },
    setPreferredPaymentMethod(state: ConsumerState, action: PayloadAction<PreferredPaymentMethod>) {
      state.preferredPaymentMethod = action.payload
    },
    setFeatureFlagState(state: ConsumerState, action: PayloadAction<boolean>) {
      state.hasFeatureFlagsReady = action.payload
    },
    setIsUserVerifyAuthenticatorAvailable(state, action) {
      state.isUserVerifyAuthenticatorAvailable = action.payload
    },
    setVerifyAccountMobileRequired(state, action) {
      state.verifyAccountMobileRequired = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // TODO
      // .addCase(updateAccountDetails.fulfilled, (prevState, action) => {
      // })
      // .addCase(updateAccountDetails.pending, (state) => {
      // })
      // .addCase(updateAccountDetails.rejected, (state, action) => {
      // })
      .addMatcher(getAccount.matchFulfilled, (state, action) => {
        const account = action.payload
        state.id = account.id
        state.uuid = account.uuid
        state.email = account.email
        state.phoneNumber = account.phoneNumber ?? ''
        state.givenNames = account.givenNames
        state.otherGivenNames = account.otherGivenNames
        state.surname = account.surname
        state.dateOfBirth = account.dateOfBirth
        state.profileConfirmed = account.profileConfirmed
        state.contactAddress = account.contactAddress
        state.countryCode = account.countryCode
        state.preferredLocale = account.preferredLocale
        state.preferredPaymentMethod = account.preferredPaymentMethod
        state.accountStatus = account.accountStatus
        state.identityCheckStatus = account.identityCheckStatus
        state.identityCheckStatuses = account.identityCheckStatuses
        state.verifyAccountMobileRequired = phoneNumberIsCleared(account.phoneNumber ?? '')
        state.smsVerificationEnabled = account.smsVerificationEnabled
        state.identityCheckMetadata = account.identityCheckMetadata
        state.verifiedIdentityDocuments = transformIdentityDocument(toVerifiedDocument(account.documentVerificationResults ?? []))
        state.creditCheckConsented = account.creditCheckConsented
        state.latestFacilityStatus = account.latestFacilityStatus
        state.frozenStatus = account.frozenStatus
        state.createdDate = account.createdDate
        state.documentVerificationResults = account.documentVerificationResults
      })
      .addMatcher(emailLookup.matchFulfilled, (state, action) => {
        state.email = action.meta.arg.originalArgs.email
        state.phoneNumber = maskPhoneNumber(action.payload.mobile)
        state.isEmailExists = action.payload.existing
        state.hasPassword = action.payload.passwordExists
        state.smsVerificationEnabled = action.payload.smsVerificationEnabled
        state.emailLookupCountry = action.payload.countryCode
        state.trustedSession = action.payload.trustedSession ?? false
        state.cashtag = action.payload.cashtag
      })
      .addMatcher(authLogin.matchPending, (state) => {
        state.hasFeatureFlagsReady = false
      })
  },
})

export const {
  updateConsumerMobile,
  updateConsumerEmail,
  setConsumerError,
  setPreferredPaymentMethod,
  setSessionExpired,
  setFeatureFlagState,
  setIsUserVerifyAuthenticatorAvailable,
  setVerifyAccountMobileRequired,
} = slice.actions
export const clearConsumer = slice.actions.clearConsumer as ActionCreatorWithoutPayload
export const clearSessionExpired = slice.actions.clearSessionExpired as ActionCreatorWithoutPayload

export const consumerReducer = slice.reducer
