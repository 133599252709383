import React, { FunctionComponent, Dispatch, SetStateAction, createContext, useState, ReactNode } from 'react'
import { OrderChannel } from '@afterpaytouch/portal-api/consumer/ordertransactions/types'

import { Route } from '../../config/router'

interface BreadcrumbProps {
  orderId: number
  channel: OrderChannel
}

export type BreadcrumbRoute = Partial<Record<Route, BreadcrumbProps>>

export interface BreadcrumbContextProps {
  crumbs: BreadcrumbRoute
  setCrumbs: Dispatch<SetStateAction<BreadcrumbRoute>>
}

export interface BreadcrumbProviderProps {
  children: ReactNode
}

export const breadcrumbContextDefaults: BreadcrumbContextProps = {
  // @ts-ignore: OPERATION BLEED STOPPER
  crumbs: null,
  setCrumbs: () => {},
}

export const BreadcrumbContext = createContext<BreadcrumbContextProps>(breadcrumbContextDefaults)

export const BreadCrumbProvider: FunctionComponent<BreadcrumbProviderProps> = ({ children }) => {
  const [crumbs, setCrumbs] = useState<BreadcrumbRoute>()

  const value = {
    crumbs,
    setCrumbs,
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  return <BreadcrumbContext.Provider value={value}>{children}</BreadcrumbContext.Provider>
}
