import { GetConsumerLoyaltyStatusResponseBody } from '@afterpaytouch/portal-api'
import { loyaltyService } from '@afterpaytouch/portal-api/consumer/loyalty'
import { useIsUnauthenticatedRoute } from '../../config/router'
import { useFlag } from '../../hooks'
import { isGetConsumerLoyaltyStatusPlatinumOrAbove, isGetConsumerLoyaltyStatusResponseBody } from './loyalty.utils'

export const {
  useGetConsumerLoyaltyStatusQuery,
  endpoints: { getConsumerLoyaltyStatus },
} = loyaltyService

export const useGetConsumerLoyaltyStatus = (): ReturnType<typeof useGetConsumerLoyaltyStatusQuery> => {
  const isPulseFlagEnabled = useIsPulseFlagEnabled()
  const isUnauthenticatedRoute = useIsUnauthenticatedRoute()
  return useGetConsumerLoyaltyStatusQuery(undefined, { skip: isUnauthenticatedRoute || !isPulseFlagEnabled })
}

const useGetConsumerLoyaltyStatusQueryData = (): GetConsumerLoyaltyStatusResponseBody => {
  // @ts-ignore: OPERATION BLEED STOPPER
  return getConsumerLoyaltyStatus.useQueryState()?.data
}

const useIsPulseFlagEnabled = (): boolean => useFlag('consumer-portal-pulse-enabled', false)

const useIsPulseEnabledForConsumer = (): boolean => isGetConsumerLoyaltyStatusResponseBody(useGetConsumerLoyaltyStatusQueryData())

export const useIsPulseTierPlatinumOrAbove = (): boolean => isGetConsumerLoyaltyStatusPlatinumOrAbove(useGetConsumerLoyaltyStatusQueryData())

export const useIsPulseEnabled = (): boolean => {
  const isPulseFlagEnabled = useIsPulseFlagEnabled()
  const isPulseEnabledForConsumer = useIsPulseEnabledForConsumer()

  return isPulseFlagEnabled && isPulseEnabledForConsumer
}
