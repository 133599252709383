import { Button, Loader, Panel, Text, useIsBreakpointMediumAndUp } from '@afterpaytouch/core'
import { OAuthGrantType, OAuthScope } from '@afterpaytouch/portal-api'
import { Trans, useTranslation } from 'next-i18next'
import React, { FunctionComponent, useCallback } from 'react'
import { useAuthorizeMutation, useConfirmAccessQuery } from '../../state'
import { ExtendedPanel } from '../Panel'

const I18N_NAMESPACE = ['common', 'oauth']

interface OAuthAuthorizeProps {
  clientId: string
  grantType: OAuthGrantType
  redirectUri: string
  scopes: OAuthScope[]
  state: string
}

export const OAuthAuthorizePanel: FunctionComponent<OAuthAuthorizeProps> = ({ clientId, grantType, redirectUri, scopes, state }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  // @ts-ignore: OPERATION BLEED STOPPER
  const isBpMediumUp: boolean = useIsBreakpointMediumAndUp()
  const testNameSpace = 'oauth-authorize'

  const {
    data: confirmAccessData,
    isLoading: isConfirmAccessLoading,
    isError: isConfirmAccessError,
    refetch: refetchConfirmAccessQuery,
  } = useConfirmAccessQuery({
    params: {
      clientId,
      grantType,
      redirectUri,
      scopes,
    },
  })

  const refetchOAuthAuthorize = useCallback((): void => {
    if (isConfirmAccessError) {
      refetchConfirmAccessQuery()
    }
  }, [isConfirmAccessError, refetchConfirmAccessQuery])

  const [authorize, { isLoading: isAuthorizeLoading }] = useAuthorizeMutation()

  const isError = isConfirmAccessError
  const isLoading = isConfirmAccessLoading
  const isDisabled = isError || isLoading || isAuthorizeLoading

  const isAllowButtonLoading = isAuthorizeLoading
  const isCancelButtonLoading = false

  const isAllowButtonDisabled = isDisabled
  const isCancelButtonDisabled = isDisabled

  const onCancel = (): void => {
    window.location.href = encodeURI(`${redirectUri}?error=access_denied&error_reason=user_denied&error_description=Permissions+error`)
  }

  const onAllow = async (): Promise<void> => {
    try {
      const { code } = await authorize({
        body: { clientId, grantType, redirectUri, scopes },
      }).unwrap()
      window.location.href = encodeURI(`${redirectUri}?state=${state}&code=${code}&granted_scopes=${scopes.join(',')}`)
    } catch (err) {}
  }

  if (isError) {
    return (
      <Panel>
        <Panel.Content>
          <ExtendedPanel.Error onClick={refetchOAuthAuthorize} testNameSpace={testNameSpace} />
        </Panel.Content>
      </Panel>
    )
  }

  if (isLoading) {
    return (
      <Panel>
        {/* @ts-ignore: OPERATION BLEED STOPPER */}
        <Panel.Content>
          <Loader testNameSpace={testNameSpace} />
        </Panel.Content>
      </Panel>
    )
  }

  return (
    <Panel>
      {/* @ts-ignore: OPERATION BLEED STOPPER */}
      <Panel.Content>
        <div className='flex justify-center py-10'>
          <div className='max-w-md'>
            <Text>
              <Trans
                i18nKey='oauth:authorize:description'
                // @ts-ignore: OPERATION BLEED STOPPER
                values={{ clientName: confirmAccessData.clientName }}
                components={{
                  bold: <Text bold renderAs='span' />,
                }}
              />
            </Text>
            <div className='mt-6'>
              <Text>
                <Trans
                  i18nKey='oauth:authorize:permission'
                  // @ts-ignore: OPERATION BLEED STOPPER
                  values={{ clientName: confirmAccessData.clientName }}
                  components={{
                    bold: <Text bold renderAs='span' />,
                  }}
                />
              </Text>
            </div>
            <ul className='mt-2 pl-6'>
              {/* @ts-ignore: OPERATION BLEED STOPPER */}
              {confirmAccessData.scopeAndDescription.map(({ description }) => {
                return (
                  <>
                    {description.map((desc, index) => {
                      return (
                        <li key={index} className='mt-2'>
                          <Text>{desc}</Text>
                        </li>
                      )
                    })}
                  </>
                )
              })}
            </ul>
            <div className='mt-10 flex flex-col gap-y-4 md:flex-row-reverse md:justify-end md:gap-x-2'>
              <div>
                <Button
                  padding={isBpMediumUp ? 'Fixed' : 'Fluid'}
                  size={isBpMediumUp ? 'Regular' : 'Large'}
                  disabled={isAllowButtonDisabled}
                  loading={isAllowButtonLoading}
                  onClick={onAllow}
                >
                  {t('oauth:authorize:cta:allow')}
                </Button>
              </div>
              <div>
                <Button.Ghost
                  padding={isBpMediumUp ? 'Fixed' : 'Fluid'}
                  size={isBpMediumUp ? 'Regular' : 'Large'}
                  disabled={isCancelButtonDisabled}
                  loading={isCancelButtonLoading}
                  onClick={onCancel}
                >
                  {t('oauth:authorize:cta:cancel')}
                </Button.Ghost>
              </div>
            </div>
            <div className='mt-6'>
              <Text color='Gray40' size='S'>
                {t('oauth:authorize:disclaimer')}
              </Text>
            </div>
          </div>
        </div>
      </Panel.Content>
    </Panel>
  )
}
