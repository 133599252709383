import { useEffect } from 'react'
import { createModalHook } from '../../hooks'
import { useCreateConsumerTagMutation, useGetConsumerTagsWithDataQuery, useIsPulseEnabled, useUpdateConsumerTagMutation } from '../../state'

export const usePulseIntroModal = createModalHook()

export type OpenPulseIntroModal = ReturnType<typeof usePulseIntroModal>[1]['openModal']

export const useShowPulseIntroModalOnMount = (openPulseIntroModal: OpenPulseIntroModal): void => {
  const isPulseEnabled = useIsPulseEnabled()

  const { data: consumerTags, isSuccess: isGetConsumerTagsSuccesss } = useGetConsumerTagsWithDataQuery()
  const isPulseEnabledAndGetTagsSuccess = isPulseEnabled && isGetConsumerTagsSuccesss
  const hasSeenPulseIntroModal = consumerTags?.['portal.consumer-has-seen-pulse-intro-modal'] === String(true)
  const hasSeenPulseIntroModalDataReturned = consumerTags?.['portal.consumer-has-seen-pulse-intro-modal'] !== undefined

  const [updateConsumerTag] = useUpdateConsumerTagMutation()
  const [createConsumerTag] = useCreateConsumerTagMutation()

  useEffect(() => {
    if (isPulseEnabledAndGetTagsSuccess && !hasSeenPulseIntroModalDataReturned) {
      openPulseIntroModal()
      createConsumerTag({ body: { name: 'portal.consumer-has-seen-pulse-intro-modal', data: String(true) } })
    }

    if (isPulseEnabledAndGetTagsSuccess && !hasSeenPulseIntroModal && hasSeenPulseIntroModalDataReturned) {
      openPulseIntroModal()
      updateConsumerTag({ body: { name: 'portal.consumer-has-seen-pulse-intro-modal', data: String(true) } })
    }
  }, [isPulseEnabledAndGetTagsSuccess, hasSeenPulseIntroModal, hasSeenPulseIntroModalDataReturned])
}
