import { CardDefaultValue } from '@afterpaytouch/form'
import { InputAddressData } from '@afterpaytouch/core'
import { ResultCode, TopazResult, NewCard } from '@afterpaytouch/topaz-api'
import { isValidObject } from '../../utils/object'
import { topazErrors } from './constants'

export const isValidTopazResponse = (result: TopazResult): boolean => result?.resultCode === ResultCode.OK

export const getTopazError = (result: TopazResult): string | null => topazErrors[result?.resultCode] ?? null

export const mapNewCardToTopaz = (cardDetails: CardDefaultValue, address?: InputAddressData): NewCard => {
  const [month, year] = cardDetails.expiryDate.split('/')

  let data: NewCard = {
    cardHolderName: cardDetails.cardHolderName,
    cardExpiryMonth: month,
    cardExpiryYear: year,
    cardNumber: cardDetails.cardNumber.replace(/\s/g, ''),
    cardSecurityCode: cardDetails.cvv,
  }

  if (isValidObject(address)) {
    data = {
      ...data,
      address: {
        street1: address.address1,
        street2: address.address2 ?? undefined,
        city: address.suburb,
        state: address.state,
        postcode: address.postcode,
        country: address.countryCode,
      },
    }
  }

  return data
}
