import React, { FunctionComponent } from 'react'
import { Button, Divider, Heading, useIsBreakpointMediumAndUp, useModalDeepLink } from '@afterpaytouch/core'
import { PaymentMethodRow } from '../PaymentMethodRow'
import { Trans, useTranslation } from 'next-i18next'
import { PaymentMethodType, SavedPaymentMethod } from '@afterpaytouch/portal-api/payments/types'
import { useChangePaymentMethodModal } from './ChangePaymentMethodModal.hooks'
import { useEnableAutopay, useUpdatePaymentBusinessLogic } from './hooks'
import { maskCreditCardNumber } from '../../utils/card'
import {
  useHasOrderIsAutopayDisabled,
  useGetOrderTransactionQuery,
  useIsOrderConsumerLending,
  useOrderIsPayByInstallment,
  useHasOrderTotalOwed,
  useIsClearpayPartiallyMigrated,
} from '../../state'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { OrderAutopay } from '../OrderAutopay'
import { ChangePaymentMethod } from '../ChangePaymentMethod'
import { maskBankAccountNumber } from '../../utils/bankAccount'
import { AchBankAccountStatus, AchBankAccountStatuses } from '@afterpay/types'
import { useSearchParam } from '../../hooks/useSearchParam/useSearchParam'
import { useRouter } from 'next/router'
import { SupportedLocale } from '@afterpaytouch/shop-api'
import { Route } from '../../config/router'
import { stringToBoolean } from '@afterpay/utils/string'
import { LaunchDarklyFlags, useFlag } from '../../hooks'
import { OrderAutopayPBI } from '../OrderAutopayPBI/OrderAutopayPBI'

const I18N_NAMESPACE = ['common', 'orders']

interface OrderPaymentMethodProps {
  paymentData: SavedPaymentMethod
  orderId: string
  canPaymentMethodChange?: boolean
  displayStorePaymentToken?: boolean
  achBankAccountStatus?: AchBankAccountStatus
}

export const OrderPaymentMethod: FunctionComponent<OrderPaymentMethodProps> = ({
  paymentData,
  orderId,
  canPaymentMethodChange = false,
  displayStorePaymentToken = true,
  achBankAccountStatus,
}) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  // @ts-ignore: OPERATION BLEED STOPPER
  const isDesktop: boolean = useIsBreakpointMediumAndUp()
  const isBank = paymentData?.type === PaymentMethodType.ACH_BANK_ACCOUNT
  const isApplePay = paymentData?.type === PaymentMethodType.APPLE_PAY
  const isCreditCard = paymentData?.type === PaymentMethodType.CREDIT_CARD
  const isCashAppPay = paymentData?.type === PaymentMethodType.CASH_APP_PAY
  const hasOrderTotalOwed = useHasOrderTotalOwed(orderId)
  const isOrderPCL = useIsOrderConsumerLending(orderId)
  const isOrderPBI = useOrderIsPayByInstallment(orderId)
  const isAutopayDisabled = useHasOrderIsAutopayDisabled(orderId)
  const isClearpayPartiallyMigrated = useIsClearpayPartiallyMigrated(orderId)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const { data: orderData, isLoading: isGetOrderDataLoading } = useGetOrderTransactionQuery(orderId)
  const router = useRouter()
  const locale = router?.locale as SupportedLocale
  const changePaymentMethodModalName = 'change-payment-method'
  // @ts-ignore: OPERATION BLEED STOPPER
  const isChangePaymentMethodModalOpenParam = stringToBoolean(useSearchParam(`modal-${changePaymentMethodModalName}`))
  const [isChangePaymentMethodModalOpen, { openModal: openChangePaymentMethodModal, closeModal: closeChangePaymentMethodModal }] =
    useChangePaymentMethodModal(isChangePaymentMethodModalOpenParam)
  useModalDeepLink(isChangePaymentMethodModalOpen, changePaymentMethodModalName)
  const isAmexSoftCloseEnabled = useFlag('amex-soft-close-enabled', false)
  const showAmexError = isAmexSoftCloseEnabled && isCreditCard && paymentData.cardBrand === 'AMEX'

  const autopayEnabledSuccessMessage = (): JSX.Element => <Trans i18nKey={'orders:orderAutopay:successToastMsg'} />
  const { handleEnableAutopay } = useEnableAutopay(orderId, autopayEnabledSuccessMessage)

  let number: string
  if (isBank) {
    number = maskBankAccountNumber(paymentData.numberMask)
  } else if (isCreditCard || isApplePay) {
    number = maskCreditCardNumber(paymentData.maskedPan)
  } else if (isCashAppPay) {
    number = paymentData.cashtag
  }

  const isAutopayToggleEnabled = useFlag(LaunchDarklyFlags.AutopayToggleEnabled, false)

  return (
    <>
      <Heading size={'L'}>{t('orders:orderPaymentMethod:header')}</Heading>
      <div className='py-4'>
        {paymentData && (
          <PaymentMethodRow
            // @ts-ignore: OPERATION BLEED STOPPER
            number={number}
            // @ts-ignore: OPERATION BLEED STOPPER
            expiryDate={isCreditCard && paymentData.expiryDate}
            // @ts-ignore: OPERATION BLEED STOPPER
            cardType={(isCreditCard || isApplePay) && paymentData.cardBrand}
            isPreferred={false}
            type={paymentData.type}
            // @ts-ignore: OPERATION BLEED STOPPER
            bankName={(isBank && paymentData.issuingBank) || (isCashAppPay && t('common:cashAppPay'))}
            textColorFire={achBankAccountStatus === AchBankAccountStatuses.UNLINKED || showAmexError}
            rightAdornment={
              canPaymentMethodChange &&
              hasOrderTotalOwed &&
              !isClearpayPartiallyMigrated && (
                <Button.Ghost
                  onClick={() => {
                    openChangePaymentMethodModal()
                    logEvent(TrackingEvent.CLICKED_CHANGE_ORDER_PAYMENT_METHOD)
                  }}
                  size={isDesktop ? 'Regular' : 'Small'}
                  testNameSpace='change-payment-method'
                >
                  {t('orders:orderPaymentMethod:changeCTA')}
                </Button.Ghost>
              )
            }
          />
        )}
      </div>
      <ChangePaymentMethod
        show={isChangePaymentMethodModalOpen}
        onBackdropClick={closeChangePaymentMethodModal}
        onEscapeKeyDown={closeChangePaymentMethodModal}
        onClose={closeChangePaymentMethodModal}
        orderId={orderId}
        displayStorePaymentToken={displayStorePaymentToken}
        enabledCardTypes={orderData?.enabledCardTypes}
        merchant={orderData?.merchant}
        paymentMethod={orderData?.paymentMethod}
        paymentType={orderData?.paymentType}
        currency={orderData?.orderSummary?.orderTotal?.currency}
        useUpdatePaymentBusinessLogic={useUpdatePaymentBusinessLogic}
        isGetOrderDataLoading={isGetOrderDataLoading}
        achFlowEntryDeepLink={`${window.location.origin}/${locale}/orders/${orderId}/${
          Route.ORDER__CHANGEPAYMENTMETHOD_ADDBANKACCOUNTMODAL?.match(/(\?).*/g)?.[0]
        }`}
      />
      {isOrderPCL && isAutopayDisabled && (
        <>
          <Divider kind='Hairline' />
          <div className='pb-2 pt-2'>
            {/* TODO: Rename this to OrderAutopayPCL */}
            <OrderAutopay onHandleTurnOnAutopay={handleEnableAutopay()} />
          </div>
        </>
      )}
      {isOrderPBI && isAutopayToggleEnabled && (
        <>
          <Divider kind='Hairline' />
          <OrderAutopayPBI orderId={orderId} />
        </>
      )}
      <Divider kind='Hairline' />
    </>
  )
}
