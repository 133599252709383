import { useTranslation } from 'next-i18next'
import { Button, Heading, Panel, Text } from '@afterpaytouch/core'
import React, { FunctionComponent } from 'react'
import Image from 'next/image'
import { NewTabLink } from '..'
import { useConsumerSizes } from '../../utils/responsive'
import { useDisplayIllustrations } from '../../state/convergence/hooks'

const I18N_NAMESPACE = ['common', 'index']

export const MonthlyPaymentsCreditFacilityPanel: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isDisplayIllustrations = useDisplayIllustrations()
  const { subheadingSize, buttonSize, textSize } = useConsumerSizes()

  return (
    <Panel>
      <Panel.Content>
        <div className='lg:flex lg:gap-x-3' data-testid='monthly-payments-panel'>
          <div className='grid auto-cols-auto justify-between gap-x-3 lg:flex-1'>
            <div className='col-span-1 pb-3'>
              <Heading size={subheadingSize}>{t('index:monthlyPayments:title')}</Heading>
            </div>
            <div className='col-span-1 justify-self-end pb-3 lg:order-1 lg:justify-self-start'>
              <NewTabLink href={t('common:urls:payMonthlyUrl')}>
                <Button.Secondary padding='Fixed' size={buttonSize} onClick={undefined} aria-label={t('index:monthlyPayments:learnMore')}>
                  {t('index:monthlyPayments:learnMore')}
                </Button.Secondary>
              </NewTabLink>
            </div>
            <div className='col-span-2 pb-3'>
              <Text size={textSize}>{t('index:monthlyPayments:body')}</Text>
            </div>
          </div>
          {isDisplayIllustrations && (
            <div className='-mb-6 grid items-end lg:flex-1'>
              <Image
                width={567}
                height={235}
                src='/images/pay-monthly-scene.svg'
                alt={t('index:welcome:existingUser:monthlyPayments:heading')}
                sizes='100vw'
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </div>
          )}
        </div>
      </Panel.Content>
    </Panel>
  )
}
