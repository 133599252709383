import React, { FunctionComponent } from 'react'
import { Modal, Heading, Text, Button, Divider, Icon, Grid } from '@afterpaytouch/core'
import { useConsumerSizes } from '../../../utils/responsive'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { useConsumerGivenName } from '../../../state'
import { useTranslation } from 'next-i18next'

const I18_NAMESPACE = ['index']

const GUTTER_VARIANT = {
  HALF: 0.5,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  STANDARD: 'standard',
  NONE: 'none',
} as const

export interface WelcomeModalProps {
  isModalOpen: boolean
  onClose: () => void
}

export const WelcomeModal: FunctionComponent<WelcomeModalProps> = ({ isModalOpen, onClose }) => {
  const { t } = useTranslation(I18_NAMESPACE)
  const { headingSize, textSize } = useConsumerSizes()
  const givenName = useConsumerGivenName()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const onShow = (): void => logEvent('Viewed Welcome Modal')

  const onButtonClick = (): void => {
    logEvent('Clicked Welcome Modal Button')
    onClose()
  }

  const interpolationProps = {
    givenName,
  }

  return isModalOpen ? (
    <Modal show={isModalOpen} onShow={onShow} onClose={onClose} onHide={onClose} onBackdropClick={onClose} backdropKind='Darker'>
      {' '}
      <Modal.Header>
        <div className='mt-10'>
          <Heading size={headingSize}>{t('index:welcome.welcomeModal.heading', interpolationProps)} </Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <Divider kind='Hairline' />
          <Grid verticalGutter={GUTTER_VARIANT.TWO} gutter={GUTTER_VARIANT.NONE}>
            <Grid.FixedItem align={'center'} width={72}>
              <Icon.OpeningTime />
            </Grid.FixedItem>
            <div>
              <Text bold size={textSize}>
                {t('index:welcome.welcomeModal.content.payment.header')}
              </Text>
              <Text size={textSize}>{t('index:welcome.welcomeModal.content.payment.body')}</Text>
            </div>
          </Grid>
          <Divider kind='Hairline' />
          <Grid verticalGutter={GUTTER_VARIANT.TWO} gutter={GUTTER_VARIANT.NONE}>
            <Grid.FixedItem align={'center'} width={72}>
              <Icon.Shop />
            </Grid.FixedItem>
            <div>
              <Text bold size={textSize}>
                {t('index:welcome.welcomeModal.content.shop.heading')}
              </Text>
              <Text size={textSize}>{t('index:welcome.welcomeModal.content.shop.body')} </Text>
            </div>
          </Grid>
          <Divider kind='Hairline' />
          <Grid verticalGutter={GUTTER_VARIANT.TWO} gutter={GUTTER_VARIANT.NONE}>
            <Grid.FixedItem align={'center'} width={72}>
              <Icon.Edit />
            </Grid.FixedItem>
            <div>
              <Text bold size={textSize}>
                {t('index:welcome.welcomeModal.content.personalInformation.heading')}
              </Text>
              <Text size={textSize}>{t('index:welcome.welcomeModal.content.personalInformation.body')}</Text>
            </div>
          </Grid>
          <Divider kind='Hairline' />
        </div>
        <Button.Secondary padding='Fluid' onClick={onButtonClick}>
          {t('index:welcome.welcomeModal.button.label')}
        </Button.Secondary>
      </Modal.Content>
    </Modal>
  ) : null
}
