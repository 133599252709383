import React, { FunctionComponent, useContext } from 'react'
import { TrackingEvent } from '../../../model/amplitude'
import { AchFlowModalContext, AchFlowSteps } from '../AchFlowModalContext'
import { useCreateLinkTokenMutation } from '../../../state'
import { BasePlaid } from '../BasePlaid'

/**
 *  Solution Review
 *  Pass the redirect url to the server and get the Plaid link token =>
 *  Open the Plaid in the iFrame, redirect back to /plaid-finished page once consumer finishes =>
 *  On the /plaid-finished page post the message to the window =>
 *  On this Page, listen the window message =>
 *  Jump to the link bank account step once received the message with the Plaid data
 */

export const CreatePlaid: FunctionComponent = () => {
  const { setStep, setPublicToken } = useContext(AchFlowModalContext)

  const onHandlePlaidFlowFinished = (data): void => {
    setPublicToken(data.token)
    setStep(AchFlowSteps.LinkBankAccount)
  }

  const amplitudeEvents = {
    onError: TrackingEvent.ACH.ViewAddBankAccountError,
    onSuccess: TrackingEvent.ACH.SelectedContinueInPlaidSuccess,
    onOauthError: TrackingEvent.ACH.ViewAddBankAccountOauthError,
    onOauthSuccess: TrackingEvent.ACH.SelectedContinueInPlaidOauthSuccess,
  }

  return <BasePlaid useCreateToken={useCreateLinkTokenMutation} onHandlePlaidFlowFinished={onHandlePlaidFlowFinished} amplitudeEvents={amplitudeEvents} />
}
