import React, { FunctionComponent, ReactNode } from 'react'
import { Panel as CorePanel, useIsBreakpointMediumAndUp } from '@afterpaytouch/core'

import styles from './style.module.scss'
import { ErrorDialog } from '../ErrorDialog'
import { Breadcrumb } from '../Breadcrumb'
import clsx, { ClassValue } from 'clsx'

export interface PanelErrorProps {
  onClick: () => void
  testNameSpace?: string
}

export interface PanelHeadProps {
  showMobileBreadCrumb?: boolean
  children: ReactNode
}

type ExtendedPanelComponent = typeof CorePanel & {
  Error: FunctionComponent<PanelErrorProps>
  HeadWithCrumb: FunctionComponent<PanelHeadProps>
}

export const PanelError: FunctionComponent<PanelErrorProps> = ({ testNameSpace = '', onClick }) => {
  const panelTestNameSpace = testNameSpace !== '' ? `${testNameSpace}-panel-error` : 'panel-error'
  const classNames: ClassValue = clsx(styles.errorContainer)
  return (
    <div className={classNames} data-testid={panelTestNameSpace}>
      <ErrorDialog onClick={onClick} />
    </div>
  )
}

export const PanelHeadWithCrumb: FunctionComponent<PanelHeadProps> = ({ showMobileBreadCrumb = true, children }) => {
  const isBpMediumUp = useIsBreakpointMediumAndUp()
  return (
    <CorePanel.Head>
      {showMobileBreadCrumb && !isBpMediumUp && (
        <div className='mb-3'>
          <Breadcrumb bold={false} />
        </div>
      )}
      {children}
    </CorePanel.Head>
  )
}

export const ExtendedPanel: ExtendedPanelComponent = {
  ...CorePanel, // TODO: Remove to reduce ambiguity
  Error: PanelError,
  HeadWithCrumb: PanelHeadWithCrumb,
} as ExtendedPanelComponent
