import { EnabledCardsResponse } from '@afterpaytouch/portal-api/consumer/enabledCards/types'
import { CardBrand } from '@afterpay/types'

export const cardsEnabled = (cardList: EnabledCardsResponse): CardBrand[] => {
  const brands = [CardBrand.MASTERCARD, CardBrand.VISA, CardBrand.AMEX]
  const cardListKeys = Object.keys(cardList)

  const isBrandEnabled = (brand: CardBrand): boolean => cardListKeys.some((card) => cardList[card] === true && card.includes(brand.toLowerCase()))

  // @ts-ignore: OPERATION BLEED STOPPER
  return brands.map((brand) => isBrandEnabled(brand) && brand).filter((x) => x)
}
