import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { getCardBrandFromCardNumber } from '@afterpaytouch/form/lib/fields/Card/utils'
import { CardBrand } from '@afterpay/types'
import {
  initialiseScanIntent,
  setCardScanRequired,
  startCardScan,
  useAppDispatch,
  useCardScanCardNumber,
  useCardScanCheckpoint,
  useCardScanExpiryDate,
  useIsReadyToScan,
} from '../../state'
import { isMobileChrome, isMobileSafari, isMobileSamsung } from '../../utils/device'

import { PaymentMethodRow } from '../PaymentMethodRow'

import { BrowserType, CardScanAmplitudeErrorReason, CardScanCheckpoint, CardScanError, isScanIntent, ScanIntent } from '../../model'
import { Button, Grid, Heading, Icon, Text } from '@afterpaytouch/core'

import style from './style.module.css'
import { maskCreditCardNumber } from '../../utils/card'
import { TrackOnMount, useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'

export interface CardScanProps {
  onCancel: () => void
  onSuccess: () => void
  onError: (error: CardScanError) => void
}

const I18N_NAMESPACE = ['cardScan']

const errorExist = (error: CardScanError | undefined): error is CardScanError => Boolean(error)
const nonScanErrors: CardScanError[] = [CardScanError.Unsupported, CardScanError.NoPermission]
const isNonScanErrors = (error: CardScanError): boolean => nonScanErrors.includes(error)

const isTrue = (v: any): v is true => v === true

// ui-customization
const css = `@font-face { font-family: "Italianplate"; font-weight: 400; font-style: normal; font-display: swap; src: url("https://static.afterpay.com/font/ItalianPlateNo2Expanded-Regular.woff2") format("woff2"), url("https://static.afterpay.com/font/ItalianPlateNo2Expanded-Regular.woff") format("woff"); }
@font-face { font-family: "Italianplate Bold"; font-weight: 400; font-style: normal; font-display: swap; src: url("https://static.afterpay.com/font/ItalianPlateNo2Expanded-Bold.woff2") format("woff2"), url("https://static.afterpay.com/font/ItalianPlateNo2Expanded-Bold.woff") format("woff"); }
body .modal { border-radius: 0 !important; height: inherit !important; max-height: inherit !important; max-width: inherit !important; width: inherit !important; }
#full_view_finder { margin: 0; }
#card_view_finder { box-shadow: 0 0 0 9999px rgb(0 0 0 / 80%) !important; border-color: #fff !important; border-width: 1px !important; }
svg#svg_view_finder { opacity: 0 !important; }
.instructions { margin-top: 30%; font-size: 24px; font-family: Italianplate Bold !important; }
.card_details { display: none !important; }
.logo { display: none !important; }
.button.cannot_scan { display: none !important; }
.button.swap { display: none !important; }
.security_notification { margin-top: 24px; }
.security_notification svg { display: none; }
.security_notification .security_text { font-size: 14px; line-height: 22px; font-family: Italianplate !important; }
`

export const CardScanForm: FunctionComponent<CardScanProps> = ({ onCancel, onSuccess, onError }) => {
  const dispatch = useAppDispatch()
  const [error, setError] = useState<CardScanError>()
  const [browserType, setBrowserType] = useState<BrowserType>(BrowserType.Others)
  const [clicked, setClicked] = useState<boolean>(false)
  const { t } = useTranslation(I18N_NAMESPACE)
  const cardNumber: string = useCardScanCardNumber()
  const expiryDate: string = useCardScanExpiryDate()
  const ready: boolean = useIsReadyToScan()
  const checkpoint: CardScanCheckpoint = useCardScanCheckpoint()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  const title: string = useMemo<string>(() => {
    if (errorExist(error)) {
      if (isNonScanErrors(error)) {
        return t(`cardScan:error.title.${CardScanError.NoPermission}`)
      }

      return t(`cardScan:error.title.${error}`)
    }

    return t('cardScan:title')
  }, [t, error])

  const subtitle = useMemo<string>(() => {
    if (errorExist(error)) {
      if (isNonScanErrors(error)) {
        return t(`cardScan:error.subtitle.${CardScanError.NoPermission}.${browserType}`)
      }

      const lastFour = cardNumber.slice(-4)
      return t(`cardScan:error.subtitle.${error}`, { lastFour })
    }

    return t('cardScan:subtitle')
  }, [t, error, browserType, cardNumber])

  const backBtnVisible: boolean = useMemo<boolean>(() => {
    // @ts-ignore: OPERATION BLEED STOPPER
    return !isNonScanErrors(error)
  }, [error])
  const scanAgainBtnVisible: boolean = useMemo<boolean>(() => {
    // @ts-ignore: OPERATION BLEED STOPPER
    return [CardScanError.DidntMatch, CardScanError.CannotScan].includes(error)
  }, [error])

  const initialise = async (): Promise<void> => {
    try {
      const intent: ScanIntent | { error: CardScanError } = await dispatch(initialiseScanIntent())
      if (isScanIntent(intent)) {
        intent.iframe.style.zIndex = '999999' // ensure the iframe will show on top level
        intent.configureScan({
          localization: {
            instructionsLoading: t('cardScan:scan'),
            instructionsReading: t('cardScan:scan'),
            instructionsScan: t('cardScan:scan'),
            instructionsCapturing: t('cardScan:scan'),
            instructionsProcessing: t('cardScan:scan'),
            instructionsWrongCard: t('cardScan:scan'),
            instructionsTroubleScanning: t('cardScan:scan'),
            securityNotification: t('cardScan:notify'),
          },
          css,
          enableCannotScanButton: false,
        })
      } else if (intent.error === CardScanError.Unsupported) {
        setError(CardScanError.Unsupported)
      }
    } catch (ignore) {
      onError(CardScanError.TerminalError)
    }
  }

  const scanClickHandler = async (): Promise<void> => {
    if (!ready || clicked) {
      return
    }

    logEvent(TrackingEvent.PRESSED_CARD_SCAN_ADD_PAYMENT, {
      checkpoint,
    })

    setClicked(true)
    const result: true | { error: CardScanError } = await dispatch(startCardScan())

    if (isTrue(result)) {
      logEvent(TrackingEvent.SCAN_PAYMENT_MATCHED_ADD_PAYMENT, {
        checkpoint,
      })
      onSuccess()
    } else {
      setError(result.error)
      switch (result.error) {
        case CardScanError.UserCanceled:
          setError(undefined)
          break
        case CardScanError.DidntMatch:
          logEvent(TrackingEvent.SCAN_PAYMENT_FAILED_ADD_PAYMENT, {
            checkpoint,
            reason: CardScanAmplitudeErrorReason.NotMatched,
          })
          initialise()
          break
        case CardScanError.TooManyTries:
          logEvent(TrackingEvent.SCAN_PAYMENT_FAILED_ADD_PAYMENT, {
            checkpoint,
            reason: CardScanAmplitudeErrorReason.RateLimit,
          })
          break
        case CardScanError.NoPermission:
          logEvent(TrackingEvent.SCAN_PAYMENT_FAILED_ADD_PAYMENT, {
            checkpoint,
            reason: CardScanAmplitudeErrorReason.NoPermission,
          })
          break
        case CardScanError.CannotScan:
          break
        case CardScanError.TerminalError:
          logEvent(TrackingEvent.SCAN_PAYMENT_FAILED_ADD_PAYMENT, {
            checkpoint,
            reason: CardScanAmplitudeErrorReason.OtherReasons,
          })
          onError(CardScanError.TerminalError)
          break
      }
    }

    setClicked(false)
  }

  const backClickHandler = (): void => {
    logEvent(TrackingEvent.PRESSED_BACK_IN_CARD_SCAN, {
      checkpoint,
    })
    onCancel()
  }

  // @ts-ignore: OPERATION BLEED STOPPER
  const cardBrand: CardBrand = useMemo(() => {
    return getCardBrandFromCardNumber(cardNumber)
  }, [cardNumber])
  const maskedPan: string = useMemo(() => {
    return maskCreditCardNumber(cardNumber)
  }, [cardNumber])

  useEffect(() => {
    initialise()
    if (isMobileSamsung()) {
      setBrowserType(BrowserType.Samsung)
    } else if (isMobileChrome()) {
      setBrowserType(BrowserType.Chrome)
    } else if (isMobileSafari()) {
      setBrowserType(BrowserType.Safari)
    }
    return () => {
      dispatch(setCardScanRequired(false))
    }
  }, [])

  return (
    <>
      <TrackOnMount eventName={TrackingEvent.VIEWED_CARD_SCAN_ADD_PAYMENT} eventProps={{ checkpoint }} />
      <Grid>
        <Heading size='XXL'>{title}</Heading>
      </Grid>
      <Grid>
        <Text size='M'>{subtitle}</Text>
      </Grid>
      {!errorExist(error) ? (
        <>
          {cardNumber && (
            <div className={style.cardContainer}>
              <PaymentMethodRow cardType={cardBrand} number={maskedPan} expiryDate={expiryDate} testNameSpace='card' />
            </div>
          )}
          <Grid>
            <Text size='M' color='Gray40'>
              {t('cardScan:tips')}
            </Text>
          </Grid>
          <Grid>
            <Grid.Item>
              <Button padding='Fluid' onClick={scanClickHandler} testNameSpace='card-scan-submit' loading={clicked} disabled={!ready || clicked}>
                <Icon.Camera size='Small' title={t('cardScan:scan')} />
                {t('cardScan:scan')}
              </Button>
            </Grid.Item>
          </Grid>
        </>
      ) : (
        <>
          {backBtnVisible && (
            <Grid>
              <Button padding='Fluid' onClick={backClickHandler} testNameSpace='card-scan-back'>
                {t('cardScan:backToAddPayment')}
              </Button>
            </Grid>
          )}
          {scanAgainBtnVisible && (
            <Grid>
              <Button.Ghost padding='Fluid' loading={clicked} onClick={scanClickHandler} testNameSpace='card-scan-submit-again' disabled={!ready || clicked}>
                <Icon.Camera size='Small' title={t('cardScan:scanAgain')} />
                {t('cardScan:scanAgain')}
              </Button.Ghost>
            </Grid>
          )}
        </>
      )}
    </>
  )
}
