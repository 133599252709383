import React, { FunctionComponent, ReactNode, useContext } from 'react'
import { useTranslation, Trans } from 'next-i18next'
import { Text, Button } from '@afterpaytouch/core'
import { Money as MoneyType, PaymentMethodType } from '@afterpaytouch/portal-api'
import { getCardBrandFromCardNumber } from '@afterpaytouch/form/lib/fields/Card/utils'
import { isApplePay, isBankAccount, isCard, isCashAppPay } from '@afterpay/types'
import { CardDefaultValue } from '@afterpaytouch/form'
import { PayNowInput, PayNowModalContext } from '../../PayNowModalContext'
import { Money } from '../../..'
import { PaymentMethodRow } from '../../../PaymentMethodRow'
import { maskCreditCardNumber } from '../../../../utils/card'
import { isConsumerLendingOrder, useGetOrderChannel, useGetOrderTransactionQuery, useGetTotalPayableHardshipRepayments } from '../../../../state'
import { getOrderChannelLabel } from '../../../../state/orders/utils'
import { PaymentMethods } from '../../../../state/orders/types'
import { isNewCreditCardPayment, getSingleInstallmentSequence } from '../../utils'

const I18N_NAMESPACE = ['common', 'payments']

const PaymentResultKinds = {
  success: 'success',
  error: 'error',
} as const

type PaymentResultTypes = 'success' | 'error'

interface PaymentResultProps {
  type: PaymentResultTypes
  payment?: MoneyType
  message?: string
  paymentMethod?: PaymentMethods | CardDefaultValue
  testNameSpace?: string
  merchant?: string
}

export const PaymentResult: FunctionComponent<PaymentResultProps> = ({
  type,
  message,
  payment,
  paymentMethod,
  testNameSpace = 'paynow-payment-result',
  merchant,
}) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isError = type === PaymentResultKinds.error
  const isPaymentSelected = Boolean(paymentMethod)
  const {
    setResult,
    setPayment,
    closeModal,
    order,
    payment: paymentSetupData,
    isBankPayment,
    hasAccountLevelPayments,
    payNowStep,
  } = useContext(PayNowModalContext)
  const orderId = order?.orderId
  const orderChannel = useGetOrderChannel(String(orderId))
  const totalRepayments = useGetTotalPayableHardshipRepayments()
  const channelTrans = t(`common:channel:${getOrderChannelLabel(orderChannel)}`)
  const isConsumerLending = isConsumerLendingOrder(order?.paymentType)
  const { data: orderData } = useGetOrderTransactionQuery(String(orderId), { skip: hasAccountLevelPayments })
  const handleRetry = (): void => {
    setResult({ success: false, error: false })
    setPayment({
      ...paymentSetupData,
      initiateData: undefined,
    })
  }
  const renderRow = (label: ReactNode, value: ReactNode): ReactNode => (
    <div className='mt-6 first:mt-0'>
      <Text bold>{label}</Text>
      <div className='mt-1'>{value}</div>
    </div>
  )

  const renderPaymentMethodRow = (): ReactNode => {
    if (isCard(paymentMethod)) {
      return (
        <PaymentMethodRow
          cardType={paymentMethod.cardBrand}
          number={maskCreditCardNumber(paymentMethod.maskedPan)}
          expiryDate={paymentMethod.expiryDate}
          testNameSpace={testNameSpace}
        />
      )
    }

    if (isApplePay(paymentMethod)) {
      return (
        <PaymentMethodRow
          type={PaymentMethodType.APPLE_PAY}
          testNameSpace={testNameSpace}
          bodyComponent={
            <div className='flex flex-row items-center'>
              <div className='ml-2 md:ml-4'>
                <Text>Apple Pay</Text>
              </div>
            </div>
          }
        />
      )
    }

    if (isNewCreditCardPayment(paymentMethod)) {
      return (
        <PaymentMethodRow
          cardType={getCardBrandFromCardNumber(paymentMethod.cardNumber)}
          number={maskCreditCardNumber(paymentMethod.cardNumber)}
          expiryDate={paymentMethod.expiryDate}
          testNameSpace={testNameSpace}
        />
      )
    }

    if (isBankAccount(paymentMethod)) {
      return (
        <PaymentMethodRow
          type={PaymentMethodType.ACH_BANK_ACCOUNT}
          bankName={paymentMethod.issuingBank}
          number={maskCreditCardNumber(paymentMethod.numberMask)}
          testNameSpace={testNameSpace}
        />
      )
    }

    if (isCashAppPay(paymentMethod)) {
      return (
        <PaymentMethodRow
          type={PaymentMethodType.CASH_APP_PAY}
          bankName={t('common:cashAppPay')}
          number={paymentMethod.cashtag}
          testNameSpace={testNameSpace}
        />
      )
    }

    return null
  }
  const renderRepaymentDetails = (): ReactNode => {
    return renderRow(
      t('payments:customPayment:payment:order'),
      <>
        <Text renderAs='p' testNameSpace={`${testNameSpace}-repayment-plan`}>
          {t('payments:customPayment:repaymentPlan:title')}
        </Text>
        {payNowStep === PayNowInput.NEXT ? (
          <Text renderAs='span' testNameSpace={`${testNameSpace}-repayment-progress`}>
            {totalRepayments && t('payments:customPayment:repaymentPlan:progress', { total: totalRepayments })}
          </Text>
        ) : (
          <Text renderAs='span' testNameSpace={`${testNameSpace}-repayment-remaining-or-custom`}>
            {payment?.symbol}
            {payment?.amount}
          </Text>
        )}
      </>
    )
  }

  const renderMerchantOrder = (): ReactNode => {
    return (
      !hasAccountLevelPayments &&
      channelTrans &&
      merchant &&
      renderRow(
        t('payments:customPayment:payment:order'),
        <>
          <Text renderAs='p' testNameSpace={`${testNameSpace}-merchant`}>
            {merchant}
          </Text>
          <Text renderAs='span' testNameSpace={`${testNameSpace}-channel`}>
            {`${channelTrans} #${orderId}`}
          </Text>
        </>
      )
    )
  }

  const renderPaymentTerms = (): ReactNode => {
    const offsetInstallmentSequence = getSingleInstallmentSequence(paymentSetupData)
    const installmentSequence = typeof offsetInstallmentSequence === 'number' ? offsetInstallmentSequence : null
    const installmentsCount = orderData?.loan?.term
    return (
      Boolean(installmentSequence) &&
      Boolean(installmentsCount) &&
      renderRow(
        t('payments:customPayment:payment:paymentTerms:heading'),
        <Text testNameSpace={`${testNameSpace}-payment-terms`}>
          {t('payments:customPayment:payment:paymentTerms:message', { installmentSequence, installmentsCount })}
        </Text>
      )
    )
  }

  return (
    <>
      <div data-testid={`${testNameSpace}-${type}`} className={isBankPayment || isError ? 'mt-0' : 'mt-3'}>
        {isError && isBankPayment && (
          <div className='mb-5'>
            <Text renderAs='span' testNameSpace={`${testNameSpace}-message-bank`}>
              {message ?? t('payments:customPayment:payment:error:messages:cardNotEnabled')}
            </Text>
          </div>
        )}
        {hasAccountLevelPayments && renderRepaymentDetails()}
        {isBankPayment && renderMerchantOrder()}
        {isBankPayment &&
          isPaymentSelected &&
          renderRow(
            t('payments:customPayment:payment:amountPaid'),
            <Money
              testNameSpace={`${testNameSpace}-payment`}
              // @ts-ignore: OPERATION BLEED STOPPER
              size='M'
              // @ts-ignore: OPERATION BLEED STOPPER
              value={payment}
            />
          )}
        {isConsumerLending && renderPaymentTerms()}
        {isPaymentSelected && renderRow(t('payments:customPayment:payment:paymentMethod'), renderPaymentMethodRow())}
        {isBankPayment && !isError && (
          <>
            <div className='mt-5'>
              <Text renderAs='p' size='S'>
                {t('payments:customPayment:payment:bankAccount:content:1')}
              </Text>
              <div className='mt-5'>
                <Text renderAs='p' size='S'>
                  <Trans
                    i18nKey={'payments:customPayment:payment:bankAccount:content:2'}
                    components={{
                      strong: <strong></strong>,
                    }}
                  />
                </Text>
              </div>
            </div>
            <div className='mt-5'>
              <Button.Primary padding='Fluid' onClick={closeModal}>
                {t('common:close')}
              </Button.Primary>
            </div>
          </>
        )}
        {isError && (
          <div className='mt-5'>
            {isPaymentSelected ? (
              <Button.Primary testNameSpace={`${testNameSpace}-retry`} padding='Fluid' onClick={handleRetry}>
                {isBankPayment || isConsumerLending ? t('payments:customPayment:payment:retryBank') : t('payments:customPayment:payment:retry')}
              </Button.Primary>
            ) : (
              <Button.Primary testNameSpace={`${testNameSpace}-retry`} padding='Fluid' onClick={closeModal}>
                {t('common:close')}
              </Button.Primary>
            )}
          </div>
        )}
      </div>
    </>
  )
}
