import React, { FunctionComponent, useContext } from 'react'
import { Button, Divider, Heading, Icon } from '@afterpaytouch/core'
import { useConsumerSizes } from '../../../utils/responsive'
import { OrderRefundModalContext } from '../OrderRefundModalContext'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'

const I18N_NAMESPACE = ['orders']

interface OrderRefundNavigationProps {
  handleBack?: () => void
  displayBack?: boolean
  heading: string
}

export const OrderRefundNavigation: FunctionComponent<OrderRefundNavigationProps> = ({ handleBack, heading, displayBack = true }) => {
  const { closeModal } = useContext(OrderRefundModalContext)
  const { sectionHeadingSize } = useConsumerSizes()
  const { t } = useTranslation(I18N_NAMESPACE)

  return (
    <>
      <div className={'flex items-center justify-between px-6'}>
        <div className={clsx(!displayBack && 'w-4')}>
          {displayBack && (
            <Button.Link
              size='Small'
              onClick={handleBack}
              testNameSpace='order-refund-nav-modal-back'
              aria-label={t('orders:returningOrderModal:aria:nav:goBack')}
            >
              <Icon.CaretLeft size='Small' />
            </Button.Link>
          )}
        </div>
        <Heading size={sectionHeadingSize} testNameSpace='order-refund-nav-modal-heading'>
          {heading}
        </Heading>
        <Button.Link size='Small' onClick={closeModal} testNameSpace='order-refund-nav-modal-close' aria-label={t('orders:returningOrderModal:aria:nav:close')}>
          <Icon.Close size='Small' />
        </Button.Link>
      </div>
      <Divider kind='Hairline' />
    </>
  )
}
