import React, { FunctionComponent, ReactNode } from 'react'
import * as SentryReact from '@sentry/react'
import type { Integration } from '@sentry/types'

interface ErrorBoundaryProps {
  dsn?: string
  fallbackError: JSX.Element
  children: ReactNode
}

interface SentryConfigurationProps {
  dsn?: string
  environment?: string
  version?: string
  ignoreHosts?: string[]
  integrations?: Integration[]
}

const getSentryConfiguration = ({ dsn, environment, version, ignoreHosts, integrations }: SentryConfigurationProps): SentryReact.BrowserOptions => {
  return {
    dsn,
    release: version,
    environment,
    integrations,
    tracesSampleRate: 0.1,
    autoSessionTracking: true,
    beforeBreadcrumb: (breadcrumb) => {
      if (breadcrumb.type === 'http' && breadcrumb.category === 'xhr') {
        try {
          const url = new URL(breadcrumb.data?.url)
          // @ts-ignore: OPERATION BLEED STOPPER
          if (ignoreHosts.includes(url.host)) {
            return null
          }
        } catch (e) {}
      }
      return breadcrumb
    },
  }
}

export const init = ({ dsn, environment, version, ignoreHosts, integrations }: SentryConfigurationProps): void => {
  if ((dsn ?? '').length > 0) {
    const sentryConfig = getSentryConfiguration({ dsn, environment, version, ignoreHosts, integrations })
    SentryReact.init(sentryConfig)
  }
}

export const ErrorBoundary: FunctionComponent<ErrorBoundaryProps> = ({ dsn, fallbackError, children }) => {
  if ((dsn ?? '').length === 0) {
    return <>{children}</>
  }

  // @ts-expect-error TODO: fix type error
  return <SentryReact.ErrorBoundary fallback={fallbackError}>{children}</SentryReact.ErrorBoundary>
}

export const withSentryProfiler = SentryReact.withProfiler

export const Sentry = {
  init,
  ErrorBoundary,
}

export default Sentry
