import { apiService as api } from '@afterpaytouch/portal-api/api'
import { createPortalApi, createTopazApi, createShopApi } from './request'
import { apiService as topaz } from '@afterpaytouch/topaz-api/api'
import { apiService as shop } from '@afterpaytouch/shop-api/api'

// This file is to initialise the api services
// We're not putting this inside store.ts because of circular dependency
export const apiService = api.setBaseQueryFn(createPortalApi())

export const { reducer: apiReducer, middleware: apiMiddleware } = apiService.service

// Not sure this will be required for shop and topaz.
// It's in portal-api because both the checkout and portal apps need to run custom app specific middleware (mostly token handling logic via Axios interceptors)
// The setup could be greatly simplified if we omit these. We can revisit and clean this logic in the future.
export const topazService = topaz.setBaseQueryFn(createTopazApi())

export const { reducer: topazReducer, middleware: topazMiddleware } = topazService.service

export const shopService = shop.setBaseQueryFn(createShopApi())

export const { reducer: shopReducer, middleware: shopMiddleware } = shopService.service
