import { FetchCategoriesResponse } from '@afterpaytouch/shop-api'
import { useGetCategoriesQuery } from '../../state'
import { useRouter } from 'next/router'
import { SupportedLocale } from '../../model'
import { useTranslation } from 'next-i18next'

const I18N_NAMESPACE = ['index']

export interface IUseGetCategories {
  category?: `index:stores:category:${'trending' | 'new' | 'most-popular'}`
  getCategoriesData: FetchCategoriesResponse
  isGetCategoriesDataLoading: boolean
  isGetCategoriesDataError: boolean
  refetchGetCategories: () => void
}

export const useGetCategories = ({ category = 'index:stores:category:trending' }): IUseGetCategories => {
  const router = useRouter()
  const { t } = useTranslation(I18N_NAMESPACE)

  const {
    data: getCategoriesData,
    isLoading: isGetCategoriesDataLoading,
    isError: isGetCategoriesDataError,
    refetch: refetchGetCategories,
  } = useGetCategoriesQuery({
    locale: router?.locale as SupportedLocale,
    category: t(`${category}`),
  })

  return {
    // @ts-ignore: OPERATION BLEED STOPPER
    getCategoriesData,
    isGetCategoriesDataLoading,
    isGetCategoriesDataError,
    refetchGetCategories,
  }
}
