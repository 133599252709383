import React, { FunctionComponent, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { Modal, Button, Text } from '@afterpaytouch/core'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { AchFlowNavigation } from '../AchFlowNavigation'
import { AchFlowModalContext } from '../AchFlowModalContext'

const I18N_NAMESPACE = ['ach']

export const Error: FunctionComponent<{ refetch: () => void }> = ({ refetch }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { result } = useContext(AchFlowModalContext)
  const amplitude = useAmplitudeWithEnduringEventProperties()

  return (
    <>
      <Modal.Header divider>
        <AchFlowNavigation />
      </Modal.Header>
      <Modal.Content>
        {/* Show the specific message if httpStatusCode is 412, means there is no valid accounts */}
        {result.httpStatusCode === 412 ? (
          <>
            <div className='mb-4'>
              <Text>{t('ach:linkBankAccount:error:detail1NoAccount')}</Text>
            </div>
            <div className='mb-4'>
              <Text>{t('ach:linkBankAccount:error:detail2NoAccount')}</Text>
            </div>
          </>
        ) : (
          <div className='mb-4'>
            <Text>{t('ach:linkBankAccount:error:link')}</Text>
          </div>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button
          padding='Fluid'
          onClick={() => {
            refetch()
            amplitude.logEvent(TrackingEvent.ACH.ClickedTryAgainInLinkBankAccountError)
          }}
          testNameSpace='ach-link-bank-account-error-cta'
        >
          {t('ach:linkBankAccount:error:CTA')}
        </Button>
      </Modal.Footer>
    </>
  )
}
