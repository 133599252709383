import { setCookie, parseCookies } from 'nookies'
import { useEffect } from 'react'
import { COOKIE_SHOW_NEW_PORTAL } from '../../utils/redirects'
import { getPortalMigrationEnabled } from '../../utils/convergence'
import { useRouter } from 'next/router'
import { getCountryCodeFromLocale } from '../../utils/consumer'

export const useNewPortalRolloutCookie = (): void => {
  const router = useRouter()
  const { locale } = router
  const country = getCountryCodeFromLocale(locale)
  const now = Date.now()
  // TODO clean code once login FF is fully rollout IDENTITY-2438
  const loginMigrationEnabled = getPortalMigrationEnabled(country)
  let showNewPortal: string
  // with the new portal migration rollout, should always show the new portal
  if (loginMigrationEnabled) {
    showNewPortal = 'true'
  } else {
    showNewPortal = parseCookies()[COOKIE_SHOW_NEW_PORTAL] ?? 'false'
  }
  useEffect(() => {
    setCookie(null, COOKIE_SHOW_NEW_PORTAL, showNewPortal, {
      path: '/',
      secure: true,
      sameSite: 'none',
      expires: new Date(now + 1000 * 60 * 60 * 24 * 365),
    })
  }, [now])
}
