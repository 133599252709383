import React, { FunctionComponent, useState } from 'react'
import { Modal, Heading, Text, Button } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import styles from './style.module.scss'
import { NewTabLink } from '../NewTabLink'
import { useConsumerSizes } from '../../utils/responsive'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'

export interface OfferModalInfo {
  offerTitle?: string
  offerDetail?: string
  offerTerms?: string
  offerLogo?: string
  storeName?: string
  shopUrl?: string
}

export interface OfferModalProps {
  show: boolean
  onClose: () => void
  modalInfo?: OfferModalInfo
}

const I18N_NAMESPACE = ['index']

export const OfferModal: FunctionComponent<OfferModalProps> = ({ show, onClose, modalInfo = {} }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const { headingSize } = useConsumerSizes()
  const [isImgBroken, setIsImgBroken] = useState(false)
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  if (modalInfo == null || Object.keys(modalInfo).length === 0) {
    return null
  }

  const handleImgError = (): void => {
    setIsImgBroken(true)
  }

  const onClickButton = (): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    logEvent(TrackingEvent.CLICKED_SHOP_TILE, { outboundLink: modalInfo.shopUrl, context: 'new_web_portal' })
  }

  return (
    <div className={styles.offerModal}>
      <Modal show={show} onClose={onClose} onHide={onClose} onBackdropClick={onClose} onEscapeKeyDown={onClose}>
        <Modal.Content>
          <div className={styles.offerLogo}>
            {!isImgBroken && <img src={modalInfo.offerLogo} alt={modalInfo.storeName} onError={handleImgError} />}
            {isImgBroken && <Text>{modalInfo.storeName}</Text>}
          </div>
          <Heading size={headingSize}>{modalInfo.offerTitle}</Heading>
          <div className={styles.offerDetail}>
            <Text>{modalInfo.offerDetail}</Text>
          </div>
          <div className={styles.offerTerms}>
            <Text size='XS'>{modalInfo.offerTerms}</Text>
          </div>
          {/* @ts-ignore: OPERATION BLEED STOPPER */}
          <NewTabLink href={modalInfo.shopUrl}>
            <Button padding='Fluid' onClick={onClickButton}>
              {t('index:stores:shop')}
            </Button>
          </NewTabLink>
        </Modal.Content>
      </Modal>
    </div>
  )
}
