import React, { useEffect, FunctionComponent, useCallback, useContext } from 'react'
import { Modal } from '@afterpaytouch/core'
import { CloseCreditFacilityFlowModalProvider, CloseCreditFacilityFlowContext, CloseCreditFacilityFlowSteps } from './PayMonthlyCloseCreditFacilityContext'
import { SelectCloseCreditFacilityReason } from './SelectCloseCreditFacilityReason'
import { ConfirmCloseCreditFacility } from './ConfirmCloseCreditFacility'
import { IneligibleCloseCreditFacility } from './IneligibleCloseCreditFacility'
import { useGetCloseCreditFacilityEligibilityQuery } from '../../state'

export interface CloseCreditFacilityFlowProps {
  show: boolean
  closeModal: () => void
  openDate: string
}

export const PayMonthlyCloseCreditFacilitySteps: FunctionComponent = () => {
  const { step, resetState, show, closeModal } = useContext(CloseCreditFacilityFlowContext)
  const { data, isFetching, isLoading } = useGetCloseCreditFacilityEligibilityQuery()

  const renderStep = useCallback((): React.ReactNode => {
    switch (step) {
      case CloseCreditFacilityFlowSteps.SelectCloseCreditFacilityReason:
        return <SelectCloseCreditFacilityReason />
      case CloseCreditFacilityFlowSteps.ConfirmCloseCreditFacility:
        return <ConfirmCloseCreditFacility />
      case CloseCreditFacilityFlowSteps.IneligibleCloseCreditFacility:
        return <IneligibleCloseCreditFacility />
    }
  }, [step])

  useEffect(() => {
    if (!show && data != null && !isFetching && !isLoading) {
      resetState(data)
    }
  }, [show, data, resetState])

  return (
    <Modal show={show} onClose={closeModal} onHide={closeModal} onEscapeKeyDown={closeModal} testNameSpace='close-credit-facility'>
      {renderStep()}
    </Modal>
  )
}

export const PayMonthlyCloseCreditFacilityModal: FunctionComponent<CloseCreditFacilityFlowProps> = ({ show = false, closeModal, openDate }) => {
  return (
    <CloseCreditFacilityFlowModalProvider show={show} closeModal={closeModal} openDate={openDate}>
      <PayMonthlyCloseCreditFacilitySteps />
    </CloseCreditFacilityFlowModalProvider>
  )
}
