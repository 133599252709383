import { DeviceDetails } from '@afterpaytouch/portal-api'
import { isMobile } from '@afterpaytouch/core'
import { isValidObject } from './object'

// TODO: might need to be changed, what do we want to submit to backend here?
const getPlugins = (): any[] => {
  let index
  const plugins = []
  for (index = 0; index < navigator.plugins.length; index++) {
    // @ts-ignore: OPERATION BLEED STOPPER
    plugins.push({
      name: navigator.plugins[index].name,
      filename: navigator.plugins[index].filename,
    })
  }
  return plugins
}

export const deviceDetails = (): DeviceDetails => {
  return {
    ua: navigator?.userAgent?.toLowerCase(),
    os: navigator.platform,
    cn: navigator.appCodeName,
    v: navigator?.appVersion?.toLowerCase(),
    ce: navigator.cookieEnabled,
    l: navigator.language,
    o: navigator.onLine,
    je: navigator?.javaEnabled(),
    screen: {
      cd: screen.colorDepth,
      w: screen.width,
      h: screen.height,
      ha: screen.availHeight,
      wa: screen.availWidth,
    },
    tz: new Date().getTimezoneOffset(),
    plugins: getPlugins(),
  }
}

const uaMatch = (reg: RegExp): boolean => {
  return isValidObject(navigator) && reg.test(navigator.userAgent)
}

const vendorMatch = (reg: RegExp): boolean => {
  return isValidObject(navigator) && reg.test(navigator.vendor)
}

export const isMobileChrome = (): boolean => {
  return vendorMatch(/Google/i) && isMobile()
}

export const isMobileSafari = (): boolean => {
  return vendorMatch(/Apple/i) && isMobile()
}

export const isMobileSamsung = (): boolean => {
  return uaMatch(/SamsungBrowser/i) && isMobile()
}
