import { setCookie, parseCookies, destroyCookie } from 'nookies'
import { locales } from '../../i18n.config'
import { getLocaleFromHref } from './url'
import { Country } from '@afterpay/types'
import { app } from '../env'

export const COOKIE_SHOW_NEW_PORTAL = 'x-apt-new-portal'
export const COOKIE_SHOW_OLD_PORTAL = 'x-apt-old-portal'

const setCookieToShowOldPortal = (): void => {
  setCookie(null, COOKIE_SHOW_OLD_PORTAL, JSON.stringify(true), {
    maxAge: 60 * 60, // 1 hour
    sameSite: 'lax',
    path: '/',
  })
}

// In the old portal, some path like login/idv flow, it won't proceed the redirect even if the COOKIE_SHOW_NEW_PORTAL is true. Redirect to the old portal with the cookie set to true to force the redirect once the consumer finish login/idv.
export const redirectToOldPortalWithCookie = (destination: string = '', isAllowed: boolean = false): void => {
  const cookies = parseCookies()
  const stringifyFlag = JSON.stringify(isAllowed)
  if (cookies[COOKIE_SHOW_NEW_PORTAL] !== stringifyFlag) {
    // TODO clean code once login FF is fully rollout IDENTITY-2438
    setCookie(null, COOKIE_SHOW_NEW_PORTAL, stringifyFlag, {
      maxAge: 60 * 60, // 1 hour
      sameSite: 'lax',
      path: '/',
    })
  }
  setCookieToShowOldPortal()
  const oldPortalUrl = getOldPortalUrl(destination)
  window.location.href = oldPortalUrl
}

export const getOldPortalUrl = (destination: string = ''): string => {
  // Redirect to old portal at origin with new locale mapped to old locale and any deeplinks applied
  const baseUrl: string = window.location.origin
  const currentLocale = getLocaleFromHref(window.location.href)
  // @ts-ignore: OPERATION BLEED STOPPER
  const oldLocale: string = locales.find((l) => l.locale === currentLocale).redirect
  const validDestination = destination !== '' ? '/' + destination : ''
  return `${baseUrl}/${oldLocale}${validDestination}`
}

export const navigateToShop = (): void => {
  window.location.href = app.SHOP_URL
}

export const switchToOldPortal = (destination: string = ''): void => {
  // Delete Cloudflare cookie
  destroyCookie(null, COOKIE_SHOW_NEW_PORTAL)
  setCookieToShowOldPortal()
  const oldPortalUrl = getOldPortalUrl(destination)
  window.location.href = oldPortalUrl
}

export const getPortalUrlViaCountry = (country: Country): string => {
  // TODO review these url while other region migration finished
  switch (country) {
    case 'GB':
      // return 'https://portal.afterpay.com/en-GB'
      return 'https://portal.clearpay.com/uk/login-email'
    case 'US':
      return 'https://portal.afterpay.com/en-US'
    case 'NZ':
      // return 'https://portal.afterpay.com/en-NZ'
      return 'https://portal.afterpay.com/nz/login-email'
    case 'CA':
      return 'https://portal.afterpay.com/en-CA'
    case 'FR':
      // return 'https://portal.afterpay.com/fr-FR'
      return 'https://portal.afterpay.com/fr/login-email'
    case 'ES':
      // return 'https://portal.afterpay.com/es-ES'
      return 'https://portal.afterpay.com/es/login-email'
    case 'IT':
      // return 'https://portal.afterpay.com/it-IT'
      return 'https://portal.afterpay.com/it/login-email'
    default:
      // return 'https://portal.afterpay.com/en-AU'
      return 'https://portal.afterpay.com/au/login-email'
  }
}
