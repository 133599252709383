import React, { FunctionComponent, useState, ReactNode } from 'react'
import styles from './style.module.scss'
import clsx, { ClassValue } from 'clsx'
import { Trans, useTranslation } from 'next-i18next'
import Link from 'next/link'
import { Paginate } from '../Paginate'
import {
  Heading,
  Icon,
  Skeleton,
  Tag,
  Text,
  TextSizes,
  useDate,
  useIsBreakpointLargeAndUp,
  Link as CoreLink,
  Dropdown,
  Panel,
  useThemeClass,
} from '@afterpaytouch/core'
import { Table } from '../DataTable'
import {
  useGetOrderTransactionsQuery,
  useActiveOrders,
  useOrdersCompletedOrders,
  useConsumerAccountIsInHardship,
  useConsumerHasBothPclAndNonPclOrders,
} from '../../state'
import { Money } from '../Money'
import { defaultOrdersParams, getMerchantName, GetOrderTransactionsQuery } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import { OrdersResult } from '@afterpaytouch/portal-api/types'
import { getOrderChannelLabel } from '../../state/orders/utils'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { useRouter } from 'next/router'
import { StoreLogo } from '../StoreLogo'
import { OrdersListHead } from '../OrdersListHead'
import { useFlag } from '../../hooks'

const I18N_NAMESPACE = ['common', 'orders']

interface DataCellProps {
  bold?: boolean
  order: OrdersResult
  size?: TextSizes
  testNameSpace?: string
  children: ReactNode
}

const OrderDataCell: FunctionComponent<DataCellProps> = ({ children, bold, order, size = 'M', testNameSpace = '' }) => {
  const { id } = order
  return (
    <Table.LinkDataCell href={'orders/[id]'} renderAs={`orders/${id}`}>
      <Text bold={bold} size={size} testNameSpace={`${testNameSpace}-data-cell`}>
        {children}
      </Text>
    </Table.LinkDataCell>
  )
}

const OrderTypeFilters = {
  All: 'All',
  PayInFour: 'PayInFour',
  PayMonthly: 'PayMonthly',
} as const
type OrderTypeFilter = (typeof OrderTypeFilters)[keyof typeof OrderTypeFilters]

const OrderStatusFilters = {
  All: 'All',
  Active: 'Active',
  Inactive: 'Inactive',
} as const
type OrderStatusFilter = (typeof OrderStatusFilters)[keyof typeof OrderStatusFilters]

export const OrdersList: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const router = useRouter()
  const themeClass = useThemeClass()
  const initialPaginationParamsState: GetOrderTransactionsQuery = defaultOrdersParams
  const [paginationParams, setPaginationParams] = useState<GetOrderTransactionsQuery>(initialPaginationParamsState)

  const [orderTypeFilter, setOrderTypeFilter] = useState<OrderTypeFilter>(OrderTypeFilters.All)
  const [orderStatusFilter, setOrderStatusFilter] = useState<OrderStatusFilter>(OrderStatusFilters.All)

  const isOrdersFilterRowEnabled = useFlag('consumer-portal-order-page-filter-chips-enabled', false)

  const isOrderTypeFilterAll = orderTypeFilter === OrderTypeFilters.All
  const isOrderTypeFilterPayInFour = orderTypeFilter === OrderTypeFilters.PayInFour
  const isOrderTypeFilterPayMonthly = orderTypeFilter === OrderTypeFilters.PayMonthly

  const isOrderStatusFilterAll = orderStatusFilter === OrderStatusFilters.All
  const isOrderStatusFilterActive = orderStatusFilter === OrderStatusFilters.Active
  const isOrderStatusFilterInactive = orderStatusFilter === OrderStatusFilters.Inactive

  const defaultOrderFilterParam = false

  const {
    data: getOrderTransactionsQueryData,
    isSuccess: isGetOrderTransactionsQuerySuccess,
    isFetching: isGetOrderTransactionsQueryFetching,
    isLoading: isGetOrderTransactionsQueryLoading,
  } = useGetOrderTransactionsQuery(
    {
      ...paginationParams,
      payInFour: isOrderTypeFilterAll ? defaultOrderFilterParam : isOrderTypeFilterPayInFour,
      payMonthly: isOrderTypeFilterAll ? defaultOrderFilterParam : isOrderTypeFilterPayMonthly,
      active: isOrderStatusFilterAll ? defaultOrderFilterParam : isOrderStatusFilterActive,
      inactive: isOrderStatusFilterAll ? defaultOrderFilterParam : isOrderStatusFilterInactive,
    },
    {
      refetchOnMountOrArgChange: false,
    }
  )

  const isGetOrdersLoading = isGetOrderTransactionsQueryFetching || isGetOrderTransactionsQueryLoading

  const activeOrders = useActiveOrders(paginationParams)
  const completedOrders = useOrdersCompletedOrders(paginationParams)
  const isBpLargeUp = useIsBreakpointLargeAndUp()
  const classNames: ClassValue = clsx(styles.container, isBpLargeUp && styles.isBpLargeUp)
  const { formatUnixDate } = useDate({ locale: 'en-AU', year: 'numeric' })
  // @ts-ignore: OPERATION BLEED STOPPER
  const showPagination = getOrderTransactionsQueryData?.totalResults > 10
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const isHardship = useConsumerAccountIsInHardship()
  const hasBothPclAndNonPclOrders = useConsumerHasBothPclAndNonPclOrders()

  const getNextPageData = ({ offset }: GetOrderTransactionsQuery): void => {
    setPaginationParams({ ...paginationParams, offset })
  }

  const amplitudePaginationEvent = (pageNumber: number): void => {
    logEvent(TrackingEvent.CLICKED_ORDERS_LIST_PAGINATION, {
      pageNumber,
    })
  }

  const amplitudeOrderEvent = (order: OrdersResult, isActiveOrder: boolean): void => {
    const { id: orderId, paymentType } = order
    logEvent(TrackingEvent.CLICKED_ORDER_DETAIL, {
      orderId,
      paymentType,
      isActiveOrder,
      outboundLink: router.pathname,
    })
  }

  const hardshipHeading = (): JSX.Element => {
    return (
      <>
        <div className='flex'>
          <div className='mr-3'>
            <Icon name='WarningAlt' color='Fire' size='Small' />
          </div>
          <Text color='Fire' size={isBpLargeUp ? 'M' : 'S'} testNameSpace='hardship-heading'>
            <Trans
              i18nKey={'orders:orderList:hardship:header'}
              components={{ supportLink: <CoreLink href={t('orders:orderList:hardship:supportLinkHref')} target='_blank' kind='Underline' /> }}
            />
          </Text>
        </div>
        <div className={styles.divider}></div>
      </>
    )
  }

  const renderOrdersFilterRow = (): JSX.Element => {
    const onSelectOrderTypeFilter = (selected: OrderTypeFilter): void => setOrderTypeFilter(selected)
    const onSelectOrderStatusFilter = (selected: OrderStatusFilter): void => setOrderStatusFilter(selected)

    return (
      <div className='flex flex-wrap gap-x-2 gap-y-2' data-testid='order-list-filter-row'>
        {hasBothPclAndNonPclOrders && (
          <div className='w-52'>
            <Dropdown
              size='Medium'
              testNameSpace='order-list-filter-type'
              onSelect={onSelectOrderTypeFilter}
              label={t('orders:orderList:filters:type:label')}
              disableNativeLabel
              scrollIntoView={false}
            >
              <Dropdown.MenuItem value={OrderTypeFilters.All}>{t('orders:orderList:filters:type:all')}</Dropdown.MenuItem>
              <Dropdown.MenuItem value={OrderTypeFilters.PayInFour}>{t('orders:orderList:filters:type:payInFour')}</Dropdown.MenuItem>
              <Dropdown.MenuItem value={OrderTypeFilters.PayMonthly}>{t('orders:orderList:filters:type:payMonthly')}</Dropdown.MenuItem>
            </Dropdown>
          </div>
        )}
        <div className='w-52'>
          <Dropdown
            size='Medium'
            testNameSpace='order-list-filter-status'
            onSelect={onSelectOrderStatusFilter}
            label={t('orders:orderList:filters:status:label')}
            disableNativeLabel
            scrollIntoView={false}
          >
            <Dropdown.MenuItem value={OrderStatusFilters.All} testNameSpace='order-list-filter-status'>
              {t('orders:orderList:filters:status:all')}
            </Dropdown.MenuItem>
            <Dropdown.MenuItem value={OrderStatusFilters.Active} testNameSpace='order-list-filter-status'>
              {t('orders:orderList:filters:status:active')}
            </Dropdown.MenuItem>
            <Dropdown.MenuItem value={OrderStatusFilters.Inactive} testNameSpace='order-list-filter-status'>
              {t('orders:orderList:filters:status:inactive')}
            </Dropdown.MenuItem>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderDesktopList = (): JSX.Element => {
    const tableHeadings: string[] = Array.from(Array(6).keys()).map((n) => t(`orders:orderList:tableHeadings:${n}`))
    const isActiveOrder = (amountOwing): boolean => amountOwing > 0
    return (
      <>
        {isHardship && hardshipHeading()}
        {isOrdersFilterRowEnabled && <div className='mb-6'>{renderOrdersFilterRow()}</div>}
        <Table>
          <Table.Head>
            <Table.Row>
              {tableHeadings?.map((tableHeading, i) => (
                <Table.HeaderCell key={`table-row-headercell-${i}`} scope={'col'}>
                  <Text bold={true} size={'S'} color={'Gray40'}>
                    {tableHeading}
                  </Text>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Content>
            {isGetOrderTransactionsQuerySuccess &&
              getOrderTransactionsQueryData.results.map((order) => (
                <React.Fragment key={order.id}>
                  <Table.Row amplitudeFn={() => amplitudeOrderEvent(order, isActiveOrder(order.owedAmount.amount))} testNameSpace={'order-list'}>
                    <OrderDataCell order={order} bold={true} testNameSpace='merchant'>
                      <div className='flex items-center gap-x-4'>
                        {isGetOrdersLoading ? (
                          <div className={'bg-dark-prominent relative flex h-11 w-11 items-center justify-center rounded-[4px]'}>
                            <Skeleton />
                          </div>
                        ) : (
                          <StoreLogo merchant={order?.merchant} />
                        )}
                        <span className='flex-1'>
                          {/* TODO: Investigate replacing CSS prop 'white-space' with 'word-break' */}
                          <Heading lineClamp={1}>{getMerchantName(order?.merchant)}</Heading>
                        </span>
                      </div>
                    </OrderDataCell>
                    <OrderDataCell order={order} testNameSpace='order-status'>
                      {isActiveOrder(order.owedAmount.amount) && !isHardship && <Tag kind='Black'>{t('orders:orderList:active')}</Tag>}
                      {isActiveOrder(order.owedAmount.amount) && isHardship && <Tag kind='Gray'>{t('orders:orderList:paused')}</Tag>}
                    </OrderDataCell>
                    <OrderDataCell order={order} testNameSpace='purchase-date'>
                      {formatUnixDate(order.orderDate)}
                    </OrderDataCell>
                    <OrderDataCell order={order}>
                      {t(`common:channel:${getOrderChannelLabel(order.channel)}`)} #{order.id}
                    </OrderDataCell>
                    <OrderDataCell order={order}>
                      <Money size='M' value={order.totalAmount} />
                    </OrderDataCell>
                    <OrderDataCell order={order}>
                      <Money size='M' value={order.owedAmount} />
                    </OrderDataCell>
                    <Table.LinkDataCell href='orders/[id]' renderAs={`orders/${order.id}`}>
                      <Icon.CaretRight title='caret right to details page' size='ExtraSmall' color='Gray80' role='img' />
                    </Table.LinkDataCell>
                  </Table.Row>
                </React.Fragment>
              ))}
          </Table.Content>
        </Table>
        {showPagination && (
          <>
            <div className={styles.divider}></div>
            <div className={styles.paginationContainer}>
              {getOrderTransactionsQueryData !== null && (
                <Paginate
                  // @ts-ignore: OPERATION BLEED STOPPER
                  total={getOrderTransactionsQueryData.totalResults}
                  // @ts-ignore: OPERATION BLEED STOPPER
                  limit={getOrderTransactionsQueryData.limit}
                  // @ts-ignore: OPERATION BLEED STOPPER
                  offset={getOrderTransactionsQueryData.offset}
                  onSelect={getNextPageData}
                  amplitudeFn={amplitudePaginationEvent}
                />
              )}
            </div>
          </>
        )}
      </>
    )
  }

  const renderMobileList = (): JSX.Element => {
    return (
      <div>
        {isHardship && hardshipHeading()}
        {isOrdersFilterRowEnabled && <div className='mb-6'>{renderOrdersFilterRow()}</div>}
        <ul className={styles.orderListMobile}>
          {Boolean(activeOrders?.length) &&
            activeOrders.map((activeOrder) => (
              <li className={styles.orderListItem} key={`active-order-${activeOrder.id}`} onClick={() => amplitudeOrderEvent(activeOrder, true)}>
                <Link href={'orders/[id]'} as={`orders/${activeOrder.id}`} prefetch={false} className={clsx(styles.linkDataCell, 'text-dark')}>
                  <div className={styles.orderDetail}>
                    <div className='flex'>
                      <div className='flex items-center gap-x-2'>
                        {isGetOrdersLoading ? (
                          <div className={'bg-gray-96 relative flex h-11 w-11 items-center justify-center rounded-[4px]'}>
                            <Skeleton />
                          </div>
                        ) : (
                          <StoreLogo merchant={activeOrder?.merchant} />
                        )}
                        <div>
                          <Heading size='S' lineClamp={1}>
                            {getMerchantName(activeOrder?.merchant)}
                          </Heading>
                          <Text size='S' lineClamp={1}>
                            {formatUnixDate(activeOrder.orderDate)}
                          </Text>
                        </div>
                      </div>
                      <div className='mx-4'>
                        <Tag kind='Black'>{t('orders:orderList:active')}</Tag>
                      </div>
                      <div className='flex flex-1 flex-col text-right'>
                        <Text size='S'>
                          <Money size='S' bold value={activeOrder.owedAmount} />
                        </Text>
                        <Text size='S'>{t('orders:orderList:owing')}</Text>
                      </div>
                    </div>
                  </div>
                  <div className={styles.icon}>
                    <Icon.CaretRight title={t('orders:orderList:caretRightTitle')} size='ExtraSmall' color='Gray80' role='img' />
                  </div>
                </Link>
              </li>
            ))}
          {Boolean(completedOrders?.length) && (
            <>
              <div className={styles.divider}></div>
              {completedOrders.map((completedOrder) => (
                <li className={styles.orderListItem} key={`completed-order-${completedOrder.id}`} onClick={() => amplitudeOrderEvent(completedOrder, false)}>
                  <Link href={'orders/[id]'} as={`orders/${completedOrder.id}`} prefetch={false} className={clsx(styles.linkDataCell, 'text-dark')}>
                    <div className={styles.orderDetail}>
                      <div className='flex'>
                        <div className='flex items-center gap-x-2'>
                          {isGetOrdersLoading ? (
                            <div className={'bg-gray-96 relative flex h-11 w-11 items-center justify-center rounded-[4px]'}>
                              <Skeleton />
                            </div>
                          ) : (
                            <StoreLogo merchant={completedOrder?.merchant} />
                          )}
                          <div>
                            <Heading size='S' lineClamp={1}>
                              {getMerchantName(completedOrder?.merchant)}
                            </Heading>
                            <Text size='S' lineClamp={1}>
                              {formatUnixDate(completedOrder.orderDate)}
                            </Text>
                          </div>
                        </div>
                        <div className='flex flex-1 flex-col text-right'>
                          <Text size={'S'}>
                            <Money size='S' value={completedOrder.totalAmountAfterRefundsAndDiscounts} />
                          </Text>
                          <Text size={'S'} color={'Moss Gem'}>
                            {t('orders:orderList:paid')}
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className={styles.icon}>
                      <Icon.CaretRight title={'caret right to details page'} size={'ExtraSmall'} color={'Gray80'} role='img' />
                    </div>
                  </Link>
                </li>
              ))}
            </>
          )}
          {showPagination && (
            <>
              <div className={styles.divider}></div>
              <div className={styles.paginationContainer}>
                {getOrderTransactionsQueryData !== null && (
                  <Paginate
                    // @ts-ignore: OPERATION BLEED STOPPER
                    total={getOrderTransactionsQueryData.totalResults}
                    // @ts-ignore: OPERATION BLEED STOPPER
                    limit={getOrderTransactionsQueryData.limit}
                    // @ts-ignore: OPERATION BLEED STOPPER
                    offset={getOrderTransactionsQueryData.offset}
                    onSelect={getNextPageData}
                    amplitudeFn={amplitudePaginationEvent}
                  />
                )}
              </div>
            </>
          )}
        </ul>
      </div>
    )
  }

  return (
    <div className={classNames}>
      <Panel testNameSpace='order-list'>
        <Panel.Head>
          <OrdersListHead />
        </Panel.Head>
        <Panel.Content bordered={true}>{isBpLargeUp ? renderDesktopList() : renderMobileList()}</Panel.Content>
      </Panel>
    </div>
  )
}
