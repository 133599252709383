import React, { FunctionComponent } from 'react'
import styles from './style.module.scss'
import { Skeleton } from '@afterpaytouch/core'

export const EmptyCardSkeleton: FunctionComponent = () => {
  return (
    <div className={styles.cardEmpty}>
      <div className={styles.content}>
        <Skeleton />
      </div>
      <div className={styles.footer}>
        <Skeleton />
      </div>
    </div>
  )
}
