import { PhoneNumberConfig, usePhoneNumber } from '@afterpaytouch/core'
import { SupportedLocale } from '@afterpaytouch/portal-api'
import { useRouter } from 'next/router'
import { mapLocales } from '../../utils/mapLocales'

export const useFormatMobile = (mobile: string): string => {
  const country = mapLocales('locale', useRouter()?.locale as SupportedLocale)
  const { formatNational } = usePhoneNumber({ country } as PhoneNumberConfig)
  return formatNational(mobile) ?? mobile
}
