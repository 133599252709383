import React, { FunctionComponent, useEffect, useRef } from 'react'
import { Skeleton, Text, Divider, useTooltip, Heading, Icon, Span } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { Money } from '../../Money'
import { useFlag } from '../../../hooks'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { ShellButton } from '../../ShellButton/ShellButton'
import { CreditFacility } from '@afterpaytouch/portal-api'
import { Route } from '../../../config/router'
import { AppLink } from '../../AppLink'
import { TrackingEvent } from '../../../model/amplitude'

const I18N_NAMESPACE = ['common', 'payMonthly']

export const PayMonthlyLimit: FunctionComponent<{
  creditFacility?: CreditFacility
  isLoading: boolean
}> = ({ creditFacility, isLoading }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isDecreaseLimitFlagEnabled = useFlag('consumer-portal-pay-monthly-decrease-limit-enabled', false)
  const isConsumerDecreaseLimitEnabled = Boolean(creditFacility?.decreaseLimit)
  const isDecreaseLimitEnabled = isDecreaseLimitFlagEnabled && isConsumerDecreaseLimitEnabled
  const isDecreaseLimitDisabled = isDecreaseLimitFlagEnabled && !isConsumerDecreaseLimitEnabled
  const amount = creditFacility?.limit != null ? `${creditFacility?.limit.symbol} ${creditFacility?.limit.amount}` : ''

  const testNameSpace = 'pay-monthly-panel-limit'

  const btnRef = useRef(null)
  const { showTooltip, unload } = useTooltip(btnRef, {
    caretPlacement: 'bottom-end',
  })

  useEffect(() => {
    return () => {
      unload()
    }
  }, [])

  return (
    <>
      <div className='relative'>
        <div className='flex justify-between'>
          <Text bold>{t('payMonthly:panel:content:details:limit')}</Text>
          <MaskedElement>
            <Money size={'M'} value={creditFacility?.limit ?? { amount: '0.00', currency: 'AUD', symbol: '$' }} />
          </MaskedElement>
        </div>
        {isDecreaseLimitEnabled && (
          <div className='flex justify-end'>
            <AppLink route={Route.PAY_MONTHLY_DECREASE_LIMIT} kind='Underline' trackEventName={TrackingEvent.CREDIT_FACILITY.PressedDecreaseClFacility}>
              {t('payMonthly:decreaseLimit:button')}
            </AppLink>
          </div>
        )}
        {isDecreaseLimitDisabled && (
          <div className='relative flex justify-end'>
            <div ref={btnRef}>
              {/* TODO confirmed with the designer about the shell button */}
              <ShellButton
                testNameSpace={`${testNameSpace}-disabled`}
                onClick={() =>
                  showTooltip(
                    <div className='w-80'>
                      <Heading>{t('payMonthly:decreaseLimit:unableToDecrease:title')}</Heading>
                      <Text>{t('payMonthly:decreaseLimit:unableToDecrease:description', { amount })}</Text>
                    </div>
                  )
                }
              >
                <Span size='S' bold color='Gray70'>
                  {t('payMonthly:decreaseLimit:button')}
                </Span>
                <span className='ml-1'>
                  <Icon name='Info' size='ExtraSmall' title={t('payMonthly:decreaseLimit:button')} />
                </span>
              </ShellButton>
            </div>
          </div>
        )}
        {isLoading && <Skeleton testNameSpace={`${testNameSpace}-skeleton`} />}
      </div>
      <Divider kind='Hairline' margin='Regular' />
    </>
  )
}
