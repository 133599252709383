import React, { useEffect, FunctionComponent, useCallback, useContext } from 'react'
import { Modal } from '@afterpaytouch/core'
import { ConfirmCloseAccount } from '../ConfirmCloseAccount'
import { SelectCloseAccountReason } from '../SelectCloseAccountReason'
import { CloseAccountFlowModalProvider, CloseAccountFlowContext, CloseAccountFlowSteps } from './CloseAccountFlowContext'

export interface CloseAccountFlowProps {
  show: boolean
  closeModal: () => void
  onSuccess?: () => void
}

export const CloseAccountSteps: FunctionComponent<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const { step, resetState, show, closeModal } = useContext(CloseAccountFlowContext)
  const renderStep = useCallback((): React.ReactNode => {
    switch (step) {
      case CloseAccountFlowSteps.SelectCloseAccountReason:
        return <SelectCloseAccountReason />
      case CloseAccountFlowSteps.ConfirmCloseAccount:
        return <ConfirmCloseAccount onSuccess={onSuccess} />
    }
  }, [onSuccess, step])

  useEffect(() => {
    if (!show) {
      resetState()
    }
  }, [show, resetState])

  return (
    <Modal show={show} onClose={closeModal} onHide={closeModal} onEscapeKeyDown={closeModal} testNameSpace='close-account-flow'>
      {renderStep()}
    </Modal>
  )
}

export const CloseAccountFlowModal: FunctionComponent<CloseAccountFlowProps> = ({ show = false, closeModal, onSuccess = () => {} }) => {
  return (
    <CloseAccountFlowModalProvider show={show} closeModal={closeModal}>
      <CloseAccountSteps onSuccess={onSuccess} />
    </CloseAccountFlowModalProvider>
  )
}
