import { AsyncAction, setIsApplePayAvailable } from '../../state'
import { app } from '../../env'

export const checkIfApplePayAvailable = (isEnabledForMPANApplePayFlow?: boolean): AsyncAction<void> => {
  return async (dispatch) => {
    const isSecure = window?.location?.protocol === 'https:'
    const { APPLE_PAY_MERCHANT_ID, APPLE_PAY_ACQUIRING_MERCHANT_ID } = app
    if (isSecure) {
      try {
        const applePaySession = window.ApplePaySession
        const applepayMerchantId = isEnabledForMPANApplePayFlow ? APPLE_PAY_ACQUIRING_MERCHANT_ID : APPLE_PAY_MERCHANT_ID
        const hasApplePaySetup = (await (window as any).ApplePaySession?.canMakePaymentsWithActiveCard(applepayMerchantId)) === true
        const eligibleSupportVersion = applePaySession?.supportsVersion(4)

        const isAvailable = typeof applePaySession !== 'undefined' && hasApplePaySetup && eligibleSupportVersion
        // @ts-ignore
        dispatch(setIsApplePayAvailable(isAvailable))
      } catch (e) {
        dispatch(setIsApplePayAvailable(false))
      }
    } else {
      dispatch(setIsApplePayAvailable(false))
    }
  }
}
