import React, { FunctionComponent, ReactNode } from 'react'
import { useNewPortalRolloutCookie } from '../../hooks'

interface NewPortalRolloutCookieProviderProps {
  children: ReactNode
}

export const NewPortalRolloutCookieProvider: FunctionComponent<NewPortalRolloutCookieProviderProps> = ({ children }) => {
  useNewPortalRolloutCookie()
  return <>{children}</>
}
