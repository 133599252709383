export const Weekdays = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
}

export const EntryPoint = {
  PaymentSettings: 'Payment Settings',
  Modal: 'Modal',
} as const

type EntryPointType = typeof EntryPoint
type EntryPointKeys = keyof EntryPointType
export type EntryPointProps = EntryPointType[EntryPointKeys]
