import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Grid, Heading, useIsBreakpointLargeAndUp, useIsCashTheme } from '@afterpaytouch/core'
import { useGetAmountDueSummaryQuery } from '../../state'
import { TotalOwingModal, useTotalOwingModal } from '../TotalOwingModal'
import { Money } from '../Money'

const I18N_NAMESPACE = ['common', 'orders']

interface OrdersListHeadProps {
  testNameSpace?: string
}

export const OrdersListHead: FunctionComponent<OrdersListHeadProps> = ({ testNameSpace = 'orders-list-head' }) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isCashTheme = useIsCashTheme()
  const { data } = useGetAmountDueSummaryQuery()
  const [isTotalOwingModalOpen, { openModal: openTotalOwingModal, closeModal: closeTotalOwingModal }] = useTotalOwingModal()
  const isBpLargeUp = useIsBreakpointLargeAndUp()

  return (
    <Grid center>
      <Grid.Item>
        <div className='mr-12'>
          {/* @ts-ignore: OPERATION BLEED STOPPER */}
          <Money testNameSpace={testNameSpace} size={isBpLargeUp ? 'XXXL' : 'XXL'} value={data?.total} bold />
          <Heading size='M'>{t('orders:orderList:totalOwing')}</Heading>
        </div>
      </Grid.Item>
      <Grid.Item sm='auto'>
        <Button kind={isCashTheme ? 'Primary' : 'Secondary'} testNameSpace={testNameSpace} size='Small' onClick={openTotalOwingModal}>
          {t('orders:orderList:totalOwingModal:cta:open')}
        </Button>
        {isTotalOwingModalOpen && <TotalOwingModal isModalOpen={isTotalOwingModalOpen} closeModal={closeTotalOwingModal} />}
      </Grid.Item>
    </Grid>
  )
}
