import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Heading, Skeleton, Text } from '@afterpaytouch/core'
import { useConsumerContactAddress, useConsumerSelector, useConsumerCountry, useUpdateAccountDetailMutation } from '../../../state'
import { FormWrapper } from '..'
import FormBuilder from '@afterpaytouch/form'
import { ErrorCode } from '@afterpaytouch/portal-api/types'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { getIdentityVerifyErrorCode, IdentityErrorCode } from '@afterpaytouch/portal-api/identity'
import { DocumentProps, ProfileErrorCode, ProfileErrors } from '../../../model'
import { getSchema, getVerifyAddressPayload } from './utils'
import { MaskedElement } from '@afterpaytouch/integrations/MaskedElement'
import { Wrapper } from '../Wrapper'
import { VerifyAddressFormData } from './types'
import { ErrorMessage } from '../VerifyIdentity/components'
import { isCallable } from '@afterpay/utils'

const I18N_NAMESPACE = ['common', 'verifyIdentity', 'addNewAddress']

export const VerifyAddress: FunctionComponent<DocumentProps> = ({ onSuccess, onFailure }) => {
  const amplitude = useAmplitudeWithEnduringEventProperties()
  const [lock, setLock] = useState(false)
  const [error, setError] = useState<IdentityErrorCode | null>(null)
  const { t } = useTranslation(I18N_NAMESPACE)
  const consumer = useConsumerSelector()
  const initialAddresses = useConsumerContactAddress()
  const country = useConsumerCountry()
  const [updateAccountDetails, { isError: isUpdateAccountDetailsError, isSuccess: isUpdateAccountDetailsSuccess, error: updateAccountDetailsError }] =
    useUpdateAccountDetailMutation()

  const { schema } = useMemo(() => {
    if (typeof initialAddresses === 'undefined' || initialAddresses == null || typeof country === 'undefined') {
      return { schema: undefined }
    }
    const components = {
      submit: () => (
        <Button.Primary loading={lock} type='submit' disabled={lock} padding='Fluid'>
          {t('verifyIdentity:partialVerified:cta')}
        </Button.Primary>
      ),
    }
    return getSchema(components, t, initialAddresses, country)
  }, [initialAddresses, t, country, lock])

  const onSubmit = async (data: VerifyAddressFormData): Promise<void> => {
    if (typeof country === 'undefined') {
      return
    }
    setError(null)
    amplitude.logEvent(TrackingEvent.PRESSED_SOFTID_LANDING_PAGE_ADDRESS_VERIFY_BUTTON)
    setLock(true)
    const payload = getVerifyAddressPayload(consumer, data, country)

    try {
      updateAccountDetails(payload)
    } catch (e) {
      const error = getIdentityVerifyErrorCode(e)
      if ([ErrorCode.MaxAttemptsReached, ErrorCode.MaxAttemptsReachedForType].includes(e?.data?.errorCode)) {
        return isCallable(onFailure) && onFailure()
      }
      setError(error ?? IdentityErrorCode.Default)
      setLock(false)
    }
  }

  useEffect(() => {
    if (isUpdateAccountDetailsError && updateAccountDetailsError !== null) {
      const errorCode = getIdentityVerifyErrorCode(updateAccountDetailsError)
      setError(errorCode == null ? ProfileErrorCode.Default : ProfileErrors[errorCode])
    }
    if (isUpdateAccountDetailsSuccess) {
      isCallable(onSuccess) && onSuccess()
    }
  }, [isUpdateAccountDetailsSuccess, isUpdateAccountDetailsError])

  useEffect(() => {
    if (typeof error !== 'undefined') {
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [error])

  return (
    <>
      <Heading>{t('verifyIdentity:partialVerified:address:heading')}</Heading>
      <Wrapper marginType='Small'>
        <Text>{t('verifyIdentity:partialVerified:address:description')}</Text>
      </Wrapper>
      <ErrorMessage identityType='licence' error={error} />
      <FormWrapper>
        <MaskedElement>
          {typeof schema !== 'undefined' ? (
            <FormBuilder schema={schema} testNameSpace={'verify-address-page'} onSubmit={onSubmit} disabled={lock} />
          ) : (
            <Skeleton kind='Primary' />
          )}
        </MaskedElement>
      </FormWrapper>
    </>
  )
}
