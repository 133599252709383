import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Dropdown, Heading, Modal, Text } from '@afterpaytouch/core'
import { SELECT_CLOSE_PAY_MONTHLY_REASONS, SelectClosePayMonthlyReasonProps } from '@afterpaytouch/portal-api/consumer/creditFacility/types'
import { CloseCreditFacilityFlowContext, CloseCreditFacilityFlowSteps } from '../PayMonthlyCloseCreditFacilityContext'
import { useGetCloseCreditFacilityEligibilityQuery } from '../../../state'

export const SelectCloseCreditFacilityReason: FunctionComponent = () => {
  const I18N_NAMESPACE = ['common', 'payMonthly']
  const { t } = useTranslation(I18N_NAMESPACE)

  const selectCloseCreditFacilityReasonsValue = Object.keys(SELECT_CLOSE_PAY_MONTHLY_REASONS)

  const reasonsTranslationMapping = {
    CLOSE_PAY_MONTHLY: t('payMonthly:closeCreditFacility:selectCloseCreditFacilityReason:dropdown:reason:0'),
    BREAK_FROM_AFTERPAY: t('payMonthly:closeCreditFacility:selectCloseCreditFacilityReason:dropdown:reason:1'),
    APPLYING_FOR_MORTGAGE: t('payMonthly:closeCreditFacility:selectCloseCreditFacilityReason:dropdown:reason:2'),
    UNABLE_TO_SHOP_RIGHT_NOW: t('payMonthly:closeCreditFacility:selectCloseCreditFacilityReason:dropdown:reason:3'),
  }

  const { setStep, setCloseCreditFacilityReason } = useContext(CloseCreditFacilityFlowContext)

  const testNameSpace = 'select-close-credit-facility-reason'
  const { data, isFetching, isLoading } = useGetCloseCreditFacilityEligibilityQuery()

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [continueButtonClicked, setContinueButtonClicked] = useState(false)

  useEffect(() => {
    if (continueButtonClicked) {
      if (data?.eligible === true) {
        setStep(CloseCreditFacilityFlowSteps.ConfirmCloseCreditFacility)
      }
      if (data?.eligible === false) {
        setStep(CloseCreditFacilityFlowSteps.IneligibleCloseCreditFacility)
      }
    }
  }, [continueButtonClicked, data])

  return (
    <>
      <Modal.Header divider>
        <div className='text-center'>
          <Heading size='L'>{t('payMonthly:closeCreditFacility:modal:title')}</Heading>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Text>{t('payMonthly:closeCreditFacility:selectCloseCreditFacilityReason:detail')}</Text>
        <div className='my-3'>
          <Text bold>{t('payMonthly:closeCreditFacility:selectCloseCreditFacilityReason:dropdown:title')}</Text>
        </div>
        <div className='flex flex-col content-around'>
          <div className='mb-6'>
            <Dropdown
              size='Medium'
              onSelect={(value) => {
                setCloseCreditFacilityReason(value as SelectClosePayMonthlyReasonProps)
                setIsButtonDisabled(false)
              }}
              testNameSpace={testNameSpace}
              id={`${testNameSpace}-dropdown`}
              defaultValue={selectCloseCreditFacilityReasonsValue[0]}
            >
              {selectCloseCreditFacilityReasonsValue.map((itemKey, index) => (
                <Dropdown.MenuItem key={index} value={itemKey}>
                  {reasonsTranslationMapping[itemKey]}
                </Dropdown.MenuItem>
              ))}
            </Dropdown>
          </div>
          <Button onClick={() => setContinueButtonClicked(true)} disabled={isButtonDisabled} testNameSpace={testNameSpace} loading={isFetching || isLoading}>
            {t('common:continue')}
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}
