import { Grid, Heading, InputOnFocus } from '@afterpaytouch/core'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent, useEffect, useState } from 'react'
import FormBuilder from '@afterpaytouch/form'
import { Channel, IdentityErrorCode, IdentityDocumentVerificationStatus, IdentityMedicareColour } from '@afterpaytouch/portal-api/identity'
import { getIdentityVerifyErrorCode } from '@afterpaytouch/portal-api/identity/utils'
import { FormWrapper } from '..'
import { ErrorMessage } from '../VerifyIdentity/components'
import { DocumentProps, SupportedLocale } from '../../../model'
import { useConsumerSelector, useIdentityMedicareMutation } from '../../../state'
import { formatNumberForBackend, getMedicareSchema, MedicareForm } from './utils'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'
import { isDifferentWithProfileDOB, isDifferentWithProfileNames } from '../VerifyIdentity/helper'
import { compareObjects } from '../../../utils/comparison'
import { USER_VERIFICATION_ERRORS } from '../VerifyIdentity/constants'
import { useConsumerSizes } from '../../../utils/responsive'

const I18N_NAMESPACE = ['common', 'verifyIdentity']

interface SchemaUpdates {
  selectedColour?: IdentityMedicareColour
  showProfileNameAlert: boolean
  showProfileDobAlert: boolean
}

const terminalErrors = new Set<IdentityErrorCode>([IdentityErrorCode.MaxAttemptsReached, IdentityErrorCode.MaxAttemptsReachedForType])

export const Medicare: FunctionComponent<DocumentProps> = ({ onSuccess = () => {} }) => {
  const amplitude = useAmplitudeWithEnduringEventProperties()
  const [lock, setLock] = useState(false)
  const [schemaUpdates, setSchemaUpdates] = useState<SchemaUpdates>({
    showProfileNameAlert: false,
    showProfileDobAlert: false,
  })
  // @ts-ignore: OPERATION BLEED STOPPER
  const [error, setError] = useState<IdentityErrorCode>(null)
  const { t } = useTranslation(I18N_NAMESPACE)
  const { givenNames, otherGivenNames, surname, dateOfBirth } = useConsumerSelector()
  const { headingSize, buttonSize } = useConsumerSizes()
  const router = useRouter()
  const [identityVerifyMedicare] = useIdentityMedicareMutation()
  const [submittedRequest, setSubmittedRequest] = useState(undefined)

  useEffect(() => {
    if (!terminalErrors.has(error)) {
      // Scroll to top
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [error])

  const profileDateOfBirth = dateOfBirth ?? ''

  const handleSubmit = async (data: MedicareForm): Promise<void> => {
    // @ts-ignore: OPERATION BLEED STOPPER
    setError(null)
    try {
      setLock(true)
      amplitude.logEvent(TrackingEvent.PRESSED_HARDID_MEDICARE_PAGE_VERIFY_BUTTON)

      const request = {
        ...data,
        channel: Channel.PORTAL,
        number: formatNumberForBackend(data.number),
      }

      if (compareObjects(request, submittedRequest)) {
        setLock(false)
        setError(IdentityErrorCode.IdentityAlreadyRejected)
        return
      }

      const response = await identityVerifyMedicare(request).unwrap()

      if (response.status === IdentityDocumentVerificationStatus.VERIFIED) {
        onSuccess()
      } else if (response.status === IdentityDocumentVerificationStatus.REJECTED) {
        setLock(false)
        // @ts-ignore: OPERATION BLEED STOPPER
        setSubmittedRequest(request)
        setError(IdentityErrorCode.IdentityCheckRejected)
      }
    } catch (e) {
      setError(getIdentityVerifyErrorCode(e) ?? IdentityErrorCode.Default)
      setLock(false)
    }
  }

  const onNameBlur = (ev: InputOnFocus): void => {
    setSchemaUpdates({
      ...schemaUpdates,
      showProfileNameAlert: isDifferentWithProfileNames(ev.target.value, { givenNames, otherGivenNames, surname }),
    })
  }

  const handleOnChange = (data: MedicareForm): void => {
    // @ts-ignore: OPERATION BLEED STOPPER
    setError(null)
    setSchemaUpdates({
      ...schemaUpdates,
      selectedColour: data.colour,
      showProfileDobAlert: isDifferentWithProfileDOB(data.dateOfBirth, profileDateOfBirth),
    })
  }

  return (
    <>
      <Grid>
        <Heading size={headingSize}>{t('verifyIdentity:document:medicare')}</Heading>
      </Grid>
      <ErrorMessage identityType='medicare' error={error} testNameSpace={'verify-identity-medicare-error'} />
      <FormWrapper>
        <FormBuilder
          disabled={lock}
          schema={getMedicareSchema({
            t,
            buttonSize,
            submitted: lock,
            profileDateOfBirth,
            onNameBlur,
            locale: router.locale as SupportedLocale,
            error: USER_VERIFICATION_ERRORS.includes(error),
            ...schemaUpdates,
          })}
          testNameSpace={'verify-identity-medicare'}
          onSubmit={handleSubmit}
          onChange={handleOnChange}
        />
      </FormWrapper>
    </>
  )
}
