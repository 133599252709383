import React, { FunctionComponent, ReactNode } from 'react'
import { Heading, Grid, Text, Tag } from '@afterpaytouch/core'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { OrderMerchant } from '../../model'
import { getMerchantName } from '@afterpaytouch/portal-api/consumer/ordertransactions'
import Link from 'next/link'
import { StoreLogo } from '../StoreLogo'
import { useAmplitudeWithEnduringEventProperties } from '../../integrations/amplitude'
import { TrackingEvent } from '../../model/amplitude'
import { PaymentType } from '@afterpay/types'

const I18N_NAMESPACE = ['index', 'payments', 'upcomingPayments']

interface NewUpcomingPaymentItemProps {
  orderId: string
  paymentType: PaymentType
  dateDue: string
  merchant: OrderMerchant
  installmentSequence: number
  installmentsCount: number
  isOverdue: boolean
  hasOpenRegzDispute: boolean
  action: () => ReactNode
}
export const NewUpcomingPaymentItem: FunctionComponent<NewUpcomingPaymentItemProps> = ({
  orderId,
  paymentType,
  dateDue,
  merchant,
  installmentSequence,
  installmentsCount,
  isOverdue,
  hasOpenRegzDispute,
  action,
}) => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const textColor = isOverdue ? 'Fire' : 'Black'
  const { logEvent } = useAmplitudeWithEnduringEventProperties()
  const router = useRouter()
  return (
    <Grid center>
      <Link
        href={orderId ? `orders/[id]` : 'orders'}
        as={`orders/${orderId}`}
        prefetch={false}
        target='_self'
        className={'flex text-inherit no-underline'}
        onClick={() =>
          logEvent(TrackingEvent.CLICKED_ORDER_DETAIL, {
            orderId,
            outboundLink: router.pathname,
            paymentType,
          })
        }
      >
        <Grid.Item sm='auto'>
          <StoreLogo merchant={merchant} />
        </Grid.Item>
        <Grid.Item>
          <div className='pl-2'>
            <div className='flex flex-row flex-wrap gap-x-2 break-all'>
              <Heading size='M' lineClamp={1} testNameSpace='upcoming-payment-item'>
                {getMerchantName(merchant)}
              </Heading>
              {paymentType === PaymentType.PCL && (
                <div>
                  <Tag.Gray testNameSpace='upcoming-payment-item'>{t('upcomingPayments:payMonthly:tag')}</Tag.Gray>
                </div>
              )}
            </div>
            <Text size='S' renderAs='span' color={textColor}>
              {t('upcomingPayments:paymentProgress', { installment: installmentSequence, total: installmentsCount })} •
              {hasOpenRegzDispute ? (
                <span>
                  {' '}
                  <Tag kind='Black'>{t('common:inDisputeTag')}</Tag>
                </span>
              ) : (
                dateDue
              )}
            </Text>
          </div>
        </Grid.Item>
      </Link>
      <Grid.Item sm='auto'>{action()}</Grid.Item>
    </Grid>
  )
}
