import { Heading, Text, Button } from '@afterpaytouch/core'
import React, { FunctionComponent, useEffect } from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { Route } from '../../../config/router'
import { useAmplitudeWithEnduringEventProperties } from '../../../integrations/amplitude'
import { TrackingEvent } from '../../../model/amplitude'

const I18N_NAMESPACE = ['common', 'verifyIdentity']

export const Success: FunctionComponent = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const route = useRouter()
  const { logEvent } = useAmplitudeWithEnduringEventProperties()

  useEffect(() => {
    // @ts-ignore: OPERATION BLEED STOPPER
    logEvent(TrackingEvent.VIEWED_VERIFICATION_SUCCESS, { locale: route?.locale })
  }, [])

  return (
    <div className='text-center'>
      <Image
        width={48}
        height={48}
        src={`/images/element-check.svg`}
        alt={t('verifyIdentity:success:title')}
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
      <br />
      <br />
      <Heading bold size='XL'>
        {t('verifyIdentity:success:title')}
      </Heading>
      <br />
      <Text>{t('verifyIdentity:success:detail')}</Text>
      <br />
      <Button
        padding='Fluid'
        onClick={() => {
          route.push(Route.HOME)
        }}
      >
        {t('verifyIdentity:success:cta')}
      </Button>
    </div>
  )
}
