import React, { FunctionComponent, useContext } from 'react'
import { Divider, Modal, Text, Icon, Accordion, Grid, Button, Link } from '@afterpaytouch/core'
import { useConsumerSizes } from '../../../utils/responsive'
import { OrderRefundNavigation } from '../OrderRefundNavigation'
import { OrderRefundModalContext } from '../OrderRefundModalContext'
import Image from 'next/image'
import { Trans, useTranslation } from 'next-i18next'
import { useDisplayIllustrations } from '../../../state/convergence/hooks'

const I18N_NAMESPACE = ['orders', 'common']

export const MailReturnConfirm: FunctionComponent = () => {
  const { textSize, smallTextSize } = useConsumerSizes()
  const { order, closeModal, isOverdueOrComplete } = useContext(OrderRefundModalContext)
  const isDisplayIllustrations = useDisplayIllustrations()
  const { merchant } = order ?? {}
  const { t } = useTranslation(I18N_NAMESPACE)

  const accordionContent = [
    {
      icon: 'icon-senditems',
      iconWidth: 41,
      iconHeight: 48,
      heading: t('orders:returningOrderModal:mailReturn:confirm:accordion:content1:heading'),
      body: t('orders:returningOrderModal:mailReturn:confirm:accordion:content1:body'),
    },
    {
      icon: 'icon-keepyourreturn',
      iconWidth: 40,
      iconHeight: 40,
      heading: t('orders:returningOrderModal:mailReturn:confirm:accordion:content2:heading'),
      body: t('orders:returningOrderModal:mailReturn:confirm:accordion:content2:body'),
    },
    {
      icon: 'icon-app-balance',
      iconWidth: 36,
      iconHeight: 56,
      heading: t('orders:returningOrderModal:mailReturn:confirm:accordion:content3:heading'),
      body: t('orders:returningOrderModal:mailReturn:confirm:accordion:content3:body'),
    },
  ]

  return (
    <>
      <OrderRefundNavigation
        displayBack={false}
        heading={
          isOverdueOrComplete
            ? t('orders:returningOrderModal:mailReturn:confirm:overdueOrComplete:heading')
            : t('orders:returningOrderModal:mailReturn:confirm:heading')
        }
      />
      <Modal.Content>
        <Text size={textSize} testNameSpace='mail-confirm-modal-body'>
          {isOverdueOrComplete
            ? t('orders:returningOrderModal:mailReturn:confirm:overdueOrComplete:body1')
            : t('orders:returningOrderModal:mailReturn:confirm:body1', { merchant: merchant?.name })}
        </Text>
        <br />
        <Text size={textSize} testNameSpace='mail-confirm-modal-body'>
          {isOverdueOrComplete
            ? t('orders:returningOrderModal:mailReturn:confirm:overdueOrComplete:body2')
            : t('orders:returningOrderModal:mailReturn:confirm:body2')}
        </Text>
        <br />
        <Text size={textSize} testNameSpace='mail-confirm-modal-body'>
          {isOverdueOrComplete ? (
            <Trans
              i18nKey='orders:returningOrderModal:mailReturn:confirm:overdueOrComplete:body3'
              components={{
                zdHelpLink: <Link href={t('common:urls:returningOrderModal:zdLink')} kind='Underline' target='_blank' bold={false} />,
              }}
            />
          ) : (
            t('orders:returningOrderModal:mailReturn:confirm:body3')
          )}
        </Text>
        <div>
          <Divider />
          <Accordion id='additional-info'>
            <Accordion.Button>
              <Grid>
                <div className='flex items-center' data-testid='mail-confirm-modal-more-info-button'>
                  <Grid.FixedItem width={28}>
                    <Icon name='Info' size='Small' />
                  </Grid.FixedItem>
                  <Text bold size={textSize}>
                    {t('orders:returningOrderModal:mailReturn:confirm:accordion:header')}
                  </Text>
                </div>
              </Grid>
            </Accordion.Button>
            <Divider />
            <Accordion.Details>
              {accordionContent.map((item, index) => (
                <Grid key={index}>
                  {isDisplayIllustrations && (
                    <Grid.FixedItem width={64}>
                      <Image
                        src={`/images/${item.icon}.svg`}
                        height={item.iconHeight}
                        width={item.iconWidth}
                        alt={t('orders:returningOrderModal:alt', { title: item.heading })}
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                        }}
                      />
                    </Grid.FixedItem>
                  )}
                  <div data-testid={`mail-confirm-modal-more-info-panel-${index}`}>
                    <Text bold size={smallTextSize}>
                      {item.heading}
                    </Text>
                    <Text size={smallTextSize}>{item.body}</Text>
                  </div>
                </Grid>
              ))}
            </Accordion.Details>
          </Accordion>
        </div>
        <div className='mt-6'>
          <Button.Primary
            padding='Fluid'
            onClick={closeModal}
            testNameSpace='mail-confirm-modal-confirm-button'
            aria-label={t('orders:returningOrderModal:aria:nav:close')}
          >
            {t('orders:returningOrderModal:mailReturn:confirm:cta')}
          </Button.Primary>
        </div>
      </Modal.Content>
    </>
  )
}
