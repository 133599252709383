import { LoyaltyTierBenefit } from '@afterpaytouch/portal-api'
import React, { FunctionComponent } from 'react'
import { PulseBenefitRow } from '../PulseBenefitRow'

export interface PulseBenefitsListProps {
  pulseBenefits: LoyaltyTierBenefit[]
}

export const PulseBenefitsList: FunctionComponent<PulseBenefitsListProps> = ({ pulseBenefits }) => {
  const testNameSpace = 'pulse-benefits-list'

  return (
    <div data-testid={`${testNameSpace}-container`}>
      {pulseBenefits?.map((pulseBenefit) => (
        <div className='mb-2' key={pulseBenefit.title}>
          <PulseBenefitRow pulseBenefit={pulseBenefit} />
        </div>
      ))}
    </div>
  )
}
